export class UserModel {
  userId: number;
  name: string;
  firstName: string;
  lastName: string;
  role: number;
  userRoleId: number;
  email: string;
  password: string;
  gender: number;
  cellPhoneNumber: string;
  status: string;
  groupList: Array<Group>;
  groupBranchList: Array<GroupBranchListDTO>;
  constructor() {
    this.userId = 0;
    this.name = '';
    this.firstName = '';
    this.lastName = '';
    this.role = null;
    this.userRoleId = null;
    this.email = '';
    this.password = null;
    this.gender = 0;
    this.cellPhoneNumber = '';
    this.groupList = new Array<Group>();
    this.groupBranchList = new Array<GroupBranchListDTO>();
  }
}

export class Group {
  userId: number;
  groupId: number;
  subGroupId: number;
  group: string;
  subGroup: string;
  constructor() {
    this.userId = 0;
    this.groupId = null;
    this.subGroupId = 0;
    this.group = '';
    this.subGroup = '';
  }
}

export class GroupBranchListDTO {
  idGroup: number;
  nameGroup: string;
  idBranchList: Array<BranchDTO>;

  constructor() {
    this.idGroup = 0;
    this.nameGroup = '';
    this.idBranchList = new Array<BranchDTO>();
  }
}

export class BranchDTO {
  branchId: number;
  name: string;

  constructor() {
    this.branchId = 0;
    this.name = '';
  }
}
