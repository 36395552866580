import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {TabDirective, TabsetComponent} from 'ngx-bootstrap/tabs';
import {CategoryCoverageModel, CoverageModel} from '../admin-policies/coverage-model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DualListComponent} from 'angular-dual-listbox';
import {SpecialCoverageModel} from '../admin-policies/special-coverage-model';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {NgxSpinnerService} from 'ngx-spinner';
import {regex} from '../../common/regex';
import {Documents} from '../upload-file/documents';
declare var $: any;
@Component({
  selector: 'app-admin-policy-simplify',
  templateUrl: './admin-policy-simplify.component.html',
  styleUrls: ['./admin-policy-simplify.component.css']
})
export class AdminPolicySimplifyComponent implements OnInit {


  policyAttachmentList = [];
  url: string;
  reportGridViewType = true;
  lclSectionSelected: number;
  catClients: any = [];
  catGroups: any = [];
  catInsuranceCompany: any = [];
  catAgentKeys: any = [];
  catBranch: any = [];
  catSubBranch: any = [];
  policyId: number;
  categoryId: number;
  carId: number;
  medicalCare: number;
  // Objetos para los DatePickers ->
  bsStartDate: Partial<BsDatepickerConfig>  = new BsDatepickerConfig();
  bsExpirationDate: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  category = 'CATEGORIA';
  typeList: any = [];
  categoryList: any = [];
  categoryList1: any = [];
  bCoverage: number;
  attachmentCatalog: any = [];
  catPaymentTypes: any = [];
  catTypePayments: any = [];
  flag: number;
  public fileUpload: File;
  public fileUploadAttachment: File;
  public fileUploadPolicy: File;
  error = {active: false, msg: ''};
  receiptData: any = {};
  fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'];
  fileTypesPolicy: string[] = ['application/pdf'];
  dataToSendGeneral: any = {};
  typeReceiptOption: any;
  dataToSendAttachment: any = {};
  dataToSendReceipt: any = {};
  listDataToSendReceipt: any [];
  dataToSendCategory: any = {};
  dataToSendCancelPolicy: any = {};
  dataReceiptEdith: any = {};
  typeSubBranch: number;
  bGeneral: boolean;
  bCategory: boolean;
  bCoverageSpecial: boolean;
  bAttachment: boolean;
  bReceipt: boolean;
  base64: string;
  bReceiptC: number;
  receiptList: any = [];
  receiptTotal: any = {};
  policyAmount: any = {};
  categoryName: string;
  catTaxes: any = [];
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  dropdownSettingsCoordinators = {};
  clientId: number;
  catClientsPolicy: any = [];
  policyRenew: any = {};
  currencyList: any = [];
  salesmanList: any = [];
  executiveSurexList: any = [];
  SurexsSelectedItems: any = [];
  dropdownSettingsSurexs = {};
  executiveIncidentList: any = [];
  incidentsSelectedItems: any = [];
  dropdownSettingsIncidents = {};
  catCoordinatorList: any = [];
  paymentTypeOld: number;
  selectedConceptTypeValidity: number;
  yearsValidity: number;
  startDateTmp: string;
  isRenewPolicy: boolean;
  endDateTmp: any;
  dataExpired: any = [];
  policyIdExpired: number;
  base64File2: string;
  isLoadPolicy: boolean;
  isSplitRights: boolean;
  isSplitRightsByCar: boolean;
  catManagerIncidentList: any = [];
  CoordinatorsSelectedItems: any = [];
  modalRefRenew: BsModalRef;
  catAgeMaxLimit: any = [];
  /*variables para modulo de ubicaciones*/
  locationId: number;
  dropdownSettingsSecurityMeasures = {};
  dropdownSettingsFireProtection = {};
  dropdownSettingsEnterpriseAdministrator = {};
  postcode: string;
  addressId: number;
  catCountries: any = [];
  catMunicipality: any = [];
  catNeighborhood: any = [];
  catActivities: any = [];
  catConstructionTypes: any = [];
  catFireProtection: any = [];
  catSecurityMeasures: any = [];
  catEnterpriseAdministrator: any = [];
  listNameLocations: any = [];
  categoryDamageCoverageList: any = [];
  categoryDamageCoverageListSend: any = [];
  categoryDamageCoverage: any = {};
  coverageListBasicList: any = []; // lista para coverturas basicas
  rootExtendion: any = {}; // extension de cubierta
  allRisk: any = {}; // extension de cubierta
  phenomenases: any = {}; // extension de cubierta
  phenomenasesList: any = []; // lista para coverturas basicas

  catTypesDeducible: any = [];
  catTypesMoneda: any = [];
  catMinMax: any = [];
  deducibleType: any = {};
  deducibleTypeId: number;
  percentaje: number;
  sumAssured: number;

  /*extension de cubierta*/
  deducibleTypeIdEC: number;
  deducibleTypeEC: any = {};
  minMaxECId: number;

  minMaxGen: any = {};
  minMaxEC: any = {};
  typeMonedaECId: number;
  typeMonedaIdGen: number;
  deducibleTypeIdGen: number;
  typeMonedaEC: any = {};
  typeMonedaGen: any = {};
  deducibleTypeGen: any = {};
  percentajeIdGen: number;
  percentajeGen: number;

  valorEC: number;
  percentajeEC: number;

  /*FENOMENOS HIDROMETEREOLOGICOS*/
  deducibleTypeIdFH: number;
  deducibleTypeFH: any = {};
  percentajeFH: number;
  valorCoaseguroFH: number;

  /*TERREMOTO Y ERUPCION VOLCANICAS*/
  deducibleTypeIdTEV: number;
  deducibleTypeTEV: any = {};
  percentajeTEV: number;
  valorCoaseguroTEV: number;
  /*REMOSION DE ESCONBROS*/
  deducibleTypeIdRE: number;
  deducibleTypeRE: any = {};
  valorRE: number;

  /*BIENES A LA INTERPERIE*/

  percentajeBI: number;
  deducibleTypeIdBI: number;
  deducibleTypeBI: any = {};
  percentajeCoaseguroBI: number;
  listCoverages: any = [];
  listCoveragesTemp: any = [];

  /*GENERIC 1*/
  sumAssuredGen: number;
  minMaxIdGen: number;
  // percentajeGen:
  // typeMonedaGen

  /*VARIABLES  de ubicaciones*/
  // data
  dataToSendLocation: any = {};
  dataToSendLocationList: any = [];
  coverageList: any = [];
  catPostCode: any = [];
  /*edicion de covertura edificion*/
  editDataCovergesIncendio: any = {}; //
  tempListDataCoverage: any = [];
  nameSeccion: string;
  /*MODAL*/
  modalRef: BsModalRef;
  modalRefNewClient: BsModalRef;
  /*rotura de maquinarioa  json */
  genericJson: any = {};
  private modalRefProyection: BsModalRef;

  /*VARIABLES PARA INDICAR SI SE MUESTRA O NO UN TAP*/

  stTwo = false;
  nameType: any = {};
  staticTabs: TabsetComponent;

  // VARIABLES COBERTURAS ESPECIALES
  categorySpecialCoverageList: any = [];
  key: any;
  display: any;

  // tslint:disable-next-line:max-line-length
  format = { add: 'ASIGNAR COBERTURA', remove: 'QUITAR COBERTURA', all: 'SELECCIONAR TODOS', none: 'DESELECCIONAR', direction: DualListComponent.LTR, draggable: true };
  categoryForm: FormGroup;
  policyForm: FormGroup;
  receiptForm: FormGroup;
  myFormReceipt: FormGroup;
  statusList: any = [];
  paymentList: any = [];
  roleId: number;
  coverageForm: FormGroup; // Formulario
  branchList: any[]; // Tipos de ramos

  // variables para poblacion inicial
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  public fileUploadZip: File;
  errorZip = {active: false, msg: ''};
  fileTypesZip: string[] = ['application/zip', 'application/x-zip-compressed'];
  public fileUploadRar: File;
  errorRar = {active: false, msg: ''};
  fileTypesRar: string[] = ['application/rar', 'application/x-rar-compressed'];
  chargesPercentage = 0;
  isEdithCharges: boolean;
  officeList: any = [];
  isSimplifyPolice: boolean;

  // ES PARA COMMENTS

  dataToSendIssuePolicy: any = {};
  responseImportInsured: any = [];
  insuredProcess: number;
  insuredUnprocessed: number;
  importMassive: boolean;
  branchId: string;
  subbranchId: string;
  validationPolicy: any = {};
  catGenders: any = [];
  @Output() isSimply = new EventEmitter<boolean>();

  endorsementList: any = [];
  endorsementId: number;
  editData: boolean;
  catTypeAdmin: any = [];
  modalSaveEndorsement: BsModalRef;
  constructor(
    private api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private shared: SharedService,
    private datePipe: DatePipe,
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
  ) {
    this.isSplitRights = false;
    this.isSplitRightsByCar = false;
    this.isLoadPolicy = false;
    this.isRenewPolicy = false;
    this.isEdithCharges = false;
    this.editData = true;
    this.shared.gPolicyIdObservable().subscribe(
      gPolicyId => {
        const policy = parseInt(localStorage.getItem('policy-id'), 10);
        if (gPolicyId > 0) {
          localStorage.setItem('policy-id', String(gPolicyId));
          this.shared.fPolicyDataReadonly = true;
        } else if (gPolicyId < 0) {
          if (policy > 0) {
            this.shared.fPolicyDataReadonly = true;
          } else {
            this.shared.fPolicyDataReadonly = false;
          }
        } else {
          localStorage.setItem('policy-id', String(gPolicyId));
          this.shared.fPolicyDataReadonly = false;
        }
        if (this.shared.fPolicyDataReadonly) {
          // console.log('edición de documento');
          this.bGeneral = true;
          this.bCategory = true;
          this.bCoverageSpecial = true;
          this.bAttachment = true;
          this.bReceipt = true;
          this.loadCatalogGenerals();
          this.categoryList = [];
          this.policyAttachmentList = [];
          this.bReceiptC = 1;
          this.receiptList = [];
          this.bCoverage = -1;
          setTimeout(() => {
            this.getTypesAdminPolicy();
            this.getPolicy();
            $('#smartwizardsimply').smartWizard('goToStep', 0);
          }, 1000);
        } else {
          this.policyId = 0;
          // console.log('nuevo documento');
          this.bGeneral = true;
          this.bCategory = false;
          this.bCoverageSpecial = false;
          this.bAttachment = false;
          this.bReceipt = false;
          this.loadCatalogGenerals();
          this.getIdOT();
          this.clientsSelectedItems = [];
          setTimeout(() => {
            this.isLoadPolicy = true;
          }, 1000);
        }
        this.lclSectionSelected = 0;
      }
    );
    this.categoryForm = this.fb.group({
      catalogs: ['groups', [Validators.required]],
      category: ['category', [Validators.required]]
    });
    this.policyForm  = this.fb.group({
      numberPolicy: [''],
      startDate: ['', [Validators.required]],
      expirationDate: ['', [Validators.required]],
      group: ['', [Validators.required]],
      client: ['', [Validators.required]],
      insurance: ['', [Validators.required]],
      keyAgent: ['', [Validators.required]],
      branch: ['', [Validators.required]],
      subBranch: ['', [Validators.required]],
      salesman: ['', [Validators.required]],
      coordinator: ['', [Validators.required]],
      executive: ['', [Validators.required]],
      incident: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      payment: ['', [Validators.required]],
      collection: ['', [Validators.required]],
      renewPolicy: ['', [Validators.required]],
      managerIncident: ['', [Validators.required]],
      max_age_limit: [''],
      office: [''],
      medicalCare: [''],
      typeAdmin: ['']
    });
    this.receiptForm = this.fb.group({
      payment: new FormControl({ value: '', disabled : true} , Validators.required),
      netPremium: ['', [Validators.required]],
      rights: ['', [Validators.required]],
      charges: new FormControl({ value: '', disabled : true} , Validators.required),
      commission: ['', [Validators.required]],
      commissionAmount: ['', [Validators.required]],
      iva: ['', [Validators.required]],
      ivaAmount: new FormControl({ value: ''} , Validators.required),
      totalPremium: new FormControl({ value: ''} , Validators.required),
      chargesPercentage: new FormControl({ value: '', disabled : true} , Validators.required)
    });
    this.myFormReceipt = this.fb.group({
      numberReceipt: [''],
      paymentDeadline: [''],
      paymentDate: [''],
      netPremium: [''],
      rights: [''],
      paymentCharges: [''],
      iva: [''],
      totalPremium: [''],
      receiptStatus: [''],
      paymentForm: [''],
      settlementNumber: ['']
    });
  }


  ngOnInit() {
    this.medicalCare = 0;
    this.bsStartDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });
    this.bsExpirationDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });
    this.typeList = [ {name: 'SMGMV'}, {name: 'UMAM'}, {name: 'USD'}, {name: 'PESOS MEXICANOS'}, {name: 'MESES'}];
    this.roleId = Number(localStorage.getItem('roleId'));
    // this.numberPolicy=121;
    $('#smartwizardsimply').smartWizard({
      selected: 0, // Initial selected step, 0 = first step
      enableAllSteps: true,
      theme: 'default', // theme for the wizard, related css need to include for other than default theme
      justified: true, // Nav menu justification. true/false
      autoAdjustHeight: true, // Automatically adjust content height
      cycleSteps: false, // Allows to cycle the navigation of steps
      backButtonSupport: true, // Enable the back button support
      enableURLhash: false, // Enable selection of the step based on url hash,
      enableAnchorOnDoneStep: true,
      transition: {
        animation: 'none', // Effect on navigation, none/fade/slide-horizontal/slide-vertical/slide-swing
        speed: '400', // Transion animation speed
        easing: '' // Transition animation easing. Not supported without a jQuery easing plugin
      },
      toolbarSettings: {
        toolbarPosition: 'bottom', // none, top, bottom, both
        toolbarButtonPosition: 'right', // left, right, center
        showNextButton: false, // show/hide a Next button
        showPreviousButton: false, // show/hide a Previous button
        toolbarExtraButtons: [] // Extra buttons to show on toolbar, array of jQuery input/buttons elements
      },
      anchorSettings: {
        anchorClickable: true, // Enable/Disable anchor navigation
        enableAllAnchors: true, // Activates all anchors clickable all times
        markDoneStep: true, // Add done state on navigation
        markAllPreviousStepsAsDone: true, // When a step selected by url hash, all previous steps are marked done
        removeDoneStepOnNavigateBack: true, // While navigate back done step after active step will be cleared
        enableAnchorOnDoneStep: true // Enable/Disable the done steps navigation
      },
      keyboardSettings: {
        keyNavigation: false, // Enable/Disable keyboard navigation(left and right keys are used if enabled)
        keyLeft: [37], // Left key code
        keyRight: [39] // Right key code
      },
      lang: { // Language variables for button
        next: 'SIGUIENTE',
        previous: 'ANTERIOR'
      },
      disabledSteps: [], // Array Steps disabled
      errorSteps: [], // Highlight step with errors
      hiddenSteps: [] // Hidden steps
    });
    this.flag = 1;
    this.dropdownSettingsClients = {
      singleSelection: false,
      idField: 'clientId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSurexs = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EJECUTIVOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsIncidents = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EJECUTIVOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsCoordinators = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON COORDINADORES',
      searchPlaceholderText: 'BUSCAR'
    };

    /*configuracion para catalogos de ubicaciones*/
    this.dropdownSettingsSecurityMeasures = {
      singleSelection: false,
      idField: 'catalogyId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON MEDIDAS DE SEGURIDAD',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsFireProtection = {
      singleSelection: false,
      idField: 'catalogyId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON PROTECCIONES CONTRA INCENDIOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsEnterpriseAdministrator = {
      singleSelection: false,
      idField: 'userId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ADMINISTRADORES DE EMPRESA',
      searchPlaceholderText: 'BUSCAR'
    };

    this.catTypesDeducible.push({id: 1, name: 'SIN DEDUCLIBLE'});
    this.catTypesDeducible.push({id: 2, name: 'EN TODA Y CADA PERDIDA'});
    // this.getSubBranches(2);
    this.catTypesMoneda.push({id: 1, name: 'MX'});
    this.catTypesMoneda.push({id: 2, name: 'DOLARES'});
    this.catTypesMoneda.push({id: 3, name: 'UMA'});

    this.catMinMax.push({id: 1, name: 'MÍNIMO'});
    this.catMinMax.push({id: 2, name: 'MÁXIMO'});

    // inicializa campos para editar covertura de editDataCovergesIncendio
    this.editDataCovergesIncendio = {
      typeCovegareId: 100
      , sumAssured: 0
      , deducibleTypeId: 0
      , percentaje: 0
      , deducibleTypeIdEC: 0
      , percentajeEC: 0
      , minMaxECId: 0
      , valorEC: 0
      , typeMonedaECId: 0
      , deducibleTypeIdFH: 0
      , percentajeFH: 0
      , valorCoaseguroFH: 0
      , deducibleTypeIdTEV: 0
      , percentajeTEV: 0
      , valorCoaseguroTEV: 0
      , valorRE: 0
      , deducibleTypeIdRE: 0
      , deducibleTypeIdBI: 0
      , percentajeBI: 0

      , percentajeCoaseguroBI: 0
    };

    /*inicializar json para coverturas rotura maquinaria */
    this.initialJsonGeneric();

    this.key = 'id';
    this.display = 'name';
  }


  /*+-------------------------------------------------------------------------------------------------------+
       |------------------------------------------|  G E N E R A L E S  |------------------------------------------|
     +-------------------------------------------------------------------------------------------------------+*/
  loadCatalogGenerals() {
    this.dataToSendGeneral = {
      policyId: 0,
      number: 'OT00001',
      startDate: undefined,
      expirationDate: undefined,
      groupId: undefined,
      insuranceId: undefined,
      branchId: undefined,
      subBranchId: undefined,
      renew: false,
      status: 2,
      clients: [],
      currencyId: undefined,
      userId: 0,
      paymentTypeId: undefined,
      coordinators: [],
      executiveSurexs: [],
      executiveIncidents: [],
      policyIdOld: 0,
      agentKeyId: 0,
      typePaymentPolicy: 0,
      managerIncident: 0,
      maxAgeLimit: 25,
      startDatePeriod: '',
      endDatePeriod: '',
      medicalCare: false,
      typeAdministrationPolicy: 2
    };

    this.getSalesman();
    this.getCurrency();
    this.getGroups();
    this.getInsuranceCompanies();
    this.getBranches();
    this.getTypePayments();
    this.getTypePaymentsPolicy();
    this.getExecutives(1);
    this.getExecutives(2);
    this.getExecutives(3);
    this.getManagerIncident();
    this.getCatMaxAgeLimit();
    this.getOffices();
    this.getTypesAdminPolicy();
    this.SurexsSelectedItems = [];
    this.incidentsSelectedItems = [];
    this.CoordinatorsSelectedItems = [];
    this.clientsSelectedItems = [];
  }
  getIdOT() {
    this.api.getId().then((data: any) => {
      this.dataToSendGeneral.number = data.otId;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  sendGeneralData() {
    this.spinner.show('sp');
    this.policyId = 1;
    this.dataToSendGeneral.startDate = this.datePipe.transform(this.dataToSendGeneral.startDate, 'yyyy-MM-dd', 'es-ES');
    this.dataToSendGeneral.expirationDate = this.datePipe.transform(this.dataToSendGeneral.expirationDate, 'yyyy-MM-dd', 'es-ES');
    this.dataToSendGeneral.policySimply = true;
    setTimeout(() => {
      try {
        // console.log(this.dataToSendGeneral);
        this.api.postPolicyData(this.dataToSendGeneral)
          .then(
            (response: any) => {
              this.policyId = response.policyId;
              localStorage.setItem('policy-id', String(this.policyId));
              this.typeSubBranch = parseInt(this.dataToSendGeneral.subBranchId, 10);
              this.dataToSendGeneral.policyId = this.policyId;
              this.shared.fPolicyDataReadonly = true;
              this.bCategory = true;
              this.dataToSendAttachment = {
                attachmentId: 0,
                name: undefined,
                attachmentPath: undefined,
                typeAttachmentId: 1,
                subBranchId: this.dataToSendGeneral.subBranchId,
                subBranch: undefined,
                relationId: this.policyId
              };
              // Agregamos a el id de la poliza al attachment que guardará el attachment
              setTimeout(() => {
                  this.categoryList = [];
                  this.bCoverage = -1;
                  this.fileUpload = undefined;
                  this.shared.gPolicyIdCategory.next(Number(localStorage.getItem('policy-id')));
                  $('#smartwizardsimply').smartWizard('goToStep', 1);
              }, 1000);
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.spinner.hide('sp');
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      } catch (e) {
      }
    }, 500);
  }
  sendGeneralDataUpdate() {
    this.spinner.show('sp');
    // console.log(this.dataToSendGeneral);
    const dateStart = Date.parse(this.dataToSendGeneral.startDate);
    const dateExpiration = Date.parse(this.dataToSendGeneral.expirationDate);
    this.dataToSendGeneral.startDate = this.datePipe.transform(dateStart, 'yyyy-MM-dd', 'es-ES');
    this.dataToSendGeneral.expirationDate = this.datePipe.transform(dateExpiration, 'yyyy-MM-dd', 'es-ES');
    const clients = [];
    setTimeout(() => {
      try {
        // console.log(this.dataToSendGeneral);
        this.api.putPolicyData(this.dataToSendGeneral)
          .then(
            (response: any) => {
              this.typeSubBranch = parseInt(this.dataToSendGeneral.subBranchId, 10);
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              if (this.paymentTypeOld !== this.dataToSendGeneral.paymentTypeId) {
                this.loadDataToSendReceipt();
                this.invokeServiceGetConfigurationRightInsurance();
                this.api.postReceiptDataRenews(this.dataToSendReceipt)
                  .then(
                    (response1: any) => {
                      this.getCategoriesPolicy();
                      $('#smartwizardsimply').smartWizard('goToStep', 2);
                      this.spinner.hide('sp');
                    }, error => {
                      this.spinner.hide('sp');
                      this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                    }
                  );
              } else {
                this.spinner.hide('sp');
              }
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      } catch (e) {
      }
    }, 500);
  }
  getCurrency() {
    this.currencyList = [
      {currencyId: 1, name: 'MX'}, {currencyId: 2, name: 'USD'}
    ];
  }
  getCatMaxAgeLimit() {
    this.api.getCatAgeMaxLimit(1).then((data: any) => {
      this.catAgeMaxLimit = data;
    }, error => {
    });
  }
  getOffices() {
    this.api.getOffices().then((data: any) => {
      this.officeList = data;
      this.dataToSendGeneral.officeId = this.officeList[0].officeId;
    }, error => {
    });
  }
  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.catGroups = data;
    }, error => {
    });
  }
  getSubGroups(id, event?) {
    this.api.getSubGroups(id).then((data: any) => {
      this.catClients = data;
      this.validateSubbranchFlotilla();
      if (this.dataToSendGeneral.policyId === 0) {
        this.clientsSelectedItems = [];
        this.dataToSendGeneral.clients = [];
      }
    }, error => {
    });

    if (event !== undefined && event !== null) {
      const textToCopy = String(event.groupId + '::' + event.name);
      this.copyText(textToCopy);
    }
  }
  getInsuranceCompanies() {
    this.api.getInsuranceCompanies().then((data: any) => {
      this.catInsuranceCompany = data;
    }, error => {
    });
  }
  getAgentKeys(insuranceId, event?) {
    this.api.getAgentKeysByInsuranceCompany(insuranceId).then((data: any) => {
      this.catAgentKeys = data;
    }, error => {
    });

    if (event !== undefined && event !== null) {
      const textToCopy = String(event.insuranceId + '::' + event.name);
      this.copyText(textToCopy);
    }
  }
  chageAgentKey(event) {
    let textToCopy = '';
    if (event !== undefined && event !== null) {
      if (event.agentKeyId === 0) {
        textToCopy = '0::0';
      }  else {
        textToCopy = String(event.agentKeyId + '::' + event.description);
      }
      this.copyText(textToCopy);
    }
  }
  changeSeller(event) {
    if (event !== undefined && event !== null) {
      const textToCopy = String(event.userId + '::' + event.name);
      this.copyText(textToCopy);
    }
  }
  getBranches() {
    this.api.getBranches().then((data: any) => {
      this.catBranch = data;
    }, error => {
    });
  }
  getSubBranches(id) {
    if (this.dataToSendGeneral.clients !== undefined && this.dataToSendGeneral.clients !== null
      && this.dataToSendGeneral.branchId === 3 && this.dataToSendGeneral.clients.length > 1) {
      this.dataToSendGeneral.clients = [];
      this.clientsSelectedItems = [];
      this.toastr.warning('Para pólizas de autos, solo puedes agregar un subgrupo.', 'Notificación');
      return;
    }

    if (this.dataToSendGeneral.branchId === 3 || this.dataToSendGeneral.branchId === '3') {
      this.attachmentCatalog = [
        {attachmentId: 1, description: 'ARCHIVO'} ];
    } else {
      this.attachmentCatalog = [
        {attachmentId: 1, description: 'ARCHIVO'},
        {attachmentId: 8, description: 'DIRECTORIO MEDICO'}];
    }

    this.api.getSubBranches(id).then((data: any) => {
      this.catSubBranch = data;
    }, error => {
    });
  }
  getPolicy() {
    this.api.getPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.dataToSendGeneral = data;
      this.isRenewPolicy = this.dataToSendGeneral.renew;
      this.invokeServiceGetMedicalCareByInsuranceBranchId();
      this.paymentTypeOld = this.dataToSendGeneral.paymentTypeId;
      localStorage.setItem('policy-subbranchId', this.dataToSendGeneral.subBranchId);
      localStorage.setItem('policy-branchId', this.dataToSendGeneral.branchId);
      this.policyId = this.dataToSendGeneral.policyId;
      this.isSimplifyPolice = this.dataToSendGeneral.policySimply;
      this.isSimply.emit(this.dataToSendGeneral.policySimply);
      this.getSubGroups(this.dataToSendGeneral.groupId);
      this.getSubBranches(this.dataToSendGeneral.branchId);
      this.getAgentKeys(this.dataToSendGeneral.insuranceId);
      this.getExecutivesByPolicy(1);
      this.getExecutivesByPolicy(2);
      this.getExecutivesByPolicy(3);
      setTimeout(() => {
        this.isLoadPolicy = true;
      }, 1000);
      if (this.dataToSendGeneral.branchId === 2) {// si es poliza de daños
        this.getCoverages(15);
      }

      if (this.dataToSendGeneral.branchId === 3 || this.dataToSendGeneral.branchId === '3') {
        this.attachmentCatalog = [
          {attachmentId: 1, description: 'ARCHIVO'} ];
      } else {
        this.attachmentCatalog = [
          {attachmentId: 1, description: 'ARCHIVO'},
          {attachmentId: 8, description: 'DIRECTORIO MEDICO'}];
      }

      // Se cargan los recibos que se muestran en general
      this.categoryList = [];
      this.receiptList = [];
      this.loadDataToSendReceipt();
      this.getCategoriesPolicy();
      if (this.dataToSendGeneral.status === 1 || this.dataToSendGeneral.status === 4) {
        this.invokeServiceGetEndorsementByPolicy(this.dataToSendGeneral.policyId);
      }

    }, error => {
    });
    this.api.getClientsPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.clientsSelectedItems = data;
      this.changeSubGroups();
    }, error => {
    });
  }
  public invokeServiceForDownloadPolicyFile() {
    this.api.getPolicyFile(this.dataToSendGeneral.policyId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', this.dataToSendGeneral.number + '.pdf');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
        }
      );
  }
  onChangeInputFilePolicy(e) {
    this.error = {active: false, msg: ''};
    this.fileUploadPolicy = e.target.files[0];
    if (0 > this.fileTypesPolicy.indexOf(this.fileUploadPolicy.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
    const formData = new FormData();
    formData.append('file', this.fileUploadPolicy);
    this.api.postFilePolicy(formData, localStorage.getItem('policy-id'))
      .subscribe(
        response => {
          this.toastr.success('Su información ha sido actualizada correctamente.', 'Notificación');
          this.fileUploadPolicy = undefined;
        }, error => {
          this.toastr.warning(error.error);
        }
      );
  }
  chanceStatusCoordinators(event?) {
    if (event !== undefined) {
      const textToCopy = event.directoryId + '::' + event.name;
      this.copyText(textToCopy);
    }

    setTimeout(() => {
      try {
        const coordinators = [];
        this.CoordinatorsSelectedItems.forEach(item => {
          coordinators.push(item.directoryId);
        });
        // console.log(executive);
        this.dataToSendGeneral.coordinators = coordinators;
      } catch (e) {
      }
    }, 500);
  }
  changeIncident(event) {
    if (event !== undefined) {
      const textToCopy = event.userId + '::' + event.name;
      this.copyText(textToCopy);
    }
  }
  changeMoneda(event) {
    if (event !== undefined) {
      const textToCopy = event.currencyId + '::' + event.name;
      this.copyText(textToCopy);
    }
  }
  changeCatPaymentType(event) {
    if (event !== undefined) {
      const textToCopy = event.typePaymentPolicy + '::' + event.description;
      this.copyText(textToCopy);
    }
  }
  changePaymentType(event) {
    if (event !== undefined) {
      const textToCopy = event.paymentTypeId + '::' + event.paymentType;
      this.copyText(textToCopy);
    }
  }
  changeOffice(event) {
    if (event !== undefined) {
      const textToCopy = event.officeId + '::' + event.office;
      this.copyText(textToCopy);
    }
  }

  changeStatusSurexs(event?) {
    if (event !== undefined) {
      const textToCopy = event.directoryId + '::' + event.name;
      this.copyText(textToCopy);
    }

    setTimeout(() => {
      try {
        const executive = [];
        this.SurexsSelectedItems.forEach(item => {
          executive.push(item.directoryId);
        });
        // console.log(executive);
        this.dataToSendGeneral.executiveSurexs = executive;
      } catch (e) {
      }
    }, 500);
  }
  changeStatusIncidents(event?) {
    if (event !== undefined) {
      const textToCopy = event.directoryId + '::' + event.name;
      this.copyText(textToCopy);
    }

    setTimeout(() => {
      try {
        const executive1 = [];
        this.incidentsSelectedItems.forEach(item => {
          executive1.push(item.directoryId);
        });
        // console.log(executive1);
        this.dataToSendGeneral.executiveIncidents = executive1;
      } catch (e) {
      }
    }, 500);
  }
  getExecutivesByPolicy(id) {
    this.api.getExecutivesByPolicy(localStorage.getItem('policy-id'), id).then((data: any) => {
      switch (id) {
        case 1:
          // this.dataToSendGeneral.directoryId = data[0].directoryId;
          this.CoordinatorsSelectedItems = data;
          this.chanceStatusCoordinators();
          break;
        case 2:
          this.SurexsSelectedItems = data;
          this.changeStatusSurexs();
          break;
        case 3:
          this.incidentsSelectedItems = data;
          this.changeStatusIncidents();
          break;
      }
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getExecutives(id) {
    this.api.getExecutives(id).then((data: any) => {
      switch (id) {
        case 1:
          this.catCoordinatorList = data;
          break;
        case 2:
          this.executiveSurexList = data;
          break;
        case 3:
          this.executiveIncidentList = data;
          break;
      }
    }, error => {
    });
  }
  getSalesman() {
    this.api.getSalesman().then((data: any) => {
      this.salesmanList = data;
    }, error => {
    });
  }
  changeSubGroups(event?) {
    let subGroup = [];
    if ( this.clientsSelectedItems !== undefined &&  this.clientsSelectedItems !== null
      && this.dataToSendGeneral.branchId === 3 &&  this.clientsSelectedItems.length > 1) {
      this.dataToSendGeneral.clients = [];
      this.clientsSelectedItems = [];
      subGroup = [];
      this.toastr.warning('Para pólizas de autos, solo puedes agregar un subgrupo.', 'Notificación');
      return;
    }

    if (event !== undefined) {
      const textToCopy = event.clientId + '::' + event.name;
      this.copyText(textToCopy);
    }

    setTimeout(() => {
      try {
        this.clientsSelectedItems.forEach(item => {
          subGroup.push(item.clientId);
        });
        this.dataToSendGeneral.clients = subGroup;
      } catch (e) {
      }
    }, 1000);
  }
  public openModalAddExpiration(startDate: Date[], template: TemplateRef<any>) {
    this.selectedConceptTypeValidity = 1;
    this.yearsValidity = 2;
    if (!this.shared.fPolicyDataReadonly && this.policyId === 0) {
      if (startDate !== null) {
        this.startDateTmp = this.datePipe.transform(startDate, 'yyyy-MM-dd', 'es-ES');
        this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
      }
    }
  }
  public selectedTypeValidity() {
    switch (this.selectedConceptTypeValidity) {
      case 1:
        this.endDateTmp = new Date(this.startDateTmp);
        // tslint:disable-next-line:max-line-length
        this.endDateTmp = this.endDateTmp.setFullYear(this.endDateTmp.getFullYear() + 1, this.endDateTmp.getMonth(), this.endDateTmp.getDate() + 1);
        this.dataToSendGeneral.expirationDate = this.datePipe.transform(this.endDateTmp, 'yyyy-MM-dd', 'es-ES');
        break;
      case 2:
        if (this.yearsValidity === 0 || this.yearsValidity === undefined) {
          this.toastr.warning('DEBE DE INGRESAR EL NUMERO DE AÑOS DE VIGENCIA DE LA PÓLIZA', 'NOTIFICACIÓN');
          return;
        }
        if (this.yearsValidity < 0) {
          this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER NEGATIVO, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
          return;
        }
        if (this.yearsValidity < 1) {
          this.toastr.warning('EL MÍNIMO PERMITIDO PARA MULTI-ANUAL ES 2', 'NOTIFICACIÓN');
          return;
        }
        this.endDateTmp = new Date(this.startDateTmp);
        // tslint:disable-next-line:max-line-length
        this.endDateTmp = this.endDateTmp.setFullYear(this.endDateTmp.getFullYear() + this.yearsValidity, this.endDateTmp.getMonth(), this.endDateTmp.getDate() + 1);
        this.dataToSendGeneral.expirationDate = this.datePipe.transform(this.endDateTmp, 'yyyy-MM-dd', 'es-ES');
        break;
      case 3:
        this.dataToSendGeneral.expirationDate = '';
        break;
    }
    this.modalRef.hide();
  }
  validateNumberYears() {
    if (this.yearsValidity < 0) {
      this.yearsValidity = 2;
      this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER NEGATIVO, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
      return;
    }
    if (this.yearsValidity === 1) {
      this.toastr.warning('EL NÚMERO MÍNIMO PERMITIDO PARA LA OPCIÓN MULTI-ANUAL, ES 2', 'NOTIFICACIÓN');
      this.yearsValidity = 2;
      return;
    }
    if (this.yearsValidity === 0) {
      this.yearsValidity = 2;
      this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER 0, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
      return;
    }
  }
  onChangeRenewPolicy(event, template: TemplateRef<any>) {
    this.isRenewPolicy = event;
    this.dataToSendGeneral.renew = event;
    this.policyIdExpired = 0;
    if (!this.shared.fPolicyDataReadonly) {
      if (this.isRenewPolicy) {
        if (this.dataToSendGeneral.startDate === undefined || this.dataToSendGeneral.groupId === 0
          || this.dataToSendGeneral.clients.length === 0 || this.dataToSendGeneral.subBranchId === 0) {
          this.toastr.warning('DEBE DE CAPTURAR LOS DATOS PREVIOS PARA PODER SELECCIONAR LA PÓLIZA A RENOVAR', 'NOTIFICACIÓN');
          this.dataToSendGeneral.renew = false;
          this.isRenewPolicy = false;
          this.policyForm.controls.renewPolicy.setValue(false);
          return;
        }
        const dateStart = Date.parse(this.dataToSendGeneral.startDate);
        const data = {
          groupId: this.dataToSendGeneral.groupId,
          clients: this.dataToSendGeneral.clients,
          subBranchId: this.dataToSendGeneral.subBranchId,
          startDate: this.datePipe.transform(dateStart, 'yyyy-MM-dd', 'es-ES')
        };
        this.getPoliciesExpired(data);
        this.modalRefRenew = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
      }
    }
  }
  getPoliciesExpired(dataRenew) {
    this.api.postExpiredPolicies(dataRenew).then((data: any) => {
      if (data == null) {
        this.dataExpired = [];
      } else {
        this.dataExpired = data;
      }
    }, error => {
    });
  }
  selectPolicy(policyExpiredId, event) {
    this.dataToSendGeneral.policyIdOld = policyExpiredId;
    this.dataExpired.forEach(item => {
      if (item.policyId === policyExpiredId) {
        this.dataToSendGeneral.policyNumberOld = item.policyNumber;
        return;
      }
    });

    if (event !== undefined && event !== null) {
      const textToCopy = String(event.policyId + '::' + event.number);
      this.copyText(textToCopy);
    }
  }
  saveRelationRenew() {
    if (this.dataToSendGeneral.policyIdOld === 0) {
      this.toastr.warning('DEBE DE SELECCIONAR UNA PÓLIZA', 'NOTIFICACIÓN');
      return;
    } else {
      this.modalRefRenew.hide();
    }
  }
  closeModalRenew() {
    this.modalRefRenew.hide();
    this.policyForm.controls.renewPolicy.setValue(false);
  }
  getManagerIncident() {
    this.api.getManagerIncident().then((data: any) => {
      if (data == null) {
        this.catManagerIncidentList = [];
      } else {
        this.catManagerIncidentList = data;
        // this.dataToSendGeneral.managerIncident = this.catManagerIncidentList[0].userId;
      }
    }, error => {
    });
  }
  invokeServiceGetMedicalCareByInsuranceBranchId() {
    this.api.getConfigurationMedicalCareByInsuranceSubBranch(this.dataToSendGeneral.subBranchId, this.dataToSendGeneral.insuranceId)
      .then((data: any) => {
        this.medicalCare = data.medicalCare;
      }, error => {
      });
  }


  invokeServiceLoadEndorsement() {
    console.log(this.endorsementId);
    if (this.endorsementId > 0) {
      this.endorsementList.forEach( item => {
        if (item.endorsementId === this.endorsementId) {
          this.editData = item.edit;
        }
      });
      this.shared.gPolicyIdEndorsement.next(this.endorsementId);
      $('#smartwizard').smartWizard('goToStep', 3);
    }
  }

  invokeServiceGetEndorsementByPolicy(policy) {
    const obj = {
      policyId: policy
    };
    this.api.getEndorsementsByPolicy(obj).then((data: any) => {
      this.endorsementList = data;
      this.endorsementId = this.endorsementList[this.endorsementList.length -  1].endorsementId;
      this.editData = this.endorsementList[this.endorsementList.length - 1].edit;
      setTimeout(() => {
        this.shared.gPolicyIdEndorsement.next(this.endorsementId);
      }, 1000);
    }, error => {
    });
  }
  getTypesAdminPolicy() {
    this.catTypeAdmin = [
      {
        typeAdminId: 1,
        name: 'AUTO ADMINISTRADA'
      },
      {
        typeAdminId: 2,
        name: 'A DETALLE'
      }

    ];
  }
  invokeServiceGetPolicyOld(id: number) {
    console.log(id);
    this.shared.fPolicyDataReadonly = true;
    if (this.shared.gPolicyId.getValue() !== id) {
      this.shared.gPolicyId.next(id);
    }
  }
  /*--+---------------------------------------------------------------------------------+
  |----------------------------| C A T E G O R I A |----------------------------|
  +---------------------------------------------------------------------------------+--*/
  getClientsPolicy(id) {
    this.api.getClientsPolicy(id).then((data: any) => {
      this.catClientsPolicy = data;
      this.clientId = this.catClientsPolicy[0].clientId;
    }, error => {
    });
  }
  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypesPolicy.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  sendCategoryData() {
    this.spinner.show('sp');
    this.dataToSendCategory = {
      categoryId: 0,
      name: this.category,
      typeTax: this.flag,
      clientId: this.clientId
    };
    if (this.dataToSendCategory.name === undefined || this.dataToSendCategory.name === '') {
      this.toastr.info('POR FAVOR INGRESE UN NOMBRE DE CATEGORIA', 'Notificación');
      this.spinner.hide('sp');
      return;
    }
    if (this.flag === 1) {
      if (this.error.active === true || this.fileUpload === undefined) {
        this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
        this.spinner.hide('sp');
        return;
      }
    }
    this.api.postCategoryPolicyData(this.dataToSendCategory, localStorage.getItem('policy-id'))
      .then(
        (response: any) => {
          this.categoryId = response.categoryId;
          this.category = undefined;
          this.getCategoriesPolicy();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          if (this.flag === 1) {
            const formData = new FormData();
            formData.append('file', this.fileUpload);
            this.api.postLayoutTariff(formData, this.categoryId)
              .subscribe(
                response1 => {
                  this.fileUpload = undefined;
                  (document.getElementById('fileCategory') as HTMLInputElement).value = null;
                  this.spinner.hide('sp');
                }, error => {
                  this.toastr.warning(error.error);
                  this.spinner.hide('sp');
                }
              );
          } else {
            this.spinner.hide('sp');
          }
        }, error => {
          this.spinner.show('hide');
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }
  getCategoriesPolicyUpdatePopulation() {
    this.categoryList1 = [];
    this.api.getCategoriesPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.categoryList1 = data;
    }, error => {
    });
  }
  getCategoriesPolicy(carId?) {
    this.receiptList = [];
    this.categoryList = [];
    this.typeReceiptOption = null;
    this.api.getCollectionsByPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.categoryList = data;
      this.typeReceiptOption = this.categoryList[0];
      // en caso de a ver cobranza hace la petición
      if (this.categoryList[0].receipt > 0) {
        switch (this.dataToSendGeneral.typePaymentPolicy) {
          case 1:
            this.loadReceiptPolicyCategory(0);
            this.categoryId = 0;
            break;
          case 2:
            this.loadReceiptPolicyCategory(this.categoryList[0].clientId);
            this.categoryId = this.categoryList[0].clientId;
            break;
          case 3:
            this.loadReceiptPolicyCategory(this.categoryList[0].categoryId);
            this.categoryId = this.categoryList[0].categoryId;
            break;
          case 4:
            this.loadReceiptPolicyByCar(carId);
            break;
        }
      }
    }, error => {
    });
  }

  nextOperation(id) {
    switch (id) {
      case 0:
        this.bAttachment = true;
        setTimeout(() => {
          $('#smartwizardsimply').smartWizard('goToStep', 1);
          this.loadDateSmartWizard(1);
        }, 1000);
        break;
      case 1:
        this.bReceipt = true;
        this.receiptList = [];
        setTimeout(() => {
          $('#smartwizardsimply').smartWizard('goToStep', 2);
          this.loadDateSmartWizard(2);
        }, 1000);
        break;

      case 2:
        this.bReceipt = true;
        setTimeout(() => {
          $('#smartwizardsimply').smartWizard('goToStep', 3);
          this.loadDateSmartWizard(3);
        }, 1000);
        break;
    }
  }
  edit_category(row) {
    this.dataToSendCategory = row;
    document.getElementById('edit_category' + row.categoryId).style.display = 'none';
    document.getElementById('save_category' + row.categoryId).style.display = 'block';
    const name = document.getElementById('name' + row.categoryId);
    name.innerHTML = '<input type=\'text\' id=\'name_text' + row.categoryId + '\' value=\'' + row.name + '\'>';
  }
  save_category(id) {
    this.spinner.show('sp');
    this.dataToSendCategory = {
      categoryId: id,
      name: (document.getElementById('name_text' + id) as HTMLInputElement).value,
      typeTax: this.dataToSendCategory.typeTax
    };
    document.getElementById('edit_category' + id).style.display = 'block';
    document.getElementById('save_category' + id).style.display = 'none';
    this.api.putCategoryPolicyData(this.dataToSendCategory)
      .then(
        (response: any) => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.getCategoriesPolicy();
          this.spinner.hide('sp');
        }, error => {
          this.toastr.warning(error.error);
          // console.error();
          this.spinner.hide('sp');
        }
      );
  }
  getCoverages(id) {
    this.api.getCoverageSubbranch(id).then((data: any) => {
      this.coverageList = data;
    }, error => {
    });
  }

  /*--+---------------------------------------------------------------------------------+
  |----------------------------| A N E X O S |----------------------------|
  +---------------------------------------------------------------------------------+--*/
  changeSelectionRadio(option: number) {
    switch (option) {
      case 1:
        break;
      case 8:
        this.fileUploadAttachment = undefined;
        (document.getElementById('fileAttachment') as HTMLInputElement).value = null;
        this.dataToSendAttachment.name = '';
        break;
    }
  }
  onUploadChange(evt) {
    const file = evt.target.files[0];
    this.fileUploadAttachment = evt.target.files[0];
    this.dataToSendAttachment.name = this.fileUploadAttachment.name;
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoaded(e) {
    this.dataToSendAttachment.attachmentPath = btoa(e.target.result);
  }
  saveAttachment() {
    if (this.dataToSendGeneral.policyId === 0) {
      this.toastr.warning('NO SE PUEDE GUARDAR EL ANEXO SIN ANTES GUARDAR LA PÓLIZA', 'NOTIFICACIÓN');
      return;
    }
    this.spinner.show('sp');
    if (this.dataToSendAttachment.typeAttachmentId === 8 && this.dataToSendAttachment.name === undefined) {
      this.toastr.info('POR FAVOR, INGRESE URL DEL DIRECTORIO MEDICO', 'NOTIFICACIÓN');
      this.spinner.hide('sp');
      return;
    }
    if (this.dataToSendAttachment.typeAttachmentId === 1 && this.dataToSendAttachment.attachmentPath === undefined) {
      this.toastr.info('POR FAVOR, SELECCIONE UN ARCHIVO', 'NOTIFICACIÓN');
      this.spinner.hide('sp');
      return;
    }
    if (this.dataToSendAttachment.typeAttachmentId === 8) {
      this.dataToSendAttachment.attachmentPath = this.dataToSendAttachment.name;
      this.dataToSendAttachment.name = 'DIRECTORIO MEDICO';
    }
    this.api.postAttachmentData(this.dataToSendAttachment)
      .then(
        (response: any) => {
          this.getAttachmentPolicy();
          if (this.dataToSendAttachment.typeAttachmentId === 1) {
            this.fileUploadAttachment = undefined;
            (document.getElementById('fileAttachment') as HTMLInputElement).value = null;
          }
          this.dataToSendAttachment = {
            attachmentId: 0,
            name: undefined,
            attachmentPath: undefined,
            typeAttachmentId: 1,
            subBranchId: this.dataToSendGeneral.subBranchId,
            subBranch: undefined,
            relationId: localStorage.getItem('policy-id')
          };

          this.spinner.hide('sp');
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }
  getAttachmentPolicy() {
    this.api.getAttachmentPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      if (data != null) {
        this.policyAttachmentList = data;
      } else {
        this.policyAttachmentList = [];
      }
    }, error => {
      this.policyAttachmentList = [];
    });
  }
  public invokeServiceForGetAttachmentFile(attachment) {
    this.api.getAttachmentFilepolicy(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          // console.error(error);
        }
      );
  }
  deleteAttachment(attanchment) {
    this.spinner.show('sp');
    this.api.deleteAttachment(attanchment.attachmentId).subscribe(response => {
      this.getAttachmentPolicy();
      this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
      this.api.deletePolicyAttachmentRelation(attanchment.attachmentId).subscribe(response1 => {
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
      });
    }, error => {
      this.spinner.hide('sp');
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }
  showDocumentViewer(item, template: TemplateRef<any>) {
    this.spinner.show('sp');
    this.base64File2 = '';
    this.api.getAttachmentByViewer(item.attachmentId).then((data: any) => {
      const res = data;
      if (res.b64 === 'no') {
        this.spinner.hide('sp');
        this.toastr.warning('ESTE DOCUMENTO NO TIENE VISTA PREVIA', 'NOTIFICACION');
      } else {
        this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
        setTimeout(() => {
          this.base64File2 = res.b64;
          this.spinner.hide('sp');
        }, 1000);
      }
    }, error => {
      this.spinner.hide('sp');
    });

  }
  cleanSpacesString(cadena: string) {
    const cleanString = cadena.replace(/ /g, '').trim();
    this.dataToSendAttachment.name = cleanString;

  }
  /*--+---------------------------------------------------------------------------------+
|----------------------------| C O B R A N Z A |----------------------------|
+---------------------------------------------------------------------------------+--*/
  getTypePaymentsPolicy() {
    this.api.getTypePaymentsPolicy().then((data: any) => {
      this.catTypePayments = data;

      let index = -1;

      for (let i = 0; i < this.catTypePayments.length; i++) {
        if (this.catTypePayments[i].description === 'POR AUTO FLOTILLA') { // search for the object by description
          index = i;
          break;
        }
      }

      if (index !== -1) { // if the object is found
        this.catTypePayments.splice(index, 1); // remove the object using splice
      }

      let indexcat = -1;

      for (let i = 0; i < this.catTypePayments.length; i++) {
        if (this.catTypePayments[i].description === 'POR CATEGORÍA') { // search for the object by description
          indexcat = i;
          break;
        }
      }

      if (indexcat !== -1) { // if the object is found
        this.catTypePayments.splice(indexcat, 1); // remove the object using splice
      }

    }, error => {
    });
  }
  getTypePayments() {
    this.api.getTypePayments().then((data: any) => {
      this.catPaymentTypes = data;
    }, error => {
    });
  }
  showReceipt(c) {
    localStorage.removeItem('policy-branchId');
    localStorage.removeItem('policy-subbranchId');
    localStorage.setItem('policy-branchId', this.dataToSendGeneral.branchId);
    localStorage.setItem('policy-subbranchId', this.dataToSendGeneral.subBranchId);
    let data;
    this.bReceiptC = c.receipt;
    switch (this.dataToSendGeneral.typePaymentPolicy) {
      case 1:
        this.categoryName = c.number; // Número póliza
        this.categoryId = 0;

        break;
      case 2:
        this.categoryName = c.client; // por subgrupo
        this.categoryId = c.clientId;
        break;
      case 3:
        this.categoryName = c.client + '-' + c.category;
        this.categoryId = c.categoryId; // por categoria
        break;
      case 4:
        this.categoryName = c.number; // Número póliza
        this.categoryId = 0;
        break;
    }
    this.dataToSendReceipt.categoryId = this.categoryId;
    if (this.bReceiptC > 0 && this.dataToSendGeneral.typePaymentPolicy !== 4) {
      this.loadReceiptPolicyCategory(this.categoryId);
    } else {
      this.receiptList = [];
      if (this.dataToSendGeneral.subBranchId === 10
        || this.dataToSendGeneral.subBranchId === 6
        || this.dataToSendGeneral.subBranchId === 13) {
        this.dataToSendReceipt.taxId = 3;
      }
    }
    this.invokeServiceGetConfigurationRightInsurance();
  }
  invokeDataAcumulativeFlotilla(data) {
    this.api.getDataAcumulativeFlotilla(data).then(
      (response: any) => {
        this.dataToSendReceipt.netPremium = response.netPremium;
        this.dataToSendReceipt.rights = response.rights;
        this.dataToSendReceipt.paymentCharges = response.paymentCharges;
        this.dataToSendReceipt.paymentType = this.dataToSendGeneral.paymentTypeId;
        this.dataToSendReceipt.commissionAmount = response.commissionAmount;
        this.dataToSendReceipt.commission = response.commission;
        this.catTaxes.map(taxes => {
          if (taxes.taxValue ===  response.ivaPercent) {
            this.dataToSendReceipt.taxId = taxes.taxId;
          }
        });
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
      }, error => {
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
  }
  generateReceipt() {
    this.dataToSendGeneral.categoryId = this.categoryId;
    if (this.dataToSendGeneral.typePaymentPolicy > 1 && this.dataToSendReceipt.categoryId === 0 && this.categoryId === 0) {
      this.toastr.warning('POR FAVOR SELECCIONE DE NUEVO LA CATEGORIA A LA CUAL SE AGREGARA COBRANZA', 'NOTIFICACIÓN');
      return;
    }
    if (this.dataToSendReceipt.netPremium === 0) {
      this.toastr.info('LA PRIMA NETA DEBE DE SER MAYOR A CERO PARA LA GENERACIÓN DE RECIBOS.', 'NOTIFICACIÓN');
      return;
    }
    this.spinner.show('sp');
    this.api.postReceiptData(this.dataToSendReceipt)
      .then(
        (response: any) => {
          this.receiptList = response;
          this.getCategoriesPolicy();
          this.loadDataToSendReceipt();
          this.spinner.hide('sp');
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }
  loadDataToSendReceipt() {
    this.dataToSendReceipt = {
      receiptId: 1,
      commission: 0,
      commissionAmount: 0,
      netPremium: 0,
      rights: 0,
      paymentCharges: 0,
      iva: 0,
      totalPremium: 0,
      paymentDate: '',
      paymentMonth: 0,
      policyId: localStorage.getItem('policy-id'),
      receiptStatusId: 1,
      paymentFormId: 0,
      paymentType: this.dataToSendGeneral.paymentTypeId,
      categoryId: 0,
      taxId: 0
    };
  }
  loadDataToSendReceiptflotilla(amount) {
    this.dataToSendReceipt = {
      receiptId: amount.receiptId,
      commission: amount.commission,
      commissionAmount: amount.commissionAmount,
      netPremium: amount.netPremium,
      rights: amount.rights,
      paymentCharges: amount.paymentCharges,
      iva: amount.iva,
      totalPremium: amount.totalPremium,
      paymentDate: '',
      paymentMonth: amount.paymentMonth,
      policyId: localStorage.getItem('policy-id'),
      receiptStatusId: 1,
      paymentFormId: amount.paymentFormId,
      paymentType: this.dataToSendGeneral.paymentTypeId,
      categoryId: amount.categoryId,
      taxId: amount.taxId,
      carId: amount.carId
    };

    this.calChargesPercentage();
  }

  invokeFunctionFormatValue(valor, decimal): number {
    // si no es un número devuelve el valor, o lo convierte a número con N decimales
    valor = parseFloat(valor);
    return isNaN(valor) ? valor : Number(valor.toFixed(decimal));
  }
  sumTotalPremium() {
    if (this.dataToSendReceipt.taxId > 0) {
      this.reCalTax();
    }
    this.calCommissionAmount();

    if (this.chargesPercentage === 0 && this.dataToSendReceipt.paymentCharges > 0) {
      this.calChargesPercentage();
    }
    // tslint:disable-next-line:max-line-length
    this.dataToSendReceipt.totalPremium = this.dataToSendReceipt.netPremium + this.dataToSendReceipt.rights + this.dataToSendReceipt.paymentCharges + this.dataToSendReceipt.iva;
    this.dataToSendReceipt.totalPremium = this.invokeFunctionFormatValue(this.dataToSendReceipt.totalPremium, 2);
  }
  loadDataTaxes() {
    this.api.getTaxes().then((data: any) => {
      this.catTaxes = data;
      if (this.dataToSendGeneral.subBranchId === 10 ||
        this.dataToSendGeneral.subBranchId === 6) {
        if (this.catTaxes !== undefined) {
          this.catTaxes.forEach(item => {
            if (item.value === 0) {
              this.dataToSendReceipt.taxId = item.taxId;
            }
          });
        }
      }
    }, error => {
    });
  }
  calTax(i) {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(i)) {
        // tslint:disable-next-line:max-line-length
        this.dataToSendReceipt.iva = ((this.dataToSendReceipt.netPremium + this.dataToSendReceipt.rights + this.dataToSendReceipt.paymentCharges) * tax.taxValue) / 100;
        this.dataToSendReceipt.iva = this.invokeFunctionFormatValue(this.dataToSendReceipt.iva, 2);
        this.sumTotalPremium();
      }
    }
  }
  reCalTax() {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(this.dataToSendReceipt.taxId)) {
        // ('entra a recalculo');
        // tslint:disable-next-line:max-line-length
        this.dataToSendReceipt.iva = ((this.dataToSendReceipt.netPremium + this.dataToSendReceipt.rights + this.dataToSendReceipt.paymentCharges) * tax.taxValue) / 100;
        this.dataToSendReceipt.iva = this.invokeFunctionFormatValue(this.dataToSendReceipt.iva, 2);
      }
    }
  }
  renewReceipt() {
    this.spinner.show('sp');
    this.bReceiptC = 0;
    this.receiptList = [];
    this.api.deleteReceiptsPolicyCategory(this.categoryId, localStorage.getItem('policy-id')).subscribe(response => {
      this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
      this.getCategoriesPolicy();
      this.invokeServiceGetConfigurationRightInsurance();
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }
  renewReceiptByCar(carId) {
    this.spinner.show('sp');
    this.bReceiptC = 0;
    this.receiptList = [];
    this.api.deleteReceiptsPolicyCar(carId, localStorage.getItem('policy-id')).subscribe(response => {
      this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
      this.getCategoriesPolicy(carId);
      this.invokeServiceGetConfigurationRightInsurance();
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }
  receiptModal(r) {
    localStorage.setItem('receipt-id', r.receiptId);
  }
  loadReceiptPolicyCategory(categoryId) {
    this.receiptList = [];
    this.isSplitRights = false;
    this.getPaymentForms();
    this.getStatusReceipts();
    this.api.getReceiptPolicyCategory(localStorage.getItem('policy-id'), categoryId).then((data: any) => {
      this.receiptList = data.receiptList;
      this.receiptTotal = data.policyAmount;
      this.policyAmount = data.policyAmount;
      if (this.receiptList.length > 1) {
        if (this.receiptList[0].rights > 0 && this.receiptList[1].rights <= 0) {
          this.isSplitRights = true;
        }
      }
    }, error => {
      console.error('desde policy: ', error.message);
    });
  }
  loadReceiptPolicyByCar(carId) {
    this.receiptList = [];
    this.isSplitRightsByCar = false;
    this.api.getReceiptPolicyCar(localStorage.getItem('policy-id'), carId).then((data: any) => {
      this.receiptList = data;
      if (this.receiptList.length > 1) {
        if (this.receiptList[0].rights > 0 && this.receiptList[1].rights <= 0) {
          this.isSplitRightsByCar = true;
        }
      }
    }, error => {
      console.error('desde policy: ', error.message);
    });
  }

  edit_row(row) {
    this.dataReceiptEdith = row;
    document.getElementById('edit_button' + row.receiptId).style.display = 'none';
    document.getElementById('save_button' + row.receiptId).style.display = 'block';
    const paymentDate = document.getElementById('paymentDate' + row.receiptId);
    const netPremium = document.getElementById('netPremium' + row.receiptId);
    const rights = document.getElementById('rights' + row.receiptId);
    const paymentCharges = document.getElementById('paymentCharges' + row.receiptId);
    const iva = document.getElementById('iva' + row.receiptId);
    const totalPremium = document.getElementById('totalPremium' + row.receiptId);
    paymentDate.innerHTML = '<input type=\'text\' id=\'paymentDate_text' + row.receiptId + '\' value=\'' + row.paymentDate + '\'>';
    netPremium.innerHTML = '<input type=\'number\' id=\'netPremium_number' + row.receiptId + '\' value=\'' + row.netPremium + '\'>';
    rights.innerHTML = '<input type=\'number\' id=\'rights_number' + row.receiptId + '\' value=\'' + row.rights + '\'>';
    // tslint:disable-next-line:max-line-length
    paymentCharges.innerHTML = '<input type=\'number\' id=\'paymentCharges_number' + row.receiptId + '\' value=\'' + row.paymentCharges + '\'>';
    iva.innerHTML = '<input type=\'number\' id=\'iva_number' + row.receiptId + '\' value=\'' + row.iva + '\'>';
    totalPremium.innerHTML = '<input type=\'number\' id=\'totalPremium_number' + row.receiptId + '\' value=\'' + row.totalPremium + '\'>';
  }

  save_row(id) {
    this.dataReceiptEdith = {
      receiptId: id,
      commission: this.dataReceiptEdith.commission,
      netPremium: (document.getElementById('netPremium_number' + id) as HTMLInputElement).value,
      rights: (document.getElementById('rights_number' + id) as HTMLInputElement).value,
      paymentCharges: (document.getElementById('paymentCharges_number' + id) as HTMLInputElement).value,
      iva: (document.getElementById('iva_number' + id) as HTMLInputElement).value,
      totalPremium: (document.getElementById('totalPremium_number' + id) as HTMLInputElement).value,
      paymentDate: (document.getElementById('paymentDate_text' + id) as HTMLInputElement).value,
      paymentMonth: 0,
      receiptStatusId: 1,
      paymentFormId: 0
    };
    // console.log(this.dataReceiptEdith);
    document.getElementById('edit_button' + id).style.display = 'block';
    document.getElementById('save_button' + id).style.display = 'none';
    this.api.putReceiptData(this.dataReceiptEdith)
      .then(
        (response: any) => {
          this.loadReceiptPolicyCategory(this.categoryId);
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }

  /****
   * edit receipt
   */
  closeModal() {
    this.modalRef.hide();
  }
  openMdlAttachment(template: TemplateRef<any>, receipt) {
    this.getPaymentForms();
    this.getStatusReceipts();
    this.editReceiptMdl(receipt);
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }
  updateReceipt() {
    this.spinner.show('sp');
    if (this.receiptData.paymentDate === 'Invalid Date') {
      this.spinner.hide('sp');
      this.toastr.info('INGRESE UNA FECHA VALIDA', 'Notificación');
      return;
    }
    const paymentDate = Date.parse(this.receiptData.paymentDate);
    this.receiptData.paymentDate = this.datePipe.transform(paymentDate, 'yyyy-MM-dd');
    const deadLinePayment = Date.parse(this.receiptData.paymentDeadline);
    this.receiptData.paymentDeadline = this.datePipe.transform(deadLinePayment, 'yyyy-MM-dd');
    this.api.putReceiptData(this.receiptData)
      .then(
        (response: any) => {
          this.loadReceiptPolicyCategory(this.categoryId);
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.spinner.hide('sp');
          this.modalRef.hide();
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  editReceiptMdl(r) {
    this.spinner.show('sp');
    this.api.getReceiptById(r.receiptId)
      .then(
        (response: any) => {
          this.receiptData = {
            receiptId: response[0].receiptId,
            commission: response[0].commission,
            commissionAmount: response[0].commissionAmount,
            netPremium: response[0].netPremium,
            rights: response[0].rights,
            paymentCharges: response[0].paymentCharges,
            iva: response[0].iva,
            totalPremium: response[0].totalPremium,
            paymentDate: this.datePipe.transform(response[0].paymentDate, 'yyyy/MM/dd'),
            paymentMonth: 0,
            policyId: Number(localStorage.getItem('policy-id')),
            receiptStatusId: response[0].receiptStatusId,
            paymentFormId: response[0].paymentFormId,
            taxId: response[0].taxId,
            settlementNumber: response[0].settlementNumber,
            numberReceipt: response[0].numberReceipt,
            paymentDeadline: response[0].paymentDeadline
          };
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  getStatusReceipts() {
    this.api.getStatusReceipts().then((data: any) => {
      this.statusList = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }
  getPaymentForms() {
    this.api.getPaymentForms().then((data: any) => {
      this.paymentList = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }
  calTotal() {
    // tslint:disable-next-line:max-line-length
    this.receiptData.iva = ((this.receiptData.netPremium + this.receiptData.rights + this.receiptData.paymentCharges) * this.receiptData.taxId) / 100;
    // tslint:disable-next-line:max-line-length
    this.receiptData.totalPremium = this.receiptData.netPremium + this.receiptData.rights + this.receiptData.paymentCharges + this.receiptData.iva;
  }
  calCommissionPercentage() {
    const commission = (this.dataToSendReceipt.commissionAmount * 100) / this.dataToSendReceipt.netPremium;
    this.dataToSendReceipt.commission = this.invokeFunctionFormatValue(commission, 2);
  }
  calCommissionAmount() {
    const commissionAmount = (this.dataToSendReceipt.netPremium * this.dataToSendReceipt.commission) / 100;
    this.dataToSendReceipt.commissionAmount = this.invokeFunctionFormatValue(commissionAmount, 2);
  }
  calChargesPercentage() {
    const chargesPercentageTmp = (this.dataToSendReceipt.paymentCharges * 100) / this.dataToSendReceipt.netPremium;
    this.chargesPercentage = chargesPercentageTmp;
    this.chargesPercentage = this.invokeFunctionFormatValue(this.chargesPercentage, 2);
  }
  calChargesAmount() {
    if (this.dataToSendReceipt.paymentCharges === 0) {
      const chargesAmount = (this.dataToSendReceipt.netPremium * this.chargesPercentage) / 100;
      this.dataToSendReceipt.paymentCharges = chargesAmount;
      this.dataToSendReceipt.paymentCharges = this.invokeFunctionFormatValue(chargesAmount, 2);
    }
  }
  splitRights(template: TemplateRef<any>, carid?) {
    this.carId = carid;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
  }
  sendSplitRights() {
    this.spinner.show('sp');
    this.api.getSplitRights(localStorage.getItem('policy-id'), this.categoryId).then((data: any) => {
      if (this.dataToSendGeneral.subBranchId !== 21) {
        this.loadReceiptPolicyCategory(this.categoryId);
      }
      if (this.dataToSendGeneral.subBranchId === 10 || this.dataToSendGeneral.subBranchId === 6) {
        this.dataToSendReceipt.taxId = 3;
      }
      this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
      this.spinner.hide('sp');
      this.modalRef.hide();
    }, error => {
      this.toastr.error('Su solicitud no se ha realizado de forma exitosa, comuniquese con el Administrador', 'Notificación');
      this.modalRef.hide();
      this.spinner.hide('sp');
    });
  }
  downloadReceipsByCarId(carId) {
    this.spinner.show('sp');
    this.api.downloadReceiptByCarId(carId).then((response: any) => {
      if (response == null) {
        this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
        this.spinner.hide('sp');
        return;
      } else {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download',  'RECEIPTS.xlsx');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      }
      this.spinner.hide('sp');
    }, error => {
      this.toastr.error('Su solicitud no se ha realizado de forma exitosa, comuniquese con el Administrador', 'Notificación');
      this.spinner.hide('sp');
    });
  }
  downloadReceipsByPolicyId() {
    this.spinner.show('sp');
    this.api.downloadReceiptByPolicyId(localStorage.getItem('policy-id')).then((response: any) => {
      if (response == null) {
        this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
        this.spinner.hide('sp');
        return;
      } else {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download',  'RECEIPTS.xlsx');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      }
      this.spinner.hide('sp');
    }, error => {
      this.toastr.error('Su solicitud no se ha realizado de forma exitosa, comuniquese con el Administrador', 'Notificación');
      this.spinner.hide('sp');
    });
  }
  sendSplitRightsByCar() {
    this.spinner.show('sp');
    this.api.getSplitRightsCarId(localStorage.getItem('policy-id'), this.carId).then((data: any) => {
      this.loadReceiptPolicyByCar(this.carId);
      this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
      this.spinner.hide('sp');
      this.modalRef.hide();
    }, error => {
      this.toastr.error('Su solicitud no se ha realizado de forma exitosa, comuniquese con el Administrador', 'Notificación');
      this.modalRef.hide();
      this.spinner.hide('sp');
    });
  }
  invokeServiceGetConfigurationRightInsurance() {
    this.spinner.show('sp');
    this.api.getConfigurationRightsByInsuranceSubBranch(this.dataToSendGeneral.subBranchId, this.dataToSendGeneral.insuranceId)
      .then((data: any) => {
        if (data != null) {
          this.dataToSendReceipt.receiptId = data.configurationRightId;
        } else {
          this.dataToSendReceipt.receiptId = 1;
        }
        this.spinner.hide('sp');
      }, error => {
        this.toastr.error('Su solicitud no se ha realizado de forma exitosa, comuniquese con el Administrador', 'Notificación');
        this.spinner.hide('sp');
      });
  }

  /*--+---------------------------------------------------------------------------------+
  |----------------------------| F U N C I O N E S G E N E R A L E S|-------------------|
  +---------------------------------------------------------------------------------+--*/

  updatePopulation(template: TemplateRef<any>) {
    this.getCategoriesPolicyUpdatePopulation();
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }

  sendEmailCertificate() {
    this.api.sendMail(localStorage.getItem('policy-id'))
      .then(
        (data) => {
          this.spinner.hide('sp');
          this.toastr.success('Se han enviado las notificaciones a los usuarios', 'Notificación');
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        }
      );
  }
  processPopulation() {
    this.spinner.show('sp');
    if (this.fileUploadXlsx === undefined || (this.fileUploadZip === undefined && this.fileUploadRar === undefined)) {
      this.toastr.info('DEBE DE INGRESAR LOS ARCHIVOS SOLICITADOS', 'NOTIFICACION');
      this.spinner.hide('sp');
      return;
    }
    const formDataXlsx = new FormData();
    formDataXlsx.append('fileXlsx', this.fileUploadXlsx);
    if (this.fileUploadZip !== undefined ) {
      formDataXlsx.append('fileCompressed', this.fileUploadZip);
    } else {
      formDataXlsx.append('fileCompressed', this.fileUploadRar);
    }
    this.api.postImportInsuredPolicySimply(formDataXlsx, this.dataToSendGeneral.groupId, Number(localStorage.getItem('policy-id')))
      .subscribe(
        responseInsured => {
          this.toastr.success('El proceso de importación ha iniciado en cuanto termine se le notificara.', 'Notificación');
          this.spinner.hide('sp');
          this.modalRef.hide();
        }, error => {
          this.toastr.warning(error.error);
          this.spinner.hide('sp');
        }
      );
  }
  onChangeInputFileXlsx(e) {
    this.errorXlsx = {active: false, msg: ''};
    this.fileUploadXlsx = e.target.files[0];
    // console.log(this.fileUploadXlsx);
    if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
      this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  onChangeInputFileZip(e) {
    this.errorZip = {active: false, msg: ''};

    if (0 > this.fileTypesZip.indexOf(e.target.files[0].type) && 0 > this.fileTypesRar.indexOf(e.target.files[0].type)) {
      this.errorZip = {active: true, msg: 'Debe seleccionar un archivo válido'};
    } else {
      if (this.fileTypesZip.indexOf(e.target.files[0].type) > 0) {
        this.fileUploadZip = e.target.files[0];
      } else {
        this.fileUploadRar = e.target.files[0];
      }
    }
  }
  downloadLayout(id) {
    this.spinner.show('sp');
    this.api.getLayoutFile(id)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            this.spinner.hide('sp');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (id === 1) {
              downloadLink.setAttribute('download',  'LAYOUT_ASEGURADOS.xlsx');
            } else if (id === 25) {
              downloadLink.setAttribute('download',  'LAYOUT_ASEGURADOS_SIMPLE.xlsx');
            } else {
              downloadLink.setAttribute('download',  'LAYOUT_TARIFAS.xlsx');
            }
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  renewalsPolicy() {
    this.spinner.show('sp');
    this.api.postRenewalsPolicy(localStorage.getItem('policy-id'))
      .then(
        (data) => {
          this.policyRenew = data;
          // console.log(this.policyRenew);
          this.spinner.hide('sp');
          this.toastr.success('Se ha generado el documento', 'Notificación');
          this.shared.gPolicyId.next(this.policyRenew.policeId);
          setTimeout(() => {
            if (this.staticTabs !== undefined) {
              this.staticTabs.tabs[0].active = true;
            }
          }, 1000);
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        }
      );
  }

  cancelPolicy() {
    this.spinner.show('sp');
    this.dataToSendCancelPolicy = {
      policyId: localStorage.getItem('policy-id'),
      statusId: 0,
      number: ''
    };
    this.api.putChangeStatusPolicy(this.dataToSendCancelPolicy)
      .then(
        (data) => {
          this.toastr.success('Su información ha sido actualizada correctamente.', 'Notificación');
          this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
          this.spinner.hide('sp');
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        }
      );
  }

  finishPolicy(id: number) {
    this.spinner.show('sp');
    this.dataToSendCancelPolicy = {
      policyId: localStorage.getItem('policy-id'),
      statusId: id,
      number: '',
      cancelationId: 0
    };
    this.api.putChangeStatusPolicy(this.dataToSendCancelPolicy)
      .then(
        (data) => {
          this.toastr.success('Su información ha sido actualizada correctamente.', 'Notificación');
          this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
          this.spinner.hide('sp');
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        }
      );
  }

  onManagementTabSelect(data: TabDirective): void {
    this.lclSectionSelected = Number(data.id);
    switch (this.lclSectionSelected) {
      case 0:
        // console.info('general ' + this.lclSectionSelected);
        break;
      case 1:
        this.dataToSendAttachment = {
          attachmentId: 0,
          name: undefined,
          attachmentPath: undefined,
          typeAttachmentId: 1,
          subBranchId: this.dataToSendGeneral.subBranchId,
          subBranch: undefined,
          relationId: localStorage.getItem('policy-id')
        };
        // console.info('anexos ' + this.lclSectionSelected);
        this.getAttachmentPolicy();
        break;
      case 2:
        this.loadDataToSendReceipt();
        this.getCategoriesPolicy();
        this.loadDataTaxes();
        // console.info('cobranza ' + this.lclSectionSelected);
        break;
    }
  }

  newDocument() {
    this.shared.fPolicyDataReadonly = false;
    this.shared.gPolicyId.next(0);
    setTimeout(() => {
      $('#smartwizardsimply').smartWizard('goToStep', 0);
    }, 1000);
  }

  loadDateSmartWizard(id) {
    switch (id) {
      case 0:
        if (this.shared.fPolicyDataReadonly) {
          this.getPolicy();
        }
        this.shared.gPolicyIdCategory.next(Number(localStorage.getItem('policy-id')));
        break;
      case 1:
        this.dataToSendAttachment = {
          attachmentId: 0,
          name: undefined,
          attachmentPath: undefined,
          typeAttachmentId: 1,
          subBranchId: this.dataToSendGeneral.subBranchId,
          subBranch: undefined,
          relationId: localStorage.getItem('policy-id')
        };
        // console.info('anexos ' + this.lclSectionSelected);
        this.getAttachmentPolicy();
        break;
      case 2:
        // localStorage.setItem('policy-id', String(29));
        this.categoryList = [];
        this.receiptList = [];
        this.loadDataToSendReceipt();
        this.getCategoriesPolicy();
        this.loadDataTaxes();
        // console.info('cobranza ' + this.lclSectionSelected);
        break;
    }
  }


  initialJsonGeneric() {
    this.sumAssuredGen = null;
    this.percentajeGen = null;
    this.typeMonedaGen = null;
    this.deducibleTypeIdGen = null;
    this.minMaxIdGen = null;
    this.typeMonedaIdGen = null;

  }


  /*--------------------------------------------------------------------------------------*/

  /**
   * funcion que guarda una cobertura
   */
  validateSubbranchFlotilla() {

    if (this.dataToSendGeneral.subBranchId !== 21 && this.dataToSendGeneral.typePaymentPolicy !== undefined
      && this.dataToSendGeneral.typePaymentPolicy === 4) {
      this.dataToSendGeneral.subBranchId = undefined;
      this.policyForm.controls.subBranch.setValue(undefined);
      this.toastr.warning('El tipo de cobranza por Auto Flotilla, solo aplica al subramo Auto flotilla', 'Notificación');
    }

    if (this.dataToSendGeneral.branchId !== undefined && this.dataToSendGeneral.branchId === 3 &&
      (this.dataToSendGeneral.typePaymentPolicy === 2 || this.dataToSendGeneral.typePaymentPolicy === 3)) {
      this.dataToSendGeneral.typePaymentPolicy = undefined;
      this.policyForm.controls.collection.setValue(undefined);
      this.toastr.warning('Para el ramo Autos, solo aplica el tipo de pago por Póliza', 'Notificación');
    }
    this.invokeServiceGetMedicalCareByInsuranceBranchId();
  }

  openModalAddClient(template: TemplateRef<any>) {
    localStorage.removeItem('typePerson');
    localStorage.removeItem('client-id');
    localStorage.removeItem('selectTypeContact');
    localStorage.removeItem('isEditClient');
    localStorage.removeItem('isView');
    localStorage.setItem('selectTypeContact', '3');
    localStorage.setItem('isEditClient', 'false');
    localStorage.setItem('isView', 'false');
    localStorage.setItem('isViewFromPolicie', 'true');
    // tslint:disable-next-line:max-line-length
    this.modalRefNewClient = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }

  closeModalNewClient() {
    this.modalRefNewClient.hide();
  }

  validatetypePyment() {
    if (this.dataToSendGeneral.typePaymentPolicy === 4 && this.dataToSendGeneral.subBranchId !== undefined
      && this.dataToSendGeneral.subBranchId !== 21) {
      this.dataToSendGeneral.typePaymentPolicy = undefined;
      this.policyForm.controls.collection.setValue(undefined);
      this.toastr.warning('El tipo de cobranza por Auto Flotilla, solo aplica al subramo Auto flotilla', 'Notificación');
    }

    if (this.dataToSendGeneral.branchId !== undefined && this.dataToSendGeneral.branchId === 3 &&
      (this.dataToSendGeneral.typePaymentPolicy === 2 || this.dataToSendGeneral.typePaymentPolicy === 3)) {
      this.dataToSendGeneral.typePaymentPolicy = undefined;
      this.policyForm.controls.collection.setValue(undefined);
      this.toastr.warning('Para el ramo Autos, solo aplica el tipo de pago por Póliza y por Autos Flotilla', 'Notificación');
    }
  }

  copyText(text: string) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (text));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  openModal(template: TemplateRef<any>) {
    this.spinner.show('sp');
    this.loadInitialModal();
    this.modalRef = this.modalService.show(template);
    this.validateIssuancePolicy();
    this.spinner.hide('sp');
  }

  SendPolicy() {
    console.log(this.fileUploadXlsx);
    if (this.dataToSendIssuePolicy.number === '' || this.fileUpload === undefined) {
      this.toastr.warning('PARA EMITIR LA PÓLIZA ES NECESARIO EL NUMERO Y EL ARCHIVO DE LA PÓLIZA');
      return;
    }
    this.spinner.show('sp');
    // console.log(this.dataToSendIssuePolicy);
    this.invokeChangeStatusPolicy();
  }
  invokeChangeStatusPolicy() {
    this.api.putChangeStatusPolicy(this.dataToSendIssuePolicy)
      .then(
        (data) => {
          this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
          const formData = new FormData();
          formData.append('file', this.fileUpload);
          this.api.postFilePolicy(formData, this.dataToSendIssuePolicy.policyId)
            .subscribe(
              response => {
                // mandar a home
                this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
                this.shared.gSearchId = 0;
                this.router.navigate(['/admin/executive']);
                this.shared.fUpdateHome.next(2);
                this.spinner.hide('sp');
                this.modalRef.hide();
              }, error => {
                this.toastr.warning(error.error);
                this.spinner.hide('sp');
                this.modalRef.hide();
              }
            );
        }, error => {
          this.modalRef.hide();
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  loadInitialModal() {
    this.importMassive = false;
    this.branchId = localStorage.getItem('policy-branchId');
    this.subbranchId = localStorage.getItem('policy-subbranchId');
    this.dataToSendIssuePolicy = {
      policyId: localStorage.getItem('policy-id'),
      statusId: 1,
      number: ''
    };
    this.fileUpload = undefined;
    this.fileUploadXlsx = undefined;
    this.fileUploadZip = undefined;
    this.getCategoriesPolicyForImport();
  }

  validateIssuancePolicy() {
    this.api.validationIssuancePolicySimple(this.dataToSendIssuePolicy.policyId).then((data: any) => {
      this.validationPolicy = data;
      console.log(this.validationPolicy);
    }, error => {

    });
  }

  getCategoriesPolicyForImport() {
    this.categoryList = [];
    this.api.getCategoriesPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.categoryList = data;
    }, error => {
    });
  }


  openModalSaveEndorsement(template: TemplateRef<any>) {
    if (this.dataToSendGeneral.status === 1 || this.dataToSendGeneral.status === 4) {
      this.modalSaveEndorsement = this.modalService.show(template, {class: 'modal-lg', keyboard: false, animated: true});
    } else {
      this.saveData(true);
    }
  }
  saveData(save) {
    this.invokeServiceGetEndorsementByPolicy(this.dataToSendGeneral.policyId);
  }
  closeModalEndorsement() {
    this.modalSaveEndorsement.hide();
    this.saveData(true);
  }

  calculateTotalPremium(receipt) {
    this.receiptList.forEach( item => {
      if (item.receiptId === receipt.receiptId) {
        item.iva = ((item.netPremium + item.rights + item.paymentCharges) * 16) / 100;
        item.iva = this.invokeFunctionFormatValue(item.iva,  2);
        item.totalPremium = item.netPremium + item.rights + item.paymentCharges + item.iva;
        item.totalPremium = this.invokeFunctionFormatValue(item.totalPremium, 2);
        return;
      }
    });
  }

  invokeServiceSaveReceipts() {
    this.spinner.show('sp');
    let validateData = true;
    let netPremiumTmp = 0;
    let rightsTmp = 0;
    let chargesTmp = 0;
    let ivaTmp = 0;
    let totalPremiumTmp = 0;
    // console.log('validacion de cobranza');
    this.receiptList.forEach(receipt => {
      if (receipt.paymentDate === null || receipt.paymentDeadline === null) {
        this.spinner.hide('sp');
        this.toastr.info('INGRESE UNA FECHA VALIDA', 'Notificación');
        validateData = false;
        return;
      }
      const paymentDate = Date.parse(receipt.paymentDate);
      receipt.paymentDate = this.datePipe.transform(paymentDate, 'yyyy-MM-dd');
      const deadLinePayment = Date.parse(receipt.paymentDeadline);
      receipt.paymentDeadline = this.datePipe.transform(deadLinePayment, 'yyyy-MM-dd');
      netPremiumTmp = netPremiumTmp + receipt.netPremium;
      rightsTmp = rightsTmp + receipt.rights;
      chargesTmp = chargesTmp + receipt.paymentCharges;
      ivaTmp = ivaTmp + receipt.iva;
      totalPremiumTmp = totalPremiumTmp + receipt.totalPremium;
      receipt.policyId = this.policyId;
    });
    if (!validateData) {
      return;
    }
    this.policyAmount = {
      netPremium: netPremiumTmp,
      rights: rightsTmp,
      charges: chargesTmp,
      iva: ivaTmp,
      totalPremium: totalPremiumTmp
    };
    // console.log(this.receiptList);
    // console.log(this.policyAmount);
    /*if (this.receiptTotal.netPremium !== this.policyAmount.netPremium ||
      this.receiptTotal.rights !== this.policyAmount.rights ||
      this.receiptTotal.charges !== this.policyAmount.charges ||
      this.receiptTotal.iva !== this.policyAmount.iva ||
      this.receiptTotal.totalPremium !== this.policyAmount.totalPremium ) {
      this.spinner.hide('sp');
      this.toastr.warning('VERIFIQUE LOS MONTOS', 'NOTIFICACIÓN');
      return;
    }*/
    this.api.putReceiptDataGeneral(this.receiptList)
      .then(
        (response: any) => {
          this.loadReceiptPolicyCategory(this.categoryId);
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACION');
          this.spinner.hide('sp');
        }, error => {
          console.error(error.status);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  invokeServiceValidateNumberPolicy() {
    this.spinner.show('sp');
    const obj = {
      number: this.dataToSendIssuePolicy.number,
      policyId: Number(localStorage.getItem('policy-id'))
    };
    this.api.getValidateNumberPolicy(obj).then((dataP: any) => {
      this.spinner.hide('sp');
      if (dataP.policyNumberExists > 0) {
        this.dataToSendIssuePolicy.number = '';
        this.toastr.warning('EL NUMERO DE PÓLIZA YA EXISTE, CAMBIELO E INTENTE DE NUEVO POR FAVOR', 'NOTIFICACIÓN');
        return;
      }
    }, error => {
      this.spinner.hide('sp');
    });
  }
}
