import {Component, OnInit, TemplateRef} from '@angular/core';
import {Placement} from '../admin-placement-quotation-detail/placement';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {NgxSpinnerService} from 'ngx-spinner';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FileUploadControl, FileUploadValidators} from '@iplab/ngx-file-upload';
import {SharedService} from '../shared-service.service';
import {Documents} from '../upload-file/documents';

@Component({
  selector: 'app-load-policy-automation-list',
  templateUrl: './load-policy-automation-list.component.html',
  styleUrls: ['./load-policy-automation-list.component.css']
})
export class LoadPolicyAutomationListComponent implements OnInit {


  automationList: any = [];
  // -Varibles para paginado de usuarios->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filters: any = {};
  placement: Placement;
  bsConfig: Partial<BsDatepickerConfig>;
  startDateTmp: string;
  endDateTmp: any;
  selectedConceptTypeValidity: number;
  yearsValidity: number;
  modalLoadAutomation: BsModalRef;
  formCreateProcess: FormGroup;
  isFileDragDropAvailable: true;
  catClients: any = [];
  catClientsSelected: any = [];
  catGroups: any = [];
  catInsuranceCompany: any = [];
  // Labels for each file input
  public fileLabels: any[] = [];
  public fileUploadControlMdl: FileUploadControl[] = [];

  multiple: true;
  uploadedFiles: File[] = [];
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private formBuilder: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService) {
    this.getGroups();
    this.getInsuranceCompanies();
  }

  ngOnInit() {

    this.formCreateProcess = this.formBuilder.group({
      groupId: ['', Validators.required],
      insuranceId: ['', Validators.required],
      clients: [[]],
      documents: [[]]
    });
    this.clearFiltersTable();
  }
  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    this.filters = {
      page: 1,
      size: 10
    };
    this.getByPagination(1, 10);
  }
  /**
   * Carga los datos según la página seleccionada
   */
  pageChangedSpecial(event: PageChangedEvent): void {
    console.log(event);
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }

  changeColorPagination() {
  }

  /**
   * Función para obtener las coberturas especiales del sistema
   */
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.api.getAutomationProcess(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.automationList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.automationList = this.paginationResponse.tList;
          // this.quotationList = response;
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.automationList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }


  openQuotationDetail(idPlacement) {
    this.router.navigate(['/admin/automation-detail/', idPlacement]);
  }


  public openModalAddNewProcess(template: TemplateRef<any>) {
    this.modalLoadAutomation = this.modalService.show(template, {class: 'modal-lg', keyboard: false,
      ignoreBackdropClick: true, backdrop: false});
  }

  uploadedFilesMdl(position?) {
    console.log(event);

    this.fileUploadControlMdl.forEach((control, index) => {
      if (control.value && index === position) {
        const files: File[] = control.value; // Obtener el array de archivos del control

        // Obtener el nombre a partir de la posición del archivo
        const fileLabel = this.fileLabels && this.fileLabels[position] ? this.fileLabels[position].name : null;

        if (fileLabel) {
          // Iterar sobre los archivos en el array
          const updatedFiles = files.map(file => {
            // Dividir el nombre del archivo en nombre y extensión
            const originalFileName = file.name;
            const dotIndex = originalFileName.lastIndexOf('.');
            const nameWithoutExtension = originalFileName.substring(0, dotIndex);
            const extension = originalFileName.substring(dotIndex);

            // Crear el nuevo nombre concatenando el nombre de la propiedad `name` antes de la extensión
            const newFileName = `${nameWithoutExtension}_${fileLabel}${extension}`;

            // Crear un nuevo archivo con el nuevo nombre
            return new File([file.slice(0, file.size)], newFileName, {
              type: file.type,
              lastModified: file.lastModified,
            });
          });

          // Actualizar el control con los archivos renombrados
          control.setValue(updatedFiles);
        }
      }
    });
    // Actualiza la lista de archivos subidos
    this.uploadedFiles = this.getUploadedFiles();
    this.validateFiles();

    this.fileUploadControlMdl.forEach(control => {
      if (control.invalid) {
        control.getError().forEach(ERROR => {
          if (ERROR.fileTypes !== undefined) {
            ERROR.fileTypes.forEach(FILE => {
              this.toastr.warning('Se quita ' + FILE.file.name + '. Formato no válido, solo PDF, JPEG, JPG y PNG', 'Notificación');
              control.removeFile(FILE.file);
            });
          }
        });
      }
    });
  }

// Método para obtener archivos subidos desde los controles
  getUploadedFiles(): any[] {
    let uploadedFiles = [];
    this.fileUploadControlMdl.forEach(control => {
      if (control.value) {
        uploadedFiles = uploadedFiles.concat(control.value);
      }
    });
    return uploadedFiles;
  }

  validateFiles() {
    let pdfCount = 0;
    let excelCount = 0;
    let zipCount = 0;
    let csvCount = 0;

    const uploadedFiles = this.getUploadedFiles();

    // Obtener las etiquetas de archivos basadas en insuranceId
    let maxPdf = 0;
    let maxExcel = 0;
    let maxZip = 0;
    let maxCsv = 0;

    let skipPdfValidation = false;
    let skipExcelValidation = false;
    let skipZipValidation = false;
    let skipCsvValidation = false;

    for (let i = 0; i < this.fileLabels.length; i++) {
      const label = this.fileLabels[i];
      const match = label.label.match(/\.(\w+)/);
      const fileType = match ? match[1].toLowerCase() : '';

      if (fileType === 'pdf') {
        if (!label.isSimple) {
          skipPdfValidation = true;
        } else {
          maxPdf++;
        }
      }

      if (fileType === 'xlsx' || fileType === 'xls') {
        if (!label.isSimple) {
          skipExcelValidation = true;
        } else {
          maxExcel++;
        }
      }

      if (fileType === 'csv') {
        if (!label.isSimple) {
          skipCsvValidation = true;
        } else {
          maxCsv++;
        }
      }

      if (fileType === 'zip') {
        if (!label.isSimple) {
          skipZipValidation = true;
        } else {
          maxZip++;
        }
      }
    }

    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];
      const fileType = file.name.split('.').pop().toLowerCase();
      if (fileType === 'pdf') {
        pdfCount++;
      } else if (fileType === 'xlsx' || fileType === 'xls') {
        excelCount++;
      } else if (fileType === 'zip') {
        zipCount++;
      } else if (fileType === 'csv') {
        csvCount++;
      }
    }

    if (!skipPdfValidation && pdfCount > maxPdf) {
      this.toastr.warning(`Solo se permiten ${maxPdf} archivos PDF`, 'Notificación');
      this.removeExcessFiles('pdf', pdfCount - maxPdf);
    }

    if (!skipExcelValidation && excelCount > maxExcel) {
      this.toastr.warning(`Solo se permiten ${maxExcel} archivos Excel`, 'Notificación');
      this.removeExcessFiles('xlsx', excelCount - maxExcel);
      this.removeExcessFiles('xls', excelCount - maxExcel);
    }

    if (!skipCsvValidation && csvCount > maxCsv) {
      this.toastr.warning(`Solo se permiten ${maxCsv} archivos Excel`, 'Notificación');
      this.removeExcessFiles('csv', excelCount - maxExcel);
    }

    if (!skipZipValidation && zipCount > maxZip) {
      this.toastr.warning(`Solo se permite ${maxZip} archivo ZIP`, 'Notificación');
      this.removeExcessFiles('zip', zipCount - maxZip);
    }
  }

  removeExcessFiles(fileType: string | null, excessCount: number) {
    let count = 0;

    for (const control of this.fileUploadControlMdl) {
      const files = control.value || [];

      for (let i = files.length - 1; i >= 0; i--) {
        const file = files[i];
        if (fileType === null || file.name.split('.').pop().toLowerCase() === fileType) {
          control.removeFile(file); // Asegúrate de que este método existe y funciona como se espera
          count++;
          if (count >= excessCount) {
            break;
          }
        }
      }

      if (count >= excessCount) {
        break;
      }
    }
  }



  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.catGroups = data;
    }, error => {
    });
  }
  getSubGroups(id) {
    this.api.getSubGroups(id).then((data: any) => {
      this.catClients = data;
      if (this.formCreateProcess.get('clients').value.length !== 0 && this.formCreateProcess.get('clients').value.length !== null
        && this.formCreateProcess.get('clients').value.length !== undefined) {
        this.onChangeSubgroup();
      }
    }, error => {
    });
  }

  onChangeSubgroup() {
    this.catClientsSelected = [];
    this.formCreateProcess.get('clients').value.forEach(clientSelected => {
      const foundClient = this.catClients.find(client => client.clientId === clientSelected);
      if (foundClient) {
        this.catClientsSelected.push(foundClient);
      }
    });
  }

  getInsuranceCompanies() {
    this.api.getInsuranceCompanies().then((data: any) => {
      this.catInsuranceCompany = data.filter(company =>
        company.insuranceId === 118 ||
        company.insuranceId === 8 ||
        company.insuranceId === 6 ||
        company.insuranceId === 11 );
    }, error => {
    });
  }

  invokeServiceUploadFilesMultiples() {
    this.spinner.show('sp');
    // console.log('total de archivos');
    const objDocument = [];

    // Validar que todos los controles tengan archivos seleccionados
    for (let i = 0; i < this.fileUploadControlMdl.length; i++) {
      if (!this.fileUploadControlMdl[i].value || this.fileUploadControlMdl[i].value.length === 0) {
        this.toastr.warning('Es necesario agregar todos los documentos especificados', 'NOTIFICACIÓN');
        this.spinner.hide('sp');
        return;
      }
    }

    this.fileUploadControlMdl.forEach((control, index) => {
      const files = control.value; // Obtener los archivos desde el control
      files.forEach(FILE => {
        const documents = new Documents();
        this.getBase64(FILE).then(
          res => {
            const documentTmp = {
              orderFile: index,
              name: FILE.name,
              b64: res.split(',')[1]
            };
            objDocument.push(documentTmp);
          }
        );
      });
    });


    this.formCreateProcess.get('documents').patchValue(objDocument);

    // console.log(objDocument);
    setTimeout(() => {
      if (objDocument.length === 1) {
        this.modalLoadAutomation.hide();
        this.spinner.hide('sp');
        return;
      }
      this.api.saveProcessLoadingAutomation(this.formCreateProcess.value)
        .then(
          (response: any) => {

            this.api.startLoadingAutomationPolicy(response);

            this.spinner.hide('sp');
            this.modalLoadAutomation.hide();
            this.formCreateProcess.reset();
            this.fileUploadControlMdl = [];
            this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');

          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    }, 3000);
  }

  getBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  selectFilesConfiguration() {
    if (this.formCreateProcess.get('insuranceId').value === 118) {
      this.fileLabels = [
        {label: 'Póliza (.pdf)', isSimple: true, name: 'CARATULA'},
        {label: 'Endosos (.pdf)', isSimple: true, name: 'ENDOSOS'},
        {label: 'Listado (.xls, .xlsx)', isSimple: true, name: 'TITULAR'},
        {label: 'Certificados (.zip)', isSimple: true, name: 'CERTIFICADOS'}];
    } else if (this.formCreateProcess.get('insuranceId').value === 8) {
      this.fileLabels = [
        {label: 'Póliza (.pdf)', isSimple: true, name: 'CARATULA'},
        {label: 'Endosos (.pdf)', isSimple: true, name: 'ENDOSOS'},
        {label: 'Tarifas (.pdf)', isSimple: true, name: 'TARIFAS'},
        {label: 'Listado (.xls, .xlsx)', isSimple: true, name: 'TITULARES'},
        {label: 'Certificados (.zip)', isSimple: true, name: 'CERTIFICADOS'}];
    } else if (this.formCreateProcess.get('insuranceId').value === 6) {
      this.fileLabels = [
        {label: 'Póliza (incluyen certificados) (.pdf)', isSimple: true, name: 'POLIZA'},
        {label: 'Tarifas (.pdf) (Multiples archivos)', isSimple: false, name: 'TARIFAS'}];
    }  else if (this.formCreateProcess.get('insuranceId').value === 11) {
      this.fileLabels = [
        {label: 'Póliza (.pdf)', isSimple: false, name: 'CARATULA'},
        {label: 'Detalle Coberturas (.pdf)', isSimple: true, name: 'DETALLE_COBERTURAS'},
        {label: 'Endosario (.pdf)', isSimple: true, name: 'ENDOSARIO'},
        {label: 'Listado (.csv)', isSimple: true, name: 'LISTADO'},
        {label: 'Tarifas (.xlsx)', isSimple: true, name: 'TARIFAS'},
        {label: 'Certificados (.zip)', isSimple: true, name: 'CERTIFICADOS'},
        {label: 'Credenciales (.zip)', isSimple: true, name: 'CREDENCIALES'}];
    }

    // Genera los controles de subida de archivos según las etiquetas
    this.generateFileUploadControls();
  }

  generateFileUploadControls() {
    this.fileUploadControlMdl = [];  // Reiniciamos el arreglo de controles

    this.fileLabels.forEach((label, index) => {
      let control: FileUploadControl;

      // Crear un nuevo control específico para cada archivo
      if (label.label.includes('.pdf')) {
        control = new FileUploadControl(
          {
            listVisible: true,
            discardInvalid: true,
            accept: ['.pdf']
          },
          [
            FileUploadValidators.accept(['.pdf'])
          ]
        );
      } else if (label.label.includes('.xls') || label.label.includes('.xlsx')) {
        control = new FileUploadControl(
          {
            listVisible: false,
            discardInvalid: true,
            accept: ['.xls', '.xlsx']
          },
          [
            FileUploadValidators.accept(['.xls', '.xlsx'])
          ]
        );
      } else if (label.label.includes('.zip')) {
        control = new FileUploadControl(
          {
            listVisible: false,
            discardInvalid: true,
            accept: ['.zip']
          },
          [
            FileUploadValidators.accept([ '.zip'])
          ]
        );
      } else if (label.label.includes('.csv')) {
        control = new FileUploadControl(
          {
            listVisible: false,
            discardInvalid: true,
            accept: ['.csv']
          },
          [
            FileUploadValidators.accept([ '.csv'])
          ]
        );
      }

      this.fileUploadControlMdl.push(control);

    });
  }


  removeFile(file: any) {
    for (const control of this.fileUploadControlMdl) {
      const files = control.value || [];
      const index = files.indexOf(file);
      if (index > -1) {
        control.removeFile(file);
        break;
      }
    }
  }

  closeModal() {
    this.modalLoadAutomation.hide();
  }
}
