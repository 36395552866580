import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {SharedService} from '../shared-service.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {DualListComponent} from 'angular-dual-listbox';
import {ChartDataSets} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import { EnvioProgramadoComponent } from '../dialogs/envio-programado/envio-programado.component';
import { SaveDynamicReportComponent } from './save/save.component';
import { DndDropEvent } from 'ngx-drag-drop';

import dataTmp from './csvjson.json';

import { NgxDateRangePickerOptions } from 'ngx-daterangepicker';
import { BaseChartDirective } from 'ng2-charts';

import * as _ from 'lodash';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import 'moment/locale/es';

import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-dinamic-reports-new',
  templateUrl: './dinamic-reports-new.component.html',
  styleUrls: ['./dinamic-reports-new.component.css']
})
export class DinamicReportsNewComponent implements OnInit, AfterViewInit {

    reportForm: FormGroup; // Formulario

    pgClientBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
    pgClientTotalItems: number = 16; // Total de registros
    pgClientCurrentPage = 1; // Página actual
    pgClientMaxSize = 5; // Número de links en paginado
    pgClientItemsPerPage: number = 10;

    reporteGuardado: boolean = false;

    draggable = {
        // note that data is handled with JSON.stringify/JSON.parse
        // only set simple data or POJO's as methods will be lost 
        data: "myDragData",
        effectAllowed: "all",
        disable: false,
        handle: false
    };

    // variables: any = [
    //     {
    //         id: '1',
    //         name: 'Documento',
    //         type: 'TEXT'
    //     },
    //     {
    //         id: '2',
    //         name: 'Desde',
    //         type: 'DATE'
    //     },
    //     {
    //         id: '3',
    //         name: 'Hasta',
    //         type: 'DATE'
    //     },
    //     {
    //         id: '4',
    //         name: 'Estatus',
    //         type: 'TEXT'
    //     },
    //     {
    //         id: '5',
    //         name: 'Sungrupo',
    //         type: 'TEXT'
    //     },
    //     {
    //         id: '6',
    //         name: 'Ramo',
    //         type: 'TEXT'
    //     },
    //     {
    //         id: '7',
    //         name: 'Subramo',
    //         type: 'TEXT'
    //     },
    //     {
    //         id: '8',
    //         name: 'Moneda',
    //         type: 'TEXT'
    //     },
    //     {
    //         id: '9',
    //         name: 'Forma de pago',
    //         type: 'TEXT'
    //     },
    //     {
    //         id: '10',
    //         name: 'Compañía',
    //         type: 'TEXT'
    //     },
    //     {
    //         id: '11',
    //         name: 'Prima neta',
    //         type: 'NUMBER'
    //     },
    //     {
    //         id: '12',
    //         name: 'Prima total',
    //         type: 'NUMBER'
    //     },
    //     {
    //         id: '13',
    //         name: 'Comisión',
    //         type: 'NUMBER'
    //     },
    //     {
    //         id: '14',
    //         name: 'Ingreso mxn',
    //         type: 'NUMBER'
    //     }
    // ];
    variables = [];
    key: any;
    display: any;
    coverageCoincidenceList: any = [];
    variablesDestination: any = [];
    variablesDestinationText: any = [];
    variablesDestinationNumber: any = [];

    filas: any = [];
    filasDestination: any = [];
    columnas: any = [];
    columnasDestination: any = [];
    valores: any = [];
    valoresDestination: any = [];
    filtrosValue: any = [];

    format = {
        add: 'ASIGNAR CAMPO',
        remove: 'QUITAR CAMPO',
        all: 'SELECCIONAR TODOS',
        none: 'DESELECCIONAR',
        direction: DualListComponent.LTR,
        draggable: true
    };

    // filtros: any = [
    //     {
    //         id: '1',
    //         name: 'Documento',
    //         type: 'TEXT'
    //     },
    //     {
    //         id: '2',
    //         name: 'Desde',
    //         type: 'DATE'
    //     },
    //     {
    //         id: '3',
    //         name: 'Hasta',
    //         type: 'DATE'
    //     },
    //     {
    //         id: '4',
    //         name: 'Estatus',
    //         type: 'TEXT'
    //     },
    //     {
    //         id: '5',
    //         name: 'Sungrupo',
    //         type: 'TEXT'
    //     },
    //     {
    //         id: '6',
    //         name: 'Ramo',
    //         type: 'OPTIONS'
    //     },
    //     {
    //         id: '7',
    //         name: 'Subramo',
    //         type: 'OPTIONS'
    //     },
    //     {
    //         id: '8',
    //         name: 'Moneda',
    //         type: 'OPTIONS'
    //     },
    //     {
    //         id: '9',
    //         name: 'Forma de Pago',
    //         type: 'OPTIONS'
    //     },
    //     {
    //         id: '10',
    //         name: 'Compañia',
    //         type: 'OPTIONS'
    //     },
    //     {
    //         id: '11',
    //         name: 'Prima neta',
    //         type: 'NUMBER'
    //     },
    //     {
    //         id: '12',
    //         name: 'Prima total',
    //         type: 'NUMBER'
    //     },
    //     {
    //         id: '13',
    //         name: 'Comisión',
    //         type: 'NUMBER'
    //     },
    //     {
    //         id: '14',
    //         name: 'Ingreso mxn',
    //         type: 'NUMBER'
    //     }
    // ];
    filtros = [];
    keyFiltros: any;
    displayFiltros: any;
    filtrosDestination: any = [];

    data: any = [];
    data2: any = [];
    dataAnterior: any = [];
    tipoGrafica: string;
    numeroValores: any[] = [];
    columnasValores: any = [];
    columnaAgrupacion: any;
    columnaAgrupacionTabla: any;
    columnaAgrupacionTablaValor: any;

    tipoValor: string = 'SUMA';

    resColumnas2: any = [];

    lineChartData: ChartDataSets[] = [];

    lineChartLabels: Label[] = [];

    lineChartOptions: any = {
        responsive: true,
    };

    lineChartColors: Color[] = [];

    lineChartLegend = true;
    lineChartPlugins = [];
    lineChartType = 'line';

    showGrafica: boolean = false;

    page = 1;
    pageSize = 10;
    reporteEjecutado: boolean = false;

    options: NgxDateRangePickerOptions[];

    columnaAgrupada: boolean;

    backgroundsColumnas = [
        '#5bc0de',
        '##5cb85c',
        '#f0ad4e',
        '#0275d8',
    ];

    topics: any;
    filterDate: any;

    dynamicReport: any;
    dynamicReportId: number;
    configuracion: any;

    catalogosOpciones: any[];
    dropdownOpciones: any[];

    isView: boolean;

    @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;

    constructor(
        public shared: SharedService,
        private router: Router,
        private toastr: ToastrService,
        private api: ApiService,
        private spinner: NgxSpinnerService,
        private activatedRoute: ActivatedRoute,
        private modalService: BsModalService,
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        moment.locale('es');
        moment.updateLocale(moment.locale(), { invalidDate: 'Sin Fecha' })

        this.key = 'id';
        this.display = 'name';

        this.keyFiltros = 'id';
        this.displayFiltros = 'name';

        this.numeroValores = [];
        this.options = [];
        this.catalogosOpciones = [];
        this.dropdownOpciones  =[];

        this.createForm();
        this.loadTopics();

        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.dynamicReportId = params.id;
                this.loadReport();
                // this.setData();
            }
        });

        this.activatedRoute.data.subscribe(data => {
            if (data.isView) {
                this.isView = data.isView;
            }
        });

        // this.variablesDestination = [
        //     {
        //         id: '2',
        //         name: 'Desde',
        //         type: 'DATE'
        //     },
        //     {
        //         id: '4',
        //         name: 'Estatus',
        //         type: 'TEXT'
        //     },
        //     {
        //         id: '10',
        //         name: 'Compañía',
        //         type: 'TEXT'
        //     },
        //     {
        //         id: '11',
        //         name: 'Prima neta',
        //         type: 'NUMBER'
        //     },
        //     {
        //         id: '12',
        //         name: 'Prima total',
        //         type: 'NUMBER'
        //     },
        // ];

        // this.filas = this.variablesDestination;
        // this.columnas = this.variablesDestination;
        // this.valores = this.variablesDestination;
        // this.ejecutarReporte();
    }

    createForm(): void {
        this.reportForm = this.formBuilder.group({
            topicId: [null, Validators.required],
            variablesId: [null, Validators.required],
            filtros: this.formBuilder.array([])
        });
    }

    async createFiltro(filtro: any): Promise<any> {
        let value: any = filtro.valor;
        if (filtro.type === 'DATE') {
            let fecha = {
                from: moment().toDate(),
                to: moment().toDate()
            };

            if (value) {
                const arr = value.split(',');
                fecha = {
                    from: moment(arr[0], 'YYYY-MM-DD').toDate(),
                    to: moment(arr[1], 'YYYY-MM-DD').toDate()
                };
                value = {
                    from: arr[0],
                    to: arr[1]
                };
            }
            this.options.push({
                theme: 'default',
                labels: ['Start', 'End'],
                menu: [
                    {alias: 'td', text: 'Hoy', operation: '0d'},
                    {alias: 'tm', text: 'Este Mes', operation: '0m'},
                    {alias: 'lm', text: 'Ultimo Mes', operation: '-1m'},
                    {alias: 'tw', text: 'Esta Semana', operation: '0w'},
                    {alias: 'lw', text: 'Ultima Semana', operation: '-1w'},
                    {alias: 'ty', text: 'Este año', operation: '0y'},
                    {alias: 'ly', text: 'Ultimo año', operation: '-1y'},
                    {alias: 'lyt', text: 'Ultimo año apartir de hoy', operation: '-1yt'},
                ],
                dateFormat: 'YYYY-MM-DD',
                outputFormat: 'YYYY-MM-DD',
                startOfWeek: 0,
                outputType: 'object',
                locale: 'es',
                date: fecha
            });
        } else if (filtro.type === 'CATALOGO' || filtro.type === 'CATALOGO_VALUE_VARCHAR') {
            this.options.push({
                theme: 'default',
                labels: ['Start', 'End'],
                menu: [
                    {alias: 'td', text: 'Hoy', operation: '0d'},
                    {alias: 'tm', text: 'Este Mes', operation: '0m'},
                    {alias: 'lm', text: 'Ultimo Mes', operation: '-1m'},
                    {alias: 'tw', text: 'Esta Semana', operation: '0w'},
                    {alias: 'lw', text: 'Ultima Semana', operation: '-1w'},
                    {alias: 'ty', text: 'Este año', operation: '0y'},
                    {alias: 'ly', text: 'Ultimo año', operation: '-1y'},
                    {alias: 'lyt', text: 'Ultimo año apartir de hoy', operation: '-1yt'},
                ],
                dateFormat: 'YYYY-MM-DD',
                outputFormat: 'YYYY-MM-DD',
                startOfWeek: 0,
                outputType: 'object',
                locale: 'es',
            });
            const map = JSON.parse(filtro.mapCatalogo);
            if (!map.options) {
                const response: any = await this.api.getCatalogo(filtro.urlCatalogo).toPromise();
                this.catalogosOpciones[filtro.name] = response;
                const dropdownOpcion = {
                    singleSelection: false,
                    idField: map.id,
                    textField: map.value,
                    selectAllText: 'TODOS',
                    unSelectAllText: 'NINGUNO',
                    itemsShowLimit: 3,
                    allowSearchFilter: true,
                    noDataAvailablePlaceholderText: 'NO SE ENCONTRARON VALORES',
                    searchPlaceholderText: 'BUSCAR'
                };

                this.dropdownOpciones[filtro.name] = dropdownOpcion;
                if (filtro.valor) {
                    const valores = filtro.valor.split(',');
                    const values: any = [];
                    valores.forEach(val => {
                        let index = -1;
                        response.forEach((r, i) => {
                            if (r[map.id] == val) {
                                index = i;
                            }
                        });
                        const ob = response[index];
                        const name = ob[map.value];
                        const valu = !isNaN(val) ? +val : val;
                        values.push({
                            [map.id]: valu,
                            [map.value]: name
                        });
                    });
                    value = values;
                }
            } else {
                this.catalogosOpciones[filtro.name] = map.options;
                const dropdownOpcion = {
                    singleSelection: false,
                    idField: map.id,
                    textField: map.value,
                    selectAllText: 'TODOS',
                    unSelectAllText: 'NINGUNO',
                    itemsShowLimit: 3,
                    allowSearchFilter: true,
                    noDataAvailablePlaceholderText: 'NO SE ENCONTRARON VALORES',
                    searchPlaceholderText: 'BUSCAR'
                };
                this.dropdownOpciones[filtro.name] = dropdownOpcion;
                this.options.push({
                    theme: 'default',
                    labels: ['Start', 'End'],
                    menu: [
                        {alias: 'td', text: 'Hoy', operation: '0d'},
                        {alias: 'tm', text: 'Este Mes', operation: '0m'},
                        {alias: 'lm', text: 'Ultimo Mes', operation: '-1m'},
                        {alias: 'tw', text: 'Esta Semana', operation: '0w'},
                        {alias: 'lw', text: 'Ultima Semana', operation: '-1w'},
                        {alias: 'ty', text: 'Este año', operation: '0y'},
                        {alias: 'ly', text: 'Ultimo año', operation: '-1y'},
                        {alias: 'lyt', text: 'Ultimo año apartir de hoy', operation: '-1yt'},
                    ],
                    dateFormat: 'YYYY-MM-DD',
                    outputFormat: 'YYYY-MM-DD',
                    startOfWeek: 0,
                    outputType: 'object',
                    locale: 'es',
                });
            }
        } else {
            this.options.push({
                theme: 'default',
                labels: ['Start', 'End'],
                menu: [
                    {alias: 'td', text: 'Hoy', operation: '0d'},
                    {alias: 'tm', text: 'Este Mes', operation: '0m'},
                    {alias: 'lm', text: 'Ultimo Mes', operation: '-1m'},
                    {alias: 'tw', text: 'Esta Semana', operation: '0w'},
                    {alias: 'lw', text: 'Ultima Semana', operation: '-1w'},
                    {alias: 'ty', text: 'Este año', operation: '0y'},
                    {alias: 'ly', text: 'Ultimo año', operation: '-1y'},
                    {alias: 'lyt', text: 'Ultimo año apartir de hoy', operation: '-1yt'},
                ],
                dateFormat: 'YYYY-MM-DD',
                outputFormat: 'YYYY-MM-DD',
                startOfWeek: 0,
                outputType: 'object',
                locale: 'es',
            });
        }

        const input = this.formBuilder.group({
            valor: [value],
            valor2: [value],
            filtro: [filtro],
        });

        input.valueChanges.subscribe(value => {
            if (filtro.mapCatalogo) {
                const map = JSON.parse(filtro.mapCatalogo);
                console.log(map);
                if (map && map.dependesTo) {
                    const index = _.findIndex(this.filtrosDestination, { name: map.dependesTo });
                    const ob = this.filtrosDestination[index];

                    console.log(ob);
                    if (ob) {
                        const valores = [];
                        value.valor.forEach(item => {
                            valores.push(item[map.id]);
                        });
                        const mapOb = JSON.parse(ob.mapCatalogo);
                        let params: any;
                        if (mapOb.params.noParams) {
                            params = valores;
                        } else {
                            params = {
                                [mapOb.params.name]: valores
                            };
                        }
                        if (mapOb.method === 'post') {
                            this.api.postCatalogo(ob.urlCatalogo, params).subscribe(response => {
                                this.catalogosOpciones[ob.name] = response;
                                const map = JSON.parse(ob.mapCatalogo);
                                const dropdownOpcion = {
                                    singleSelection: false,
                                    idField: map.id,
                                    textField: map.value,
                                    selectAllText: 'TODOS',
                                    unSelectAllText: 'NINGUNO',
                                    itemsShowLimit: 3,
                                    allowSearchFilter: true,
                                    noDataAvailablePlaceholderText: 'NO SE ENCONTRARON VALORES',
                                    searchPlaceholderText: 'BUSCAR'
                                };

                                this.dropdownOpciones[ob.name] = dropdownOpcion;
                            },
                            err => {
                                console.log(err);
                            });
                        }

                    }
                }
            }
        });

        return input;
    }

    canEjecutarReporte(): boolean {
        let res = false;
        res = this.variablesDestination.length >= 2;
        return res;
    }

    loadTopics(): void {
        this.api.getTopics().subscribe(response => {
            this.topics = response;
        },
        err => {
            console.log(err);
        });
    }

    loadVariables(): void {
        const topicId = this.reportForm['controls'].topicId.value;
        const params = {
            topicId: topicId
        };
        this.api.getVariablesByTopic(params).subscribe(async response => {
            const data: any = response;
            const variables = data.map(o => (
                {
                    id: o.topicsVariablesId,
                    name: o.nameShow,
                    type: o.type,
                    urlCatalogo: o.urlCatalogo,
                    mapCatalogo: o.mapCatalogo,
                    allowed: 'all'
                }
            ));
            this.variables = variables;
            this.filtros = variables;

            if (this.dynamicReportId) {
                this.variablesDestination = this.configuracion.configuracionReporte.variables;
                this.filtrosDestination = this.configuracion.configuracionReporte.filtros;
                this.filasDestination = this.configuracion.tablaDinamica.filas;
                this.valoresDestination = this.configuracion.tablaDinamica.valores;
                this.columnasDestination = this.configuracion.tablaDinamica.columnas;

                this.filas = this.configuracion.tablaDinamica.filas.length > 0 ? this.configuracion.tablaDinamica.filas : this.configuracion.configuracionReporte.variables;
                this.columnas = this.configuracion.tablaDinamica.columnas.length > 0 ? this.configuracion.tablaDinamica.columnas : this.configuracion.configuracionReporte.variables;
                this.valores = this.configuracion.tablaDinamica.valores.length > 0 ? this.configuracion.tablaDinamica.valores : this.configuracion.configuracionReporte.variables;

                this.filtrosDestination.forEach(async filtro => {
                });

                for (let i = 0; i < this.filtrosDestination.length; i++) {
                    const filtro = this.filtrosDestination[i];
                    const filter = await this.createFiltro(filtro);
                    this.filtrosArray.push(filter);
                }

                this.ejecutarReporte();
            }
        },
        err => {
            console.log(err);
        });
    }

    mapId(data: any): any {
        return data.map(o => {
            const index = _.findIndex(this.variables, { id: o });
            return this.variables[index];
        });
    }

    loadReport(): void {
        this.api.getDynamicReport({ dynamicReportId: this.dynamicReportId }).subscribe(response => {
            this.dynamicReport = response;
            this.configuracion = JSON.parse(this.dynamicReport.configuration);

            this.reportForm['controls'].topicId.setValue(this.dynamicReport.topicId);
            this.tipoGrafica = this.configuracion.tablaDinamica.tipoGrafica;
            this.loadVariables();
        },
        err => {
            console.log(err);
        });
    }

    setData(): void {
        this.variablesDestination = [
            {
                id: '1',
                name: 'Documento',
                type: 'TEXT'
            },
            {
                id: '2',
                name: 'Desde',
                type: 'DATE'
            },
            {
                id: '3',
                name: 'Hasta',
                type: 'DATE'
            },
            {
                id: '11',
                name: 'Prima neta',
                type: 'NUMBER'
            },
            {
                id: '12',
                name: 'Prima total',
                type: 'NUMBER'
            },
        ];
        this.filtrosDestination = [
            {
                id: '1',
                name: 'PRUEBA',
                type: 'TEXT'
            },
            {
                id: '2',
                name: 'PRUEBA 2',
                type: 'OPTIONS'
            },
            {
                id: '3',
                name: 'PRUEBA 3',
                type: 'DATE'
            }
        ];

        this.tipoGrafica = 'BAR';
        this.columnasValores = [
            {
                id: '11',
                name: 'Prima neta',
                type: 'NUMBER'
            },
            {
                id: '12',
                name: 'Prima total',
                type: 'NUMBER'
            },
        ];
        this.columnaAgrupacion =
            {
                id: '1',
                name: 'Documento',
                type: 'TEXT'
            };
    }

    ngAfterViewInit(): void {
        this.hideOpciones();
    }

    analisis(): void {
        this.hideOpciones();
    }

    hideOpciones(): void {
        const cols = document.querySelectorAll<HTMLElement>('.button-bar');
        for(let i=0; i<cols.length; i++) {
            cols[i].style.display = 'none';
        }
    }

    changeColorPagination() {
    }

    pageClientChanged(event: PageChangedEvent): void {
    }

    onDestinationChange(evento) {
        this.variablesDestinationText = [];
        this.variablesDestinationNumber = [];

        const valores = [];
        this.variablesDestination.forEach(variable => {
            if (variable.type === 'TEXT') {
                this.variablesDestinationText.push(variable);
            } else {
                this.variablesDestinationNumber.push(variable);
            }
            valores.push(variable);
        });

        if (this.reporteEjecutado) {
            this.ejecutarReporte();
        }

        this.filas = this.variablesDestination;
        this.columnas = this.variablesDestination;
        this.valores = this.variablesDestination.filter(ob => ob.type === 'NUMBER');
    }

    async onDestinationChangeFiltro(evento) {
        evento.forEach(async filtro => {
            let exists = false;
            const mapCatalogo = JSON.parse(filtro.mapCatalogo);
            this.filtrosArray.controls.forEach(fil => {
                const valFiltro = fil.get('filtro').value;
                if (valFiltro.id === filtro.id) {
                    exists = true;
                }
            });
            if (!exists) {
                const filter = await this.createFiltro(filtro);
                this.filtrosArray.push(filter);

                if (mapCatalogo && mapCatalogo.dependesBy) {
                    let existsDep = false;
                    this.filtrosArray.controls.forEach(fil => {
                        const valFiltro = fil.get('filtro').value;
                        if (valFiltro.name === mapCatalogo.dependesBy) {
                            exists = true;
                        }
                    });
                    if (!existsDep) {
                        let filtroDependesBy;
                        this.filtros.forEach(async filt => {
                            if (filt.name === mapCatalogo.dependesBy) {
                                filtroDependesBy = filt;
                            }
                        });
                        this.filtrosDestination.push(filtroDependesBy);
                        const filter = await this.createFiltro(filtroDependesBy);
                        this.filtrosArray.push(filter);
                    }
                }

            }

        });
        this.filtrosArray.controls.forEach((fil, index) => {
            let exists = false;
            evento.forEach(filtro => {
                const valFiltro = fil.get('filtro').value;
                if (valFiltro.id === filtro.id) {
                    exists = true;
                }
            });
            if (!exists) {
                this.filtrosArray.removeAt(index);
            }
        });
        // if (this.reporteEjecutado) {
        //     this.ejecutarReporte();
        // }
    }

    onDestinationFilasChange(evento) {
        evento.forEach(fila => {
            if (fila.type === 'DATE') {
                this.dataAnterior.forEach(data => {
                    const fecha = data[fila.name];
                    const checkFecha = moment(fecha, 'DD/MM/YYYY');
                    if (checkFecha.isValid()) {
                        const mes = moment(fecha, 'DD/MM/YYYY').format('MMM');
                        data[fila.name + '_old'] = data[fila.name];
                        data[fila.name] = mes;
                    }
                });
                const sortedDate = _.orderBy(this.dataAnterior, [fila.name + '_old'], ['asc']);
                this.dataAnterior = sortedDate;
            }
        });

        this.filasDestination.forEach(fila => {
            fila.tipoAgrupacion = 'MES';
        });

        this.crearTablaDinamica();
    }
    onDestinationColumnasChange(evento) {
        evento.forEach(fila => {
            if (fila.type === 'DATE') {
                this.dataAnterior.forEach(data => {
                    const fecha = data[fila.name];
                    const checkFecha = moment(fecha, 'DD/MM/YYYY');
                    if (checkFecha.isValid()) {
                        const mes = moment(fecha, 'DD/MM/YYYY').format('MMM');
                        data[fila.name + '_old'] = data[fila.name];
                        data[fila.name] = mes;
                    }
                });
            }
        });

        this.columnasDestination.forEach(columna => {
            columna.tipoAgrupacion = 'MES';
        });

        this.crearTablaDinamica();
    }

    onDestinationValoresChange(evento) {
        this.valoresDestination.forEach(valor => {
            valor.tipoAgrupacion = 'SUMA';
        });
        this.crearTablaDinamica();
    }

    actualizarValoresAgrupacion2(fila: any, tipo: string): void {
        fila.tipoAgrupacion = tipo;
        this.dataAnterior.forEach(data => {
            const fecha = data[fila.name + '_old'];
            let format = '';
            switch (tipo) {
                case 'FECHA':
                    format = 'DD/MM/YYYY';
                    break;
                case 'MES':
                    format = 'MMM';
                    break;
                case 'AÑO':
                    format = 'YYYY';
                    break;
            }
            const res = moment(fecha, 'DD/MM/YYYY').format(format);
            data[fila.name] = res;
        });

        this.crearTablaDinamica();
    }

    setFormato(fila: any, tipo: string): void {
        fila.formato = tipo;
    }

    actualizarTipoValor2(valor: any, event: any): void {
        valor.tipoAgrupacion = event.target.value;
        this.crearTablaDinamica();
    }

    crearTablaDinamica(): void {
        this.spinner.show('sp');
        let resColumnas2: any = {};
        this.columnasDestination.forEach((columna, index) => {
            if (index === 0) {
                resColumnas2 = _.chain(this.dataAnterior).groupBy(columna.name)
                .value();
                Object.keys(resColumnas2).forEach(key => {
                    resColumnas2[key].columna = columna;
                });
            } else {
                const newColumnas = _.chain(this.dataAnterior).groupBy(columna.name)
                .value();
                Object.keys(resColumnas2).forEach(key => {
                    resColumnas2[key].children = newColumnas;
                    resColumnas2[key].columna = columna;
                });
            }
        });

        this.resColumnas2 = resColumnas2;

        let data = [];
        let marginFila = 0;
        
        this.filasDestination.forEach((fila, index) => {
            if (index === 0) {
                data =_.chain(this.dataAnterior).groupBy(fila.name)
                .map((dato, index) => {
                    const ob: any = {};
                    const agrupacion: any = {};
                    agrupacion.name = index;
                    agrupacion.fila = fila;
                    agrupacion.marginFila = marginFila;
                    ob.agrupacion = agrupacion;

                    let resColumnasDato2: any = {};
                    this.columnasDestination.forEach((columna, index) => {
                        if (index === 0) {
                            resColumnasDato2 = _.chain(dato).groupBy(columna.name)
                            .value();
                            Object.keys(resColumnasDato2).forEach(key => {
                                resColumnasDato2[key].columna = columna;
                            });
                        } else {
                            Object.keys(resColumnasDato2).forEach(key => {
                                const ob = resColumnasDato2[key];
                                const newColumnas = _.chain(ob).groupBy(columna.name)
                                .value();
                                resColumnasDato2[key].children = newColumnas;
                                resColumnasDato2[key].columna = columna;
                            });
                        }
                    });

                    let indexColumnas = 0;
                    Object.keys(resColumnas2).forEach(key => {
                        let exists = false;
                        ob[key] = {};
                        const obCol2 = resColumnas2[key];
                        Object.keys(resColumnasDato2).forEach(keyDato => {
                            const obCol = resColumnasDato2[keyDato];
                            if (keyDato === key && keyDato !== 'children') {
                                let valores: any = {};
                                this.valoresDestination.forEach(valor => {
                                    let v = null;
                                    switch (valor.tipoAgrupacion) {
                                        case 'SUMA':
                                            v = _.sumBy(resColumnasDato2[keyDato], valor.name);
                                            v = v.toFixed(2);
                                            break;
                                        case 'PROMEDIO':
                                            const suma = _.sumBy(resColumnasDato2[keyDato], valor.name);
                                            v = (suma / resColumnasDato2[keyDato].length);
                                            v = v.toFixed(2);
                                            break;
                                        case 'CONTAR':
                                            v = resColumnasDato2[keyDato].length;
                                            break;
                                    }
                                    // valores[valor.name] = _.sumBy(resColumnasDato2[keyDato], valor.name);
                                    valores[valor.name] = v;
                                });
                                ob[key].valor = valores;
                                ob[key].columna = obCol2.columna;
                                exists = true;

                                const columnaDato = resColumnasDato2[keyDato];
                                if (columnaDato.children) {
                                    const obChildren = this.agruparColumnaR(resColumnas2[key], columnaDato, indexColumnas);
                                    ob[key].children = obChildren;
                                }
                            } else {
                                const columnaDato = resColumnasDato2[keyDato];
                                if (columnaDato.children) {
                                    const obChildren: any = {};
                                    Object.keys(resColumnas2[key].children).forEach(k => {
                                        let valores: any = {};
                                        this.valoresDestination.forEach(valor => {
                                            valores[valor.name] = 0;
                                        });
                                        obChildren[k] = {};
                                        obChildren[k].background = this.backgroundsColumnas[indexColumnas];
                                        obChildren[k].valor = valores;
                                        obChildren[k].columna = obCol2.columna;
                                    });

                                    ob[key].children = obChildren;
                                }
                            }

                            ob[key].columna = obCol2.columna;
                        });
                        if (!exists) {
                            let valores: any = {};
                            this.valoresDestination.forEach(valor => {
                                valores[valor.name] = 0;
                            });
                            ob[key].valor = valores;
                            ob[key].columna = obCol2.columna;
                        }
                    });
                    indexColumnas++;

                    ob.value = dato;
                    return ob;
                })
                .value();
            } else {
                marginFila += 10;
                data.forEach((dat, key) => {
                    if (dat.children) {
                        const datTmp = this.childrenR(dat, fila, resColumnas2, marginFila);
                        dat = datTmp;
                    } else {
                        const datTmp = this.agruparFilaR(dat, fila, resColumnas2, marginFila);
                        dat = datTmp;
                    }
                });
            }
        });

        this.data2 = data;
        this.spinner.hide('sp');
        if (this.data2.length > 0 && this.tipoGrafica) {
            this.graficar();
        } else {
            this.showGrafica = false;
            this.tipoGrafica = null;
            if (this.filasDestination.length <= 0 && this.columnasDestination.length <= 0 && this.valoresDestination.length <= 0) {
                this.variablesDestination.forEach(fila => {
                    if (fila.type === 'DATE') {
                        this.dataAnterior.forEach(data => {
                            const fecha = data[fila.name + '_old'];
                            data[fila.name] = fecha;
                        });
                    }
                });
            }
        }
    }

    childrenR(dat: any, fila, resColumnas2, marginFila): any {
        dat.children.forEach(d => {
            if (d.children) {
                return this.childrenR(d, fila, resColumnas2, marginFila);
            } else {
                return this.agruparFilaR(d, fila, resColumnas2, marginFila);
            }
        });
    }

    agruparFilaR(dat: any, fila: any, resColumnas2, marginFila: number): any {
        const newGroup =_.chain(this.dataAnterior).groupBy(fila.name).value();

        const newData =_.chain(dat.value).groupBy(fila.name)
        .map((dato, index) => {
            const ob: any = {};
            const agrupacion: any = {};
            agrupacion.name = index;
            agrupacion.fila = fila;
            agrupacion.marginFila = marginFila;
            ob.agrupacion = agrupacion;

            let resColumnasDato2: any = {};
            this.columnasDestination.forEach((columna, index) => {
                if (index === 0) {
                    resColumnasDato2 = _.chain(dato).groupBy(columna.name)
                    .value();
                } else {
                    const newColumnas = _.chain(dato).groupBy(columna.name)
                    .value();
                    Object.keys(resColumnasDato2).forEach(key => {
                        resColumnasDato2[key].children = newColumnas;
                    });
                }
            });

            let indexColumnas = 0;
            Object.keys(resColumnas2).forEach(key => {
                let exists = false;
                ob[key] = {};
                const obCol2 = resColumnas2[key];
                Object.keys(resColumnasDato2).forEach(keyDato => {
                    if (keyDato === key && keyDato !== 'children') {
                        let valores: any = {};
                        this.valoresDestination.forEach(valor => {
                            let v = null;
                            switch (valor.tipoAgrupacion) {
                                case 'SUMA':
                                    v = _.sumBy(resColumnasDato2[keyDato], valor.name);
                                    v = v.toFixed(2);
                                    break;
                                case 'PROMEDIO':
                                    const suma = _.sumBy(resColumnasDato2[keyDato], valor.name);
                                    v = (suma / resColumnasDato2[keyDato].length);
                                    v = v.toFixed(2);
                                    break;
                                case 'CONTAR':
                                    v = resColumnasDato2[keyDato].length;
                                    break;
                            }
                            valores[valor.name] = v;
                            // valores[valor.name] = _.sumBy(resColumnasDato2[keyDato], valor.name);
                        });
                        ob[key].valor = valores;
                        ob[key].columna = obCol2.columna;
                        exists = true;
                    }
                    const columnaDato = resColumnasDato2[keyDato];
                    if (columnaDato.children) {
                        const obChildren = this.agruparColumnaR(resColumnas2[key], columnaDato, indexColumnas);
                        ob[key].children = obChildren;
                    }
                });
                if (!exists) {
                    let valores: any = {};
                    this.valoresDestination.forEach(valor => {
                        valores[valor.name] = 0;
                    });
                    ob[key].valor = valores;
                    ob[key].columna = obCol2.columna;
                }
            });

            ob.value = dato;

            return ob;
        })
        .value();

        Object.keys(newGroup).forEach(key => {
            let exists = false;
            let values = [];
            newData.forEach(dat2 => {
                if (dat2.agrupacion.name === key) {
                    exists = true;
                    values = dat2.value;
                }
            });
            if (!exists) {
                const ob: any = {};
                Object.keys(resColumnas2).forEach(key => {
                    const obCol2 = resColumnas2[key];
                    let valores: any = {};
                    this.valoresDestination.forEach(valor => {
                        valores[valor.name] = 0;
                    });
                    const val = { valor: valores, columna: obCol2.columna };
                    ob[key] = val;
                });
                const agrupacion: any = {};
                agrupacion.name = key;
                agrupacion.fila = fila;
                agrupacion.marginFila = marginFila;
                ob.agrupacion = agrupacion;
                ob.value = values;

                newData.push(ob);
            }
        });

        dat.children = newData;
        console.log(newData);

        return dat;
    }

    agruparColumnaR(columna: any, columnaDato: any, indexColumnas: number): any {
        const obChildren: any = {};
        Object.keys(columna.children).forEach(keyChildren => {
            const obTmp : any = {};
            let existsChildren = false;
            const obCol2 = columna.children[keyChildren];
            Object.keys(columnaDato.children).forEach(keyChildrenDato => {
                if (keyChildren === keyChildrenDato) {
                    let valores: any = {};
                    this.valoresDestination.forEach(valor => {
                        let v = null;
                        switch (valor.tipoAgrupacion) {
                            case 'SUMA':
                                v = _.sumBy(columnaDato.children[keyChildren], valor.name);
                                v = v.toFixed(2);
                                break;
                            case 'PROMEDIO':
                                const suma = _.sumBy(columnaDato.children[keyChildren], valor.name);
                                v = (suma / columnaDato.children[keyChildren].length);
                                v = v.toFixed(2);
                                break;
                            case 'CONTAR':
                                v = columnaDato.children[keyChildren].length;
                                break;
                        }
                        valores[valor.name] = v;
                        // valores[valor.name] = _.sumBy(columnaDato.children[keyChildrenDato], valor.name);
                    });
                    obTmp.valor = valores;
                    obTmp.columna = obCol2.columna ? obCol2.columna : columnaDato.columna;
                    existsChildren = true;
                }

                //Recursivo
                const columnDato = columnaDato.children[keyChildrenDato];
                const column = columna.children[keyChildren];
                if (columnDato.children) {
                    indexColumnas++;
                    const child = this.agruparColumnaR(column, columnDato, indexColumnas);
                    obChildren.children = child;
                }
            });

            if (!existsChildren) {
                let valores: any = {};
                this.valoresDestination.forEach(valor => {
                    valores[valor.name] = 0;
                });
                obTmp.valor = valores;
            }
            obTmp.background = this.backgroundsColumnas[indexColumnas];
            obTmp.columna = obCol2.columna ? obCol2.columna : columnaDato.columna;
            obChildren[keyChildren] = obTmp;
        });

        return obChildren;
    }

    agrupar(data: any, name: string): any {
        const resColumnas: any = _.chain(this.dataAnterior).groupBy(item => {
            let str = '';
            this.columnasDestination.forEach((columna, index) => {
                if (index <= 0) {
                    str += `${item[columna.name]}`;
                } else {
                    str += `+${item[columna.name]}`;
                }
            });

            return `"${str}"`;
        }).value();

        const res =_.chain(data).groupBy(name)
        .map((dato, index) => {
            const ob: any = {};
            ob.agrupacion = index;

            const resColumnasDato = _.chain(dato).groupBy(item => {
                let str = '';
                this.columnasDestination.forEach((columna, index) => {
                    if (index <= 0) {
                        str += `${item[columna.name]}`;
                    } else {
                        str += `+${item[columna.name]}`;
                    }
                });

                return `"${str}"`;
            }).value();
            Object.keys(resColumnas).forEach(key => {
                let exists = false;
                Object.keys(resColumnasDato).forEach(keyDato => {
                    if (keyDato === key) {
                        let valor = null;
                        valor = _.sumBy(resColumnasDato[keyDato], 'Prima total');
                        ob[key] = valor;
                        exists = true;
                    }
                });
                if (!exists) {
                    ob[key] = 0;
                }
            });

            ob.value = dato;
            return ob;
        })
        .value();
        return res;
    }

    ejecutarReporte() {
        this.spinner.show('sp');
        this.reporteEjecutado = true;
        this.data = [];
        this.dataAnterior = [];
        const variablesId = [];
        this.variablesDestination.forEach(column => {
            variablesId.push(column.id);
        });
        const formData = this.reportForm.value;
        const filtros = [];
        formData.filtros.forEach((filtro, index) => {
            if (filtro.valor != null && filtro.valor !== '') {
                let value = filtro.valor;
                const ob = this.filtrosDestination[index];
                if (filtro.valor.from) {
                    value = filtro.valor.from + ',' + filtro.valor.to;
                    this.filtrosDestination[index].valor = value;
                    filtros.push({
                        id: ob.id,
                        valor: value
                    });
                }
                if (_.isArray(filtro.valor) && filtro.valor.length > 0) {
                    const map = JSON.parse(ob.mapCatalogo);
                    value = _.map(filtro.valor, map.id).join(',');
                    this.filtrosDestination[index].valor = value;
                    filtros.push({
                        id: ob.id,
                        valor: value
                    });
                }
                if (filtro.filtro.type === 'NUMBER' && filtro.valor2 != null) {
                    value = filtro.valor + ',' + filtro.valor2;
                    this.filtrosDestination[index].valor = value;
                    filtros.push({
                        id: ob.id,
                        valor: value
                    });
                }
                if (filtro.filtro.type === 'TEXT') {
                    this.filtrosDestination[index].valor = value;
                    filtros.push({
                        id: ob.id,
                        valor: value
                    });
                }
            }
        });
        this.filtrosValue = filtros;
        const params = {
            variablesId: variablesId,
            filtros: this.filtrosValue,
            topicId: this.reportForm['controls'].topicId.value
        };
        this.api.dinamicQuery(params).subscribe(response => {
            const data: any = response;
            data.forEach(dat => {
                const tmp = {};
                this.variablesDestination.forEach(column => {
                    let tmpVal = dat[column.name];
                    if (column.type === 'NUMBER') {
                        tmpVal = tmpVal ? tmpVal : 0;
                    }
                    switch (column.type) {
                        case 'NUMBER':
                            tmpVal = tmpVal ? tmpVal : 0;
                            break;
                        case 'DATE':
                            const res = moment(tmpVal);
                            tmpVal = res.isValid() ? res.format('DD/MM/YYYY') : null;
                            break;
                    }
                    tmp[column.name] = tmpVal;
                });
                this.data.push(tmp);
                this.dataAnterior.push(tmp);
            });

            if (this.columnaAgrupada) {
                this.agruparColumna(this.columnaAgrupacionTabla, 'SUMA');
            }
            // this.graficar();
            this.hideOpciones();
            if (this.dynamicReportId) {
                if (
                    this.configuracion.tablaDinamica.filas.length > 0 &&
                    this.configuracion.tablaDinamica.columnas.length > 0 &&
                    this.configuracion.tablaDinamica.valores.length > 0
                ) {
                    this.filasDestination.forEach(fila => {
                        if (fila.type === 'DATE') {
                            this.dataAnterior.forEach(data => {
                                const fecha = data[fila.name];
                                let format = '';
                                switch (fila.tipoAgrupacion) {
                                    case 'FECHA':
                                        format = 'DD/MM/YYYY';
                                        break;
                                    case 'MES':
                                        format = 'MMM';
                                        break;
                                    case 'AÑO':
                                        format = 'YYYY';
                                        break;
                                }
                                const res = moment(fecha, 'DD/MM/YYYY').format(format);

                                data[fila.name + '_old'] = data[fila.name];
                                data[fila.name] = res;
                            });
                        }
                    });

                    this.columnasDestination.forEach(columna => {
                        if (columna.type === 'DATE') {
                            this.dataAnterior.forEach(data => {
                                const fecha = data[columna.name];
                                let format = '';
                                switch (columna.tipoAgrupacion) {
                                    case 'FECHA':
                                        format = 'DD/MM/YYYY';
                                        break;
                                    case 'MES':
                                        format = 'MMM';
                                        break;
                                    case 'AÑO':
                                        format = 'YYYY';
                                        break;
                                }
                                const res = moment(fecha, 'DD/MM/YYYY').format(format);

                                data[columna.name + '_old'] = data[columna.name];
                                data[columna.name] = res;
                            });
                        }
                    });

                    this.crearTablaDinamica();
                }
            } else {
                this.filasDestination.forEach(fila => {
                    if (fila.type === 'DATE') {
                        this.dataAnterior.forEach(data => {
                            const fecha = data[fila.name];
                            const checkFecha = moment(fecha, 'DD/MM/YYYY');
                            if (checkFecha.isValid()) {
                                const mes = moment(fecha, 'DD/MM/YYYY').format('MMM');
                                data[fila.name + '_old'] = data[fila.name];
                                data[fila.name] = mes;
                            }
                        });
                        const sortedDate = _.orderBy(this.dataAnterior, [fila.name + '_old'], ['asc']);
                        this.dataAnterior = sortedDate;
                    }
                });

                this.filasDestination.forEach(fila => {
                    fila.tipoAgrupacion = 'MES';
                });

                this.columnasDestination.forEach(fila => {
                    if (fila.type === 'DATE') {
                        this.dataAnterior.forEach(data => {
                            const fecha = data[fila.name];
                            const checkFecha = moment(fecha, 'DD/MM/YYYY');
                            if (checkFecha.isValid()) {
                                const mes = moment(fecha, 'DD/MM/YYYY').format('MMM');
                                data[fila.name + '_old'] = data[fila.name];
                                data[fila.name] = mes;
                            }
                        });
                    }
                });

                this.columnasDestination.forEach(columna => {
                    columna.tipoAgrupacion = 'MES';
                });
                this.valoresDestination.forEach(valor => {
                    valor.tipoAgrupacion = 'SUMA';
                });

                this.crearTablaDinamica();
            }

            this.reporteEjecutado = true;
            this.spinner.hide('sp');
        },
        err => {
            console.log(err);
            this.spinner.hide('sp');
        });
    }

    checkFillData(): boolean {
        return this.filasDestination.length > 0 && this.columnasDestination.length > 0 && this.valoresDestination.length > 0;
    }

    graficar(): void {
        this.showGrafica = false;
        if (this.tipoGrafica && this.checkFillData()) {
            this.spinner.show('sp');
            setTimeout(() => {
                const datas = [];
                const labels = [];

                const datasTmp = {};
                Object.keys(this.resColumnas2).forEach(key => {
                    const ob = this.resColumnas2[key];
                    this.valoresDestination.forEach(val => {
                        datasTmp[val.name + '-' + key] = [];
                        if (ob.children) {
                            this.graficaAgrupaColumnaR(ob.children, datasTmp, key);
                        }
                    });
                });

                this.data2.forEach(dat => {
                    labels.push(dat.agrupacion.name);
                    Object.keys(dat).forEach(key => {
                        if (key !== 'agrupacion' && key !== 'value' && key !== 'children') {
                            const ob = dat[key];
                            let valor = 0;
                            this.valoresDestination.forEach(val => {
                                valor = ob.valor[val.name];
                                let arr = datasTmp[val.name + '-' + key];
                                arr.push(valor);
                                datasTmp[val.name + '-' + key] = arr;

                                if (ob.children) {
                                    this.graficaAgrupaDataR(ob.children, datasTmp, key);
                                }
                            });
                        } else if (key === 'children') {
                            dat.children.forEach(d => {
                                this.graficaAgruparFilaR(dat, d, datasTmp, labels);
                            });
                        }
                    });
                });

                Object.keys(datasTmp).forEach(key => {
                    const ob = datasTmp[key];
                    datas.push({
                        data: ob,
                        label: key
                    });
                });

                this.lineChartData = datas;
                this.lineChartLabels = labels;
                this.lineChartType = this.tipoGrafica;
                if (this.lineChartType === 'pie') {
                    this.lineChartOptions = {
                        responsive: true,
                        tooltips: {
                            callbacks: {
                                label: function(item, data) {
                                    const label = data.labels[item.index] + '-' + data.datasets[item.datasetIndex].label;
                                    const value = data.datasets[item.datasetIndex].data[item.index];
                                    return label + ': ' + value;
                                },
                                title: () => null,
                            }
                        },
                    };
                } else {
                    this.lineChartOptions = {
                        responsive: true,
                    };
                }
                this.showGrafica = true;
                this.spinner.hide('sp');

            }, 500);
        }
    }

    graficaAgrupaDataR(data: any, datasTmp: any, key: string): void {
        Object.keys(data).forEach(child => {
            this.valoresDestination.forEach(val => {
                const o = data[child];
                let v = 0;
                this.valoresDestination.forEach(val => {
                    v = o.valor[val.name];
                    let a = datasTmp[val.name + '-' + key + '-' + child];
                    a.push(v);
                    datasTmp[key + '-' + child] = a;
                    if (o.children) {
                        this.graficaAgrupaDataR(o.children, datasTmp, child);
                    }
                });
            });
        });
    }

    graficaAgrupaColumnaR(data: any, datasTmp: any, key: string): void {
        Object.keys(data).forEach(k => {
            this.valoresDestination.forEach(val => {
                const ob = data[k];
                const ke = val.name + '-' + key + '-' + k;
                datasTmp[ke] = [];
                if (ob.children) {
                    this.graficaAgrupaColumnaR(ob.children, datasTmp, ke);
                }
            });
        });
    }

    graficaAgruparFilaR(parent: any, data: any, datasTmp: any, labels: any): void {
        labels.push(parent.agrupacion.name + '-' + data.agrupacion.name);
        Object.keys(data).forEach(key => {
            if (key !== 'agrupacion' && key !== 'value' && key !== 'children') {
                const ob = data[key];
                let valor = 0;
                this.valoresDestination.forEach(val => {
                    valor = ob.valor[val.name];
                    let arr = datasTmp[val.name + '-' + key];
                    arr.push(valor);
                    datasTmp[val.name + '-' + key] = arr;
                });
            } else if (key === 'children') {
                data.children.forEach(dat => {
                    this.graficaAgruparFilaR(data, dat, datasTmp, labels);
                });
            }
        });
    }

    totalizarColumna(columna: any): void {
        columna.totalizar = !columna.totalizar;
    }

    totalizar(columna: any): number {
        let res = 0;
        this.data.forEach(dato => {
            this.variablesDestination.forEach(column => {
                if (column.id === columna.id) {
                    const valor = dato[column.name];
                    res += +valor;
                }
            });
        });

        return res;
    }

    selectTipoGrafica(tipografica: string): void {
        this.tipoGrafica = tipografica
        if (this.numeroValores.length <= 0) {
            this.agregarValor();
        }
        if (this.reporteEjecutado) {
            this.graficar();
        }
    }

    agregarValor(): void {
        this.numeroValores.push({});
    }

    deleteValor(i: number): void {
        this.numeroValores.splice(i, 1);
        this.columnasValores.splice(i, 1);
    }

    selectColumnaAgrupacion(event: any): void {
        let variableOb;
        this.variablesDestination.forEach(variable => {
            if (+variable.id === +event.target.value) {
                variableOb = variable;
            }
        });

        this.columnaAgrupacion = variableOb;
    }

    selectValor(event: any): void {
        let variableOb;
        this.variablesDestination.forEach(variable => {
            if (variable.name === event.target.value) {
                variableOb = variable;
            }
        });
        this.columnasValores.push(variableOb);
    }

    openEnvioProgramado(): void {
        const modalRef = this.modalService.show(EnvioProgramadoComponent, { initialState: { dynamicReportId: this.dynamicReportId }});
        modalRef.content.onClose.subscribe(data => {
            modalRef.hide();
        });
    }

    agruparColumna(column: any, tipo: string): void {
        if (this.columnaAgrupada) {
            this.columnaAgrupacionTabla = column;
            const res = _.chain(this.dataAnterior).groupBy(column.name).map((dat) =>  {
                const ob = {};
                this.variablesDestination.forEach(col => {
                    if (col.tipoAgrupacion === undefined) {
                        col.tipoAgrupacion = tipo
                    } else if (this.columnaAgrupacionTablaValor && this.columnaAgrupacionTablaValor.name === col.name) {
                        col.tipoAgrupacion = tipo;
                    }
                    switch (col.type) {
                        case 'TEXT':
                        case 'DATE':
                            ob[col.name] = dat[0][col.name];
                            break;
                        case 'NUMBER':
                            let valor = null;
                            switch (col.tipoAgrupacion) {
                                case 'SUMA':
                                    valor = _.sumBy(dat, col.name);
                                    break;
                                case 'PROMEDIO':
                                    const suma = _.sumBy(dat, col.name);
                                    valor = (suma / dat.length);
                                    break;
                                case 'CONTEO':
                                    valor = dat.length;
                                    break;
                            }
                            ob[col.name] = valor;
                            break;
                    }
                });
                return ob;
            }).value();
            this.data = res;
        } else {
            this.data = this.dataAnterior;
        }

        this.graficar();
    }

    actualizarValoresAgrupacion(column: any, tipo: string): void {
        this.columnaAgrupacionTablaValor = column;
        this.agruparColumna(this.columnaAgrupacionTabla, tipo);
    }

    compararColumna(column: any): void {
        const colOb = Object.assign({}, column);
        colOb.name = column.name  + ' Comparativa';
        colOb.isComparativa = true;
        colOb.id = column.id + '_Comp';
        this.variablesDestination.push(colOb);
        this.variablesDestinationNumber.push(colOb);
        // this.columnasValores.push(colOb);
        // this.numeroValores.push({});

        this.ejecutarReporte();
    }

    deleteColumn(column: any, index: number): void {
        _.remove(this.variablesDestination, { name: column.name });
        const indexValor = _.findIndex(this.columnasValores, { name: column.name });
        this.deleteValor(indexValor);
        this.ejecutarReporte();
    }

    onSave(): void {
        const modalRef = this.modalService.show(SaveDynamicReportComponent, {});
        modalRef.content.onClose.subscribe(data => {
            this.save(data);
            modalRef.hide();
        });
    }

    save(data: any): void {
        const configuration = {
            tablaDinamica: {
                filas: this.filasDestination,
                columnas: this.columnasDestination,
                valores: this.valoresDestination,
                tipoGrafica: this.tipoGrafica,
            },
            configuracionReporte: {
                clasificacion: 1,
                variables: this.variablesDestination,
                filtros: this.filtrosDestination
            }
        }
        const params = {
            name: data.value.name,
            configuration: JSON.stringify(configuration),
            topicId: this.reportForm['controls'].topicId.value,
            scheduled: false,
            inDashboard: false,
            description: data.value.description
        };
        this.api.saveDynaminReport(params).subscribe(response => {
            this.dynamicReportId = +response;
            this.toastr.success('Reporte guardado correctamente', `Notificación`);
            this.reporteGuardado = true;
            // this.router.navigate(['/admin/dinamic-reports']);
        },
        err => {
            console.log(err);
        });
    }

    descargarExcel(): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.data);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(data, 'report_export_' + new Date().getTime() + '.xlsx');
    }

    descargarTablaDinamica(): void {
        let newData = [];
        this.data2.forEach(data => {
            const tmp = {};
            let newDataTmp = [];
            Object.keys(data).forEach(key => {
                if (key !== 'agrupacion' && key !== 'children') {
                    if (key !== 'value') {
                        const valores = data[key].valor;
                        Object.keys(valores).forEach(keyValor => {
                            tmp[key + '_' + keyValor] = valores[keyValor];
                        });

                        const children = data[key].children;
                        if (children && Object.keys(children).length >= 0) {
                            this.getTmpChildrenColumn(children, key, tmp);
                        }
                    }
                } else {
                    if (key !== 'children') {
                        const ob = data['agrupacion'];
                        tmp['agrupacion'] = ob.fila.name + '_' + ob.name;
                    } else {
                        newDataTmp = this.getTmpChildren(data['children']);
                    }
                }
            });

            newData.push(tmp);
            newData = newData.concat(newDataTmp);
        });

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(data, 'dynamictable_export_' + new Date().getTime() + '.xlsx');
    }

    getTmpChildrenColumn(children: any, key: string, tmp: any): void {
        Object.keys(children).forEach(keyChildren => {
            const valores = children[keyChildren].valor;
            Object.keys(valores).forEach(keyValor => {
                tmp[key + '_' + keyValor + '_' + keyChildren] = valores[keyValor];
            });

            const childrenTmp = children[keyChildren].children;
            if (childrenTmp && Object.keys(childrenTmp).length >= 0) {
                this.getTmpChildrenColumn(childrenTmp, key, tmp);
            }
        });

    }

    getTmpChildren(dataArr: any): any {
        let newData = [];
        dataArr.forEach(data => {
            const tmp = {};
            let newDataTmp = [];
            Object.keys(data).forEach(key => {
                if (key !== 'agrupacion' && key !== 'children') {
                    if (key !== 'value') {
                        const valores = data[key].valor;
                        Object.keys(valores).forEach(keyValor => {
                            tmp[key] = valores[keyValor];
                        });

                        const children = data[key].children;
                        if (children && Object.keys(children).length >= 0) {
                            this.getTmpChildrenColumn(children, key, tmp);
                        }
                    }
                } else {
                    if (key !== 'children') {
                        const ob = data['agrupacion'];
                        tmp['agrupacion'] = ob.fila.name + '_' + ob.name;
                    } else {
                        newDataTmp = this.getTmpChildren(data['children']);
                    }
                }
            });
            newData.push(tmp);
            newData = newData.concat(newDataTmp);
        });

        return newData;
    }

    onDragStart(event:DragEvent) {
 
        console.log("drag started", JSON.stringify(event, null, 2));
    }
    
    onDragEnd(event:DragEvent) {
        
        console.log("drag ended", JSON.stringify(event, null, 2));
    }
    
    onDraggableCopied(event:DragEvent) {
        
        console.log("draggable copied", JSON.stringify(event, null, 2));
    }
    
    onDraggableLinked(event:DragEvent) {
        
        console.log("draggable linked", JSON.stringify(event, null, 2));
    }
        
    onDraggableMoved(event:DragEvent) {
        
        console.log("draggable moved", JSON.stringify(event, null, 2));
    }
        
    onDragCanceled(event:DragEvent) {
        
        console.log("drag cancelled", JSON.stringify(event, null, 2));
    }
    
    onDragover(event:DragEvent) {
        
        console.log("dragover", JSON.stringify(event, null, 2));
    }
    
    async onDropFiltros(event:DndDropEvent) {
        const index = _.findIndex(this.variables, { id: event.data });
        const filtro = this.filtros[index];

        let exists = false;
        const mapCatalogo = JSON.parse(filtro.mapCatalogo);
        this.filtrosArray.controls.forEach(fil => {
            const valFiltro = fil.get('filtro').value;
            if (valFiltro.id === filtro.id) {
                exists = true;
            }
        });
        if (!exists) {
            this.filtrosDestination.push(filtro);
            const filter = await this.createFiltro(filtro);
            this.filtrosArray.push(filter);

            if (mapCatalogo && mapCatalogo.dependesBy) {
                let existsDep = false;
                this.filtrosArray.controls.forEach(fil => {
                    const valFiltro = fil.get('filtro').value;
                    if (valFiltro.name === mapCatalogo.dependesBy) {
                        exists = true;
                    }
                });
                if (!existsDep) {
                    let filtroDependesBy;
                    this.filtros.forEach(async filt => {
                        if (filt.name === mapCatalogo.dependesBy) {
                            filtroDependesBy = filt;
                        }
                    });
                    this.filtrosDestination.push(filtroDependesBy);
                    const filter = await this.createFiltro(filtroDependesBy);
                    this.filtrosArray.push(filter);
                }
            }

        }

        this.filtrosArray.controls.forEach((fil, index) => {
            let exists = false;
            this.filtrosDestination.forEach(filtro => {
                const valFiltro = fil.get('filtro').value;
                if (valFiltro.id === filtro.id) {
                    exists = true;
                }
            });
            if (!exists) {
                this.filtrosArray.removeAt(index);
            }
        });
    }

    onDeleteFiltro(index: number): void {
        const ob = this.filtrosDestination[index];
        this.filtrosDestination.splice(index, 1);

        this.filtrosArray.controls.forEach((fil, index) => {
            let exists = false;
            this.filtrosDestination.forEach(filtro => {
                const valFiltro = fil.get('filtro').value;
                if (valFiltro.id === filtro.id) {
                    exists = true;
                }
            });
            if (!exists) {
                this.filtrosArray.removeAt(index);
            }
        });
    }

    onDropFilas(event:DndDropEvent) {
        const index = _.findIndex(this.variables, { id: event.data });
        const ob = this.variables[index];

        this.variablesDestination.push(ob);
        if (ob.type === 'TEXT') {
            this.variablesDestinationText.push(ob);
        } else {
            this.variablesDestinationNumber.push(ob);
        }
        this.filasDestination.push(ob);
        this.filas.push(ob);

        if (this.filas.length > 0 && this.columnas.length > 0 && this.valores.length > 0) {
            this.ejecutarReporte();
        } else {
            this.cleanDataTable();
        }
    }

    onDeleteFilas(index: number): void {
        const ob = this.filas[index];
        const ind = _.findIndex(this.variablesDestination, { id: ob.id });
        this.variablesDestination.splice(ind, 1);

        if (ob.type === 'TEXT') {
            this.variablesDestinationText.splice(ind, 1);
        } else {
            this.variablesDestinationNumber.splice(ind, 1);
        }
        this.filasDestination.splice(index, 1);
        this.filas.splice(index, 1);

        if (this.filas.length > 0 && this.columnas.length > 0 && this.valores.length > 0) {
            this.ejecutarReporte();
        } else {
            this.cleanDataTable();
        }
    }

    onDropColumnas(event:DndDropEvent) {
        const index = _.findIndex(this.variables, { id: event.data });
        const ob = this.variables[index];

        this.variablesDestination.push(ob);
        if (ob.type === 'TEXT') {
            this.variablesDestinationText.push(ob);
        } else {
            this.variablesDestinationNumber.push(ob);
        }
        this.columnasDestination.push(ob);
        this.columnas.push(ob);

        if (this.filas.length > 0 && this.columnas.length > 0 && this.valores.length > 0) {
            this.ejecutarReporte();
        } else {
            this.cleanDataTable();
        }
    }

    onDeleteColumnas(index: number): void {
        const ob = this.columnas[index];
        const ind = _.findIndex(this.variablesDestination, { id: ob.id });
        this.variablesDestination.splice(ind, 1);

        if (ob.type === 'TEXT') {
            this.variablesDestinationText.splice(ind, 1);
        } else {
            this.variablesDestinationNumber.splice(ind, 1);
        }
        this.columnasDestination.splice(index, 1);
        this.columnas.splice(index, 1);

        if (this.filas.length > 0 && this.columnas.length > 0 && this.valores.length > 0) {
            this.ejecutarReporte();
        } else {
            this.cleanDataTable();
        }
    }

    onDropValores(event:DndDropEvent) {
    
        const index = _.findIndex(this.variables, { id: event.data });
        const ob = this.variables[index];

        if (ob.type === 'NUMBER') {
            this.variablesDestination.push(ob);
            if (ob.type === 'TEXT') {
                this.variablesDestinationText.push(ob);
            } else {
                this.variablesDestinationNumber.push(ob);
            }
            this.valoresDestination.push(ob);
            this.valores.push(ob);

            if (this.filas.length > 0 && this.columnas.length > 0 && this.valores.length > 0) {
                console.log('ejecuatr reporte');
                this.ejecutarReporte();
            } else {
                this.cleanDataTable();
            }
        } else {
            this.toastr.error('No se puede agregar debido a que no es un valor númerico', `Notificación`);
        }
    }

    onDeleteValores(index: number): void {
        const ob = this.valores[index];
        const ind = _.findIndex(this.variablesDestination, { id: ob.id });
        this.variablesDestination.splice(ind, 1);

        if (ob.type === 'TEXT') {
            this.variablesDestinationText.splice(ind, 1);
        } else {
            this.variablesDestinationNumber.splice(ind, 1);
        }
        this.valoresDestination.splice(index, 1);
        this.valores.splice(index, 1);

        if (this.filas.length > 0 && this.columnas.length > 0 && this.valores.length > 0) {
            this.ejecutarReporte();
        } else {
            this.cleanDataTable();
        }
    }

    cleanDataTable(): void {
        this.data2 = [];
        this.data = [];
        this.showGrafica = false;
        this.dataAnterior = [];
    }

    onFiltroSelect(event: any): void {
        console.log('filtro select');
        if (this.filas.length > 0 && this.columnas.length > 0 && this.valores.length > 0) {
            this.ejecutarReporte();
        }
    }

    choosedDate(event: any): void {
        console.log('cambio fecha');
        if (this.filas.length > 0 && this.columnas.length > 0 && this.valores.length > 0) {
            this.ejecutarReporte();
        }
    }

    changeFiltro(): void {
        console.log('cambio filtro');
        if (this.filas.length > 0 && this.columnas.length > 0 && this.valores.length > 0) {
            this.ejecutarReporte();
        }
    }

    get filtrosArray(): any {
        const array = <FormArray> this.reportForm['controls'].filtros;
        return array;
    }
}
