import {Component, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgWizardConfig, NgWizardService, STEP_STATE, StepChangedArgs, StepValidationArgs, THEME} from 'ng-wizard';
import {number} from '@amcharts/amcharts4/core';
import {Documents} from '../upload-file/documents';
import {regex} from "../../common/regex";
import {FileUploadControl, FileUploadValidators} from "@iplab/ngx-file-upload";
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-incident-masive-load',
  templateUrl: './incident-masive-load.component.html',
  styleUrls: ['./incident-masive-load.component.css']
})
export class IncidentMasiveLoadComponent implements OnInit {

    infoNoValidos: any[] = [];
    infoValidos: any[] = [];
    presenta: boolean = false;

    constructor(
        private api: ApiService,
        private router: Router,
        private toastr: ToastrService,
        private shared: SharedService,
        private datePipe: DatePipe,
        public fb: FormBuilder,
        private modalService: BsModalService,
        private spinner: NgxSpinnerService,
        private ngWizardService: NgWizardService
    ) {
    }

    ngOnInit() {
    }

    fileUploadXls(event: any): void {
        // Obtener la fecha actual
        const today = new Date();
        const e: any = event;
        // Convertir a cadena ISO y extraer la fecha en formato aaaa-mm-dd
        const formattedDate = today.toISOString().slice(0, 10);

        console.log('FECHA: ' , formattedDate); // Salida: "2023-05-19";
        // Obtener la fecha y hora actual
        const now = new Date();
        console.log('FECHA-HORA: ', now);
        // Extraer la hora en formato hh:mm:ss
        const formattedTime = now.toLocaleTimeString();

        console.log('HORA: ' , formattedTime); // Salida: "13:45:30"

        console.log(event.target.files);
        let selectedFile = event.target.files[0];
        // this.onFileSelected(event);
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);

        const dataIncident = {
            incidentId: 0,
            name: '',
            policyId : 0,
            policy: '',
            userId: 0,
            insured: '',
            typeIncidentId: 0,
            contact: '',
            folioSurexs: '',
            folioInsurance: '',
            kinshipId: 0,
            kinship: '',
            groupId: 0,
            clientId: 0,
            description: '',
            medicalCondition: '',
            classification: 1,
            typeRequestIncidentId: 0,
            incidentIdOld: 0
        };

        if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            // tslint:disable-next-line:no-shadowed-variable
            fileReader.onload = (event) => {
                console.log('event: ', event);
                // @ts-ignore
                const binaryData = event.target.result;
                const workBook = XLSX.read(binaryData, {type: 'binary'});

                // Obtener los datos de la hoja de cálculo en forma de matriz JSON
                const sheetName = workBook.SheetNames[0];
                const worksheet = workBook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                const params = jsonData.map((ob) => {
                    const tmp = {
                        tipoSiniestro: this.getTipoSiniestro(ob['tipo_siniestro']),
                        noSerie: ob['no_serie'],
                        dondeOcurrio: this.getDondeOcurrio(ob['donde_ocurrio_choque']),
                        referenciasLugar: null,
                        tipoAccidente: this.getComoFueAccidente(ob['como_fue_accidente']),
                        necesitaGrua: ob['necesitan_grua'] === 'Si' ? true : false,
                        hayLesionados: ob['hay_lesionados'] === 'Si' ? true : false,
                        hayTercerAuto: ob['hay_carro_involucrado'] === 'Si' ? true : false,
                        observaciones: ob['observaciones'],
                        asistencia: ob['tipo_asistencia'] ? this.getTipoAsistencia(ob['tipo_asistencia']) : null
                    };

                    const data = {...tmp, ...dataIncident};
                    data.typeIncidentId = +tmp.tipoSiniestro;
                    data.classification = 1;

                    return data;
                });

                this.presenta = true;
                this.api.saveMassiveIncidentCar(params).then((res: any) => {
                    console.log(res);
                    this.infoNoValidos = res.noValidos;
                    this.infoValidos = res.validos;
                });
            }
        }
    }

    getTipoSiniestro(tipoSiniestro: string): number {
        switch (tipoSiniestro) {
            case 'Daños Materiales (Colisión)':
                return 2;
            case 'Robo Total':
                return 6;
            case 'Robo Parcial':
                return 11;
            case 'Asistencia vial':
                return 10;
            case 'Cristales':
                return 112;
            case 'Responsabilidad Civil':
                return 7;
        }
    }

    getDondeOcurrio(dondeOcurrio: string): number {
        switch (dondeOcurrio) {
            case 'Ciudad':
                return 1;
            case 'Carretera (Federal)':
                return 2;
            case 'Autopista (Cuota)':
                return 3;
        }
    }

    getTipoAsistencia(tipoAsistencia: string): number {
        switch (tipoAsistencia) {
            case 'Grua':
                return 1;
            case 'Paso de Corriente':
                return 2;
            case 'Cerrajero':
                return 3;
            case 'Cambio de llantas':
                return 4;
        }
    }

    getComoFueAccidente(tipoAsistencia: string): number {
        switch (tipoAsistencia) {
            case 'Grua':
                return 1;
            case 'Paso de Corriente':
                return 2;
            case 'Cerrajero':
                return 3;
            case 'Cambio de llantas':
                return 4;
        }
    }

    nuevaCarga(): void {
        this.presenta = false;
        this.infoNoValidos = [];
        this.infoValidos = [];
    }

    procesaInfo(): void {
        this.toastr.success('Información procesada correctamente', 'Notificación');
        this.nuevaCarga();
    }

    goToBack() {
        this.router.navigate(['/admin/incidents']);
    }

}
