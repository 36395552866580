import {Component, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-cobranza-car-policy',
  templateUrl: './cobranza-car-policy.component.html',
  styleUrls: ['./cobranza-car-policy.component.css']
})
export class CobranzaCarPolicyComponent implements OnInit {
  paymentType = 1;
  netPremium;
  rights;
  paymentCharges;
  chargesPercentage;
  commission;
  commissionAmount;
  taxId;
  iva;
  totalPremium;
  catPaymentTypes: any = [];
  catTaxes: any = [];
  show = true;
  receiptList: any = [];
  paymentList: any = [];
  statusList: any = [];
  modalRef: BsModalRef;
  bsStartDate: Partial<BsDatepickerConfig>  = new BsDatepickerConfig();
  paymentDate: any;
  receiptStatusId: any;
  paymentFormId: any;

  constructor( private api: ApiService,
               private router: Router,
               private toastr: ToastrService,
               private shared: SharedService,
               private datePipe: DatePipe,
               public fb: FormBuilder,
               private spinner: NgxSpinnerService,
               private modalService: BsModalService,
               ) { }

  ngOnInit() {
    this.bsStartDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });
    this.getTypePayments();
    this.loadDataTaxes();
    this.receiptList = [
      {numberReceipt: '1/4',
        paymentDate: '2022-02-01',
        netPremium: 31160.75,
        rights: 1745.00,
        iva: 5572.44,
        totalPremium: 38170.67},
      {numberReceipt: '2/4',
        paymentDate: '2022-05-01',
        netPremium: 31160.75,
        rights: 0,
        iva: 5572.44,
        totalPremium: 38170.67},
      {numberReceipt: '3/4',
        paymentDate: '2022-08-01',
        netPremium: 31160.75,
        rights: 0,
        iva: 5572.44,
        totalPremium: 38170.67},
      {numberReceipt: '4/4',
        paymentDate: '2022-11-01',
        netPremium: 31160.75,
        rights: 1745.00,
        iva: 5572.44,
        totalPremium: 38170.67},
    ];


  }

  getTypePayments() {
    this.api.getTypePayments().then((data: any) => {
      this.catPaymentTypes = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  loadDataTaxes() {
    this.api.getTaxes().then((data: any) => {
      this.catTaxes = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  generateReceipt() {
    this.show = false;
  }

  renewReceipt() {
    this.show = true;
  }

  openMdlAttachment(template: TemplateRef<any>, receipt) {
    this.getStatusReceipts();
    this.getPaymentForms();
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }

  getStatusReceipts() {
    this.api.getStatusReceipts().then((data: any) => {
      this.statusList = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }
  getPaymentForms() {
    this.api.getPaymentForms().then((data: any) => {
      this.paymentList = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  closeModal() {
    this.modalRef.hide();
  }

}
