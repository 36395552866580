import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-catalog-user-client',
  templateUrl: './catalog-user-client.component.html',
  styleUrls: ['./catalog-user-client.component.css']
})
export class CatalogUserClientComponent implements OnInit {

  groupList: any = [];
  filters: any = {};
  userList: any = [];
  paginationResponse: any = {};
  modalRef: BsModalRef;
  userId: number;
  selectionHigh: boolean;
  catalogForm = this.fb.group({
    catalogs: ['groups', [Validators.required]]
  });
  flag: number;
  myFormUser: FormGroup;
  clientList: any = [];
  groupSelected: number;
  isInsured: boolean;
  insuredIncidentList: any = [];
  keywordIncident = 'groupName';
  @ViewChild('user', {static: true}) user;
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  dataUser: any = {};
  catGenders: any = [];
  operationModal: number;
  catGroups: any = [];
  branchList: any = [];
  branchSelectedItems: any = [];
  dropdownSettingsBranch = {};
  // -Varibales para paginado->
  pgReceiptBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgReceiptMaxSize = 3; // Número de links en paginado
  pgReceiptItemsPerPage = 10; // Número de registros por página
  pgReceiptTotalItems: number; // Total de registros
  pgReceiptCurrentPage = 1; // Página actual
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService,
              private validateAccess: ValidateAccessService) {
    this.myFormUser = this.fb.group({
      group: ['', [Validators.required]],
      subGroup: ['', [Validators.required]],
      name: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      insured: [''],
      branch: ['']
    });

  }

  ngOnInit() {
    this.invokeServiceGetBranches();
    this.invokeServiceGetGenders();
    this.dropdownSettingsClients = {
      singleSelection: false,
      idField: 'clientId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsBranch = {
      singleSelection: false,
      idField: 'branchId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON RAMOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.initFiltersOrders();
    this.getGroups();
    this.selectionHigh = false;
    this.flag = 1;
    this.isInsured = false;
    this.validateAccess.validateAccessCore(43).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getPaymentOrdersByPagination(1, 10);
      }
    });
  }
  initDataUser() {
    this.dataUser = {
      userId: 0,
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      groupId: 0,
      insurance: false,
      clientList: [],
      groupName: '',
      gender: 1
    };
  }
  loadFiltersTable() {
    this.validateAccess.validateAccessCore(43).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getPaymentOrdersByPagination(1, 10);
      }
    });
  }
  initFiltersOrders() {
    this.filters = {
      groupId: 0,
      search: '',
      page: 1,
      size: 10
    };
  }
  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupList = data;
      this.catGroups = data;
    }, error => {
    });
  }
  getPaymentOrdersByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.api.getUsersAdminClientPagination(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.pgReceiptTotalItems = 0;
            this.userList = [];
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON EL FILTRO SELECCIONADO', 'NOTIFICACIÓN');
          } else {
            this.paginationResponse = response;
            this.pgReceiptTotalItems = this.paginationResponse.total ;
            this.userList = this.paginationResponse.users ;
          }
          this.spinner.hide('sp');
        }, error => {
          this.pgReceiptTotalItems = 0;
          this.userList = [];
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  pageReceiptChanged(event: PageChangedEvent): void {
    this.pgReceiptCurrentPage = event.page;
    // console.log(this.pgReceiptCurrentPage);
    this.getPaymentOrdersByPagination(this.pgReceiptCurrentPage, this.pgReceiptItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() {
  }

  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }
  closeModal() {
    this.userId = 0;
    this.selectionHigh = false;
    this.modalRef.hide();
  }
  confirmDelete() {
    // console.log(this.userId);
    this.api.deleteUserAdminClient(this.userId).subscribe( response => {
      this.getPaymentOrdersByPagination(1, 10);
      this.closeModal();
      this.toastr.success('SE HA ELIMINADO CORRECTAMENTE EL USUARIO', 'NOTIFICACIÓN');
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }
  loadCatalog(value) {
    this.flag = value.catalogs;
  }
  selectHigh() {
    switch (this.flag) {
      case 1:
        this.isInsured = true;
        break;
      case 2:
        this.isInsured = false;
        break;
    }
    this.dataUser.insurance = this.isInsured;
    this.selectionHigh = true;
  }
  openModal(template: TemplateRef<any>, operation: number, id: number) {
    let accessCore = 0;
    if (operation === 0) {
      accessCore = 44;
    } else if (operation === 1) {
      accessCore = 45;
    } else if (operation === 2) {
      accessCore = 46;
    }
    this.validateAccess.validateAccessCore(accessCore).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.operationModal = operation;
        this.initDataUser();
        this.clientsSelectedItems = [];
        this.groupSelected = 0;
        switch (operation) {
          case 0:
            this.myFormUser.get('group').enable();
            this.flag = 1;
            this.selectionHigh = false;
            this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
            break;
          case 1:
            this.invokeServiceGetUserById(id);
            this.myFormUser.get('group').disable();
            this.selectionHigh = true;
            this.isInsured = false;
            this.flag = 2;
            this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});

            break;
          case 2:
            this.myFormUser.get('group').enable();
            this.userId = id;
            this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
            break;
        }
      }
    });
  }

  changeGroupToConsult(event: any) {
    if (event !== undefined) {
      this.dataUser.groupId = event.groupId;
      const group = [];
      group.push(event.groupId);
      let multiGroup = {};
      multiGroup = {
        idsGroups: group
      };
      this.getSubGroups(multiGroup);
      if (this.isInsured) {
        this.invokeServiceGetUserByGroup(event.groupId);
      }
    }
  }
  getSubGroups(groups) {
    this.api.getSubGroupsMultiselect(groups).then((data: any) => {
      this.clientList = data;
    }, error => {
    });
  }
  invokeServiceSave() {
    // console.log(this.dataUser);
    this.spinner.show('sp');
    this.api.postSaveUserAdmin(this.dataUser)
      .then(
        (response: any) => {
          if (response != null) {
            this.toastr.success('EL USUARIO SE REGISTRO CORRECTAMENTE', 'NOTIFICACIÓN');
          } else {
            this.toastr.warning('EL USUARIO YA EXISTE COMO ADMINISTRADOR', 'NOTIFICACIÓN');
          }
          this.selectionHigh = false;
          this.closeModal();
          this.spinner.hide('sp');
          this.getPaymentOrdersByPagination(1, 10);
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
          this.selectionHigh = false;
          this.closeModal();
        }
      );
  }


  invokeServiceUpdate() {
    // console.log(this.dataUser);
    this.spinner.show('sp');
    this.api.postUpdateUserAdmin(this.dataUser)
      .then(
        (response: any) => {
          this.toastr.success('EL USUARIO SE ACTUALIZO CORRECTAMENTE', 'NOTIFICACIÓN');
          this.selectionHigh = false;
          this.closeModal();
          this.spinner.hide('sp');
          this.getPaymentOrdersByPagination(1, 10);
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
          this.selectionHigh = false;
          this.closeModal();
        }
      );
  }

  selectInsuredIncident(item) {
    this.dataUser.userId = item.userId;
    this.dataUser.name = item.name;
    this.dataUser.firstName = item.firstName;
    this.dataUser.lastName = item.lastName;
    this.dataUser.email = item.email;
    this.dataUser.gender = item.groupId;

  }

  inputClearedInsuredIncident() {
    this.user.close();
  }

  changeStatusSubGroups() {
    const subGroups = [];
    setTimeout(() => {
      try {
        this.clientsSelectedItems.forEach(item => {
          subGroups.push(item.clientId);
        });
        this.dataUser.clientList = subGroups;
      } catch (e) { }
    }, 500);
  }

  changeStatusBranch() {
    const branches = [];
    setTimeout(() => {
      try {
        this.branchSelectedItems.forEach(item => {
          branches.push(item.branchId);
        });
        this.dataUser.branchList = branches;
      } catch (e) { }
    }, 500);
  }

  invokeServiceGetUserByGroup(groupId) {
    this.api.getUserByGroup(groupId).then((data: any) => {
      if (data == null) {
        this.insuredIncidentList = [];
        return;
      }
      this.insuredIncidentList = data;
    }, error => {
    });
  }
  invokeServiceGetGenders() {
    const data1 = {name: 'GENDERS'};
    this.api.getCatalogByBusiness(data1).then((data: any) => {
      if (data != null) {
        this.catGenders = data;
      }
    }, error => {
    });
  }
  getSelectedGender(event) {
    // console.log('getSelectedGender');
    // console.log(event);
  }
  invokeServiceGetUserById(id: number) {
    this.spinner.show('sp');
    this.api.getUserAdminById(id).then((data: any) => {
      // this.clientList = data;
      this.dataUser.userId = data.userId;
      this.dataUser.name = data.name;
      this.dataUser.firstName = data.firstName;
      this.dataUser.lastName = data.lastName;
      this.dataUser.email = data.email;
      this.dataUser.gender = data.gender;
      this.dataUser.groupId = data.groupId;
      this.groupSelected = data.groupId;
      this.dataUser.clientList = data.clientList;
      this.dataUser.branchList = data.branchList;
      const clients = [];
      const group = [];
      const branches = [];
      group.push(this.dataUser.groupId);
      let multiGroup = {};
      multiGroup = {
        idsGroups: group
      };
      setTimeout(() => {
        this.getSubGroups(multiGroup);
      }, 500);
      setTimeout(() => {
        data.clientList.forEach(item => {
          this.clientList.forEach(client => {
            if (item === client.clientId) {
              clients.push(client);
            }
          });
        });
        this.clientsSelectedItems = clients;
        this.spinner.hide('sp');
        data.branchList.forEach(b => {
          this.branchList.forEach( bl => {
            if (b === bl.branchId) {
              branches.push(bl);
            }
          });
        });
        this.branchSelectedItems = branches;
        if (this.operationModal === 1 ) {
          this.myFormUser.get('email').disable();
        }
      }, 1000);
    }, error => {
    });
  }
  invokeServiceGetBranches() {
    this.branchList = [
      {branchId: 1, name: 'BENEFICIOS'},
      {branchId: 2, name: 'DAÑOS'},
      {branchId: 3, name: 'AUTOS'}
    ];
  }
}
