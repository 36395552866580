import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'save-dynamic-report',
    templateUrl: 'save.component.html'
})
export class SaveDynamicReportComponent implements OnInit {

    public onClose: Subject<any>;

    envioProgramadoForm: FormGroup;

    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.onClose = new Subject();
        this.createForm();
    }

    createForm(): void {
        this.envioProgramadoForm = this.formBuilder.group({
            name: [null, Validators.required],
            description: [null, Validators.required],
        });
    }

    close(): void {
        this.envioProgramadoForm.markAllAsTouched();
        if (this.envioProgramadoForm.invalid) {
            return;
        }

        const data = this.envioProgramadoForm.value;
        this.onClose.next({ value: data });
        this.bsModalRef.hide();
    }

}
