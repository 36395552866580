import { AfterContentInit, Component, ContentChild, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

import { ControlValidators } from './control-validator.service';

@Component({
  selector: 'control-validator',
  templateUrl: 'control-validator.component.html',
  providers: [ControlValidators]
})
export class ValidatorComponent implements AfterContentInit {

  @ContentChild(NgControl, { static: false })
  ngControl: any;

  @Input()
  messages: any;

  constructor(private controlValidators: ControlValidators) { }

  ngAfterContentInit(): void {
    if (!this.ngControl) {
      console.warn('Control validator without ngControl');
    }
  }

  get errorMessage() {
    if (this.ngControl) {
      for (const propertyName in this.ngControl.errors) {
        if (this.ngControl.errors.hasOwnProperty(propertyName) &&
          this.ngControl.touched ||
          this.ngControl._parent && this.ngControl._parent.submitted) {

          return this.controlValidators.getValidatorErrorMessage(propertyName, this.ngControl.errors[propertyName], this.messages);
        }
      }
    }

    return null;
  }
}
