export class CoverageModel {
  id: number;
  description: string;
  value: string;
  visible: string;
  medition: string;

  constructor(id: number, description: string, visible: string, medition: string) {
    this.id = id;
    this.description = description;
    this.visible = visible;
    this.medition = medition;
  }
}

export class CategoryCoverageModel {
  idCategoria: number;
  idCoverage: number;
  nameCoverage: string;
  coverageList: Array<CoverageModel>;
  constructor() {
    this.coverageList = new Array<CoverageModel>();
  }
}
