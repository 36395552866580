import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-attachment-request-mdl',
  templateUrl: './attachment-request-mdl.component.html',
  styleUrls: ['./attachment-request-mdl.component.css']
})
export class AttachmentRequestMdlComponent implements OnInit {
  modalRef: BsModalRef;
  modalDelete: BsModalRef;
  dataToSendIssuePolicy: any = {};
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  responseImportInsured: any = [];
  insuredProcess: number;
  insuredUnprocessed: number;
  importMassive: boolean;
  groupList: any = [];
  catClients: any = [];
  policyList: any = [];
  categoriesList: any = [];
  subgroup: any;
  group: any;
  policy: any;
  categoryId: any;
  policyId: any;
  clientName: any;
  clientId: any;
  policyName: any;
  categoryName: any;
  selectedType = 0;
  policiesSelectedItems: any = [];
  dropdownSettingsPolicies = {};
  listRequestTemp: any[];
  idRequestTemp: any;
  constructor(private modalService: BsModalService,
              private toastr: ToastrService,
              private shared: SharedService,
              private api: ApiService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) { }

  ngOnInit() {
    this.dropdownSettingsPolicies = {
      singleSelection: true,
      idField: 'categoryId',
      textField: 'policyList',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON PÓLIZAS ACTIVAS',
      searchPlaceholderText: 'BUSCAR'
    };
  }

  openModal(template: TemplateRef<any>) {
    this.validateAccess.validateAccessCore(85).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.subgroup = null;
        this.group = null;
        this.policyId = null;
        this.categoryId = null;
        this.policyList = [];
        this.policiesSelectedItems = [];
        this.loadInitialModal();
        this.getGroups();
        this.fileUploadXlsx = null;
        this.modalRef = this.modalService.show(template, {class: 'modal-mg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
      }
    });
  }

  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupList = data;
    }, error => {
    });
  }

  SendPolicy() {
    if (this.fileUploadXlsx === null || this.fileUploadXlsx === undefined ) {
        this.toastr.warning('DEBE DE INGRESAR EL ARCHIVO SOLICITADO', 'NOTIFICACION');
    } else {
      this.spinner.show('sp');

      const formData = new FormData();
      formData.append('file', this.fileUpload);
      const formDataXlsx = new FormData();
      formDataXlsx.append('fileXlsx', this.fileUploadXlsx);

      if (this.selectedType === 0)  {
        this.api.postImportRequest(formDataXlsx, this.subgroup)
          .subscribe(
            responseInsured => {
              this.responseImportInsured = responseInsured;
              this.toastr.success('El proceso de importación ha iniciado, en cuanto termine se le notificará.', 'Notificación');
              this.insuredUnprocessed = 0;
              this.insuredProcess = 0;
              this.importMassive = true;
              this.cleanFiles();
              this.spinner.hide('sp');
              this.modalRef.hide();
            }, error => {
              console.log(error);
              this.toastr.warning(error.error);
              console.error();
              this.spinner.hide('sp');
            }
          );
      } else {
        this.api.postImportCertificates(formDataXlsx)
          .subscribe(
            responseInsured => {
              this.responseImportInsured = responseInsured;
              this.toastr.success('El proceso de importación ha iniciado, en cuanto termine se le notificará.', 'Notificación');
              this.insuredUnprocessed = 0;
              this.insuredProcess = 0;
              this.importMassive = true;
              this.cleanFiles();
              this.spinner.hide('sp');
              this.modalRef.hide();
            }, error => {
              console.log(error);
              this.toastr.warning(error.error);
              console.error();
              this.spinner.hide('sp');
            }
          );
      }
    }
  }

  downloadLayout() {

    if (this.subgroup === undefined || this.subgroup === null) {
      this.toastr.warning('Es necesario seleccionar el subgrupo para poder generar el layout');
      return;
    }
    this.spinner.show('sp');
    this.api.getLayoutMassiveRequestLoadInsured(this.subgroup)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',  'LAYOUT_SOLICITUDES.xlsx');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  onChangeInputFileXlsx(e) {
    this.errorXlsx = {active: false, msg: ''};
    this.fileUploadXlsx = e.target.files[0];
    console.log(this.fileUploadXlsx);
    if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
      this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
      this.fileUploadXlsx = null;
    }
  }

  loadInitialModal() {
    this.importMassive = false;
    this.dataToSendIssuePolicy = {
      policyId: localStorage.getItem('policy-id'),
      statusId: 1,
      number: ''
    };
    this.fileUpload = undefined;


  }
  closeModal() {
    this.listRequestTemp = [];
    this.modalRef.hide();
    this.cleanFiles();
  }
  cleanFiles() {
    this.fileUploadXlsx = undefined;
  }

  invokeGetSubgrupobyGroup(idGroup) {
    if (idGroup !== undefined ) {
      const group = idGroup.groupId;
      this.api.getSubGroups(group).then((data: any) => {
        this.catClients = data;
      }, error => {

      });
    }
  }

  onChangeSubgrupo() {
    this.invokeServiceForGetRequestLayoutTemp();
  }




  invokeGetCategoryByPolicies(idPolicy) {
    if (idPolicy !== undefined) {
      for (const i in this.policyList) {
        if (this.policyList[i].policyId === idPolicy) {
          this.policyName = this.policyList[i].number;
        }
      }

      this.api.getCategoriesByPolicyIdClientId(idPolicy, this.clientId).then((data: any) => {
        this.categoriesList = data;
      }, error => {

      });
    }
  }

  getCategory(idPolicy) {
    this.categoryName = idPolicy.name;
  }

  clearGroup() {
    this.subgroup = undefined;
    this.catClients = [];
    this.policyList = [];
    this.categoriesList = [];
  }

  clearClients() {
    this.policy = null;
    this.subgroup = undefined;
    this.policyList = [];
    this.categoriesList = [];
  }

  changePolicies(event) {
    this.categoryId = event.categoryId;
    this.policyId = event.policyList.split('-')[0];
  }

  invokeServiceForGetRequestLayoutTemp() {
    this.api.getRequestTemp(this.subgroup).then(
      (response: any) => {
        this.listRequestTemp = response;

      }, error => {
        console.error(error);
      }
    );
  }

  deleteRequestTemp() {
    this.api.deleteRequestTemp(this.idRequestTemp)
      .then(
        response => {
          this.invokeServiceForGetRequestLayoutTemp();
          this.modalDelete.hide();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          console.error();
        }
      );
  }

  openModalDeleteRegister(template: TemplateRef<any>, requestTempId) {
    this.idRequestTemp = requestTempId;
    this.modalDelete = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
  }

  seeRequestTempDetail(idRequestTemp) {
    localStorage.removeItem('requestTemp-id');
    localStorage.removeItem('subgroupId');
    localStorage.removeItem('groupId');
    localStorage.setItem('requestTemp-id', idRequestTemp);
    localStorage.setItem('subgroupId', this.subgroup);
    localStorage.setItem('groupId', this.group);
    this.router.navigate(['/admin/request-detail']);
  }
}
