import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';
import {WebsocketService} from '../websocket.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { PushNotificationsService} from 'ng-push';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  lclContentSidebar: number;
  userId: string;
  chatForm: FormGroup;
  msgList: Array<any> = [];

  constructor(public shared: SharedService,
              private wsRocket: WebsocketService,
              private fb: FormBuilder,
              private pushNotifications: PushNotificationsService) {
    this.pushNotifications.requestPermission();
    this.chatForm = this.fb.group({
      message: ['', [Validators.required]],
    });

    this.shared.fStreamRoomMessagesObservable().subscribe(
      fStreamRoomMessages => {
        if (fStreamRoomMessages) {
          this.userId = localStorage.getItem('ruid');
          this.streamingRoomMessages();
        }
      });

    this.shared.fContentSidebarObservable().subscribe(
      sbId => {
        this.lclContentSidebar = sbId;
        if (this.lclContentSidebar === 1) {
          setTimeout(() => {
            this.shared.gMessageCount = 0;
          }, 1000);
        }
      }
    );
  }

  ngOnInit() {
    // this.wsRocket.connectToHost();
    // this.wsRocket.signIn('mrsapper', 'Qwerty123.');
    // this.wsRocket.signIn('jramirez', 'Qwerty123.');
    // this.wsRocket.listChannels();
    // this.streamingRoomMessages();
  }

  streamingRoomMessages() {
    this.wsRocket.streamRoomMessages().subscribe(
      (data) => {
        console.info(data);
        this.msgList.push(data.fields.args[0]);
        if (this.lclContentSidebar !== 1) {
          this.shared.gMessageCount++;
          const lastMessage = this.msgList[this.msgList.length - 1].msg;
          this.webNotify(lastMessage);
        }
      },
      (err) => console.log(err),
      () => console.log('Completed')
    );
  }

  sendMessage(text: string) {
    // console.log('I am sending :' + this.chatForm.value.message);
    text = this.chatForm.value.message;
    this.wsRocket.sendChatMessage(text).subscribe(
      (data) => console.log('Send message response :' + JSON.stringify(data))
    );
    this.chatForm.reset();
  }

  webNotify(message) {
    const options = { // set options
      body: message,
      icon: 'assets/img/favicon.ico', // adding an icon
      silent: false,
      sound: 'assets/sounds/chime.mp3'
    };
    this.pushNotifications.create('Soporte Surexs', options).subscribe( // creates a notification
      res => console.log(res),
      err => console.log(err)
    );
  }


}
