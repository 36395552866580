import { Component, OnInit, ViewChild } from '@angular/core';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  @ViewChild('first', {static: false}) first;
  @ViewChild('second', {static: false}) second;
  @ViewChild('tres', {static: false}) tres;
  constructor(private ngWizardService: NgWizardService) { }
  listFirst:any=[];
  itemSelectFist:any={};
  itemSelectSecod:any={};
  listSecond:any=[];
  itemSelectTres:any={};
  listTres:any=[];
  keywordFirst="cValue";
  keywordSecond="cValue";
  keywordTres="cValue";
  ngOnInit() {
      this.listFirst=[{"cKey":"1","cValue":"MEXICO"},{"cKey":"2","cValue":"USA"}];
      this.listSecond=[{"cKey":"1","cValue":"OAXACA"},{"cKey":"2","cValue":"CHIAPAS"}];
      this.listTres=[{"cKey":"1","cValue":"NEZAHUALCOYOTL"},{"cKey":"2","cValue":"CHIMALHUACAN"}];
  }
  selectStateFirst(event){
    console.log('selectStateFirst');
    console.log(event);
    this.itemSelectFist=event;

  }
  selectStateSecond(event){
    console.log('selectStateSecond');
    console.log(event);
    this.itemSelectSecod=event;
    this.listTres=[{"cKey":"1","cValue":"NEZAHUALCOYOTL"},{"cKey":"2","cValue":"CHIMALHUACAN"}];
  }
  clearStatic(e): void {
      console.log('clear');
      e.stopPropagation();
      this.first.clear();
  }
  closed(){
    console.log('closed');
  }
  clearFirst(){
    console.log('clearFirst');
    this.itemSelectFist={};
    this.itemSelectSecod={};
    this.itemSelectTres={};
    this.listFirst=[{"cKey":"1","cValue":"MEXICO"},{"cKey":"2","cValue":"USA"}];
    this.listSecond=[];
    this.listTres=[];
    this.cleanAutocomplete("first");


  }
  selectStateTres(event){
    console.log('selectStateSecond');
    console.log(event);
    this.itemSelectTres=event;
  }
  clearTres(){
    console.log('clearFirst');
    this.itemSelectTres={};

  }
  clearSecond(){
    console.log('clearSecond');
    this.listTres=[];
    this.itemSelectSecod={};
    this.itemSelectTres={};

  }

  getValues(){
    console.log('this.itemSelectFist');
    console.log(this.itemSelectFist);
    console.log('this.itemSelectSecod');
    console.log(this.itemSelectSecod);
    console.log('this.tres');
    console.log(this.itemSelectTres);

  }
  public cleanAutocomplete(idField){
    switch(idField){
      case "first":{

        this.first.clear();
        this.first.close();break;
        this.second.clear();
        this.second.close();break;
        this.tres.clear();
        this.tres.close();break
      } break;
      case "stateSecond":{
         this.second.clear();
         this.second.close();
         this.tres.clear();
         this.tres.close();
      } break;
      case "stateTres":{
        this.tres.clear();
        this.tres.close();
      } break;
    }
  }

}
