import {Component, HostListener, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SharedService} from '../shared-service.service';
import {timeout} from 'rxjs/operators';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-insured',
  templateUrl: './insured.component.html',
  styleUrls: ['./insured.component.css']
})
export class InsuredComponent implements OnInit {
  USER_ID = localStorage.getItem('user-id');
  // -Varibales para paginado de Siniestros->
  pgIncidentBoundaryLinks = false; // Bandera que indidca si se muestra Inicio/Fin del paginado
  pgIncidentMaxSize = 3; // Número de links en paginado
  pgIncidentItemsPerPage = 4; // Número de registros por página
  pgIncidentTotalItems: number; // Total de registros
  pgIncidentCurrentPage = 1; // Página actual
  // -Varibales para paginado de Siniestro<-

  // -Varibales para paginado de Solicitudes->
  pgRequestBoundaryLinks = false; // Bandera que indidca si se muestra Inicio/Fin del paginado
  pgRequestMaxSize = 3; // Número de links en paginado
  pgRequestItemsPerPage = 4; // Número de registros por página
  pgRequestTotalItems: number; // Total de registros
  pgRequestCurrentPage = 1; // Página actual
  // -Varibales para paginado de Solicitudes<-

  idFlipCard: string; // -Almacena el Id de la póliza para mostrar su reverso
  lclPolicyDetail: any = {}; // -Almacena el detalle de la póliza al consultar el reverso

  countPolicy;
  listPolicies;
  listIncidents;
  listtypepolicy: any = [];
  listtypeinciden;
  lclPolicyType: any = {};
  lclIncidentType: any = {};
  policyType;
  policyId;
  bsModalRef: BsModalRef;
  incidentGridViewType: boolean;
  requestGridViewType: boolean;
  insuredId;

  incidentsList: any = [];

  statusCatRequestsList: any = [];
  lclStatusRequestSelected: any = {};
  requestList: any = [];

  widthSize: number = window.innerWidth;
  statusRequest: any;
  idStatus: number;
  isCollapsable = true;
  modalRef: BsModalRef;
  lclRequest: any = [];
  isRequesCar = false;
  lclRequestDetail: any = [];
  comments: string;
  polizas: any;
  operationType = 0;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.widthSize = window.innerWidth;
  }
  constructor(private router: Router,
              private api: ApiService,
              private modalService: BsModalService,
              private toastr: ToastrService,
              public shared: SharedService) {
    this.shared.fUpdateIncidentsObservable().subscribe(
      fIncidents => {
        if (fIncidents) {
          // this.getIncidentuser(this.lclPolicyType.typeId);
          this.loadincidents(this.lclIncidentType);
          this.shared.fUpdateIncidents.next(false);
        }
      }
    );
    this.shared.fUpdateRequestsObservable().subscribe(
      fRequests => {
        if (fRequests) {
          console.info('ACTUALIZA LAS SOLICICTUDES');
          this.invokeServiceForGetRequests(this.lclStatusRequestSelected);
          this.shared.fUpdateIncidents.next(false);
        }
      }
    );

    this.shared.fInsuredDataReadonly = this.router.url === '/admin/insured';

    this.shared.pgInsuredObservable().subscribe(
      ginsuredId => {
        this.getPolicySize(ginsuredId);
        this.gettypepolicyuser(ginsuredId);
        this.invokeServiceForGetStatusCatRequests();
        this.insuredId = ginsuredId;
      }
    );
  }

  ngOnInit() {
    this.incidentGridViewType = true;
    this.requestGridViewType = true;
  }

  flipThisCard(policyId, carId, event) {
    event.stopPropagation();
    this.lclPolicyDetail = {};
    if (this.idFlipCard === (policyId + '' + carId)) {
      this.idFlipCard = undefined;
    } else {
      this.idFlipCard = (policyId + '' + carId);
      this.getPolicyDetail(policyId, carId);
    }
  }

  getPolicyDetail(policyId, carId) {
    this.api.getDetailPolicy(policyId, this.insuredId, carId)
      .then(
        (data) => {
          this.lclPolicyDetail = data;
        }, error => {
          console.error(error);
        });
  }

  getPolicySize(insuredId) {
    this.api.getPoliciesSize(insuredId)
      .then(
        (data) => {
          this.countPolicy = data;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  invokeServiceForGetPolicyCertificateFile(attachmentId) {
      this.api.getAttachmentId(attachmentId)
        .subscribe(
          (response: any) => {
            // // console.log(response);
            if (response == null) {
              this.toastr.info('No existen archivos para descargar');
              return;
            } else {
              const dataType = response.type;
              const binaryData = [];
              binaryData.push(response);
              const downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
              downloadLink.setAttribute('download', 'certificado.pdf');
              document.body.appendChild(downloadLink);
              downloadLink.click();
              downloadLink.remove();
            }
          }, error => {
            // console.error(error);
          }
        );
  }

  getIncidentuser(typePolicyId) {
    this.api.getUserincident(typePolicyId)
      .then(
        (data) => {
          this.listIncidents = data;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  gettypepolicyuser(insuredId) {
    this.api.getTypePolicies(insuredId)
      .then(
        (data) => {
          this.listtypepolicy = (data);
          this.loadpolicy(data[0], insuredId);
          this.lclPolicyType = data[0];
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  invoketypeincidents() {
    this.listtypeinciden = [
      {
        id: 0,
        description: 'Todos'
      },
      {
        id: 1,
        description: 'Aceptado'
      },
      {
        id: 2,
        description: 'Pendiente'
      },
      {
        id: 3,
        description: 'Rechazado'
      }
    ];
    this.lclIncidentType = this.listtypeinciden[0];
    this.loadincidents(this.listtypeinciden[0]);
  }

  loadpolicy(policyType, insuredId) {
    this.lclPolicyType = policyType;
    this.api.getUserPoliciesType(policyType.typeId, insuredId)
      .then(
        (data) => {
          this.listPolicies = data;
          this.policyType = policyType.typeId;
          // this.getIncidentuser(policyType.typeId);
          this.invoketypeincidents();
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  loadincidents(incident) {
    this.lclIncidentType = incident;
    this.api.getUserTypeIncident(this.insuredId, incident.id, this.policyType, this.pgIncidentCurrentPage, this.pgIncidentItemsPerPage)
      .subscribe(
        (data) => {
          const oData: any = data;
          this.pgIncidentTotalItems = oData.total;
          this.incidentsList = oData.incidents;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }
  setIncidentGridView(value) {
    this.pgIncidentCurrentPage = 1;
    this.pgIncidentItemsPerPage = value ? 4 : 10;
    this.incidentGridViewType = value;
    this.loadincidents(this.lclIncidentType);
  }
  displayPolicyDetail(policyId) {
    this.shared.gPolicyId = new BehaviorSubject<number>(-1);
    this.shared.gPolicyId.next(policyId);
    this.router.navigate(['/admin/policies']);

    /*
    this.shared.fOpenSidebar(4,(this.widthSize <= 768) ? 'full' : '');
    this.shared.gPolicyId.next(policyId);*/
  }

  displayIncidentDetail(incidentId) {
    this.shared.fOpenSidebar(5, (this.widthSize <= 768) ? 'full' : '');
    this.shared.gIncidentId = new BehaviorSubject<number>(-1);
    this.shared.gIncidentId.next(incidentId);
  }

  goToIncident(incidentId) {
    localStorage.setItem('incidentId', incidentId);
    this.router.navigate(['/admin/incident_detail']);
  }

  /**
   * Cálcula el inicio y fin del páginado
   *
   * @param PageChangedEvent event Información del páginadod
   */
  pageIncidentChanged(event: PageChangedEvent): void {
    this.pgIncidentCurrentPage = event.page;
    this.loadincidents(this.lclIncidentType);
  }

  /*----------------------------------------------------------------------------------------------------------------------------------------
   ------------------------------------------------------S O L I C I T U D E S--------------------------------------------------------------
   ---------------------------------------------------------------------------------------------------------------------------------------*/

  invokeServiceForGetStatusCatRequests() {
    this.api.getStatusCatRequests()
      .then(
        (data) => {
          this.statusCatRequestsList = data;
          this.lclStatusRequestSelected = this.statusCatRequestsList[1];
          this.invokeServiceForGetRequests(this.lclStatusRequestSelected);
        }, error => {
          console.error(error);
        });
  }
  invokeServiceForGetRequests(status) {
    this.lclStatusRequestSelected = status;
    this.api.getRequestTypeGeneral(this.insuredId, this.lclStatusRequestSelected.statusRequestId, this.pgRequestCurrentPage, this.pgRequestItemsPerPage)
      .subscribe((data) => {
        const oData: any = data;
        this.pgRequestTotalItems = oData.total;
        this.requestList = oData.requestList;
      }, error => {
        console.info(error);
      });
  }
  setRequestGridView(value) {
    this.pgRequestCurrentPage = 1;
    this.pgRequestItemsPerPage = value ? 4 : 10;
    this.requestGridViewType = value;
    this.invokeServiceForGetRequests(this.lclStatusRequestSelected);
  }

  displayRequestDetail(requestId) {
    //this.shared.fOpenSidebar(3,(this.widthSize <= 768) ? 'full' : '');
    const dataSend = {
      relationid: requestId,
      eventtype: 3,
      eventid: requestId
    };
    localStorage.setItem('eventView', JSON.stringify(dataSend));
    this.shared.fCloseSidebar();
    setTimeout( () => {
      this.shared.fOpenSidebar(23, 'md');
    }, 700);

    // this.shared.gRequestId.next(requestId);
  }

  /**
   * Cálcula el inicio y fin del páginado
   *
   * @param PageChangedEvent event Información del páginadod
   */
  pageRequestChanged(event: PageChangedEvent): void {
    this.pgRequestCurrentPage = event.page;
    this.invokeServiceForGetRequests(this.lclStatusRequestSelected);
  }

  requestGeneralModal(r) {
    localStorage.setItem('request-id', r);
  }

  openModalRequestDetail(template: TemplateRef<any>, requestId, status, idStatus?) {
    this.getRequestDetail(requestId);
    this.statusRequest = status;

    /*this.idStatus = idStatus;
    if (this.idStatus == 4) {
      this.isCollapsable = false;
    } else {
      this.isCollapsable = true;
    }*/

    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  async getRequestDetail(requestId) {
    const data = {
      requestId
    };
    this.api.getRequest(data)
      .then(
        (response: any) => {
          if (response.requestProcessUser !== undefined && response.requestProcessUser.length !== 0) {
            this.isRequesCar = false;
            this.lclRequestDetail = response.requestProcessUser;
            let countTitular = 0;

            if (response.requestProcessUser[0].operationType === 2) {
              this.lclRequestDetail = [... new Map (response.requestProcessUser.map(item =>
                [item.policyId, item])).values()];
              countTitular = this.lclRequestDetail.length;

            } else {
              response.requestProcessUser.forEach(value => {
                if (value.typeKinshipId === 10) {
                  countTitular++;
                }
              });
            }
            this.polizas = new Array(countTitular);
            this.comments = this.lclRequestDetail[0].comments;
            this.operationType = this.lclRequestDetail[0].operationType;
          } else {
            this.isRequesCar = true;
            this.lclRequestDetail = response.requestProcessCar;
          }

        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }
  closeModal() {
    this.modalRef.hide();
    this.comments = '';
  }
}
