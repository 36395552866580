import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';
import {QuoteService} from '../quote.service';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-emission-form',
  templateUrl: './emission-form.component.html',
  styleUrls: ['./emission-form.component.css']
})
export class EmissionFormComponent implements OnInit {

  catColoniesList: any = [];

  dataToSend: any = {};
  vehicleSelected: any = {};
  constructor(public shared: SharedService,
              private quoteApi: QuoteService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.dataToSend.town = undefined;

    this.dataToSend.gender = 1;
    this.dataToSend.insurer = 1;
    this.dataToSend.nationality = 1;

    this.invokeServiceForGetInsuredData(this.shared.gQuote.insuredId);
  }

  private invokeServiceForGetInsuredData(insuredId) {
    this.quoteApi.getInsuredData(insuredId)
      .subscribe(
        (response: any = {}) => {
          console.info(response);
          this.dataToSend.idInsuredData = response.idInsuredData;
          this.dataToSend.birthDate = response.birthDate;
          this.dataToSend.gender = response.idCatGender;
          this.dataToSend.postcode = response.postcode;
          this.dataToSend.state = response.state;
          this.invokeServiceForGetColoniesByPostcode(response.postcode);
        }, error => {
          console.info(error);
        });
  }

  private launchProgressAlert() {
    Swal.fire({
      title: `Emitiendo cotización ${this.shared.gQuote.quoteNumber}`,
      html: `Se esta realizando la emisión de sus datos, por favor espere un momento`,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
      onClose: () => {
      }
    });
  }

  sendRequestData() {
    this.launchProgressAlert();
    console.info(this.dataToSend);
    const insured = {
      idInsuredData: this.dataToSend.idInsuredData,
      personType: 1,
      rfc: this.dataToSend.rfc,
      name: this.dataToSend.name,
      pLastName: this.dataToSend.pLastName,
      sLastName: this.dataToSend.sLastName,
      idCatGender: this.dataToSend.gender,
      maritalStatus: this.dataToSend.maritalStatus,
      birthDate: this.dataToSend.birthDate,
      email: this.dataToSend.email,
      phone: this.dataToSend.phone,
      nationality: this.dataToSend.nationality,
      birthCountry: this.dataToSend.birthCountry,

      street: this.dataToSend.street,
      externalNumber: this.dataToSend.externalNumber,
      internalNumber: this.dataToSend.internalNumber,
      colony: this.dataToSend.colony.cValue,
      colonyCode: this.dataToSend.colony.cKey,
      town: this.dataToSend.town,
      townCode: this.dataToSend.townCode,
      state: this.dataToSend.state,
      stateCode: this.dataToSend.stateCode,
      postcode: this.dataToSend.postcode,
      country: this.dataToSend.country,
      engine: this.dataToSend.engine,
      series: this.dataToSend.series,
      plate: this.dataToSend.plate
    };

    const dataToSend: any = {};
    dataToSend.insured = insured;
    const quoteId = this.shared.gQuote.quoteId;
    if (this.shared.gQuote.insurerId === 2) {
      this.quoteApi.doGnpEmission(quoteId, insured)
        .subscribe(
          (response) => {
            console.info(response);
            this.shared.gQuote = {movementId: response, insurerId: 2};
            this.shared.fCloseSidebar();
            Swal.close();
            setTimeout( () => {
              this.shared.fOpenSidebar(14, 'md');
            }, 700);
          }, error => {
            console.error(error);
            if (error.status === 400) {
              Swal.fire({
                icon: 'error',
                title: 'Ups...',
                text: `${error.error.description}. (${error.status})`
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ups...',
                text: `Ocurrió un problema al realizar la emisión de sus datos. (${error.status})`
              });
            }
          }
        );
    } else if (this.shared.gQuote.insurerId === 1) {
      this.quoteApi.doQltEmission(quoteId, insured)
        .subscribe(
          (response: number) => {
            console.info(response);
            this.shared.gQuote = {movementId: response, insurerId: 1};
            this.shared.fCloseSidebar();
            Swal.close();
            setTimeout( () => {
              this.shared.fOpenSidebar(14, 'md');
            }, 700);
          }, error => {
            console.error(error);
            if (error.status === 400) {
              Swal.fire({
                icon: 'error',
                title: 'Ups...',
                text: `${error.error.message} (${error.status})`
              });
              this.dataToSend.version = undefined;
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ups...',
                text: `Ocurrió un problema al realizar la emisión de sus datos. (${error.status})`
              });
            }
          }
        );
    }
  }

  private invokeServiceForGetColoniesByPostcode(postcode) {
    this.quoteApi.getColoniesByPostCode(postcode)
      .subscribe(
        (response: any = {}) => {
          this.catColoniesList = response;
        }, error => {
          console.error(error);
        });
  }

  public colonySelected() {
    this.invokeServiceForGetTownByColonyCode(this.dataToSend.colony.cKey);
  }

  private invokeServiceForGetTownByColonyCode(colonyCode) {
    this.quoteApi.getTownByColonyCode(this.dataToSend.postcode, colonyCode)
      .subscribe(
        (response: any = {}) => {
          console.info(response);
          this.dataToSend.town = response.cValue;
          this.dataToSend.townCode = response.cKey;
        }, error => {
          console.error(error);
        });
  }

}
