import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {DatePipe} from '@angular/common';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-graph-incidents',
  templateUrl: './graph-incidents.component.html',
  styleUrls: ['./graph-incidents.component.css']
})
export class GraphIncidentsComponent implements OnInit {

  reportInvcidentList: any = [];
  // -Varibles para paginado de usuarios->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filtersHistogram: any = [];
  modalRef: BsModalRef;
  typeIncidentList = [];
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          beginAtZero: true,
          min: 0
        }
      }]
    },
  };
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];
  barChartData: ChartDataSets[] = [];
  barChartColors = [
    {
      backgroundColor: [
        'rgba(68, 138, 166, 1)',
        'rgba(50, 97, 115, 1)',
        'rgba(1, 24, 38, 1)',
        'rgba(68, 138, 166, 1)',
        'rgba(68, 138, 166, 1)',
        'rgba(68, 138, 166, 1)',
        'rgba(68, 138, 166, 1)',
        'rgba(68, 138, 166, 1)',
        'rgba(68, 138, 166, 1)',
        'rgba(68, 138, 166, 1)',
        'rgba(68, 138, 166, 1)',
        'rgba(68, 138, 166, 1)']
    }
  ];

  private _filters: any = {};
  reportList: any = [];
  @Input()
  typeChart: any;

  constructor(private api: ApiService,
              private toastr: ToastrService,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private datePipe: DatePipe,
              private validateAccess: ValidateAccessService) {
  }

  ngOnInit() {
  this.getIncidentTypes();

  }

  loadChart() {
    this.barChartData = [];
    this.barChartLabels = [];
    this.spinner.show('sp');
    this._filters.page = this.typeChart;
    this.api.getIncidents(this._filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.reportList = [];
            this.spinner.hide('sp');
            return;
          }
          this.reportList = response;
          this.creatChart();
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.reportList = [];
          this.spinner.hide('sp');
        }
      );
  }



  creatChart() {
    const data = {
      data: [],
      label: 'casos'
    };
    // Obtenemos las etiquetas
    this.reportList.forEach(X => {
      this.barChartLabels.push(X.incidentType);
      data.data.push(X.number);
    });
    this.barChartData.push(data);
  }

  chartClicked(template: TemplateRef<any>, e: any): void {
    this.validateAccess.validateAccessCore(2).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
      } else {
        if (e.active.length > 0) {
          const chart = e.active[0]._chart;
          const activePoints = chart.getElementAtEvent(e.event);

          if ( activePoints.length > 0) {

            const clickedElementIndex = activePoints[0]._index;
            const incidentType = chart.data.labels[clickedElementIndex].replace(/['"]+/g, '');
            const index = this.typeIncidentList.findIndex(INCIDENT => INCIDENT.description === incidentType);

            this.filtersHistogram = {
              idGrupo: this._filters.idGrupo,
              idSubgrupo: this._filters.idSubgrupo,
              idRamo: this._filters.idRamo,
              idSubRamo: this._filters.idSubRamo,
              idEjecutivo: this._filters.idEjecutivo,
              idAseguradora: this._filters.idAseguradora,
              idIncidentType: [this.typeIncidentList[index].typeIncidentId],
              idRequestType: [],
              idStatus: this._filters.idStatus,
              dateStart: this._filters.dateStart,
              dateEnd: this._filters.dateEnd,
              page: 1,
              size: 10
            };
            this.openModalRestorePassword(template);
            this.getByPagination(1, 10);

          }
        }
      }
    });
  }

  /**
   * Función para obtener los usuarios del sistema
   */
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.api.getReportsIncidentGraph(this.filtersHistogram)
      .then(
        (response: any) => {
          if (response == null) {
            this.reportInvcidentList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.reportInvcidentList = this.paginationResponse.tList;
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.reportInvcidentList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }
  openModalRestorePassword(template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }

  get filters(): any {
    return this._filters;
  }
  @Input()
  set filters(value: any) {
    this._filters = value;
    this.barChartData = [];
    this.barChartLabels = [];
    this.loadChart();
  }

  /**
   * Carga los datos según la página seleccionada
   */
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
  }

  getIncidentTypes() {

    this.api.getIncidentTypesReport().then((data: any) => {
      this.typeIncidentList = data;

    }, error => {

    });
  }

}
