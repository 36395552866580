import { Component, TemplateRef, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {Event as NavigationEvent, NavigationEnd, Router} from '@angular/router';
import {ValidateAccessService} from "../validate-access.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-admin-client',
  templateUrl: './admin-client.component.html',
  styleUrls: ['./admin-client.component.css']
})
export class AdminClientComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig>;
  groupsList: any = [];
  typePersonList: any = [];
  executiveSurexsList: any = [];
  branchesList: any = [];
  clientsList: any = [];
  businessNameList: any = [];
  numbersPoliciesList: any = [];
  dropdownSettingsGroups: any = {};
  groupSelectedItems: any = {};
  typePersonSelectedItems: any = {};
  executiveSurexsSelectedItems: any = {};
  branchSelectedItems: any = {};
  dataFilters: any = {};
  // ids
  idsGroupSelected: any = [];
  idsTypePerson: any = [];
  idsExecutiveSurexs: any = [];
  nameBussinessSelected: any = [];
  idPoliciesSelected: any = [];
  idsBranch: any = [];
  filterCheckActive = false;
  /*variables para paginancion */
   pgClientBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
   pgClientTotalItems: number; // Total de registros
   pgClientCurrentPage = 1; // Página actual
   pgClientMaxSize = 3; // Número de links en paginado
   pgClientItemsPerPage: number;
   page = 1;
   size = 10;
  keyword = 'description';
  keywordBussinesName = 'description';
  modalRef: BsModalRef;
  selectTypeContact: any = {};
  dataClientEdit: any = {};
  constructor(private api: ApiService,
              private modalService: BsModalService,
              private router: Router,
              private validateAccess: ValidateAccessService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.invokeServiceGetGroupsList();
    this.invokeServiceGetBusinessName();
    this.invokeServiceGetExecutiveSurexs();
    this.invokeServiceGetTypePerson();
    this.invokeServiceGetNumbersPolicies();
    this.pgClientItemsPerPage = this.size; // Número de registros por página
    this.invokeServiceGetBranches();
    this.dataFilters = {
      size: this.size,
      page: this.page,
      groupIds: []
      , typePersonIds: []
      , ejecutiveSurexsIds: []
      , bussinessNames: []
      , policyIds: []
      , branchIds: []
      , status: ''
    };
    this.invokeServiceGetClientByFilters(this.dataFilters);
    this.dropdownSettingsGroups = {
      singleSelection: false,
      idField: 'id',
      textField: 'description',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON DATOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dataClientEdit = {
        isEdit: true
        , clientId: 0
    };
  }
  getIdsSelecteds(selectedItems) {
    const idsSelected = [];
    for (const item in selectedItems) {
        idsSelected.push(selectedItems[item].id);
      }
    return idsSelected;
  }
  getClientsByFilter() {
      this.pgClientCurrentPage = 1;
      this.idsGroupSelected = this.getIdsSelecteds(this.groupSelectedItems);
      this.idsTypePerson = this.getIdsSelecteds(this.typePersonSelectedItems);
      this.idsExecutiveSurexs = this.getIdsSelecteds(this.executiveSurexsSelectedItems);
      this.idsBranch = this.getIdsSelecteds(this.branchSelectedItems);

      this.dataFilters = {
        size: this.size,
        page: this.page,
        groupIds: this.idsGroupSelected
        , typePersonIds: this.idsTypePerson
        , ejecutiveSurexsIds: this.idsExecutiveSurexs
        , bussinessNames: this.nameBussinessSelected
        , policyIds: this.idPoliciesSelected
        , branchIds: this.idsBranch
        , status: (document.getElementById('checkFilterActive') as HTMLInputElement).value === 'on'
      };

      this.invokeServiceGetClientByFilters(this.dataFilters);
  }
  getClientsChangePage(page, size) {
     this.dataFilters = {
      size,
      page,
      groupIds: this.idsGroupSelected
       , typePersonIds: this.idsTypePerson
       , ejecutiveSurexsIds: this.idsExecutiveSurexs
        , bussinessNames: this.nameBussinessSelected
        , policyIds: this.idPoliciesSelected
        , branchIds: this.idsBranch
        , status: ''
    };
     this.invokeServiceGetClientByFilters(this.dataFilters);
  }
  invokeServiceGetGroupsList() {
    const data = {name: 'GROUPS'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
                 if (data != null) {
                  this.groupsList = data;
                 }
               }, error => {
                 console.error(error.status);
                 console.error(error.statusText);
                 console.error(error.message);
    });
  }
  invokeServiceGetTypePerson() {
    const data = {name: 'TYPE_PERSON'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
                 if (data != null) {
                  this.typePersonList = data;
                 }
               }, error => {
                 console.error(error.status);
                 console.error(error.statusText);
                 console.error(error.message);
    });
  }

  invokeServiceGetExecutiveSurexs() {
    const data = {name: 'EJECUTIVES_SUREXS'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
                 if (data != null) {
                  this.executiveSurexsList = data;
                 }
               }, error => {
                 console.error(error.status);
                 console.error(error.statusText);
                 console.error(error.message);
    });
  }
  invokeServiceGetBusinessName() {
    const data = {name: 'BUSINESS_NAME'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
                 if (data != null) {
                  this.businessNameList = data;
                 }
               }, error => {
                 console.error(error.status);
                 console.error(error.statusText);
                 console.error(error.message);
    });
  }
  invokeServiceGetNumbersPolicies() {
      const data = {name: 'NUMBERS_POLICIES'};
      this.api.getCatalogByBusiness(data).then((data: any) => {
                   if (data != null) {
                    this.numbersPoliciesList = data;
                   }
                 }, error => {
                   console.error(error.status);
                   console.error(error.statusText);
                   console.error(error.message);
      });
    }

  invokeServiceGetBranches() {
      const data = {name: 'BRANCHES'};
      this.api.getCatalogByBusiness(data).then((data: any) => {
                   if (data != null) {
                    this.branchesList = data;
                   }
                 }, error => {
                   console.error(error.status);
                   console.error(error.statusText);
                   console.error(error.message);
      });
    }
    invokeServiceGetClientByFilters(data) {

      this.api.getClientsByFilters(data).then((data: any) => {
                   if (data != null) {
                    this.clientsList = data;
                    this.pgClientTotalItems = data[0].total;
                   } else {
                      this.clientsList = [];
                      this.pgClientTotalItems = 0;
                   }
                 }, error => {
                   console.error(error.status);
                   console.error(error.statusText);
                   console.error(error.message);
      });
    }
  /***  PAGINACION **/
    pageClientChanged(event: PageChangedEvent): void {
        this.pgClientCurrentPage = event.page;
        this.getClientsChangePage(this.pgClientCurrentPage, this.pgClientItemsPerPage);

      }
    changeColorPagination() {
    }
  selectPolicy(item) {

      this.idPoliciesSelected = [];
      this.idPoliciesSelected.push(item.id);
    }
    onChangeSearchPolicy(val: string) {
    }
    onFocusedPolicy(e) {
    }
    clearPolicy() {
      this.idPoliciesSelected = [];
    }
    selectBussinesName(item) {
      this.nameBussinessSelected = [];
      this.nameBussinessSelected.push(item.description);
    }
    onChangeSearchBussinesName(val: string) {
    }
    onFocusedBussinesName(e) {
    }
    clearBussinesName() {
      this.nameBussinessSelected = [];
    }
    cleanFilter() {
      this.pgClientCurrentPage = 1;
      const matches = document.querySelectorAll<HTMLElement>('.x');
      if (matches !== null && matches !== undefined && matches.length > 0) {
         for (const i in matches) {
            if (matches[i].style !== undefined) {
              // matches[i].style.display = "none";
              matches[i].click();
            }
         }
      }

      this.idPoliciesSelected = [];
      this.nameBussinessSelected = [];
      this.groupSelectedItems = {};
      this.typePersonSelectedItems = {};
      this.executiveSurexsSelectedItems = {};
      this.numbersPoliciesList = [];
      this.businessNameList = [];
      this.branchSelectedItems = {};
      this.dataFilters = {
        size: this.size,
        page: this.page,
        groupIds: []
        , typePersonIds: []
        , ejecutiveSurexsIds: []
        , bussinessNames: []
        , policyIds: []
        , branchIds: []
        , status: ''
      };
      this.invokeServiceGetClientByFilters(this.dataFilters);
      this.invokeServiceGetBusinessName();
      this.invokeServiceGetNumbersPolicies();

    }
    clickCheckActive(event) {
      console.log('ever');
      console.log(event.target.checked);
      this.filterCheckActive = event.target.checked;
    }
    openModalSelectTypeClient(template: TemplateRef<any>) {
      this.validateAccess.validateAccessCore(12).then((access: any) => {
        console.log('valor que regresa', access.accessCore);
        if (access.accessCore === 0) {
          this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
        } else {
          this.modalRef = this.modalService.show(template);
        }
      });
    }

    getSelectedTypeNewClient() {
      localStorage.removeItem('typePerson');
      localStorage.removeItem('client-id');
      localStorage.removeItem('selectTypeContact');
      localStorage.removeItem('isEditClient');
      localStorage.removeItem('isView');
      localStorage.removeItem('isViewFromPolicie');


      localStorage.setItem('selectTypeContact', this.selectTypeContact);
      console.log('tipo de cliente', localStorage.getItem('selectTypeContact'));
      localStorage.setItem('isEditClient', 'false');
      localStorage.setItem('isViewFromPolicie', 'false');
      this.router.navigate(['/admin/new_client']);
      this.modalRef.hide();
    }
    editDataClient(i) {
      this.validateAccess.validateAccessCore(13).then((access: any) => {
        console.log('valor que regresa', access.accessCore);
        if (access.accessCore === 0) {
          this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
        } else {
          localStorage.removeItem('typePerson');
          localStorage.removeItem('client-id');
          localStorage.removeItem('selectTypeContact');
          localStorage.removeItem('isEditClient');
          localStorage.removeItem('isView');

          localStorage.setItem('typePerson', i.typePerson);
          localStorage.setItem('client-id', i.clientId);
          localStorage.setItem('isEditClient', 'true');
          localStorage.setItem('isView', 'false');
          // navega al router de clienteguardando el local store la bandera que indica que sera edicion
          this.router.navigate(['/admin/new_client']);
        }
      });
    }

    viewDetail(i) {
      this.validateAccess.validateAccessCore(14).then((access: any) => {
        console.log('valor que regresa', access.accessCore);
        if (access.accessCore === 0) {
          this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
        } else {
          localStorage.removeItem('typePerson');
          localStorage.removeItem('client-id');
          localStorage.removeItem('selectTypeContact');
          localStorage.removeItem('isEditClient');
          localStorage.removeItem('isView');

          localStorage.setItem('typePerson', i.typePerson);
          localStorage.setItem('client-id', i.clientId);
          localStorage.setItem('isEditClient', 'false');
          localStorage.setItem('isView', 'true');
          this.router.navigate(['/admin/new_client']);
        }
      });
    }
}
