import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';
import {WebsocketService} from '../websocket.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  roleId = Number(localStorage.getItem('roleId')); // Rol del usuario
  user: any = {}; // Variable donde se almacena el perfil del usuario
  constructor(
    public sharedService: SharedService,
    private wsRocket: WebsocketService
  ) { }

  ngOnInit() {
    this.user.imageProfile = this.sharedService.gProfileImg;
    this.user.name = this.sharedService.gProfileName;
    this.user.position = this.sharedService.gProfilePosition;
  }

  closeSession() {
    localStorage.clear();
    this.sharedService.fStreamRoomMessages.next(false);
    this.wsRocket.disconnectToHost();
    // this.router.navigate(['/login']).then();
    window.open('/', '_self');
  }
}
