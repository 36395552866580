import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-admin-payment-orders',
  templateUrl: './admin-payment-orders.component.html',
  styleUrls: ['./admin-payment-orders.component.css']
})
export class AdminPaymentOrdersComponent implements OnInit {
  groupList: any = [];
  groupsSelectedItems: any = [];
  dropdownSettingsGroups = {};
  filters: any = {};
  receiptsList: any = [];
  paginationResponse: any = {};
  // -Varibales para paginado->
  pgReceiptBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgReceiptMaxSize = 3; // Número de links en paginado
  pgReceiptItemsPerPage = 10; // Número de registros por página
  pgReceiptTotalItems: number; // Total de registros
  pgReceiptCurrentPage = 1; // Página actual
  branchList: any = [];
  branchSelectedItems: any = [];
  dropdownSettingsBranch: any = {};
  subBranchList: any = [];
  subBranchSelectedItems: any = [];
  dropdownSettingsSubBranch: any = {};
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private spinner: NgxSpinnerService) {

  }

  ngOnInit() {
    this.dropdownSettingsGroups = {
      singleSelection: false,
      idField: 'groupId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsBranch = {
      singleSelection: false,
      idField: 'branchId',
      textField: 'branchType',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON RAMAS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSubBranch = {
      singleSelection: false,
      idField: 'subBranchId',
      textField: 'subBranch',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB RAMOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.invokeServiceGetBranches();
    this.initFiltersOrders();
    this.getGroups();
    this.getPaymentOrdersByPagination(1, 10);
  }
  initFiltersOrders() {
    this.filters = {
      groupList: [],
      branchList: [],
      subBranchList: [],
      page: 1,
      size: 10
    };
  }
  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupList = data;
    }, error => {
    });
  }
  changeStatusGroups() {
    const groups = [];
    let groupsName = '';
    const group = [];
    setTimeout(() => {
      try {
        this.groupsSelectedItems.forEach(item => {
          groups.push(item.name);
          group.push(item.groupId);
          groupsName = groupsName + item.name + ', ';
        });
        let multiGroup = {};
        multiGroup = {
          idsGroups: group
        };
        this.filters.groupList = groups;
        this.getPaymentOrdersByPagination(1, 10);
      } catch (e) { }
    }, 1000);
  }
  getPaymentOrdersByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.api.getPaymentOrdersPagination(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.pgReceiptTotalItems = 0;
            this.receiptsList = [];
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON EL FILTRO SELECCIONADO', 'NOTIFICACIÓN');
          } else {
            this.paginationResponse = response;
            this.pgReceiptTotalItems = this.paginationResponse.total ;
            this.receiptsList = this.paginationResponse.paymentOrders ;
          }
          this.spinner.hide('sp');
        }, error => {
          this.pgReceiptTotalItems = 0;
          this.receiptsList = [];
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  pageReceiptChanged(event: PageChangedEvent): void {
    this.pgReceiptCurrentPage = event.page;
    console.log(this.pgReceiptCurrentPage);
    this.getPaymentOrdersByPagination(this.pgReceiptCurrentPage, this.pgReceiptItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() {
  }
  downloadExcel() {
    this.api.getExcelFilePaymentOrder(this.filters)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', 'ordenes_trabajo.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          // console.error(error);
        });
  }
  loadPolicy(policy) {
    if (policy.branch === 'DAÑOS') {
      this.shared.fPolicyDataReadonly = true;
      this.shared.gPolicyIdDamage.next(policy.policyId);
      this.router.navigate(['/admin/damage-policy']);
    } else {
      this.shared.fPolicyDataReadonly = true;
      this.shared.gPolicyId.next(policy.policyId);
      this.router.navigate(['/admin/policies']);
    }
  }
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }
  invokeServiceGetBranches() {
    this.api.getBranches().then((data: any) => {
      this.branchList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  invokeServiceGetSubBranches(id) {
    this.api.getSubBranchesMulti(id).then((data: any) => {
      this.subBranchList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  changeStatusBranch() {
    const branch = [];
    const branches = [];
    setTimeout(() => {
      try {
        this.branchSelectedItems.forEach(item => {
          branch.push(item.branchId);
          branches.push(item.branchType);
        });
        this.invokeServiceGetSubBranches(branch);
        this.filters.branchList = branch;
        this.getPaymentOrdersByPagination(1, 10);
      } catch (e) { }
    }, 500);
  }
  changeStatusSubBranch() {
    const subBranch = [];
    setTimeout(() => {
      try {
        this.subBranchSelectedItems.forEach(item => {
          subBranch.push(item.subBranchId);
        });
        this.filters.subBranchList = subBranch;
        this.getPaymentOrdersByPagination(1, 10);
      } catch (e) { }
    }, 500);
  }
}
