import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  /** :fUpdateProfile Bandera para identificar si es necesario recargar los datos de perfil de usuario */
  public fUpdateProfile: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :fUpdateIncident Bandera para identificar si es necesario recargar el apartado de Siniestros */
  public fUpdateIncidents: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :fUpdateRequests Bandera para identificar si es necesario recargar el apartado de Solicitudes */
  public fUpdateRequests: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :gPolicyId Variable global para almacenar el Id de la póliza seleccionada */
  public gPolicyId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  /** :gIncidentId Variable global para almacenar el Id del Siniestro seleccionado */
  public gIncidentId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  /** :gRequestId Variable global para almacenar el Id de la Solicitud seleccionada */
  public gRequestId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  /** :gRequestId Variable global para almacenar el Id de la Solicitud General seleccionada */
  public gRequestGeneralId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  /** :fStreamRoomMessages Bandera para identificar si es posible realizar el stream de la sala de chat */
  public fStreamRoomMessages: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** :gMessageCount Variable global para almacenar el número de mensajes no leídos en el chat */
  public gMessageCount = 0;
  /** :fInsuredDataReadonly Bandera para identificar si la información del asegurado es de solo lectura */
  public fInsuredDataReadonly = false;
  /** :gPolicyId Variable global para almacenar el Id de la póliza seleccionada */
  public gReceiptPolicyId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  /** :fAdminDashboard Bandera para identificar sí se muestra el dashboard General o el de Concesionarios
   * 0 = General
   * 1 = Concesionarios
   */
  public fAdminDashboard = 0;
  /** :gCurrentRole Variable global para almacenar el Id del rol del usuario en sesión */
  public gCurrentRoleId: number;
  /** :gCurrentCustomerId Variable global para almacenar el Id del Cliente que se esta mostrando su información */
  public gCurrentCustomerId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);


  /** :gProfileImg Variable global para almacenar la imagen de perfil del usuario logeado */
  public gProfileImg: string;

  /** :gProfileImg Variable global para almacenar el nombre de perfil del usuario logeado */
  public gProfileName: string;

  /** :gProfileImg Variable global para almacenar la posiciòn de perfil del usuario logeado */
  public gProfilePosition: string;

  /** :gUserRoles Variable global para almacenar los roles del usuario logeado */
  public gUserRoles: any = [];
  /** :pginsuredId Variable global para almacenar el Id del asegurado seleccionado */
  public pginsuredId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);

  /** -------------<| Variables para Admin open |>-------------- */

  /** :gQuote Variable global para almacenar la información de la cotización en uso */
  public gQuote: any = {};

  /** -------------<| Variables para Admin close |>-------------- */

  public pgincident: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  // Arreglo global para indicar los registros por página en paginados
  public pgItemPerPageOpt = [5, 10, 15];

  // :fIncidentGeneral Almacena el tipo de filtro que se debe de aplicar en el módulo de Siniestros (Menú superior)
  public fIncidentGeneral: BehaviorSubject<number> = new BehaviorSubject(0); // <- por default 'Todos'

  // :fContentSidebar Almacena el identificador para el componente que se debe de mostrar en el sidebar
  public fContentSidebar: BehaviorSubject<number> = new BehaviorSubject(-1);

  /** :fPolicyDataReadonly Bandera para identificar si la información del documento es de solo lectura */
  public fPolicyDataReadonly = true;
  /** :variables para realizar busqueda general */
  gSearchId = 0;

  /** :fUpdateHome Bandera para identificar que componentes presentar en el home */
  public fUpdateHome: BehaviorSubject<number> = new BehaviorSubject(1);

  /** :gPolicyIdCategory Variable global para almacenar el Id de la póliza seleccionada y recargar categorias */
  public gPolicyIdCategory: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  /** :gPolicyIdEndorsement Variable global para almacenar el Id de la póliza seleccionada y recargar endosos */
  public gPolicyIdEndorsement: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  // todo ammia lite
  public gPolicyIdLite: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  /** :gPolicyIdDamage poliza daños */
  public gPolicyIdDamage: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  /**
   * Convierte un número de bytes a su equivalente en unidad de medida mas grande
   *
   * @param bytes Número de bytes
   * @param decimals Número de puunto decimales a utilizar
   * @returns  Resultado de la conversión
   */
  public formatBytes(bytes, decimals) {
    if (bytes === 0) {return '0 Bytes'; }
    const k = 1000;
    const  dm = decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   * Observable para monitorear los cambios en la variable :fUpdateHome
   * true: presenta las gráficas del dashboard
   * false: el componente de gestion
   * @see NavbarComponent
   */
  fUpdateHomeObservable(): Observable<number> {
    return this.fUpdateHome.asObservable();
  }

  /**
   * Observable para monitorear los cambios en la variable :fUpdateProfile
   * true: Actualiza los datos de perfil
   * @see NavbarComponent
   */
  fUpdateProfileObservable(): Observable<boolean> {
    return this.fUpdateProfile.asObservable();
  }

  /**
   * Observable para monitorear los cambios en la variable :fUpdateIncidents
   * true: Actualiza los registros de Siniestros
   * @see HomeComponent
   */
  fUpdateIncidentsObservable(): Observable<boolean> {
    return this.fUpdateIncidents.asObservable();
  }

  /**
   * Observable para monitorear los cambios en la variable :fUpdateRequests
   * true: Actualiza los registros de Solicitudes
   * @see HomeComponent
   */
  fUpdateRequestsObservable(): Observable<boolean> {
    return this.fUpdateRequests.asObservable();
  }

  /**
   * Observable para monitorear los cambios en la variable :gCurrentCustomerId
   * Al sufrir un cambio debe invocar todos los servicios del dashboard Admin
   * @see HomeComponent
   */
  gCurrentCustomerIdObservable(): Observable<number> {
    return this.gCurrentCustomerId.asObservable();
  }

  /**
   * Observable para monitorear los cambios en la variable :gPolicyId
   * Almacena el ID de la póliza seleccionada
   * @see HomeComponent
   * @see PolicyComponent
   */
  gPolicyIdObservable(): Observable<number> {
    return this.gPolicyId.asObservable();
  }
  gReceiptPolicyIdObservable(): Observable<number> {
    return this.gReceiptPolicyId.asObservable();
  }
  gIncidentIdObservable(): Observable<number> {
    return this.gIncidentId.asObservable();
  }
  gRequestIdObservable(): Observable<number> {
    return this.gRequestId.asObservable();
  }
  fStreamRoomMessagesObservable(): Observable<boolean> {
    return this.fStreamRoomMessages.asObservable();
  }

  fIncidentGeneralObservable(): Observable<number> {
    return this.fIncidentGeneral.asObservable();
  }

  fContentSidebarObservable(): Observable<number> {
    return this.fContentSidebar.asObservable();
  }

  pgIncidentObservable(): Observable<any> {
    return this.pgincident.asObservable();
  }

  pgInsuredObservable(): Observable<any> {
    return this.pginsuredId.asObservable();
  }
  gRequestIdGeneralObservable(): Observable<number> {
    return this.gRequestGeneralId.asObservable();
  }

  gPolicyIdCategoryObservable(): Observable<number> {
    return this.gPolicyIdCategory.asObservable();
  }

  gPolicyIdEndorsementObservable(): Observable<number> {
    return this.gPolicyIdEndorsement.asObservable();
  }
  // todo ammia lite
  gPolicyIdLiteObservable(): Observable<number> {
    return this.gPolicyIdLite.asObservable();
  }
  gPolicyIdDamageObservable(): Observable<number> {
    return this.gPolicyIdDamage.asObservable();
  }

  /* Set the width of the sidebar to 340px and the left margin of the page content to 340px */
  fOpenSidebar(sbIdentifier, size?) {
    let sidebarSize;
    const small = '340px';
    const middle = '500px';
    const large = '800px';
    const full = '97%';
    switch (size) {
      case 'lg':
        sidebarSize = large;
        break;
      case 'md':
        sidebarSize = middle;
        break;
      case 'full':
        sidebarSize = full;
        break;
      default:
        sidebarSize = small;
        break;
    }
    // console.log(sidebarSize);
    this.fContentSidebar.next(sbIdentifier);
    document.getElementById('sidebar').style.width = sidebarSize;
    document.getElementById('event').style.marginRight = sidebarSize;
    document.getElementById('navbar').style.marginRight = sidebarSize;
  }
  fCloseSidebar() {
    document.getElementById('sidebar').style.width = '0';
    document.getElementById('event').style.marginRight = '0';
    document.getElementById('navbar').style.marginRight = '0';
    setTimeout(() => {
      this.fContentSidebar.next(-1);
    }, 500);
  }







  /** :gfilterRangeDate Variable global para almacenar los filtros de fechas de la gráficas */
  public gfilterRangeDate: Date[];

  /** :gfilterAgency Variable global para almacenar las agencias para los datos de gráficas */
  public gfilterAgency: number[] = [];

  /** :gSkin Variable global para almacenar el color del skin */
  public gSkin: string;

  /** :gIsUpdateUser Variable global para indicar si es una actualización de usuario */
  public gIsUpdateUser: boolean;

  /** :updateUserId Variable global para almacenar el id del usuario que será modificado */
  public updateUserId: boolean;

  /** :gLogo Variable global para almacenar el logo */
  public gLogo: string;

  /** :gLogo Variable global para almacenar la lista de mensajes del chat */
  public gMsgList: any = [];

  /** :fUpdateChartData Bandera para identificar si es necesario recargar los datos de las gráficas */
  public fUpdateChartData: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** :gShowForm Variable global para indicar si se muestra el formulario de cotización */
  public gShowForm: boolean;


  /* ******************************************* AGENCIAS ********************************************** */
  /**
   * Observable para monitorear los cambios en la variable :fUpdateChartData
   * true: Actualiza los datos de las gráficas de agencias
   * @see AdminHomeSuperComponent
   */
  fUpdateChartDataObservable(): Observable<boolean> {
    return this.fUpdateChartData.asObservable();
  }

}
