import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {TabDirective, TabsetComponent} from 'ngx-bootstrap/tabs';
import {CategoryCoverageModel, CoverageModel} from '../admin-policies/coverage-model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DualListComponent} from 'angular-dual-listbox';
import {SpecialCoverageModel} from '../admin-policies/special-coverage-model';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {NgxSpinnerService} from 'ngx-spinner';
import {regex} from '../../common/regex';
declare var $: any;
@Component({
  selector: 'app-admin-policy-principal',
  templateUrl: './admin-policy-principal.component.html',
  styleUrls: ['./admin-policy-principal.component.css']
})
export class AdminPolicyPrincipalComponent implements OnInit {

  policyAttachmentList = [];
  url: string;

  reportGridViewType = true;
  lclSectionSelected: number;
  catClients: any = [];
  catGroups: any = [];
  catInsuranceCompany: any = [];
  catAgentKeys: any = [];
  catBranch: any = [];
  catSubBranch: any = [];
  policyId: number;
  categoryId: number;
  carId: number;
  medicalCare: number;
  // Objetos para los DatePickers ->
  bsStartDate: Partial<BsDatepickerConfig>  = new BsDatepickerConfig();
  bsExpirationDate: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  category = 'CATEGORIA';
  saTypeSelected: any = {};
  deTypeSelected: any = {};
  typeList: any = [];
  coverageSa: string;
  coverageDe: string;
  categoryList: any = [];
  categoryList1: any = [];
  bCoverage: number;
  attachmentCatalog: any = [];
  catPaymentTypes: any = [];
  catTypePayments: any = [];
  flag: number;
  public fileUpload: File;
  public fileUploadAttachment: File;
  public fileUploadPolicy: File;
  public fileRestart: File;
  error = {active: false, msg: ''};
  receiptData: any = {};
  fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'];
  fileTypesPolicy: string[] = ['application/pdf'];
  flagFile: boolean;
  // coverageList: any = [];
  dataToSendGeneral: any = {};
  dataToSendAttachment: any = {};

  dataToSendReceipt: any = {};
  listDataToSendReceipt: any [];
  dataToSendCategory: any = {};
  dataToSendCancelPolicy: any = {};
  dataReceiptEdith: any = {};
  typeSubBranch: number;
  bGeneral: boolean;
  bCategory: boolean;
  bCoverageSpecial: boolean;
  bAttachment: boolean;
  bReceipt: boolean;
  base64: string;
  bReceiptC: number;
  receiptList: any = [];
  categoryName: string;
  catTaxes: any = [];
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  dropdownSettingsCoordinators = {};
  clientId: number;
  catClientsPolicy: any = [];
  policyRenew: any = {};
  currencyList: any = [];
  salesmanList: any = [];
  executiveSurexList: any = [];
  SurexsSelectedItems: any = [];
  dropdownSettingsSurexs = {};
  executiveIncidentList: any = [];
  incidentsSelectedItems: any = [];
  dropdownSettingsIncidents = {};
  executiveSurexs: any = [];
  executiveIncidents: any = [];
  catCoordinatorList: any = [];
  paymentTypeOld: number;
  selectedConceptTypeValidity: number;
  yearsValidity: number;
  startDateTmp: string;
  isRenewPolicy: boolean;
  endDateTmp: any;
  dataExpired: any = [];
  policyIdExpired: number;
  base64File2: string;
  isLoadPolicy: boolean;
  isSplitRights: boolean;
  isSplitRightsByCar: boolean;
  catManagerIncidentList: any = [];
  CoordinatorsSelectedItems: any = [];
  modalRefRenew: BsModalRef;
  catAgeMaxLimit: any = [];
  /*variables para modulo de ubicaciones*/
  locationId: number;
  dropdownSettingsSecurityMeasures = {};
  dropdownSettingsFireProtection = {};
  dropdownSettingsEnterpriseAdministrator = {};
  postcode: string;
  addressId: number;
  catCountries: any = [];
  catMunicipality: any = [];
  catNeighborhood: any = [];
  catActivities: any = [];
  catConstructionTypes: any = [];
  catFireProtection: any = [];
  catSecurityMeasures: any = [];
  catEnterpriseAdministrator: any = [];
  isCoverageCarSaved: boolean;
  listNameLocations: any = [];

  categoryDamageCoverageList: any = [];
  categoryDamageCoverageListSend: any = [];
  categoryDamageCoverage: any = {};
  coverageListBasicList: any = []; // lista para coverturas basicas
  rootExtendion: any = {}; // extension de cubierta
  allRisk: any = {}; // extension de cubierta
  phenomenases: any = {}; // extension de cubierta
  phenomenasesList: any = []; // lista para coverturas basicas


  deducibleCoverturaBasica: any = {};

  catTypesDeducible: any = [];
  catTypesMoneda: any = [];
  catMinMax: any = [];
  deducibleType: any = {};
  deducibleTypeId: number;
  percentaje: number;
  sumAssured: number;

  /*extension de cubierta*/
  deducibleTypeIdEC: number;
  deducibleTypeEC: any = {};
  minMaxECId: number;

  minMaxGen: any = {};
  minMaxEC: any = {};
  typeMonedaECId: number;
  typeMonedaIdGen: number;
  deducibleTypeIdGen: number;
  typeMonedaEC: any = {};
  typeMonedaGen: any = {};
  deducibleTypeGen: any = {};
  percentajeIdGen: number;
  percentajeGen: number;


  sumAssuredEC: number;
  valorEC: number;
  percentajeEC: number;




  /*FENOMENOS HIDROMETEREOLOGICOS*/
  deducibleTypeIdFH: number;
  deducibleTypeFH: any = {};
  percentajeFH: number;
  valorCoaseguroFH: number;

  /*TERREMOTO Y ERUPCION VOLCANICAS*/
  deducibleTypeIdTEV: number;
  deducibleTypeTEV: any = {};
  percentajeTEV: number;
  valorCoaseguroTEV: number;
  /*REMOSION DE ESCONBROS*/
  deducibleTypeIdRE: number;
  deducibleTypeRE: any = {};
  valorRE: number;

  /*BIENES A LA INTERPERIE*/

  percentajeBI: number;
  deducibleTypeIdBI: number;
  deducibleTypeBI: any = {};
  percentajeCoaseguroBI: number;
  listCoverages: any = [];
  listCoveragesTemp: any = [];

  /*GENERIC 1*/
  sumAssuredGen: number;
  minMaxIdGen: number;
  // percentajeGen:
  // typeMonedaGen

  /*VARIABLES  de ubicaciones*/
  // data
  dataToSendLocation: any = {};
  dataToSendLocationList: any = [];
  coverageList: any = [];
  catPostCode: any = [];
  /*edicion de covertura edificion*/
  editDataCovergesIncendio: any = {}; //
  tempListDataCoverage: any = [];
  nameSeccion: string;
  /*MODAL*/
  modalRef: BsModalRef;
  modalRefNewClient: BsModalRef;
  /*rotura de maquinarioa  json */
  genericJson: any = {};
  private modalRefProyection: BsModalRef;

  /*VARIABLES PARA INDICAR SI SE MUESTRA O NO UN TAP*/

  stTwo = false;
  nameType: any = {};
  @ViewChild('gestionTabs', {static: false}) staticTabs: TabsetComponent;

  // VARIABLES COBERTURAS ESPECIALES
  categorySpecialCoverageList: any = [];
  specialCoverageList: Array<any>;
  specialCoverageListSelected: Array<any>;
  key: any;
  display: any;
  isNew = -1; // 0 = si,
  coverageToCopy = -1;
  categorySelected = '';
  coverageValue: CoverageModel;
  selectedConceptType: number;
  formSpecialCoverage: FormGroup;
  categoryCoverageModel: CategoryCoverageModel;
  categoryCoverageModelList: Array<CategoryCoverageModel>;
  // tslint:disable-next-line:max-line-length
  format = { add: 'ASIGNAR COBERTURA', remove: 'QUITAR COBERTURA', all: 'SELECCIONAR TODOS', none: 'DESELECCIONAR', direction: DualListComponent.LTR, draggable: true };
  categoryForm: FormGroup;
  policyForm: FormGroup;
  receiptForm: FormGroup;
  myFormReceipt: FormGroup;
  statusList: any = [];
  paymentList: any = [];
  roleId: number;
  coverageForm: FormGroup; // Formulario
  specialCoverageModel: SpecialCoverageModel;
  branchList: any[]; // Tipos de ramos
  subranchList: any[]; // Tipos de subramos
  branchListDetail: any[]; // Tipos de ramos Detail
  subranchListDetail: any[]; // Tipos de subramos Detail
  coverageCoincidenceList: any[];
  confirmSave: number;
  // variables para poblacion inicial
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  public fileUploadZip: File;
  errorZip = {active: false, msg: ''};
  fileTypesZip: string[] = ['application/zip', 'application/x-zip-compressed'];
  public fileUploadRar: File;
  errorRar = {active: false, msg: ''};
  fileTypesRar: string[] = ['application/rar', 'application/x-rar-compressed'];
  chargesPercentage = 0;
  isEdithCharges: boolean;
  officeList: any = [];

  modalRefCoverage: BsModalRef;
  coverageListSelected: Array<CoverageModel> = new Array<CoverageModel>();
  dataSourcePackege: any[];
  dataSourceCarType: any[];
  dataSourceUse: any[];
  coverageListCar: Array<CoverageModel>;
  keyCar: any;
  displayCar: any;

  isSimplifyPolice: boolean;
  constructor(
    private api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private shared: SharedService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService) {

  }


  ngOnInit() {
    this.isSimplifyPolice = false;
  }
  onChangeSimplyPolicy(event) {
    this.isSimplifyPolice = event;
  }
  closeModalCoverages() {
    this.coverageListSelected = [];
    this.modalRefCoverage.hide();
  }

  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  onIsPolicySimple(event) {
    this.isSimplifyPolice = event;
  }
}
