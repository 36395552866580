import {Component, OnInit, TemplateRef} from '@angular/core';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {Placement} from '../admin-placement-quotation-detail/placement';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {DatePipe} from '@angular/common';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-admin-placement-quotation-list',
  templateUrl: './admin-placement-quotation-list.component.html',
  styleUrls: ['./admin-placement-quotation-list.component.css']
})
export class AdminPlacementQuotationListComponent implements OnInit {

  quotationList: any = [];
  // -Varibles para paginado de usuarios->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filters: any = {};
  placement: Placement;
  bsConfig: Partial<BsDatepickerConfig>;
  startDateTmp: string;
  endDateTmp: any;
  selectedConceptTypeValidity: number;
  yearsValidity: number;
  modalDate: BsModalRef;
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private datePipe: DatePipe,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.clearFiltersTable();
  }
  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    this.filters = {
      page: 1,
      size: 10
    };
    this.getByPagination(1, 10);
  }
  /**
   * Carga los datos según la página seleccionada
   */
  pageChangedSpecial(event: PageChangedEvent): void {
    console.log(event);
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }

  changeColorPagination() {
  }

  /**
   * Función para obtener las coberturas especiales del sistema
   */
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.api.getplacement(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.quotationList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.quotationList = this.paginationResponse.tList;
          // this.quotationList = response;
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.quotationList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }


  openQuotationDetail(idPlacement) {
    this.router.navigate(['/admin/quotation-detail/', idPlacement]);
  }

  clonePlacement(idPlacement) {
    this.api.clonePlacement(idPlacement)
      .then(
        (response: any) => {
          this.toastr.success('Se ha cloando con éxito', 'Notificación');
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.quotationList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }

  downloadFilePlacementCoverages(idPlacement) {
    this.api.downloadReportPlacementCoverages(idPlacement).then(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download',  'SurexsSlip.xlsx');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      }, (error) => {
      });
  }

  public openModalAddExpiration(startDate: Date[], template: TemplateRef<any>) {
    this.selectedConceptTypeValidity = 1;
    this.yearsValidity = 2;
    if (startDate !== null) {
      this.startDateTmp = this.datePipe.transform(startDate, 'yyyy-MM-dd', 'es-ES');
      this.modalDate = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
    }
  }

  validateNumberYears() {
    if (this.yearsValidity < 0) {
      this.yearsValidity = 2;
      this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER NEGATIVO, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
      return;
    }
    if (this.yearsValidity === 1) {
      this.toastr.warning('EL NÚMERO MÍNIMO PERMITIDO PARA LA OPCIÓN MULTI-ANUAL, ES 2', 'NOTIFICACIÓN');
      this.yearsValidity = 2;
      return;
    }
    if (this.yearsValidity === 0) {
      this.yearsValidity = 2;
      this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER 0, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
      return;
    }
  }

  public selectedTypeValidity() {
    switch (this.selectedConceptTypeValidity) {
      case 1:
        this.endDateTmp = new Date(this.startDateTmp);
        // tslint:disable-next-line:max-line-length
        this.endDateTmp = this.endDateTmp.setFullYear(this.endDateTmp.getFullYear() + 1, this.endDateTmp.getMonth(), this.endDateTmp.getDate() + 1);
        this.placement.endDate = this.datePipe.transform(this.endDateTmp, 'yyyy-MM-dd', 'es-ES');
        break;
      case 2:
        if (this.yearsValidity === 0 || this.yearsValidity === undefined) {
          this.toastr.warning('DEBE DE INGRESAR EL NUMERO DE AÑOS DE VIGENCIA DE LA PÓLIZA', 'NOTIFICACIÓN');
          return;
        }
        if (this.yearsValidity < 0) {
          this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER NEGATIVO, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
          return;
        }
        if (this.yearsValidity < 1) {
          this.toastr.warning('EL MÍNIMO PERMITIDO PARA MULTI-ANUAL ES 2', 'NOTIFICACIÓN');
          return;
        }
        this.endDateTmp = new Date(this.startDateTmp);
        // tslint:disable-next-line:max-line-length
        this.endDateTmp = this.endDateTmp.setFullYear(this.endDateTmp.getFullYear() + this.yearsValidity, this.endDateTmp.getMonth(), this.endDateTmp.getDate() + 1);
        this.placement.endDate = this.datePipe.transform(this.endDateTmp, 'yyyy-MM-dd', 'es-ES');
        break;
      case 3:
        this.placement.endDate = '';
        break;
    }
    this.modalDate.hide();
  }
}
