import {Component, Injectable, IterableDiffers, OnInit, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';

import {BasicList, DualListComponent} from 'angular-dual-listbox';
import {CoverageModel} from './coverage-model-autos';
import {ITreeOptions, TreeComponent, TreeNode, TreeModel} from '@circlon/angular-tree-component';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {regex} from '../../common/regex';
import * as _ from 'lodash';
import {ValidateAccessService} from '../validate-access.service';


@Component({
  selector: 'app-admin-car-coverage',
  templateUrl: './admin-car-coverage.component.html',
  styleUrls: ['./admin-car-coverage.component.css']
})
export class AdminCarCoverageComponent implements OnInit {
  tree: any;
  coverageForm: FormGroup; // Formulario
  filters: any = {};
  insuranceList: any[]; // Tipos de ramos
  insuranceListDetail: any[]; // Tipos de ramos
  subranchList: any[]; // Tipos de subramos
  subranchListDetail: any[]; // Tipos de subramos Detail
  insurancesList: any[]; // Tipos de aseguradoras
  // -Varibles para paginado de coberturas->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 5; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  coverageListPaginated: any = [];
  idBranch = 3;
  level = 0;

  coverageModel: any = {};
  modalRef: BsModalRef;
  modalRefOtherValue: BsModalRef;
  coverageEmmision: any = [];
  key: any;
  display: any;
  coverageCoincidenceList: any = [];
  dataSourcesCoverages: any = [];
  isView: boolean;
  confirmSave: number;
  format = {
    add: 'ASIGNAR COBERTURA',
    remove: 'QUITAR COBERTURA',
    all: 'SELECCIONAR TODOS',
    none: 'DESELECCIONAR',
    direction: DualListComponent.LTR,
    draggable: true
  };
  addNewCoverage: true;
  subranch: any;
  insurance: any;
  isParent: boolean;
  dropdownSettingsGroups: any = {};
  dropdownSettingsInsurance: any = {};
  nodes = [
  /*  {
      name: 'POR PASAJERO',
      level: 1,
      children: [
        { name: '30,000.00', level: 1.1 },
        { name: '40,000.00' },
        { name: '50,000.00' },
        { name: '100,000.00' },
        { name: '-1' },
      ]
    },
    {
      name: 'POR VEHÍCULO',
      children: [
        { name: '30,000.00' },
        { name: '40,000.00' },
        { name: '50,000.00' },
        { name: '100,000.00' },
        { name: '-1' },
      ]
    }*/
  ];

  options: ITreeOptions = {
    displayField: 'name',
    useVirtualScroll: false,
    nodeHeight: 50,
    allowDrag: false,
    allowDrop: false
  };

  selectedNode = {
    id: '',
    name: '',
    value: '',
    open: false,
    meditionType: 'PESOS MEXICANOS',
    level: '',
    children: []
  };
  showEdit: boolean;
  typeList: any = [];
  meditionType: any;
  isText: boolean;

  optionsTemplate: {};

  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private validateAccess: ValidateAccessService) {
  }

  ngOnInit() {
    this.typeList = [{id: 1, name: 'PORCENTAJE'},
      {id: 2, name: 'UMAM'},
      {id: 3, name: 'USD'},
      {id: 4, name: 'PESOS MEXICANOS'},
      {id: 5, name: 'TEXTO'}];
    this.isText = false;
    this.confirmSave = 0;
    this.dropdownSettingsGroups = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON DATOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsInsurance = {
      singleSelection: true,
      idField: 'insuranceId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON DATOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.addNewCoverage = true;
    this.key = 'idCoverage';
    this.display = 'name';
    this.validateAccess.validateAccessCore(49).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.clearFiltersTable();
        this.invokeServicesGetSubranches(false);
        this.getInsuranceCompanies(false);
      }
    });

  }

  createFormNew() {
    // Se inicializa el formulario
    this.coverageForm = new FormGroup(
      {
        idCoverage: new FormControl({value: 0, disabled: false}),
        name: new FormControl(null, [
          Validators.maxLength(100),
          Validators.required,
          Validators.pattern(regex.alfanumericoConGuiones)
        ]),
        activate: new FormControl(true),
        description: new FormControl(null, [
          Validators.maxLength(300),
          Validators.required,
          Validators.pattern(regex.alfanumericoConGuiones)
        ]),
        idInsurance: new FormControl(null, [
          Validators.required
        ]),
        idBranch: new FormControl(this.idBranch),
        carOptionsArrayList: this.formBuilder.array([])
      }
    );
  }

  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    this.subranchList = [];
    this.filters = {
      nameCoverage: '',
      idInsurance: null,
      page: 1,
      size: 10
    };
    this.validateAccess.validateAccessCore(49).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }

  /**
   * Función para obtener las coberturas del sistema
   */
  loadFiltersTable() {
    this.validateAccess.validateAccessCore(49).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }

  /**
   * Función para obtener las coberturas especiales del sistema
   */
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.pgCurrentPage = page;
    const insurance = this.filters.idInsurance;
    if (insurance !== null) {
      this.filters.idInsurance = Number(insurance);
    }
    this.api.getCoverageCarPagination(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.coverageListPaginated = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.coverageListPaginated = this.paginationResponse.tList;
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.coverageListPaginated = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }

  ngAfterInit() {
    const treeModel: TreeModel = this.tree.treeModel;
    const firstNode: TreeNode = treeModel.getFirstRoot();

    firstNode.setActiveAndVisible();
  }

  getInsuranceCompanies(isForDetail: boolean) {
    this.api.getInsuranceCompaniesCar().then((data: any) => {
      if (isForDetail) {
        this.insuranceListDetail = data;
      } else {
        this.insurancesList = data;
      }
    }, error => {
      this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
    });
  }

  /**
   * funcion que oculta el pop up de coverage
   */
  closeModalCoverage() {
    this.clearFiltersTable();
    this.nodes = [];
    this.isView = false;
    this.clearCoverageDetail();
    this.modalRef.hide();
  }

  invokeServicesGetSubranches(isForDetail: boolean) {
    const branch = {
      id: String(this.idBranch)
    };
    this.api.getSubranches(branch)
      .then(
        (response: any) => {
          if (isForDetail) {
            this.subranchListDetail = response;
          } else {
            this.subranchList = response;
          }
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }

  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
  }

  changeColorPagination() {
  }

  onChangeNewCoverage(event) {
    this.addNewCoverage = event;
  }

  onDestinationChange(evento) {
    console.log(evento);
  }

  private clearCoverageDetail() {
    this.confirmSave = 0;
    this.coverageForm.enable();
    this.coverageForm.reset();
    this.subranchListDetail = [];
    this.insuranceListDetail = [];
    this.coverageCoincidenceList = [];
    this.coverageEmmision = [];
    this.dataSourcesCoverages = [];
  }

  /**
   * Función para ir al detalle de una cobertura
   */
  openDetailCoverage(template: TemplateRef<any>, coverageId: number, isView: boolean) {
    let accesscore = 0;
    if (coverageId === 0 && !isView) {
      accesscore = 50;
    } else if (coverageId > 0 && !isView) {
      accesscore = 51;
    } else if (coverageId > 0 && isView) {
      accesscore = 52;
    }
    this.validateAccess.validateAccessCore(accesscore).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.createFormNew();
        this.isView = isView;
        this.invokeServicesGetSubranches(true);
        this.getInsuranceCompanies(true);
        if (coverageId > 0) {
          const id = String(coverageId);
          this.invokeServicesGetCoverageCar(template, id);
          if (isView) {
            this.coverageForm.disable();
          }
        } else {
          // tslint:disable-next-line:max-line-length
          this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
        }
      }
    });
  }

  onChangeInsurance(event) {
    this.invokeGetCoverageEmission(event);
  }

  invokeGetCoverageEmission(idInsurance) {
    this.api.getCoverageCarEmmision(idInsurance).then(
      (response: any) => {
        this.coverageEmmision = response;
      }, error => {

        if (error.status === 500) {
          if (error.error.Error !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  /**
   * funcion que guarda una cobertura
   */
  validateCoincidence() {
    if (this.coverageForm.valid) {
      if (this.nodes.length === 0) {
        this.toastr.warning('Es necesario agregar las opciones que contendrá la cobertura', 'Notificación');
        return;
      } else {
        if (this.confirmSave === 0) {
          this.carOptionsArrayList.clear();
          this.getOptionsNodeToModel(this.nodes);
          // this.coverageForm.controls.idInsurance.setValue(this.coverageForm.value.idInsurance[0].insuranceId);
          this.api.getCoincidenceCoverageCar(this.coverageForm.value).then(
            (response: any) => {
              this.coverageCoincidenceList = response;
              this.confirmSave++;
              if (this.coverageCoincidenceList.length === 0) { // Si todo bien, se guarda
                if (this.coverageForm.controls.idCoverage.value !== null && this.coverageForm.controls.idCoverage.value > 0) {
                  this.editCoverage();
                } else {
                  this.saveCoverage();
                }
              }
            }, error => {
              this.spinner.hide('sp');
              if (error.status === 500) {
                if (error.error.Error !== undefined) {
                  this.toastr.error(error.error.message, 'Notificación');
                } else {
                  this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                }
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            }
          );
        } else if (this.confirmSave === 1) {
          if (this.coverageForm.controls.idCoverage.value !== null && this.coverageForm.controls.idCoverage.value > 0) {
            this.editCoverage();
          } else {
            this.saveCoverage();
          }
        }
      }

    } else {
      this.coverageForm.controls.name.markAsTouched();
      this.coverageForm.controls.description.markAsTouched();
      this.coverageForm.controls.idInsurance.markAsTouched();
    }
  }


  invokeServicesGetCoverageCar(template: TemplateRef<any>, coverageId) {
    const coverage = {
      idCoverage: Number(coverageId)
    };
    this.api.getCoverageCarById(coverage)
      .then(
        (response: any) => {
          this.coverageModel = response;
          this.createForm();
          this.modalRef =
            this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }


  getOptionsNodeToModel(nodes: any[]) {
    nodes.map( nodo => { // Se recorre el nodo
      this.createCarOptionArrayList(nodo);
      });
  }

  createCarOptionArrayList(node) {
    this.carOptionsArrayList.push(this.newNode(node)); // Se agrega Nodo
    if (node.children !== undefined && node.children.length > 0) { // Si el nodo tiene hijos, obtenerlos
      this.getOptionsNodeToModel(node.children); // Se envían los hijos
    }
  }

  get carOptionsArrayList(): FormArray {
    return this.coverageForm.get('carOptionsArrayList') as FormArray;
  }

  newNode(node): FormGroup {
    return this.formBuilder.group({
      idCoverageCarOption: new FormControl(node.idCoverageCarOption),
      coverageId: new FormControl(this.coverageForm.controls.idCoverage.value),
      value: new FormControl(node.value),
      open: new FormControl(node.open),
      meditionType: new FormControl(node.meditionType),
      description: new FormControl(node.name),
      order: new FormControl(node.level),
    });
  }

  /**
   * funcion que guarda una cobertura
   */
  saveCoverage() {
    if (this.coverageForm.valid) {

      this.api.saveCoverageCar(this.coverageForm.value).then( // Si todo bien, se guarda
        (response: any) => {
          this.spinner.hide('sp');
          this.confirmSave = 0;
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.closeModalCoverage();
        }, error => {
          this.spinner.hide('sp');
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.error(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
    } else {
      this.coverageForm.controls.name.markAsTouched();
      this.coverageForm.controls.description.markAsTouched();
      this.coverageForm.controls.idInsurance.markAsTouched();
    }
  }

  /**
   * funcion que guarda una cobertura
   */
  editCoverage() {
    if (this.coverageForm.valid) {
      this.api.updateCoverageCar(this.coverageForm.value).then( // Si todo bien, se guarda
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.closeModalCoverage();
        }, error => {
          this.spinner.hide('sp');
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.error(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
    } else {
      this.coverageForm.controls.name.markAsTouched();
      this.coverageForm.controls.description.markAsTouched();
      this.coverageForm.controls.idInsurance.markAsTouched();
    }
  }

  createForm() {
    // Se inicializa el formulario

    this.coverageForm.controls.idCoverage.setValue(this.coverageModel.idCoverage);
    this.coverageForm.controls.name.setValue(this.coverageModel.name);
    this.coverageForm.controls.activate.setValue(this.coverageModel.activate);
    this.coverageForm.controls.description.setValue(this.coverageModel.description);
    this.coverageForm.controls.idInsurance.setValue(this.coverageModel.idInsurance);
    this.coverageForm.controls.idBranch.setValue(this.coverageModel.idBranch);
    this.coverageForm.controls.idInsurance.setValue(this.coverageModel.idInsurance);
    this.createNodesFromModel();
  }

  createNodesFromModel() {
    this.coverageModel.carOptionsArrayList.map( options => {

      if ( (options.order.split('.')).length === 1 ) {
        this.nodes.push(this.createNode(options));
      } else {
        this.level = 1; // primer nivel
        this.searchTree(this.nodes, options.order.split('.'), options);

      }
    });
  }

  searchTree(tree: any[], children: any[], option) {

      tree.map( node => { // Recorremos los hijos
        if (this.level === 1) { // Si estamos en el nivel 1 (1, 2, 3)
          if (node.level === children[this.level - 1]) { // Y el level del node donde eamos es igual a nivel del hijo que buscandos [1 , 1, 1] <- 1.1.1
            if (children.length === this.level) { // Y ya no hay mas por recorrer
              node.children.push(this.createNode(option)); // Se crea el nodito
            } else { // Si no, significa que encontramos al padre y tenemos que buscar en sus hijos.
              this.level++;
              if ((children.length - this.level) === 0) {
                node.children.push(this.createNode(option));
              } else {
                this.searchTree(node.children, children, option);
              }
            }
          }
        } else { // Si no estamos en el primero nivel ya, si no, dentro de los hijos
          const nivel = (node.level.split('.'))[this.level - 1]; // Se parte la cadena para ver el número del nivel

          if (nivel === children[this.level - 1]) { // Y el level del node donde eamos es igual a nivel del hijo que buscamos [1 , 1, 1] <- 1.1.1
            if (children.length === this.level) { // Y ya no hay mas por recorrer
              node.children.push(this.createNode(option));
            } else {
              this.level++;
              if ((children.length - this.level) === 0) {
                node.children.push(this.createNode(option));
              } else {
                this.searchTree(node.children, children, option);
              }
            }
          }
        }
      });
  }

  createNode(option: any): any {
    return {
      idCoverageCarOption: option.idCoverageCarOption,
      coverageId: this.coverageModel.idCoverage,
      name: option.description,
      value: option.value,
      meditionType: option.meditionType,
      level: option.order,
      open: option.open,
      children: []
    };
  }

  /* TREE NODE */

  onActivateNode(event: any) {
    this.selectedNode = event.node.data;
    this.showEdit = true;
    // Do stuff with selected node
  }

  copyNode(node: any, tree) {
    const parentNode = node.realParent ? node.realParent : node.treeModel.virtualRoot;
    const copyNode = JSON.stringify(node.data);
    const newNode = JSON.parse(copyNode);
    this.deleteIds(newNode);
    parentNode.data.children.push(newNode);
    tree.treeModel.update();
  }

  deleteIds(node: TreeNode) {
    node.id = null;
    if (node.children) {
      node.children.forEach(child => this.deleteIds(child));
    }
  }

  canAddNode(node: any): boolean {
    const level = node.data.level.split('.');
    return level.length < 2;
  }

  addNode(node: any, tree, index) {
    const level = node.data.level + '.' + (index + 1);
    const newNode = {
      idCoverageCarOption: 0,
      name: 'new item',
      level};
    if (!node.data.children) {
      node.data.children = [];
    }
    node.data.children.push(newNode);
    tree.treeModel.update();

    const someNode = tree.treeModel.getNodeById(node.id);
    someNode.expand();
  }

  deleteNode(node, tree) {
    const parentNode = node.realParent ? node.realParent : node.treeModel.virtualRoot;
    // tslint:disable-next-line:only-arrow-functions
    _.remove(parentNode.data.children, function(child) {
      return child === node.data;
    });
    tree.treeModel.update();
    if (node.children !== null && node.children.length === 0) {
      node.hasChildren = false;
    }
  }

  nodeEdited() {
    this.showEdit = false;
  }

  editNode(template: TemplateRef<any>, node: any, tree) {
    this.selectedNode = node.data;
    if (!this.selectedNode.open) {
      if (this.selectedNode.meditionType === 'USD') {
        this.isText = false;
        this.optionsTemplate = {
          suffix: ' USD'
        };
      } else if (this.selectedNode.meditionType === 'PESOS MEXICANOS') {
        this.isText = false;
        this.optionsTemplate = {};
      } else if (this.selectedNode.meditionType === 'PORCENTAJE') {
        this.isText = false;
        this.optionsTemplate = {
          allowNegative: true,
          allowZero: true,
          decimal: '',
          precision: 0,
          prefix: '',
          suffix: ' %',
          thousands: '',
          nullable: true
        };
      } else if (this.selectedNode.meditionType === 'UMAM') {
        this.isText = false;
        this.optionsTemplate = {
          allowNegative: true,
          allowZero: true,
          decimal: '',
          precision: 0,
          prefix: '',
          suffix: ' UMAM',
          thousands: '',
          nullable: true
        };
      } else {
        this.isText = true;
        this.optionsTemplate = {};
      }
    } else {
      this.isText = true;
    }

    this.modalRefOtherValue =
      this.modalService.show(template, {
        class: 'modal-sm', keyboard: false, focus: false, ignoreBackdropClick: true, backdrop: false
      });

  }

  closeModal() {
    this.modalRefOtherValue.hide();
    this.selectedNode = {
      id: '',
      name: '',
      value: '',
      level: '',
      open: false,
      meditionType: 'PESOS MEXICANOS',
      children: []
    };
  }

  addNewValue() {

    /*if (this.selectedNode.name === '' || this.selectedNode.name === undefined) {
      this.toastr.warning('Es necesario agregar el nombre de la opción', 'Notificación');
      return;
    } else*/

    if (this.selectedNode.value === '' || this.selectedNode.value === undefined) {
      this.toastr.warning('Es necesario agregar el valor de la opción', 'Notificación');
      return;
    }
    const option = document.getElementById('valueCoverage') as HTMLInputElement | null;

    if (this.isParent) {
      const newNode = {
        name: option.value,
        value: this.selectedNode.value,
        meditionType: this.selectedNode.meditionType,
        level: String(this.nodes.length + 1),
        open: this.selectedNode.open,
        children: []};
      this.nodes.push(newNode);
      this.modalRefOtherValue.hide();
      this.isParent = false;
      this.selectedNode = {
        id: '',
        name: '',
        value: '',
        meditionType: 'PESOS MEXICANOS',
        level: '',
        open: false,
        children: []
      };
      this.tree.treeModel.update();
    } else {
      this.selectedNode.name = option.value;
      this.closeModal();
    }
  }

  addParentNode(tree: TreeComponent, template: TemplateRef<any>, isParent) {
    this.isParent = isParent;
    this.tree = tree;
    this.modalRefOtherValue =
      this.modalService.show(template, {class: 'modal-sm', keyboard: false, focus: false, ignoreBackdropClick: true, backdrop: false});
  }

  onChangeMedition(e) {
    console.log(e);
    if (!this.selectedNode.open) {
      switch (e) {
        case 'PORCENTAJE': // 'PORCENTAJE' :
          this.isText = false;
          this.optionsTemplate = {
            allowNegative: true,
            allowZero: true,
            decimal: '',
            precision: 0,
            prefix: '',
            suffix: ' %',
            thousands: '',
            nullable: true
          };
          break;

        case 'UMAM': // 'UMAM' :
          this.isText = false;
          this.optionsTemplate = {
            allowNegative: true,
            allowZero: true,
            decimal: '',
            precision: 0,
            prefix: '',
            suffix: ' UMAM',
            thousands: '',
            nullable: true
          };
          break;

        case 'PESOS MEXICANOS': // 'PESOS MEXICANOS' :
          this.isText = false;
          this.optionsTemplate = {};
          break;

        case 'USD': // 'USD' :
          this.isText = false;
          this.optionsTemplate = {
            suffix: ' USD'
          };
          break;

        case 'TEXTO': // 'TEXTO' :
          this.isText = true;
          this.optionsTemplate = {
            allowNegative: true,
            allowZero: true,
            decimal: '',
            precision: 0,
            prefix: '',
            suffix: ' UMAM',
            thousands: '',
            nullable: true
          };
          break;

        default:
          break;
      }
    }
  }

  clickCheckActive() {
    if (this.selectedNode.open) {
      this.isText = true;
      this.selectedNode.value = '-1';
    } else {
      this.selectedNode.value = '0';
      this.onChangeMedition(this.selectedNode.meditionType);
    }
  }
}
