export class SpecialCoverageModel {
  coverageId: number;
  name: string;
  description: string;
  branch: number;
  subranch: number;
  coverageType: number;

  constructor() {
    this.coverageId = 0;
    this.name = '';
    this.description = '';
    this.branch = null;
    this.subranch = null;
    this.coverageType = 0;
  }
}
