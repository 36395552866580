import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SharedService} from './shared-service.service';
import {ApiService} from './api.service';
import * as Forge from 'node-forge';
import {switchMap, timeout} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequestOptionsInterceptor implements HttpInterceptor {


  constructor(private api: ApiService, private shared: SharedService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth header from the service.
    const authHeader = 'Basic ' + localStorage.getItem('token');
    let authHeader2 = 'Bearer ' + localStorage.getItem('bearer');
    const authHeaderAmmia = 'Bearer ' + localStorage.getItem('ammia');
    const roleId = localStorage.getItem('roleId');
    const roleHeader = '' + roleId;
    let  newHeaders;
    // if (req.url.includes('134.122.124.249') || req.url.includes('localhost')) {
    // tslint:disable-next-line:max-line-length
    if (req.url.includes('qa.tienda.ammia') || req.url.includes('services.ammia.io/api/ammia')
      || req.url.includes('/ammia/v1.0/protected') || req.url.includes('/ammia/v1.0/public')) {
      newHeaders = req.headers.set('Authorization', authHeaderAmmia);
      const checkUrl = req.url.indexOf('protected/sso/system/policy/getPublicPolicy');
      if (checkUrl === -1) {
        return this.api.getPublicKey1().pipe(
          switchMap((data: any) => {
            const body = req.body;
            const value = JSON.stringify(body);
            const encryptado = this.encryptWithPublicKeyLong(value, data.mswSANXnWqh);
            const ob = {
              mswSANXnWqh: encryptado,
              vRPbyweQpC: data.vRPbyweQpC
            };
            const newReq1 = req.clone({headers: newHeaders, body: ob });
            return next.handle(newReq1);
          })
        );
      }
      // tslint:disable-next-line:max-line-length
    } else if (req.url.includes('34.229.208.93') || (/localhost:\d+\/api\/core/.test(req.url))
      || req.url.includes('core.surexs.com/api') || req.url.includes('services.ammia.io/api/core')
        || req.url.includes('servicesq.ammia.io/api/core')
      || req.url.includes('qa.services.ammia.io/api/core')) {
      if (authHeader2 === 'Bearer null') {
        // tslint:disable-next-line:max-line-length surexs
        authHeader2 = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI5NDYiLCJzdWIiOiJzdXJleHMsc3VyZXhzLDEifQ.ljkEvw9RvSiSEmHI2PeblnTD4YD8mj99xOjVAgR3idw';
        // tslint:disable-next-line:max-line-length gfhk
        // authHeader2 = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIxIiwic3ViIjoic3VyZXhzLGdmaGssMiJ9.wGv7zjzof5zsrPIZjNeUWZCSLXf5_GTU4K2mX2QSxaw';
      }
      newHeaders = req.headers.set('Authorization', authHeader2);
      newHeaders = newHeaders.set('roleId', roleHeader);
    } else {
      // tslint:disable-next-line:max-line-length surexs
        newHeaders = req.headers.set('Authorization', 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI5NDYiLCJzdWIiOiJzdXJleHMsc3VyZXhzLDEifQ.ljkEvw9RvSiSEmHI2PeblnTD4YD8mj99xOjVAgR3idw');
      // tslint:disable-next-line:max-line-length gfhk
        // newHeaders = req.headers.set('Authorization', 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIxIiwic3ViIjoic3VyZXhzLGdmaGssMiJ9.wGv7zjzof5zsrPIZjNeUWZCSLXf5_GTU4K2mX2QSxaw');
        newHeaders = newHeaders.set('roleId', roleHeader);
        console.log(newHeaders);
    }
    // }
    const userIdExternal = localStorage.getItem('user-id');
    const customerId = localStorage.getItem('customer-id');
    // Sí la vista es de solo lectura para un asegurado
    if (req.url.includes('52.15.130.194')) { // 134.209.117.135
      if (this.shared.fInsuredDataReadonly) {
        const insured = JSON.parse(localStorage.getItem('insuredView'));
        newHeaders = newHeaders.set('insuredId', '' +  insured.userId);
        newHeaders = newHeaders.set('roleId', '' + 3);
        if (Number(roleId) === 3) {
          newHeaders = newHeaders.set('customerId', this.shared.gCurrentCustomerId.value.toString());
        }
      }
    }
    /*if (Number(roleId) === 3) {
      newHeaders = newHeaders.set('customerId', this.shared.gCurrentCustomerId.value.toString());
    }*/

    if (req.url.includes('100.26.10.11')) {  // 157.245.249.35
      newHeaders = newHeaders.set('userIdExternal', '' + userIdExternal);
      newHeaders = newHeaders.set('customerId', '' + customerId);
      newHeaders = newHeaders.set('agencyId', '0');
    }

    // Clone the request to add the new header.
    const headReq = req.clone(
      {headers: newHeaders});
    // Pass on the cloned request instead of the original request.
    return next.handle(headReq);
  }

  encryptWithPublicKeyLong(valueToEncrypt: string, keyPublica: string): any {
    let keyFinal = '-----BEGIN PUBLIC KEY-----  ';
    keyFinal = keyFinal + keyPublica;
    keyFinal = keyFinal + '  -----END PUBLIC KEY-----';

    const rsa = Forge.pki.publicKeyFromPem(keyFinal);
    const val = this.splitPlaintext(rsa, valueToEncrypt.toString());

    return val;
  }

  splitPlaintext(publicKey, plaintext) {
    const len = 80;
    if (plaintext.length < len) {
      return [Forge.util.bytesToHex(publicKey.encrypt(Forge.util.encodeUtf8(plaintext)))];
    }

    const result = [];
    const splitCount = Math.ceil(plaintext.length / len);
    for (let i = 0; i < splitCount; i++) {
      const str = plaintext.substring(i * len, (i + 1) * len);
      result.push(Forge.util.bytesToHex(publicKey.encrypt(Forge.util.encodeUtf8(str))));
    }

    return result;
  }

}
