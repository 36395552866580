import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupEndorsementsByCoverageId',
  pure: false,
})
export class GroupEndorsementsByCoverageIdPipe  implements PipeTransform {

  transform(endorsements: any[], length: number): { [coverageId: string]: any[] } {
    const groupedEndorsements: { [coverageId: string]: any[] } = {};

    endorsements.forEach(endorsement => {
      const coverageId = endorsement.get('coverageId').value;
      if (!groupedEndorsements[coverageId]) {
        groupedEndorsements[coverageId] = [];
      }
      groupedEndorsements[coverageId].push(endorsement);
    });

    return groupedEndorsements;
  }


}

