import {PlacementDetail} from './placementDetail';
import {CompanySubranch} from './companySubranch';

export class Placement {
  private _idCompany: number;
  private _nameCompany: string;
  private _versionNumber: string;
  private _startDate: string;
  private _endDate: string;
  private _placementStatusId: number;
  private _placementDetails: PlacementDetail[];
  private _companySubranch: CompanySubranch[];

  constructor(
    idCompany: number,
    nameCompany: string,
    versionNumber: string,
    startDate: string,
    endDate: string,
    placementStatusId: number,
    placementDetails: PlacementDetail[] = [],
    companySubranch: CompanySubranch[] = []
  ) {
    this._idCompany = idCompany;
    this._nameCompany = nameCompany;
    this._versionNumber = versionNumber;
    this._startDate = startDate;
    this._endDate = endDate;
    this._placementStatusId = placementStatusId;

    // Mapea placementDetails usando el constructor de PlacementDetail
    this._placementDetails = placementDetails.map(detail => new PlacementDetail(
      detail.idTradeName,
      detail.rfc,
      detail.directionCompany,
      detail.tradeName,
      detail.companyId
    ));

    this._companySubranch = companySubranch.map(detail => new CompanySubranch(
      detail.idCompanySubranch,
      detail.companyId,
      detail.subranchId,
      detail.branchId,
      detail.companySubranchCategories
    ));
  }


  get idCompany(): number {
    return this._idCompany;
  }

  set idCompany(value: number) {
    this._idCompany = value;
  }

  get nameCompany(): string {
    return this._nameCompany;
  }

  set nameCompany(value: string) {
    this._nameCompany = value;
  }

  get versionNumber(): string {
    return this._versionNumber;
  }

  set versionNumber(value: string) {
    this._versionNumber = value;
  }

  get startDate(): string {
    return this._startDate;
  }

  set startDate(value: string) {
    this._startDate = value;
  }

  get endDate(): string {
    return this._endDate;
  }

  set endDate(value: string) {
    this._endDate = value;
  }

  get placementStatusId(): number {
    return this._placementStatusId;
  }

  set placementStatusId(value: number) {
    this._placementStatusId = value;
  }

  get placementDetails(): PlacementDetail[] {
    return this._placementDetails;
  }

  set placementDetails(value: PlacementDetail[]) {
    this._placementDetails = value;
  }

  get companySubranch(): CompanySubranch[] {
    return this._companySubranch;
  }

  set companySubranch(value: CompanySubranch[]) {
    this._companySubranch = value;
  }

  toPlainObject(): any {
    return {
      idCompany: this._idCompany,
      nameCompany: this._nameCompany,
      versionNumber: this._versionNumber,
      startDate: this._startDate,
      endDate: this._endDate,
      placementStatusId: this._placementStatusId,
      placementDetails: this._placementDetails.map(detail => detail.toPlainObject()),
      companySubranch: this._companySubranch.map(detail => detail.toPlainObject())
    };
  }
}
