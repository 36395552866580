import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup} from '@angular/forms';
import {QuoteService} from '../quote.service';
import {object} from '@amcharts/amcharts4/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-invoice-validator',
  templateUrl: './invoice-validator.component.html',
  styleUrls: ['./invoice-validator.component.css']
})
export class InvoiceValidatorComponent implements OnInit {

  public onClose: Subject<object>;
  uploadForm: FormGroup;
  objResponse: any = {};

  constructor(public bsModalRef: BsModalRef,
              private formBuilder: FormBuilder,
              private quoteApi: QuoteService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.onClose = new Subject();
    this.uploadForm = this.formBuilder.group({
      file: ['']
    });
  }

  public onConfirm(): void {
    this.onClose.next(this.objResponse);
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next({success: false});
    this.bsModalRef.hide();
  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    this.uploadForm.get('file').setValue(file);

    const formData = new FormData();
    formData.append('file', this.uploadForm.get('file').value);

    this.quoteApi.validateInvoice(formData)
      .subscribe(
        (response: any) => {
          this.objResponse = {success: true, insuredAmount: response.insuredAmount};
          this.onConfirm();
      }, error => {
          console.info(error);
          this.toastr.warning(error.error.message, `Problema!`);
      });
  }


}
