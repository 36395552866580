import { Component, OnInit, ViewChild } from '@angular/core';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {SharedService} from '../shared-service.service';
import { ApiService } from '../api.service';
import { DatePipe } from '@angular/common';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  chartPolicyFilterArray: string[] = [
    'Número de pólizas emitidas en el mes',
    'Número de pólizas emitidas de manera acumulada en el año',
    'Pólizas pagadas',
    'Pólizas pendientes de pago',
    'Pólizas canceladas'
  ]; // Variable que contiene los posibles filtros para la gráfica de pòlizas
  chartPolicyFilter: string = this.chartPolicyFilterArray[0]; // Variable que indica el filtro seleccionado para la gráfica de pòlizas
  @ViewChild('chartPolicyId', {static: false}) private chartPolicyId; // Elemento para la gráfica de pólizas
  chartPolicy: any; // Variable que contiene los datos para la gráfica de pólizas
  @ViewChild('chartRenovationId', {static: false}) private chartRenovationId; // Elemento para la gráfica de renovaciones
  chartRenovation: any; // Variable que contiene los datos para la gráfica de renovaciones
  @ViewChild('chartCollectionId', {static: false}) private chartCollectionId; // Elemento para la gráfica de cobranza
  chartCollection: any; // Variable que contiene los datos para la gráfica de cobranza
  @ViewChild('chartUDIId', {static: false}) private chartUDIId; // Elemento para la gráfica de UDI
  chartUDI: any; // Variable que contiene los datos para la gráfica de UDI
  @ViewChild('chartIssuedByBrandId', {static: false}) private chartIssuedByBrandId; // Elemento para la gráfica de pólizas emitidas por marca
  chartIssuedByBrand: any; // Variable que contiene los datos para la gráfica de pólizas emitidas por marca
  filterChart: any = {// Variable que contiene los filtros para las gráficas
    dateStart: new Date(),
    dateEnd: new Date()
  };

  // Objetos para los DatePickers ->
  bsStart: Partial<BsDatepickerConfig>;

  constructor(
    public shared: SharedService,
    private apiService: ApiService,
    private datePipe: DatePipe
  ) {

    this.shared.gSkin = '#448AA6'; // localStorage.getItem('skin');
    this.shared.gLogo = localStorage.getItem('logo');

    this.shared.fUpdateChartDataObservable().subscribe( fUpdateChartData => {
      if (fUpdateChartData) {
        this.loadChartsData(this.shared.gfilterRangeDate);
      }
    });

    setTimeout(() => {
      this.loadChartPolicy();
      this.loadChartRenovation();
      this.loadChartCollection();
      this.loadChartUDI();
      this.loadChartIssuedByBrand();
    }, 1000);
  }

  ngOnInit() {
    // Iniciamos configuración para DatePicker de Fecha de Inicio ->
    this.bsStart = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      maxDate: new Date()
    });
  }

  loadChartsData(filterRangeDate: Date[]) {
    // console.log(filterRangeDate);

    if(filterRangeDate == null){
      return false;
    }
    // console.log(filterRangeDate[0]);
    // console.log(filterRangeDate[1]);
    this.filterChart.dateStart = this.datePipe.transform(filterRangeDate[0], 'yyyy-MM-dd');
    this.filterChart.dateEnd = this.datePipe.transform(filterRangeDate[1], 'yyyy-MM-dd');
    // console.log(this.filterChart);
    this.loadChartPolicy();
    this.loadChartRenovation();
    this.loadChartCollection();
    this.loadChartUDI();
    this.loadChartIssuedByBrand();
    this.shared.fUpdateChartData.next(false);
  }

  loadChartPolicy() {
    /*
    this.apiService.getDataChartPolicy(this.filterChart.dateStart, this.filterChart.dateEnd, this.shared.gfilterAgency).subscribe( (response) => {
      let labels = [];
      let data = [];
      let bckgnd = [];

      for (let obj in response){
        labels.push(response[obj].month);
        data.push({month: response[obj].month, total: response[obj].total});
        bckgnd.push(this.shared.gSkin);
      }


      let chart = am4core.create(this.chartPolicyId.nativeElement, am4charts.XYChart);
      //chart.scrollbarX = new am4core.Scrollbar();

      // Add data
      chart.data = data;

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'month';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.horizontalCenter = 'right';
      categoryAxis.renderer.labels.template.verticalCenter = 'middle';
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.tooltip.disabled = true;
      categoryAxis.renderer.minHeight = 110;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 50;

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.dataFields.valueY = 'total';
      series.dataFields.categoryX = 'month';
      series.tooltipText = '[{categoryX}: bold]{valueY}[/]';
      series.columns.template.strokeWidth = 0;

      series.tooltip.pointerOrientation = 'vertical';

      series.columns.template.column.cornerRadiusTopLeft = 10;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.fillOpacity = 0.8;

      let listColors = this.generateColor('#FFFFFF', this.shared.gSkin, data.length + data.length);
      let seriesColors = [];
      for (let obj of listColors) {
        seriesColors.push(am4core.color('#' + obj));
      }
      chart.colors.list =  seriesColors;

      let hoverState = series.columns.template.column.states.create('hover');
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;

      series.columns.template.adapter.add('fill', function(fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });

      chart.cursor = new am4charts.XYCursor();

    }, error => {

    });
    */
  }

  loadChartRenovation() {
    /*
    this.apiService.getDataChartRenovation(this.filterChart.dateStart, this.filterChart.dateEnd, this.shared.gfilterAgency).subscribe( (response: any) => {

      let labels = [];
      let dt = [];
      let bckgnd = [];
      var gradientColors = [];
      var gc = this.generateColor('#000000', this.shared.gSkin, response.length + response.length);
      for (let obj in response){
        labels.push(response[obj].title);
        //dt.push(response[obj].total);
        dt.push({title: response[obj].title, total: response[obj].total});
        bckgnd.push(this.shared.gSkin);
        gradientColors.push(
          {
            start: '#' + gc[obj],
            end: '#FFFFFF'
          }
        );
      }

      let chart = am4core.create(this.chartRenovationId.nativeElement, am4charts.PieChart3D);
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      chart.data = dt;

      chart.legend = new am4charts.Legend();

      chart.innerRadius = 50;

      let series = chart.series.push(new am4charts.PieSeries3D());
      series.dataFields.value = 'total';
      series.dataFields.category = 'title';
      series.labels.template.disabled = true;//Se deshabilitan las etiquetas al rededor de la gráfica
      series.ticks.template.disabled = true;//Se deshabilitan las etiquetas al rededor de la gráfica
      let listColors = this.generateColor('#FFFFFF', this.shared.gSkin, dt.length + 2);
      let seriesColors = [];
      for (let obj of listColors) {
        seriesColors.push(am4core.color('#' + obj));
      }
      series.colors.list =  seriesColors;


    });
    */
  }

  loadChartCollection() {
    /*
    this.apiService.getDataChartCollection(this.filterChart.dateStart, this.filterChart.dateEnd, this.shared.gfilterAgency).subscribe( (response: any) => {

      let labels = [];
      let dt = [];
      let bckgnd = [];
      var gradientColors = [];
      var gc = this.generateColor('#000000', this.shared.gSkin, response.length + response.length);
      for (let obj in response){
        labels.push(response[obj].status);
        //dt.push(response[obj].total);
        dt.push({status: response[obj].status, total: response[obj].total});
        bckgnd.push(this.shared.gSkin);
        gradientColors.push(
          {
            start: '#' + gc[obj],
            end: '#FFFFFF'
          }
        );
      }
      //am4core.useTheme(am4themes_animated);
      let chart = am4core.create(this.chartCollectionId.nativeElement, am4charts.PieChart3D);
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      chart.data = dt;

      chart.innerRadius = am4core.percent(50);

      chart.legend = new am4charts.Legend();

      //chart.innerRadius = 50;

      let series = chart.series.push(new am4charts.PieSeries3D());
      series.dataFields.value = 'total';
      series.dataFields.category = 'status';
      series.labels.template.disabled = true;//Se deshabilitan las etiquetas al rededor de la gráfica
      series.ticks.template.disabled = true;//Se deshabilitan las etiquetas al rededor de la gráfica
      let listColors = this.generateColor('#FFFFFF', this.shared.gSkin, dt.length + 2);
      let seriesColors = [];
      for (let obj of listColors) {
        seriesColors.push(am4core.color('#' + obj));
      }
      series.colors.list =  seriesColors;
    });
    */
  }

  loadChartUDI() {
    /*
    this.apiService.getDataChartUdi(this.filterChart.dateStart, this.filterChart.dateEnd, this.shared.gfilterAgency).subscribe( response => {

      let labels = [];
      let dt = [];

      for (let obj in response){
        labels.push(response[obj].month);
        dt.push({date: response[obj].date, total: response[obj].total});
      }

      let chart = am4core.create(this.chartUDIId.nativeElement, am4charts.XYChart);
      chart.language.locale = am4lang_es_ES;

      let listColors = this.generateColor('#FFFFFF', this.shared.gSkin, dt.length + dt.length);
      let seriesColors = [];
      for (let obj of listColors) {
        seriesColors.push(am4core.color('#' + obj));
      }
      chart.colors.list =  seriesColors;

      chart.data = dt;

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 50;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.logarithmic = true;
      valueAxis.renderer.minGridDistance = 20;

      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = 'total';
      series.dataFields.dateX = 'date';
      series.tensionX = 0.8;
      series.strokeWidth = 3;

      let bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.fill = am4core.color('#fff');
      bullet.circle.strokeWidth = 3;

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.fullWidthLineX = true;
      chart.cursor.xAxis = dateAxis;
      chart.cursor.lineX.strokeWidth = 0;
      chart.cursor.lineX.fill = am4core.color('#000');
      chart.cursor.lineX.fillOpacity = 0.1;

      let range = valueAxis.axisRanges.create();
      range.value = 90.4;
      range.grid.stroke = am4core.color(this.shared.gSkin);
      range.grid.strokeWidth = 1;
      range.grid.strokeOpacity = 1;
      range.grid.strokeDasharray = '3,3';
      range.label.inside = true;
      range.label.text = 'Promedio';
      range.label.fill = range.grid.stroke;
      range.label.verticalCenter = 'bottom';
    });
    */
  }

  loadChartIssuedByBrand() {
    /*
    this.apiService.getDataChartIssuedByBrand(this.filterChart.dateStart, this.filterChart.dateEnd, this.shared.gfilterAgency).subscribe( response => {
      let data = [];
      for (let obj in response){
        data.push({mark: response[obj].mark, total: response[obj].total});
      }
      let labels = [];
      let dt = [];
      let bckgnd = [];
      for (let obj in response) {
        labels.push(response[obj].mark);
        dt.push({mark: response[obj].mark, total: response[obj].total});
        bckgnd.push(this.shared.gSkin);
      }


      let chartIssuedByBrand = am4core.create(this.chartIssuedByBrandId.nativeElement, am4charts.XYChart);
      chartIssuedByBrand.hiddenState.properties.opacity = 0; // this makes initial fade in effect

      chartIssuedByBrand.data = data;

      let listColors = this.generateColor('#FFFFFF', this.shared.gSkin, data.length + data.length);
      let seriesColors = [];
      for (let obj of listColors) {
        seriesColors.push(am4core.color('#' + obj));
      }
      chartIssuedByBrand.colors.list = seriesColors;


      let categoryAxis = chartIssuedByBrand.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataFields.category = 'mark';
      categoryAxis.renderer.minGridDistance = 40;

      let valueAxis = chartIssuedByBrand.yAxes.push(new am4charts.ValueAxis());

      let series = chartIssuedByBrand.series.push(new am4charts.CurvedColumnSeries());
      series.dataFields.categoryX = 'mark';
      series.dataFields.valueY = 'total';
      series.tooltipText = '{valueY.value}';
      series.columns.template.strokeOpacity = 0;

      series.columns.template.fillOpacity = 0.75;

      let hoverState = series.columns.template.states.create('hover');
      hoverState.properties.fillOpacity = 1;
      hoverState.properties.tension = 0.4;

      chartIssuedByBrand.cursor = new am4charts.XYCursor();

      series.columns.template.adapter.add('fill', function(fill, target) {
        return chartIssuedByBrand.colors.getIndex(target.dataItem.index);
      });

    });
    */
  }

  hex (c) {
    var s = '0123456789abcdef';
    var i = parseInt (c);
    if (i == 0 || isNaN (c))
      return '00';
    i = Math.round (Math.min (Math.max (0, i), 255));
    return s.charAt ((i - i % 16) / 16) + s.charAt (i % 16);
  }

  /* Convert an RGB triplet to a hex string */
  convertToHex (rgb) {
    return this.hex(rgb[0]) + this.hex(rgb[1]) + this.hex(rgb[2]);
  }

  /* Remove '#' in color hex string */
  trim (s) { return (s.charAt(0) == '#') ? s.substring(1, 7) : s }

  /* Convert a hex string to an RGB triplet */
  convertToRGB (hex) {
    var color = [];
    color[0] = parseInt ((this.trim(hex)).substring (0, 2), 16);
    color[1] = parseInt ((this.trim(hex)).substring (2, 4), 16);
    color[2] = parseInt ((this.trim(hex)).substring (4, 6), 16);
    return color;
  }

  generateColor(colorStart,colorEnd,colorCount){

    // The beginning of your gradient
    var start = this.convertToRGB (colorStart);

    // The end of your gradient
    var end   = this.convertToRGB (colorEnd);

    // The number of colors to compute
    var len = colorCount;

    //Alpha blending amount
    var alpha = 0.0;

    var saida = [];

    for (let i = 0; i < len; i++) {
      var c = [];
      alpha += (1.0/len);

      c[0] = start[0] * alpha + (1 - alpha) * end[0];
      c[1] = start[1] * alpha + (1 - alpha) * end[1];
      c[2] = start[2] * alpha + (1 - alpha) * end[2];

      saida.push(this.convertToHex(c));

    }

    return saida;

  }

}
