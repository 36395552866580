import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {error} from 'util';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {

  userList: any = [];
  // -Varibles para paginado de usuarios->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filters: any = {};
  filtersDownload: any = {};
  roleList: any[]; // Tipo de rol

  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) { }

  ngOnInit() {
    this.invokeServiceGetRoles();
    this.validateAccess.validateAccessCore(29).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.clearFiltersTable();
      }
    });
  }
  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    this.filters = {
      name: '',
      role: null,
      email: '',
      page: 1,
      size: 10
    };
    this.validateAccess.validateAccessCore(29).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }

  /**
   * Función para limpiar los filtros
   */
  downloadXLS() {
    this.validateAccess.validateAccessCore(34).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.filtersDownload = {
          name: this.filters.name,
          role: this.filters.role,
          email: this.filters.email,
        };
        this.api.downloadXLS(this.filtersDownload).then(
          (response: any) => {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',  'USUARIOS.xlsx');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }, (error) => {

          });
      }
    });
  }
  /**
   * Carga los datos según la página seleccionada
   */
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }
  /**
   * Función para obtener los usuarios del sistema
   */
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    const role = this.filters.role;
    if (role !== null) {
      this.filters.role = Number(role);
    }
    this.api.getUserPagination(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.userList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.userList = this.paginationResponse.tList;
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.userList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }
  /**
   * Función para obtener los usuarios del sistema
   */
  loadFiltersTable() {
    this.validateAccess.validateAccessCore(29).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }
  /**
   * Función para ir al detalle de un usuario
   */
  openDetailUser(userId, operation) {
    let accessCore = 0;
    if (operation === 0) {
      accessCore = 30;
    } else if (operation === 1) {
      accessCore = 31;
    } else if (operation === 2) {
      accessCore = 32;
    }
    this.validateAccess.validateAccessCore(accessCore).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        localStorage.setItem('user-id', userId);
        localStorage.setItem('operationUser', operation);
        this.router.navigate(['/admin/user_detail']);
      }
    });
  }
  /**
   * Función para obtener roles
   */
  invokeServiceGetRoles() {
    this.api.getRolList()
      .then(
        (response: any) => {
          this.roleList = response;
          this.roleList.sort( function (a, b) { // Función para ordenar
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }
}
