export class RolModel {
  idRole: number;
  name: string;
  description: string;
  actions: Array<Action>;

  constructor() {
    this.idRole = null;
    this.name = '';
    this.description = '';
    this.actions = new Array<Action>();
  }
}

export class Action {
  no: number;
  idAction: number;
  action: string;
  process: string;
  active: string;

  constructor() {
    this.no = 0;
    this.idAction = 0;
    this.action = '';
    this.process = '';
    this.active = 'true';
  }
}
