import {CoveragePlacementSelected} from './coveragePlacementSelected';
import {CompanySubranch} from './companySubranch';
import {PlacementDetail} from './placementDetail';
import {number, string} from '@amcharts/amcharts4/core';

export class CompanySubranchCategories {
  private _idCoverageSubranchCategories: number;
  private _nameCategory: string;
  private _coveragesJson: string;
  private _endorsementJson: string;
  private _endorsementPlacementFactorsSelectedJson: string;
  private _coveragePlacementFactorsSelectedJson: string;
  private _companySubranchId: number;
  private _monedaType: number;
  private _groupId: number;
  private _coveragePlacementSelected: CoveragePlacementSelected[];
  private _endorsementPlacementSelected: CoveragePlacementSelected[];
  private _coveragePlacementFactorsSelected: CoveragePlacementSelected[];
  private _endorsementPlacementFactorsSelected: CoveragePlacementSelected[];

  constructor(
    idCoverageSubranchCategories: number,
    nameCategory: string,
    coveragesJson: string,
    endorsementJson: string,
    endorsementPlacementFactorsSelectedJson: string,
    coveragePlacementFactorsSelectedJson: string,
    companySubranchId: number,
    monedaType: number,
    groupId: number,
    coveragePlacementSelected: CoveragePlacementSelected[] = [],
    endorsementPlacementSelected: CoveragePlacementSelected[] = [],
    coveragePlacementFactorsSelected: CoveragePlacementSelected[] = [],
    endorsementPlacementFactorsSelected: CoveragePlacementSelected[] = [],
  ) {
    this._idCoverageSubranchCategories = idCoverageSubranchCategories;
    this._nameCategory = nameCategory;
    this._coveragesJson = coveragesJson;
    this._endorsementJson = endorsementJson;
    this._coveragePlacementFactorsSelectedJson = coveragePlacementFactorsSelectedJson;
    this._endorsementPlacementFactorsSelectedJson = endorsementPlacementFactorsSelectedJson;
    this._companySubranchId = companySubranchId;
    this._monedaType = monedaType;
    this._groupId = groupId;
    this._coveragePlacementSelected = coveragePlacementSelected.map(detail => new CoveragePlacementSelected(
      detail.idCoverage,
      detail.nameCoverage,
      detail.descriptionCoverage,
      detail.cuantitative,
      detail.valueSelected,
      detail.coveragePlacementSelected,
    ));

    this._endorsementPlacementSelected = endorsementPlacementSelected.map(detail => new CoveragePlacementSelected(
      detail.idCoverage,
      detail.nameCoverage,
      detail.descriptionCoverage,
      detail.cuantitative,
      detail.valueSelected,
      detail.coveragePlacementSelected
    ));

    this._coveragePlacementFactorsSelected = coveragePlacementFactorsSelected.map(detail => new CoveragePlacementSelected(
      detail.idCoverage,
      detail.nameCoverage,
      detail.descriptionCoverage,
      detail.cuantitative,
      detail.valueSelected,
      detail.coveragePlacementSelected,
    ));

    this._endorsementPlacementFactorsSelected = endorsementPlacementFactorsSelected.map(detail => new CoveragePlacementSelected(
      detail.idCoverage,
      detail.nameCoverage,
      detail.descriptionCoverage,
      detail.cuantitative,
      detail.valueSelected,
      detail.coveragePlacementSelected
    ));
  }

  // Getter y Setter para _nameCategory
  get nameCategory(): string {
    return this._nameCategory;
  }

  set nameCategory(value: string) {
    this._nameCategory = value;
  }

  // Getter y Setter para _coveragesJson
  get coveragesJson(): string {
    return this._coveragesJson;
  }

  set coveragesJson(value: string) {
    this._coveragesJson = value;
  }

  // Getter y Setter para _endorsementJson
  get endorsementJson(): string {
    return this._endorsementJson;
  }

  set endorsementJson(value: string) {
    this._endorsementJson = value;
  }

  // Getter y Setter para _companySubranchId
  get companySubranchId(): number {
    return this._companySubranchId;
  }

  set companySubranchId(value: number) {
    this._companySubranchId = value;
  }

  // Getter y Setter para _monedaType
  get monedaType(): number {
    return this._monedaType;
  }

  set monedaType(value: number) {
    this._monedaType = value;
  }


  get idCoverageSubranchCategories(): number {
    return this._idCoverageSubranchCategories;
  }

  set idCoverageSubranchCategories(value: number) {
    this._idCoverageSubranchCategories = value;
  }

  get coveragePlacementSelected(): CoveragePlacementSelected[] {
    return this._coveragePlacementSelected;
  }

  set coveragePlacementSelected(value: CoveragePlacementSelected[]) {
    this._coveragePlacementSelected = value;
  }


  get endorsementPlacementSelected(): CoveragePlacementSelected[] {
    return this._endorsementPlacementSelected;
  }

  set endorsementPlacementSelected(value: CoveragePlacementSelected[]) {
    this._endorsementPlacementSelected = value;
  }

  get groupId(): number {
    return this._groupId;
  }

  set groupId(value: number) {
    this._groupId = value;
  }

  get coveragePlacementFactorsSelected(): CoveragePlacementSelected[] {
    return this._coveragePlacementFactorsSelected;
  }

  set coveragePlacementFactorsSelected(value: CoveragePlacementSelected[]) {
    this._coveragePlacementFactorsSelected = value;
  }

  get endorsementPlacementFactorsSelected(): CoveragePlacementSelected[] {
    return this._endorsementPlacementFactorsSelected;
  }

  set endorsementPlacementFactorsSelected(value: CoveragePlacementSelected[]) {
    this._endorsementPlacementFactorsSelected = value;
  }


  get endorsementPlacementFactorsSelectedJson(): string {
    return this._endorsementPlacementFactorsSelectedJson;
  }

  set endorsementPlacementFactorsSelectedJson(value: string) {
    this._endorsementPlacementFactorsSelectedJson = value;
  }

  get coveragePlacementFactorsSelectedJson(): string {
    return this._coveragePlacementFactorsSelectedJson;
  }

  set coveragePlacementFactorsSelectedJson(value: string) {
    this._coveragePlacementFactorsSelectedJson = value;
  }

  toPlainObject(): any {
    return {
      idCoverageSubranchCategories: this._idCoverageSubranchCategories,
      nameCategory: this._nameCategory,
      coveragesJson: this._coveragesJson,
      endorsementJson: this._endorsementJson,
      endorsementPlacementFactorsSelectedJson: this._endorsementPlacementFactorsSelectedJson,
      coveragePlacementFactorsSelectedJson: this._coveragePlacementFactorsSelectedJson,
      companySubranchId: this._companySubranchId,
      monedaType: this._monedaType,
      groupId: this._groupId,
      coveragePlacementSelected: this._coveragePlacementSelected.map(detail => detail.toPlainObject()),
      endorsementPlacementSelected: this._endorsementPlacementSelected.map(detail => detail.toPlainObject()),
      coveragePlacementFactorsSelected: this._coveragePlacementFactorsSelected.map(detail => detail.toPlainObject()),
      endorsementPlacementFactorsSelected: this._endorsementPlacementFactorsSelected.map(detail => detail.toPlainObject()),
    };
  }
}
