import { Component } from '@angular/core';
import {SharedService} from './shared-service.service';
import {NavigationStart, Router} from '@angular/router';
import {setTheme} from 'ngx-bootstrap/utils';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'surexs';
  constructor(private shared: SharedService,
              private router: Router) {
    setTheme('bs3'); // or 'bs4'
    this.shared.gUserRoles = JSON.parse(localStorage.getItem('userRoles'));

    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });

    localStorage.setItem('clientNew', '');
    localStorage.setItem('esClienteFisico', 'SI');
  }
}
