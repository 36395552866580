import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-upload-file-damage',
  templateUrl: './upload-file-damage.component.html',
  styleUrls: ['./upload-file-damage.component.css']
})
export class UploadFileDamageComponent implements OnInit {
  modalRef: BsModalRef;
  dataToSendIssuePolicy: any = {};
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  // tslint:disable-next-line:max-line-length
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel.sheet.macroEnabled.12'];
  public fileUploadZip: File;
  errorZip = {active: false, msg: ''};
  fileTypesZip: string[] = ['application/zip', 'application/x-zip-compressed'];
  responseImportInsured: any = [];
  insuredProcess: number;
  insuredUnprocessed: number;
  importMassive: boolean;
  validationPolicy: any = {};
  categoryList: any = [];
  _typeImport: number;
  _sectionId: number;
  titleImport: string;
  @Output() public resultImport = new EventEmitter<any>();
  result: any = {};
  catClientsPolicy: any = [];
  subGroupsList: any = [];
  constructor(private modalService: BsModalService,
              private toastr: ToastrService,
              private shared: SharedService,
              private api: ApiService,
              private router: Router,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  /*--+---------------------------------------------------------------------------------+
  |-------------------| F U N C I O N E S G E N E R A L E S |----------------------------|
  +---------------------------------------------------------------------------------+--*/
  get typeImport(): number {
    return this._typeImport;
  }
  @Input()
  set typeImport(value: number) {
    this._typeImport = value;
  }
  get sectionId(): number {
    return this._sectionId;
  }
  @Input()
  set sectionId(value: number) {
    this._sectionId = value;
  }
  openModal(template: TemplateRef<any>) {
    this.fileUploadXlsx = undefined;
    this.fileUploadZip = undefined;
    this.spinner.show('sp');
    switch (this._typeImport) {
      case 7:
        this.titleImport = 'IMPORTACION MASIVA DE UBICACIONES';
        this.invokeServiceGetClientsPolicy(Number(localStorage.getItem('policy-id')));
        break;
      case 8:
        this.titleImport = 'IMPORTACION MASIVA DE PERDIDAS CONSECUENCIALES';
        break;
      case 9:
        this.titleImport = 'IMPORTACION MASIVA DE INCENDIO CONTENIDO';
        break;
      case 10:
        this.titleImport = 'IMPORTACION MASIVA DE INCENDIO EDIFICIO';
        break;
      case 11:
        this.titleImport = 'IMPORTACION MASIVA DE CALDERAS';
        break;
      case 12:
        this.titleImport = 'IMPORTACION MASIVA DE ROCTURA DE MAQUINARIA';
        break;
      case 13:
        this.titleImport = 'IMPORTACION MASIVA DE EQUIPO ELECTRONICO';
        break;
      case 14:
        this.titleImport = 'IMPORTACION MASIVA DE EQUIPO CONTRATISTA';
        break;
      case 15:
        this.titleImport = 'IMPORTACION MASIVA DE CRISTALES';
        break;
      case 16:
        this.titleImport = 'IMPORTACION MASIVA DE ROBO';
        break;
      case 17:
        this.titleImport = 'IMPORTACION MASIVA DE DINERO';
        break;
      case 18:
        this.titleImport = 'IMPORTACION MASIVA DE ANUNCIOS';
        break;
      case 19:
        this.titleImport = 'IMPORTACION MASIVA DE RESPONSABILIDAD CIVIL';
        break;
    }
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
    this.spinner.hide('sp');
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  onChangeInputFileXlsx(e) {
    this.errorXlsx = {active: false, msg: ''};
    this.fileUploadXlsx = e.target.files[0];
    console.log(this.fileUploadXlsx);
    if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
      this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  onChangeInputFileZip(e) {
    this.errorZip = {active: false, msg: ''};
    this.fileUploadZip = e.target.files[0];
    if (0 > this.fileTypesZip.indexOf(this.fileUploadZip.type)) {
      this.errorZip = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  loadInitialModal() {
    this.importMassive = false;
    this.dataToSendIssuePolicy = {
      policyId: localStorage.getItem('policy-id'),
      statusId: 1,
      number: ''
    };
    this.fileUpload = undefined;
    this.fileUploadXlsx = undefined;
    this.fileUploadZip = undefined;
  }
  invokeServiceDownloadLayoutsDamages() {
    this.spinner.show('sp');
    let fileName = '';
    switch (this._typeImport) {
      case 7:
        fileName = 'UBICACIONES.xlsm';
        break;
      case 8:
        fileName = 'PERDIDAS CONSECUENCIALES.xlsx';
        break;
      case 9:
        fileName = 'INCENDIO CONTENIDO.xlsx';
        break;
      case 10:
        fileName = 'INCENDIO EDIFICIO.xlsx';
        break;
      case 11:
        fileName = 'CALDERAS.xlsx';
        break;
      case 12:
        fileName = 'MAQUINARIA.xlsx';
        break;
      case 13:
        fileName = 'EQUIPO ELECTRONICO.xlsx';
        break;
      case 14:
        fileName = 'EQUIPO CONTRATISTA.xlsx';
        break;
      case 15:
        fileName = 'CRISTALES.xlsx';
        break;
      case 16:
        fileName = 'ROBO.xlsx';
        break;
      case 17:
        fileName = 'DINERO.xlsx';
        break;
      case 18:
        fileName = 'ANUNCIOS.xlsx';
        break;
      case 19:
        fileName = 'RC.xlsx';
        break;
    }
    if (this._typeImport === 7) {
      this.api.getLayoutFileDamageLocation(this.subGroupsList)
        .subscribe(
          (response: any) => {
            this.spinner.hide('sp');
            if (response == null) {
              this.toastr.info('No existen archivos para descargar');
              return;
            } else {
              const dataType = response.type;
              const binaryData = [];
              binaryData.push(response);
              const downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
              downloadLink.setAttribute('download',  fileName);
              document.body.appendChild(downloadLink);
              downloadLink.click();
              downloadLink.remove();
            }
          }, error => {
            this.spinner.hide('sp');
            // console.error(error);
          }
        );
    } else {
      this.api.getLayoutFileDamageSections(this._typeImport)
        .subscribe(
          (response: any) => {
            this.spinner.hide('sp');
            if (response == null) {
              this.toastr.info('No existen archivos para descargar');
              return;
            } else {
              const dataType = response.type;
              const binaryData = [];
              binaryData.push(response);
              const downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
              downloadLink.setAttribute('download',  fileName);
              document.body.appendChild(downloadLink);
              downloadLink.click();
              downloadLink.remove();
            }
          }, error => {
            this.spinner.hide('sp');
            // console.error(error);
          }
        );
    }
  }

  invokeServiceImportMassiveDamage() {
    this.result = {};
    this.spinner.show('sp');
    if (this._typeImport === 7) {
      if (this.fileUploadXlsx === undefined || (this.fileUploadZip === undefined)) {
        this.toastr.info('DEBE DE INGRESAR LOS ARCHIVOS SOLICITADOS', 'NOTIFICACION');
        this.spinner.hide('sp');
        return;
      }
      const formDataXlsx = new FormData();
      formDataXlsx.append('fileXlsx', this.fileUploadXlsx);
      if (this.fileUploadZip !== undefined ) {
        formDataXlsx.append('fileCompressed', this.fileUploadZip);
      }
      this.api.postImportLocationDamage(formDataXlsx, Number(localStorage.getItem('policy-id')))
        .subscribe(
          response => {
            this.result = response;
            switch (this.result.code) {
              case 0:
                this.toastr.warning('LAYOUT NO COMPATIBLE, DESCARGUE E INTENTE DE NUEVO', 'NOTIFICACION');
                this.spinner.hide('sp');
                break;
              case 1:
                this.toastr.warning(this.result.bug, 'NOTIFICACION');
                this.spinner.hide('sp');
                break;
              case 3:
                this.toastr.error('HA OCURRIDO UN PROBLEMA EN EL PROCESO', 'NOTIFICACION');
                this.spinner.hide('sp');
                break;
              case 4:
                this.toastr.success('SE HA TERMINADO EL PROCESO', 'NOTIFICACION');
                this.spinner.hide('sp');
                this.modalRef.hide();
                const objet = {
                  typeImport: 7,
                  list: this.result.massiveImportDamageList
                };
                this.resultImport.emit(objet);
                break;
            }
          }, error => {
            this.toastr.error('HA OCURRIDO UN PROBLEMA EN EL PROCESO', 'NOTIFICACION');
            this.spinner.hide('sp');
          }
        );
    } else {
      if (this.fileUploadXlsx === undefined) {
        this.toastr.info('DEBE DE INGRESAR EL LAYOUT', 'NOTIFICACION');
        this.spinner.hide('sp');
        return;
      }
      const formDataXlsx = new FormData();
      formDataXlsx.append('fileXlsx', this.fileUploadXlsx);
      this.api.postImportSectionDamage(formDataXlsx, this._typeImport, this._sectionId, Number(localStorage.getItem('policy-id')))
        .subscribe(
          response => {
            this.result = response;
            switch (this.result.code) {
              case 1:
                this.toastr.warning('EL LAYOUT NO ES CORRECTO, DESCARGUE EL LAYOUT CORRECTO', 'NOTIFICACION');
                this.spinner.hide('sp');
                break;
              case 2:
                this.toastr.success('SE HA TERMINADO EL PROCESO', 'NOTIFICACION');
                this.spinner.hide('sp');
                this.modalRef.hide();
                const objet = {
                  typeImport: this._typeImport,
                  list: this.result.list
                };
                this.resultImport.emit(objet);
            }
          }, error => {
            this.toastr.error('OCURRIO UN PROBLEMA COMUNIQUESE CON EL ADMINISTRADOR', 'NOTIFICACION');
            this.spinner.hide('sp');
          }
        );
    }
  }

  invokeServiceGetClientsPolicy(id) {
    this.api.getClientsPolicy(id).then((data: any) => {
      this.catClientsPolicy = data;
      this.subGroupsList = [];
      this.catClientsPolicy.forEach(item => {
        this.subGroupsList.push(item.name);
      });
    }, error => {
      this.catClientsPolicy = [];
    });
  }

}
