import {Component, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';


@Component({
  selector: 'app-policy-lite',
  templateUrl: './policy-lite.component.html',
  styleUrls: ['./policy-lite.component.css']
})
export class PolicyLiteComponent implements OnInit {

  policyForm: FormGroup;
  dataToSendGeneral: any = {};
  bsStartDate: Partial<BsDatepickerConfig>  = new BsDatepickerConfig();
  bsExpirationDate: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  roleId: number;
  policyId: number;
  catGroups: any = [];
  catClients: any = [];
  catInsuranceCompany: any = [];
  catAgentKeys: any = [];
  catBranch: any = [];
  catSubBranch: any = [];
  paymentTypeOld: number;
  salesmanList: any = [];
  clientsSelectedItems: any = [];
  catCoordinatorList: any = [];
  executiveSurexList: any = [];
  executiveIncidentList: any = [];
  SurexsSelectedItems: any = [];
  incidentsSelectedItems: any = [];
  clientId: number;
  modalRef: BsModalRef;
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  public fileUploadZip: File;
  errorZip = {active: false, msg: ''};
  fileTypesZip: string[] = ['application/zip', 'application/x-zip-compressed'];
  public fileUploadRar: File;
  errorRar = {active: false, msg: ''};
  fileTypesRar: string[] = ['application/rar', 'application/x-rar-compressed'];
  selectionMassImport: boolean;
  catalogForm = this.fb.group({
    catalogs: ['groups', [Validators.required]]
  });
  flag: number;
  constructor(
    private api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private shared: SharedService,
    private datePipe: DatePipe,
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService) {
    // observable para saber si es nueva o edición de una póliza
    this.shared.gPolicyIdLiteObservable().subscribe(
      gPolicyIdLite => {
        const policy = parseInt(localStorage.getItem('policy-id-lite'), 10);
        console.log('policyId');
        console.log(policy);
        console.log(gPolicyIdLite);
        if (gPolicyIdLite > 0) {
          localStorage.setItem('policy-id-lite', String(gPolicyIdLite));
          this.shared.fPolicyDataReadonly = true;
        } else if (gPolicyIdLite < 0) {
          if (policy > 0) {
            this.shared.fPolicyDataReadonly = true;
          } else {
            this.shared.fPolicyDataReadonly = false;
          }
        } else {
          this.shared.fPolicyDataReadonly = false;
        }
        if (this.shared.fPolicyDataReadonly) {
          console.log('edición de documento');
          setTimeout(() => {
            this.getPolicy();
            // @ts-ignore
            $('#smartwizard').smartWizard('goToStep', 0);
          }, 1000);
        } else {
          console.log('nuevo documento');
          this.loadCatalogGenerals();
        }
      }
    );

    this.policyForm  = this.fb.group({
      numberPolicy: [''],
      startDate: ['', [Validators.required]],
      expirationDate: ['', [Validators.required]],
      group: ['', [Validators.required]],
      client: ['', [Validators.required]],
      insurance: ['', [Validators.required]],
      keyAgent: ['', [Validators.required]],
      branch: ['', [Validators.required]],
      subBranch: ['', [Validators.required]],
      salesman: ['', [Validators.required]],
      coordinator: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.policyId = 0;
    this.loadCatalogGenerals();
    this.bsStartDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });
    this.bsExpirationDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });
    this.roleId = Number(localStorage.getItem('roleId'));
    // @ts-ignore
    $('#smartwizard').smartWizard({
      selected: 0, // Initial selected step, 0 = first step
      enableAllSteps: true,
      theme: 'default', // theme for the wizard, related css need to include for other than default theme
      justified: true, // Nav menu justification. true/false
      autoAdjustHeight: true, // Automatically adjust content height
      cycleSteps: false, // Allows to cycle the navigation of steps
      backButtonSupport: true, // Enable the back button support
      enableURLhash: false, // Enable selection of the step based on url hash,
      enableAnchorOnDoneStep: true,
      transition: {
        animation: 'none', // Effect on navigation, none/fade/slide-horizontal/slide-vertical/slide-swing
        speed: '400', // Transion animation speed
        easing: '' // Transition animation easing. Not supported without a jQuery easing plugin
      },
      toolbarSettings: {
        toolbarPosition: 'bottom', // none, top, bottom, both
        toolbarButtonPosition: 'right', // left, right, center
        showNextButton: false, // show/hide a Next button
        showPreviousButton: false, // show/hide a Previous button
        toolbarExtraButtons: [] // Extra buttons to show on toolbar, array of jQuery input/buttons elements
      },
      anchorSettings: {
        anchorClickable: true, // Enable/Disable anchor navigation
        enableAllAnchors: true, // Activates all anchors clickable all times
        markDoneStep: true, // Add done state on navigation
        markAllPreviousStepsAsDone: true, // When a step selected by url hash, all previous steps are marked done
        removeDoneStepOnNavigateBack: true, // While navigate back done step after active step will be cleared
        enableAnchorOnDoneStep: true // Enable/Disable the done steps navigation
      },
      keyboardSettings: {
        keyNavigation: false, // Enable/Disable keyboard navigation(left and right keys are used if enabled)
        keyLeft: [37], // Left key code
        keyRight: [39] // Right key code
      },
      lang: { // Language variables for button
        next: 'SIGUIENTE',
        previous: 'ANTERIOR'
      },
      disabledSteps: [], // Array Steps disabled
      errorSteps: [], // Highlight step with errors
      hiddenSteps: [] // Hidden steps
    });
  }




  /*--+---------------------------------------------------------------------------------+
  |----------------------------| F U N C I O N E S G E N E R A L E S|-------------------|
  +---------------------------------------------------------------------------------+--*/

  newDocument() {
    this.shared.fPolicyDataReadonly = false;
    this.shared.gPolicyIdLite.next(0);
    setTimeout(() => {
      // @ts-ignore
      $('#smartwizard').smartWizard('goToStep', 0);
    }, 1000);
  }

  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }
  loadDateSmartWizard(id) {
    // console.log('pestaña: ' + id);
    switch (id) {
      case 0:
        if (this.shared.fPolicyDataReadonly) {
          // console.info('general ' + this.lclSectionSelected);
          this.getPolicy();
        }
        break;
    }
  }

  /*+-------------------------------------------------------------------------------------------------------+
       |------------------------------------------|  G E N E R A L E S  |------------------------------------------|
     +-------------------------------------------------------------------------------------------------------+*/
  loadCatalogGenerals() {
    this.dataToSendGeneral = {
      policyId: 0,
      number: 'OT00001',
      startDate: undefined,
      expirationDate: undefined,
      groupId: undefined,
      insuranceId: undefined,
      branchId: 0,
      subBranchId: 0,
      renew: false,
      status: 5,
      clients: [],
      currencyId: 1,
      userId: 0,
      paymentTypeId: 1,
      directoryId: 0,
      executiveSurexs: [44],
      executiveIncidents: [21],
      policyIdOld: 0,
      agentKeyId: 0,
      typePaymentPolicy: 1
    };
    // this.getTypePayments();
    this.getSalesman();
    this.getGroups();
    this.getInsuranceCompanies();
    this.getBranches();
    this.getExecutives(1);
  }

  sendGeneralData() {
    this.spinner.show('sp');
    this.dataToSendGeneral.startDate = this.datePipe.transform(this.dataToSendGeneral.startDate, 'yyyy-MM-dd', 'es-ES');
    this.dataToSendGeneral.expirationDate = this.datePipe.transform(this.dataToSendGeneral.expirationDate, 'yyyy-MM-dd', 'es-ES');
    this.dataToSendGeneral.clients.push(this.clientId);
    setTimeout(() => {
      try {
        console.log(this.dataToSendGeneral);
        this.api.postPolicyDataLite(this.dataToSendGeneral)
          .then(
            (response: any) => {
              this.policyId = response.policyId;
              localStorage.setItem('policy-id-lite', String(this.policyId));
              this.dataToSendGeneral.policyId = this.policyId;
              this.shared.fPolicyDataReadonly = true;
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.spinner.hide('sp');
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      } catch (e) {
      }
    }, 500);
  }

  sendGeneralDataUpdate() {
    this.spinner.show('sp');
    console.log(this.dataToSendGeneral);
    const dateStart = Date.parse(this.dataToSendGeneral.startDate);
    const dateExpiration = Date.parse(this.dataToSendGeneral.expirationDate);
    this.dataToSendGeneral.startDate = this.datePipe.transform(dateStart, 'yyyy-MM-dd', 'es-ES');
    this.dataToSendGeneral.expirationDate = this.datePipe.transform(dateExpiration, 'yyyy-MM-dd', 'es-ES');
    this.dataToSendGeneral.clients.push(this.clientId);
    setTimeout(() => {
      try {
        console.log(this.dataToSendGeneral);
        this.api.putPolicyDataLite(this.dataToSendGeneral)
          .then(
            (response: any) => {
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.spinner.hide('sp');
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      } catch (e) {
      }
    }, 500);
  }

  getGroups() {
    this.api.getGroupsLite('AMMIA LITE').then((data: any) => {
      this.catGroups = data;
      if (this.dataToSendGeneral.policyId <= 0) {
        this.dataToSendGeneral.groupId = this.catGroups[0].groupId;
      }
      console.log(this.dataToSendGeneral.groupId);
      this.getSubGroups(this.dataToSendGeneral.groupId);
    }, error => {
    });
  }

  getSubGroups(id) {
    this.api.getSubGroups(id).then((data: any) => {
      this.catClients = data;
    }, error => {
    });
  }

  getInsuranceCompanies() {
    this.api.getInsuranceCompanies().then((data: any) => {
      this.catInsuranceCompany = data;
      if (this.dataToSendGeneral.policyId <= 0) {
        this.dataToSendGeneral.insuranceId = this.catInsuranceCompany[0].insuranceId;
      }
      this.getAgentKeys(this.dataToSendGeneral.insuranceId);
    }, error => {
    });
  }
  getAgentKeys(insuranceId) {
    this.api.getAgentKeysByInsuranceCompany(insuranceId).then((data: any) => {
      this.catAgentKeys = data;
      if (this.dataToSendGeneral.policyId <= 0) {
        this.dataToSendGeneral.agentKeyId = this.catAgentKeys[0].agentKeyId;
      }
    }, error => {
    });
  }

  getBranches() {
    this.api.getBranches().then((data: any) => {
      this.catBranch = data;
      if (this.dataToSendGeneral.policyId <= 0) {
        this.dataToSendGeneral.branchId = this.catBranch[0].branchId;
      }
      this.getSubBranches(this.dataToSendGeneral.branchId);
    }, error => {
    });
  }

  getSubBranches(id) {
    this.api.getSubBranches(id).then((data: any) => {
      this.catSubBranch = data;
      if (this.dataToSendGeneral.policyId <= 0) {
        this.dataToSendGeneral.subBranchId = this.catSubBranch[0].subBranchId;
      }
    }, error => {
    });
  }

  getPolicy() {
    this.api.getPolicy(localStorage.getItem('policy-id-lite')).then((data: any) => {
      this.dataToSendGeneral = data;
      this.paymentTypeOld = this.dataToSendGeneral.paymentTypeId;
      this.policyId = this.dataToSendGeneral.policyId;
      this.getSubGroups(this.dataToSendGeneral.groupId);
      this.getSubBranches(this.dataToSendGeneral.branchId);
      this.getAgentKeys(this.dataToSendGeneral.insuranceId);
      this.getExecutivesByPolicy(1);
      console.log(this.dataToSendGeneral);
    }, error => {
    });
    this.api.getClientsPolicy(localStorage.getItem('policy-id-lite')).then((data: any) => {
      this.clientsSelectedItems = data;
      this.clientId = data[0].clientId;
      this.changeSubGroups();
    }, error => {
    });
  }


  getExecutivesByPolicy(id) {
    this.api.getExecutivesByPolicy(localStorage.getItem('policy-id-lite'), id).then((data: any) => {
      switch (id) {
        case 1:
          this.dataToSendGeneral.directoryId = data[0].directoryId;
          break;
        case 2:
          this.SurexsSelectedItems = data;
          break;
        case 3:
          this.incidentsSelectedItems = data;
          break;
      }
    }, error => {
    });
  }

  getExecutives(id) {
    this.api.getExecutives(id).then((data: any) => {
      switch (id) {
        case 1:
          this.catCoordinatorList = data;
          this.dataToSendGeneral.directoryId = this.catCoordinatorList[0].directoryId;
          break;
        case 2:
          this.executiveSurexList = data;
          break;
        case 3:
          this.executiveIncidentList = data;
          break;
      }
    }, error => {
    });
  }

  getSalesman() {
    this.api.getSalesman().then((data: any) => {
      this.salesmanList = data;
      this.dataToSendGeneral.userId = this.salesmanList[0].userId;
    }, error => {
    });
  }

  changeSubGroups() {
    const subGroup = [];
    setTimeout(() => {
      try {
        this.clientsSelectedItems.forEach(item => {
          subGroup.push(item.clientId);
        });
        this.dataToSendGeneral.clients = subGroup;
      } catch (e) {
      }
    }, 1000);
  }

  LoadMassImport(template: TemplateRef<any>) {
    this.selectionMassImport = false;
    this.flag = 1;
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }

  /*+-------------------------------------------------------------------------------------------------------+
 |--------------------------------|  A L T A S / B A J A S M A S I V A S  |---------------------------------|
     +-------------------------------------------------------------------------------------------------------+*/

  onChangeInputFileXlsx(e) {
    this.errorXlsx = {active: false, msg: ''};
    this.fileUploadXlsx = e.target.files[0];
    // console.log(this.fileUploadXlsx);
    if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
      this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  onChangeInputFileZip(e) {
    this.errorZip = {active: false, msg: ''};

    if (0 > this.fileTypesZip.indexOf(e.target.files[0].type) && 0 > this.fileTypesRar.indexOf(e.target.files[0].type)) {
      this.errorZip = {active: true, msg: 'Debe seleccionar un archivo válido'};
    } else {
      if (this.fileTypesZip.indexOf(e.target.files[0]) > 0) {
        this.fileUploadZip = e.target.files[0];
      } else {
        this.fileUploadRar = e.target.files[0];
      }
    }
  }
  downloadLayout(id) {
    this.spinner.show('sp');
    this.api.getLayoutFile(id)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (id === 6) {
              downloadLink.setAttribute('download',  'LAYOUT_ASEGURADOS_AMMIA_LITE.xlsx');
            }
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }
  processMassImport() {
    this.spinner.show('sp');
    if (this.fileUploadXlsx === undefined || (this.fileUploadZip === undefined && this.fileUploadRar === undefined)) {
      this.toastr.info('DEBE DE INGRESAR LOS ARCHIVOS SOLICITADOS', 'NOTIFICACION');
      this.spinner.hide('sp');
      return;
    }
    const formDataXlsx = new FormData();
    formDataXlsx.append('fileXlsx', this.fileUploadXlsx);
    if (this.fileUploadZip !== undefined ) {
      formDataXlsx.append('fileCompressed', this.fileUploadZip);
    } else {
      formDataXlsx.append('fileCompressed', this.fileUploadRar);
    }
    this.api.postImportInsuredPolicyLite(formDataXlsx, Number(localStorage.getItem('policy-id-lite')))
      .subscribe(
        responseInsured => {
          this.toastr.success('El proceso de importación ha iniciado en cuanto termine se le notificara.', 'Notificación');
          this.spinner.hide('sp');
          this.modalRef.hide();
        }, error => {
          this.toastr.warning(error.error);
          this.spinner.hide('sp');
        }
      );
  }
  processMassImportLow() {
    this.spinner.show('sp');
    if (this.fileUploadXlsx === undefined) {
      this.toastr.info('DEBE DE INGRESAR LOS ARCHIVOS SOLICITADOS', 'NOTIFICACION');
      this.spinner.hide('sp');
      return;
    }
    const formDataXlsx = new FormData();
    formDataXlsx.append('fileXlsx', this.fileUploadXlsx);
    this.api.postImportInsuredPolicyLiteLow(formDataXlsx, Number(localStorage.getItem('policy-id-lite')))
      .subscribe(
        responseInsured => {
          this.toastr.success('El proceso de importación ha iniciado en cuanto termine se le notificara.', 'Notificación');
          this.spinner.hide('sp');
          this.modalRef.hide();
        }, error => {
          this.toastr.warning(error.error);
          this.spinner.hide('sp');
        }
      );
  }
  loadCatalog(value) {
    this.flag = value.catalogs;
  }

  closeModal() {
    this.modalRef.hide();
    this.selectionMassImport = false;
  }
  selectMassImport() {
    this.selectionMassImport = true;
  }
}
