import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-modal-non-renewable',
  templateUrl: './modal-non-renewable.component.html',
  styleUrls: ['./modal-non-renewable.component.css']
})
export class ModalNonRenewableComponent implements OnInit {
  modalCancellationPolicy: BsModalRef;
  reasonsCancellationList: any = [];
  cancelationId: number;
  dataToSendCancelPolicy: any = {};
  // tslint:disable-next-line:variable-name
  _policyId: number;
  // tslint:disable-next-line:variable-name
  _branchId: number;
  // tslint:disable-next-line:variable-name
  _statusId: number;
  @Output() public resultModalNoRenew = new EventEmitter<any>();
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              public fb: FormBuilder,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService) { }

  ngOnInit() {
  }

  openModal(template: TemplateRef<any>) {
    this.cancelationId = 0;
    this.spinner.show('sp');
    const dataObj = {
      branchId: this.branchId,
      statusId: this._statusId
    };
    this.api.getReasonsNoRenew(dataObj).then((data: any) => {
      this.reasonsCancellationList = data;
      // tslint:disable-next-line:max-line-length
      this.modalCancellationPolicy = this.modalService.show(template, {class: 'modal-md', keyboard: false, ignoreBackdropClick: true, backdrop: false});
      this.spinner.hide('sp');
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  closeModalCancelPolicy() {
    this.modalCancellationPolicy.hide();
  }

  cancelPolicy() {
    if (this.cancelationId === null || this.cancelationId === undefined) {
      this.toastr.warning('Es nesario establecer un motivo de no renovación.', 'Notificación');
      return;
    }
    this.spinner.show('sp');
    this.dataToSendCancelPolicy = {
      policyId: this._policyId,
      statusId: this._statusId,
      number: '',
      cancelationId: this.cancelationId
    };
    console.log(this.dataToSendCancelPolicy);
    this.api.putChangeStatusPolicy(this.dataToSendCancelPolicy)
      .then(
        (data) => {
          this.toastr.success('Su información ha sido actualizada correctamente.', 'Notificación');
          this.spinner.hide('sp');
          this.resultModalNoRenew.emit('cierre');
          this.modalCancellationPolicy.hide();
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        });
  }

  get policyId(): number {
    return this._policyId;
  }
  @Input()
  set policyId(value: number) {
    this._policyId = value;
  }
  get branchId(): number {
    return this._branchId;
  }
  @Input()
  set branchId(value: number) {
    this._branchId = value;
  }

  get statusId(): number {
    return this._statusId;
  }
  @Input()
  set statusId(value: number) {
    this._statusId = value;
  }

}
