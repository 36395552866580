import { Component, OnInit } from '@angular/core';
import {ChartDataSets} from 'chart.js';
import {Color, Label} from 'ng2-charts';

@Component({
  selector: 'app-collection-chart',
  templateUrl: './collection-chart.component.html',
  styleUrls: ['./collection-chart.component.css']
})
export class CollectionChartComponent implements OnInit {

  constructor() { }
  lineChartData: ChartDataSets[] = [
    { data: [85, 83, 85, 86, 87, 83],
      label: 'Total Cobranza' },
  ];

  lineChartLabels: Label[] = ['Chevrolet', 'Nissan', 'Jeep', 'Dodge', 'Toyota', 'Chrysler'];

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: '#326173',
      backgroundColor: 'rgba(41, 63, 121, 0.05)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
  ngOnInit() {
  }

}
