import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-modal-car-attc',
  templateUrl: './modal-car-attc.component.html',
  styleUrls: ['./modal-car-attc.component.css']
})
export class ModalCarAttcComponent implements OnInit {

  get idCategory(): number {
    return this._idCategory;
  }
  @Input()
  set idCategory(value: number) {
    this._idCategory = value;
  }

  modalRef: BsModalRef;
  dataToSendIssuePolicy: any = {};
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  public fileUploadZip: File;
  errorZip = {active: false, msg: ''};
  fileTypesZip: string[] = ['application/zip', 'application/x-zip-compressed'];
  responseImportInsured: any = [];
  insuredProcess: number;
  insuredUnprocessed: number;
  importMassive: boolean;
  branchId: string;
  validationPolicy: any = {};
  categoryList: any = [];
  userinfo: { nombre, apellidoPaterno, apellidoMaterno, email, birthday, policyId, genero };

  private _idCategory: number;
  bsLeavingDateBirthDate: Partial<BsDatepickerConfig>;
  catGenders: any = [];
  genderSelected;

  modalSaveEndorsement: BsModalRef;
  private _dataToSendGeneral: any = {};
  endosoId: number;

  constructor(private modalService: BsModalService,
              private toastr: ToastrService,
              private shared: SharedService,
              private api: ApiService,
              private router: Router,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.userinfo = {
      nombre: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      email: '',
      birthday: '',
      genero: '',
      policyId: ''
    };

    this.bsLeavingDateBirthDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      adaptivePosition: true
    });

    this.invokeServiceGetGenders();
  }

  getSelectedGender(event) {
    this.genderSelected = event;
    console.log(event);
  }

  invokeServiceGetGenders() {
    console.log('invokeServiceGetGenders');
    const data = {name: 'GENDERS'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
      if (data != null) {
        this.catGenders = data;
        console.log('data');
        console.log(data);
      }
    }, error => {
      //console.error(error.status);
      //console.error(error.statusText);
      //console.error(error.message);
    });
  }

  openModal(template: TemplateRef<any>) {
    this.spinner.show('sp');
    this.loadInitialModal();
    this.modalRef = this.modalService.show(template,  {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
    this.spinner.hide('sp');
  }

  SendPolicy() {

    this.spinner.show('sp');
    this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
    const formDataXlsx = new FormData();
    formDataXlsx.append('fileXlsx', this.fileUploadXlsx);
    // const formDataZip = new FormData();
    formDataXlsx.append('fileCompressed', this.fileUploadZip);
    formDataXlsx.append('policyId', localStorage.getItem('policy-id'));
    formDataXlsx.append('categoryId', String(this.idCategory));
    formDataXlsx.append('endorsementId', String(this.endosoId));
    this.api.postMassiveCar(formDataXlsx)
      .then( // Si todo bien, se guarda
        (response: any) => {
          this.responseImportInsured = response;
          this.toastr.success('EL PROCESO DE IMPORTACIÓN HA INICIADO EN CUANTO TERMINE SE LE NOTIFICARA', 'NOTIFICACIÓN');
          this.importMassive = true;
          this.spinner.hide('sp');
          this.modalRef.hide();
          if (this.modalSaveEndorsement !== undefined) {
            this.modalSaveEndorsement.hide();
          }
          this.router.navigate(['/admin/executive']);
        }, error => {
          console.error();
          this.modalRef.hide();
          if (this.modalSaveEndorsement !== undefined) {
            this.modalSaveEndorsement.hide();
          }
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
          this.spinner.hide('sp');
        }
      );
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  onChangeInputFileXlsx(e) {
    this.errorXlsx = {active: false, msg: ''};
    this.fileUploadXlsx = e.target.files[0];
    // console.log(this.fileUploadXlsx);
    if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
      this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  onChangeInputFileZip(e) {
    this.errorZip = {active: false, msg: ''};
    this.fileUploadZip = e.target.files[0];
    if (0 > this.fileTypesZip.indexOf(this.fileUploadZip.type)) {
      this.errorZip = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  loadInitialModal() {
    this.importMassive = false;
    this.branchId = localStorage.getItem('policy-branchId');
    this.dataToSendIssuePolicy = {
      policyId: localStorage.getItem('policy-id'),
      statusId: 1,
      number: ''
    };
    this.fileUpload = undefined;
    this.fileUploadXlsx = undefined;
    this.fileUploadZip = undefined;
    this.getCategoriesPolicy();
  }

  downloadLayout() {
    this.spinner.show('sp');
    this.api.getLayoutFile(1)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', 'LAYOUT_CAR.xlsx');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  getCategoriesPolicy() {
    this.categoryList = [];
    this.api.getCategoriesPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.categoryList = data;
    }, error => {
    });
  }


  get dataToSendGeneral(): any {
    return this._dataToSendGeneral;
  }

  @Input()
  set dataToSendGeneral(value: any) {
    this._dataToSendGeneral = value;
  }

  openModalSaveEndorsement(template: TemplateRef<any>, option:number, carDetail?) {
    console.log(this.fileUploadXlsx);

    if (this.fileUploadXlsx === undefined) {
      this.toastr.warning('DEBE DE CARGAR EL LAYOUT DE SUS AUTOS', 'NOTIFICACIÓN');
      return;
    }
    if (this.fileUploadZip === undefined) {
      this.toastr.warning('DEBE DE CARGAR LOS CERTIFICADOS', 'NOTIFICACIÓN');
      return;
    }

    if (this.dataToSendGeneral.status === 1 || this.dataToSendGeneral.status === 4) {
      if (this.dataToSendGeneral.typeAdministrationPolicy !== 1 || this.dataToSendGeneral.typeAdministrationPolicy !== '1') {
        this.modalSaveEndorsement = this.modalService.show(template, {class: 'modal-lg', keyboard: false, animated: true});
      }
    } else {
      this.saveNewCarOrChanges({saveData: true, endorsementId: 0});
    }

  }

  saveNewCarOrChanges(endorsement?) {
    if (endorsement || endorsement.saveData) {
      this.endosoId = endorsement.endorsementId === undefined ? 0 : endorsement.endorsementId;
      this.SendPolicy();
    }
  }
}
