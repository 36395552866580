import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-admin-role',
  templateUrl: './admin-role.component.html',
  styleUrls: ['./admin-role.component.css']
})
export class AdminRoleComponent implements OnInit {

  rolList: any = [];
  // Varibales para paginado de usuarios
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filters: any = {};
  roleList: any[]; // Tipos de rol

  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) { }

  ngOnInit() {
    this.invokeServiceGetRoles();
    this.validateAccess.validateAccessCore(35).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.clearFiltersTable();
      }
    });
  }
  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    this.filters = {
      role: null,
      page: 1,
      size: 10
    };
    this.validateAccess.validateAccessCore(35).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }
  /**
   * Carga los datos según la página seleccionada
   */
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }
  /**
   * Función para obtener los roles del sistema
   */
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    const role = this.filters.role;
    if (role !== null) {
      this.filters.role = Number(role);
    }
    this.api.getRolPagination(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.rolList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.rolList = this.paginationResponse.tList;
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.rolList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }
  /**
   * Función para obtener los roles del sistema
   */
  loadFiltersTable() {
    this.validateAccess.validateAccessCore(35).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }
  /**
   * Función para ir al detalle de un rol
   */
  openDetailUser(roleId, operation) {
    let accessCore = 0;
    if (operation === 0) {
      accessCore = 36;
    } else if (operation === 1) {
      accessCore = 37;
    } else if (operation === 2) {
      accessCore = 38;
    }
    this.validateAccess.validateAccessCore(accessCore).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        localStorage.setItem('role-id', roleId);
        localStorage.setItem('operationUser', operation);
        this.router.navigate(['/admin/role_detail']);
      }
    });
  }
  /**
   * Función para cargar roles
   */
  invokeServiceGetRoles() {
    this.api.getRolListRol()
      .then(
        (response: any) => {
          this.roleList = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }

}
