import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-attachment-mdl',
  templateUrl: './attachment-mdl.component.html',
  styleUrls: ['./attachment-mdl.component.css']
})
export class AttachmentMdlComponent implements OnInit {
  modalRef: BsModalRef;
  dataToSendAttachmentReceipt: any = {};
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['*'];
  receiptAttachmentList: any = [];
  public fileUploadAttachment: File;
  dataToSendAttachment: any = {};
  constructor(private modalService: BsModalService,
              private toastr: ToastrService,
              private shared: SharedService,
              private api: ApiService,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) { }

  ngOnInit() {
  }
  openModal(template: TemplateRef<any>) {
    this.validateAccess.validateAccessCore(71).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        setTimeout( () => {
          this.getAttachmentReceipt();
          this.loadInitialModal();
        }, 1000);
        this.modalRef = this.modalService.show(template);
      }
    });
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  loadInitialModal() {
    this.dataToSendAttachment = {
      attachmentId: 0,
      name: undefined,
      attachmentPath: undefined,
      typeAttachmentId: 1,
      relationId: localStorage.getItem('receipt-id')
    };
    this.fileUpload = undefined;
  }
  invokeServiceForGetAttachmentFile(attachment) {
    this.api.getAttachmentFilepolicy(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          // console.error(error);
        }
      );
  }
  deleteAttachment(attanchment) {
    this.spinner.show('sp');
    // console.log(attanchment);
    this.api.deleteAttachment(attanchment.attachmentId).subscribe( response => {
      this.getAttachmentReceipt();
      this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
      this.api.deletePolicyAttachmentRelation(attanchment.attachmentId).subscribe( response1 => {
        // console.log('ok');
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
      });
    }, error => {
      this.spinner.hide('sp');
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }
  getAttachmentReceipt() {
    this.api.getAttachmentReceipt(localStorage.getItem('receipt-id')).then((data: any) => {
      if (data != null) {
        this.receiptAttachmentList = data;
        // console.log(this.receiptAttachmentList);
      } else {
        this.receiptAttachmentList = [];
      }
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
      this.receiptAttachmentList = [];
    });
  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    this.fileUploadAttachment = evt.target.files[0];
    this.dataToSendAttachment.name = this.fileUploadAttachment.name;
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoaded(e) {
    this.dataToSendAttachment.attachmentPath = btoa(e.target.result);
  }
  saveAttachment() {
    this.spinner.show('sp');
    // console.log(this.dataToSendAttachment);
    if (this.dataToSendAttachment.typeAttachmentId === 8) {
      this.dataToSendAttachment.attachmentPath = this.dataToSendAttachment.name;
      this.dataToSendAttachment.name = 'DIRECTORIO MEDICO';
    } else if (this.dataToSendAttachment.attachmentPath === undefined) {
      this.toastr.warning('DEBE DE CARGAR UN ARCHIVO', 'NOTIFICACIÓN');
      this.spinner.hide('sp');
      return;
    }
    this.api.postAttachmentDataReceipt(this.dataToSendAttachment)
      .then(
        (response: any) => {
          this.getAttachmentReceipt();
          this.dataToSendAttachment = {
            attachmentId: 0,
            name: undefined,
            attachmentPath: undefined,
            typeAttachmentId: 1,
            relationId: localStorage.getItem('receipt-id')
          };
          this.fileUploadAttachment = undefined;
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.spinner.hide('sp');
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

}
