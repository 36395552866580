import {Component, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, FormGroupName, Validators} from '@angular/forms';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {NgxSpinnerService} from 'ngx-spinner';
import {disable} from '@amcharts/amcharts4/.internal/core/utils/Debug';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-admin-receipts',
  templateUrl: './admin-receipts.component.html',
  styleUrls: ['./admin-receipts.component.css']
})
export class AdminReceiptsComponent implements OnInit {
  modalRef: BsModalRef;
  receiptsList: any = [];
  filterRangeDate: Date[];
  filterRangeDateDeadline: Date[];
  bsConfig: Partial<BsDatepickerConfig>;
  bsConfigDeadline: Partial<BsDatepickerConfig>;
  filterDate: any = {};
  clientList: any = [];
  groupList: any = [];
  insuranceList: any = [];
  statusList: any = [];
  policyList: any = [];
  executiveList: any = [];
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  groupsSelectedItems: any = [];
  dropdownSettingsGroups = {};
  insurancesSelectedItems: any = [];
  dropdownSettingsInsurances = {};
  statusSelectedItems: any = [];
  dropdownSettingsStatus = {};
  policiesSelectedItems: any = [];
  dropdownSettingsPolicies = {};
  executivesSelectedItems: any = [];
  dropdownSettingsExecutives = {};
  currencyList: any = [];
  currencySelectedItems: any = [];
  dropdownSettingsCurrency: any = {};
  salesmanList: any = [];
  salesmanSelectedItems: any = [];
  dropdownSettingsSalesman: any = {};
  coordinatorList: any = [];
  coordinatorSelectedItems: any = [];
  dropdownSettingsCoordinator: any = {};
  executiveSurexList: any = [];
  surexSelectedItems: any = [];
  dropdownSettingsSurex: any = {};
  executiveIncidentList: any = [];
  incidentSelectedItems: any = [];
  dropdownSettingsIncident: any = {};
  branchList: any = [];
  branchSelectedItems: any = [];
  dropdownSettingsBranch: any = {};
  subBranchList: any = [];
  subBranchSelectedItems: any = [];
  dropdownSettingsSubBranch: any = {};
  statusPolicyList: any = [];
  statusPolicySelectedItems: any = [];
  dropdownSettingsStatusPolicy: any = {};
  isCollapsed: boolean;
  rangeDate: string;
  rangeDateDeadline: string;
  rangeGroups: string;
  rangeSubgroups: string;
  rangeInsurances: string;
  rangeExecutive: string;
  rangePolicies: string;
  rangeStatus: string;
  rangeGroupList: any = [];
  rangeSubgroupList: any = [];
  rangeInsuranceList: any = [];
  rangeExecutiveList: any = [];
  rangePolicyList: any = [];
  rangeStatusList: any = [];
  rangeCurrency: any = [];
  rangeSubBranch: any = [];
  rangeBranch: any = [];
  rangeSalesman: any = [];
  rangeIncident: any = [];
  rangeSurexs: any = [];
  rangeCoordinator: any = [];
  rangeStatusReceipt: any = [];
  editR: boolean;
  editRece: boolean;
  paymentList: any = [];
  paymentFormId: number;
  receiptStatusId: number;
  receiptData: any = {};
  paginationResponse: any = {};
  filters: any = {};
  // -Varibales para paginado de recibos->
  pgReceiptBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgReceiptMaxSize = 3; // Número de links en paginado
  pgReceiptItemsPerPage = 10; // Número de registros por página
  pgReceiptTotalItems: number; // Total de registros
  pgReceiptCurrentPage = 1; // Página actual
  bsStartDate: Partial<BsDatepickerConfig>;
  roleId: number;
  // variables modal receipt
  myFormReceipt: FormGroup;
  dataReceipt: any = {};
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService,
              private validateAccess: ValidateAccessService) {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
    this.shared.gReceiptPolicyIdObservable().subscribe(
      gReceiptsPolicyId => {
        // localStorage.setItem('policy-id', String(121));
        if (gReceiptsPolicyId > -1) {
        //  localStorage.setItem('policy-id', String(121));
        }

      }
    );
    this.myFormReceipt = this.fb.group({
      numberReceipt: [''],
      paymentDeadline: [''],
      paymentDate: [''],
      netPremium: [''],
      rights: [''],
      paymentCharges: [''],
      iva: [''],
      totalPremium: [''],
      receiptStatus: [''],
      paymentForm: [''],
      settlementNumber: ['']
    });
  }

  ngOnInit() {
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });
    this.bsConfigDeadline = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });
    this.bsStartDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });
    this.roleId = Number(localStorage.getItem('roleId'));
    this.editR = true;
    this.rangeDate = undefined;
    this.rangeGroups = undefined;
    this.rangeSubgroups = undefined;
    this.rangeInsurances = undefined;
    this.rangeExecutive = undefined;
    this.rangePolicies = undefined;
    this.rangeStatus = undefined;
    this.rangeCurrency = undefined;
    this.rangeSubBranch = undefined;
    this.rangeBranch = undefined;
    this.rangeSalesman = undefined;
    this.rangeIncident = undefined;
    this.rangeSurexs = undefined;
    this.rangeCoordinator = undefined;
    this.rangeStatusReceipt = undefined;
    this.rangeGroupList = [];
    this.rangeSubgroupList = [];
    this.rangeInsuranceList = [];
    this.rangeExecutiveList = [];
    this.rangePolicyList = [];
    this.rangeStatusList = [];
    this.isCollapsed = true;
    this.shared.gSearchId = 2;
    this.getGroups();
    this.getInsuranceCompanies();
    this.getStatusReceipts();
    this.getPolicies();
    this.getPaymentForms();
    this.getCurrency();
    this.getSalesman();
    this.getExecutives(1);
    this.getExecutives(2);
    this.getExecutives(3);
    this.getBranches();
    this.getStatusPolicy();
    this.dropdownSettingsClients = {
      singleSelection: false,
      idField: 'clientId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsGroups = {
      singleSelection: false,
      idField: 'groupId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsInsurances = {
      singleSelection: false,
      idField: 'insuranceId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ASEGURADORAS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsStatus = {
      singleSelection: false,
      idField: 'receiptStatusId',
      textField: 'receiptStatus',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ESTATUS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsPolicies = {
      singleSelection: false,
      idField: 'policyId',
      textField: 'number',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON DOCUMENTOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSalesman = {
      singleSelection: false,
      idField: 'userId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON VENDEDORES',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsCurrency = {
      singleSelection: false,
      idField: 'currencyId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON MONEDAS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsCoordinator = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON COORDINADORES',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSurex = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EJECUTIVOS SUREXS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsIncident = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EJECUTIVO SINIESTRO',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsBranch = {
      singleSelection: false,
      idField: 'branchId',
      textField: 'branchType',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON RAMAS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSubBranch = {
      singleSelection: false,
      idField: 'subBranchId',
      textField: 'subBranch',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB RAMOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsStatusPolicy = {
      singleSelection: false,
      idField: 'statusPolicyId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ESTATUS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.loadFilters();
    if (localStorage.getItem('filter-receipts') != undefined) {
      switch (localStorage.getItem('filter-receipts')) {
        case 'paid':
          this.statusSelectedItems = [{receiptStatusId: 3, receiptStatus: 'PAGADO'}];
          this.filters.statusPayment = ['PAGADO'];
          break;
        case 'pending':
          this.statusSelectedItems = [{receiptStatusId: 2, receiptStatus: 'PENDIENTE'}];
          this.filters.statusPayment = ['PENDIENTE'];
          break;
        case 'cancellations':
          this.statusSelectedItems = [{receiptStatusId: 3, receiptStatus: 'CANCELADO'}];
          this.filters.statusPayment = ['CANCELADO'];
          break;
      }
    }
    this.getReceiptByPagination(1, 10);
  }
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  getReceiptsPolicy() {
    this.api.getReceiptsPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.receiptsList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  receiptModal(r) {
    localStorage.setItem('receipt-id', r.receipNumber);
    // // console.log(localStorage.getItem('receipt-id'));
  }
  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getSubGroups(groups) {
    this.api.getSubGroupsMultiselect(groups).then((data: any) => {
      this.clientList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getInsuranceCompanies() {
    this.api.getInsuranceCompanies().then((data: any) => {
      this.insuranceList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getStatusReceipts() {
    this.api.getStatusReceipts().then((data: any) => {
      this.statusList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getPaymentForms() {
    this.api.getPaymentForms().then((data: any) => {
      this.paymentList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getPolicies() {
    this.api.getPolicyFilterDocuments().then((data: any) => {
      this.policyList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getCurrency() {
    this.currencyList = [
      { currencyId: 1, name: 'MX'} , {currencyId: 2, name: 'USD'}
    ];
  }
  getSalesman() {
    this.api.getSalesman().then((data: any) => {
      this.salesmanList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getExecutives(id) {
    this.api.getExecutives(id).then((data: any) => {
      switch (id) {
        case 1:
          this.coordinatorList = data;
          break;
        case 2:
          this.executiveSurexList = data;
          break;
        case 3:
          this.executiveIncidentList = data;
          break;
      }
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getBranches() {
    this.api.getBranches().then((data: any) => {
      this.branchList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getSubBranches(id) {
    this.api.getSubBranchesMulti(id).then((data: any) => {
      this.subBranchList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getStatusPolicy() {
    this.statusPolicyList = [
      {statusPolicyId: 1, name: 'ACTIVA'},
      {statusPolicyId: 6, name: 'RENOVACIÓN'},
      {statusPolicyId: 0, name: 'CANCELADO'},
      {statusPolicyId: 3, name: 'NO RENOVABLE'},
      {statusPolicyId: 7, name: 'NO RENOVADA'}
    ];
  }

  loadDatesData(filterRangeDate: Date[]) {
    if (filterRangeDate != null) {
      this.filters.dateStart = this.datePipe.transform(filterRangeDate[0], 'yyyy-MM-dd');
      this.filters.dateEnd = this.datePipe.transform(filterRangeDate[1], 'yyyy-MM-dd');
      this.rangeDate = this.filters.dateStart + '-' + this.filters.dateEnd + ' ';
    } else {
      this.filters.dateStart = '';
      this.filters.dateEnd = '';
      this.rangeDate = undefined;
    }
  }
  loadDatesDataDeadline(filterRangeDateDeadline: Date[]) {
    if (filterRangeDateDeadline != null) {
      this.filters.dateStartDeadline = this.datePipe.transform(filterRangeDateDeadline[0], 'yyyy-MM-dd');
      this.filters.dateEndDeadline = this.datePipe.transform(filterRangeDateDeadline[1], 'yyyy-MM-dd');
      this.rangeDateDeadline = this.filters.dateStartDeadline + '-' + this.filters.dateEndDeadline + ' ';
      // console.log(this.filters);
    } else {
      this.filters.dateStartDeadline = '';
      this.filters.dateEndDeadline = '';
      this.rangeDateDeadline = undefined;
    }
  }
  changeStatusGroups() {
    const groups = [];
    let groupsName = '';
    const group = [];
    setTimeout(() => {
      try {
        this.groupsSelectedItems.forEach(item => {
          groups.push(item.name);
          group.push(item.groupId);
          groupsName = groupsName + item.name + ', ';
        });
        this.rangeGroups = groupsName + ' ';
        this.rangeGroupList = groups;
        let multiGroup = {};
        multiGroup = {
          idsGroups: group
        };
        this.getSubGroups(multiGroup);
        this.filters.namesGroup = groups;
      } catch (e) { }
    }, 1000);
  }
  changeStatusSubGroups() {
    const subGroups = [];
    let subGroupsName = '';
    setTimeout(() => {
      try {
        this.clientsSelectedItems.forEach(item => {
          subGroups.push(item.name);
          subGroupsName = subGroupsName + item.name + ', ';
        });
        this.rangeSubgroupList = subGroups;
        this.rangeSubgroups = subGroupsName + ' ';
        this.filters.namesSubGroup = subGroups;
      } catch (e) { }
    }, 500);
  }
  changeStatusInsurances() {
    const insurances = [];
    let insurancesName = '';
    setTimeout(() => {
      try {
        this.insurancesSelectedItems.forEach(item => {
          insurances.push(item.name);
          insurancesName = insurancesName + item.name + ', ';
        });
        this.rangeInsuranceList =  insurances;
        this.rangeInsurances = insurancesName + ' ';
        this.filters.namesInsurance = insurances;
      } catch (e) { }
    }, 500);
  }
  changeStatusExecutives() {
    const executives = [];
    let executivesName = '';
    setTimeout(() => {
      try {
        this.executivesSelectedItems.forEach(item => {
          executives.push(item.executiveId);
          executivesName = executivesName + item.name + ', ';
        });
        this.rangeExecutiveList =  executives;
        this.rangeExecutive = executivesName + ' ';
      } catch (e) { }
    }, 500);
  }
  changeStatusPolicies() {
    const policies = [];
    let policiesName = '';
    setTimeout(() => {
      try {
        this.policiesSelectedItems.forEach(item => {
          policies.push(item.policyId);
          policiesName = policiesName + item.number + ', ';
        });
        this.rangePolicyList = policies;
        this.rangePolicies =  policiesName + ' ';
        this.filters.policyId = policies;
        this.rangeStatusReceipt = policiesName;
      } catch (e) { }
    }, 500);
  }
  changeStatusReceipts() {
    const status = [];
    let statusName = '';
    setTimeout(() => {
      try {
        this.statusSelectedItems.forEach(item => {
          status.push(item.receiptStatus);
          statusName = statusName + item.receiptStatus + ', ';
        });
        this.rangeStatusList = status;
        this.rangeStatus = statusName + ' ';
        this.filters.statusPayment = status;
      } catch (e) { }
    }, 500);
  }
  changeStatusCurrency() {
    const currencies = [];
    let currencyName = '';
    setTimeout(() => {
      try {
        this.currencySelectedItems.forEach(item => {
          currencies.push(item.name);
          currencyName = currencyName + item.name + ', ';
        });
        this.filters.currency = currencies;
        this.rangeCurrency = currencyName;
      } catch (e) { }
    }, 500);
  }
  changeStatusSalesman() {
    const salesman = [];
    let salesmanName = '';
    setTimeout(() => {
      try {
        this.salesmanSelectedItems.forEach(item => {
          salesman.push(item.name);
          salesmanName = salesmanName + item.name + ', ';
        });
        this.filters.nameSalesMan = salesman;
        this.rangeSalesman = salesmanName;
      } catch (e) { }
    }, 500);
  }
  changeStatusCoordinator() {
    const coordinator = [];
    let coordinatorName = '';
    setTimeout(() => {
      try {
        this.coordinatorSelectedItems.forEach(item => {
          coordinator.push(item.name);
          coordinatorName = coordinatorName + item.name + ', ';
        });
        this.filters.namesCoordinator = coordinator;
        this.rangeCoordinator = coordinatorName;
      } catch (e) { }
    }, 500);
  }
  changeStatusSurexs() {
    const surexs = [];
    let surexsName = '';
    setTimeout(() => {
      try {
        this.surexSelectedItems.forEach(item => {
          surexs.push(item.name);
          surexsName = surexsName + item.name + ', ';
        });
        this.filters.namesEjecutivesSurex = surexs;
        this.rangeSurexs = surexsName;
      } catch (e) { }
    }, 500);
  }
  changeStatusIncident() {
    const incident = [];
    let incidentName = '';
    setTimeout(() => {
      try {
        this.surexSelectedItems.forEach(item => {
          incident.push(item.name);
          incidentName = incidentName + item.name + ', ';
        });
        this.filters.namesEjecutivesSiniestro = incident;
        this.rangeIncident = incidentName;
      } catch (e) { }
    }, 500);
  }
  changeStatusBranch() {
    const branch = [];
    let branchName = '';
    const branches = [];
    setTimeout(() => {
      try {
        this.branchSelectedItems.forEach(item => {
          branch.push(item.branchId);
          branches.push(item.branchType);
          branchName = branchName + item.branchType + ', ';
        });
        this.getSubBranches(branch);
        this.filters.namesBranch = branches;
        this.rangeBranch = branchName;
      } catch (e) { }
    }, 500);
  }
  changeStatusSubBranch() {
    const subBranch = [];
    let subBranchName = '';
    setTimeout(() => {
      try {
        this.subBranchSelectedItems.forEach(item => {
          subBranch.push(item.subBranch);
          subBranchName = subBranchName + item.subBranch + ', ';
        });
        this.filters.namesSubBranch = subBranch;
        this.rangeSubBranch = subBranchName;
      } catch (e) { }
    }, 500);
  }
  changeStatusPolicy() {
    const statusPolicy = [];
    let statusPolicyName = '';
    setTimeout(() => {
      try {
        this.statusPolicySelectedItems.forEach(item => {
          statusPolicy.push(item.name);
          statusPolicyName = statusPolicyName + item.name + ', ';
        });
        this.filters.statusPolicy = statusPolicy;
      } catch (e) { }
    }, 500);
  }
  editReceipt(r) {
    this.spinner.show('sp');
    this.api.getReceiptById(r.receipNumber)
      .then(
        (response: any) => {
          // console.log(response);
          this.receiptData = {
            receiptId: response[0].receiptId,
            commission: 0,
            commissionAmount: 0,
            netPremium: response[0].netPremium,
            rights: response[0].rights,
            paymentCharges: response[0].paymentCharges,
            iva: response[0].iva,
            totalPremium: response[0].totalPremium,
            paymentDate: new Date(response[0].paymentDate),
            policyId: r.policyId,
            receiptStatusId: response[0].receiptStatusId,
            paymentFormId: response[0].paymentFormId,
            settlementNumber: response[0].settlementNumber
          };
          // console.log(this.receiptData);
          this.editR = false;
          document.getElementById('paymentDate' + r.receipNumber).style.display = 'block';
          document.getElementById('lpaymentDate' + r.receipNumber).style.display = 'none';
          document.getElementById('netPremium' + r.receipNumber).style.display = 'block';
          document.getElementById('lnetPremium' + r.receipNumber).style.display = 'none';
          document.getElementById('rights' + r.receipNumber).style.display = 'block';
          document.getElementById('lrights' + r.receipNumber).style.display = 'none';
          document.getElementById('paymentCharges' + r.receipNumber).style.display = 'block';
          document.getElementById('lpaymentCharges' + r.receipNumber).style.display = 'none';
          document.getElementById('iva' + r.receipNumber).style.display = 'block';
          document.getElementById('liva' + r.receipNumber).style.display = 'none';
          document.getElementById('totalPremium' + r.receipNumber).style.display = 'block';
          document.getElementById('ltotalPremium' + r.receipNumber).style.display = 'none';
          document.getElementById('status' + r.receipNumber).style.display = 'block';
          document.getElementById('lstatus' + r.receipNumber).style.display = 'none';
          document.getElementById('paymentr' + r.receipNumber).style.display = 'block';
          // document.getElementById('lpaymentr' + r.receipNumber).style.display = 'none';
          document.getElementById('settlementNumber' + r.receipNumber).style.display = 'block';
          document.getElementById('edit' + r.receipNumber).style.display = 'none';
          document.getElementById('save' + r.receipNumber).style.display = 'block';
          this.spinner.hide('sp');
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  pageReceiptChanged(event: PageChangedEvent): void {
    // // console.log(event);
    this.pgReceiptCurrentPage = event.page;
    this.getReceiptByPagination(this.pgReceiptCurrentPage, this.pgReceiptItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() {
   /* setTimeout(() => {
      // console.log(this.paginationUser.elementRef.nativeElement.childNodes[0].childNodes);
      for (const obj of this.paginationUser.elementRef.nativeElement.childNodes[0].childNodes) {
        // console.log(obj);
        // console.log(obj.className);

        if (obj.className != null && obj.className.includes('pagination-page page-item active') ) {
          // console.log('entroo');
          obj.childNodes[0].style.background = this.sharedService.gSkin;
          obj.childNodes[0].style.borderColor = '#ffffff';
        }
        if (obj.className != null && obj.className === 'pagination-page page-item' ) {
          obj.childNodes[0].style.color = this.sharedService.gSkin;
        }
        if (obj.className != null && obj.className === 'pagination-page page-item disabled' ) {
          obj.childNodes[0].style.color = this.sharedService.gSkin;
        }
        if (obj.className != null && obj.className === 'pagination-next page-item' ) {
          obj.childNodes[0].style.color = this.sharedService.gSkin;
        }
      }
    }, 10);*/
  }
  getReceiptByPagination(page, size) {
    this.spinner.show('sp');
    // console.log(this.filters);
    this.api.getReceiptsPagination(this.filters, page, size)
      .then(
        (response: any) => {
          if (response == null) {
            this.paginationResponse = [];
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON LA BUSQUEDA SOLICITADA', 'NOTIFICACIÓN');
            this.pgReceiptTotalItems = 0;
            this.receiptsList = [];
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgReceiptTotalItems = this.paginationResponse.total ;
          this.receiptsList = this.paginationResponse.receipts ;
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  updateReceipt() {
    // console.log(this.receiptData);
    this.spinner.show('sp');
    if (this.receiptData.paymentDate == 'Invalid Date') {
      this.spinner.hide('sp');
      this.toastr.info('INGRESE UNA FECHA VALIDA', 'Notificación');
      return;
    }
    this.receiptData.paymentDate = new Date(this.receiptData.paymentDate);
    this.api.putReceiptData(this.receiptData)
      .then(
        (response: any) => {
          this.getReceiptByPagination(this.pgReceiptCurrentPage, this.pgReceiptItemsPerPage);
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.spinner.hide('sp');
          this.modalRef.hide();
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  calTotal() {
    // tslint:disable-next-line:max-line-length
    // this.receiptData.totalPremium = this.receiptData.netPremium + this.receiptData.rights + this.receiptData.paymentCharges + this.receiptData.iva;
    // tslint:disable-next-line:max-line-length
    this.receiptData.iva = ((this.receiptData.netPremium + this.receiptData.rights + this.receiptData.paymentCharges) * this.receiptData.taxId) / 100;
    // console.log(this.receiptData.iva);
    // tslint:disable-next-line:max-line-length
    this.receiptData.totalPremium = this.receiptData.netPremium + this.receiptData.rights + this.receiptData.paymentCharges + this.receiptData.iva;
    // console.log(this.receiptData.totalPremium);
  }
  loadFilters() {
    this.filters = {
      policyId: [],
      dateStart: '',
      dateEnd: '',
      namesGroup: [],
      namesSubGroup: [],
      namesCategory: [],
      namesEjecutivesSiniestro: [],
      namesEjecutivesSurex: [],
      namesCoordinator: [],
      nameSalesMan: [],
      namesInsurance: [],
      namesBranch: [],
      namesSubBranch: [],
      statusPolicy: [],
      statusPayment: [],
      currency: [],
      seeCommision: false,
      dateStartDeadline: '',
      dateEndDeadline: ''
    };
  }
  loadFiltersTable(val) {
    this.isCollapsed = val;
    if (val) {
      // console.log(this.filters);
      this.getReceiptByPagination(1, 10);
    }
  }
  downloadExcel() {
    this.validateAccess.validateAccessCore(69).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.spinner.show('sp');
        if (this.roleId === 2) {
          this.filters.seeCommision = true;
        }
        const data = JSON.stringify(this.filters);
        // console.log(data);
        this.api.getExcelFile(this.filters)
          .subscribe(
            (response: any) => {
              // console.log(response);
              const dataType = response.type;
              const binaryData = [];
              binaryData.push(response);
              const downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
              downloadLink.setAttribute('download', 'Recibos.csv');
              document.body.appendChild(downloadLink);
              downloadLink.click();
              downloadLink.remove();
              this.spinner.hide('sp');
            }, error => {
              // console.error(error);
              this.spinner.hide('sp');
            });
      }
    });
  }
  validationDate() {
    // console.log(this.receiptData.paymentDate);
  }

  /****
   * edit receipt
   */
  closeModal() {
    this.modalRef.hide();
  }
  openMdlAttachment(template: TemplateRef<any>, receipt) {
    this.validateAccess.validateAccessCore(70).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getPaymentForms();
        this.getStatusReceipts();
        this.editReceiptMdl(receipt);
        this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
      }
    });
    // console.log(receipt);
  }

  editReceiptMdl(r) {
    this.spinner.show('sp');
    this.api.getReceiptById(r.receipNumber)
      .then(
        (response: any) => {
          this.receiptData = {
            receiptId: response[0].receiptId,
            commission: response[0].commission,
            commissionAmount: response[0].commissionAmount,
            netPremium: response[0].netPremium,
            rights: response[0].rights,
            paymentCharges: response[0].paymentCharges,
            iva: response[0].iva,
            totalPremium: response[0].totalPremium,
            paymentDate: response[0].paymentDate,
            paymentMonth: 0,
            policyId: r.policyId,
            receiptStatusId: response[0].receiptStatusId,
            paymentFormId: response[0].paymentFormId,
            taxId: response[0].taxId,
            settlementNumber: response[0].settlementNumber,
            numberReceipt: response[0].numberReceipt,
            paymentDeadline: response[0].paymentDeadline
          };
          // console.log(this.receiptData);
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
}
