import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgSelectComponent} from '@ng-select/ng-select';
import {number} from '@amcharts/amcharts4/core';
import {ValidateAccessService} from '../validate-access.service';

@Component({
  selector: 'app-attachment-policies-mdl',
  templateUrl: './attachment-policies-mdl.component.html',
  styleUrls: ['./attachment-policies-mdl.component.css']
})
export class AttachmentPoliciesMdlComponent implements OnInit {
  modalRef: BsModalRef;
  dataToSendIssuePolicy: any = {};
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  public fileUploadZip: File;
  errorZip = {active: false, msg: ''};
  fileTypesZip: string[] = ['application/zip', 'application/x-zip-compressed'];
  public fileUploadRar: File;
  errorRar = {active: false, msg: ''};
  fileTypesRar: string[] = ['application/rar', 'application/x-rar-compressed'];
  responseImportInsured: any = [];
  insuredProcess: number;
  insuredUnprocessed: number;
  importMassive: boolean;
  groupList: any = [];
  catClients: any = [];
  policyList: any = [];
  categoriesList: any = [];
  client: any;
  policy: any;
  category: any;
  clientName: any;
  clientId: any;
  policyName: any;
  categoryName: any;
  selectedType = 0;
  constructor(private modalService: BsModalService,
              private toastr: ToastrService,
              private shared: SharedService,
              private api: ApiService,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) { }

  ngOnInit() {
  }openModal(template: TemplateRef<any>) {
    this.validateAccess.validateAccessCore(81).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.client = null;
        this.policy = null;
        this.category = null;
        this.loadInitialModal();
        this.getGroups();
        this.fileUploadXlsx = null;
        this.fileUploadZip = undefined;
        this.fileUploadRar = undefined;
        this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
      }
    });
  }

  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupList = data;
    }, error => {
    });
  }

  SendPolicy() {
    if (this.fileUploadXlsx === undefined || (this.fileUploadZip === undefined && this.fileUploadRar === undefined)) {
        this.toastr.warning('DEBE DE INGRESAR LOS ARCHIVOS SOLICITADOS', 'NOTIFICACION');
    } else {
      this.spinner.show('sp');

      const formData = new FormData();
      formData.append('file', this.fileUpload);
      const formDataXlsx = new FormData();
      formDataXlsx.append('fileXlsx', this.fileUploadXlsx);
      if (this.fileUploadZip !== undefined ) {
        formDataXlsx.append('fileCompressed', this.fileUploadZip);
      } else {
        formDataXlsx.append('fileCompressed', this.fileUploadRar);
      }

      if (this.selectedType === 0)  {
        this.api.postImportInsured(formDataXlsx)
          .subscribe(
            responseInsured => {
              this.responseImportInsured = responseInsured;
              this.toastr.success('El proceso de importación ha iniciado, en cuanto termine se le notificará.', 'Notificación');
              this.insuredUnprocessed = 0;
              this.insuredProcess = 0;
              this.importMassive = true;
              this.cleanFiles();
              this.spinner.hide('sp');
              this.modalRef.hide();
            }, error => {
              console.log(error);
              this.toastr.warning(error.error);
              console.error();
              this.spinner.hide('sp');
            }
          );
      } else {
        this.api.postImportCertificates(formDataXlsx)
          .subscribe(
            responseInsured => {
              this.responseImportInsured = responseInsured;
              this.toastr.success('El proceso de importación ha iniciado, en cuanto termine se le notificará.', 'Notificación');
              this.insuredUnprocessed = 0;
              this.insuredProcess = 0;
              this.importMassive = true;
              this.cleanFiles();
              this.spinner.hide('sp');
              this.modalRef.hide();
            }, error => {
              console.log(error);
              this.toastr.warning(error.error);
              console.error();
              this.spinner.hide('sp');
            }
          );
      }
    }
  }

  downloadLayout() {
    this.spinner.show('sp');
    let typeLayout;
    if (this.selectedType === 0) {
      typeLayout = 2;
    } else {
      typeLayout = 5;
    }
    this.api.getLayoutFile(typeLayout)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (typeLayout === 2) {
              downloadLink.setAttribute('download',  'LAYOUT_ASEGURADOS.xlsx');
            } else {
              downloadLink.setAttribute('download',  'LAYOUT_CERTIFICADOS.xlsx');
            }
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  onChangeInputFileXlsx(e) {
    this.errorXlsx = {active: false, msg: ''};
    this.fileUploadXlsx = e.target.files[0];
    console.log(this.fileUploadXlsx);
    if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
      this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
      this.fileUploadXlsx=null;
    }
  }
  onChangeInputFileZip(e) {
    this.errorZip = {active: false, msg: ''};

    if (0 > this.fileTypesZip.indexOf(e.target.files[0].type) && 0 > this.fileTypesRar.indexOf(e.target.files[0].type)) {
      this.errorZip = {active: true, msg: 'Debe seleccionar un archivo válido'};
    } else {
      if (this.fileTypesZip.indexOf(e.target.files[0]) > 0) {
        this.fileUploadZip = e.target.files[0];
      } else {
        this.fileUploadRar = e.target.files[0];
      }
    }
  }
  loadInitialModal() {
    this.importMassive = false;
    this.dataToSendIssuePolicy = {
      policyId: localStorage.getItem('policy-id'),
      statusId: 1,
      number: ''
    };
    this.fileUpload = undefined;


  }
  closeModal() {
    this.modalRef.hide();
    this.cleanFiles();
  }
  cleanFiles() {
    this.fileUploadXlsx = undefined;
    this.fileUploadZip = undefined;
    this.fileUploadRar = undefined;
  }

  invokeGetSubgrupobyGroup(idGroup) {
    if (idGroup !== undefined ) {
      const group = idGroup.groupId;
      this.api.getSubGroups(group).then((data: any) => {
        this.catClients = data;
      }, error => {

      });
    }
  }

  invokeGetPolicyByGroup(idClient) {
    if (idClient !== undefined ) {
      this.clientName = idClient.name;
      this.clientId = idClient.clientId;
      this.api.getPoliciesByCliente(this.clientId).then((data: any) => {
        this.policyList = data;
      }, error => {

      });
    }
  }

  invokeGetCategoryByPolicies(idPolicy) {
    if (idPolicy !== undefined) {
      for (const i in this.policyList) {
        if (this.policyList[i].policyId === idPolicy) {
          this.policyName = this.policyList[i].number;
        }
      }

      this.api.getCategoriesByPolicyIdClientId(idPolicy, this.clientId).then((data: any) => {
        this.categoriesList = data;
      }, error => {

      });
    }
  }

  getCategory(idPolicy) {
    this.categoryName = idPolicy.name;
  }

  clearGroup() {
    this.client = null;
    this.catClients = [];
    this.policyList = [];
    this.categoriesList = [];
  }

  clearClients() {
    this.policy = null;
    this.policyList = [];
    this.categoriesList = [];
  }

  clearPolicy() {
    this.category = null;
    this.categoriesList = [];
  }

}
