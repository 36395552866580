
import { Injectable } from '@angular/core';


@Injectable()
export class AuthService {



  isAuthenticated(): boolean {

    const token = localStorage.getItem('token');

    // check if token is set, then...
    if (token) {
      return true;
    }
    return false;
  }
}
