import {Component, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormGroup} from "@angular/forms";
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-admin-endorsement',
  templateUrl: './admin-endorsement.component.html',
  styleUrls: ['./admin-endorsement.component.css']
})
export class AdminEndorsementComponent implements OnInit {
  // -Varibles para paginado->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filters: any = {};
  subranchList: any = [];
  coverageList: any = [];
  endorsementId: number;
  modalRef: BsModalRef;
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) { }

  ngOnInit() {
    this.validateAccess.validateAccessCore(57).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.clearFiltersTable();
      }
    });
  }

  functionLoadFilterInitial() {
    this.filters = {
      search: '',
      branchId: 2,
      subBranchId: 18,
      page: 1,
      size: 10
    };
  }
  clearFiltersTable() {
    this.validateAccess.validateAccessCore(57).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.subranchList = [];
        this.functionLoadFilterInitial();
        this.getByPagination(1, 10);
      }
    });
  }
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.pgCurrentPage = page;
    this.api.getEndorsementsPagination(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.coverageList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.coverageList = this.paginationResponse.tList;
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.coverageList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }

  loadFiltersTable() {
    this.validateAccess.validateAccessCore(57).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        console.log(this.filters);
        this.getByPagination(1, 10);
      }
    });
  }

  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }

  invokeServiceDeleteEndorsementById() {
    this.api.deleteEndorsementCivilWorkById(this.endorsementId).subscribe( response => {
      this.modalRef.hide();
      this.toastr.success('OPERACIÓN REALIZADA CON ÉXITO', 'NOTIFICACIÓN');
      this.filters.search = '';
      this.getByPagination(1, 10);
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }
  openDelete(template: TemplateRef<any>, id: number) {
    this.validateAccess.validateAccessCore(59).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.endorsementId = id;
        this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false, backdrop: 'static'});
      }
    });
  }
  closeModal() {
    this.modalRef.hide();
  }

  getResultEndorsement(e) {
    this.getByPagination(1, 10);
  }
}
