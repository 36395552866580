import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label, SingleDataSet} from 'ng2-charts';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {number} from '@amcharts/amcharts4/core';

@Component({
  selector: 'app-graph-usepolicy',
  templateUrl: './graph-usepolicy.component.html',
  styleUrls: ['./graph-usepolicy.component.css']
})
export class GraphUsepolicyComponent implements OnInit {

  lineChartOptions: ChartOptions = {
    responsive: true
  };
  lineChartLabels: Label[] = [];
  lineChartType: ChartType = 'line';
  lineChartLegend  = true;
  lineChartPlugins  = [];
  lineChartData: ChartDataSets[] = [];
  lineChartColors = [
    {
      borderColor: '#326173',
      backgroundColor: 'rgba(68, 138, 166, .5)',
    }
  ];

  private _filters: any = {};
  reportList: any = [];

  constructor(private api: ApiService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {


  }

  loadChart() {
    this.spinner.show('sp');
    this.api.getGraphicUsoPoliza(this._filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.reportList = [];
            this.spinner.hide('sp');
            return;
          }
          this.reportList = response;
          this.creatChart();
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.reportList = [];
          this.spinner.hide('sp');
        }
      );
  }

  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {

    const fecha = new Date();
    const actualYear = fecha.getFullYear();
    const dateStart = '2020-01-01';
    const dateEnd = actualYear.toString().concat('-12-31');

    this._filters = {
      idGrupo: [],
      idSubgrupo: [],
      idRamo: [],
      idSubRamo: [],
      idEjecutivo: [],
      idSalesman: [],
      idAseguradora: [],
      dateStart,
      dateEnd
    };

    this.loadChart();
  }

  creatChart() {
    const data = {
      data: [],
      label: 'casos'
    };
    // Obtenemos las etiquetas
    this.reportList.forEach(X => {
        this.lineChartLabels.push(X.month);
        data.data.push(X.premium);
    });

    this.lineChartData.push(data);

  }

  get filters(): any {
    return this._filters;
  }
  @Input()
  set filters(value: any) {
    this._filters = value;
    this.lineChartData = [];
    this.lineChartLabels = [];
    this.loadChart();
  }

}
