import { Component, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {DatePipe} from '@angular/common';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-admin-report-provision',
  templateUrl: './admin-report-provision.component.html',
  styleUrls: ['./admin-report-provision.component.css']
})
export class AdminReportProvisionComponent implements OnInit {

  reportProvisionList: any = [];
  // -Varibles para paginado de usuarios->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filters: any = {};
  filtersDownload: any = {};
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  groupsSelectedItems: any = [];
  dropdownSettingsGroups = {};
  clientList: any = [];
  groupList: any = [];
  branchList: any = [];
  branchSelectedItems: any = [];
  dropdownSettingsBranch: any = {};
  subBranchList: any = [];
  subBranchSelectedItems: any = [];
  dropdownSettingsSubBranch: any = {};
  executiveSurexList: any = [];
  surexSelectedItems: any = [];
  dropdownSettingsSurex: any = {};
  insuranceList: any = [];
  insurancesSelectedItems: any = [];
  dropdownSettingsInsurances = {};
  filterRangeDate: Date[];
  bsConfig: Partial<BsDatepickerConfig>;
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private spinner: NgxSpinnerService,
              private datePipe: DatePipe,
              private validateAccess: ValidateAccessService) { }

  ngOnInit() {
    this.clearFiltersTable();
    this.getGroups();
    this.getBranches();
    this.getInsuranceCompanies();
    this.getExecutives(2);
    this.dropdownSettingsGroups = {
      singleSelection: false,
      idField: 'groupId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };

    this.dropdownSettingsClients = {
      singleSelection: false,
      idField: 'clientId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsBranch = {
      singleSelection: false,
      idField: 'branchId',
      textField: 'branchType',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON RAMAS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSubBranch = {
      singleSelection: false,
      idField: 'subBranchId',
      textField: 'subBranch',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB RAMOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSurex = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EJECUTIVOS SUREXS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsInsurances = {
      singleSelection: false,
      idField: 'insuranceId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ASEGURADORAS',
      searchPlaceholderText: 'BUSCAR'
    };

  }
  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    this.reportProvisionList = [];
    this.pgTotalItems = 0;

    this.groupsSelectedItems = [];
    this.clientsSelectedItems = [];
    this.branchSelectedItems = [];
    this.subBranchSelectedItems = [];
    this.filterRangeDate = [];
    this.surexSelectedItems = [];
    this.insurancesSelectedItems = [];
    this.filters = {
      idGrupo: [],
      idSubgrupo: [],
      idRamo: [],
      idSubRamo: [],
      idEjecutivo: [],
      idAseguradora: [],
      dateStart: '',
      dateEnd: '',
      page: 1,
      size: 10
    };
  }

  /**
   * Carga los datos según la página seleccionada
   */
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }
  /**
   * Función para obtener los usuarios del sistema
   */
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.api.getReportsProvision(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.reportProvisionList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.reportProvisionList = this.paginationResponse.tList;
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.reportProvisionList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }
  /**
   * Función para obtener los usuarios del sistema
   */
  loadFiltersTable() {
    this.getByPagination(1, 10);
  }

  /**
   * función para regresar a dashboard principal
   */
  goToBack() {
    this.router.navigate(['/admin/report_core']);
  }
  /**
   * obtiene los grupos
   */
  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupList = data;
    }, error => {
      // // console.logerror(error.status);
      // // console.logerror(error.statusText);
      // // console.logerror(error.message);
    });
  }
  getSubGroups(groups) {
    this.api.getSubGroupsMultiselect(groups).then((data: any) => {
      this.clientList = data;
    }, error => {
      // // console.logerror(error.status);
      // // console.logerror(error.statusText);
      // // console.logerror(error.message);
    });
  }
  changeStatusGroups() {
    const group = [];
    let multiGroup = {};
    setTimeout(() => {
      try {
        if (this.groupsSelectedItems.length === 0) {
          this.filters.idGrupo = [];
          this.clientsSelectedItems = [];
        } else {
          this.groupsSelectedItems.forEach(item => {
            group.push(item.groupId);
          });
          multiGroup = {
            idsGroups: group
          };
          this.getSubGroups(multiGroup);
          this.filters.idGrupo = group;
          this.clientsSelectedItems = [];
        }
      } catch (e) { }
    }, 1000);
  }
  changeStatusSubGroups() {
    const subgroup = [];
    setTimeout(() => {
      try {
        if (this.clientsSelectedItems.length === 0) {
          this.filters.idSubgrupo = [];
        } else {
          this.clientsSelectedItems.forEach(item => {
            subgroup.push(item.clientId);
          });
          this.filters.idSubgrupo = subgroup;
        }

      } catch (e) { }
    }, 500);
  }

  changeStatusBranch() {
    const branch = [];
    setTimeout(() => {
      try {
        if (this.branchSelectedItems.length === 0) {
          this.filters.idRamo = [];
        } else {
          this.branchSelectedItems.forEach(item => {
            branch.push(item.branchId);
          });
          this.getSubBranches(branch);
          this.filters.idRamo = branch;
        }
        this.subBranchSelectedItems = [];
      } catch (e) { }
    }, 500);
  }
  changeStatusSubBranch() {
    const subranch = [];
    setTimeout(() => {
      try {
        if (this.subBranchSelectedItems.length === 0) {
          this.filters.idSubRamo = [];
        } else {
          this.subBranchSelectedItems.forEach(item => {
            subranch.push(item.subBranchId);
          });
          this.filters.idSubRamo = subranch;
        }
      } catch (e) { }
    }, 500);
  }

  getSubBranches(id) {
    this.api.getSubBranchesMulti(id).then((data: any) => {
      this.subBranchList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  changeStatusSurexs() {
    const surex = [];
    setTimeout(() => {
      try {
        if (this.surexSelectedItems.length === 0) {
          this.filters.idEjecutivo = 0;
        } else {
          this.surexSelectedItems.forEach(item => {
            surex.push(item.directoryId);
          });
          this.filters.idEjecutivo = surex;
        }
      } catch (e) { }
    }, 500);
  }

  changeStatusInsurances() {
    const insurance = [];
    setTimeout(() => {
      try {
        if (this.insurancesSelectedItems.length === 0) {
          this.filters.idAseguradora = 0;
        } else {
          this.insurancesSelectedItems.forEach(item => {
            insurance.push(item.insuranceId);
          });
          this.filters.idAseguradora = insurance;
        }
      } catch (e) { }
    }, 500);
  }

  loadDatesData(filterRangeDate: Date[]) {
    if (filterRangeDate != null) {
      if (filterRangeDate[0] > filterRangeDate[1]) {
        this.toastr.info('POR FAVOR INGRESE UNA FECHA VALIDA', 'NOTIFICACION');
        setTimeout(() => {
          this.filterRangeDate = undefined;
          this.filters.dateStart = '';
          this.filters.dateEnd = '';
          return;
        }, 500);
      }
      this.filters.dateStart = this.datePipe.transform(filterRangeDate[0], 'yyyy-MM-dd');
      this.filters.dateEnd = this.datePipe.transform(filterRangeDate[1], 'yyyy-MM-dd');
      // // console.loglog(this.filters);
    } else {
      this.filters.dateStart = '';
      this.filters.dateEnd = '';
    }
  }
  getBranches() {
    this.api.getBranches().then((data: any) => {
      this.branchList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  getExecutives(id) {
    this.api.getExecutives(id).then((data: any) => {
      switch (id) {
        case 1:
         // this.coordinatorList = data;
          break;
        case 2:
          this.executiveSurexList = data;
          break;
        case 3:
          // this.executiveIncidentList = data;
          break;
      }
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  getInsuranceCompanies() {
    this.api.getInsuranceCompanies().then((data: any) => {
      this.insuranceList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  invokeServiceForGetReportsProvisionXls() {
    this.validateAccess.validateAccessCore(8).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
      } else {
        this.spinner.show('sp');
        this.api.getReportsProvisionXls(this.filters)
          .then(
            (response: any) => {
              console.log(response);
              if (response == null) {
                this.toastr.info('No se encontraron registros para descargar');
                this.spinner.hide('sp');
                return;
              } else {
                const dataType = response.type;
                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                downloadLink.setAttribute('download', 'reporte_provision.xls');
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
                this.spinner.hide('sp');
              }

            }, error => {

              this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      }
    });
  }
}
