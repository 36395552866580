import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';

import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {deprecate} from 'util';

const API_QUOTE = environment.api_quote;

@Injectable()
export class QuoteService {
  constructor(private http: HttpClient) { }

  public getGnpMarks() {
    return this.http.get(`${API_QUOTE}/gnp/catalogs/vehicle_marks`);
  }
  public getGnpVehicleModels(mark) {
    return this.http.get(`${API_QUOTE}/gnp/catalogs/vehicle_models?vehicleType=AUT&carMaker=${mark}`);
  }
  public getGnpVehicleTypes(mark) {
    return this.http.get(`${API_QUOTE}/gnp/catalogs/vehicles_bodywork?vehicleType=AUT&carMaker=${mark}`);
  }
  public getGnpVehicleVersions(mark, type) {
    return this.http.get(`${API_QUOTE}/gnp/catalogs/vehicles_version?vehicleType=AUT&carMaker=${mark}&bodywork=${type}`);
  }
  public getGnpVehicle(mark, type, version, model) {
    return this.http.get(`${API_QUOTE}/gnp/catalogs/vehicles?vehicleType=AUT&carMaker=${mark}&version=${version}&model=${model}&bodywork=${type}`);
  }

  public getQltMarks() {
    return this.http.get(`${API_QUOTE}/qualitas/catalogs/marks`);
  }
  public getQltVehicleTypes(mark, model) {
    return this.http.get(`${API_QUOTE}/qualitas/catalogs/rates?cMark=${mark}&model=${model}`);
  }
  public getQltVehicleVersions(mark, type, model) {
    return this.http.get(`${API_QUOTE}/qualitas/catalogs/rates?cMark=${mark}&cModel=${model}&cType=${type}`);
  }
  public getQltVehicle(mark, type, model, version) {
    return this.http.get(`${API_QUOTE}/qualitas/catalogs/rates?cMark=${mark}&cModel=${model}&cType=${type}&cVersion=${version}`);
  }





  public doGnpQuote(data) {
    return this.http.post(`${API_QUOTE}/gnp/quote`, data);
  }
  public doQltQuote(data) {
    return this.http.post(`${API_QUOTE}/qualitas/quote`, data);
  }

  public getQuoteById(quoteId, insurerId) {
    return this.http.get(`${API_QUOTE}/quote/${quoteId}?insurerId=${insurerId}`);
  }
  public getCoveragesByQuoteId(quoteId, insurerId) {
    return this.http.get(`${API_QUOTE}/quote/${quoteId}/coverages?insurerId=${insurerId}`);
  }
  public getEmissionById(emissionId, insurerId) {
    return this.http.get(`${API_QUOTE}/quote/emissions/${emissionId}?insurerId=${insurerId}`);
  }
  public getReceiptByEmissionId(emissionId, insurerId) {
    return this.http.get(`${API_QUOTE}/quote/emissions/${emissionId}/receipt?insurerId=${insurerId}`);
  }
  public getQuotesByUUID(uuid) {
    return this.http.get(`${API_QUOTE}/quote/uuid/${uuid}`);
  }

  public getQltQuotesSearch(search, page, size) {
    return this.http.post(`${API_QUOTE}/quote/?search=${search}&page=${page}&size=${size}`, '');
  }

  public doQltEmission(quoteId, data) {
    return this.http.post(`${API_QUOTE}/qualitas/emission/${quoteId}`, data);
  }
  public doGnpEmission(quoteId, data) {
    return this.http.post(`${API_QUOTE}/gnp/emission/${quoteId}`, data);
  }


  public getCheckPostcode(postcode) {
    return this.http.get(`${API_QUOTE}/quote/catalogs/postcode/${postcode}/check`);
  }
  public getStateByPostcode(postcode) {
    return this.http.get(`${API_QUOTE}/quote/catalogs/postcode/${postcode}/state`);
  }
  public getColoniesByPostCode(postcode) {
    return this.http.get(`${API_QUOTE}/quote/catalogs/postcode/${postcode}/colonies`);
  }
  public getTownByColonyCode(postcode, colonyCode) {
    return this.http.get(`${API_QUOTE}/quote/catalogs/postcode/${postcode}/colonies/${colonyCode}/town`);
  }

  public getCatPackages() {
    return this.http.get(`${API_QUOTE}/quote/catalogs/packages`);
  }
  public getCatGenders() {
    return this.http.get(`${API_QUOTE}/quote/catalogs/genders`);
  }

  public getInsuredData(insuredId) {
    return this.http.get(`${API_QUOTE}/quote/insured/${insuredId}`);
  }

  public downloadPolicyPdf(emissionId, insurerId) {
    return this.http.get(`${API_QUOTE}/quote/emissions/${emissionId}/pdf?insurerId=${insurerId}`, {responseType: 'arraybuffer'});
  }
  public downloadReceiptPdf(emissionId, insurerId) {
    return this.http.get(`${API_QUOTE}/quote/emissions/${emissionId}/receipt_pdf?insurerId=${insurerId}`, {responseType: 'arraybuffer'});
  }

  public validateInvoice(file) {
    return this.http.post(`${API_QUOTE}/quote/invoice/`, file);
  }

}
