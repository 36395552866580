import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest, HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import * as Forge from 'node-forge';
import {catchError, map, switchMap, tap, timeout} from 'rxjs/operators';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResponseOptionsInterceptor implements HttpInterceptor {


  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.url.includes('api/ammia')) {
            const data = event.body;
            if (data.mswSANXnWqh && Array.isArray(data.mswSANXnWqh)) {
              const plain = this.decrypt(data.mswSANXnWqh);
              const res = JSON.parse(plain);
              event = event.clone({ body: res });
            }
          }
        }
        return event;
      }),
      catchError(error => {
        const data = error.error;
        if (data.mswSANXnWqh) {
          const tmp = this.decrypt(data.mswSANXnWqh);
          const res = JSON.parse(tmp);
          error.error = res;
        }
        return throwError(error);
      })
    );
  }

  decrypt(value: any[]): any {
    let keyFinal = '-----BEGIN PRIVATE KEY-----';
    keyFinal = keyFinal + environment.decryption;
    keyFinal = keyFinal + '-----END PRIVATE KEY-----';

    const rsa = Forge.pki.privateKeyFromPem(keyFinal);
    let plain = '';
    for (const val of value) {
      const tmp = Forge.util.decodeUtf8(rsa.decrypt(Forge.util.hexToBytes(val)));
      plain += tmp;
    }

    return plain;
  }

}
