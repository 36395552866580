import {Component, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-payment-notifications',
  templateUrl: './payment-notifications.component.html',
  styleUrls: ['./payment-notifications.component.css']
})
export class PaymentNotificationsComponent implements OnInit {
  catGroups: any = [];
  dataToSendGeneral: any = {};
  paymentNotificationList: any = [];
  // -Varibales para paginado ->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  modalRef: BsModalRef;
  filters: any = [];
  daraConfiguration: any = {};
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService,
              private validateAccess: ValidateAccessService) { }

  ngOnInit() {
    this.invokeFunctionInitFilter();
    this.getGroups();
    this.validateAccess.validateAccessCore(65).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }

  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.catGroups = data;
    }, error => {
      // console.error(error.status);
    });
  }

  pageChanged(event: PageChangedEvent): void {
    // // // console.loglog(event);
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.api.getConfigurationNotificationsPagination(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.paymentNotificationList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.total ;
          this.paymentNotificationList = this.paginationResponse.list ;
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.paymentNotificationList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }
  invokeFunctionInitFilter() {
    this.filters = {
      groupId: 0,
      page: 1,
      size: 10
    };
  }
  invokeFunctionClearFiltersTable() {
    this.validateAccess.validateAccessCore(65).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.invokeFunctionInitFilter();
        this.getByPagination(1, 10);
      }
    });
  }

  invokeFunctionLoadFiltersTable() {
    this.validateAccess.validateAccessCore(65).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }

  invokeServiceConfirmDelete() {
    this.spinner.show('sp');
    this.api.saveConfigurationNotificationsPayment(this.daraConfiguration)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.getByPagination(1, 10);
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  invokeFunctionOnChangeNotification(event, template: TemplateRef<any>, item) {
    this.validateAccess.validateAccessCore(66).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
        this.getByPagination(1, 10);
      } else {
        this.daraConfiguration = item;
        this.modalRef = this.modalService.show(template, {class: 'modal-xs', keyboard: false, backdrop: 'static'});
      }
    });
  }
  invokeFunctionCloseModal() {
    this.modalRef.hide();
    this.getByPagination(1, 10);
  }
}
