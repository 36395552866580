import {Component, OnInit, TemplateRef} from '@angular/core';
import {DualListComponent} from 'angular-dual-listbox';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {PlacementDetail} from './placementDetail';
import {Placement} from './placement';
import {CompanySubranch} from './companySubranch';
import {CompanySubranchCategories} from './companySubranchCategories';
import {CoveragePlacement} from './coveragePlacement';
import {CoveragePlacementSelected} from './coveragePlacementSelected';
import {ActivatedRoute} from '@angular/router';
import {ValuesSelected} from './valuesSelected';
import {CoverageOptionPlacement} from './coverageOptionPlacement';
import {DatePipe} from '@angular/common';
import _, {isEqual} from 'lodash';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

declare var $: any;
@Component({
  selector: 'app-admin-placement-quotation-detail',
  templateUrl: './admin-placement-quotation-detail.component.html',
  styleUrls: ['./admin-placement-quotation-detail.component.css']
})
export class AdminPlacementQuotationDetailComponent implements OnInit {

  companyInformation: any = [];
  subbranchs: any = [];
  subbranchSelected: any = [];
  isFlipped = false;
  coverageList: any = [];
  endorsementListSelected: any = [];
  startDateTmp: string;
  endDateTmp: any;
  key: any;
  display: any;
  format = {
    add: 'ASIGNAR COBERTURA',
    remove: 'QUITAR COBERTURA',
    all: 'SELECCIONAR TODOS',
    none: 'DESELECCIONAR',
    direction: DualListComponent.LTR,
    draggable: true
  };
  modalRefCoverage: BsModalRef;
  modalRefCoverageStableshid: BsModalRef;
  modalRefAddFactor: BsModalRef;
  modalAddCoverageRef: BsModalRef;
  modalDeleteCategory: BsModalRef;
  modalDate: BsModalRef;
  selectedConceptTypeValidity: number;
  yearsValidity: number;
  placement: Placement;
  packages: [];
  placementDetailes: PlacementDetail[];
  coveragePlacements: CoveragePlacement[];
  endorsementPlacements: CoveragePlacement[];
  coveragePlacementsSelected: CoveragePlacementSelected[] = [];
  companySubranchCategoriesActual: CompanySubranchCategories[] = [];
  subranchActual: CompanySubranch;
  endorsementPlacementAssigned: CoveragePlacementSelected[] = [];
  valuetoAdd: ValuesSelected;
  valueFather: string;
  coveragePlacementFactor: CoveragePlacement;
  edorsementePlacementFactor: CoveragePlacementSelected;
  coveragePlacementSelectedToAddFactor: CoveragePlacementSelected[] = [];
  endorsementPlacementSelectedToAddFactor: CoveragePlacementSelected[] = [];
  companySubranchCategoryForFactor: CompanySubranchCategories;
  bsConfig: Partial<BsDatepickerConfig>;

  coverageToCopy: CoveragePlacementSelected[] = [];
  indexToDelete: number;
  idCategoryToDelete: number;
  companyId: number;
  constructor(private modalService: BsModalService,
              private api: ApiService,
              private toastr: ToastrService,
              private datePipe: DatePipe,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService) {

    this.activatedRoute.params.subscribe(params => {
      if (params.id === '0') {
        this.placement = new Placement(0, '', '', '', '', 0, this.placementDetailes);
        this.addCompanyInformation();
      } else {
        this.companyId = params.id;
        this.getCompanyInformation(params.id);
      }
    });

    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD',
      adaptivePosition: true
    });




  }

  ngOnInit() {

      this.key = 'idCoverage';
      this.display = 'nameCoverage';
      this.getAllSubranches();
  }

  addCompanyInformation() {
    this.placement.placementDetails.push(new PlacementDetail(0, '', '', '', 0));
  }

  deleteCompanyInformation() {
    this.placement.placementDetails.pop();
  }

  deleteLastFactoCoverage(index) {

  }

  deleteLastFactoEndorsement(index) {


  }


  getCoveragesBySubranchId(subranchId: number, companySubranchCategories: CompanySubranchCategories[]) {

        this.api.getCoveragesBySubranchId(subranchId)
            .then(
                (response: any) => {
                    this.spinner.hide('sp');
                    const coverages = response;
                    // Se agregan las coberturas
                    this.coveragePlacements = coverages.map(coverage => new CoveragePlacement(coverage.idCoverage, coverage.nameCoverage,
                      '',
                        coverage.isCoverageSpecial, coverage.cuantitative, coverage.optional, coverage.open, coverage.binary,
                        coverage.coverageOptionPlacements));
                  // Se agregan el array de las respuestas

                    for (const subranch of companySubranchCategories) {
                    if (subranch.coveragePlacementSelected.length === 0) {
                      for (const category of companySubranchCategories) {
                        // tslint:disable-next-line:no-shadowed-variable
                        for (const coverages of this.coveragePlacements) {
                          if (coverages.isOptional) {
                            category.coveragePlacementSelected.push(
                              new CoveragePlacementSelected(coverages.idCoverage, coverages.nameCoverage, '',
                                coverages.cuantitative, [new ValuesSelected('0', '0', false)]));
                          } else if (coverages.isBinary) {
                            const indice = category.coveragePlacementSelected.push(
                              new CoveragePlacementSelected(coverages.idCoverage, coverages.nameCoverage, '',
                                coverages.cuantitative, [new ValuesSelected('0', '', false)])) - 1;

                            // Se agrega las subcoberturas
                            for (const option of coverages.coverageOptionPlacements) {
                              if (option.subCoveragesPlacementId !== undefined && option.coveragesPlacementId !== 0) {

                                for (const subCoverage of option.coveragesPlacementList ) {
                                  if (subCoverage.isOptional) {
                                    category.coveragePlacementSelected[indice].coveragePlacementSelected.push(
                                      new CoveragePlacementSelected(subCoverage.idCoverage, subCoverage.nameCoverage, '',
                                        coverages.cuantitative, [new ValuesSelected('0', '0', false)]));
                                  } else {
                                    category.coveragePlacementSelected[indice].coveragePlacementSelected.push(
                                      new CoveragePlacementSelected(subCoverage.idCoverage, subCoverage.nameCoverage, '',
                                        coverages.cuantitative, [new ValuesSelected('' +
                                          '', '0', false)]));
                                  }

                                }

                              }
                            }

                          } else {
                            category.coveragePlacementSelected.push(
                              new CoveragePlacementSelected(coverages.idCoverage, coverages.nameCoverage, '',
                                coverages.cuantitative, [new ValuesSelected('', '0', false)]));
                          }
                        }
                      }
                    }
                  }


                }, error => {
                    if (error.status === 500) {
                        if (error.error.message !== undefined) {
                            this.toastr.warning(error.error.message, 'Notificación');
                        } else {
                            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                        }
                    } else {
                        this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                    }
                    console.error(error);
                    this.spinner.hide('sp');
                }
            );
    }

  getCoveragesByGroupId(groupId: number, companySubranchCategories: CompanySubranchCategories[]) {
    this.coveragePlacements = [];
    this.api.getCoveragesByGroupId(groupId)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          const coverages = response;
          // Se agregan las coberturas
          this.coveragePlacements = coverages.map(coverage => new CoveragePlacement(coverage.idCoverage, coverage.nameCoverage, '',
            coverage.isCoverageSpecial, coverage.cuantitative, coverage.optional, coverage.open, coverage.binary,
            coverage.coverageOptionPlacements));
          // Se agregan el array de las respuestas

          for (const subranch of companySubranchCategories) {
            if (subranch.coveragePlacementSelected.length === 0) {
              for (const category of companySubranchCategories) {
                category.groupId = groupId;
                for (const coverages of this.coveragePlacements) {
                  if (coverages.isOptional) {
                    category.coveragePlacementSelected.push(
                      new CoveragePlacementSelected(coverages.idCoverage, coverages.nameCoverage, '',
                        coverages.cuantitative, [new ValuesSelected('0', '0', false)]));
                  } else if (coverages.isBinary) {
                    const indice = category.coveragePlacementSelected.push(
                      new CoveragePlacementSelected(coverages.idCoverage, coverages.nameCoverage, '',
                        coverages.cuantitative, [new ValuesSelected('0', '', false)])) - 1;

                    // Se agrega las subcoberturas
                    for (const option of coverages.coverageOptionPlacements) {
                      if (option.subCoveragesPlacementId !== undefined && option.coveragesPlacementId !== 0) {

                        for (const subCoverage of option.coveragesPlacementList ) {
                          if (subCoverage.isOptional) {
                            category.coveragePlacementSelected[indice].coveragePlacementSelected.push(
                              new CoveragePlacementSelected(subCoverage.idCoverage, subCoverage.nameCoverage, '',
                                coverages.cuantitative, [new ValuesSelected('0', '0', false)]));
                          } else {
                            category.coveragePlacementSelected[indice].coveragePlacementSelected.push(
                              new CoveragePlacementSelected(subCoverage.idCoverage, subCoverage.nameCoverage, '',
                                coverages.cuantitative, [new ValuesSelected('' +
                                  '', '0', false)]));
                          }

                        }

                      }
                    }

                  } else {
                    category.coveragePlacementSelected.push(
                      new CoveragePlacementSelected(coverages.idCoverage, coverages.nameCoverage, '',
                        coverages.cuantitative, [new ValuesSelected('', '0', false)]));
                  }
                }
              }
            }
          }


        }, error => {
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
          console.error(error);
          this.spinner.hide('sp');
        }
      );
  }

  getEndorsementBySubranchId(subranchId: number) {

    this.api.getEndorsementBySubranchId(subranchId)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          const coverages = response;
          // Se agregan las coberturas
          this.endorsementPlacements = coverages.map(coverage => new CoveragePlacement(coverage.idCoverage, coverage.nameCoverage,
            coverage.descriptionCoverage, coverage.isCoverageSpecial, coverage.cuantitative, coverage.optional, coverage.open,
            coverage.binary));

          for (const category of this.companySubranchCategoriesActual) {
            if (category.endorsementPlacementSelected.length > 0) {
              for (const endorsement of category.endorsementPlacementSelected) {
                for (const endorsementlist of this.endorsementPlacements) {
                  if (endorsement.idCoverage === endorsementlist.idCoverage) {
                    if (!this.endorsementListSelected.some(item => isEqual(item, endorsementlist))) {
                      // Si no existe, agregarlo al array
                      this.endorsementListSelected.push(endorsementlist);
                    }
                    break;
                  }
                }
              }
            }
          }
        }, error => {
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
          console.error(error);
          this.spinner.hide('sp');
        }
      );

  }

  addCategoriesNumber(subranch, numberCategories: number) {
    let branchExist = false;

    for (const subranchPlacement of this.placement.companySubranch) {
      if (subranchPlacement.subranchId === subranch.subBranchId) {

        if (numberCategories < subranchPlacement.companySubranchCategories.length) {
          this.toastr.warning('Si deseas eliminar algun subgrupo, en cada una de las tarjetas tendrás la opción para hacerlo',
            'Notificación');
          return;
        } else {
          branchExist = true;
          const totalCategories = numberCategories - subranchPlacement.companySubranchCategories.length;
          subranchPlacement.companySubranchCategories.push(...this.createCategories(totalCategories));

          if (this.subranchActual.subranchId === subranch.subBranchId) {
            this.coveragePlacements = [];
            this.isFlipped = false;
            $('.tab-pane').removeClass('active in');
            $('#myTabs').removeClass('active in');
          }
          break;
        }
      }
    }

    if (!branchExist) {
      this.placement.companySubranch.push(new CompanySubranch(0, this.placement.idCompany, subranch.subBranchId, subranch.branchId,
        this.createCategories(numberCategories)));
    }
  }

  createCategories(numberCategories: number): CompanySubranchCategories[] {
      const companySubranchCategories: CompanySubranchCategories[] = [];
      for (let i = 0; i < numberCategories; i++) {
          companySubranchCategories.push(new CompanySubranchCategories(0, '', '', '', '',
              '', 0, 1, 0 ));
      }
      return companySubranchCategories;
  }


  activeTAb(subranchSelect, companySubranchCategories: CompanySubranchCategories[]) {
    this.coveragePlacements = [];
    this.endorsementListSelected = [];
    this.isFlipped = false;
    $('.tab-pane').removeClass('active in');
    $('#myTabs').removeClass('active in');
    $('#myTabs' + subranchSelect.subranchId).addClass('active in');
    $('#myTabs a[href="#' + subranchSelect.subranchId + '"]').tab('show');
    this.companySubranchCategoriesActual = companySubranchCategories;
    this.subranchActual = subranchSelect;

    if (subranchSelect.branchId !== 3) {
      this.getCoveragesBySubranchId(subranchSelect.subranchId, companySubranchCategories);
      this.getEndorsementBySubranchId(subranchSelect.subranchId);
    } else {

      this.getPackagesByBranchId(subranchSelect.branchId);

      if (this.subranchActual.idCompanySubranch !== undefined && this.subranchActual.idCompanySubranch > 0) {
        this.getCoveragesByGroupId(this.companySubranchCategoriesActual[0].groupId, this.companySubranchCategories);
      }

    }
  }


  rotateCard() {
    this.isFlipped = !this.isFlipped;
  }
  onDestinationChange(event) {
    console.log(event);
    console.log(this.endorsementListSelected);
  }

  onCloseCoveregeSelected() {

    for (const companySubranchCategory of this.companySubranchCategoriesActual) {
      companySubranchCategory.endorsementPlacementSelected = this.endorsementPlacementAssigned
          .map(detail =>
          new CoveragePlacementSelected(
              detail.idCoverage,
              detail.nameCoverage,
              detail.descriptionCoverage,
              detail.cuantitative,
              detail.valueSelected,
              detail.coveragePlacementSelected));
    }

    this.modalRefCoverageStableshid.hide();
  }


  openModal(template: TemplateRef<any>) {
    // this.getCoverages();
    this.modalRefCoverage = this.modalService
      .show(template, {class: 'modal-lg',  keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }

  closeModalRefCoverage(template: TemplateRef<any>) {

    let existACuantitative = false;
        // tslint:disable-next-line:no-shadowed-variable
    for (const coverages of this.endorsementListSelected) {

      if (coverages.cuantitative) {
        existACuantitative = true;
        const indice = this.endorsementPlacementAssigned.push(
          new CoveragePlacementSelected(coverages.idCoverage, coverages.nameCoverage, coverages.description,
            coverages.cuantitative, [new ValuesSelected('false', '', true)])) - 1;

        this.endorsementPlacementAssigned[indice].coveragePlacementSelected.push(
          new CoveragePlacementSelected(1, 'SUMA ASEGURADA', '',
            true, [new ValuesSelected('0', '0', false)]));

        this.endorsementPlacementAssigned[indice].coveragePlacementSelected.push(
          new CoveragePlacementSelected(2, 'DEDUCIBLE', '',
            true, [new ValuesSelected('0', '0', false)]));

        this.endorsementPlacementAssigned[indice].coveragePlacementSelected.push(
          new CoveragePlacementSelected(3, 'COASEGURO', '',
            true, [new ValuesSelected('0', '0', false)]));

        this.endorsementPlacementAssigned[indice].coveragePlacementSelected.push(
          new CoveragePlacementSelected(4, 'PERIODO DE ESPERA', '',
            true, [new ValuesSelected('0', '0', false)]));

      } else {
        this.endorsementPlacementAssigned.push(
          new CoveragePlacementSelected(coverages.idCoverage, coverages.nameCoverage, coverages.description,
            false, [new ValuesSelected('false', '0', false)]));
      }
    }

    if (existACuantitative) {
      this.modalRefCoverageStableshid = this.modalService
        .show(template, {class: 'modal-xs',  keyboard: false, ignoreBackdropClick: true, backdrop: false});
    } else {
      this.endorsementPlacementAssigned.forEach((endorsementPlacementAssigned) => {

        for (const companySubranchCategory of this.companySubranchCategoriesActual) {
          // Verificar si el elemento2 no está en lista1
          if (!companySubranchCategory.endorsementPlacementSelected.some((endorsementPlacementSelected) =>
            endorsementPlacementSelected.idCoverage === endorsementPlacementAssigned.idCoverage)) {
            // Agregar el elemento2 a lista1
            companySubranchCategory.endorsementPlacementSelected.push(_.cloneDeep(endorsementPlacementAssigned));
          }
        }
      });
      this.endorsementPlacementAssigned = [];
    }

    this.modalRefCoverage.hide();
  }

  onChangeNoAmparado(event, coverage) {
    console.log(event);
    console.log(coverage);
    // coverage.noamparado = event.target.checked;
  }

  onChangeExcess(event, category) {
    category.haveExcess = event.target.checked;
  }

  copiarObjeto(original: any): any {
    // return { ...original };
      const copyNode = JSON.stringify(original);
      return JSON.parse(copyNode);
  }

  openModalLoadFactor(template: TemplateRef<any>, category: CompanySubranchCategories, coveragePlacement?: CoveragePlacement,
                      coveragePlacementSelected?: CoveragePlacementSelected) {
    this.modalRefAddFactor = this.modalService
      .show(template, {class: 'modal-xs',  keyboard: false, ignoreBackdropClick: true, backdrop: false});
    this.companySubranchCategoryForFactor = category;
    // this.edorsementePlacementFactor = coveragePlacementSelected;

    if (!this.isFlipped) {
      this.coveragePlacementFactor = coveragePlacement;
      this.coveragePlacementSelectedToAddFactor = category.coveragePlacementFactorsSelected;
      if (coveragePlacement.isOptional) {
        this.coveragePlacementSelectedToAddFactor.push(
          new CoveragePlacementSelected(coveragePlacement.idCoverage, coveragePlacement.nameCoverage, '',
            coveragePlacement.cuantitative, [new ValuesSelected('0', '0', false)]));
      } else if (coveragePlacement.isBinary) {
        const indice = this.coveragePlacementSelectedToAddFactor.push(
          new CoveragePlacementSelected(coveragePlacement.idCoverage, coveragePlacement.nameCoverage, '',
            coveragePlacement.cuantitative, [new ValuesSelected('0', '', false)])) - 1;

        // Se agrega las subcoberturas
        for (const option of coveragePlacement.coverageOptionPlacements) {
          if (option.subCoveragesPlacementId !== undefined && option.coveragesPlacementId !== 0) {

            for (const subCoverage of option.coveragesPlacementList ) {
              if (subCoverage.isOptional) {
                this.coveragePlacementSelectedToAddFactor[indice].coveragePlacementSelected.push(
                  new CoveragePlacementSelected(subCoverage.idCoverage, subCoverage.nameCoverage, '',
                    coveragePlacement.cuantitative, [new ValuesSelected('0', '0', false)]));
              } else {
                this.coveragePlacementSelectedToAddFactor[indice].coveragePlacementSelected.push(
                  new CoveragePlacementSelected(subCoverage.idCoverage, subCoverage.nameCoverage, '',
                    coveragePlacement.cuantitative, [new ValuesSelected('' +
                      '', '0', false)]));
              }
            }
          }
        }
      } else {

        this.coveragePlacementSelectedToAddFactor.push(
          new CoveragePlacementSelected(coveragePlacement.idCoverage, coveragePlacement.nameCoverage, '',
            coveragePlacement.cuantitative, [new ValuesSelected('', '0', false)]));
      }
    } else {
      this.edorsementePlacementFactor = coveragePlacementSelected;
      this.endorsementPlacementSelectedToAddFactor = category.endorsementPlacementFactorsSelected;
      if (coveragePlacementSelected.cuantitative) {
        const indice = this.endorsementPlacementSelectedToAddFactor.push(
          new CoveragePlacementSelected(coveragePlacementSelected.idCoverage, coveragePlacementSelected.nameCoverage,
            coveragePlacementSelected.descriptionCoverage,
            coveragePlacementSelected.cuantitative, [new ValuesSelected('false', '', true)])) - 1;

        this.endorsementPlacementAssigned[indice].coveragePlacementSelected.push(
          new CoveragePlacementSelected(1, 'SUMA ASEGURADA', '',
            true, [new ValuesSelected('0', '0', false)]));

        this.endorsementPlacementAssigned[indice].coveragePlacementSelected.push(
          new CoveragePlacementSelected(2, 'DEDUCIBLE', '',
            true, [new ValuesSelected('0', '0', false)]));

        this.endorsementPlacementAssigned[indice].coveragePlacementSelected.push(
          new CoveragePlacementSelected(3, 'COASEGURO', '',
            true, [new ValuesSelected('0', '0', false)]));

        this.endorsementPlacementAssigned[indice].coveragePlacementSelected.push(
          new CoveragePlacementSelected(4, 'PERIODO DE ESPERA', '',
            true, [new ValuesSelected('0', '0', false)]));

      } else {
        this.endorsementPlacementSelectedToAddFactor.push(
          new CoveragePlacementSelected(coveragePlacementSelected.idCoverage, coveragePlacementSelected.nameCoverage,
            coveragePlacementSelected.descriptionCoverage,
            false, [new ValuesSelected('false', '0', false)]));
      }

    }

  }

  containsData(category: CompanySubranchCategories, coveragePlacementSelected: CoveragePlacementSelected): boolean {

    if (!this.isFlipped) {
      return category.coveragePlacementFactorsSelected.some(objeto => objeto.idCoverage === coveragePlacementSelected.idCoverage);

    } else {
      return category.endorsementPlacementFactorsSelected.some(objeto => objeto.idCoverage === coveragePlacementSelected.idCoverage);
    }
  }

  closeModalLoadFactor() {
    this.modalRefAddFactor.hide();
  }

  saveDataCompany() {
    this.spinner.show('sp');
    this.api.saveDataCompany(this.placement.toPlainObject())
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          const placementBack = response;
          this.placement = new Placement(placementBack.idCompany, placementBack.nameCompany, placementBack.versionNumber,
            placementBack.startDate, placementBack.endDate, placementBack.placementStatusId, placementBack.placementDetails);

          this.getAllSubranches();
        }, error => {

          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
          console.error(error);
          this.spinner.hide('sp');
        }
      );
  }

    saveSubranchCategory() {
        this.spinner.show('sp');
        this.api.saveCategorySubranchCategories(this.placement.companySubranch.map(detail => detail.toPlainObject()))
            .then(
                (response: any) => {
                  const subranches = response;
                  if (this.companyId === undefined || this.companyId === 0) {
                    this.companyId = subranches[0].companyId;
                  }
                  this.getCompanyInformation(subranches[0].companyId);
                  this.spinner.hide('sp');
                }, error => {

                    if (error.status === 500) {
                        if (error.error.message !== undefined) {
                            this.toastr.warning(error.error.message, 'Notificación');
                        } else {
                            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                        }
                    } else {
                        this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                    }
                    console.error(error);
                    this.spinner.hide('sp');
                }
            );
    }

  getAllSubranches() {
    this.spinner.show('sp');
    this.api.getSubBranchesPlacement()
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.subbranchs = response;
        }, error => {
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
          console.error(error);
          this.spinner.hide('sp');
        }
      );
  }

    getCompanyInformation(idCompany) {
        this.spinner.show('sp');
        this.api.getCompanyInformtion(idCompany)
            .then(
                (response: any) => {
                    this.spinner.hide('sp');
                    const placementBack = response;

                    this.placement = new Placement(placementBack.idCompany, placementBack.nameCompany, placementBack.versionNumber,
                      placementBack.startDate, placementBack.endDate, placementBack.placementStatusId,
                      placementBack.placementDetails, placementBack.companySubranch);

                }, error => {

                    if (error.status === 500) {
                        if (error.error.message !== undefined) {
                            this.toastr.warning(error.error.message, 'Notificación');
                        } else {
                            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                        }
                    } else {
                        this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                    }
                    console.error(error);
                    this.spinner.hide('sp');
                }
            );
    }

    formateDate(date: string): string {
      const fechaOriginal = new Date(date);

      const dia = fechaOriginal.getDate().toString().padStart(2, '0');
      const mes = (fechaOriginal.getMonth() + 1).toString().padStart(2, '0');
      const año = fechaOriginal.getFullYear();

      return `${dia}-${mes}-${año}`;
    }

    getNameSubranch(idSubranch): string {
      const subbranch = this.subbranchs.find(subranch => subranch ? subranch.subBranchId === idSubranch : false);
      return subbranch ? subbranch.subBranch : '';
    }

  getTotalOfCategories(idSubranch): string {
    const categories = this.placement.companySubranch.find(placement => placement ? placement.subranchId === idSubranch : false);
    return categories ? categories.companySubranchCategories.length.toString() : '0';
  }

  getPackagesByBranchId(branchId) {
    this.spinner.show('sp');
    this.api.getPackagesByBranchId(branchId)
      .then(
        (response: any) => {
          this.spinner.hide('sp');

          this.packages = response;

        }, error => {

          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
          console.error(error);
          this.spinner.hide('sp');
        }
      );
  }

  typeaheadOnSelectCoverage(event: CoverageOptionPlacement, template: TemplateRef<any>, e, ngmodelo) {
    if (ngmodelo.value === '-1') {
      this.valuetoAdd = ngmodelo;
      console.log(event);

      if (event.orderLevel.includes('.')) {
        this.valueFather  = event.group;
      }

      this.modalAddCoverageRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false, focus: false, backdrop: false,
        ignoreBackdropClick: true, animated: true});
    }
  }

  closeModal() {
    this.valuetoAdd = undefined;
    this.valueFather = '';
    this.modalAddCoverageRef.hide();
  }
  closeModalDeleteCategory() {
    this.idCategoryToDelete = undefined;
    this.indexToDelete = undefined;
    this.modalDeleteCategory.hide();
  }
  addNewValue() {
    const option = document.getElementById('valueCoverage') as HTMLInputElement | null;
    this.valuetoAdd.value = this.valueFather + ' ' + option.value;

    this.valuetoAdd = undefined;
    this.valueFather = '';

    this.modalAddCoverageRef.hide();
  }

  get companySubranchCategories(): CompanySubranchCategories[] {
    return this.subranchActual.companySubranchCategories;
  }

  copyValue(coveragePlacementSelected: CoveragePlacementSelected[]) {
    this.coverageToCopy = coveragePlacementSelected.map(coverages => new CoveragePlacementSelected(
      coverages.idCoverage,
      coverages.nameCoverage,
      coverages.descriptionCoverage,
      coverages.cuantitative,
      coverages.valueSelected,
      coverages.coveragePlacementSelected));
  }

  pasteValue(index: number) {
    this.subranchActual.companySubranchCategories[index].coveragePlacementSelected =
      this.coverageToCopy.map(coverages => new CoveragePlacementSelected(
      coverages.idCoverage,
      coverages.nameCoverage,
      coverages.descriptionCoverage,
      coverages.cuantitative,
      coverages.valueSelected,
      coverages.coveragePlacementSelected));

  }

  confirmDeleteCategory(index: number, idCategory: number, template: TemplateRef<any>) {
    this.modalAddCoverageRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false, focus: false, backdrop: false,
      ignoreBackdropClick: true, animated: true});
  }

  deleteCategory(indexToDelete: number, idCategory: number) {
    this.spinner.show('sp');
    if (idCategory === 0) {
      this.subranchActual.companySubranchCategories.splice(indexToDelete, 1);
      this.toastr.success('La categoría ha sido eliminada', 'Notificación');
    } else {
      this.api.deleteCategory(idCategory)
        .then(
          (response: any) => {
            this.spinner.hide('sp');
            this.subranchActual.companySubranchCategories.splice(this.indexToDelete, 1);
            this.toastr.success('La categoría ha sido eliminada', 'Notificación');

          }, error => {

            if (error.status === 500) {
              if (error.error.message !== undefined) {
                this.toastr.warning(error.error.message, 'Notificación');
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
            console.error(error);
            this.spinner.hide('sp');
          }
        );
    }
  }

  public openModalAddExpiration(startDate: Date[], template: TemplateRef<any>) {
    this.selectedConceptTypeValidity = 1;
    this.yearsValidity = 2;
    if (startDate !== null) {
        this.startDateTmp = this.datePipe.transform(startDate, 'yyyy-MM-dd', 'es-ES');
        this.modalDate = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
    }
  }

  validateNumberYears() {
    if (this.yearsValidity < 0) {
      this.yearsValidity = 2;
      this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER NEGATIVO, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
      return;
    }
    if (this.yearsValidity === 1) {
      this.toastr.warning('EL NÚMERO MÍNIMO PERMITIDO PARA LA OPCIÓN MULTI-ANUAL, ES 2', 'NOTIFICACIÓN');
      this.yearsValidity = 2;
      return;
    }
    if (this.yearsValidity === 0) {
      this.yearsValidity = 2;
      this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER 0, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
      return;
    }
  }

  public selectedTypeValidity() {
    switch (this.selectedConceptTypeValidity) {
      case 1:
        this.endDateTmp = new Date(this.startDateTmp);
        // tslint:disable-next-line:max-line-length
        this.endDateTmp = this.endDateTmp.setFullYear(this.endDateTmp.getFullYear() + 1, this.endDateTmp.getMonth(), this.endDateTmp.getDate() + 1);
        this.placement.endDate = this.datePipe.transform(this.endDateTmp, 'yyyy-MM-dd', 'es-ES');
        break;
      case 2:
        if (this.yearsValidity === 0 || this.yearsValidity === undefined) {
          this.toastr.warning('DEBE DE INGRESAR EL NUMERO DE AÑOS DE VIGENCIA DE LA PÓLIZA', 'NOTIFICACIÓN');
          return;
        }
        if (this.yearsValidity < 0) {
          this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER NEGATIVO, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
          return;
        }
        if (this.yearsValidity < 1) {
          this.toastr.warning('EL MÍNIMO PERMITIDO PARA MULTI-ANUAL ES 2', 'NOTIFICACIÓN');
          return;
        }
        this.endDateTmp = new Date(this.startDateTmp);
        // tslint:disable-next-line:max-line-length
        this.endDateTmp = this.endDateTmp.setFullYear(this.endDateTmp.getFullYear() + this.yearsValidity, this.endDateTmp.getMonth(), this.endDateTmp.getDate() + 1);
        this.placement.endDate = this.datePipe.transform(this.endDateTmp, 'yyyy-MM-dd', 'es-ES');
        break;
      case 3:
        this.placement.endDate = '';
        break;
    }
    this.modalDate.hide();
  }

  chageValueto0(event, coveragePlacementSelected: CoveragePlacementSelected) {
    console.log(event);
    if (!event) {
      coveragePlacementSelected.coveragePlacementSelected.forEach(coveragePlacement => {
        coveragePlacement.valueSelected[0].value = '0';
        coveragePlacement.valueSelected[0].meditionType = '0';
      });
    }
  }

  downloadFilePlacementCoverages() {
    this.api.downloadReportPlacementCoverages(this.companyId).then(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download',  'SurexsSlip.xlsx');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      }, (error) => {
      });
  }

  stablishBaseVersion() {
    this.spinner.show('sp');
    this.api.stablishBaseVersion(this.companyId).then (
      (response: any) => {
        this.spinner.hide('sp');
        this.toastr.success('Se ha generado la base correctamente', 'Notificación');
      }, error => {
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
        console.error(error);
        this.spinner.hide('sp');
      });
  }

  changeMonedatype(companySubranchCategories: CompanySubranchCategories) {
    if (companySubranchCategories.monedaType === 2 || companySubranchCategories.monedaType === Number('2')) {
      companySubranchCategories.coveragePlacementSelected.forEach(coverage => {
        if (coverage.coveragePlacementSelected.length === 0) {
          coverage.valueSelected[0].meditionType = '0';
        } else {
          coverage.coveragePlacementSelected.forEach(subcoverage => {
            subcoverage.valueSelected[0].meditionType = '0';
          });
        }
      });

      companySubranchCategories.endorsementPlacementSelected.forEach(endorsement => {
        if (endorsement.coveragePlacementSelected.length === 0) {
          endorsement.valueSelected[0].meditionType = '0';
        } else {
          endorsement.coveragePlacementSelected.forEach(subcenrosement => {
            subcenrosement.valueSelected[0].meditionType = '0';
          });
        }
      });
    }
  }
}

