import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label, MultiDataSet} from 'ng2-charts';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-graph-user-status',
  templateUrl: './graph-user-status.component.html',
  styleUrls: ['./graph-user-status.component.css']
})
export class GraphUserStatusComponent implements OnInit {

  doughnutChartOptions: ChartOptions = {
    responsive: true
  };
  doughnutChartLabels: Label[] = [];
  doughnutChartType: ChartType = 'doughnut';
  doughnutChartLegend  = true;
  doughnutChartPlugins  = [];
  doughnutChartData: ChartDataSets[] = [];
  doughnutChartColors = [
    {
      backgroundColor: [
        'rgba(68, 136, 166, 1)',
        'rgba(108, 152, 159, 1)',
        'rgba(181, 200, 207, 1)',
        'rgba(50, 97, 115, 1)',
        'rgba(1, 24, 38, 1)',
        'rgba(153, 153, 153, 1)',
        'rgba(91, 105, 142, 1)',
        'rgba(166, 166, 166, 1)',
        'rgba(126, 145, 196, 1)',
        'rgba(230, 230, 230, 1)',
        'rgba(140, 161, 219, 1)',
        'rgba(54, 54, 54, 1)']
    }
  ];

  private _filters: any = {};
  reportList: any = [];

  constructor(private api: ApiService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {


  }

  loadChart() {
    this.spinner.show('sp');
    this.api.getusers(this._filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.reportList = [];
            this.spinner.hide('sp');
            return;
          }
          this.reportList = response;
          this.creatChart();
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.reportList = [];
          this.spinner.hide('sp');
        }
      );
  }

  creatChart() {
    const data = {
      data: []
    };
    // Obtenemos las etiquetas
    this.reportList.forEach(X => {
      this.doughnutChartLabels.push(X.title);
      data.data.push(X.value);
    });

    this.doughnutChartData.push(data);
  }

  @Input()
  set filters(value: any) {
    this._filters = value;
    if (this._filters.dateStart === null) {
      const fecha = new Date();
      const actualYear = fecha.getFullYear();
      const dateStart = actualYear.toString().concat('-01-01');
      const dateEnd = actualYear.toString().concat('-12-31');

      this._filters.dateStart = dateStart;
      this._filters.dateEnd = dateEnd;
    }
    this.doughnutChartData = [];
    this.doughnutChartLabels = [];
    this.loadChart();
  }

}
