import {CoverageOptionPlacement} from './coverageOptionPlacement';
import {number, string} from '@amcharts/amcharts4/core';

export class CoveragePlacement {
  private _idCoverage: number;
  private _nameCoverage: string;
  private _description: string;
  private _isCoverageSpecial: boolean;
  private _cuantitative: boolean;
  private _isOptional: boolean;
  private _isOpen: boolean;
  private _isBinary: boolean;
  private _coverageOptionPlacements: CoverageOptionPlacement[];

  constructor(
    idCoverage: number,
    nameCoverage: string,
    description: string,
    isCoverageSpecial: boolean,
    cuantitative: boolean,
    isOptional: boolean,
    isOpen: boolean,
    isBinary: boolean,
    coverageOptionPlacements: CoverageOptionPlacement[] = []
  ) {
    this._idCoverage = idCoverage;
    this._nameCoverage = nameCoverage;
    this._description = description;
    this._isCoverageSpecial = isCoverageSpecial;
    this._cuantitative = cuantitative;
    this._isOptional = isOptional;
    this._isOpen = isOpen;
    this._isBinary = isBinary;

      // Mapea placementDetails usando el constructor de PlacementDetail
    this._coverageOptionPlacements = coverageOptionPlacements.map(detail => new CoverageOptionPlacement(
        detail.idCoveragesOptionPlacement,
        detail.value,
        detail.description,
        detail.group,
        detail.meditionType,
        detail.orderLevel,
        detail.isOpen,
        detail.coveragesPlacementId,
        detail.subCoveragesPlacementId,
        detail.coveragesPlacementList
    ));
  }

  // Getter y Setter para _idCoverage
  get idCoverage(): number {
    return this._idCoverage;
  }

  set idCoverage(value: number) {
    this._idCoverage = value;
  }

  // Getter y Setter para _nameCoverage
  get nameCoverage(): string {
    return this._nameCoverage;
  }

  set nameCoverage(value: string) {
    this._nameCoverage = value;
  }

  // Getter y Setter para _isCoverageSpecial
  get isCoverageSpecial(): boolean {
    return this._isCoverageSpecial;
  }

  set isCoverageSpecial(value: boolean) {
    this._isCoverageSpecial = value;
  }

  // Getter y Setter para _isOptional
  get isOptional(): boolean {
    return this._isOptional;
  }

  set isOptional(value: boolean) {
    this._isOptional = value;
  }

  // Getter y Setter para _isOpen
  get isOpen(): boolean {
    return this._isOpen;
  }

  set isOpen(value: boolean) {
    this._isOpen = value;
  }

  // Getter y Setter para _isBinary
  get isBinary(): boolean {
    return this._isBinary;
  }

  set isBinary(value: boolean) {
    this._isBinary = value;
  }

  // Getter y Setter para _coverageOptionPlacements
  get coverageOptionPlacements(): CoverageOptionPlacement[] {
    return this._coverageOptionPlacements;
  }

  set coverageOptionPlacements(value: CoverageOptionPlacement[]) {
    this._coverageOptionPlacements = value;
  }

  get cuantitative(): boolean {
    return this._cuantitative;
  }

  set cuantitative(value: boolean) {
    this._cuantitative = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  toPlainObject(): any {
    return {
      idCoverage: this._idCoverage,
      nameCoverage: this._nameCoverage,
      description: this._description,
      isCoverageSpecial: this._isCoverageSpecial,
      cuantitative: this._cuantitative,
      isOptional: this._isOptional,
      isOpen: this._isOpen,
      isBinary: this._isBinary,
      coverageOptionPlacements: this._coverageOptionPlacements.map(optionPlacement => optionPlacement.toPlainObject())
    };
  }
}
