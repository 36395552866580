import { Component, OnInit } from '@angular/core';
import {ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-incident-chart',
  templateUrl: './incident-chart.component.html',
  styleUrls: ['./incident-chart.component.css']
})
export class IncidentChartComponent implements OnInit {

  constructor() { }

  lineChartData: ChartDataSets[] = [
    { data: [85, 72, 78, 75, 77, 75],
      fill: false,
      label: 'Total de siniestros por mes reportados' },
  ];

  lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June'];

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: '#326173',
      backgroundColor: 'rgba(41, 63, 121, 0.05)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';

  ngOnInit() {
  }

}
