import { Component, OnInit ,TemplateRef, Input} from '@angular/core';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-view-policies-damages',
  templateUrl: './view-policies-damages.component.html',
  styleUrls: ['./view-policies-damages.component.css']
})
export class ViewPoliciesDamagesComponent implements OnInit {
   modalRef: BsModalRef;
   @Input() numberPolicy: string;
   @Input() coverageList;
   @Input()   catTypesMoneda;
   listLocations:any=[];
   coveragesListContent:any=[];
   coveragesIds:any=[];
   typeCovegareId:number;
   genericJson:any={};
   nameSeccion:string;

  constructor(
   private api: ApiService,
   private toastr: ToastrService,
   private modalService: BsModalService,
   ) { }

  ngOnInit() {
    // console.log('thisngOnInit');
    // console.log(this.numberPolicy);
    // this.invokeServiceForGetLocationsByPolicy({"policyId":this.numberPolicy}); se comenta para lo de daños pendiente por revisar
    // console.log("catTypesMoneda");
    // console.log(this.catTypesMoneda);
   //inicializar json generico
   this.genericJson={
      locationId:null
      ,numberPolicy:null
      ,genericCivilLiability:{
        sumAssured:null
        ,deducible:{
          percentaje:null,
          deducibleType:null,
          minMax:null,
          typeMoney:null
        }
      }
    };

  }
  openModalViewCoverage(template: TemplateRef<any>,data){
   this.modalRef = this.modalService.show(template);

    // console.log('data.id');
    // console.log(data.locationId);
    this.invokeServiceForGetJsonCoeragesByLocationId({"locationId":data.locationId});

  }
  openModalEditCoverage(templateEditCoverage,i){
    // console.log(i);
    this.typeCovegareId=i.id;
    this.nameSeccion=i.description;
    switch(this.typeCovegareId){
      case 42:
        this.genericJson=JSON.parse(this.coveragesListContent[0].coverageValueJson);
        // console.log("this.generi");
        // console.log(this.genericJson);
        // console.log("this.genericJson[genericCivilLiability]");
        // console.log(this.genericJson[0].genericCivilLiability.deducible.deducibleType);
      break;
      default: break;
    }
    this.modalRef = this.modalService.show(templateEditCoverage);
  }
  public invokeServiceForGetLocationsByPolicy(data) {
      this.api.getLocationByPolicy(data).then((data: any) => {
      if (data != null) {
      	//this.policyAttachmentList = data;
      	// console.log('data getLocationByPolicy');
      	// console.log(data);
        this.listLocations=data;
        }
      }, error => {
        // console.error(error.status);
        // console.error(error.statusText);
        // console.error(error.message);
      });



    }
  public invokeServiceForGetJsonCoeragesByLocationId(data) {
      this.api.getJsonCoeragesByLocationId(data).then((data: any) => {
      if (data != null) {
          this.coveragesListContent=data;
          for(let data in this.coverageList){
            for(let data2 in this.coveragesListContent){
              if(this.coverageList[data].id===this.coveragesListContent[data2].typeCoverage){
                this.coveragesIds.push(this.coverageList[data]);
                break;
              }
            }
          }

         }
       }, error => {
         // console.error(error.status);
         // console.error(error.statusText);
         // console.error(error.message);
       });



  }



}
