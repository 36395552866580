import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TabDirective, TabsetComponent} from 'ngx-bootstrap/tabs';
import {Time} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';


@Component({
  selector: 'app-request-mdl',
  templateUrl: './request-mdl.component.html',
  styleUrls: ['./request-mdl.component.css']
})
export class RequestMdlComponent implements OnInit {
  modalRef: BsModalRef;
  modalRef1: BsModalRef;
  modalRd: BsModalRef;
  dataToSendAttachmentReceipt: any = {};
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['*'];
  receiptAttachmentList: any = [];
  public fileUploadAttachment: File;
  dataRequestGeneral: any = {};
  dataRequestMessages: any = [];
  dataMessage: any = {};
  dataMessageLog: any = {};
  lclSectionSelected: number;
  dataRequestAuthor: any = [];
  dataRequestEvents: any = [];
  dataRequestMessageFilter: any = {};
  // dropdowns
  authorSelectedItems: any = [];
  dropdownSettingsAuthor: any = {};
  typesSelectedItems: any = [];
  dropdownSettingsTypes: any = {};
  requestMessagesList: any = [];
  // table
  filters: any = {};
  // -Varibales para paginado de recibos->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 5; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  requestId: number;
  dataMessageDetail: any = {};
  id: NodeJS.Timer;
  countMessageChat: number;
  // variables siniestro
  dataIncident: any = {};
  myFormIncident: FormGroup;
  groupSelected: number;
  clientSelected: number;
  insuredIncidentList: any = [];
  keywordIncident = 'name';
  policySelected: number;
  policyInsuredList: any = [];
  typeIncidentSelected: number;
  typeIncidentList: any = [];
  @ViewChild('user', {static: true}) user;
  kinshipIncidentList: any = [];
  kinshipSelected: number;
  typeRequestList: any = [];
  typeDocumentationSelected: number;
  isIncident: boolean;
  incidentSelected: any = {};
  incidentList: any = [];
  catalogForm = this.fb.group({
    catalogs: ['groups', [Validators.required]]
  });
  flag: number;
  selectionIncident: boolean;
  phaseList: any [];
  phaseSelectedItems: any = [];
  dropdownSettingsPhase = {};
  clientList: any = [];
  groupList: any = [];
  statusList: any = [];
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  groupsSelectedItems: any = [];
  dropdownSettingsGroups = {};
  statusSelectedItems: any = [];
  dropdownSettingsStatus = {};
  constructor(private modalService: BsModalService,
              private toastr: ToastrService,
              private shared: SharedService,
              private api: ApiService,
              private spinner: NgxSpinnerService,
              public fb: FormBuilder,
              private router: Router) {
    this.myFormIncident = this.fb.group({
      group: ['', [Validators.required]],
      subGroup: ['', [Validators.required]],
      insured: ['', [Validators.required]],
      incidentOld: [''],
      policy: ['', [Validators.required]],
      nameIncident: [''],
      typeIncident: ['', [Validators.required]],
      typeRequest: ['', [Validators.required]],
      numberContact: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
        Validators.maxLength(10)]],
      folioSrx: ['', {disabled: true}],
      folioInsurance: [''],
      description: ['', [Validators.required]],
      medicalCondition: ['', [Validators.required]],
      kinship: [''],
    });
  }

  ngOnInit() {
    this.loadFilters();
    this.messageClear();
    this.messageLogClear();
    this.dropdownSettingsAuthor = {
      singleSelection: false,
      idField: 'userId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON AUTHORES',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsTypes = {
      singleSelection: false,
      idField: 'typeRequestId',
      textField: 'description',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EVENTOS',
      searchPlaceholderText: 'BUSCAR'
    };
  }
  openModal(template: TemplateRef<any>) {
    setTimeout( () => {
      this.requestId = Number(localStorage.getItem('request-id'));
      this.getRequestDetail();
      this.messageClear();
      this.messageLogClear();
    }, 1000);
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }
  getRequestDetail() {
    this.spinner.show('sp');
    this.api.getDetailRequest(this.requestId).then((data: any) => {
      this.dataRequestGeneral = data;
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  ngOnDestroyInterval() {
    if (this.id) {
      clearInterval(this.id);
    }
  }
  getRequestMessagesAttention(type, requestId, timeout) {
    setTimeout( () => {
      this.api.getRequestMessagesAttention(type , requestId).then((data: any) => {
        this.dataRequestMessages = data;
        if (this.countMessageChat !== this.dataRequestMessages.length) {
          this.spinner.show('sp');
          this.countMessageChat = this.dataRequestMessages.length;
          setTimeout( () => {
            this.loadScrool();
            this.spinner.hide('sp');
          }, 300);
        }
      }, error => {
        // console.error(error.status);
        // console.error(error.statusText);
        // console.error(error.message);
      });
    }, timeout);
  }
  loadScrool() {
    var objDiv = document.getElementById('chat');
    objDiv.scrollTop = objDiv.scrollHeight;
  }
  downloadFile(m) {
    // console.log(m);
    this.api.getAttachmentRequestById(m.id)
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', m.attachmentName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          // console.error(error);
        }
      );
  }
  sendMessage() {
    this.spinner.show('sp');
    this.dataMessage.requestId = this.requestId;
    this.dataMessage.userId = localStorage.getItem('user-id');
    this.dataMessage.typeEvent = 1;
    this.dataMessage.userType = 2;
    // console.log(this.dataMessage);
    if (this.dataMessage.message === '') {
      this.toastr.warning('POR FAVOR INGRESE UN MENSAJE A ENVIAR', 'NOTIFICACIÓN');
      this.spinner.hide('sp');
      return;
    }
    this.api.postSendMessageRequest(this.dataMessage)
      .then(
        (response: any) => {
          this.messageClear();
          this.spinner.hide('sp');
          (document.getElementById('fileChat') as HTMLInputElement).value = null;
          this.getRequestMessagesAttention(1, this.requestId, 500);
          this.api.sendMessageFromCoreRequest(response).then((data: any) => {
            if (data == null) {
              console.log(data);
            }
            this.incidentList = data;
          }, error => {
          });
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  messageClear() {
    this.dataMessage = {
      id: 0,
      requestId: 0,
      message: '',
      attachmentName: '',
      path: '',
      userId: 0,
      createDate: '',
      typeEvent: 0,
      userType: 0
    };
    this.fileUploadAttachment = undefined;
  }
  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    this.fileUploadAttachment = evt.target.files[0];
    this.dataMessage.attachmentName = this.fileUploadAttachment.name;
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoaded(e) {
    this.dataMessage.path = btoa(e.target.result);
    // console.log(this.dataMessage);
  }
  handleReaderLoadedLog(e) {
    this.dataMessageLog.path = btoa(e.target.result);
    // console.log(this.dataMessage);
  }
  onManagementTabSelect(data: TabDirective): void {
    this.lclSectionSelected = Number(data.id);
    // console.log(this.lclSectionSelected);
    switch (this.lclSectionSelected) {
      case 0:
        // console.log('detalle general');
        this.ngOnDestroyInterval();
        break;
      case 1:
        this.countMessageChat = 0;
        this.getRequestMessagesAttention(1, this.requestId, 2000);
        this.id = setInterval(() => {
          this.getRequestMessagesAttention(1, this.requestId, 200);
        }, 5000);
        break;
      case 2:
        this.ngOnDestroyInterval();
        // console.log('bitacora');
        this.loadDataRequestMessageFilter();
        this.getAuthors();
        this.getTypeEvents();
        this.getByPagination(1, 5);
        break;
    }
  }
  getAuthors() {
    this.api.getRequestAuthors(this.requestId).then((data: any) => {
      this.dataRequestAuthor = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getTypeEvents() {
    this.api.getRequestTypesEvents(this.requestId).then((data: any) => {
      this.dataRequestEvents = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  loadDataRequestMessageFilter() {
    this.dataRequestMessageFilter = {
      description : '',
      authors: [],
      typeEvents: [],
      requestId: this.requestId,
      page: 0,
      size: 0
    };
  }

  changeStatusTypes() {
    const events = [];
    setTimeout(() => {
      try {
        this.typesSelectedItems.forEach(item => {
          events.push(item.typeRequestId);
        });
        this.filters.typeEvents = events;
      } catch (e) { }
    }, 500);
  }
  changeStatusAuthor() {
    const authors = [];
    setTimeout(() => {
      try {
        this.authorSelectedItems.forEach(item => {
          authors.push(item.userId);
        });
        this.filters.authors = authors;
      } catch (e) { }
    }, 500);
  }

  changeStatus(statusId) {
    this.api.updateRequestStatus(this.requestId, statusId)
      .then(
        (response: any) => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.shared.gRequestGeneralId.next(this.requestId);
          this.closeRequestDetail();
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }

  messageLogClear() {
    this.dataMessageLog = {
      id: 0,
      requestId: 0,
      message: '',
      attachmentName: '',
      path: '',
      userId: 0,
      createDate: '',
      typeEvent: 0,
      userType: 0
    };
    this.fileUpload = undefined;
  }
  onUploadChangeLog(evt: any) {
    const file = evt.target.files[0];
    this.fileUpload = evt.target.files[0];
    this.dataMessageLog.attachmentName = this.fileUpload.name;
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoadedLog.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  sendMessageLog() {
    this.spinner.show('sp');
    this.dataMessageLog.requestId = this.requestId;
    this.dataMessageLog.userId = localStorage.getItem('user-id');
    this.dataMessageLog.typeEvent = 2;
    this.dataMessageLog.userType = 2;
    // console.log(this.dataMessageLog);
    if (this.dataMessageLog.message == '') {
      this.toastr.warning('POR FAVOR INGRESE UN EVENTO A ENVIAR', 'NOTIFICACIÓN');
      this.spinner.hide('sp');
      return;
    }
    this.api.postSendMessageRequest(this.dataMessageLog)
      .then(
        (response: any) => {
          this.messageLogClear();
          (document.getElementById('fileLog') as HTMLInputElement).value = null;
          // this.getRequestMessagesAttention(1, this.requestId, 500);
          this.getByPagination(1, 5);
          this.spinner.hide('sp');
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  clearFiltersTable() {
    this.loadFilters();
    this.authorSelectedItems = [];
    this.typesSelectedItems = [];
    this.getByPagination(1, 5);
  }

  loadFilters() {
    this.filters = {
      description: '',
      authors: [],
      typeEvents: [],
      requestId: this.requestId,
      page: 1,
      size: 5
    };
  }
  loadTableLog() {
    // console.log(this.dataRequestMessageFilter);
    this.getByPagination(1, 5);
  }
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.filters.requestId = this.requestId;
    // console.log(this.filters);
    this.api.getRequestPaginationLog(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.requestMessagesList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          // // console.log(this.paginationResponse);
          this.pgTotalItems = this.paginationResponse.total ;
          this.requestMessagesList = this.paginationResponse.requestMessageList ;
        }, error => {
          // // console.error(error.status);
          // // console.error(error.statusText);
          // // console.error(error.message);
          this.requestMessagesList = [];
          this.pgTotalItems = 0;
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  pageChanged(event: PageChangedEvent): void {
    // // // console.log(event);
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }

  closeRequestDetail() {
    this.ngOnDestroyInterval();
    localStorage.removeItem('request-id');
    this.modalRef.hide();
  }

  closeModal() {
    this.modalRd.hide();
  }
  openModalDetail(template: TemplateRef<any>, id) {
    setTimeout( () => {
      this.getRequestMessageDetail(id);
    }, 1000);
    this.modalRd = this.modalService.show(template, {class: 'modal-md'});
  }
  getRequestMessageDetail(id) {
    this.api.getRequestMessageDetail(id).then((data: any) => {
      this.dataMessageDetail = data;
    }, error => {
    });
  }

  /* -----------------------------------------------------------------------------------------------------
   ------------------------N U E V O S I N I E S T R O-----------------------------------------------
   ----------------------------------------------------------------------------------------------------- */
  generateIncident(template: TemplateRef<any>) {
    this.modalRef1 = this.modalService.show(template, {class: 'modal-lg'});
    // cargar datos que ya tenemos en la solicitud
    this.flag = 1;
    this.isIncident = true;
    this.loadDataIncident();
    this.getGroups();
  }
  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupList = data;
      this.groupSelected = this.dataRequestGeneral.groupId;
      this.dataIncident.groupId = this.groupSelected;
      const group = [];
      group.push(this.groupSelected);
      let multiGroup = {};
      multiGroup = {
        idsGroups: group
      };
      this.getSubGroups(multiGroup);
    }, error => {
    });
  }
  closeModal1() {
    this.modalRef1.hide();
    this.selectionIncident = false;
  }
  changeGroupToConsult(event: any) {
    if (event !== undefined) {
      this.dataIncident.groupId = event.groupId;
      const group = [];
      group.push(event.groupId);
      let multiGroup = {};
      multiGroup = {
        idsGroups: group
      };
      this.getSubGroups(multiGroup);
    }
  }
  changeClientToConsult(event: any) {
    if (event !== undefined) {
      this.dataIncident.clientId = event.clientId;
      this.invokeServiceGetUserByGroupClient(this.dataIncident.groupId, this.dataIncident.clientId);
    }
  }
  selectInsuredIncident(item) {
    this.dataIncident.userId = item.userId;
    this.invokeServiceGetPoliciesByUser(item.userId);
    if (!this.isIncident) {
      this.invokeServiceGetIncidentByUserId();
    }
  }
  inputClearedInsuredIncident() {
    this.dataIncident.userId = 0;
    this.user.close();
  }
  changePolicyToConsult(event: any) {
    if (event !== undefined) {
      this.dataIncident.policyId = event.policyId;
      this.invokeServiceGetTypeIncidentsByPolicy(event.policyId);
      this.invokeServiceGetKinshipByUserPolicy();
    }
  }
  changeInsuredToConsult(event: any) {
    if (event !== undefined) {
      this.dataIncident.userId = event.userId;
      this.invokeServiceGetPoliciesByUser(this.dataIncident.userId);
      if (!this.isIncident) {
        this.invokeServiceGetIncidentByUserId();
      }
    }
  }
  invokeServiceSaveIncident() {
    this.dataIncident.incidentId = this.requestId;
    this.policyInsuredList.forEach(item => {
      if (item.policyId === this.dataIncident.policyId) {
        this.dataIncident.policy = item.policy;
      }
    });
    this.insuredIncidentList.forEach(item => {
      if (item.userId === this.dataIncident.userId) {
        this.dataIncident.insured = item.name;
      }
    });
    // console.log(this.dataIncident);
    setTimeout( () => {
    this.spinner.show('sp');
    this.api.saveIncidentByRequest(this.dataIncident)
      .then(
        (response: any) => {
          this.toastr.success('EL SINIESTRO SE REGISTRO CORRECTAMENTE', 'NOTIFICACIÓN');
          // se obtiene folio surexs e incidentid para presentar en pantalla y
          // se manda a escribir mensaje a bitacora de solicitud que se genero solicitud a partir de este siniestro
          // se termina solicitud
          this.dataIncident.incidentId = response.incidentId;
          this.dataIncident.folioSurexs = response.folioSurexs;
          this.invokeServiceGenerateLog();
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
          this.selectionIncident = false;
        }
      );
    }, 1000);
  }
  changeTypeIncidentToConsult(event: any) {
    if (event !== undefined) {
      this.dataIncident.typeIncidentId = event.typeIncidentId;
    } else {
      this.dataIncident.typeIncidentId = 0;
    }
  }
  changeKinshipToConsult(event: any) {
    if (event !== undefined) {
      this.dataIncident.kinshipId = event.userId;
    }
  }
  changeDocumentationToConsult(event: any) {
    if (event !== undefined) {
      this.dataIncident.typeRequestIncidentId = event.requestTypeIncidentId;
    }
  }
  /*openIncident(template: TemplateRef<any>, isIncident) {
    // this.isIncident = isIncident;
    this.loadDataIncident();
    this.modalRef1 = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
    this.myFormIncident.reset();
  }*/
  loadDataIncident() {
    this.dataIncident = {
      incidentId: 0,
      name: '',
      policyId : this.dataRequestGeneral.policyId,
      policy: '',
      userId: this.dataRequestGeneral.insuredId,
      insured: '',
      typeIncidentId: 0,
      contact: '',
      folioSurexs: '',
      folioInsurance: '',
      kinshipId: 0,
      kinship: '',
      groupId: this.dataRequestGeneral.groupId,
      clientId: this.dataRequestGeneral.clientId,
      description: this.dataRequestGeneral.subject,
      medicalCondition: '',
      classification: 1,
      typeRequestIncidentId: 0,
      incidentIdOld: 0
    };
  }
  invokeServiceGetPoliciesByUser(userId) {
    this.api.getPoliciesByUserId(userId).then((data: any) => {
      if (data == null) {
        this.policyInsuredList = [];
        return;
      }
      this.policyInsuredList = data;
      this.dataIncident.policyId = this.dataRequestGeneral.policyId;
      this.policySelected = this.dataIncident.policyId;
    }, error => {
    });
  }
  invokeServiceGetUserByGroupClient(groupId, clientId) {
    this.api.getUserByGroupClient(groupId, clientId).then((data: any) => {
      if (data == null) {
        this.insuredIncidentList = [];
        return;
      }
      this.insuredIncidentList = data;
    }, error => {
    });
  }
  invokeServiceGetKinshipByUserPolicy() {
    this.api.getKinshipByUserPolicy(this.dataIncident.policyId, this.dataIncident.userId).then((data: any) => {
      if (data == null) {
        this.kinshipIncidentList = [];
        return;
      }
      this.kinshipIncidentList = data;
    }, error => {
    });
  }
  invokeServiceGetTypeIncidentsByPolicy(policyId) {
    this.api.getTypeIncidentByPolicy(policyId).then((data: any) => {
      if (data == null) {
        this.typeIncidentList = [];
        return;
      }
      this.typeIncidentList = data;
    }, error => {
    });
  }
  invokeServiceGetTypeDocumentation(type: number) {
    this.api.getTypesRequestIncidentHigh(type).then((data: any) => {
      this.typeRequestList = data;
    }, error => {
    });
  }
  changeTypeIncident() {
    for (const item of this.incidentList) {
      if (this.incidentSelected == item.incidentId) {
        this.dataIncident.incidentIdOld = item.incidentId;
        this.dataIncident.medicalCondition = item.medicalCondition;
        this.dataIncident.folioInsurance = item.folioInsurance;
      }
    }
  }
  invokeServiceGetIncidentByUserId() {
    this.api.getIncidentsByUserId(this.dataIncident.userId).then((data: any) => {
      if (data == null) {
        this.toastr.info('EL USUARIO NO CUENTA CON SINIESTROS PREVIOS');
        this.incidentList = [];
        return;
      }
      this.incidentList = data;
    }, error => {
    });
  }
  loadCatalog(value) {
    this.flag = value.catalogs;
  }
  selectReclamation() {
    this.invokeServiceGetTypeDocumentation(this.flag);
    switch (this.flag) {
      case 1:
        this.isIncident = true;
        break;
      case 2:
        this.isIncident = false;
        break;
    }
    if (this.isIncident) {
      this.dataIncident.classification = 1;
    } else {
      this.dataIncident.classification = 2;
      this.invokeServiceGetIncidentByUserId();
    }
    this.selectionIncident = true;
    this.myFormIncident.reset();
    this.typeDocumentationSelected = 0;
    this.typeIncidentSelected = 0;
  }
  getSubGroups(groups) {
    this.api.getSubGroupsMultiselect(groups).then((data: any) => {
      this.clientList = data;
      this.clientSelected = this.dataRequestGeneral.subGroupId;
      this.dataIncident.clientId = this.clientSelected;
      this.invokeServiceGetUserByGroupClient(this.dataIncident.groupId, this.dataIncident.clientId);
      this.dataIncident.userId = this.dataRequestGeneral.insuredId;
      this.invokeServiceGetPoliciesByUser(this.dataIncident.userId);
      if (!this.isIncident) {
        this.invokeServiceGetIncidentByUserId();
      }
      this.invokeServiceGetTypeIncidentsByPolicy(this.dataIncident.policyId);
      this.invokeServiceGetKinshipByUserPolicy();
    }, error => {
    });
  }
  invokeServiceLoadIncident() {
    localStorage.setItem('incidentId',  this.dataIncident.incidentId);
    this.router.navigate(['/admin/incident_detail']);
    this.closeModal1();
    this.closeRequestDetail();
  }
  invokeServiceSaveMessageLogIncident(folio: string) {
    this.dataMessageLog = {
      id: 0,
      requestId: this.requestId,
      message: 'Se generó el siniestro con Folio: ' + folio + ' a partir de esta solicitud.',
      attachmentName: '',
      path: '',
      userId: localStorage.getItem('user-id'),
      createDate: '',
      typeEvent: 2,
      userType: 2
    };
    this.api.postSendMessageRequest(this.dataMessageLog)
      .then(
        (response: any) => {
          this.messageLogClear();
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  invokeServiceGenerateLog() {
    this.invokeServiceSaveMessageLogIncident(this.dataIncident.folioSurexs);
    this.invokeServiceFinishRequest(3);
    this.invokeServiceUpdateRequestIncident();
  }
  invokeServiceFinishRequest(statusId) {
    this.api.updateRequestStatus(this.requestId, statusId)
      .then(
        (response: any) => {
          this.shared.gRequestGeneralId.next(this.requestId);
          this.getRequestDetail();
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }
  invokeServiceUpdateRequestIncident() {
    const data = {
      requestId: this.requestId,
      incidentId: this.dataIncident.incidentId
    };
    this.api.updateRequestIncident(data)
      .then(
        (response: any) => {
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }
}
