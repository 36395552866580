import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FileUploadControl, FileUploadValidators} from "@iplab/ngx-file-upload";
import {ToastrService} from "ngx-toastr";
import {SharedService} from "../shared-service.service";
import {ApiService} from "../api.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Documents} from "../upload-file/documents";

@Component({
  selector: 'app-location-damage-housing-edit',
  templateUrl: './location-damage-housing-edit.component.html',
  styleUrls: ['./location-damage-housing-edit.component.css']
})
export class LocationDamageHousingEditComponent implements OnInit {
  modalRef: BsModalRef;
  modalViewer: BsModalRef;
  modalAttachment: BsModalRef;
  modalSaveEndorsement: BsModalRef;
  // tslint:disable-next-line:variable-name
  _policyId: number;
  // tslint:disable-next-line:variable-name
  _locationId: number;
  // tslint:disable-next-line:variable-name
  _clientId: number;
  // tslint:disable-next-line:variable-name
  _endorsementId: number;
  // tslint:disable-next-line:variable-name
  _dataToSendGeneral: any = [];
  @Output() public resultAddress = new EventEmitter<any>();
  homeTypeHousingForm: FormGroup;
  dataAddressFiscal: any = {};
  dataAddress: any = {};
  countryListFiscal: any = [];
  countryList: any = [];
  countryListOther: any = [];
  municipalityListFiscal: any = [];
  municipalityList: any = [];
  municipalityListOther: any = [];
  zipCodeListFiscal: any = [];
  stateListFiscal: any = [];
  stateList: any = [];
  stateListOther: any = [];
  neighborhoodListFiscal: any = [];
  neighborhoodList: any = [];
  streetsListFiscal: any = [];
  streetsList: any = [];
  streetsListOther: any = [];
  selectStateFiscalyTemp: any = {};
  selectMunicipalityFiscalTemp: any = {};
  selectZipCodeFiscalTemp: any = {};
  selectNeighborhoodFiscalTemp: any = {};
  selectStreetFiscalTemp: any = {};
  _FISCAL = 'FISCAL';
  streetFiscalNew: any = {};
  isNewStreetFiscal = false;
  isNewStreetOther = false;
  addressForm: FormGroup;
  countryFiscal: string;
  typeWallCeiling: any = [];
  catSecurityMeasure: any = [];
  securityMeasureSelectedItems: any = [];
  dropdownSettingsSecurityMeasure: any = {};
  catFireProtection: any = [];
  fireProtectionSelectedItems: any = [];
  dropdownSettingsFireProtection: any = {};
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  subActivity: string;
  subActivityList: any = [];
  modalLocation: BsModalRef;
  operationLocation: number;
  base64File2: string;
  catClientsPolicy: any = [];
  addressId: number;
  typeHousingList: any = [];
  selectNeighborhoodTemp: any = {};
  streetOtherNew: any = {};
  selectZipCodeOtherTemp: any = {};
  zipCodeList: any = [];
  sameAddressFiscal = false;
  formMdl: FormGroup;
  public fileUploadControlMdl = new FileUploadControl({listVisible: false, discardInvalid: true, accept: ['.pdf']},
    [FileUploadValidators.accept(['.pdf']), FileUploadValidators.fileSize(25428800)]);
  multiple: true;
  receiptData: any = [];
  constructor(private modalService: BsModalService,
              private toastr: ToastrService,
              private shared: SharedService,
              private api: ApiService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder) {
    this.homeTypeHousingForm = new FormGroup({
      countryFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      zipCodeFiscal: new FormControl(null, [
        Validators.required
      ]),
      stateFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      municipalityCodeFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      neighborhoodFiscal: new FormControl(null, [
        Validators.required
      ]),
      streetFiscal: new FormControl(null, [
        Validators.required
      ]),
      addStreetFiscal: new FormControl(),
      numExtFiscal: new FormControl(null, [
        Validators.maxLength(20),
        Validators.required
      ]),
      numIntFiscal: new FormControl(null, [
        Validators.maxLength(20),
        Validators.required
      ]),
      country: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      zipCode: new FormControl(null, [
        Validators.required
      ]),
      state: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      municipalityCode: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      neighborhood: new FormControl(null, [
        Validators.required
      ]),
      addStreet: new FormControl(),
      street: new FormControl(null, [
        Validators.required
      ]),
      numExt: new FormControl(),
      numInt: new FormControl(null, [
        Validators.required
      ]),
      sameAddress: new FormControl(),
      typeHousing: new FormControl(null, [
        Validators.required
      ]),
      typeWall: new FormControl(null, [
        Validators.required
      ]),
      basement: new FormControl(null, [
        Validators.required
      ]),
      floor: new FormControl(null, [
        Validators.required
      ]),
      securityMeasure: new FormControl(null, [
        Validators.required
      ]),
      fireProtection: new FormControl(null, [
        Validators.required
      ])
    });
    this.formMdl = this.formBuilder.group({
      filesMdl: new FormControl()
    });
  }

  ngOnInit() {
    // console.log('valores iniciales');
    this.dropdownSettingsSecurityMeasure = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON MEDIDAS DE SEGURIDAD',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsFireProtection = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON PROTECCIÓN CONTRA INCENDIOS',
      searchPlaceholderText: 'BUSCAR'
    };
  }

  /* -----------------------------------------------------------------------------------------------------
   ------------------------F U N C I O N E S G E N E R A L E S-----------------------------------------------
   ----------------------------------------------------------------------------------------------------- */
  get policyId(): number {
    return this._policyId;
  }
  @Input()
  set policyId(value: number) {
    this._policyId = value;
  }
  @Input()
  set clientId(value: number) {
    this._clientId = value;
  }
  get clientId(): number {
    return this._clientId;
  }
  @Input()
  set locationId(value: number) {
    this._locationId = value;
  }
  get locationId(): number {
    return this._locationId;
  }

  @Input()
  set endorsementId(value: number) {
    this._endorsementId = value;
  }
  get endorsementId(): number {
    return this._endorsementId;
  }

  @Input()
  set dataToSendGeneral(value: any) {
    this._dataToSendGeneral = value;
  }
  get dataToSendGeneral(): any {
    return this._dataToSendGeneral;
  }
  loadInitialValuesData() {
    this.dataAddress = {
      id: 0,
      country: null,
      zipCode: null,
      stateCode: null,
      municipalityCode: null,
      neighborhoodCode: null,
      street: null,
      numExt: null,
      numInt: 'S/N',
      sameAddress: false
    };
    this.dataAddressFiscal = {
      locationId: 0,
      location: 'CASA HOGAR',
      country: null,
      zipCode: null,
      stateCode: null,
      municipalityCode: null,
      neighborhoodCode: null,
      street: null,
      numExt: null,
      numInt: 'S/N',
      homeTypeHousingId: 0,
      constructionTypeId: 0,
      basement: 0,
      floor: 0,
      securityMeasureList: [],
      fireProtectionList: [],
      locationNumber: 1,
      clientId: this._clientId,
      pathFile: '',
      nameFile: '',
      policyId: this._policyId,
      streetNew : false
    };

    this.streetFiscalNew = {
      coloniesStreetsId: 0
      , neighborhoodCode: null
      , nameStreet: null
      , status: false

    };
    this.isNewStreetFiscal = false;
    this.isNewStreetOther = false;
    this.fireProtectionSelectedItems = [];
    this.securityMeasureSelectedItems = [];
    this.subActivityList = [];
    this.fileUpload = null;
  }

  openModal(template: TemplateRef<any>) {
    if (this._policyId === 0) {
      this.toastr.warning('NO SE PUEDEN AGREGAR DIRECCIONES SIN ANTES AGREGAR LA PÓLIZA', 'NOTIFICATION');
      return;
    }
    console.log('valor inicial cliente');
    console.log(this._clientId);
    console.log(this._policyId);
    this.spinner.show('sp');
    this.invokeServiceGetCountries();
    this.invokeServiceGetStatesByCountry();
    this.getTypesHomeWallCeiling();
    this.loadInitialValuesData();
    this.invokeServiceGetClientsPolicy(localStorage.getItem('policy-id'));
    this.invokeServiceGetSecurityMeasure();
    this.invokeServiceGetFireProtection();
    this.invokeServiceGetTypeHousing();
    this.addressId = this._locationId;
    if (this.addressId > 0) {
      this.base64File2 = '';
      let streetTmp = '';
      let neighborhoodCodeTmp = '';
      let streetTmpOther = '';
      let neighborhoodCodeTmpOther = '';
      const damagePolicy = {
        locationId: this.addressId,
        type: 1,
        endorsementId: this._endorsementId === undefined ? 0 : this._endorsementId
      };
      this.api.getAddressHousing(damagePolicy).then((data: any) => {
        this.dataAddressFiscal = data;
        this.dataAddress = data.addressFiscal;
        this.sameAddressFiscal = data.addressFiscal.sameAddress;
        this.isNewStreetFiscal = true;
        this.isNewStreetOther = true;
        streetTmp = this.dataAddressFiscal.street;
        neighborhoodCodeTmp = this.dataAddressFiscal.neighborhoodCode;
        streetTmpOther = this.dataAddress.street;
        neighborhoodCodeTmpOther = this.dataAddress.neighborhoodCode;
        this.selectStateMunicipalityByZipCode(this._FISCAL);
        this.selectStateMunicipalityByZipCodeOther(this._FISCAL);
        // load multiSelect
        setTimeout(() => {
          const securityMeasure = [];
          this.dataAddressFiscal.securityMeasureList.forEach(item => {
            this.catSecurityMeasure.forEach(item2 => {
              if (item === item2.id) {
                securityMeasure.push(item2);
                return;
              }
            });
          });
          this.securityMeasureSelectedItems = securityMeasure;
        }, 1000);
        setTimeout(() => {
          const fireProtection = [];
          this.dataAddressFiscal.fireProtectionList.forEach(item => {
            this.catFireProtection.forEach(item2 => {
              if (item === item2.id) {
                fireProtection.push(item2);
                return;
              }
            });
          });
          this.fireProtectionSelectedItems = fireProtection;
        }, 1000);
        setTimeout(() => {
          this.dataAddressFiscal.street = streetTmp;
          this.dataAddressFiscal.neighborhoodCode = neighborhoodCodeTmp;
          this.dataAddress.street = streetTmp;
          this.dataAddress.neighborhoodCode = neighborhoodCodeTmp;
        }, 1000);
      }, error => {
        this.spinner.hide('sp');
      });
      console.log(this.dataAddressFiscal);
    } else {
      this.dataAddressFiscal.country = 'MÉXICO';
    }
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
    setTimeout( () => {
      this.spinner.hide('sp');
    }, 1000);
  }

  /* -----------------------------------------------------------------------------------------------------
   ------------------------D I R E C C I O N D E R I E S G O-----------------------------------------------
   ----------------------------------------------------------------------------------------------------- */
  invokeServiceGetCountries() {
    this.api.getCountries().then((data: any) => {
      if (data != null) {
        this.countryList = data;
        this.countryListFiscal = data;
        this.dataAddressFiscal.country = this.countryListFiscal[0].cValue;
        this.dataAddress.country = this.countryList[0].cValue;
      }
    }, error => {
    });
  }
  getCountrySelectedFiscal(event) {
    this.dataAddressFiscal.country = event.target.value;
  }
  selectStateMunicipalityByZipCode(typeAddress: string) {
    let zipCode;
    if (typeAddress === this._FISCAL) {
      zipCode = this.dataAddressFiscal.zipCode;
    }
    this.api.getCheckZipeCode(zipCode).then((result: any) => {
      if (result != null) {
        if (result) {
          this.invokeStateByZipCode(zipCode, typeAddress);
          let value;
          if (typeAddress === this._FISCAL) {
            value =  {
              cKey: this.dataAddressFiscal.zipCode,
              cValue: this.dataAddressFiscal.zipCode
            };
          }
          this.selectZipCodeFiscal(value);
        } else {
          this.toastr.warning('El código postal no existe, por favor intenta con otro', 'Notificación');
          this.clearStateFiscal();
        }
      }
    }, error => {
    });

  }
  selectStateFiscaly(event) {
    if (event !== undefined) {
      this.selectStateFiscalyTemp = event;
      this.dataAddressFiscal.stateCode = event.cKey;
      this.clearMunicipalityFiscal();
      this.invokeServiceGetMunicipalyByStateName(event, this._FISCAL);
    }
  }
  clearStateFiscal() {
    this.dataAddressFiscal.stateCode = null;
    this.clearMunicipalityFiscal();
    this.clearMunicipalityFiscalList();

  }
  selectMunicipalityFiscal(event) {
    // this.zipCodeListFiscal=[];
    if (event !== undefined) {
      this.selectMunicipalityFiscalTemp = event;
      this.dataAddressFiscal.municipalityCode = event.cKey;
      this.invokeServiceGetZipCodesByMunicipalyCode(event, this._FISCAL);
      this.clearZipCodeFiscal();
    }
  }
  clearMunicipalityFiscal() {
    this.dataAddressFiscal.municipalityCode = null;
    this.dataAddressFiscal.zipCode = null;
    this.clearZipCodeFiscalList();
    this.dataAddressFiscal.neighborhoodCode = null;
    this.clearNeighborhoodFiscalList();
    this.dataAddressFiscal.street = null;
    this.clearStreetFiscalList();
  }
  selectNeighborhoodFiscal(event) {
    if (event !== undefined) {
      this.selectNeighborhoodFiscalTemp = {};
      this.dataAddressFiscal.neighborhoodCode = event.cKey;
      this.clearStreetFiscalAndList();
      this.invokeServiceGetStreetsByColonie(event, this._FISCAL);
      this.streetFiscalNew.neighborhoodCode = event.cKey;
    }
  }
  clearNeighborhoodFiscal() {
    this.dataAddressFiscal.street = null;
    this.dataAddressFiscal.neighborhoodCode = null;
    this.clearStreetFiscalList();
  }
  selectStreetFiscal(event) {
    if (event !== undefined) {
      this.selectStreetFiscalTemp = event;
      this.dataAddressFiscal.street = event.cValue;
    }
  }
  clearStreetFiscal() {
    this.dataAddressFiscal.street = null;

  }
  onChangeStreet() {}
  onChangeFiscal(event) {
    this.isNewStreetFiscal = event;
    this.dataAddressFiscal.street = null;
    this.dataAddressFiscal.streetNew = event;
  }
  onChangeNumExt() {}
  onChangeNumInt() {}

  invokeStateByZipCode(zipCode, typeAddress) {
    this.api.getStatesByZipeCode(zipCode).then((result: any) => {
      if (result != null) {
        if (this._FISCAL === typeAddress) {
          this.dataAddressFiscal.stateCode = result.cKey;
          // this.dataAddressFiscal.state = result.cValue;
          // console.log('obtener municipio');
          // console.log(result);
          this.invokeServiceGetMunicipalyByStateName(result, this._FISCAL);
        }
      } else {
        if (this._FISCAL === typeAddress) {
          this.dataAddressFiscal.stateCode = null;
          this.dataAddressFiscal.state = null;
        }
      }
    }, error => {
    });
  }
  invokeTownByZipCode(zipCode, typeAddress) {
    this.api.getTownByPostcode(zipCode).then((result: any) => {
      if (result != null) {
        if (typeAddress === this._FISCAL) {
          this.dataAddressFiscal.municipalityCode = result.cKey;
        }
      } else {
        if (typeAddress === this._FISCAL) {
          this.dataAddressFiscal.municipalityCode = null;
        }
      }
    }, error => {
    });
  }

  clearMunicipalityFiscalList() {
    this.municipalityListFiscal = [];
  }
  clearMunicipalityList() {
    this.municipalityList = [];
  }
  clearZipCodeFiscalList() {
    this.zipCodeListFiscal = [];
  }
  clearNeighborhoodFiscalList() {
    this.neighborhoodListFiscal = [];
  }
  clearStreetFiscalList() {
    this.streetsListFiscal = [];
  }
  clearNeighborhoodList() {
    this.neighborhoodList = [];
  }
  invokeServiceGetMunicipalyByStateName(data1, typeAddress) {
    this.api.getMunicipalyByStateName(data1).then((data: any) => {
      if (data != null) {
        switch (typeAddress) {
          case this._FISCAL: {
            this.municipalityListFiscal = data;
            this.invokeTownByZipCode(this.dataAddressFiscal.zipCode, typeAddress);
            break;
          }
        }
      }
    }, error => {
    });

  }
  invokeServiceGetZipCodesByMunicipalyCode(data1, typeAddress) {
    this.api.getZipCodesByMunicipalyCode(data1).then((data: any) => {
      if (data != null) {
        this.zipCodeListFiscal = data;
      }
    }, error => {
    });
  }
  clearZipCodeFiscal() {
    this.dataAddressFiscal.street = null;
    this.dataAddressFiscal.neighborhoodCode = null;
    this.dataAddressFiscal.zipCode = null;
    this.clearNeighborhoodFiscalList();
    this.clearStreetFiscalList();
  }
  selectZipCodeFiscal(event) {
    if (event !== undefined) {
      this.selectZipCodeFiscalTemp = event;
      this.dataAddressFiscal.zipCode = event.cValue;
      this.invokeServiceGetColoniesByZipCode(event, this._FISCAL);
      this.clearNeighborhoodFiscal();
    }
  }
  invokeServiceGetColoniesByZipCode(data1, typeAddress) {
    this.api.getColoniesByZipCode(data1).then((data: any) => {
      if (data != null) {
        this.neighborhoodListFiscal = data;
      }
    }, error => {
    });
  }
  selectNeighborhoodFiscalM(event) {
    if (event !== undefined) {
      this.selectNeighborhoodFiscalTemp = {};
      this.dataAddressFiscal.neighborhoodCode = event.cKey;
      this.streetFiscalNew.neighborhoodCode = event.cKey;
    }
  }
  clearStreetFiscalAndList() {
    this.dataAddressFiscal.street = null;
    this.clearStreetFiscalList();

  }
  invokeServiceGetStreetsByColonie(data1, typeAddress) {
    this.api.getStreetsByColonie(data1).then((data: any) => {
      if (data != null) {
        this.streetsListFiscal = data;
      }
    }, error => {
    });
  }
  invokeServiceGetStatesByCountry() {
    this.api.getStatesByCountry().then((data: any) => {
      if (data != null) {
        this.stateListFiscal = data;
        this.stateList = data;
      }
    }, error => {
    });
  }

  /* -----------------------------------------------------------------------------------------------------
   ------------------------D I R E C C I O N F I S C A L -----------------------------------------------
   ----------------------------------------------------------------------------------------------------- */
  getCountrySelectedOther(event) {
    this.dataAddress.country = event.target.value;
  }
  selectStateMunicipalityByZipCodeOther(typeAddress: string) {
    let zipCode;
    if (typeAddress === this._FISCAL) {
      zipCode = this.dataAddress.zipCode;
    }
    this.api.getCheckZipeCode(zipCode).then((result: any) => {
      if (result != null) {
        if (result) {
          this.invokeStateByZipCodeOther(zipCode, typeAddress);
          let value;
          if (typeAddress === this._FISCAL) {
            value =  {
              cKey: this.dataAddress.zipCode,
              cValue: this.dataAddress.zipCode
            };
          }
          this.selectZipCodeOther(value);
        } else {
          this.toastr.warning('El código postal no existe, por favor intenta con otro', 'Notificación');
          this.clearStateFiscal();
        }
      }
    }, error => {
    });

  }
  selectStateOther(event) {
    if (event !== undefined) {
      this.selectStateFiscalyTemp = event;
      this.dataAddressFiscal.stateCode = event.cKey;
      this.clearMunicipalityOther();
      this.invokeServiceGetMunicipalyByStateNameOther(event, this._FISCAL);
    }
  }
  clearStateOther() {
    this.dataAddress.stateCode = null;
    this.clearMunicipalityOther();
    this.clearMunicipalityOther();

  }
  selectMunicipalityOther(event) {
    // this.zipCodeListFiscal=[];
    if (event !== undefined) {
      this.selectMunicipalityFiscalTemp = event;
      this.dataAddress.municipalityCode = event.cKey;
      this.invokeServiceGetZipCodesByMunicipalyCodeOther(event, this._FISCAL);
      this.clearZipCodeOther();
    }
  }
  clearMunicipalityOther() {
    this.dataAddress.municipalityCode = null;
    this.dataAddress.zipCode = null;
    this.clearZipCodeOtherList();
    this.dataAddress.neighborhoodCode = null;
    this.clearNeighborhoodOtherList();
    this.dataAddress.street = null;
    this.clearStreetOtherList();
  }
  selectNeighborhoodOther(event) {
    if (event !== undefined) {
      this.selectNeighborhoodFiscalTemp = {};
      this.dataAddress.neighborhoodCode = event.cKey;
      this.clearStreetOtherAndList();
      this.invokeServiceGetStreetsByColonieOther(event, this._FISCAL);
      this.streetFiscalNew.neighborhoodCode = event.cKey;
    }
  }
  clearNeighborhoodOther() {
    this.dataAddress.street = null;
    this.dataAddress.neighborhoodCode = null;
    this.clearStreetOtherList();
  }
  selectStreetOther(event) {
    if (event !== undefined) {
      this.selectStreetFiscalTemp = event;
      this.dataAddress.street = event.cValue;
    }
  }
  clearStreetOther() {
    this.dataAddress.street = null;

  }
  onChangeStreetOther() {}
  onChangeOther(event) {
    this.isNewStreetOther = event;
    this.dataAddress.street = null;
    this.dataAddress.streetNew = event;
  }
// me quede aquí
  invokeStateByZipCodeOther(zipCode, typeAddress) {
    this.api.getStatesByZipeCode(zipCode).then((result: any) => {
      if (result != null) {
        if (this._FISCAL === typeAddress) {
          this.dataAddress.stateCode = result.cKey;
          // this.dataAddressFiscal.state = result.cValue;
          // console.log('obtener municipio');
          // console.log(result);
          this.invokeServiceGetMunicipalyByStateNameOther(result, this._FISCAL);
        }
      } else {
        if (this._FISCAL === typeAddress) {
          this.dataAddress.stateCode = null;
          // this.dataAddress.state = null;
        }
      }
    }, error => {
    });
  }
  invokeTownByZipCodeOther(zipCode, typeAddress) {
    this.api.getTownByPostcode(zipCode).then((result: any) => {
      if (result != null) {
        if (typeAddress === this._FISCAL) {
          this.dataAddress.municipalityCode = result.cKey;
        }
      } else {
        if (typeAddress === this._FISCAL) {
          this.dataAddress.municipalityCode = null;
        }
      }
    }, error => {
    });
  }

  clearMunicipalityOtherList() {
    this.municipalityList = [];
  }
  clearMunicipalityListOther() {
    this.municipalityList = [];
  }
  clearZipCodeOtherList() {
    this.zipCodeList = [];
  }
  clearNeighborhoodOtherList() {
    this.neighborhoodList = [];
  }
  clearStreetOtherList() {
    this.streetsList = [];
  }
  clearNeighborhoodListOther() {
    this.neighborhoodList = [];
  }
  invokeServiceGetMunicipalyByStateNameOther(data1, typeAddress) {
    this.api.getMunicipalyByStateName(data1).then((data: any) => {
      if (data != null) {
        switch (typeAddress) {
          case this._FISCAL: {
            this.municipalityList = data;
            this.invokeTownByZipCodeOther(this.dataAddress.zipCode, typeAddress);
            break;
          }
        }
      }
    }, error => {
    });

  }
  invokeServiceGetZipCodesByMunicipalyCodeOther(data1, typeAddress) {
    this.api.getZipCodesByMunicipalyCode(data1).then((data: any) => {
      if (data != null) {
        this.zipCodeList = data;
      }
    }, error => {
    });
  }
  clearZipCodeOther() {
    this.dataAddress.street = null;
    this.dataAddress.neighborhoodCode = null;
    this.dataAddress.zipCode = null;
    this.clearNeighborhoodOtherList();
    this.clearStreetOtherList();
  }
  selectZipCodeOther(event) {
    if (event !== undefined) {
      this.selectZipCodeOtherTemp = event;
      this.dataAddress.zipCode = event.cValue;
      this.invokeServiceGetColoniesByZipCodeOther(event, this._FISCAL);
      this.clearNeighborhoodOther();
    }
  }
  invokeServiceGetColoniesByZipCodeOther(data1, typeAddress) {
    this.api.getColoniesByZipCode(data1).then((data: any) => {
      if (data != null) {
        this.neighborhoodList = data;
      }
    }, error => {
    });
  }
  selectNeighborhoodOtherM(event) {
    if (event !== undefined) {
      this.selectNeighborhoodTemp = {};
      this.dataAddress.neighborhoodCode = event.cKey;
      this.streetOtherNew.neighborhoodCode = event.cKey;
    }
  }
  clearStreetOtherAndList() {
    this.dataAddress.street = null;
    this.clearStreetOtherList();

  }
  invokeServiceGetStreetsByColonieOther(data1, typeAddress) {
    this.api.getStreetsByColonie(data1).then((data: any) => {
      if (data != null) {
        this.streetsList = data;
      }
    }, error => {
    });
  }

  getSelectedSameAddress(event) {
    this.sameAddressFiscal = event.target.checked;
    this.allAddressDisabled(this.sameAddressFiscal);
    this.dataAddress.sameAddress = this.sameAddressFiscal;
    if (this.sameAddressFiscal === false) {
      this.dataAddress.country = '';
      this.dataAddress.zipCode = null;
      this.dataAddress.stateCode = null;
      this.dataAddress.municipalityCode = null;
      this.dataAddress.neighborhoodCode = null;
      this.dataAddress.street = '';
      // this.dataAddress.streetCode = null;
      this.dataAddress.stateCode = null;
      this.dataAddress.numExt = '';
      this.dataAddress.numInt = '';
      this.isNewStreetOther = false;
      this.invokeServiceGetStatesByCountry();
      this.clearStateOther();
    } else {

      this.isNewStreetOther = this.isNewStreetFiscal;
      this.dataAddress.country = this.dataAddressFiscal.country;
      this.dataAddress.zipCode = this.dataAddressFiscal.zipCode;
      this.dataAddress.stateCode = this.dataAddressFiscal.stateCode;
      // this.dataAddress.state = this.dataAddressFiscal.state;
      this.dataAddress.municipalityCode = this.dataAddressFiscal.municipalityCode;
      this.dataAddress.neighborhoodCode = this.dataAddressFiscal.neighborhoodCode;
      this.dataAddress.street = this.dataAddressFiscal.street;
      // this.dataAddress.streetCode = this.dataAddressFiscal.streetCode;
      this.dataAddress.numExt = this.dataAddressFiscal.numExt;
      this.dataAddress.numInt = this.dataAddressFiscal.numInt;

      this.loadCatalogDataAddress();
    }
  }

  allAddressDisabled(isDisabled: boolean) {
    if (isDisabled) {
      this.homeTypeHousingForm.get('country').disable();
      this.homeTypeHousingForm.get('state').disable();
      this.homeTypeHousingForm.get('municipalityCode').disable();
      this.homeTypeHousingForm.get('zipCode').disable();
      this.homeTypeHousingForm.get('neighborhood').disable();
      this.homeTypeHousingForm.get('street').disable();
      this.homeTypeHousingForm.get('numInt').disable();
      this.homeTypeHousingForm.get('numExt').disable();
    } else {
      this.homeTypeHousingForm.get('country').enable();
      this.homeTypeHousingForm.get('state').enable();
      this.homeTypeHousingForm.get('municipalityCode').enable();
      this.homeTypeHousingForm.get('zipCode').enable();
      this.homeTypeHousingForm.get('neighborhood').enable();
      this.homeTypeHousingForm.get('street').enable();
      this.homeTypeHousingForm.get('numInt').enable();
      this.homeTypeHousingForm.get('numExt').enable();
    }
  }
  loadCatalogDataAddress() {
    this.municipalityList = this.municipalityListFiscal;
    this.neighborhoodList = this.neighborhoodListFiscal;
  }
  converterToKeyValueClass(cKey: string, cValue: string): any {
    const keyValue = {cKey, cValue};
    return keyValue;
  }

  /* -----------------------------------------------------------------------------------------------------
   ------------------------D A T O S C A S A -----------------------------------------------
   ----------------------------------------------------------------------------------------------------- */
  invokeServiceGetTypeHousing() {
    this.api.getCatalogTypeHousing().then((data: any) => {
      this.typeHousingList = data;
    }, error => {
    });
  }
  invokeServiceGetClientsPolicy(id) {
    this.api.getClientsPolicy(id).then((data: any) => {
      this.catClientsPolicy = data;
    }, error => {
      this.catClientsPolicy = [];
    });
  }
  invokeServiceGetSecurityMeasure() {
    this.api.getCatalogDamagesSecurity().then((data: any) => {
      this.catSecurityMeasure = data;
    }, error => {
    });
  }
  invokeServiceGetFireProtection() {
    this.api.getCatalogDamagesFire().then((data: any) => {
      this.catFireProtection = data;
    }, error => {
    });
  }
  changeSecurityMeasure() {
    setTimeout(() => {
      try {
        const measure = [];
        this.securityMeasureSelectedItems.forEach(item => {
          measure.push(item.id);
        });
        // // console.log(executive1);
        this.dataAddressFiscal.securityMeasureList = measure;
      } catch (e) {
      }
    }, 500);
  }
  changeFireProtection() {
    setTimeout(() => {
      try {
        const fire = [];
        this.fireProtectionSelectedItems.forEach(item => {
          fire.push(item.id);
        });
        // // console.log(executive1);
        this.dataAddressFiscal.fireProtectionList = fire;
      } catch (e) {
      }
    }, 500);
  }
  getTypesHomeWallCeiling() {
    this.api.getCatalogDamagesWall().then((data: any) => {
      this.typeWallCeiling = data;
    }, error => {
    });
  }
  closeModal() {
    this.modalViewer.hide();
  }
  uploadedFilesMdl() {
    // console.log('entra : ');
    // console.log( this.fileUploadControlMdl);
    if (this.fileUploadControlMdl.invalid) {
      this.fileUploadControlMdl.getError().map(ERROR => {
        if (ERROR.fileSize !== undefined) {
          ERROR.fileSize.map(FILE => {
            this.toastr.warning( 'Se quita ' + FILE.file.name + '. El tamaño del archivo debe ser menor a 25 MB', 'Notificación');
            this.fileUploadControlMdl.removeFile(FILE.file);
          });
        } else if (ERROR.fileTypes !== undefined) {
          ERROR.fileTypes.map(FILE => {
            this.toastr.warning( 'Se quita ' + FILE.file.name + '.  Formato no válido, solo PDF, JPEG, JPG y PNG', 'Notificación');
            this.fileUploadControlMdl.removeFile(FILE.file);
          });
        }
      });
    }
  }
  invokeServiceUploadFilesMultiples() {
    this.spinner.show('sp');
    // console.log('total de archivos');
    const objDocument = [];
    let cont = 1;
    this.fileUploadControlMdl.value.forEach(FILE => {
      const documents = new Documents();
      this.getBase64(FILE).then(
        res => {
          const documentTmp = {
            orderFile: cont,
            name: FILE.name,
            b64: res.split(',')[1]
          };
          objDocument.push(documentTmp);
        }
      );
      cont = cont + 1;
    });
    // console.log(objDocument);
    setTimeout(() => {
      if (objDocument.length === 1) {
        this.modalAttachment.hide();
        this.dataAddressFiscal.nameFile = objDocument[0].name;
        this.dataAddressFiscal.pathFile = objDocument[0].b64;
        this.spinner.hide('sp');
        return;
      }
      this.api.postMergeDocument(objDocument)
        .then(
          (response: any) => {
            this.spinner.hide('sp');
            this.modalAttachment.hide();
            this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
            // tslint:disable-next-line:max-line-length
            this.dataAddressFiscal.nameFile = this.dataAddressFiscal.location === '' ? 'certificado.pdf' : this.dataAddressFiscal.location + '.pdf';
            this.dataAddressFiscal.pathFile = response.b64;
          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    }, 3000);
  }
  getBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }
  openUploadFilesMultiples(template: TemplateRef<any>) {
    this.formMdl.reset();
    this.fileUploadControlMdl.clear();
    this.modalAttachment = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
  }
  showDocumentViewer(item, template: TemplateRef<any>) {
    this.base64File2 = '';
    this.modalViewer = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
    this.spinner.show('sp');
    setTimeout(() => {
      this.base64File2 = item;
      this.spinner.hide('sp');
      // console.log(item);
    }, 1000);
  }


  invokeServiceSaveLocationHousing() {
    if (this.dataAddressFiscal.pathFile === '') {
      this.toastr.warning('POR FAVOR AGREGUE LA PÓLIZA', 'NOTIFIACIÓN');
      return;
    }
    this.spinner.show('sp');
    this.dataAddressFiscal.addressFiscal = this.dataAddress;
    this.dataAddressFiscal.policyId = this._policyId;
    console.log(this.dataAddressFiscal);
    this.api.putLocationHousing(this.dataAddressFiscal)
      .then(
        (response: any) => {
          this.modalRef.hide();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.spinner.hide('sp');
          if (this.dataAddressFiscal.streetNew) {
            const dataJson = {
              coloniesStreetsId: 0,
              neighborhoodCode: this.dataAddressFiscal.neighborhoodCode,
              nameStreet: this.dataAddressFiscal.street,
              status: true
            };
            this.api.postStreet(dataJson)
              .then(
                (response1: any) => {
                  console.log(response1);
                }, error => {
                }
              );
          }
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }


  openModalSaveEndorsement(template: TemplateRef<any>) {
    if (this.dataToSendGeneral.status === 1 || this.dataToSendGeneral.status === 4) {
      this.modalSaveEndorsement = this.modalService.show(template, {class: 'modal-lg', keyboard: false, animated: true});
    } else {
      this.saveData(true);
    }
  }
  saveData(save) {
    console.log('respuesta cierre endoso');
    this.invokeServiceSaveLocationHousing();
  }
  closeModalEndorsement() {
    this.modalSaveEndorsement.hide();
    this.saveData(true);
  }
}
