import { Component, TemplateRef, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-configuration-documents',
  templateUrl: './configuration-documents.component.html',
  styleUrls: ['./configuration-documents.component.css']
})
export class ConfigurationDocumentsComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig>;
  processList: any = [];
  subProcessList: any = [];
  configDocumentsList: any = [];
  processListTable: any = [];
  subProcessSelectedItems: any  = [];
  typeDocumentList: any = [];
  dropdownSettingsPoliciesLow = {};
  newDocument: any = {};
  processSelected = 0;
  titleModalDocument = 'ALTA DE NUEVO DOCUMENTO';
  titleButtonModalDocument = 'GUARDAR DOCUMENTO';
  isUpdate: boolean;
  namesSubProcessSelected: any = [];
  modalRef: BsModalRef;
  pgDocumentBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgDocumentTotalItems: number; // Total de registros
  pgDocumentCurrentPage = 1; // Página actual
  pgDocumentMaxSize = 3; // Número de links en paginado
  pgDocumentItemsPerPage: number;
  page = 1;
  size = 5;
  constructor(private api: ApiService,
              private modalService: BsModalService,
              private toastr: ToastrService,
              private validateAccess: ValidateAccessService) { }

  ngOnInit() {
    this.pgDocumentItemsPerPage = this.size; // Número de registros por página
    // this.invokeServiceGetProcess();
    // this.invokeServiceConfigDocuments();
    // configuracion select poliza baja
    this.dropdownSettingsPoliciesLow = {
     singleSelection: false,
     idField: 'id',
     textField: 'name',
     selectAllText: 'TODOS',
     unSelectAllText: 'NINGUNO',
     itemsShowLimit: 4,
     allowSearchFilter: true,
     noDataAvailablePlaceholderText: 'NO SE ENCONTRARON PÓLIZAS',
     searchPlaceholderText: 'BUSCAR'
    };
     // inicializan valores para documentos
    this.newDocument = {
      configurationTypeDocumentId: 0,
      configurationDocumentationId: 0,
      name: '',
      proccessId: 0,
      subproccessId: 0,
      status: 1,
      catTypeDocumentId: null,
      required: false,
      multiple: false
    };
    this.validateAccess.validateAccessCore(25).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getProcessByFilter();
      }
    });
  }
  invokeServiceGetProcess() {
    this.api.getProcess()
      .then(
        (data: any) => {
          console.log('getProcess');
          console.log(data);
          if (data != null) {
            this.processList = data;
          }
    }, error => {
        console.error(error.status);
        console.error(error.statusText);
        console.error(error.message);
    });
  }
  invokeServiceGetSubProcess(dataT) {
     this.api.getSubProcess(dataT)
       .then(
         (data: any) => {
              if (data != null) {
                this.subProcessList = data;
               }
             }, error => {
               console.error(error.status);
               console.error(error.statusText);
               console.error(error.message);
             });
  }
  invokeServiceConfigDocuments(id) {
     this.api.getConfigDocumentsById(id)
       .then(
         (data: any) => {
               if (data != null) {
                this.configDocumentsList = data;
                if (!this.isUpdate) {
                  this.newDocument.configurationDocumentationId = -1;
                }
               }
             }, error => {
               console.error(error.status);
               console.error(error.statusText);
               console.error(error.message);
             });
  }
  invokeServiceGetProcessByFilters(dataT) {
    this.api.getProcessByFilter(dataT)
      .then(
        (data: any) => {
         if (data != null) {
          this.processListTable = data;
          this.pgDocumentTotalItems = data[0].total;
         } else {
          this.processListTable = [];
          this.pgDocumentTotalItems = 0;
         }
      }, error => {
       console.error(error.status);
       console.error(error.statusText);
       console.error(error.message);
    });
  }
  invokeServiceGetTypesDocuments(id) {
    if (id === 0) {
      this.api.getTypesDocuments()
        .then(
          (data: any) => {
            if (data != null) {
              this.typeDocumentList = data;
            }
          }, error => {
            console.error(error.status);
            console.error(error.statusText);
            console.error(error.message);
          });
    } else {
      this.api.getTypesDocumentsByConfiguration(id)
        .then(
          (data: any) => {
            if (data != null) {
              this.typeDocumentList = data;
            }
          }, error => {
            console.error(error.status);
            console.error(error.statusText);
            console.error(error.message);
          });
    }
  }
  invokeServiceSaveDocument(dataT) {
    this.api.saveDocument(dataT)
      .then(
        (data: any) => {
           if (data > 0) {
            this.toastr.success('Operación realizada con éxito', 'Notificación');
            this.processList = [];
            this.invokeServiceGetProcess();
            this.modalRef.hide();

           } else if (data === 0) {
             this.toastr.warning('La configuración de documento ya existe. ! Verifique los datos', 'Notificación');
             this.processList = [];
             this.invokeServiceGetProcess();
           }
         }, error => {
           this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
           console.error(error.status);
           console.error(error.statusText);
           console.error(error.message);
    });
  }
  invokeServiceDeleteDocument(dataT) {
      this.api.deleteDocumentProccess(dataT)
        .then(
          (data: any) => {
            this.toastr.success('Operación realizada con éxito', 'Notificación');
            this.getProcessByFilter();
         }, error => {
           this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
           console.error(error.status);
           console.error(error.statusText);
           console.error(error.message);
          });
    }
  invokeServiceUpdateDocument(dataT) {
      this.api.updateDocumentProccess(dataT)
        .then(
          (data: any) => {
            if (data > 0) {
              this.toastr.success('Operación realizada con éxito', 'Notificación');
            } else if (data === 0) {
               this.toastr.warning('La configuración de documento ya existe. ! Verifique los datos', 'Notificación');
            }
            this.getProcessByFilter();
          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
            console.error(error.status);
            console.error(error.statusText);
            console.error(error.message);
          });
  }
  getProcessSelected(item) {
    this.subProcessSelectedItems = [];
    this.subProcessList = [];
    const param = {
          id: item.target.value
        };
    this.invokeServiceGetSubProcess(param);
  }
  getSubProcessSelected(item) {
    console.log('item');
    console.log(item);
    this.subProcessSelectedItems = [];
    this.subProcessSelectedItems.push(item.target.value);
    console.log('subProccessSelectedItems');
    console.log(this.subProcessSelectedItems);
  }
  getProcessSelectedNew(item) {
    this.newDocument.subproccessId = 0;
    this.subProcessList = [];
    const param = {
      id: item.target.value
    };
    this.invokeServiceGetSubProcess(param);
  }
  getConfigurationBySubProcess(id) {
    console.log(id);
    this.invokeServiceConfigDocuments(id);
  }
  getDocumentsByConfiguration(item) {
    console.log('load documents by configurations');
    console.log(item);
    this.invokeServiceGetTypesDocuments(item);
  }
  getProcessByFilter() {
    this.pgDocumentCurrentPage = 1;
    const data = {
      processId: this.processSelected,
      subProcessList: this.subProcessSelectedItems,
      page: this.page,
      size: this.size
    };
    this.validateAccess.validateAccessCore(25).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.invokeServiceGetProcessByFilters(data);
      }
    });
  }
  processDocument() {
    if (this.validateCampos(this.newDocument)) {
       if (this.isUpdate) {
           this.invokeServiceUpdateDocument(this.newDocument);
       } else {
           this.invokeServiceSaveDocument(this.newDocument);
       }
       this.getProcessByFilter();
    } else {
      this.toastr.warning('Ingrese todos los campos requeridos', 'Notificación');
    }

  }
  deleteDocument(item, isHabilitation) {
    this.validateAccess.validateAccessCore(28).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        if (isHabilitation) {
          item.status = 1;
        } else {
          item.status = 2;
        }
        this.invokeServiceDeleteDocument(item);
      }
    });
  }

  /************************************************** APERTUTA DE MODALES *****************************************/
  openModalNewOrUpdateDocument(template: TemplateRef<any>, item) {
    let accessCore = 0;
    if (item === null) {
      accessCore = 26;
    } else {
      accessCore = 27;
    }
    this.validateAccess.validateAccessCore(accessCore).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.isUpdate = false;
        this.typeDocumentList = [];
        this.processList = [];
        this.subProcessList = [];
        this.invokeServiceGetProcess();
        this.titleModalDocument = 'ALTA DE NUEVO DOCUMENTO';
        this.titleButtonModalDocument = 'NUEVO DOCUMENTO';
        this.newDocument = {
          configurationTypeDocumentId: 0,
          configurationDocumentationId: 0,
          name: '',
          proccessId: 0,
          subproccessId: 0,
          status: 1,
          catTypeDocumentId: null,
          required: false,
          multiple: false
        };
        if (item !== null) {
          this.isUpdate = true;
          this.titleModalDocument = 'ACTUALIZACIÓN DE DOCUMENTO';
          this.titleButtonModalDocument = 'ACTUALIZAR';
          this.newDocument = item;
          console.log(this.newDocument);
          const param = {
            id: item.proccessId
          };
          this.invokeServiceGetSubProcess(param);
          this.getConfigurationBySubProcess(this.newDocument.subproccessId);
          this.invokeServiceGetTypesDocuments(0);
          console.log(this.newDocument);
        }
        this.modalRef = this.modalService.show(template);
      }
    });
  }
  getClientsChangePage(pageT, sizeT) {
        const data = {
          processId: this.processSelected,
          subProcessList : this.subProcessSelectedItems,
          page: pageT,
          size: sizeT
        };
        console.log(data);
        this.invokeServiceGetProcessByFilters(data);
  }
  /***  PAGINACION **/
  pageDocumentChanged(event: PageChangedEvent): void {
      this.pgDocumentCurrentPage = event.page;
      this.getClientsChangePage(this.pgDocumentCurrentPage, this.pgDocumentItemsPerPage);
    }
  changeColorPagination() {}
  cleanFilter() {
    this.pgDocumentCurrentPage = 1;
    this.processSelected = 0;
    this.subProcessSelectedItems = [];
    this.processList = [];
    this.subProcessList = [];
    const data = {
      nameProcess: '',
      nameSubProcess: [],
      page: this.page,
      size: this.size
    };
    this.validateAccess.validateAccessCore(25).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getProcessByFilter();
        this.invokeServiceGetProcess();
      }
    });
  }
  validateCampos(data) {
    if (data.configurationDocumentationId === null
      || data.proccessId === 0
      || data.subproccessId === 0
      || data.catTypeDocumentId === null) {
        return false;
      }
    return true;
  }
}
