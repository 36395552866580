import {Component, HostListener, OnInit} from '@angular/core';
import {SharedService} from '../shared-service.service';
import {QuoteService} from '../quote.service';

@Component({
  selector: 'app-quote-history',
  templateUrl: './quote-history.component.html',
  styleUrls: ['./quote-history.component.css']
})
export class QuoteHistoryComponent implements OnInit {
  search: string;
  quotes: any = [];
  pageSize = 10;
  quotePage = 1;
  widthSize: number = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.widthSize = window.innerWidth;
  }
  constructor(public shared: SharedService,
              private quoteApi: QuoteService) { }

  ngOnInit() {
    this.search = '';
    this.invokeServiceForGetQuotes(this.quotePage);
  }

  public onScroll() {
    if (100 === this.quotes.length) {
      return;
    }
    this.quotePage++;
    this.invokeServiceForGetQuotes(this.quotePage);
  }

  public invokeServiceForSearch() {
    this.quotes = [];
    this.invokeServiceForGetQuotes(1);
  }

  public invokeServiceForGetQuotes(page) {
    this.quoteApi.getQltQuotesSearch(this.search, page, this.pageSize)
      .subscribe(
        (response: any = {}) => {
          // this.quotes = response;
          this.quotes = this.quotes.concat(response);
        }, error => {
          console.info(error);
        }
      );
  }

  public displayQuoteDetail(item) {
    this.shared.gQuote = item;
    if (item.idCatStatus === 3) {
      this.shared.fCloseSidebar();
      setTimeout(() => {
        this.shared.fOpenSidebar(14, (this.widthSize <= 768) ? 'full' : 'md');
      }, 700);
    } else {
      this.shared.fCloseSidebar();
      setTimeout(() => {
        this.shared.fOpenSidebar(9, (this.widthSize <= 768) ? 'full' : 'md');
      }, 700);
    }
  }
}
