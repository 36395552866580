import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';

import { EnvioProgramadoComponent } from '../dialogs/envio-programado/envio-programado.component';
import { VerReporteComponent } from '../dialogs/ver/ver.component';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';


@Component({
  selector: 'app-dinamic-reports',
  templateUrl: './dinamic-reports.component.html',
  styleUrls: ['./dinamic-reports.component.css']
})
export class DinamicReportsComponent implements OnInit {

    // reportesList: any = [
    //     {
    //         name: 'Producción dinamica',
    //         description: 'Producción dinamica',
    //         scheduled: true,
    //         inDashboard: true,
    //         classification: 'Producción'
    //     },
    //     {
    //         name: 'Ventas nuevas por Trimestre',
    //         description: 'Ventas nuevas por Trimestre',
    //         scheduled: true,
    //         inDashboard: true,
    //         classification: 'Producción'
    //     },
    //     {
    //         name: 'Cobranza por Periodo',
    //         description: 'Cobranza por Periodo',
    //         scheduled: true,
    //         inDashboard: true,
    //         classification: 'Cobranza'
    //     },
    //     {
    //         name: 'Siniestralidad por Ramo',
    //         description: 'Siniestralidad por Ramo',
    //         scheduled: true,
    //         inDashboard: true,
    //         classification: 'Siniestralidad'
    //     },
    // ];

    reportesList: any;
    pgClientBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
    pgClientTotalItems: number = 5; // Total de registros
    pgClientCurrentPage = 1; // Página actual
    pgClientMaxSize = 3; // Número de links en paginado
    pgClientItemsPerPage: number = 3;
    page = 0;
    size = 10;

    constructor(
        public shared: SharedService,
        private toastr: ToastrService,
        private api: ApiService,
        private spinner: NgxSpinnerService,
        private modalService: BsModalService,
    ) {
    }

    ngOnInit() {
        this.loadReports();
    }

    loadReports(): void {
        const params = {
            page: this.page,
            size: this.size
        };
        this.api.getDynamicReports(params).subscribe(response => {
            this.reportesList = response;
        },
        err => {
            console.log(err);
        });
    }

    changeColorPagination() {
    }

    pageClientChanged(event: PageChangedEvent): void {
        this.page = event.page;
        this.loadReports();
    }

    openEnvioProgramado(event: any, report: any): void {
        if (event) {
            const modalRef = this.modalService.show(EnvioProgramadoComponent, { initialState: { dynamicReportId: report.dynamicReportId }});
            modalRef.content.onClose.subscribe(data => {
                if (data.action === 'save' || data.action === 'update') {
                    this.loadReports();
                } else {
                    this.loadReports();
                }
                modalRef.hide();
            });
        } else {
            this.api.updateDynamicReportNoSchedule({ dynamicReportId: report.dynamicReportId }).subscribe(response => {
            },
            err => {
                console.log(err);
            });
        }
    }

    openVerReporte(): void {
        const modalRef = this.modalService.show(VerReporteComponent, { class: 'modal-lg' });
        modalRef.content.onClose.subscribe(data => {
            modalRef.hide();
        });
    }

    confirmEliminar(j: number, item: any): void {
        const modalRef = this.modalService.show(ConfirmComponent, { });
        modalRef.content.textConfirm = 'Esta seguro de eliminar el reporte';

        modalRef.content.onClose.subscribe(data => {
            modalRef.hide();
            if (data.res) {
                this.api.deleteDynamicReport(item).subscribe(response => {
                    this.loadReports();
                },
                err => {
                    console.log(err);
                });
            }
        });

    }
}
