import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {DatePipe} from '@angular/common';
import {ValidateAccessService} from '../validate-access.service';

@Component({
  selector: 'app-graph-amount',
  templateUrl: './graph-amount.component.html',
  styleUrls: ['./graph-amount.component.css']
})
export class GraphAmountComponent implements OnInit {

  // -Varibles para paginado de usuarios->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filtersHistogram: any = [];
  modalRef: BsModalRef;

  barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        }
      }
    }
  };
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];
  barChartData: ChartDataSets[] = [];
  barChartColors: Color[] = [
      { backgroundColor: 'rgba(68, 136, 166, 1)'},
      { backgroundColor: 'rgba(50, 97, 115, 1)'},
      { backgroundColor: 'rgba(1, 24, 38, 1)'},
      { backgroundColor: 'rgba(128, 128, 128, 1)'},
      { backgroundColor: 'rgba(67, 101, 196, 1)'},
      { backgroundColor: 'rgba(153, 153, 153, 1)'},
      { backgroundColor: 'rgba(91, 105, 142, 1)'},
      { backgroundColor: 'rgba(166, 166, 166, 1)'},
      { backgroundColor: 'rgba(126, 145, 196, 1)'},
      { backgroundColor: 'rgba(230, 230, 230, 1)'},
      { backgroundColor: 'rgba(140, 161, 219, 1)'},
      { backgroundColor: 'rgba(54, 54, 54, 1)'}
  ];

  private _filters: any = {};
  reportProductionList: any = [];

  constructor(private api: ApiService,
              private toastr: ToastrService,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private datePipe: DatePipe,
              private validateAccess: ValidateAccessService) {

  }

  ngOnInit() {


  }

  loadChart() {
    this.spinner.show('sp');
    this.api.getReportsAmountGraphic(this._filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.reportProductionList = [];
            this.spinner.hide('sp');
            return;
          }
          this.reportProductionList = response;
          this.creatChart();
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.reportProductionList = [];
          this.spinner.hide('sp');
        }
      );
  }

  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    const fecha = new Date();
    const actualYear = fecha.getFullYear();
    const dateStart = '2020-01-01';
    const dateEnd = actualYear.toString().concat('-12-31');

    this._filters = {
      idGrupo: [],
      idSubgrupo: [],
      idRamo: [],
      idSubRamo: [],
      idEjecutivo: [],
      idSalesman: [],
      idAseguradora: [],
      dateStart,
      dateEnd
    };

    this.loadChart();
  }

  creatChart() {
    // Obtenemos las etiquetas agrupadoras
    this.reportProductionList.forEach(X => {
      if (this.barChartLabels.length === 0) {
        this.barChartLabels.push(X.year);
      } else {
        if (!this.barChartLabels.some(Y => Y === X.year)) {
          this.barChartLabels.push(X.year);
        }
      }
    });

    const enero = {
      data: [],
      label: 'Ene'
    };
    const febrero = {
      data: [],
      label: 'Feb'
    };
    const marzo = {
      data: [],
      label: 'Mar'
    };
    const abril = {
      data: [],
      label: 'Abr'
    };
    const mayo = {
      data: [],
      label: 'May'
    };
    const junio = {
      data: [],
      label: 'Jun'
    };
    const julio = {
      data: [],
      label: 'Jul'
    };
    const agosto = {
      data: [],
      label: 'Ago'
    };
    const septiembre = {
      data: [],
      label: 'Sept'
    };
    const octubre = {
      data: [],
      label: 'Oct'
    };
    const noviembre = {
      data: [],
      label: 'Nov'
    };
    const diciembre = {
      data: [],
      label: 'Dic'
    };

    let seriesCount;
    this.barChartLabels.forEach( L => {
      if (seriesCount === undefined) {
        seriesCount = 0;
      } else {
        seriesCount++;
      }
      this.reportProductionList.forEach(X => {
        if (L === X.year) {
          switch (X.month) {
            case 1:
              if (seriesCount === enero.data.length) {
                enero.data.push(X.total);
              } else {
                enero.data = this.addNullValues(enero.data, seriesCount);
                enero.data.push(X.total);
              }
              break;
            case 2:
              if (seriesCount === febrero.data.length) {
                febrero.data.push(X.total);
              } else {
                febrero.data = this.addNullValues(febrero.data, seriesCount);
                febrero.data.push(X.total);
              }
              break;
            case 3:
              if (seriesCount === marzo.data.length) {
                marzo.data.push(X.total);
              } else {
                marzo.data = this.addNullValues(marzo.data, seriesCount);
                marzo.data.push(X.total);
              }
              break;
            case 4:
              if (seriesCount === abril.data.length) {
                abril.data.push(X.total);
              } else {
                abril.data = this.addNullValues(abril.data, seriesCount);
                abril.data.push(X.total);
              }
              break;
            case 5:
              if (seriesCount === mayo.data.length) {
                mayo.data.push(X.total);
              } else {
                mayo.data = this.addNullValues(mayo.data, seriesCount);
                mayo.data.push(X.total);
              }
              break;
            case 6:
              if (seriesCount === junio.data.length) {
                junio.data.push(X.total);
              } else {
                junio.data = this.addNullValues(junio.data, seriesCount);
                junio.data.push(X.total);
              }
              break;
            case 7:
              if (seriesCount === julio.data.length) {
                julio.data.push(X.total);
              } else {
                julio.data = this.addNullValues(julio.data, seriesCount);
                julio.data.push(X.total);
              }
              break;
            case 8:
              if (seriesCount === agosto.data.length) {
                agosto.data.push(X.total);
              } else {
                agosto.data = this.addNullValues(agosto.data, seriesCount);
                agosto.data.push(X.total);
              }
              break;
            case 9:
              if (seriesCount === septiembre.data.length) {
                septiembre.data.push(X.total);
              } else {
                septiembre.data = this.addNullValues(septiembre.data, seriesCount);
                septiembre.data.push(X.total);
              }
              break;
            case 10:
              if (seriesCount === octubre.data.length) {
                octubre.data.push(X.total);
              } else {
                octubre.data = this.addNullValues(octubre.data, seriesCount);
                octubre.data.push(X.total);
              }
              break;
            case 11:
              if (seriesCount === noviembre.data.length) {
                noviembre.data.push(X.total);
              } else {
                noviembre.data = this.addNullValues(noviembre.data, seriesCount);
                noviembre.data.push(X.total);
              }
              break;
            case 12:
              if (seriesCount === diciembre.data.length) {
                diciembre.data.push(X.total);
              } else {
                diciembre.data = this.addNullValues(diciembre.data, seriesCount);
                diciembre.data.push(X.total);
              }
              break;
          }
        }
      });
    });

    if (enero.data.length > 0) {
      this.barChartData.push(enero);
    }
    if (febrero.data.length > 0) {
      this.barChartData.push(febrero);
    }
    if (marzo.data.length > 0) {
      this.barChartData.push(marzo);
    }
    if (abril.data.length > 0) {
      this.barChartData.push(abril);
    }
    if (mayo.data.length > 0) {
      this.barChartData.push(mayo);
    }
    if (junio.data.length > 0) {
      this.barChartData.push(junio);
    }
    if (julio.data.length > 0) {
      this.barChartData.push(julio);
    }
    if (agosto.data.length > 0) {
      this.barChartData.push(agosto);
    }
    if (septiembre.data.length > 0) {
      this.barChartData.push(septiembre);
    }
    if (octubre.data.length > 0) {
      this.barChartData.push(octubre);
    }
    if (noviembre.data.length > 0) {
      this.barChartData.push(noviembre);
    }
    if (diciembre.data.length > 0) {
      this.barChartData.push(diciembre);
    }
  }

  addNullValues(data: any[], space: number): any[] {
    for (let i = data.length; i < space; i++) {
      data.push(undefined);
    }
    return data;
  }
  get filters(): any {
    return this._filters;
  }

  chartClicked(template: TemplateRef<any>, e: any): void {
    this.validateAccess.validateAccessCore(2).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
      } else {
        if (e.active.length > 0) {
          const chart = e.active[0]._chart;
          const activePoints = chart.getElementAtEvent(e.event);

          if ( activePoints.length > 0) {

            const clickedElementIndex = activePoints[0]._index;
            const year = chart.data.labels[clickedElementIndex];
            const month = (this.getNumberMonth(this.barChartData[activePoints[0]._datasetIndex].label) - 1);

            this.filtersHistogram = {
              idGrupo: [],
              idSubgrupo: [],
              idRamo: [],
              idSubRamo: [],
              idEjecutivo: [],
              idSalesman: [],
              idStatus: [],
              idAseguradora: [],
              dateStart: this.datePipe.transform(new Date(year, month, 1), 'yyyy-MM-dd'),
              dateEnd: this.datePipe.transform(new Date(year, month + 1, 0), 'yyyy-MM-dd'),
              page: 1,
              size: 10,
            };

            this.getByPagination(template, 1, 10);


          }
        }
      }
    });
  }

  getNumberMonth(month: string): number {
    switch (month) {
      case 'Ene':
        return 1;
        break;
      case 'Feb':
        return 2;
        break;
      case 'Mar':
        return 3;
        break;
      case 'Abr':
        return 4;
        break;
      case 'May':
        return 5;
        break;
      case 'Jun':
        return 6;
        break;
      case 'Jul':
        return 7;
        break;
      case 'Ago':
        return 8;
        break;
      case 'Sep':
        return 9;
        break;
      case 'Oct':
        return 10;
        break;
      case 'Nov':
        return 11;
        break;
      case 'Dic':
        return 12;
        break;
    }
  }
  /**
   * Función para obtener las pólizas  para reportear del sistema
   */
  getByPagination(template: TemplateRef<any>, page, size) {
    this.spinner.show('sp');
    this.filtersHistogram.page = page;
    this.filtersHistogram.size = size;
    this.api.getReportsProduccion(this.filtersHistogram)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTS CON SU BUSQUEDA');
            this.reportProductionList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.reportProductionList = this.paginationResponse.tList;
          if (template != null) {
            this.openModalRestorePassword(template);
          }
          this.spinner.hide('sp');
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.reportProductionList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }

  openModalRestorePassword(template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }

  @Input()
  set filters(value: any) {
    this._filters = value;
    if (this._filters.dateStart === null){
      const fecha = new Date();
      const actualYear = fecha.getFullYear();
      const dateStart = actualYear.toString().concat('-01-01');
      const dateEnd = actualYear.toString().concat('-12-31');

      this._filters.dateStart = dateStart;
      this._filters.dateEnd = dateEnd;
    }
    this.barChartData = [];
    this.barChartLabels = [];
    this.loadChart();
  }

  /**
   * Carga los datos según la página seleccionada
   */
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(null, this.pgCurrentPage, this.pgItemsPerPage);
  }
}
