import {Component, HostListener, OnInit} from '@angular/core';
import {SharedService} from '../shared-service.service';
import {QuoteService} from '../quote.service';

@Component({
  selector: 'app-quote-detail',
  templateUrl: './quote-detail.component.html',
  styleUrls: ['./quote-detail.component.css']
})
export class QuoteDetailComponent implements OnInit {

  quoteData: any = [];
  isIssued = false;
  widthSize: number = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.widthSize = window.innerWidth;
  }
  constructor(public shared: SharedService,
              private quoteApi: QuoteService) { }

  ngOnInit() {
    this.invokeServiceForGetQuoteDetail();
  }

  private invokeServiceForGetQuoteDetail() {
    /*this.quoteApi.getQuoteById(this.shared.gQuote.quoteId, this.shared.gQuote.insurerId)
      .subscribe(
        (response: any = {}) => {
          this.quoteData = response;
          this.invokeServiceForGetCoverages();
        }, error => {
          console.info(error);
        }
      );*/

    this.quoteApi.getQuotesByUUID(this.shared.gQuote.uuid)
      .subscribe(
        (response: any = {}) => {
          this.quoteData = response;
          for (const item of this.quoteData) {
            if (item.idCatStatus === 3) {
              this.isIssued = true;
            }
          }
          this.invokeServiceForGetCoverages();
        }, error => {
          console.info(error);
        }
      );
  }

  private invokeServiceForGetCoverages() {
    for (const item of this.quoteData) {
      this.quoteApi.getCoveragesByQuoteId(item.quoteId, item.insurerId)
        .subscribe(
          (response: any = {}) => {
            item.coverages = response;
          }, error => {
            console.error(error);
          }
        );
    }
  }

  public goToIssueThisQuote(quoteItem) {
    this.shared.gQuote = quoteItem;
    this.shared.fCloseSidebar();
    setTimeout( () => {
      this.shared.fOpenSidebar(13, (this.widthSize <= 768) ? 'full' : 'lg');
    }, 700);
  }

}
