import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
const API_REST_ADMIN = environment.api_rest_admin_surexs;

@Injectable({
  providedIn: 'root'
})
export class ValidateAccessService {
  accessCore: number;
  constructor(private api: ApiService,
              private http: HttpClient) { }

  messageTitle(): string {
    return 'RESTRICCIÓN DE ACCESO';
  }
  messageNotification(): string {
    return 'LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD';
  }

  validateAccessCore(id: number) {
    const dataAccess = {
      userId: parseInt(localStorage.getItem('user-login-id'), 10),
      roleId: parseInt(localStorage.getItem('roleId'), 10),
      actionId: id
    };
    console.log(dataAccess);
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/directories/roles/validations', dataAccess ).subscribe( res => {
      // this.http.post('http://localhost:7010/api/core/directories/roles/validations', dataAccess ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


}
