import {Component, EventEmitter, OnInit, Output, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {regex} from '../../common/regex';
import {ValidateAccessService} from "../validate-access.service";


@Component({
  selector: 'app-admin-endorsement-new',
  templateUrl: './admin-endorsement-new.component.html',
  styleUrls: ['./admin-endorsement-new.component.css']
})
export class AdminEndorsementNewComponent implements OnInit {
  modalRef: BsModalRef;
  modalOption: BsModalRef;
  modalAddOption: BsModalRef;
  endorsement: any = {};
  coverageForm: FormGroup; // Formulario
  typeList: any = [];
  typeValueList: any = [];
  typeOptionId: number;
  typeValueId: number;
  subActivity: string;
  subActivityList: any = [];
  catOptions: any = [];
  optionsSelectedItems: any = [];
  dropdownSettingsOptions = {};
  optionNg: string;
  optionNgList: any = [];
  myFormOptions: FormGroup;
  optionName: string;
  typeOptionEndorsement: number;
  endorsementCoincidenceList: any = [];
  filters: any = {};
  @Output() public resultEndorsement = new EventEmitter<any>();
  constructor(private modalService: BsModalService,
              private toastr: ToastrService,
              private shared: SharedService,
              private api: ApiService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              public fb: FormBuilder,
              private validateAccess: ValidateAccessService) {
    this.createFormNew();
    this.myFormOptions = this.fb.group({
      ong: ['']
    });
  }

  ngOnInit() {
    this.dropdownSettingsOptions = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON OPCIONES',
      searchPlaceholderText: 'BUSCAR'
    };
  }

  openModal(template: TemplateRef<any>) {
    this.validateAccess.validateAccessCore(58).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.spinner.show('sp');
        this.functionLoadEndorsementInit();
        this.spinner.hide('sp');
        // console.log(this.endorsement);
        this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
      }
    });
  }

  openModalOption(template: TemplateRef<any>, id) {
    this.subActivityList = [];
    this.optionName = undefined;
    this.typeOptionEndorsement = id;
    this.spinner.show('sp');
    // this.functionLoadEndorsementInit();
    this.getLoadDataTypesOptions();
    this.typeOptionId = 0;
    this.typeValueId = 0;
    // this.getLoadDataTypeValues();
    this.spinner.hide('sp');
    this.modalOption = this.modalService.show(template, {class: 'modal-md', keyboard: false, backdrop: 'static'});
  }

  openAddOption(template: TemplateRef<any>, id) {
    this.modalAddOption = this.modalService.show(template, {class: 'modal-sm', keyboard: false, backdrop: 'static'});
  }

  functionLoadEndorsementInit() {
    this.endorsement = {
      id: 0,
      name: '',
      description: '',
      level: 0,
      descriptionCoverageList:
        [
          {
            id: 0,
            name: 'LIMITE DE INDEMNIZACION',
            parent: 0,
            valueList: []
          },
          {
            id: 0,
            name: 'DEDUCIBLE',
            parent: 0,
            valueList: []
          }
        ]
    };
  }
  createFormNew() {
    // Se inicializa el formulario
    this.coverageForm = new FormGroup(
      {
        id: new FormControl({value: 0, disabled: false}),
        name: new FormControl(null, [
          Validators.maxLength(100),
          Validators.required,
          Validators.pattern(regex.alfanumericoConGuiones)
        ]),
        description: new FormControl(null, [
          Validators.maxLength(300),
          Validators.required,
          Validators.pattern(regex.alfanumericoConGuiones)
        ])
      }
    );
  }
  validateCoincidence() {
    if (this.endorsement.descriptionCoverageList[0].valueList.length === 0 &&
      this.endorsement.descriptionCoverageList[1].valueList.length === 0) {
      this.toastr.warning('DEBE DE AGREGAR AL MENOS UNA OPCIÓN');
      return;
    }
    // console.log(this.endorsement);
    this.spinner.show('sp');
    this.api.saveEndorsementCivilWork(this.endorsement)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('SE GUARDADO CORRECTAMENTE LA INFORMACIÓN', 'NOTIFICACIÓN');
          this.resultEndorsement.emit('cierre');
          this.modalRef.hide();
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  getLoadDataTypesOptions() {
    this.typeList = [
      {
        typeOptionId: 1,
        name: 'CAMPO ABIERTO'
      },
      {
        typeOptionId: 2,
        name: 'VALOR y UNIDAD'
      },
      {
        typeOptionId: 3,
        name: 'LISTA/OPCIONES'
      }
      ];
  }
  onChangeTypeOption(e) {
    // console.log(e);
    // this.typeOptionId = e.typeOptionId;
    this.typeValueId = 0;
    switch (e.name) {
      case 'CAMPO ABIERTO':
        this.typeValueList = [
          {
            id: 1,
            name: 'TEXTO'
          }];
        this.typeValueId = 1;
        break;
      case 'VALOR y UNIDAD':
        this.typeValueList = [
          {
            id: 2,
            name: 'NUMERO'
          },
          {
            id: 3,
            name: 'MONEDA'
          }
        ];
        break;
      case 'LISTA/OPCIONES':
        this.typeValueList = [
          {
            id: 1,
            name: 'TEXTO'
          }];
        this.typeValueId = 1;
        break;
    }
  }


  addNewValue() {
    if (this.optionName === undefined || this.typeOptionId === 0 || this.typeValueId === 0 ) {
      this.toastr.warning('DEBE DE INGRESAR TODOS LOS DATOS');
      return;
    }
    if (this.typeValueId === 2 || this.typeValueId === 3) {
      if (this.optionsSelectedItems.length === 0) {
        this.toastr.warning('DEBE DE SELECCIONAR AL MENOS UNA OPCIÓN DE MEDIDA');
        return;
      }
    }
    let typeVisibleTmp = 0;
    switch (this.typeOptionId) {
      case 1:
        typeVisibleTmp = 1;
        break;
      case 2:
        typeVisibleTmp = 1;
        break;
      case 3:
        typeVisibleTmp = 2;
        break;
    }
    let typeValueTmp = '';
    switch (this.typeValueId) {
      case 1:
        typeValueTmp = 'text';
        break;
      case 2:
        typeValueTmp = 'number';
        break;
      case 3:
        typeValueTmp = 'currency';
        break;
    }
    // agregamos la opción
    const objValue = {
      id: Math.floor(Math.random() * 100),
      description: this.optionName,
      value: '',
      medition: '',
      options: this.subActivityList,
      typeVisible: typeVisibleTmp,
      typeValue: typeValueTmp,
      disabledField: false
    };
    this.endorsement.descriptionCoverageList[this.typeOptionEndorsement].valueList.push(objValue);
    this.modalOption.hide();
    // console.log(this.endorsement);
  }

  onChangeTypeValue(e) {
    // console.log(e);
    this.optionsSelectedItems = [];
    switch (e.name) {
      case 'NUMERO':
        this.catOptions = [
          {
            id: 1,
            name: '%'
          }
        ];
        break;
      case 'MONEDA':
        this.catOptions = [
          {
            id: 1,
            name: 'PESOS MEXICANOS'
          },
          {
            id: 2,
            name: 'USD'
          },
          {
            id: 3,
            name: 'UMAM'
          }
        ];
        break;
    }
  }

  addSubActivity() {
    if (this.subActivity === undefined) {
      this.toastr.warning('DEBE DE INGRESAR LA OPCIÓN', 'NOTIFICACION');
      return;
    }
    this.subActivityList.push(this.subActivity);
    this.subActivity = undefined;
  }

  deleteSubActivity(activity: string) {
    const newArray = this.subActivityList.filter(item => {
      return item !== activity;
    });
    this.subActivityList = newArray;
  }

  changeOptions() {
    const options = [];
    setTimeout(() => {
      try {
        this.optionsSelectedItems.forEach(item => {
          options.push(item.name);
        });
        this.subActivityList = options;
      } catch (e) {
      }
    }, 1000);
  }


  addOption() {
    if (this.optionNg === undefined) {
      this.toastr.warning('DEBE DE INGRESAR LA OPCIÓN', 'NOTIFICACION');
      return;
    }
    this.optionNgList.push(this.optionNg);
    this.optionNg = undefined;
  }

  deleteOptionNg(op: string) {
    const newArray = this.optionNgList.filter(item => {
      return item !== op;
    });
    this.optionNgList = newArray;
  }

  AddOptionToList() {
    this.spinner.show('sp');
    const total = this.optionNgList.length;
    const objList = this.catOptions;
    this.catOptions = [];
    objList.forEach(item => {
      this.catOptions.push(item);
    });
    for (let c = 0; c < this.optionNgList.length; c ++) {
      const obj = {
        id: c + total + 1,
        name: this.optionNgList[c]
      };
      this.catOptions.push(obj);
    }
    setTimeout(() => {
      this.modalAddOption.hide();
      this.spinner.hide('sp');
    }, 1000);
  }

  deleteOption(option, id) {
    this.spinner.show('sp');
    const newArray = this.endorsement.descriptionCoverageList[id].valueList.filter(item => {
      return item.id !== option.id;
    });
    setTimeout(() => {
      this.endorsement.descriptionCoverageList[id].valueList = [];
      this.endorsement.descriptionCoverageList[id].valueList = newArray;
      this.spinner.hide('sp');
    }, 1000);
  }

  getEndorsementCoincidence() {
    this.spinner.show('sp');
    this.filters = {
      search: this.endorsement.name,
      branchId: 2,
      subBranchId: 18,
      page: 1,
      size: 100
    };
    this.api.getEndorsementsCoincidence(this.filters)
      .then(
        (response: any) => {
          if (response !== null) {
            this.endorsementCoincidenceList = response;
          } else {
            this.endorsementCoincidenceList = [];
          }
          this.spinner.hide('sp');
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
}
