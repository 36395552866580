import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'confirm',
    templateUrl: 'confirm.component.html'
})
export class ConfirmComponent implements OnInit {

    @Input()
    textConfirm: string = 'Esta seguro?';

    public onClose: Subject<any>;

    constructor(
        public bsModalRef: BsModalRef,
    ) {
    }

    ngOnInit(): void {
        this.onClose = new Subject();
    }

    close(): void {
        this.onClose.next({ res: false });
    }

    Ok(): void {
        this.onClose.next({ res: true });
    }

}
