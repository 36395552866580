import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from "@angular/router";

@Component({
  selector: 'app-modal-issue',
  templateUrl: './modal-issue.component.html',
  styleUrls: ['./modal-issue.component.css']
})
export class ModalIssueComponent implements OnInit {
  modalRef: BsModalRef;
  dataToSendIssuePolicy: any = {};
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  public fileUploadZip: File;
  errorZip = {active: false, msg: ''};
  fileTypesZip: string[] = ['application/zip', 'application/x-zip-compressed'];
  responseImportInsured: any = [];
  insuredProcess: number;
  insuredUnprocessed: number;
  importMassive: boolean;
  branchId: string;
  subbranchId: string;
  validationPolicy: any = {};
  categoryList: any = [];
  userinfo: {name, firstName, lastName, email, birthday, policyId, gender};
  userCoincidenceList: any = [];
  confirmSave: number;
  bsLeavingDateBirthDate: Partial<BsDatepickerConfig>;
  catGenders: any = [];
  genderSelected;
  private _clients: any = [];
  constructor(private modalService: BsModalService,
              private toastr: ToastrService,
              private shared: SharedService,
              private api: ApiService,
              private router: Router,
              private spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.userinfo = {
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      birthday: '',
      gender: '',
      policyId: ''
    };

    this.bsLeavingDateBirthDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      adaptivePosition: true
    });

    this.invokeServiceGetGenders();
    this.confirmSave = 0;
  }

  getSelectedGender(event) {
    this.genderSelected = event;
    console.log(event);
  }
  invokeServiceGetGenders() {
    console.log('invokeServiceGetGenders');
    const data = {name: 'GENDERS'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
      if (data != null) {
        this.catGenders = data;
        console.log('data');
        console.log(data);
      }
    }, error => {
      //console.error(error.status);
      //console.error(error.statusText);
      //console.error(error.message);
    });
  }

  openModal(template: TemplateRef<any>) {
    this.spinner.show('sp');
    this.loadInitialModal();
    this.modalRef = this.modalService.show(template, {keyboard: false, ignoreBackdropClick: true, backdrop: false});
    this.validateIssuancePolicy();

    if ((this.subbranchId === '3' || this.subbranchId === '6' || this.subbranchId === '8' || this.subbranchId === '9' ||
      this.subbranchId === '24' || this.subbranchId === '28' || this.subbranchId === '31' || this.subbranchId === '32'
      || this.subbranchId === '33' || this.subbranchId === '34' || this.subbranchId === '36') || (this.branchId === '3' && this.subbranchId !== '21')) {

      this.getClientForInsured();

    }
    this.spinner.hide('sp');
  }

  SendPolicy() {
    console.log(this.fileUploadXlsx);
    if (this.dataToSendIssuePolicy.number === '' || this.fileUpload === undefined) {
      this.toastr.warning('PARA EMITIR LA PÓLIZA ES NECESARIO EL NUMERO Y EL ARCHIVO DE LA PÓLIZA');
      return;
    }

    if (this.subbranchId !== '3' && this.subbranchId !== '6' && this.subbranchId !== '8' && this.subbranchId !== '9' &&
      this.subbranchId !== '24' && this.subbranchId !== '28' && this.subbranchId !== '31' && this.subbranchId !== '32'
      && this.subbranchId !== '33' && this.subbranchId !== '34' && this.subbranchId !== '36' && this.branchId !== '3') {
      if (this.fileUploadXlsx === undefined ) {
        this.toastr.warning('DEBE DE CARGAR EL LAYAOUT DE SU POBLACIÓN INICIAL', 'NOTIFICACIÓN');
        return;
      }
      if (this.fileUploadZip === undefined) {
        this.toastr.warning('DEBE DE CARGAR LOS CERTIFICADOS DE SU POBLACIÓN INICIAL', 'NOTIFICACIÓN');
        return;
      }
    } else {
      if ((this.subbranchId === '3' || this.subbranchId === '6' || this.subbranchId === '8' || this.subbranchId === '9' ||
        this.subbranchId === '24' || this.subbranchId === '28' || this.subbranchId === '31' || this.subbranchId === '32'
        || this.subbranchId === '33' || this.subbranchId === '34' || this.subbranchId === '36') || (this.branchId === '3' && this.subbranchId !== '21')) {
        if (this.userinfo.name == null || this.userinfo.name === ''
          || this.userinfo.firstName == null || this.userinfo.firstName === ''
          || this.userinfo.birthday == null || this.userinfo.birthday === ''
          || this.userinfo.gender == null || this.userinfo.gender === ''
          || this.userinfo.email == null || this.userinfo.email === '' ) {
          this.toastr.warning('' +
            'Es necesario llenar toda la información del asegurado.', 'Notificación');
          this.spinner.hide('sp');
          return;
        }
      }
    }

    this.spinner.show('sp');
    // console.log(this.dataToSendIssuePolicy);
    if ((this.subbranchId === '3' || this.subbranchId === '6' || this.subbranchId === '8' || this.subbranchId === '9' || this.subbranchId === '24'
      || this.subbranchId === '28' || this.subbranchId === '31' || this.subbranchId === '32' || this.subbranchId === '33' || this.subbranchId === '34'
      || this.subbranchId === '36') || ( this.branchId === '3' && this.subbranchId !== '21')) {
       this.validateCoincidence();
    } else {
      this.invokeChangeStatusPolicy();
    }
  }

  /**
   * funcion que guarda una cobertura
   */
  validateCoincidence() {
    if (this.userinfo.name == null || this.userinfo.name === ''
      || this.userinfo.firstName == null || this.userinfo.firstName === ''
      || this.userinfo.birthday == null || this.userinfo.birthday === ''
      || this.userinfo.gender == null || this.userinfo.gender === ''
      || this.userinfo.email == null || this.userinfo.email === '' ) {
      this.toastr.warning('' +
        'Es necesario llenar toda la información del asegurado.', 'Notificación');
      this.spinner.hide('sp');
      return;
    } else {
      if (this.confirmSave === 0) {
        this.api.getCoincidenceUser(this.userinfo).then(
          (response: any) => {
            this.userCoincidenceList = response;
            this.confirmSave++;
            if (this.userCoincidenceList.length === 0) { // Si todo bien, se guarda
                   this.invokeChangeStatusPolicy();
            }
            this.spinner.hide('sp');
          }, error => {
            this.spinner.hide('sp');
            if (error.status === 500) {
              if (error.error.Error !== undefined) {
                this.toastr.error(error.error.message, 'Notificación');
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          }
        );
      } else if (this.confirmSave === 1) {
        this.invokeChangeStatusPolicy();
      }
    }
  }

  invokeChangeStatusPolicy() {
    this.api.putChangeStatusPolicy(this.dataToSendIssuePolicy)
          .then(
            (data) => {
              this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));

              const formData = new FormData();
              formData.append('file', this.fileUpload);
              const formDataXlsx = new FormData();
              formDataXlsx.append('fileXlsx', this.fileUploadXlsx);
              // const formDataZip = new FormData();
              formDataXlsx.append('fileCompressed', this.fileUploadZip);
              this.api.postFilePolicy(formData, this.dataToSendIssuePolicy.policyId)
                .subscribe(
                  response => {

                    if (this.subbranchId !== '3' && this.subbranchId !== '6' && this.subbranchId !== '8' && this.subbranchId !== '9' &&
                      this.subbranchId !== '24' && this.subbranchId !== '28' && this.subbranchId !== '31' && this.subbranchId !== '32'
                      && this.subbranchId !== '33' && this.subbranchId !== '34' && this.subbranchId !== '36' && this.branchId !== '3') {

                      this.api.postImportInsuredPolicy(formDataXlsx, 6, this.dataToSendIssuePolicy.policyId)
                        .subscribe(
                          responseInsured => {
                            this.responseImportInsured = responseInsured;
                            // console.log(this.responseImportInsured);
                            this.toastr.success('EL PROCESO DE IMPORTACIÓN HA INICIADO EN CUANTO TERMINE SE LE NOTIFICARA', 'NOTIFICACIÓN');
                            this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
                            this.insuredUnprocessed = 0;
                            this.insuredProcess = 0;
                            this.importMassive = true;
                            this.spinner.hide('sp');
                            this.modalRef.hide();
                            // mandar a home
                            this.shared.gSearchId = 0;
                            this.router.navigate(['/admin/executive']);
                            this.shared.fUpdateHome.next(2);
                          }, error => {
                            // console.log(error);
                            this.toastr.warning(error.error);
                            // console.error();
                            this.spinner.hide('sp');
                          }
                        );
                    } else {
                      if ((this.subbranchId === '3' || this.subbranchId === '6' || this.subbranchId === '8' || this.subbranchId === '9' || this.subbranchId === '24'
                        || this.subbranchId === '28' || this.subbranchId === '31' || this.subbranchId === '32' || this.subbranchId === '33' || this.subbranchId === '34'
                        || this.subbranchId === '36') || ( this.branchId === '3' && this.subbranchId !== '21')) {
                        this.createUserCar();
                      } else {
                        this.relationUserCarAndCertificate();
                      }

                    }
                  }, error => {
                    this.toastr.warning(error.error);
                    // console.error();
                    this.spinner.hide('sp');
                  }
                );
            }, error => {
              this.modalRef.hide();
              this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
              this.spinner.hide('sp');
            }
          );
  }
  invokeServiceValidateNumberPolicy() {
    this.spinner.show('sp');
    const obj = {
      number: this.dataToSendIssuePolicy.number,
      policyId: Number(localStorage.getItem('policy-id'))
    };
    this.api.getValidateNumberPolicy(obj).then((dataP: any) => {
        this.spinner.hide('sp');
        if (dataP.policyNumberExists > 0) {
          this.dataToSendIssuePolicy.number = '';
          this.toastr.warning('EL NUMERO DE PÓLIZA YA EXISTE, CAMBIELO E INTENTE DE NUEVO POR FAVOR', 'NOTIFICACIÓN');
          return;
        }
      }, error => {
        this.spinner.hide('sp');
      });
  }

  createUserCar(): Promise<any> {
    return new Promise((response, error) => {
      if (this.userinfo.name == null || this.userinfo.name === ''
        || this.userinfo.firstName == null || this.userinfo.firstName === ''
        || this.userinfo.birthday == null || this.userinfo.birthday === ''
        || this.userinfo.gender == null || this.userinfo.gender === ''
        || this.userinfo.email == null || this.userinfo.email === '' ) {
        this.toastr.warning('' +
          'Es necesario llenar toda la información del asegurado.', 'Notificación');
        error(false);
        this.spinner.hide('sp');
        return;
      } else {
        this.userinfo.policyId = localStorage.getItem('policy-id');
        this.api.postInsuredCar(this.userinfo)
          .then(
            (data: any) => {
              const user = data;
              if (user._clientId !== undefined && user._clientId !== '') {
                this.userinfo.name = '';
                this.userinfo.firstName = null;
                this.userinfo.lastName = null;
                this.userinfo.birthday = null;
                this.userinfo.gender = null;
                this.userinfo.email = null;
                response(true);
                this.modalRef.hide();
                this.spinner.hide('sp');
                if (this.branchId === '3' && this.subbranchId !== '21') {
                  this.createPolicieIssues();
                }
                this.toastr.success('Operación realizada con éxito', 'Notificación');
              } else {
                response(true);
                this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
              }
              this.spinner.hide('sp');
            }, () => {
              error(false);
              this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      }
    });
  }

  relationUserCarAndCertificate(): Promise<any> {
    return new Promise((response, error) => {
      this.userinfo.policyId = localStorage.getItem('policy-id');
      this.api.postInsuredCarUser( this.userinfo.policyId)
        .then(
          (data: any) => {
            this.modalRef.hide();
            this.toastr.success('Operación realizada con éxito', 'Notificación');
            this.spinner.hide('sp');
          }, () => {
            error(false);
            this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    });
  }

  createPolicieIssues() {
    this.api.createPolicyIssues(this.dataToSendIssuePolicy.policyId)
      .then(
        (data: any) => {
          this.spinner.hide('sp');
        }, () => {
          this.toastr.warning('La póliza ha sido emitida, pero hubo un problema para determinar si podrá ser renovada desde Mobile. Contacta con el área de tecnología ', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  clearUserAndCoincidance() {
    this.userinfo.name = '';
    this.userinfo.firstName = null;
    this.userinfo.lastName = null;
    this.userinfo.birthday = null;
    this.userinfo.gender = null;
    this.userinfo.email = null;
    this.userCoincidenceList = [];
    this.confirmSave = 0;
  }

  closeModal() {
    this.clearUserAndCoincidance();
    this.modalRef.hide();
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  onChangeInputFileXlsx(e) {
    this.errorXlsx = {active: false, msg: ''};
    this.fileUploadXlsx = e.target.files[0];
    // console.log(this.fileUploadXlsx);
    if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
      this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  onChangeInputFileZip(e) {
    this.errorZip = {active: false, msg: ''};
    this.fileUploadZip = e.target.files[0];
    if (0 > this.fileTypesZip.indexOf(this.fileUploadZip.type)) {
      this.errorZip = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  loadInitialModal() {
    this.importMassive = false;
    this.branchId = localStorage.getItem('policy-branchId');
    this.subbranchId = localStorage.getItem('policy-subbranchId');
    this.dataToSendIssuePolicy = {
      policyId: localStorage.getItem('policy-id'),
      statusId: 1,
      number: ''
    };
    this.fileUpload = undefined;
    this.fileUploadXlsx = undefined;
    this.fileUploadZip = undefined;
    this.getCategoriesPolicy();
  }

  validateIssuancePolicy() {

    if (this.branchId !== '3') {
      this.api.validationIssuancePolicy(this.dataToSendIssuePolicy.policyId).then((data: any) => {
        this.validationPolicy = data;
        console.log(this.validationPolicy);
      }, error => {

      });
    } else {
      this.api.validationIssuancePolicyCar(this.dataToSendIssuePolicy.policyId).then((data: any) => {
        this.validationPolicy = data;
        console.log(this.validationPolicy);
      }, error => {

      });
    }


  }

  downloadLayout() {
    this.spinner.show('sp');
    this.api.getLayoutFile(1)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',  'LAYOUT_ASEGURADOS.xlsx');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  getCategoriesPolicy() {
    this.categoryList = [];
    this.api.getCategoriesPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.categoryList = data;
    }, error => {
    });
  }

  selectUserFormCoincidence(user) {
    this.userinfo.name = user.name;
    this.userinfo.firstName = user.firstName;
    this.userinfo.lastName = user.lastName;
    this.userinfo.email = user.email;
    this.userinfo.gender = user.gender;
    this.userinfo.birthday = user.birthdate;
  }

  getClientForInsured() {
    this.api.getClientUser(this._clients[0]).then((data: any) => {
      this.userinfo.name = data.name;
      this.userinfo.firstName = data.firstName;
      this.userinfo.lastName = data.lastName;
    }, error => {

    });
  }

  get clients(): any {
    return this._clients;
  }

  @Input()
  set clients(value: any) {
    this._clients = value;
  }
}
