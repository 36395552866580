import {CoverageOptionPlacement} from './coverageOptionPlacement';
import {CompanySubranch} from './companySubranch';
import {PlacementDetail} from './placementDetail';
import {number, string} from '@amcharts/amcharts4/core';

export class ValuesSelected {
  private _value: string;
  private _meditionType: string;
  private _amparado: boolean;

  constructor(value: string, meditionType: string, amparado) {
    this._value = value;
    this._meditionType = meditionType;
    this._amparado = amparado;

  }

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
  }

  get meditionType(): string {
    return this._meditionType;
  }

  get amparado(): boolean {
    return this._amparado;
  }

  set amparado(value: boolean) {
    this._amparado = value;
  }

  set meditionType(value: string) {
    this._meditionType = value;
  }

  toPlainObject(): any {
    return {
      value: this._value,
      meditionType: this._meditionType,
      amparado: this._amparado
    };
  }
}
