import {Component, OnInit} from '@angular/core';
import {SharedService} from '../shared-service.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-event-detail-mdl',
  templateUrl: './event-detail-mdl.component.html',
  styleUrls: ['./event-detail-mdl.component.css']
})
export class EventDetailMdlComponent implements OnInit {
  event: any = {};
  importOverview: any = {};
  insuredList: any = [];
  bug: string;
  lclRequestDetail: any = [];
  isRequest: boolean;
  isCollapsed = true;
  editField: string;
  allIsValide = false;
  idsRequestValidate: any = [];
  isRequesCar = false;
  name: string;
  lastName: string;
  firstName: string;
  subject: string;
  description: string;
  email: string;
  gender: string;
  birthDate: string;
  numberPolicy: string;
  typeKinshipId: string;
  createAt: string;
  // Para car
  mark: string;
  model: string;
  version: string;
  statusRequest: any;
  idStatus: number;
  isCollapsable = true;
  comments: string;


  constructor(public shared: SharedService,
              private toastr: ToastrService,
              private api: ApiService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.event = JSON.parse(localStorage.getItem('eventView'));
    // console.log(this.event);
    this.loadEventDetail(this.event);


  }

  getEventDetailImportOverview(id) {
    this.api.getImportOverview(id).then((data: any) => {
      // console.log('getImportOverview data');
      // console.log(data);
      this.importOverview = data;
      this.insuredList = this.importOverview.insuredMassiveList;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  /*getEventDetailImportDamages(){
    this.api.getImportDamages(id).then((data: any) => {
          // console.log('getImportOverview data');
          // console.log(data);
          this.importOverview = data;
          this.insuredList = this.importOverview.insuredMassiveList;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        });
  }*/
  loadEventDetail(event) {
    this.isRequest = false;
    // console.log('event.eventtype');
    // console.log(event.eventtype);
    switch (event.eventtype) {
      case 8:
      case 3:

        this.isRequest = true;
        const data = {
          requestId: event.relationid,
          requestType: event.eventtype, // Usamos requestType para enviar el typo de evento
          userId: event.eventid
        }; // Usamos userId para enviar el id del evento

        this.getEventRequest(data, event.eventtype);
        break;
      case 4:
        this.getEventDetailImportOverview(event.eventid);
        break;
      case 5:

        break;
    }
  }

  public invokeServiceForGetAttachmentFile() {
    this.api.getReportImportOverview(this.event.eventid)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', 'INFORME DE IMPORTACION.xls');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          console.error(error);
        }
      );
  }

  getEventRequest(data, eventtype) {
    this.api.getRequest(data)
      .then(
        (response: any) => {
          if (response.requestProcessUser !== undefined && response.requestProcessUser.length !== 0) {
            this.isRequesCar = false;
            this.lclRequestDetail = response.requestProcessUser;
            // console.log('this.lclRequestDetail');
            // console.log(this.lclRequestDetail);
            // console.log('this.requestProcessUser');
            // console.log(this.lclRequestDetail);

            if (eventtype === 8) {
              this.subject = 'IMPORTACIÓN MASIVA';
              this.description = 'IMPORTACIÓN MASIVA PARA ALTA O BAJA DE ASEGURADOS O DEPENDIENTES';
            } else {
              this.subject = this.lclRequestDetail[0].subject;
              this.description = this.lclRequestDetail[0].description;
            }
            this.name = this.lclRequestDetail[0].name;
            this.lastName = this.lclRequestDetail[0].lastName;
            this.firstName = this.lclRequestDetail[0].firstName;
            this.email = this.lclRequestDetail[0].email;
            this.gender = this.lclRequestDetail[0].gender;
            this.birthDate = this.lclRequestDetail[0].birthDate;
            this.numberPolicy = this.lclRequestDetail[0].numberPolicy;
            this.typeKinshipId = this.lclRequestDetail[0].typeKinshipId;
            this.createAt = this.lclRequestDetail[0].createAt;
            this.idStatus = this.lclRequestDetail[0].statusId;
            this.statusRequest = this.lclRequestDetail[0].status;

          } else if (response.requestProcessCar !== undefined && response.requestProcessCar.length !== 0) {
            this.lclRequestDetail = response.requestProcessCar;
            this.isRequesCar = true;
            this.subject = this.lclRequestDetail[0].subject;
            this.description = this.lclRequestDetail[0].description;
            this.createAt = this.lclRequestDetail[0].createAt;
            this.mark = this.lclRequestDetail[0].mark;
            this.model = this.lclRequestDetail[0].model;
            this.version = this.lclRequestDetail[0].version;
            this.numberPolicy = this.lclRequestDetail[0].numberPolicy;
          }

        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
          // this.toastr.error('Ocurrió un problema al procesar su petición lalo', 'Notificación');
        }
      );
    /*this.api.getRequestById(data)
          .then(
            (response: any) => {

                this.lclRequestDetail = response;
            }, error => {
              console.error(error);
            });*/
  }

  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    // this.personList[id][property] = editField;
    // console.log('Se efitara ' + editField);
  }


  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
    // console.log('valor nuevo=  ' + this.editField);
  }

  changeValueCheck($event, id) {


    if ($event.target.checked === true) {
      this.idsRequestValidate.push(id);

    } else {
      const idx = this.idsRequestValidate.indexOf(id);
      // console.log('idx');
      // console.log(idx);
      this.idsRequestValidate.splice(idx);

    }


    // console.log('this.idsRequestValidate.lenght');
    // console.log(this.idsRequestValidate.length);

    // console.log('this.lclRequestDetail.lenght');
    // console.log(this.lclRequestDetail.length);


    if (this.idsRequestValidate.length === this.lclRequestDetail.length) {
      // console.log('son del mismo tañ');
      this.allIsValide = true;

    } else {
      this.allIsValide = false;

    }
    // console.log('this.allIsValide');
    // console.log(this.allIsValide);
    // this.idsRequestValidate.push(id);

  }

  rechazarSolicitud() {
    this.lclRequestDetail[0].comments = this.comments;
    this.lclRequestDetail[0].statusId = 4;
    this.lclRequestDetail[0].validateInformation = true;

    this.invokeServiceForValidateRequest(this.lclRequestDetail);
  }

  validarSolicitud() {
    this.lclRequestDetail[0].comments = '';
    this.lclRequestDetail[0].statusId = 1;
    this.lclRequestDetail[0].validateInformation = true;

    this.invokeServiceForValidateRequest(this.lclRequestDetail);
  }

  invokeServiceForValidateRequest(data) {

    this.api.validateRequest(data)
      .then(
        (response: any) => {
          if (response.validate === true) {
            this.toastr.success('Operación realizada con éxito', 'Notificación');
            this.shared.fCloseSidebar();

          }
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
          this.shared.fCloseSidebar();

        }
      );

  }

  activateComments() {
    this.isCollapsable = false;
  }

}
