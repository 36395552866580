import {Component, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal'
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-admin-insurance-company',
  templateUrl: './admin-insurance-company.component.html',
  styleUrls: ['./admin-insurance-company.component.css']
})
export class AdminInsuranceCompanyComponent implements OnInit {
  insuranceList: any = [];
  // -Varibales para paginado de recibos->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  modalRef: BsModalRef;
  filters: any = {};
  insuranceId: number;
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) { }

  ngOnInit() {
    this.validateAccess.validateAccessCore(15).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.clearFiltersTable();
      }
    });
  }


  /**
   * función para regresar a dashboard principal
   */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }
  clearFiltersTable() {
    this.filters = {
      name: '',
      rfc: '',
      page: 1,
      size: 10
    };
    this.validateAccess.validateAccessCore(15).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }

  loadFiltersTable() {
    this.validateAccess.validateAccessCore(15).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }

  pageChanged(event: PageChangedEvent): void {
    // // // console.loglog(event);
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.api.getInsurancePagination(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.insuranceList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.total ;
          this.insuranceList = this.paginationResponse.insuranceList ;
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // // console.logerror(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.insuranceList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }

  openDetailInsurance(insuranceId, operation) {
    let accessCore = 0;
    if (insuranceId === 0 && operation === 1) {
      accessCore = 16;
    } else if ( insuranceId > 0 && operation === 1 ) {
      accessCore = 17;
    } else if (insuranceId > 0 && operation === 0 ) {
      accessCore = 19;
    }
    this.validateAccess.validateAccessCore(accessCore).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        localStorage.setItem('insurance-id', insuranceId);
        localStorage.setItem('isEdithInsurance', operation);
        this.router.navigate(['/admin/insurance_detail']);
      }
    });
  }


  closeModal() {
    this.modalRef.hide();
    this.insuranceId = 0;
  }
  confirmDelete() {
    this.api.deleteInsurance(this.insuranceId).subscribe( response => {
      this.getByPagination(1, 10);
      this.insuranceId = 0;
      this.modalRef.hide();
      this.toastr.success('OPERACIÓN REALIZADA CON ÉXITO', 'NOTIFICACIÓN');
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }
  openModalDeleteRegister(template: TemplateRef<any>, insuranceId) {
    this.validateAccess.validateAccessCore(18).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.insuranceId = insuranceId;
        this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
      }
    });
  }

}
