import {ValuesSelected} from './valuesSelected';

export class CoveragePlacementSelected {
  private _idCoverage: number;
  private _nameCoverage: string;
  private _descriptionCoverage: string;
  private _cuantitative: boolean;
  private _valueSelected: ValuesSelected[];
  private _coveragePlacementSelected: CoveragePlacementSelected[];

  constructor(idCoverage: number, nameCoverage: string, descriptionCoverage: string, cuantitative: boolean,
              valueSelected: ValuesSelected[] = [],
              coveragePlacementSelected: CoveragePlacementSelected[] = []) {
    this._idCoverage = idCoverage;
    this._nameCoverage = nameCoverage;
    this._descriptionCoverage = descriptionCoverage;
    this._cuantitative = cuantitative;
    this._valueSelected = valueSelected.map(detail => new ValuesSelected(
      detail.value,
      detail.meditionType,
      detail.amparado
    ));

    // Mapea placementDetails usando el constructor de PlacementDetail
    this._coveragePlacementSelected = coveragePlacementSelected.map(detail => new CoveragePlacementSelected(
      detail.idCoverage,
      detail.nameCoverage,
      detail.descriptionCoverage,
      detail.cuantitative,
      detail.valueSelected,
      detail.coveragePlacementSelected
    ));
  }

  get idCoverage(): number {
    return this._idCoverage;
  }

  set idCoverage(value: number) {
    this._idCoverage = value;
  }

  get nameCoverage(): string {
    return this._nameCoverage;
  }

  set nameCoverage(value: string) {
    this._nameCoverage = value;
  }

  get valueSelected(): ValuesSelected[] {
    return this._valueSelected;
  }

  set valueSelected(value: ValuesSelected[]) {
    this._valueSelected = value;
  }

  get coveragePlacementSelected(): CoveragePlacementSelected[] {
    return this._coveragePlacementSelected;
  }

  set coveragePlacementSelected(value: CoveragePlacementSelected[]) {
    this._coveragePlacementSelected = value;
  }

  get descriptionCoverage(): string {
    return this._descriptionCoverage;
  }

  set descriptionCoverage(value: string) {
    this._descriptionCoverage = value;
  }

  get cuantitative(): boolean {
    return this._cuantitative;
  }

  set cuantitative(value: boolean) {
    this._cuantitative = value;
  }

  toPlainObject(): any {
    return {
      idCoverage: this._idCoverage,
      nameCoverage: this._nameCoverage,
      descriptionCoverage: this._descriptionCoverage,
      cuantitative: this._cuantitative,
      valueSelected: this._valueSelected.map(detail => detail.toPlainObject()),
      coveragePlacementSelected: this._coveragePlacementSelected.map(detail => detail.toPlainObject()),
    };
  }
}
