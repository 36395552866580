import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import {environment} from '../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {SwiperEvent, SwiperOptions} from 'swiper';
import {SwiperComponent} from 'ngx-useful-swiper';
@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {
  @ViewChild('swiperReceipt', {static: false }) private swiperReceipt: SwiperComponent;
  detailPolicy: any = {};
  imageCompany;
  colorCompanyHex;
  annexesList: any = [];
  dependentsList: any = [];
  detailPolicyjson;
  url;
  url1;
  urlcertificate;
  lclPolicyId: number;
  loadservice1;
  loadservice2;
  insuredList: any [];
  search: string;
  counCertificates: any;
  // Variables para recibos ->
  insuranceList: any = [];
  lclInsuranceIdSelected: number;
  receiptStatusList: any = [];
  lclReceiptStatusSelected: any = {};
  receiptsList: any = [];
  receiptDetail: any = {};
  receiptAttachmentsList: any = [];
  countReceipt: number;
  salesman: any = {};
  swiperReceiptsConf: SwiperOptions = {
    // effect: 'coverflow',
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 20,
    height: 230,
    direction: 'vertical',
    mousewheel: true,
    centeredSlides: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows : false,
    },
    init: false
  };

  constructor(private api: ApiService,
              public shared: SharedService,
              private toastr: ToastrService) {
    this.shared.gPolicyIdObservable().subscribe(
      gPolicyId => {
        this.lclPolicyId = gPolicyId;
        this.loadDetailPolicy(this.lclPolicyId);
        this.loadAttachment(this.lclPolicyId);
      }
    );
  }

  ngOnInit() {
    this.search = '';
    this.loadservice1 = false;
    this.loadservice2 = false;
    // this.loadDetailPolicy(this.lclPolicyId);
  }

  loadDetailPolicy(policyId) {
    this.api.getDetailPolicy(policyId)
      .then(
        (data) => {
          this.detailPolicy = data;
          this.imageCompany = this.detailPolicy.imageCompany;
          this.colorCompanyHex = this.detailPolicy.colorCompanyHex;
          // this.loadAttachment(this.detailPolicy.policyId);
          this.getDetail(this.detailPolicy.policyId);
          if (this.detailPolicy.typePolicyId != 9 && this.detailPolicy.typePolicyId != 11) {
            this.loadInsured();
          }
          if (this.detailPolicy.typePolicyId == 9 && this.detailPolicy.policySalesman.name == "") {
            this.invokeServiceForGetSalesmanExternal(this.detailPolicy.policySalesman.userIdExternal);
          }
          if (!this.shared.fInsuredDataReadonly) {
            this.invokeServiceForGetReceiptStatusList();
          } else this.getPolicyDependents(this.detailPolicy.policyId);
          this.loadservice2 = true;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }
  getDetail(policyId) {
    this.api.getDetailPolicyjson(policyId)
      .then(
        (data) => {
          this.detailPolicyjson = data;
          this.loadservice1 = true;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  getPolicyDependents(policyId) {
    this.api.getPolicyDependents(policyId)
      .then(
        (data) => {
          const oData: any = data;
          this.dependentsList = oData.dependentList;
          console.info(data);
        }, error => {
          console.error(error);
        });
  }

  public invokeServiceForGetAttachmentFile(attachment) {
    this.api.getPolicyAttachmentFile(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          console.error(error);
        }
      );
  }

  public invokeServiceForDownloadPolicyAttachmentZipFile(policy) {
    this.api.getPolicyAttachmentZip(policy.policyId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  `anexos_poliza_${policy.number}.zip`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  loadAttachment(policyId) {
    this.api.getAttachmentlPolicy(policyId)
      .then(
        (data) => {
          this.annexesList = data;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  /**
   * Descarga el PDF de la póliza
   */
  public invokeServiceForDownloadPolicyFile() {
    this.api.getPolicyFile(this.lclPolicyId)
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', this.detailPolicy.number + '.pdf');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          console.error(error);
        }
      );
  }

  public loadInsured() {
    this.api.getCountCertificates(this.lclPolicyId)
      .then(
        (data) => {
          this.counCertificates = data;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
    this.api.getCertificatesSearch(this.lclPolicyId, this.search)
      .subscribe(
        (response: any = {}) => {
          this.insuredList = response;
        }, error => {
          console.info(error);
        }
      );
  }

  public invokeServiceForGetInsured() {
    this.api.getCertificatesSearch(this.lclPolicyId, this.search)
      .subscribe(
        (response: any = {}) => {
          this.insuredList = response;
        }, error => {
          console.info(error);
        }
      );
  }

  invokeServiceForGetSalesmanExternal(userIdExternal) {
    this.api.getSalesmanExternal(userIdExternal)
      .then(
        (data) => {
          this.salesman = data;
          this.detailPolicy.policySalesman.name = this.salesman.name;
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadCertificate(certificate) {
    // console.log(certificate);
    this.api.getCertificate(certificate.certificateId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  certificate.beneficiary + '.pdf');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }
  invokeServiceForEditCertificate(certificate) {
    // console.log(certificate);
  }
  invokeServiceForDeleteCertificate(certificate) {
    // console.log(certificate);
  }
  /*+-------------------------------------------------------------------------------------------------------+
    |------------------------------------------|  R E C I B O S  |------------------------------------------|
    +-------------------------------------------------------------------------------------------------------+*/
  invokeServiceForGetReceiptStatusList() {
    this.api.getReceiptStatusList(this.lclPolicyId)
      .then(
        (response) => {
          this.receiptStatusList = response;
          this.changeLclReceiptStatus(this.receiptStatusList[0]);
        }, error => {
          console.error(error);
        }
      );
  }
  changeLclReceiptStatus(receiptStatus) {
    this.lclReceiptStatusSelected = receiptStatus;
    this.invokeServiceForGetInsuranceReceiptsList();
  }
  invokeServiceForGetInsuranceReceiptsList() {
    this.api.getInsuranceReceiptsList(this.lclReceiptStatusSelected.statusId, this.lclPolicyId)
      .then((response) => {
        const oResponse: any = response;
        this.receiptsList = oResponse.receiptList;
        this.receiptDetail = this.receiptsList[0];
        this.swiperReceipt.swiper.init();
        this.swiperReceipt.swiper.on('slideChange', () => {
          this.swiperReceiptEventHandler();
        });
        this.invokeServiceForGetAttachmentsByReceipt(this.receiptDetail.receiptId);
      }, error => {
        console.error(error);
      });
  }
  invokeServiceForGetAttachmentsByReceipt(receiptId) {
    this.api.getReceiptAttachment(receiptId)
      .then(
        (response) => {
          const oResponse: any = response;
          this.receiptAttachmentsList = oResponse;
        }, error => {
          console.error(error);
        });
  }
  swiperReceiptEventHandler() {
    this.receiptDetail = this.receiptsList[this.swiperReceipt.swiper.activeIndex];
    this.invokeServiceForGetAttachmentsByReceipt(this.receiptDetail.receiptId);
  }
  invokeServiceForDownloadAttachmentFile(attached) {
    this.api.getAttachmentFile(attached.annexId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          if (attached.name) {
            downloadLink.setAttribute('download', attached.name);
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        });
  }
  invokeServiceForDownloadReceiptAttachmentZipFile() {
    this.api.getReceiptAttachmentZipFile(this.receiptDetail.receiptId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', `recibos_poliza_${this.receiptDetail.numberPolicy}.zip`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        });
  }

  public invokeServiceForDownloadFileReceipt() {
    this.api.getFileReceipt()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'recibos.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

}
