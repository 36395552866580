import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-commons-report-production',
  templateUrl: './commons-report-production.component.html',
  styleUrls: ['./commons-report-production.component.css']
})
export class CommonsReportProductionComponent implements OnInit {

  reportProduccionList: any = [];
  // -Varibles para paginado de usuarios->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  private _paginationResponse: any = {};
  filters: any = {};
  filtersDownload: any = {};
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  groupsSelectedItems: any = [];
  dropdownSettingsGroups = {};
  clientList: any = [];
  groupList: any = [];
  branchList: any = [];
  branchSelectedItems: any = [];
  dropdownSettingsBranch: any = {};
  subBranchList: any = [];
  subBranchSelectedItems: any = [];
  dropdownSettingsSubBranch: any = {};
  executiveSurexList: any = [];
  surexSelectedItems: any = [];
  dropdownSettingsSurex: any = {};
  insuranceList: any = [];
  insurancesSelectedItems: any = [];
  dropdownSettingsInsurances = {};
  salesmanList: any = [];
  salesmanSelectedItems: any = [];
  statusPolicySelectedItems: any = [];
  dropdownSettingsStatusPolicy: any = {};
  filterRangeDate: Date[];
  bsConfig: Partial<BsDatepickerConfig>;

  @Output()
  pageChangedEvent = new EventEmitter();

  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {

  }

  /**
   * Carga los datos según la página seleccionada
   */
  pageChanged(event: PageChangedEvent): void {
    this.pageChangedEvent.emit(event);

  }

  changeColorPagination() {
  }

  invokeServiceForGetReportsProduccionXls() {
    this.spinner.show('sp');

    this.api.getReportsProduccionXls(this.filters, 0)
      .then(
        (response: any) => {
          console.log(response);
          if (response == null) {
            this.toastr.info('No se encontraron registros para descargar');
            this.spinner.hide('sp');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', 'reporte_produccion.xls');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
            this.spinner.hide('sp');
          }

        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  get paginationResponse(): any {
    return this._paginationResponse;
  }

  @Input()
  set paginationResponse(value: any) {
    this._paginationResponse = value;

    if ( this._paginationResponse === null) {
      this.reportProduccionList = [];
      this.pgTotalItems = 0;
      return;
    }
    this.pgTotalItems = this._paginationResponse.totalRows;
    this.reportProduccionList = this._paginationResponse.tList;
  }
}
