import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {
  NgWizardConfig,
  NgWizardService,
  StepChangedArgs,
  StepValidationArgs,
  STEP_STATE,
  THEME, NgWizardStep,
} from 'ng-wizard';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {document} from 'ngx-bootstrap/utils';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {regex} from '../../common/regex';
import {Documents} from '../upload-file/documents';
import {Address, Client, Contact, DocumentationList, GeneralData, Group, Subgroup} from './client-model';
import {Router} from '@angular/router';
import {SharedService} from '../shared-service.service';
import {AddressFisico, ClientFisico, DocumentationListFisico, GroupFisico, SubgroupFisico} from './client-physical.model';
import {NgxSpinnerService} from 'ngx-spinner';
import * as XLSX from 'xlsx';
import * as JSZip from 'jszip';
import { Observable, forkJoin, zip, of, Subject } from 'rxjs';

interface ClienteFisico {
  detalleId: number;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  rfc: string;
  grupo: string;
  idGrupo: number;
  telefono: string;
  email: string;
  cpFiscal: string;
  paisFiscal: string;
  idPaisFiscal: number;
  estadoFiscal: string;
  idEstadoFiscal: string;
  municipioFiscal: string;
  idMunicipioFiscal: string;
  coloniaFiscal: string;
  idColoniaFiscal: string;
  calleFiscal: string;
  numExtFiscal: string;
  numIntFiscal: string;
  cpCliente: string;
  paisCliente: string;
  idPaisCliente: number;
  estadoCliente: string;
  idEstadoCliente: string;
  municipioCliente: string;
  idMunicipioCliente: string;
  coloniaCliente: string;
  idColoniaCliente: string;
  calleCliente: string;
  numExtCliente: string;
  numIntCliente: string;
  actaNacimiento: string;
  comprobanteDomFiscal: string;
  identificacionOficial: string;
  rfcDocumento: string;
  acusePrescripcion: string;
}

interface ClienteMoral {
  detalleId: number;
  grupo: string;
  rfcSubgrupo: string;
  tipoSociedad: string;
  idTipoSociedad: number;
  razonSocial: string;
  nombreContacto: string;
  telefono: string;
  email: string;
  tipoContacto: string;
  idTipoContacto: number;
  cpFiscal: string;
  paisFiscal: string;
  idPaisFiscal: number;
  estadoFiscal: string;
  idEstadoFiscal: string;
  municipioFiscal: string;
  idMunicipioFiscal: string;
  coloniaFiscal: string;
  idColoniaFiscal: string;
  calleFiscal: string;
  numExtFiscal: string;
  numIntFiscal: string;
  cpCliente: string;
  paisCliente: string;
  idPaisCliente: number;
  estadoCliente: string;
  idEstadoCliente: string;
  municipioCliente: string;
  idMunicipioCliente: string;
  coloniaCliente: string;
  idColoniaCliente: string;
  calleCliente: string;
  numExtCliente: string;
  numIntCliente: string;
  actaConstitutiva: string;
  poderNotarial: string;
  identificacionApoderado: string;
  comprobanteDomicilio: string;
  comprobanteDomFiscal: string;
}
@Component({
  selector: 'app-admin-new-client',
  templateUrl: './admin-new-client.component.html',
  styleUrls: ['./admin-new-client.component.css']
})
export class AdminNewClientComponent implements OnInit, OnChanges {
  @Input()
  isMassiveLoad: boolean = false;

  @Output()
  public onCloseAction: EventEmitter<any> = new EventEmitter();

  modalRef: BsModalRef;
  typeSelectedContact: string;
  _GENERAL = 'GENERAL';
  _FISCAL = 'FISCAL';
  _ADICIONAL = 'ADICIONAL';
  tipoClienteParam: string = localStorage.getItem('clientNew');

  subGroupList: any = []; // Lista de subgrupos
  typeSocietiesList: any = []; // Lista de tipo de sociedad
  typeContactForClients: any = []; // Lista de tipo de contactos
  typesAddress: any = []; // Tipos de direcciones
  // modelo de datos generales de subgrupo
  dataGeneralSubgroup: any = {};
  // modelo de datos de contactos
  dataContacts: any = {};
  dataContactsSend: any = [];
  /*datos de nuevas calles por agregar*/
  dataStreetsNewsSend: any = [];
  dataSaveList: any = [];
  // modelo de direcciones
  dataAddressClient: any = {}; // Arreglos para dirección de cliente
  dataAddressFiscal: any = {}; // Arreglos para dirección fiscal de cliente
  dataOthersAddress: any = {}; // Arreglos para dirección fiscal de adicionales
  dataOthersAddressEdit: any = {}; // Arreglos para dirección fiscal de adicionales
  dataOthersAddressListSend: any = []; // Arreglo para direcciones a persistir
  dataOthersAddressAdds: any = {};
  dataOthersAddressAddsEdit: any = {};
  // listas de catalogos
  countryListFiscal: any = []; // Lista de paises fiscal
  countryList: any = []; // Lista de paises cliente
  countryListOther: any = []; // Lista de paises adicional
  municipalityListFiscal: any = []; // Lista de municipios fiscal
  municipalityList: any = []; // Lista de municipios cliente
  municipalityListOther: any = []; // Lista de municipios adicional
  zipCodeListFiscal: any = []; // Lista de código postal fiscal
  zipCodeList: any = [];  // Lista de código postal
  zipCodeListOther: any = []; // Lista de código postal adicional
  stateListFiscal: any = []; // Lista de estados fiscal
  stateList: any = []; // Lista de estados cliente
  stateListOther: any = []; // Lista de estados adicional
  neighborhoodListFiscal: any = []; // Lista de colonia fiscal
  neighborhoodList: any = []; // Lista de colonia cliente
  neighborhoodListOther: any = []; // Lista de colonia adicionales
  streetsListFiscal: any = []; // Lista de calles fiscal
  streetsList: any = []; // Lista de calles cliente
  streetsListOther: any = []; // Lista de calles adicional
  groupsList: any = []; // Lista de grupos
  groupsListFilter: any = []; // Lista de grupos original
  typePersonList: any = []; // Lista tipos de personas
  countryTemp: any = {};

  /*selected type cliet new*/
  selectTypeContact: any = {};
  /* mismo datos de fiscal*/
  sameAddressFiscal = false;

  /*badera para saber si es un nuevo cliete o no */
  isNewGroup = false; // Es nuevo grupo ?
  indexUpdate;
  // variable para subgrupos agregados
  dataListSubgroupsAdds: any = {};
  isNewStreetFiscal = false; // Es nueva calle fiscal?
  isNewStreet = false; // Es nueva calle cliente?
  isNewStreetOther = false; // Es nueva calle adicional?

  /*CONFIGURACION DE STEPS*/
  isStepEnable: boolean;
  /*VARIABLE PARA NUEVAS CALLES*/
  streetNew: any = {};
  streetFiscalNew: any = {};
  streetOtherNew: any = {};
  streetOtherNewEdit: any = {};
  updateSubgroup = false;
  configuration: number;

  // MODELOS FORM
  datosGeneralesForm: FormGroup;
  datosContactoForm: FormGroup;
  direccionesForm: FormGroup;
  datosAgrupadorForm: FormGroup;

  // MODELOS FORM PHYSICAL
  datosGeneralesFisicoForm: FormGroup;
  direccionesFisicoForm: FormGroup;

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.default,
    cycleSteps: false,
    toolbarSettings: {
      showPreviousButton: false
      , showNextButton: false
    },
    anchorSettings: {
      anchorClickable: true,
      enableAllAnchors: true,
      markDoneStep: true,
      markAllPreviousStepsAsDone: true,
      removeDoneStepOnNavigateBack: false,
      enableAnchorOnDoneStep: true
    }
  };

  selectedStepIndex = 0;
  totalSteps: number;
  disableBtnPrevius = true;
  disableBtnNext = false;
  acronimoTypeSociety = '';

  groupId: number;
  idConfigurationDocument: number;
  isEditionOtherAddress: boolean;
  uploadedFilesToLoad: Array<Documents> = []; // Documentos del cliente
  typeDocumentList: any = []; // Documentos a consignar
  clientType: number; // Es moral o física
  clientTypeName: string; // Es moral o física
  isEditionSubGroup: boolean; // Es edición de un subgrupo
  isEditionSubGroupFromTable: boolean;
  isNuevoSubgroup: boolean; // Bid para saber si van a crear un sugrupo o van a tomarlo de uno existente
  isFileValidated: boolean; // los archivos están validados?
  isView: boolean; // modo de ver detalle?
  disableFiles: boolean; // desacticvar archivos

  // Acceso a los arreglos de los modelos
  indexSubGroup: number;
  indexContact: number;
  indexContactCobranza: number;
  indexAddressFiscal: number;
  indexAddressClient: number;

  // Modelos usados en la vista moral
  group: Group;
  subGroup: Subgroup;
  generalData: GeneralData;
  contact: Contact;
  contactListCobranza: Contact;
  address: Address;

  // Modelos usados en la vista fisica
  groupFisico: GroupFisico;
  subgroupFisico: SubgroupFisico;
  isClientFisico: boolean;

  clientToEdit: number;
  fileUnSaved: boolean;
  isViewFromPolicie: string;
  groupNamePhisic: string;

  modalRefNewGroup: BsModalRef;

  // ------ VARIABLES PARA CARGA MASIVA CLIENTES FISICO/MORAL   -------------------------

  convertToJson!: any;
  convertToJsonMoral!: string;
  convertToJsonFisico!: string;
  infoCliente: any = [];
  esClienteFisico = false;
  infoClienteMoral: any = [];
  infoClienteFisico: any = [];
  detalleId = 0;
  editingClientFisico: ClienteFisico | null = null;
  editingClientMoral: ClienteMoral | null = null;
  countryListFiscalClientes: any = [];
  municipalityListClientes: any = [];
  municipalityListMoral: any = [];
  neighborhoodListClientes: any = [];
  stateListClient: any = [];
  municipalityListClientesFisico: any = [];
  neighborhoodListClientesFisico: any = [];
  typeContactForClientsCM: any = [];
  infoPolicyMoralTemp: any = [];
  infoPolicyFisicoTemp: any = [];

  infoClienteValidate: any = [];
  anexosZips: any = [];
  esValido = false;
  presenta = false;
  esCargaMasiva = false;
  public idCargaCliente: any;
  typeSocietiesListCM: any = [];
  errorZip = {active: false, msg: ''};
  public fileUploadZip: File;
  fileTypesZip: string[] = ['application/zip', 'application/x-zip-compressed'];
  archivosZip: string[] = [];
  esValidoProcesar = false;
  esValidaInformacion = true;
  groupsListCM: any = [];

  constructor(private ngWizardService: NgWizardService,
              private api: ApiService,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private shared: SharedService,
              private router: Router,
              private toastr: ToastrService) {

    this.datosGeneralesForm = new FormGroup({
      rfcSubrupo: new FormControl(null, [
        // Validators.pattern(regex.RFCMoral),
        Validators.required
      ]),
      razonSocial: new FormControl(null, [
        Validators.maxLength(100),
        Validators.pattern(regex.razonSocial),
        Validators.required
      ]),
      comentarios: new FormControl(null, [
        Validators.maxLength(100),
      ]),
      typeSociety: new FormControl(null, [
        Validators.required,
      ]),
      typePerson: new FormControl({value: this.clientType, disabled: true}),
      nuevoSubgrupo: new FormControl(true),
    });

    this.datosContactoForm = new FormGroup({
      nombreContact: new FormControl(null, [
        Validators.maxLength(100),
        Validators.required
      ]),
      phoneContact: new FormControl(null, [
        Validators.pattern(regex.telefonoSinGuion),
        Validators.required
      ]),
      emailContact: new FormControl(null, [
        Validators.maxLength(100),
        Validators.pattern(regex.correoElectronicolarge),
        Validators.required
      ]),
      listContact: new FormControl(),
      tipoContact: new FormControl(null, [
        Validators.required
      ])
    });

    this.datosAgrupadorForm = new FormGroup({
      group: new FormControl(null, [
        Validators.pattern(regex.alfanumerico2),
        Validators.maxLength(200),
        Validators.required
      ]),
    });

    this.direccionesForm = new FormGroup({
      numIntFiscal: new FormControl(null, [
        Validators.maxLength(20),
        Validators.required
      ]),
      numExtFiscal: new FormControl(null, [
        Validators.maxLength(20),
        Validators.required
      ]),
      countryFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      stateFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      municipalityCodeFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      zipCodeFiscal: new FormControl(null, [
        Validators.required
      ]),
      neighborhoodFiscal: new FormControl(null, [
        Validators.required
      ]),
      streetFiscal: new FormControl(null, [
        Validators.required
      ]),
      street: new FormControl(null, [
        Validators.required
      ]),
      numExt: new FormControl(),
      numInt: new FormControl(null, [
        Validators.required
      ]),
      sameAddres: new FormControl(),
      country: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      state: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      municipality: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      zipCode: new FormControl(null, [
        Validators.required
      ]),
      neighborhood: new FormControl(null, [
        Validators.required
      ]),
      countryOther: new FormControl({value: null, disabled: true}),
      stateOther: new FormControl({value: null, disabled: true}),
      municipalityOther: new FormControl({value: null, disabled: true}),
      zipCodeOther: new FormControl(),
      neighborhoodOther: new FormControl(),
      streetOther: new FormControl(),
      checkStreetOther: new FormControl(),
      addStretClient: new FormControl(),
      agregatCalle: new FormControl(),
      numExtOther: new FormControl(),
      numIntOther: new FormControl(),
      addressTypeOther: new FormControl()
    });

    this.datosGeneralesFisicoForm = new FormGroup({
      rfc: new FormControl(null, [
        Validators.pattern(regex.RFCFisicaSAT),
        Validators.required
      ]),
      nombre: new FormControl(null, [
        Validators.maxLength(100),
        Validators.required
      ]),
      apellidoPaterno: new FormControl(null, [
        Validators.maxLength(100),
        Validators.required
      ]),
      apellidoMaterno: new FormControl(null, [
        Validators.maxLength(100),
        Validators.required
      ]),

      typePerson: new FormControl({value: this.clientType, disabled: true}),

      phoneForm: new FormControl(null, [
        Validators.pattern(regex.telefonoSinGuion),
        Validators.required
      ]),

      email: new FormControl(null, [
        Validators.maxLength(100),
        Validators.pattern(regex.correoElectronico),
        Validators.required
      ]),
      group: new FormControl({value: null, disabled: false}),
    });

    this.direccionesFisicoForm = new FormGroup({
      numIntFiscal: new FormControl(null, [
        Validators.maxLength(20),
        Validators.required
      ]),
      numExtFiscal: new FormControl(null, [
        Validators.maxLength(20),
        Validators.required
      ]),
      countryFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      stateFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      municipalityCodeFiscal: new FormControl(null, [
        Validators.required
      ]),
      zipCodeFiscal: new FormControl(null, [
        Validators.required
      ]),
      neighborhoodFiscal: new FormControl(null, [
        Validators.required
      ]),
      streetFiscal: new FormControl(null, [
        Validators.required
      ]),
      street: new FormControl(null, [
        Validators.required
      ]),
      numExt: new FormControl(),
      numInt: new FormControl(null, [
        Validators.required
      ]),
      sameAddres: new FormControl(),
      country: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      state: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      municipality: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      zipCode: new FormControl(null, [
        Validators.required
      ]),
      neighborhood: new FormControl(null, [
        Validators.required
      ]),
      countryOther: new FormControl(),
      stateOther: new FormControl(),
      municipalityOther: new FormControl(),
      zipCodeOther: new FormControl(),
      neighborhoodOther: new FormControl(),
      streetOther: new FormControl(),
      checkStreetOther: new FormControl(),
      addStretClient: new FormControl(),
      agregatCalle: new FormControl(),
      numExtOther: new FormControl(),
      numIntOther: new FormControl()
    });

    this.invokeServiceGetCountries();
    this.invokeServiceGetStatesByCountry();

    this.typeSelectedContact = localStorage.getItem('selectTypeContact');
    this.clientToEdit = Number(localStorage.getItem('client-id'));
    this.isEditionSubGroupFromTable = JSON.parse(localStorage.getItem('isEditClient'));
    this.isView = JSON.parse(localStorage.getItem('isView'));
    this.isViewFromPolicie = JSON.parse(localStorage.getItem('isViewFromPolicie'));
    this.isNuevoSubgroup = true;
    this.disableFiles = false;
    this.isFileValidated = false;

    if (this.typeSelectedContact === '1' || this.typeSelectedContact === '2') {
      if (this.typeSelectedContact === '1') {
        this.isNewGroup = true;
      }
      this.totalSteps = 4;
      this.invokeServiceGetTypePerson();
    } else if (this.isEditionSubGroupFromTable === true) {
      this.clientTypeName = localStorage.getItem('typePerson');

      if (this.clientTypeName === 'MORAL') {
        this.typeSelectedContact = '1';
        this.totalSteps = 4;

        const subgroup = {
          clientId: this.clientToEdit,
          groupId: null
        };
        this.invokeServiceClientInformation(subgroup, false);
      } else {
        this.isClientFisico = true;
        this.typeSelectedContact = '3';
        this.totalSteps = 2;

        const subgroup = {
          clientId: this.clientToEdit
        };
        this.invokeServiceGetGroups();
        this.invokeServiceClientInformationPhysical(subgroup, false);
      }

      this.isNewGroup = false;
    } else if (this.isView) {
      this.clientTypeName = localStorage.getItem('typePerson');
      if (this.clientTypeName === 'MORAL') {
        this.typeSelectedContact = '1';
        this.totalSteps = 4;
        const subgroup = {
          clientId: this.clientToEdit,
          groupId: null
        };
        this.invokeServiceClientInformation(subgroup, false);
      } else {
        this.isClientFisico = true;
        this.typeSelectedContact = '3';
        this.totalSteps = 2;

        const subgroup = {
          clientId: this.clientToEdit
        };
        this.invokeServiceGetGroups();
        this.invokeServiceClientInformationPhysical(subgroup, false);

      }
      this.isNewGroup = false;

    } else {
      this.totalSteps = 2;
      this.isClientFisico = true;
      this.isNewGroup = false;
      this.invokeServiceGetGroups();
      this.invokeServiceGetTypePerson();
      this.invokeServiceAllSubGroupWhitoutGroup();
    }

    this.invokeServiceGetTypeSocieties();
    this.invokeServiceGetTypeConcactos();
    this.getTypesAddress();
  }
    ngOnInit() {
    this.isEditionOtherAddress = false;
    this.isStepEnable = true;
    this.indexSubGroup = 0;
    this.indexContact = 0;
    this.indexContactCobranza = 1;
    this.indexAddressFiscal = 0;
    this.indexAddressClient = 1;

    if (this.typeSelectedContact === '1' || this.typeSelectedContact === '2') {
      this.loadNewGroup();
      if (this.isNewGroup) {
        this.invokeServiceAllSubGroupWhitoutGroup();
      } else {
        this.invokeServiceGetGroups();
      }
    } else {
      this.loadNewGroupFisico();
    }
    if (this.isEditionSubGroupFromTable) {
      if (this.validateFileRequieredFail()) {
        this.isFileValidated = false;
      } else {
        this.isFileValidated = true;
      }
    }

    this.esClienteFisico = (localStorage.getItem('esClienteFisico') === 'SI') ? true : false;
    this.getTipoSociedad();
    this.getGrupoFisico();
    this.getTipoContacto();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges): void {
    if (changes[this.tipoClienteParam]) {
      console.log(this.tipoClienteParam);
      this.typeSelectedContact = localStorage.getItem('selectTypeContact');
    }
  }

  /**
   * función para cargar nuevo modelo Grupo
   */
  loadNewGroup() {
    this.group = new Group();
    this.group.subgroups = new Array<Subgroup>();
    this.loadNewSubgroup();
  }

  /**
   * función para cargar nuevo modelo Subgrupo
   */
  async loadNewSubgroup() {
    this.subGroup = new Subgroup();
    this.group.subgroups = new Array<Subgroup>();
    this.subGroup.contactList = new Array<Contact>();
    this.contact = new Contact();
    // this.subGroup.contactList.push(new Contact(3)); // Datos Contacto
    // this.subGroup.contactList.push(new Contact(4)); // Datos Cobranza
    this.subGroup.addressList.push(new Address(1)); // Fiscal
    this.subGroup.addressList.push(new Address(3)); // Cliente
    this.address = new Address(4);
  }

  /**
   * función para cargar nuevo modelo Grupo Físico
   */
  loadNewGroupFisico() {
    this.groupFisico = new GroupFisico();
    this.groupFisico.subgroups = new Array<SubgroupFisico>();
    this.loadNewSubgroupFisico();
  }

  /**
   * función para cargar nuevo modelo Subgrupo Físico
   */
  loadNewSubgroupFisico() {
    this.subgroupFisico = new SubgroupFisico();
    this.subgroupFisico.addressList.push(new AddressFisico(1)); // Fiscal
    this.subgroupFisico.addressList.push(new AddressFisico(3)); // Cliente
  }

  /**
   * función para desactivar las pestañas tipo de persona moral
   */
  disableAll() {
    this.datosGeneralesForm.disable();
    this.datosContactoForm.disable();
    this.direccionesForm.disable();
    this.datosAgrupadorForm.disable();
    this.disableFiles = true;
  }

  /**
   * función para desactivar las pestañas tipo de persona fisica
   */
  disableAllFisico() {
    this.datosGeneralesFisicoForm.disable();
    this.direccionesFisicoForm.disable();
    this.disableFiles = true;
  }

  /**
   * funcion que se ejecuta cada vez que se carga un nuevo grupo, carga los datos predefinidos de tipo de persona moral
   */
  inicilizeDataEveryNewGroup() {
    this.subGroup.general_data.clientType = this.clientType;
    this.datosGeneralesForm.get('typePerson').setValue(this.subGroup.general_data.clientType);
  }

  /**
   * funcion que se ejecuta cada vez que se carga un nuevo grupo, carga los datos predefinidos de tipo de persona fisica
   */
  inicilizeDataEveryNewPhysicalClient() {
    if (this.subgroupFisico) {
      this.subgroupFisico.general_data.clientType = this.clientType;
      this.datosGeneralesFisicoForm.get('typePerson').setValue(this.subgroupFisico.general_data.clientType);
    }
  }

  /**
   * funcion que carga las direcciones fiscales
   */
  onEditSubGroupChangeAddressListFiscal() {
    this.municipalityListFiscal = [];
    this.zipCodeListFiscal = [];
    this.neighborhoodListFiscal = [];
    this.streetsListFiscal = [];

    if (this.isClientFisico) {
      const address = this.subgroupFisico && this.subgroupFisico.addressList[this.indexAddressFiscal];
      const state = address && address.state ? address.state.toUpperCase() : '';

      this.invokeServiceGetMunicipalyByStateName(
        this.converterToKeyValueClass(null, state),
        this._FISCAL
      );

      const municipality = address && address.municipalityCode ? address.municipalityCode : '';
      this.invokeServiceGetZipCodesByMunicipalyCode(
        this.converterToKeyValueClass(municipality, null),
        this._FISCAL
      );

      // Invocamos las colonia
      const zipcode = address && address.zipCode ? address.zipCode : '';
      this.invokeServiceGetColoniesByZipCode(
        this.converterToKeyValueClass(zipcode, zipcode),
        this._FISCAL
      );

      const street = address && address.streetCode || '';
      if (street) {
        this.invokeServiceGetStreetsByColonie(
          this.converterToKeyValueClass(address.neighborhoodCode, null),
          this._FISCAL
        );
      }
    } else {
      this.invokeServiceGetMunicipalyByStateName
      (this.converterToKeyValueClass(null, this.subGroup.addressList[this.indexAddressFiscal].state.toUpperCase()), this._FISCAL);

      this.invokeServiceGetZipCodesByMunicipalyCode(
        this.converterToKeyValueClass(this.subGroup.addressList[this.indexAddressFiscal].municipalityCode, null), this._FISCAL);

      // Invocamos las colonia
      this.invokeServiceGetColoniesByZipCode(
        this.converterToKeyValueClass(
          this.subGroup.addressList[this.indexAddressFiscal].zipCode
          , this.subGroup.addressList[this.indexAddressFiscal].zipCode), this._FISCAL);


      if (this.subGroup.addressList[this.indexAddressFiscal].streetCode !== null
        && this.subGroup.addressList[this.indexAddressFiscal].streetCode !== undefined) {
        this.invokeServiceGetStreetsByColonie(
          this.converterToKeyValueClass(this.subGroup.addressList[this.indexAddressFiscal].neighborhoodCode, null), this._FISCAL);
        // this.direccionesForm.get('streetFiscal').setValue(this.subGroup.addressList[this.indexAddressFiscal].streetCode);
      }
    }
  }

  /**
   * funcion que carga las direcciones del cliente
   */
  onEditSubGroupChangeAddressListClient() {
    this.municipalityList = [];
    this.zipCodeList = [];
    this.neighborhoodList = [];
    this.streetsList = [];

    this.invokeServiceGetMunicipalyByStateName(
      this.converterToKeyValueClass(null,
        this.isClientFisico ? this.subgroupFisico.addressList[this.indexAddressClient].state.toUpperCase()
          : this.subGroup.addressList[this.indexAddressClient].state.toUpperCase()), this._GENERAL);

    this.invokeServiceGetZipCodesByMunicipalyCode(
      this.converterToKeyValueClass(this.isClientFisico ?
        this.subgroupFisico.addressList[this.indexAddressClient].municipalityCode
        : this.subGroup.addressList[this.indexAddressClient].municipalityCode, null), this._GENERAL);

    // Invocamos la colonia
    if (this.isClientFisico) {
      this.invokeServiceGetColoniesByZipCode(
        this.converterToKeyValueClass(
          this.subgroupFisico.addressList[this.indexAddressClient].zipCode
          , this.subgroupFisico.addressList[this.indexAddressClient].zipCode), this._GENERAL);

      if (this.subgroupFisico.addressList[this.indexAddressClient].streetCode !== null
        && this.subgroupFisico.addressList[this.indexAddressClient].streetCode !== undefined) {
        this.invokeServiceGetStreetsByColonie(
          this.converterToKeyValueClass(this.subgroupFisico.addressList[this.indexAddressClient].neighborhoodCode, null), this._GENERAL);
        // this.direccionesForm.get('street').setValue(this.subGroup.addressList[this.indexAddressClient].streetCode);
      }
    } else {
      this.invokeServiceGetColoniesByZipCode(
        this.converterToKeyValueClass(
          this.subGroup.addressList[this.indexAddressClient].zipCode
          , this.subGroup.addressList[this.indexAddressClient].zipCode), this._GENERAL);

      if (this.subGroup.addressList[this.indexAddressClient].streetCode !== null
        && this.subGroup.addressList[this.indexAddressClient].streetCode !== undefined) {
        this.invokeServiceGetStreetsByColonie(
          this.converterToKeyValueClass(this.subGroup.addressList[this.indexAddressClient].neighborhoodCode, null), this._GENERAL);
        // this.direccionesForm.get('street').setValue(this.subGroup.addressList[this.indexAddressClient].streetCode);
      }
    }
  }

  /**
   * funcion que encapsula el valos y la key en una variable
   */
  converterToKeyValueClass(cKey: string, cValue: string): any {
    const keyValue = {cKey, cValue};
    return keyValue;
  }

  /**
   * funcion que manda a llamar los subgrupos de un grupo
   */
  getSubGruoupSelected(event) {
    if (event !== undefined) {
      if (this.isClientFisico) {
        const subgroup = {
          clientId: this.subGroupList.find((ELEMENT) => ELEMENT.rfc === event).clientId,
        };
        // this.invokeServiceGetGroups();
        this.invokeServiceClientInformationPhysical(subgroup, false);
      } else {
        const subgroup = {
          clientId: this.subGroupList.find((ELEMENT) => ELEMENT.rfc === event).clientId,
          groupId: null
        };
        this.invokeServiceClientInformation(subgroup, false);
      }
    }
  }

  /**
   * funcion que manda a llamar los subgrupos existentes
   */
  getGroupSelected(event) {
    if (event !== undefined) {
      if (this.group) {
        this.group.groupId = event.id;
      } else {
        this.group = new Group();
        this.group.groupId = event.id;
      }

      this.invokeServiceGetSubgroups(this.group.groupId);
      const group = {
        clientId: null,
        groupId: this.group.groupId
      };
      this.invokeServiceClientInformation(group, true);
    }
  }

  /**
   * funcion que filtra los grupos
   */
  filter(event) {
    const str = event.target.value;
    if (typeof str === 'string') {
      this.groupsList = this.groupsListFilter.filter(a => a.toLowerCase()
        .startsWith(str.toLowerCase()));
    }
  }

  /**
   * funcion que carga la información de un País seleccionado
   */
  getCountrySelectedFiscal(event) {
    this.dataAddressFiscal.country = event.target.value;
    this.dataAddressClient.country = event.target.value;
  }

  /**
   * funcion que obtiene el acrónimo de tipo de sociedad
   */
  getTypeSocietySelected(event) {
    if (event !== undefined) {
      this.acronimoTypeSociety = (event.description).substring(
        (event.description).lastIndexOf('(') + 1,
        (event.description).lastIndexOf(')')
      );
      this.subGroup.general_data.typeSocietyId = event.id;
      this.subGroup.general_data.name = '';
    } else {
      this.acronimoTypeSociety = '';
    }
  }

  /**
   * funcion que agrega el acrónimo del tipo de socidad a la razón social
   */
  onChangeRazonSocial(event) {
    if (this.subGroup.general_data.name !== undefined && this.subGroup.general_data.name.lastIndexOf(this.acronimoTypeSociety)) {
      const index = this.subGroup.general_data.name.lastIndexOf(this.acronimoTypeSociety); // Buscamos la posición del acrónimo
      const lengthName = this.subGroup.general_data.name.length;
      const lengtAcronim = this.acronimoTypeSociety.length;

      if (!((index + lengtAcronim) === lengthName)) { // Si está al final entonces si tiene el acrónomimo
        this.subGroup.general_data.name = event.target.value + ' ' + this.acronimoTypeSociety;
      }
    } else {
      this.subGroup.general_data.name = event.target.value + ' ' + this.acronimoTypeSociety;
    }
  }

  /*EVENTOS DE AUTOCOMPLETES*/
  selectBussinesName() {
  }

  /**
   * funcion que limpia el nombre del subrupo
   */
  clearBussinesName() {
    this.generalData.name = null;
  }

  /**
   * funcion que carga los datos relacionados a un estado
   */
  selectStateFiscaly(event) {
    if (event !== undefined) {
      this.subGroup.addressList[this.indexAddressFiscal].state = event.cValue;
      this.clearMunicipalityFiscal();
      this.invokeServiceGetMunicipalyByStateName(event, this._FISCAL);
    }
  }

  /**
   * funcion que carga los datos relacionados a un estado persona física
   */

  selectStatePhysic(event) {
    if (event !== undefined) {
      this.subgroupFisico.addressList[this.indexAddressFiscal].state = event.cValue;
      this.clearMunicipalityPhysic();
      this.invokeServiceGetMunicipalyByStateName(event, this._FISCAL);
    }

  }

  /**
   * funcion que limpia el selector de estado fiscal y sus dependientes
   */
  clearStateFiscal() {
    this.subGroup.addressList[this.indexAddressFiscal].stateCode = null;
    this.subGroup.addressList[this.indexAddressFiscal].state = '';
    this.clearMunicipalityFiscal();
    this.clearMunicipalityFiscalList();
  }

  /**
   * funcion que limpia el selector de estado fiscal persona física y sus dependientes
   */
  clearStatePhysical() {
    this.subgroupFisico.addressList[this.indexAddressFiscal].stateCode = null;
    this.subgroupFisico.addressList[this.indexAddressFiscal].state = '';
    this.clearMunicipalityPhysic();
    this.clearMunicipalityFiscalList();

  }

  selectStreetFiscal(event) {
    if (event !== undefined) {
    }
  }

  /**
   * funcion que limpia el selector de calle fiscal y sus dependientes
   */
  clearStreetFiscal() {
    this.subGroup.addressList[this.indexAddressFiscal].streetCode = null;
    this.subGroup.addressList[this.indexAddressFiscal].streetName = '';
    this.subGroup.addressList[this.indexAddressFiscal].street = '';
  }

  /**
   * funcion que limpia el selector de calle fiscal persona física y sus dependientes
   */
  clearStreetPhysical() {
    this.subgroupFisico.addressList[this.indexAddressFiscal].streetCode = null;
    this.subgroupFisico.addressList[this.indexAddressFiscal].street = '';
  }

  /**
   * funcion que limpia el selector de calle fiscal persona física y sus dependientes
   */
  clearStreetFiscalAndList() {
    this.subGroup.addressList[this.indexAddressFiscal].streetCode = null;
    this.subGroup.addressList[this.indexAddressFiscal].streetName = '';
    this.subGroup.addressList[this.indexAddressFiscal].street = '';
    this.clearStreetFiscalList();

  }

  /**
   * funcion que limpia el selector de calle fiscal persona física y sus dependientes
   */
  clearStreetPhysicalAndList() {
    this.subgroupFisico.addressList[this.indexAddressFiscal].streetCode = null;
    this.subgroupFisico.addressList[this.indexAddressFiscal].street = '';
    this.clearStreetFiscalList();

  }

  /**
   * funcion que limpia el selector de calle fiscal
   */
  clearStreetFiscalList() {
    this.streetsListFiscal = [];
  }

  /**
   * funcion que limpia el selector de calle cliente
   */
  clearStreetList() {
    this.streetsList = [];
  }

  /**
   * funcion que limpia el selector de calle adicional
   */
  clearStreetOtherList() {
    this.streetsListOther = [];
  }

  /**
   * funcion que limpia el selector de calle adicional
   */
  clearStreetOtherListEdit() {
    this.streetsListOther = [];
  }

  /**
   * funcion que obtiene la información de la calle seleccionada
   */
  selectStreet(event) {
    this.subGroup.addressList[this.indexAddressClient].street = event.cValue;
    this.subGroup.addressList[this.indexAddressClient].streetName = event.cValue;
  }

  /**
   * funcion que obtiene la información de la calle seleccionada Persona física
   */
  selectStreetPhysica(event) {
    this.subgroupFisico.addressList[this.indexAddressClient].street = event.cValue;
  }

  onChangeSearchStreet(event) {
  }

  /**
   * funcion que obtiene limpia la información del modelo de calle
   */
  clearStreet() {
    this.subGroup.addressList[this.indexAddressClient].streetCode = null;
    this.subGroup.addressList[this.indexAddressClient].streetName = '';
    this.subGroup.addressList[this.indexAddressClient].street = '';
  }

  /**
   * funcion que limpia la información del modelo de calle persona física
   */
  clearStreetPhysica() {
    this.subgroupFisico.addressList[this.indexAddressClient].streetCode = null;
    this.subgroupFisico.addressList[this.indexAddressClient].street = '';
  }

  /**
   * funcion que limpia la información del modelo de calle y el selector
   */
  clearStreetAndList() {
    this.subGroup.addressList[this.indexAddressClient].streetCode = null;
    this.subGroup.addressList[this.indexAddressClient].streetName = '';
    this.subGroup.addressList[this.indexAddressClient].street = '';
    this.clearStreetList();
  }

  /**
   * funcion que limpia la información del modelo de calle y el selector persona física
   */
  clearStreetAndListPhysica() {
    this.subgroupFisico.addressList[this.indexAddressClient].streetCode = null;
    this.subgroupFisico.addressList[this.indexAddressClient].street = '';
    this.clearStreetList();
  }

  /**
   * funcion que obtiene limpia la información del modelo de calle
   */
  selectStreetOther(event) {
    if (event !== undefined) {
      this.address.street = event.cValue;
    }
  }

  /**
   * funcion que obtiene la información de la calle adicional
   */
  selectStreetOtherEdit(event) {
    if (event !== undefined) {
      this.dataOthersAddressEdit.street = event.cValue;
    }
  }

  /**
   * funcion que limpia la información de la calle cliente
   */
  clearStreetOther() {
    this.address.streetCode = null;
    this.address.streetName = '';
    this.address.street = '';
  }

  /**
   * funcion que limpia la información de la calle cliente y el selector
   */
  clearStreetOtherAndList() {
    this.address.street = '';
    this.address.streetName = '';
    this.address.streetCode = null;
    this.clearStreetOtherList();
  }

  /**
   * funcion que limpia la información de la calle adicional
   */
  clearStreetOtherEdit() {
    this.dataOthersAddressEdit.street = null;
    this.dataOthersAddressAdds.street = null;
  }

  /**
   * funcion que obtiene la información del estado cliente y limpia sus dependientes
   */
  selectState(event) {
    if (event !== undefined) {
      this.subGroup.addressList[this.indexAddressClient].state = event.cValue;
      this.invokeServiceGetMunicipalyByStateName(event, this._GENERAL);
      this.clearMunicipality();
    }
  }

  /**
   * funcion que obtiene la información del estado cliente y limpia sus dependientes persona física
   */
  selectStatePhysal(event) {
    if (event !== undefined) {
      this.subgroupFisico.addressList[this.indexAddressClient].state = event.cValue;
      this.invokeServiceGetMunicipalyByStateName(event, this._GENERAL);
      this.clearMunicipalityPhysical();
    }
  }

  onChangeSearchState(event) {
  }

  /**
   * funcion que limpia la información del estado cliente y limpia sus dependientes
   */
  clearState() {
    this.subGroup.addressList[this.indexAddressClient].stateCode = null;
    this.subGroup.addressList[this.indexAddressClient].state = '';
    this.clearMunicipality();
    this.clearMunicipalityList();
  }

  /**
   * funcion que limpia la información del estado cliente y limpia sus dependientes persona física
   */
  clearStatePhisycal() {
    this.subgroupFisico.addressList[this.indexAddressClient].stateCode = null;
    this.subgroupFisico.addressList[this.indexAddressClient].state = '';
    this.clearMunicipality();
    this.clearMunicipalityList();
  }

  /**
   * funcion que limpia la información de la colonia fiscal y limpia sus dependientes
   */
  selectNeighborhoodFiscal(event) {
    if (event !== undefined) {
      this.subGroup.addressList[this.indexAddressFiscal].neighborhood = '';
      this.clearStreetFiscalAndList();
      this.invokeServiceGetStreetsByColonie(event, this._FISCAL);
    }
  }

  /**
   * funcion que limpia la información de la colonia fiscal y limpia sus dependientes persona física
   */
  selectNeighborhoodPhysical(event) {
    if (event !== undefined) {
      this.clearStreetPhysicalAndList();
      this.invokeServiceGetStreetsByColonie(event, this._FISCAL);
    }

  }

  /**
   * funcion que limpia la información de la colonia y calle fiscal y limpia sus dependientes
   */
  clearNeighborhoodFiscal() {
    this.subGroup.addressList[this.indexAddressFiscal].streetCode = null;
    this.subGroup.addressList[this.indexAddressFiscal].streetName = '';
    this.subGroup.addressList[this.indexAddressFiscal].street = '';
    this.subGroup.addressList[this.indexAddressFiscal].neighborhoodCode = null;
    this.subGroup.addressList[this.indexAddressFiscal].neighborhood = '';
    this.clearStreetFiscalList();
  }

  /**
   * funcion que limpia la información de la colonia y calle fiscal y limpia sus dependientes persona física
   */
  clearNeighborhoodPhysical() {
    this.subgroupFisico.addressList[this.indexAddressFiscal].streetCode = null;
    this.subgroupFisico.addressList[this.indexAddressFiscal].street = '';
    this.subgroupFisico.addressList[this.indexAddressFiscal].neighborhoodCode = null;
    this.clearStreetFiscalList();
  }

  /**
   * funcion que limpia la lista de colonia fiscal
   */
  clearNeighborhoodFiscalList() {
    this.neighborhoodListFiscal = [];
  }

  /**
   * funcion que limpia la lista de colonia cliente
   */
  clearNeighborhoodList() {
    this.neighborhoodList = [];
  }

  /**
   * funcion que limpia la lista de colonia adicionales
   */
  clearNeighborhoodOtherList() {
    this.neighborhoodListOther = [];
  }

  /**
   * funcion que limpia la lista de colonia adicionales
   */
  clearNeighborhoodOtherListEdit() {
    this.neighborhoodListOther = [];
  }

  /**
   * funcion que carga los dependientes de colonia según la seleccionada
   */

  selectNeighborhood(event) {
    if (event !== undefined) {
      this.subGroup.addressList[this.indexAddressClient].neighborhood = event.cValue;
      this.clearStreetAndList();
      this.invokeServiceGetStreetsByColonie(event, this._GENERAL);
    }
  }

  /**
   * funcion que carga los dependientes de colonia según la seleccionada persona física
   */
  selectNeighborhoodPhysica(event) {
    if (event !== undefined) {
      this.clearStreetAndListPhysica();
      this.invokeServiceGetStreetsByColonie(event, this._GENERAL);
    }
  }

  /**
   * funcion que limpia calle y colonia cliente del modelo y sus dependientes
   */
  clearNeighborhood() {
    this.subGroup.addressList[this.indexAddressClient].streetCode = null;
    this.subGroup.addressList[this.indexAddressClient].streetName = '';
    this.subGroup.addressList[this.indexAddressClient].street = '';
    this.subGroup.addressList[this.indexAddressClient].neighborhoodCode = null;
    this.subGroup.addressList[this.indexAddressClient].neighborhood = '';
    this.clearStreetList();
  }

  /**
   * funcion que limpia calle y colonia cliente del modelo y sus dependientes perdona física
   */
  clearNeighborhoodPhysica() {
    this.subgroupFisico.addressList[this.indexAddressClient].streetCode = null;
    this.subgroupFisico.addressList[this.indexAddressClient].street = '';
    this.subgroupFisico.addressList[this.indexAddressClient].neighborhoodCode = null;
    this.clearStreetList();
  }

  /**
   * funcion que limpia calle y colonia fiscal del modelo y sus dependientes
   */
  selectMunicipalityFiscal(event) {
    if (event !== undefined) {
      this.subGroup.addressList[this.indexAddressFiscal].municipality = '';
      this.invokeServiceGetZipCodesByMunicipalyCode(event, this._FISCAL);
      this.clearZipCodeFiscal();
    }

  }

  /**
   * funcion que obtiene códigos postales de un municipio
   */
  selectMunicipalityPhysical(event) {
    if (event !== undefined) {
      this.invokeServiceGetZipCodesByMunicipalyCode(event, this._FISCAL);
      this.clearZipCodePhysical();
    }

  }

  /**
   * funcion que obtiene estado y municipio
   */
  selectStateMunipalityByZipCode(typeAddres: string) {
    let zipCode;
    if (this.isClientFisico) { // Si es cliente físico
      if (typeAddres === this._FISCAL) {
        zipCode = this.subgroupFisico.addressList[this.indexAddressFiscal].zipCode;
      } else if (typeAddres === this._GENERAL) {
        zipCode = this.subgroupFisico.addressList[this.indexAddressClient].zipCode;
      }
    } else {
      if (typeAddres === this._FISCAL) {
        zipCode = this.subGroup.addressList[this.indexAddressFiscal].zipCode;
      } else if (typeAddres === this._GENERAL) {
        zipCode = this.subGroup.addressList[this.indexAddressClient].zipCode;
      } else if (typeAddres === this._ADICIONAL) {
        zipCode = this.address.zipCode;
      }
    }


    this.api.getCheckZipeCode(zipCode).then((result: any) => {
      if (result != null) {
        if (result) {
          this.invokeStateByZipCode(zipCode, typeAddres);
          let value;
          if (this.isClientFisico) {

            if (typeAddres === this._FISCAL) {
              value = {
                cKey: this.subgroupFisico.addressList[this.indexAddressFiscal].zipCode,
                cValue: this.subgroupFisico.addressList[this.indexAddressFiscal].zipCode
              };
            } else if (typeAddres === this._GENERAL) {
              value = {
                cKey: this.subgroupFisico.addressList[this.indexAddressClient].zipCode,
                cValue: this.subgroupFisico.addressList[this.indexAddressClient].zipCode
              };
            }
          } else {

            if (typeAddres === this._FISCAL) {
              value = {
                cKey: this.subGroup.addressList[this.indexAddressFiscal].zipCode,
                cValue: this.subGroup.addressList[this.indexAddressFiscal].zipCode
              };
            } else if (typeAddres === this._GENERAL) {
              value = {
                cKey: this.subGroup.addressList[this.indexAddressClient].zipCode,
                cValue: this.subGroup.addressList[this.indexAddressClient].zipCode
              };
            } else if (typeAddres === this._ADICIONAL) {
              value = {
                cKey: this.address.zipCode,
                cValue: this.address.zipCode
              };
            }
          }

          if (this.isClientFisico) {
            if (typeAddres === this._FISCAL) {
              this.selectZipCodePhysical(value);
            } else if (typeAddres === this._GENERAL) {
              this.selectZipCodePhysica(value);
            }
          } else {
            if (typeAddres === this._FISCAL) {
              this.selectZipCodeFiscal(value);
            } else if (typeAddres === this._GENERAL) {
              this.selectZipCode(value);
            } else if (typeAddres === this._ADICIONAL) {
              this.selectZipCodeOther(value);
            }
          }

        } else {
          this.toastr.warning('El código postal no existe, por favor intenta con otro', 'Notificación');
          if (this.isClientFisico) {
            if (typeAddres === this._FISCAL) {
              this.clearStatePhysical();
            } else if (typeAddres === this._GENERAL) {
              this.clearStatePhisycal();
            }
          } else {
            if (typeAddres === this._FISCAL) {
              this.clearStateFiscal();
            } else if (typeAddres === this._GENERAL) {
              this.clearState();
            } else if (typeAddres === this._ADICIONAL) {
              this.clearStateOther();
            }
          }
        }
      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });

  }

  invokeStateByZipCode(zipCode, typeAddress) {
    this.api.getStatesByZipeCode(zipCode).then((result: any) => {
      if (result != null) {

        if (this.isClientFisico) {
          if (this._FISCAL === typeAddress) {
            this.subgroupFisico.addressList[this.indexAddressFiscal].stateCode = result.cKey;
            this.subgroupFisico.addressList[this.indexAddressFiscal].state = result.cValue;
            this.invokeServiceGetMunicipalyByStateName(result, this._FISCAL);
          } else if (this._GENERAL === typeAddress) {
            this.subgroupFisico.addressList[this.indexAddressClient].stateCode = result.cKey;
            this.subgroupFisico.addressList[this.indexAddressClient].state = result.cValue;
            this.invokeServiceGetMunicipalyByStateName(result, this._GENERAL);
          }
        } else {
          if (this._FISCAL === typeAddress) {
            this.subGroup.addressList[this.indexAddressFiscal].stateCode = result.cKey;
            this.subGroup.addressList[this.indexAddressFiscal].state = result.cValue;
            this.invokeServiceGetMunicipalyByStateName(result, this._FISCAL);
          } else if (this._GENERAL === typeAddress) {
            this.subGroup.addressList[this.indexAddressClient].stateCode = result.cKey;
            this.subGroup.addressList[this.indexAddressClient].state = result.cValue;
            this.invokeServiceGetMunicipalyByStateName(result, this._GENERAL);
          } else if (this._ADICIONAL === typeAddress) {
            this.address.stateCode = result.cKey;
            this.address.state = result.cValue;
            this.invokeServiceGetMunicipalyByStateName(result, this._ADICIONAL);
          }
        }


      } else {
        if (this._FISCAL === typeAddress) {
          this.subGroup.addressList[this.indexAddressFiscal].stateCode = null;
          this.subGroup.addressList[this.indexAddressFiscal].state = null;
        } else if (this._GENERAL === typeAddress) {
          this.subGroup.addressList[this.indexAddressClient].stateCode = null;
          this.subGroup.addressList[this.indexAddressClient].state = null;
        } else if (this._ADICIONAL === typeAddress) {
          this.address.stateCode = null;
          this.address.state = null;
        }
      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });
  }

  invokeTownByZipCode(zipCode, typeAddress) {
    this.api.getTownByPostcode(zipCode).then((result: any) => {
      if (result != null) {
        if (this.isClientFisico) {
          if (typeAddress === this._FISCAL) {
            this.subgroupFisico.addressList[this.indexAddressFiscal].municipalityCode = result.cKey;
          } else if (typeAddress === this._GENERAL) {
            this.subgroupFisico.addressList[this.indexAddressClient].municipalityCode = result.cKey;
          }
        } else {
          if (this.isClientFisico) {
            if (typeAddress === this._FISCAL) {
              this.subgroupFisico.addressList[this.indexAddressFiscal].municipalityCode = result.cKey;
            } else if (typeAddress === this._GENERAL) {
              this.subgroupFisico.addressList[this.indexAddressClient].municipalityCode = result.cKey;
            }
          } else {
            if (typeAddress === this._FISCAL) {
              this.subGroup.addressList[this.indexAddressFiscal].municipalityCode = result.cKey;
              this.subGroup.addressList[this.indexAddressFiscal].municipality = result.cValue;
            } else if (typeAddress === this._GENERAL) {
              this.subGroup.addressList[this.indexAddressClient].municipalityCode = result.cKey;
              this.subGroup.addressList[this.indexAddressClient].municipality = result.cValue;
            } else if (typeAddress === this._ADICIONAL) {
              this.address.municipalityCode = result.cKey;
              this.address.municipality = result.cValue;
            }
          }
        }
      } else {
        if (this.isClientFisico) {
          if (typeAddress === this._FISCAL) {
            this.subgroupFisico.addressList[this.indexAddressFiscal].municipalityCode = null;
          } else if (typeAddress === this._GENERAL) {
            this.subgroupFisico.addressList[this.indexAddressClient].municipalityCode = null;
          }
        } else {
          if (typeAddress === this._FISCAL) {
            this.subGroup.addressList[this.indexAddressFiscal].municipalityCode = null;
            this.subGroup.addressList[this.indexAddressFiscal].municipality = null;
          } else if (typeAddress === this._GENERAL) {
            this.subGroup.addressList[this.indexAddressClient].municipalityCode = null;
            this.subGroup.addressList[this.indexAddressClient].municipality = null;
          } else if (typeAddress === this._ADICIONAL) {
            this.address.municipalityCode = null;
            this.address.municipality = null;
          }
        }

      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });
  }

  /**
   * funcion que limpia los municipios, calles, colonias y códigos postales fiscales del model y sus dependientes
   */
  clearMunicipalityFiscal() {

    this.subGroup.addressList[this.indexAddressFiscal].municipalityCode = null;
    this.subGroup.addressList[this.indexAddressFiscal].municipality = '';
    this.subGroup.addressList[this.indexAddressFiscal].zipCode = null;
    this.clearZipCodeFiscalList();
    this.subGroup.addressList[this.indexAddressFiscal].neighborhoodCode = null;
    this.subGroup.addressList[this.indexAddressFiscal].neighborhood = '';
    this.clearNeighborhoodFiscalList();
    this.subGroup.addressList[this.indexAddressFiscal].streetCode = null;
    this.subGroup.addressList[this.indexAddressFiscal].streetName = '';
    this.subGroup.addressList[this.indexAddressFiscal].street = '';
    this.clearStreetFiscalList();
  }

  /**
   * funcion que limpia los municipios, calles, colonias y códigos postales fiscales del model y sus dependientes persona física
   */
  clearMunicipalityPhysic() {

    this.subgroupFisico.addressList[this.indexAddressFiscal].municipalityCode = null;
    this.subgroupFisico.addressList[this.indexAddressFiscal].zipCode = null;
    this.clearZipCodeFiscalList();
    this.subgroupFisico.addressList[this.indexAddressFiscal].neighborhoodCode = null;
    this.clearNeighborhoodFiscalList();
    this.subgroupFisico.addressList[this.indexAddressFiscal].streetCode = null;
    this.subgroupFisico.addressList[this.indexAddressFiscal].street = '';
    this.clearStreetFiscalList();
  }

  clearMunicipalityFiscalList() {
    this.municipalityListFiscal = [];
  }

  /**
   * funcion que limpia los municipios, calles, colonias y códigos postales cliente del model y sus dependientes
   */
  clearMunicipality() {

    this.subGroup.addressList[this.indexAddressClient].municipalityCode = null;
    this.subGroup.addressList[this.indexAddressClient].municipality = '';
    this.subGroup.addressList[this.indexAddressClient].zipCode = null;
    this.clearZipCodeList();
    this.subGroup.addressList[this.indexAddressClient].neighborhoodCode = null;
    this.subGroup.addressList[this.indexAddressClient].neighborhood = '';
    this.clearNeighborhoodList();
    this.subGroup.addressList[this.indexAddressClient].street = '';
    this.subGroup.addressList[this.indexAddressClient].streetName = '';
    this.subGroup.addressList[this.indexAddressClient].streetCode = null;
    this.clearStreetList();
  }

  /**
   * funcion que limpia los municipios, calles, colonias y códigos postales cliente del model y sus dependientes persona física
   */
  clearMunicipalityPhysical() {

    this.subgroupFisico.addressList[this.indexAddressClient].municipalityCode = null;
    this.subgroupFisico.addressList[this.indexAddressClient].zipCode = null;
    this.clearZipCodeList();
    this.subgroupFisico.addressList[this.indexAddressClient].neighborhoodCode = null;
    this.clearNeighborhoodList();
    this.subgroupFisico.addressList[this.indexAddressClient].street = '';
    this.subgroupFisico.addressList[this.indexAddressClient].streetCode = null;
    this.clearStreetList();
  }

  /**
   * funcion que limpia los municipios, calles, colonias y códigos postales cliente del model y sus dependientes persona física
   */
  clearMunicipalityOther() {

    this.address.municipalityCode = null;
    this.address.municipality = '';
    this.address.zipCode = null;
    this.clearZipCodeOtherList();
    this.address.neighborhoodCode = null;
    this.address.neighborhood = null;
    this.clearNeighborhoodOtherList();
    this.address.street = '';
    this.address.streetCode = null;
    this.address.streetName = '';
    this.clearStreetOtherList();
  }

  /**
   * funcion que limpia los municipios, calles, colonias y códigos postales adicionales del model y sus dependientes persona física
   */
  clearMunicipalityOtherEdit() {

    this.dataOthersAddressEdit.municipalityCode = null;
    this.dataOthersAddressAddsEdit.municipality = null;
    this.dataOthersAddressEdit.zipCode = null;
    this.dataOthersAddressAddsEdit.zipCode = null;
    this.clearZipCodeOtherListEdit();
    this.dataOthersAddressEdit.neighborhoodCode = null;
    this.dataOthersAddressAddsEdit.neighborhood = null;
    this.clearNeighborhoodOtherListEdit();
    this.dataOthersAddressEdit.street = null;
    this.dataOthersAddressAddsEdit.street = null;
    this.clearStreetOtherListEdit();
  }

  /**
   * funcion que limpia el selector de municipios
   */
  clearMunicipalityList() {
    this.municipalityList = [];
  }

  /**
   * funcion que limpia el selector de municipios adicionales
   */
  clearMunicipalityOtherList() {
    this.municipalityListOther = [];
  }

  /**
   * Función para cargar los códigos postales por municipio
   */
  selectMunicipality(event) {
    if (event !== undefined) {
      this.subGroup.addressList[this.indexAddressClient].municipality = event.cValue;
      this.invokeServiceGetZipCodesByMunicipalyCode(event, this._GENERAL);
      this.clearZipCode();
    }
  }

  /**
   * Función para cargar los códigos postales por municipio persona física
   */
  selectMunicipalityPhysica(event) {
    if (event !== undefined) {
      this.invokeServiceGetZipCodesByMunicipalyCode(event, this._GENERAL);
      this.clearZipCodePhysica();
    }
  }

  /**
   * Función para cargar los colonias por código postal
   */
  selectZipCodeFiscal(event) {
    if (event !== undefined) {
      this.invokeServiceGetColoniesByZipCode(event, this._FISCAL);
      this.clearNeighborhoodFiscal();
    }
  }

  /**
   * Función para cargar los colonias por código postal
   */
  selectZipCodePhysical(event) {
    if (event !== undefined) {
      this.invokeServiceGetColoniesByZipCode(event, this._FISCAL);
      this.clearNeighborhoodPhysical();
    }
  }

  onChangeSearchZipCodeFiscal(event) {
  }

  /**
   * Función para limpia el código postal fiscal y sus dependientes
   */
  clearZipCodeFiscal() {
    this.subGroup.addressList[this.indexAddressFiscal].street = '';
    this.subGroup.addressList[this.indexAddressFiscal].streetName = '';
    this.subGroup.addressList[this.indexAddressFiscal].streetCode = null;
    this.subGroup.addressList[this.indexAddressFiscal].neighborhoodCode = null;
    this.subGroup.addressList[this.indexAddressFiscal].neighborhood = '';
    this.subGroup.addressList[this.indexAddressFiscal].zipCode = null;
    this.clearNeighborhoodFiscalList();
    this.clearStreetFiscalList();
  }

  /**
   * Función para limpia el código postal fiscal y sus dependientes de una persona fisica
   */
  clearZipCodePhysical() {
    this.subgroupFisico.addressList[this.indexAddressFiscal].street = '';
    this.subgroupFisico.addressList[this.indexAddressFiscal].streetCode = null;
    this.subgroupFisico.addressList[this.indexAddressFiscal].neighborhoodCode = null;
    this.subgroupFisico.addressList[this.indexAddressFiscal].zipCode = null;
    this.clearNeighborhoodFiscalList();
    this.clearStreetFiscalList();
  }

  /**
   * Función para limpia el listado de código postal
   */
  clearZipCodeFiscalList() {
    this.zipCodeListFiscal = [];
  }

  /**
   * Función para limpia el listado de código postal
   */
  clearZipCodeList() {
    this.zipCodeList = [];
  }

  /**
   * Función para limpia el listado de código postal
   */
  clearZipCodeOtherList() {
    this.zipCodeListOther = [];
  }

  /**
   * Función para limpia el listado de código postal
   */
  clearZipCodeOtherListEdit() {
    this.zipCodeListOther = [];
  }

  /**
   * Función para cargar las colonias por código postal
   */
  selectZipCode(event) {
    this.invokeServiceGetColoniesByZipCode(event, this._GENERAL);
    this.clearNeighborhood();

  }

  /**
   * Función para cargar las colonias por código postal
   */
  selectZipCodePhysica(event) {
    this.invokeServiceGetColoniesByZipCode(event, this._GENERAL);
    this.clearNeighborhoodPhysica();

  }

  onChangeSearchZipCode(event) {
  }

  /**
   * Función para limpiar código postal y sus dependientes del cliente
   */
  clearZipCode() {
    this.subGroup.addressList[this.indexAddressClient].streetCode = null;
    this.subGroup.addressList[this.indexAddressClient].street = '';
    this.subGroup.addressList[this.indexAddressClient].streetName = '';
    this.subGroup.addressList[this.indexAddressClient].zipCode = null;
    this.subGroup.addressList[this.indexAddressClient].neighborhoodCode = null;
    this.subGroup.addressList[this.indexAddressClient].neighborhood = '';
    this.clearNeighborhoodList();
    this.clearStreetList();
  }

  /**
   * Función para limpiar código postal y sus dependientes persona física
   */
  clearZipCodePhysica() {
    this.subgroupFisico.addressList[this.indexAddressClient].streetCode = null;
    this.subgroupFisico.addressList[this.indexAddressClient].street = '';
    this.subgroupFisico.addressList[this.indexAddressClient].zipCode = null;
    this.subgroupFisico.addressList[this.indexAddressClient].neighborhoodCode = null;
    this.clearNeighborhoodList();
    this.clearStreetList();
  }

  /**
   * Funcion que obtiene los datos del pais e invokar los estados por pais
   */
  // event formulario direcciones adicionales
  getCountryOtherSelected(event) {

    this.dataOthersAddress.country = event.target.value;
    this.dataOthersAddressAdds.country = event.target.value;
    this.countryTemp = event.target.value;
    this.invokeServiceGetStatesByCountry();
  }

  /**
   * Funcion que obtiene los datos del pais e invokar los estados por pais
   */
  getCountryOtherSelectedEdit(event) {

    this.dataOthersAddressEdit.country = event.target.value;
    this.dataOthersAddressAddsEdit.country = event.target.value;
    // this.countryTemp = event.target.value;
    this.invokeServiceGetStatesByCountry();
  }

  /**
   * Funcion que obtiene los datos del estado e invokar los municipios por estado
   */
  selectStateOther(event) {
    if (event !== undefined) {
      this.address.state = event.cValue;
      this.invokeServiceGetMunicipalyByStateName(event, this._ADICIONAL);
      this.clearMunicipalityOther();
    }
  }

  /**
   * Funcion que obtiene los datos del estado e invokar los municipios por estado
   */
  selectStateOtherEdit(event) {
    if (event !== undefined) {
      this.dataOthersAddressEdit.stateCode = event.cKey;
      this.dataOthersAddressAddsEdit.state = event.cValue;
      this.invokeServiceGetMunicipalyByStateName(event, this._ADICIONAL);
      this.clearMunicipalityOtherEdit();
    }
  }

  /**
   * Funcion que limpia el estado por componentes y sus dependientes
   */
  clearStateOther() {
    this.address.stateCode = null;
    this.address.state = '';
    this.clearMunicipalityOther();
    this.clearMunicipalityOtherList();

  }

  /**
   * Funcion que obtiene códigos postales por municipios
   */
  selectMunicipalityOther(event) {
    if (event !== undefined) {
      this.address.municipality = event.cValue;
      this.invokeServiceGetZipCodesByMunicipalyCode(event, this._ADICIONAL);
      this.clearZipCodeOther();
    }
  }

  /**
   * Funcion que obtiene códigos postales por municipios
   */
  selectMunicipalityOtherEdit(event) {
    if (event !== undefined) {
      this.dataOthersAddressEdit.municipalityCode = event.cKey;
      this.dataOthersAddressAddsEdit.municipality = event.cValue;
      this.invokeServiceGetZipCodesByMunicipalyCode(event, this._ADICIONAL);
      this.clearZipCodeOtherEdit();

    }
  }

  /**
   * Funcion que obtiene calles por colonias
   */
  selectNeighborhoodOther(event) {
    if (event !== undefined) {
      this.address.neighborhood = event.cValue;
      this.clearStreetOtherAndList();
      this.invokeServiceGetStreetsByColonie(event, this._ADICIONAL);
    }
  }

  /**
   * Funcion para eliminar calle colonia y dependientes
   */
  clearNeighborhoodOther() {
    this.address.street = '';
    this.address.streetName = '';
    this.address.streetCode = null;
    this.address.neighborhoodCode = null;
    this.clearStreetOtherList();

  }

  /**
   * Funcion para obtener código postal por colonias
   */
  selectZipCodeOther(event) {
    if (event !== undefined) {
      this.invokeServiceGetColoniesByZipCode(event, this._ADICIONAL);
      this.clearNeighborhoodOther();
    }
  }

  /**
   * Funcion para limpiar la calle, código postal y colonua
   */
  clearZipCodeOther() {
    this.address.street = '';
    this.address.streetCode = null;
    this.address.streetName = '';
    this.address.zipCode = null;
    this.address.neighborhoodCode = null;
    this.address.neighborhood = null;
    this.clearNeighborhoodOtherList();
    this.clearStreetOtherList();
  }

  /**
   * Funcion para limpiar la calle, código postal y colonua
   */
  clearZipCodeOtherEdit() {
    this.dataOthersAddressEdit.street = null;
    this.dataOthersAddressEdit.zipCode = null;
    this.dataOthersAddressAddsEdit.street = null;
    this.dataOthersAddressEdit.neighborhoodCode = null;
    this.dataOthersAddressAddsEdit.neighborhood = null;
    this.clearNeighborhoodOtherListEdit();
    this.clearStreetOtherListEdit();
  }

  /**
   * Funcion para activar o desactivar el botón de guardar o editar
   */
  activateSaveInformationButton(): boolean {
    return !((this.address.street !== null || this.address.streetCode !== null) && this.address.stateCode !== null
      && this.address.country !== null && this.address.municipalityCode !== null && this.address.neighborhoodCode !== null
      && this.address.zipCode !== null && this.address.addressType !== null);
  }

  addOtherAddress() {
    if (this.isEditionOtherAddress) {
      this.editAddressAditional();
    } else {
      if (!this.validateDuplicateAddress(this.address, null)) {
        this.subGroup.addressList.push(this.address);
        this.address = new Address(4);
      }
    }
  }

  onLoadDocuments(event) {
    this.uploadedFilesToLoad = event;
  }

  existFileUnSave(): boolean {
    this.fileUnSaved = (this.uploadedFilesToLoad.some(file => file.idDocument === null) || this.uploadedFilesToLoad === undefined || this.uploadedFilesToLoad.length === 0);
    return this.fileUnSaved;
  }

  /**
   * Funcion para copiar dirección de fiscal al del cliente
   */
  getSelectedSameAddress(event) {
    this.sameAddressFiscal = event.target.checked;
    this.allAddresDisabled(this.sameAddressFiscal);

    if (this.sameAddressFiscal === false) {
      this.subGroup.addressList[this.indexAddressClient].country = '';
      this.subGroup.addressList[this.indexAddressClient].zipCode = null;
      this.subGroup.addressList[this.indexAddressClient].stateCode = null;
      this.subGroup.addressList[this.indexAddressClient].municipalityCode = null;
      this.subGroup.addressList[this.indexAddressClient].neighborhoodCode = null;
      this.subGroup.addressList[this.indexAddressClient].street = '';
      this.subGroup.addressList[this.indexAddressClient].streetCode = null;
      this.subGroup.addressList[this.indexAddressClient].stateCode = null;
      this.subGroup.addressList[this.indexAddressClient].numExt = '';
      this.subGroup.addressList[this.indexAddressClient].numInt = '';
      this.isNewStreet = false;
      this.invokeServiceGetStatesByCountry();
      this.clearState();

    } else {

      this.isNewStreet = this.isNewStreetFiscal;
      this.subGroup.addressList[this.indexAddressClient].country =
        this.subGroup.addressList[this.indexAddressFiscal].country;
      this.subGroup.addressList[this.indexAddressClient].zipCode =
        this.subGroup.addressList[this.indexAddressFiscal].zipCode;
      this.subGroup.addressList[this.indexAddressClient].stateCode =
        this.subGroup.addressList[this.indexAddressFiscal].stateCode;
      this.subGroup.addressList[this.indexAddressClient].state =
        this.subGroup.addressList[this.indexAddressFiscal].state;
      this.subGroup.addressList[this.indexAddressClient].municipalityCode =
        this.subGroup.addressList[this.indexAddressFiscal].municipalityCode;
      this.subGroup.addressList[this.indexAddressClient].neighborhoodCode =
        this.subGroup.addressList[this.indexAddressFiscal].neighborhoodCode;
      this.subGroup.addressList[this.indexAddressClient].street =
        this.subGroup.addressList[this.indexAddressFiscal].street;
      this.subGroup.addressList[this.indexAddressClient].streetCode =
        this.subGroup.addressList[this.indexAddressFiscal].streetCode;
      this.subGroup.addressList[this.indexAddressClient].numExt =
        this.subGroup.addressList[this.indexAddressFiscal].numExt;
      this.subGroup.addressList[this.indexAddressClient].numInt =
        this.subGroup.addressList[this.indexAddressFiscal].numInt;

      this.onEditSubGroupChangeAddressListClient();
    }
  }

  /**
   * Funcion para copiar dirección de fiscal al del cliente
   */

  getSelectedSameAddressPhysical(event) {
    this.sameAddressFiscal = event.target.checked;
    this.allAddresDisabledPhysic(this.sameAddressFiscal);

    if (this.sameAddressFiscal === false) {
      this.subgroupFisico.addressList[this.indexAddressClient].country = '';
      this.subgroupFisico.addressList[this.indexAddressClient].zipCode = null;
      this.subgroupFisico.addressList[this.indexAddressClient].stateCode = null;
      this.subgroupFisico.addressList[this.indexAddressClient].municipalityCode = null;
      this.subgroupFisico.addressList[this.indexAddressClient].neighborhoodCode = null;
      this.subgroupFisico.addressList[this.indexAddressClient].street = '';
      this.subgroupFisico.addressList[this.indexAddressClient].streetCode = null;
      this.subgroupFisico.addressList[this.indexAddressClient].stateCode = null;
      this.subgroupFisico.addressList[this.indexAddressClient].numExt = '';
      this.subgroupFisico.addressList[this.indexAddressClient].numInt = '';
      this.isNewStreet = false;
      this.invokeServiceGetStatesByCountry();
      this.clearStatePhisycal();

    } else {

      this.isNewStreet = this.isNewStreetFiscal;
      this.subgroupFisico.addressList[this.indexAddressClient].country =
        this.subgroupFisico.addressList[this.indexAddressFiscal].country;
      this.subgroupFisico.addressList[this.indexAddressClient].zipCode =
        this.subgroupFisico.addressList[this.indexAddressFiscal].zipCode;
      this.subgroupFisico.addressList[this.indexAddressClient].stateCode =
        this.subgroupFisico.addressList[this.indexAddressFiscal].stateCode;
      this.subgroupFisico.addressList[this.indexAddressClient].state =
        this.subgroupFisico.addressList[this.indexAddressFiscal].state;
      this.subgroupFisico.addressList[this.indexAddressClient].municipalityCode =
        this.subgroupFisico.addressList[this.indexAddressFiscal].municipalityCode;
      this.subgroupFisico.addressList[this.indexAddressClient].neighborhoodCode =
        this.subgroupFisico.addressList[this.indexAddressFiscal].neighborhoodCode;
      this.subgroupFisico.addressList[this.indexAddressClient].street =
        this.subgroupFisico.addressList[this.indexAddressFiscal].street;
      this.subgroupFisico.addressList[this.indexAddressClient].streetCode =
        this.subgroupFisico.addressList[this.indexAddressFiscal].streetCode;
      this.subgroupFisico.addressList[this.indexAddressClient].numExt =
        this.subgroupFisico.addressList[this.indexAddressFiscal].numExt;
      this.subgroupFisico.addressList[this.indexAddressClient].numInt =
        this.subgroupFisico.addressList[this.indexAddressFiscal].numInt;

      this.onEditSubGroupChangeAddressListClient();
    }
  }

  /**
   * Funcion para desactivar las pantallas de un cliente
   */
  allAddresDisabled(isDisabled: boolean) {
    if (isDisabled) {
      this.direccionesForm.get('country').disable();
      this.direccionesForm.get('state').disable();
      this.direccionesForm.get('municipality').disable();
      this.direccionesForm.get('zipCode').disable();
      this.direccionesForm.get('neighborhood').disable();
      this.direccionesForm.get('street').disable();
      this.direccionesForm.get('numInt').disable();
      this.direccionesForm.get('numExt').disable();
    } else {
      this.direccionesForm.get('country').enable();
      this.direccionesForm.get('state').enable();
      this.direccionesForm.get('municipality').enable();
      this.direccionesForm.get('zipCode').enable();
      this.direccionesForm.get('neighborhood').enable();
      this.direccionesForm.get('street').enable();
      this.direccionesForm.get('numInt').enable();
      this.direccionesForm.get('numExt').enable();
    }
  }

  /**
   * Funcion para desactivar las pantallas de un cliente físico
   */
  allAddresDisabledPhysic(isDisabled: boolean) {
    if (isDisabled) {
      this.direccionesFisicoForm.get('country').disable();
      this.direccionesFisicoForm.get('state').disable();
      this.direccionesFisicoForm.get('municipality').disable();
      this.direccionesFisicoForm.get('zipCode').disable();
      this.direccionesFisicoForm.get('neighborhood').disable();
      this.direccionesFisicoForm.get('street').disable();
      this.direccionesFisicoForm.get('numInt').disable();
      this.direccionesFisicoForm.get('numExt').disable();
    } else {
      this.direccionesFisicoForm.get('country').enable();
      this.direccionesFisicoForm.get('state').enable();
      this.direccionesFisicoForm.get('municipality').enable();
      this.direccionesFisicoForm.get('zipCode').enable();
      this.direccionesFisicoForm.get('neighborhood').enable();
      this.direccionesFisicoForm.get('street').enable();
      this.direccionesFisicoForm.get('numInt').enable();
      this.direccionesFisicoForm.get('numExt').enable();
    }

  }

  /*invocacion de servicios*/
  /**
   * Funcion que invoca el servicio para obtener el id del proceso para los documentos
   */
  invokeServiceGetIdConfigurationByIdSubproccess() {
    let data;
    if (this.clientType === 2) { // Persona moral subproceso
      data = 3;
    } else { // Persona física subproceso
      data = 4;
    }
    this.api.getIdConfigurationByIdSubproccess(data).then((result: any) => {
      if (result != null) {
        this.idConfigurationDocument = result;

        // inicializan valores para documentos
        const configDocumentation = {
          configurationDocumentationId: result,
          name: '',
          proccessId: 0,
          subproccessId: 0,
          process: '',
          subprocess: '',
          status: 0,
          createdAt: '',
          updated: '',
          number: 0,
          catTypeDocumentId: null,
          required: false,
          total: 0,
          statusName: ''
        };

        this.invokeServiceGetDocuments(configDocumentation);
      }
    }, error => {
      if (error.status === 204) {
        this.toastr.warning('No se encontró información de los documentos.', 'Notificación');
      }
    });
  }

  /**
   * Funcion que invoca el servicio para obtener los documentos
   */
  invokeServiceGetDocuments(configDocumentation: any) {
    const data = configDocumentation;
    this.api.getDocuments(data).then((result: any) => {
      if (result != null) {
        this.typeDocumentList = result;
      }

      if (this.isView) {
        if (this.isClientFisico) {
          this.disableAllFisico();
        } else {
          this.disableAll();
        }

      }
    }, error => {
      if (error.status === 409) {
        this.toastr.error('Hubo un error al procesar la información de los documentos.', 'Notificación');
      } else if (error.status === 204) {
        this.toastr.warning('No se encontró información de los documentos.', 'Notificación');
      }
    });
  }

  /**
   * Funcion que invoca el servicio para obtener tipo de persona
   */
  invokeServiceGetTypePerson() {
    const data = {name: 'TYPE_PERSON'};
    this.api.getCatalogByBusiness(data).then((result: any) => {
      if (result != null) {
        this.typePersonList = result;
        if (this.typeSelectedContact === '1' || this.typeSelectedContact === '2') {
          this.clientType = 2;
          this.inicilizeDataEveryNewGroup();
        } else if (this.typeSelectedContact === '3') {
          this.clientType = 1;
          this.inicilizeDataEveryNewPhysicalClient();
        } else if (this.isEditionSubGroupFromTable || this.isView) {
          this.clientType = this.subGroup.general_data.clientType;
          if (this.clientType === 1) {
            this.inicilizeDataEveryNewGroup();
          } else {
            this.inicilizeDataEveryNewPhysicalClient();
          }
        }
        this.invokeServiceGetIdConfigurationByIdSubproccess();
      }
    }, error => {

    });
  }

  /**
   * Funcion que invoca el servicio para obtener todos los grupos
   */
  invokeServiceAllSubGroupWhitoutGroup() {
    this.api.getAllSubGroupWhitoutGroup().then((data: any) => {
      if (data != null) {
        this.subGroupList = data;
      }
    }, error => {

    });
  }

  /**
   * Funcion que invoca el servicio para obtener un cliente
   */
  invokeServiceClientInformation(client: any, isGroup: boolean) {
    this.spinner.show('sp');
    this.api.getClientInformation(client).then((data: any) => {
      if (data != null) {
        if (!isGroup) {
          this.subGroup = data.group.subgroups[0];
          if (this.subGroup.documentationList === undefined) {
            this.subGroup.documentationList = new Array<DocumentationList>();
          }
          this.onEditFindIndexOfDifferentTypeAddress();
          this.onEditFindIndexOfDifferentTypeContact();
          this.onEditSubGroupChangeAddressListFiscal();
          this.onEditSubGroupChangeAddressListClient();
          this.onEditChangeSameAddres();
          this.addModelFilesToListFiles();
          this.onEditChangeBidOfBidStreet();
          this.assignValues();
          if (this.isEditionSubGroupFromTable || this.isView) {
            if (data.group.subgroups[0].general_data.clientType === 2) {
              this.group = data.group;
              this.assignValuesInEddition();
            } else {
              this.groupFisico = data.group;
              this.assignValuesInEdditionPhysical();
            }
            this.invokeServiceGetTypePerson();
          }
        } else {
          if (data.group === null) {
            this.group = new Group();
            this.group.groupId = client.groupId;
            this.group.subgroups = new Array<Subgroup>();
          } else if (data.group.subgroups === null) {
            this.group.subgroups = new Array<Subgroup>(); // Para evitar el null
          } else {
            this.group = data.group;
          }

        }
      } else {
        this.loadNewGroup();
      }
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
      this.toastr.warning('Error al recuperar la información del cliente', 'Notificación');
    });
  }

  /**
   * Funcion que invoca el servicio para obtener un cliente físico
   */
  invokeServiceClientInformationPhysical(client: any, isGroup: boolean) {
    this.spinner.show('sp');

    this.api.getClientInformationPhysical(client).then((data: any) => {
      if (data != null) {
        if (!isGroup) {
          this.subgroupFisico = data.groupFisico.subgroups[0];
          if (this.subgroupFisico.documentationList === undefined) {
            this.subgroupFisico.documentationList = new Array<DocumentationList>();
          }
          this.onEditFindIndexOfDifferentTypeAddressPhysic();
          this.onEditSubGroupChangeAddressListFiscal();
          this.onEditSubGroupChangeAddressListClient();
          this.onEditChangeSameAddresPhysica();
          this.addModelFilesToListFiles();
          this.onEditChangeBidOfBidStreet();
          if (this.isEditionSubGroupFromTable || this.isView) {
            this.groupFisico = data.groupFisico;
            this.assignValuesInEdditionPhysical();
            this.invokeServiceGetTypePerson();
          }
        }
      }
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
      this.toastr.warning('Error al recuperar la información del cliente', 'Notificación');
    });
  }

  /**
   * Precarga la razón social en la vista
   */
  assignValues() {
    this.datosGeneralesForm.get('razonSocial').setValue(this.subGroup.general_data.name);

  }

  /**
   * Funcion precarga el RFC de una persona moral
   */
  assignValuesInEddition() {
    this.datosGeneralesForm.get('rfcSubrupo').setValue(this.subGroup.general_data.rfc);
  }

  /**
   * Funcion precarga el RFC y grupo de una persona física
   */
  assignValuesInEdditionPhysical() {
    this.datosGeneralesFisicoForm.get('rfc').setValue(this.subgroupFisico.general_data.rfc);
    this.datosGeneralesFisicoForm.get('group').setValue(this.groupFisico.groupId);
  }

  /**
   * Funcion que invoka el servicio para obtener los grupos
   */
  invokeServiceGetGroups() {
    const data = {name: 'GROUPS'};
    this.api.getCatalogByBusiness(data).then((result: any) => {
      if (result != null) {
        this.groupsList = result;
        this.groupsListFilter = result;
      }
    }, error => {

    });
  }

  /**
   * Funcion que invoka el servicio para obtener los tipos de sociedad
   */
  invokeServiceGetTypeSocieties() {
    const data = {name: 'TYPE_SOCIETY'};
    this.api.getCatalogByBusiness(data).then((result: any) => {
      if (result != null) {
        this.typeSocietiesList = result;
      }
    }, error => {

    });
  }

  /**
   * Funcion que invoka el servicio para los tipos de contacots
   */
  invokeServiceGetTypeConcactos() {
    this.api.getTypeContactForClients().then((result: any) => {
      if (result != null) {
        this.typeContactForClients = result;
      }
    }, error => {

    });
  }

  /**
   * Funcion que invoka el servicio para obtener subgrupos
   */
  invokeServiceGetSubgroups(data) {
    this.api.getAppertainSubGroups(data).then((result: any) => {
      if (result != null) {
        this.subGroupList = result;


      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });
  }

  /**
   * Funcion que invoka el servicio de paises
   */
  invokeServiceGetCountries() {
    this.api.getCountries().then((data: any) => {
      if (data != null) {
        this.countryList = data;
        this.countryListFiscal = data;
        this.countryListOther = data;
        this.countryListFiscalClientes = data;
      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });
  }

  /**
   * Funcion que invoka el servicio de estados por país
   */
  invokeServiceGetStatesByCountry() {

    this.api.getStatesByCountry().then((data: any) => {
      if (data != null) {
        this.stateList = data;
        this.stateListFiscal = data;
        this.stateListClient = data;
        this.stateListOther = data;
      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });
  }

  /**
   * Funcion que invoka el servicio de municipios por estado
   */
  invokeServiceGetMunicipalyByStateName(data, typeAddress) {
    this.api.getMunicipalyByStateName(data).then((result: any) => {
      if (result != null) {
        switch (typeAddress) {
          case this._GENERAL: {
            this.municipalityList = result;

            if (this.isClientFisico) {
              this.invokeTownByZipCode(this.subgroupFisico.addressList[this.indexAddressClient].zipCode, typeAddress);
              if (this.subgroupFisico.addressList[this.indexAddressClient].municipalityCode !== null) {
                this.direccionesFisicoForm.get('municipality').setValue(
                  this.subgroupFisico.addressList[this.indexAddressClient].municipalityCode);
              }
            } else {
              this.invokeTownByZipCode(this.subGroup.addressList[this.indexAddressClient].zipCode, typeAddress);
              if (this.subGroup.addressList[this.indexAddressClient].municipalityCode !== null) {
                this.direccionesForm.get('municipality').setValue(
                  this.subGroup.addressList[this.indexAddressClient].municipalityCode);
              }
            }
          }
                              break;
          case this._FISCAL: {
            this.municipalityListFiscal = result;
            if (this.isClientFisico) {
              this.invokeTownByZipCode(this.subgroupFisico.addressList[this.indexAddressFiscal].zipCode, typeAddress);
            } else {
              this.invokeTownByZipCode(this.subGroup.addressList[this.indexAddressFiscal].zipCode, typeAddress);
            }
          }
                             break;
          case this._ADICIONAL: {
            this.municipalityListOther = result;
            this.invokeTownByZipCode(this.address.zipCode, typeAddress);
          }
                                break;

        }
      }
    }, error => {

    });

  }

  /**
   * Funcion que invoka el cóigos postales por Estado
   */
  invokeServiceGetZipCodesByState(data, typeAddress) {
    this.api.getZipCodesByState(data).then((result: any) => {
      if (result != null) {
        switch (typeAddress) {
          case this._GENERAL: {
            this.zipCodeList = result;
          }
                              break;
          case this._FISCAL: {
            this.zipCodeListFiscal = result;
          }
                             break;
          case this._ADICIONAL: {
            this.zipCodeListOther = result;
          }
                                break;

        }

      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });
  }

  /**
   * Funcion que invoka el servicio de codigos postales por municipio
   */
  invokeServiceGetZipCodesByMunicipalyCode(data, typeAddress) {

    this.api.getZipCodesByMunicipalyCode(data).then((result: any) => {
      if (result != null) {
        switch (typeAddress) {
          case this._GENERAL: {
            this.zipCodeList = result;
          }
                              break;
          case this._FISCAL: {
            this.zipCodeListFiscal = result;
          }
                             break;
          case this._ADICIONAL: {
            this.zipCodeListOther = result;
          }
                                break;

        }
      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });
  }

  /**
   * Funcion que invoka el servicio de colonias por código postal
   */
  invokeServiceGetColoniesByZipCode(data, typeAddress) {
    this.api.getColoniesByZipCode(data).then((result: any) => {
      if (result != null) {
        switch (typeAddress) {
          case this._GENERAL: {
            this.neighborhoodList = result;
          }
                              break;
          case this._FISCAL: {
            this.neighborhoodListFiscal = result;
          }
                             break;
          case this._ADICIONAL: {
            this.neighborhoodListOther = result;
          }
                                break;
        }
      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });
  }

  /**
   * Funcion que invoka el servicio de colonias
   */
  invokeServiceGetStreetsByColonie(data, typeAddress) {
    this.api.getStreetsByColonie(data).then((result: any) => {


      if (result != null) {
        switch (typeAddress) {
          case this._FISCAL: {
            this.streetsListFiscal = result;

            if (this.isClientFisico) {
              if (this.subgroupFisico.addressList[this.indexAddressFiscal].streetCode !== null) {
                this.direccionesFisicoForm.controls.streetFiscal.setValue(
                  this.subgroupFisico.addressList[this.indexAddressFiscal].streetCode);
              }
            } else {
              if (this.subGroup.addressList[this.indexAddressFiscal].streetCode !== null) {
                this.direccionesForm.controls.streetFiscal.setValue(
                  this.subGroup.addressList[this.indexAddressFiscal].streetCode);
              }
            }
          }
                             break;
          case this._GENERAL: {
            this.streetsList = result;
            if (this.isClientFisico) {
              if (this.subgroupFisico.addressList[this.indexAddressClient].streetCode !== null) {
                this.direccionesFisicoForm.controls.street.setValue(
                  this.subgroupFisico.addressList[this.indexAddressClient].streetCode);
              }
            } else {
              if (this.subGroup.addressList[this.indexAddressClient].streetCode !== null) {
                this.direccionesForm.controls.street.setValue(
                  this.subGroup.addressList[this.indexAddressClient].streetCode);
              }
            }
          }
                              break;
          case this._ADICIONAL: {
            this.streetsListOther = result;
          }
                                break;

        }
      }
    }, error => {

    });
  }

  /**
   * Funcion que invoka el servicio para guardar un cliente
   */
  invokeServiceSaveDataClient(data) {
    this.spinner.show('sp');

    this.api.validateGroup(this.group.name).then(
      (exist: any) => {

        if ((exist === 'false' || exist === false)) {

          this.api.saveDataClient(data)
            .then(
              (result: any) => {
                this.spinner.hide('sp');
                this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
                this.router.navigate(['/admin/client']);
              }, error => {
                this.spinner.hide('sp');
                if (error.status === 500) {
                  if (error.error.message !== undefined) {
                    this.toastr.warning(error.error.message, 'Notificación');
                  } else {
                    this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                  }
                } else {
                  this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                }
              });
        } else if ((exist === 'true' || exist === true) && (this.group.groupId !== null)) {
          this.api.saveDataClient(data)
            .then(
              (result: any) => {
                this.spinner.hide('sp');
                this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
                this.router.navigate(['/admin/client']);
              }, error => {
                this.spinner.hide('sp');
                if (error.status === 500) {
                  if (error.error.message !== undefined) {
                    this.toastr.warning(error.error.message, 'Notificación');
                  } else {
                    this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                  }
                } else {
                  this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                }
              });
        } else {
          this.spinner.hide('sp');
          this.toastr.warning('Ya existe un grupo llamado ' + this.group.name + ', favor de modificar el nombre', 'Notificación');
        }
      }, error => {
        this.spinner.hide('sp');
        this.toastr.warning('Ocurrió un problema al procesar su petición', 'Notificación');
      }
    );


  }

  /**
   * Funcion que invoka el servicio para editar un cliente
   */
  invokeServiceEditDataClient(data) {
    this.spinner.show('sp');
    this.api.saveEditClient(data)
      .then(
        (result: any) => {
          this.spinner.hide('sp');
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.router.navigate(['/admin/client']);

        }, error => {
          this.spinner.hide('sp');
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.error(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {

            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        });
  }

  /**
   * Funcion que invoka el servicio para guardar un cliente Persona Física
   */
  invokeServiceSaveDataClientPhysical(data) {
    this.spinner.show('sp');
    this.api.saveDataClientPhysical(data)
      .then(
        (result: any) => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.router.navigate(['/admin/client']);
          this.spinner.hide('sp');
        }, error => {
          this.spinner.hide('sp');

          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.error(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {

            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        });
  }

  /**
   * Funcion que invoka el servicio para guardar un cliente Persona Física
   */
  invokeServiceSaveClientPhysical(data) {
    this.spinner.show('sp');
    this.api.saveDataMainClientPhysical(data)
      .then(
        (result: any) => {
          this.groupFisico = result;
          this.subgroupFisico = result.subgroups[0];
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.spinner.hide('sp');
          this.groupsList.forEach(gr => {
            if (gr.id === this.groupFisico.groupId) {
              this.groupFisico.name = gr.description;
            }
          });
          localStorage.setItem('guardado', 'true');
          localStorage.setItem('groupFisico', JSON.stringify(this.groupFisico));
          localStorage.setItem('subgroupFisico', JSON.stringify(this.subgroupFisico));
        }, error => {
          this.spinner.hide('sp');

          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.error(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {

            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        });
  }

  /**
   * Funcion que invoka el servicio para guardar un cliente Persona Física
   */
  invokeServiceSaveRelationGroupFisicToAmmia(data) {
    this.spinner.show('sp');
    this.api.saveRelationGroupFisicToAmmia(data)
      .then(
        (result: any) => {
          console.log(result);
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.router.navigate(['/admin/client']);
          this.spinner.hide('sp');
        }, error => {
          this.spinner.hide('sp');

          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.error(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {

            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        });
  }
  /**
   * Función que invoka el servicio para editar un cliente persona física
   */
  invokeServiceEditDataClientPhysical(data) {
    this.spinner.show('sp');
    this.api.saveEditClientPhyscal(data)
      .then(
        (result: any) => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.router.navigate(['/admin/client']);

        }, error => {
          this.spinner.hide('sp');
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.error(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {

            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        });
  }

  /**
   * Función que asinga la pestaña seleccionada a la variable para controlar los botones de siguiente o atrás
   */

  stepChanged(args: StepChangedArgs) {
    this.selectedStepIndex = args.step.index;
    this.existFileUnSave();
  }

  /** funciones generales */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/client']);
  }

  goToBackTest() {
    localStorage.setItem('clientNew', 'SI');
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/massive-load-policy']);
  }

  /**
   * Función que muestra una pestaña seleccionada
   */
  stepIndexSelected(selectedStepIndex) {
    this.ngWizardService.show(selectedStepIndex);
  }

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  /**
   * Función que activa o desactiva una pestaña
   */
  isStepEnabled(args: NgWizardStep) {
    return this.isStepEnable;
  }

  /**
   * Función que muestra las pestañas d
   */
  stepSelected(args: NgWizardStep) {
    this.selectedStepIndex = args.index;
  }

  /**
   * Función que activa o desactiva el botón para guardar un cliente
   */
  isAddClientDisabled(): boolean {
    if ((this.validContacs() || this.datosContactoForm.disabled) && this.datosGeneralesForm.valid
      && (this.direccionesForm.valid || this.direccionesForm.disabled)
      && this.isFileValidated) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Función que guarda un nuevo cliente
   */
  async addClient() {
    if (!this.validateDuplicateSubGroup(this.subGroup)) { // si está duplicado, avisar
      if (this.validateFileRequieredFail()) { // Verificar si estan los archivos
        this.toastr.warning('ES NECESARIO CARGAR TODOS LOS DOCUMENTOS REQUERIDOS MARCADOS POR UN *.', 'NOTIFICACIÓN');
      } else {
        if (this.isEditionSubGroup) {
          this.group.subgroups[this.indexSubGroup] = this.subGroup;
          this.isEditionSubGroup = false;
        } else {
          this.group.subgroups.push(this.subGroup);
        }
        await this.loadNewSubgroup();
        this.clearState();
        this.clearStateFiscal();
        this.invokeServiceGetStatesByCountry();
        this.datosContactoForm.reset();
        this.datosGeneralesForm.reset();
        this.direccionesForm.reset();
        this.uploadedFilesToLoad = [];
        this.isStepEnable = false;
        this.sameAddressFiscal = false;
        this.allAddresDisabled(false);
        this.inicilizeDataEveryNewGroup();
      }
    }
  }

  /**
   * Función que recupera los archivos cargados
   */
  addFilesToModel() {
    if (this.isEditionSubGroup === true || this.isEditionSubGroupFromTable) {
      if (this.isClientFisico) {
        this.subgroupFisico.documentationList = [];
      } else {
        this.subGroup.documentationList = [];
      }
    }
    if (this.isClientFisico) {
      this.uploadedFilesToLoad.map((DOCUMENTS) => {
        const documentModel = new DocumentationList();
        documentModel.catTypeDocumentId = DOCUMENTS.number;
        documentModel.receiptDate = DOCUMENTS.fechaComprobanteDomicilio;
        documentModel.base = DOCUMENTS.base64;
        documentModel.name = DOCUMENTS.name;
        documentModel.clientDocumentationId = DOCUMENTS.idDocument;
        this.subgroupFisico.documentationList.push(documentModel);
      });
    } else {
      this.uploadedFilesToLoad.map((DOCUMENTS) => {
        const documentModel = new DocumentationList();
        documentModel.catTypeDocumentId = DOCUMENTS.number;
        documentModel.receiptDate = DOCUMENTS.fechaComprobanteDomicilio;
        documentModel.base = DOCUMENTS.base64;
        documentModel.name = DOCUMENTS.name;
        documentModel.clientDocumentationId = DOCUMENTS.idDocument;
        this.subGroup.documentationList.push(documentModel);
      });
    }
  }

  /**
   * Función que agrega un subgrupo al modelo
   */
  updateDataSubgroup() {
    this.dataStreetsNewsSend = [];
    if (this.isNewStreetFiscal === true) {
      this.streetFiscalNew.nameStreet = this.dataAddressFiscal.street;
      this.dataStreetsNewsSend.push(this.streetFiscalNew);
    }
    if (this.isNewStreet) {
      this.streetNew.nameStreet = this.dataAddressClient.street;
      this.dataStreetsNewsSend.push(this.streetNew);
    }
    this.dataSaveList[0].coloniesStreetList = this.dataStreetsNewsSend;
    this.dataSaveList[0].client = this.generalData;
    this.dataSaveList[0].group = this.dataGeneralSubgroup;
    this.dataSaveList[0].addressList = this.dataOthersAddressListSend;
    this.dataSaveList[0].contactList = this.dataContactsSend;

    this.updateSubgroup = false;
  }

  /**
   * Función que guarda un cliente
   */
  saveDataClient() {
    if (this.group.subgroups.length === 0) {
      this.toastr.warning('Es necesario cargar al menos un cliente', 'Notificación');
    } else {
      const client = {
        group: this.group
      };
      // client.group = Object.create(this.group);
      this.invokeServiceSaveDataClient(client);
    }
  }

  /**
   * Función que guarda una edición
   */
  editDataClient() {
    if (this.validateFileRequieredFail()) {
      this.toastr.warning('ES NECESARIO CARGAR TODOS LOS DOCUMENTOS REQUERIDOS, MARCADOS POR UN *.', 'NOTIFICACIÓN');
    } else {
      this.addFilesToModel();
      this.group.subgroups[this.indexSubGroup] = this.subGroup;
      const client = new Client();
      client.group = this.group;
      this.invokeServiceEditDataClient(client);
    }
  }

  /**
   * Función que guarda un cliente físico
   */
  saveDataClientPhysical() {

    if (this.getIsValidFormByIndexTab(this.selectedStepIndex)) {
      this.groupFisico.subgroups.push(this.subgroupFisico);
      const clientFisico = new ClientFisico();
      clientFisico.groupFisico = this.groupFisico;

      this.invokeServiceSaveDataClientPhysical(clientFisico);
    }
  }

  /**
   * Si está editando un cliente fisico, validar que tenga los archivos cargados
   */
  editDataClientPhysical() {
    if (this.validateFileRequieredFail()) {
      this.toastr.warning('ES NECESARIO CARGAR TODOS LOS DOCUMENTOS REQUERIDOS, MARCADOS POR UN *.', 'NOTIFICACIÓN');
    } else {
      this.addFilesToModel();
      this.groupFisico.subgroups[this.indexSubGroup] = this.subgroupFisico;
      const client = new ClientFisico();
      client.groupFisico = this.groupFisico;
      this.invokeServiceEditDataClientPhysical(client);
    }
  }

  /**
   * Función que elimina una dirección adicional del listado
   */
  deleteAddressAdds(i) {
    this.subGroup.addressList.splice(i, 1);
  }

  /**
   * Resetea los datos del modelo a 0 cuando se terminó de actualizar un subgrupo
   */
  updateSubGroup(i) {
    this.disableIfIsGroupExist(true);
    this.isNuevoSubgroup = true;
    this.selectedStepIndex = 0;
    this.isStepEnable = true;
    this.indexSubGroup = i;
    this.isEditionSubGroup = true;
    this.subGroup = this.group.subgroups[i];
    this.onEditFindIndexOfDifferentTypeAddress();
    this.onEditSubGroupChangeAddressListFiscal();
    this.onEditSubGroupChangeAddressListClient();
    this.addModelFilesToListFiles();
    this.onEditChangeSameAddres();
    this.onEditChangeBidOfBidStreet();
    this.ngWizardService.show(0);
    this.disableBtnNext = false;

  }

  /**
   * Carga las direcciones en el modelo para mostrarlo en la vista
   */
  onEditFindIndexOfDifferentTypeAddress() {
    this.indexAddressFiscal = this.subGroup.addressList.findIndex((address) => address.addressType === 1);

    if (this.indexAddressFiscal === -1) {
      this.subGroup.addressList.push(new Address(1));
      this.indexAddressFiscal = this.subGroup.addressList.findIndex((address) => address.addressType === 1);
    }

    this.indexAddressClient = this.subGroup.addressList.findIndex((address) => address.addressType === 3);
    if (this.indexAddressClient === -1) {
      this.subGroup.addressList.push(new Address(3));
      this.indexAddressClient = this.subGroup.addressList.findIndex((address) => address.addressType === 3);
    }

  }

  /**
   * Obtiene el index de las las direcciones fiscales o clientes
   */
  onEditFindIndexOfDifferentTypeContact() {
    this.indexContact = this.subGroup.contactList.findIndex((contact) => contact.contactTypeId === 3);

    if (this.indexContact === -1) {
      this.subGroup.contactList.push(new Contact());
      this.indexContact = this.subGroup.contactList.findIndex((contact) => contact.contactTypeId === 3);
    }

    this.indexContactCobranza = this.subGroup.contactList.findIndex((contact) => contact.contactTypeId === 4);

    if (this.indexContactCobranza === -1) {
      this.subGroup.contactList.push(new Contact());
      this.indexContactCobranza = this.subGroup.contactList.findIndex((contact) => contact.contactTypeId === 4);
    }
  }

  /**
   * Obtiene el index de las direcciones fiscales o clientes persona física
   */
  onEditFindIndexOfDifferentTypeAddressPhysic() {
    // Se le asigna el 1, la dirección fiscal
    if (this.subgroupFisico.addressList.length === 1 && this.subgroupFisico.addressList[0].addressType === 0) {
      this.subgroupFisico.addressList[0].addressType = 1;
    }

    this.indexAddressFiscal = this.subgroupFisico.addressList.findIndex((address) => address.addressType === 1);
    if (this.indexAddressFiscal === -1) {
      this.subgroupFisico.addressList.push(new Address(1));
      this.indexAddressFiscal = this.subgroupFisico.addressList.findIndex((address) => address.addressType === 1);
    }

    this.indexAddressClient = this.subgroupFisico.addressList.findIndex((address) => address.addressType === 3);
    if (this.indexAddressClient === -1) {
      this.subgroupFisico.addressList.push(new Address(3));
      this.indexAddressClient = this.subgroupFisico.addressList.findIndex((address) => address.addressType === 3);
    }
  }

  /**
   * Si las direcciones in las mismas, se activa el Bid o se desactiva
   */
  onEditChangeSameAddres() {
    const addressFiscal = this.subGroup.addressList[this.indexAddressFiscal];
    const addressClient = this.subGroup.addressList[this.indexAddressClient];

    if (addressClient.stateCode === addressFiscal.stateCode && addressClient.municipalityCode === addressFiscal.municipalityCode
      && addressClient.neighborhoodCode === addressFiscal.neighborhoodCode && addressClient.zipCode === addressFiscal.zipCode
      && addressClient.streetName === addressFiscal.streetName && addressClient.numInt === addressFiscal.numInt
      && addressClient.numExt === addressFiscal.numExt) {
      this.sameAddressFiscal = true;
    } else {
      this.sameAddressFiscal = false;
    }
  }

  /**
   * Si las direcciones in las mismas, se activa el Bid o se desactiva persona física
   */
  onEditChangeSameAddresPhysica() {
    const addressFiscal = this.subgroupFisico.addressList[this.indexAddressFiscal];
    const addressClient = this.subgroupFisico.addressList[this.indexAddressClient];

    if (addressClient.stateCode === addressFiscal.stateCode && addressClient.municipalityCode === addressFiscal.municipalityCode
      && addressClient.neighborhoodCode === addressFiscal.neighborhoodCode && addressClient.zipCode === addressFiscal.zipCode
      && addressClient.numInt === addressFiscal.numInt && addressClient.numExt === addressFiscal.numExt) {
      this.sameAddressFiscal = true;
    } else {
      this.sameAddressFiscal = false;
    }
  }

  /**
   * Función que activa o desactiva los bid de Agregar Calle
   */
  onEditChangeBidOfBidStreet() {
    if (this.isClientFisico) {
      if (this.subgroupFisico.addressList[this.indexAddressFiscal].streetCode === undefined
        || this.subgroupFisico.addressList[this.indexAddressFiscal].streetCode === null) {
        this.isNewStreetFiscal = true;
      } else {
        this.isNewStreetFiscal = false;
      }
      if (this.subgroupFisico.addressList[this.indexAddressClient].streetCode === undefined
        || this.subgroupFisico.addressList[this.indexAddressClient].streetCode === null) {
        this.isNewStreet = true;
      } else {
        this.isNewStreet = false;
      }
    } else {
      if (this.subGroup.addressList[this.indexAddressFiscal].streetCode === undefined
        || this.subGroup.addressList[this.indexAddressFiscal].streetCode === null) {
        this.isNewStreetFiscal = true;
      } else {
        this.isNewStreetFiscal = false;
      }
      if (this.subGroup.addressList[this.indexAddressClient].streetCode === undefined
        || this.subGroup.addressList[this.indexAddressClient].streetCode === null) {
        this.isNewStreet = true;
      } else {
        this.isNewStreet = false;
      }
    }
  }

  /**
   * Funcion que muestra el popup de pólizas
   */
  onShowPolizas(template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }

  /**
   * Carga los archivos
   */
  addModelFilesToListFiles() {
    this.uploadedFilesToLoad = [];
    if (this.isClientFisico) {
      this.subgroupFisico.documentationList.map((DOCCMODEL) => {
        const documents = new Documents();
        documents.base64 = DOCCMODEL.base;
        documents.number = DOCCMODEL.catTypeDocumentId;
        documents.name = DOCCMODEL.name;
        documents.idDocument = DOCCMODEL.clientDocumentationId;
        this.uploadedFilesToLoad.push(documents);
      });
    } else {
      this.subGroup.documentationList.map((DOCCMODEL) => {
        const documents = new Documents();
        documents.base64 = DOCCMODEL.base;
        documents.number = DOCCMODEL.catTypeDocumentId;
        documents.name = DOCCMODEL.name;
        documents.idDocument = DOCCMODEL.clientDocumentationId;
        this.uploadedFilesToLoad.push(documents);
      });
    }

    this.existFileUnSave();
  }

  /**
   * Borrar elemento del listado de subgrupos
   */
  deleteSubGroup(i) {
    this.group.subgroups.splice(i, 1);
  }

  /**
   * Desactivar campo de estatus
   */
  disableSubGroup(i) {
    this.group.subgroups[i].general_data.status = false;
  }

  /**
   * Activar campo de estatus
   */
  enableSubGroup(i) {
    this.group.subgroups[i].general_data.status = true;
  }

  /**
   * Función que cambia a la pestaña posterior
   */
  nextStep() {

    if (this.getIsValidFormByIndexTab(this.selectedStepIndex)) {
      if (this.selectedStepIndex < this.totalSteps) {
        this.selectedStepIndex++;
        this.stepIndexSelected(this.selectedStepIndex);
      } /*else if (this.selectedStepIndex === this.totalSteps) {
        this.disableBtnNext = true;
        this.stepIndexSelected(this.selectedStepIndex);
      }*/
    } else {
      this.markTouchdByIndex(this.selectedStepIndex);

    }
  }

  /**
   * Función que cambia a la pestaña anterior
   */
  previousStep() {
    if (this.selectedStepIndex > 0) {
      this.selectedStepIndex--;
      this.stepIndexSelected(this.selectedStepIndex);
    } /*else if (this.selectedStepIndex === 0) {
      this.stepIndexSelected(this.selectedStepIndex);
      this.disableBtnPrevius = true;
    }*/
  }

  /**
   * Función que muestra la pestaña de cliente
   */
  onShowClients() {
    this.ngWizardService.show(5);

  }

  /**
   * Función que retorna si la pestaña en edición contiene información válida
   */
  getIsValidFormByIndexTab(index: number): boolean {
    switch (index) {
      case 0:
        if (this.isClientFisico) {
          return this.datosGeneralesFisicoForm.valid || this.datosGeneralesFisicoForm.disabled;
        } else {
          return this.datosAgrupadorForm.valid || this.datosAgrupadorForm.disabled;
        }
        break;
      case 1:
        if (this.isClientFisico) {
          return this.direccionesFisicoForm.valid || this.direccionesFisicoForm.disabled;
        } else {
          return this.datosGeneralesForm.valid || this.datosGeneralesForm.disabled;
        }
        break;
      case 2:
        if (this.isClientFisico) {
          if (this.validateFileRequieredFail()) {
            this.toastr.warning('ES NECESARIO CARGAR TODOS LOS DOCUMENTOS REQUERIDOS, MARCADOS POR UN *.', 'NOTIFICACIÓN');
            return false;
          } else {
            this.addFilesToModel();
            this.isFileValidated = true;
            return true;
          }
        } else {
          if (!this.isView) {
            return this.validContacsMessage() || this.datosContactoForm.disabled;
          } else {
            return true;
          }
        }
        break;
      case 3:
        return this.direccionesForm.valid || this.direccionesForm.disabled;
        break;
      case 4:
        if (!this.isView) {
          if (this.validateFileRequieredFail()) {
            this.toastr.warning('ES NECESARIO CARGAR TODOS LOS DOCUMENTOS REQUERIDOS, MARCADOS POR UN *.', 'NOTIFICACIÓN');
            return false;
          } else {
            this.addFilesToModel();
            this.isFileValidated = true;
            return true;
          }
        } else {
          return true;
        }
        break;
      default:
        return true;
        break;
    }
  }

  /**
   * Función que valida si la información en el formulario es válida
   */
  validateFileRequieredFail(): boolean {
    const documentsRequires = this.uploadedFilesToLoad.map((document) => {
      return Number(document.number);
    });

    return this.typeDocumentList.some(document => {
      return document.required === true && !documentsRequires.includes(document.number);
    });
  }

  /**
   * Marca los campos que son requeridos en la vista
   */
  markTouchdByIndex(index: number) {
    switch (index) {
      case 0: // Agrupador
        this.datosAgrupadorForm.controls.group.markAsTouched();
        break;
      case 1: // Datos Generales del Subgrupo
        this.datosGeneralesForm.controls.rfcSubrupo.markAsTouched();
        this.datosGeneralesForm.controls.razonSocial.markAsTouched();
        this.datosGeneralesForm.controls.comentarios.markAsTouched();
        this.datosGeneralesForm.controls.typeSociety.markAsTouched();
        this.datosGeneralesForm.controls.typePerson.markAsTouched();
        break;
      case 3: // Datos Direcciones Subgrupo
        this.direccionesForm.controls.numIntFiscal.markAsTouched();
        this.direccionesForm.controls.numExtFiscal.markAsTouched();
        this.direccionesForm.controls.countryFiscal.markAsTouched();
        this.direccionesForm.controls.stateFiscal.markAsTouched();
        this.direccionesForm.controls.municipalityCodeFiscal.markAsTouched();
        this.direccionesForm.controls.zipCodeFiscal.markAsTouched();
        this.direccionesForm.controls.neighborhoodFiscal.markAsTouched();
        this.direccionesForm.controls.streetFiscal.markAsTouched();
        this.direccionesForm.controls.street.markAsTouched();
        this.direccionesForm.controls.numExt.markAsTouched();
        this.direccionesForm.controls.numInt.markAsTouched();
        this.direccionesForm.controls.sameAddres.markAsTouched();
        this.direccionesForm.controls.state.markAsTouched();
        this.direccionesForm.controls.municipality.markAsTouched();
        this.direccionesForm.controls.zipCode.markAsTouched();
        this.direccionesForm.controls.neighborhood.markAsTouched();
        this.direccionesForm.controls.countryOther.markAsTouched();
        this.direccionesForm.controls.stateOther.markAsTouched();
        this.direccionesForm.controls.municipalityOther.markAsTouched();
        this.direccionesForm.controls.zipCodeOther.markAsTouched();
        this.direccionesForm.controls.neighborhoodOther.markAsTouched();
        this.direccionesForm.controls.streetOther.markAsTouched();
        this.direccionesForm.controls.checkStreetOther.markAsTouched();
        this.direccionesForm.controls.addStretClient.markAsTouched();
        this.direccionesForm.controls.agregatCalle.markAsTouched();
        break;

      default:
        break;
    }
  }

  onChange(event) {
    this.isNewStreet = event;
    this.subGroup.addressList[this.indexAddressClient].street = '';
    this.subGroup.addressList[this.indexAddressClient].streetCode = null;
  }

  onChangeClientPhysical(event) {
    this.isNewStreet = event;
    this.subgroupFisico.addressList[this.indexAddressClient].street = '';
    this.subgroupFisico.addressList[this.indexAddressClient].streetCode = null;
  }

  onChangeFiscal(event) {
    if (this.sameAddressFiscal) {
      this.isNewStreet = event;
    }
    this.isNewStreetFiscal = event;
    this.subGroup.addressList[this.indexAddressFiscal].street = '';
    this.subGroup.addressList[this.indexAddressFiscal].streetCode = null;
  }

  onChangePhysical(event) {
    if (this.sameAddressFiscal) {
      this.isNewStreet = event;
    }
    this.isNewStreetFiscal = event;
    this.subgroupFisico.addressList[this.indexAddressFiscal].street = '';
    this.subgroupFisico.addressList[this.indexAddressFiscal].streetCode = null;
  }

  onChangeNuevoSubGroup(event) {
    this.isNuevoSubgroup = event;

    if (this.isNuevoSubgroup) { // Si es subGroup existente, verificar si tienen la misma dirección (cliente y fiscal)
      this.sameAddressFiscal = false;
    } else {
      this.disableFiles = true;
      this.isFileValidated = true; // Que no valide si ya es uno existente
    }
    if (this.isClientFisico) {
      this.disableAllFisicoIsSubgroupExist(this.isNuevoSubgroup);
      this.subgroupFisico.general_data.rfc = null;
    } else {
      this.disableIfIsGroupExist(this.isNuevoSubgroup);
      this.subGroup.general_data.rfc = null;
    }
  }

  onChangeOtherAddres(event) {
    this.isNewStreetOther = event;
    if (this.isNewStreetOther) {
      this.address.streetCode = null;
    } else {
      this.address.street = '';
    }
  }

  /**
   * Valida direcciones adicionales para evitar duplicidad
   */
  validateDuplicateAddress(data, index: number) {
    let arrayToCompare;
    let isStretDuplicate = false;

    if (index !== null) {
      arrayToCompare = this.subGroup.addressList.filter((element, i) => i !== index);

      arrayToCompare.some(x => {
        if (x.country === data.country && x.stateCode === data.stateCode && x.municipalityCode === data.municipalityCode
          && x.zipCode === data.zipCode && x.neighborhoodCode === data.neighborhoodCode && (x.streetCode === data.streetCode ||
            x.street === data.street) && x.numExt === data.numExt && x.numInt === data.numInt) {
          this.toastr.warning('La direccion adicional ya se encuenta agregada', 'Notificación');
          isStretDuplicate = true;
          return;
        }
      });

    } else {
      this.subGroup.addressList.some(x => {
        if (x.country === data.country && x.stateCode === data.stateCode && x.municipalityCode === data.municipalityCode
          && x.zipCode === data.zipCode && x.neighborhoodCode === data.neighborhoodCode && (x.streetCode === data.streetCode ||
            x.street === data.street) && x.numExt === data.numExt && x.numInt === data.numInt) {
          this.toastr.warning('La direccion adicional ya se encuenta agregada', 'Notificación');
          isStretDuplicate = true;
          return;
        }
      });

    }

    return isStretDuplicate;
  }

  /**
   * Valida subrupos para evitar duplicidad
   */
  validateDuplicateSubGroup(data): boolean {
    let isDuplicate = false;
    let arrayToCompare;

    if (this.isEditionSubGroup) {
      arrayToCompare = this.group.subgroups.filter((element, i) => i !== this.indexSubGroup);
      arrayToCompare.some((SUBGROUP) => {
        if (SUBGROUP.general_data.rfc === data.general_data.rfc || SUBGROUP.general_data.name === data.general_data.name) {
          this.toastr.warning('El subgrupo ya se encuentra agregado', 'Notificación');
          isDuplicate = true;
          return;
        }
      });
    } else {
      this.group.subgroups.some((SUBGROUP) => {
        if (SUBGROUP.general_data.rfc === data.general_data.rfc || SUBGROUP.general_data.name === data.general_data.name) {
          this.toastr.warning('El subgrupo ya se encuentra agregado', 'Notificación');
          isDuplicate = true;
          return;
        }
      });
    }
    return isDuplicate;
  }

  /**
   * Función que carga la inforamción de una dirección adicional para mostrarlos
   */

  openModalEditAditionalAddress(index) {
    this.isEditionOtherAddress = true;
    this.onEditAddressAdicionalChangeAddressListAdditional(index);
    // this.address = this.subGroup.addressList[index];

    this.direccionesForm.controls.countryOther.setValue(this.subGroup.addressList[index].country);
    this.direccionesForm.controls.stateOther.setValue(this.subGroup.addressList[index].stateCode);
    this.direccionesForm.controls.municipalityOther.setValue(this.subGroup.addressList[index].municipalityCode);
    this.direccionesForm.controls.zipCodeOther.setValue(this.subGroup.addressList[index].zipCode);
    this.direccionesForm.controls.neighborhoodOther.setValue(this.subGroup.addressList[index].neighborhoodCode);
    if (this.subGroup.addressList[index].streetCode !== null
      && this.subGroup.addressList[index].streetCode !== undefined) {
      this.isNewStreetOther = false;
      this.direccionesForm.controls.checkStreetOther.setValue(false);
      this.address.streetCode = this.subGroup.addressList[index].streetCode;
      this.direccionesForm.controls.streetOther.setValue(this.subGroup.addressList[index].streetCode);
    } else {
      this.isNewStreetOther = true;
      this.direccionesForm.controls.checkStreetOther.setValue(true);
      this.address.street = this.subGroup.addressList[index].street;
      this.direccionesForm.controls.streetOther.setValue(this.subGroup.addressList[index].street);
    }
    this.direccionesForm.controls.numIntOther.setValue(this.subGroup.addressList[index].numInt);
    this.direccionesForm.controls.numExtOther.setValue(this.subGroup.addressList[index].numExt);

    this.indexUpdate = index;
  }

  /**
   * Función que carga los listados de direcciones adicional para mostrarlos
   */
  onEditAddressAdicionalChangeAddressListAdditional(i) {
    this.municipalityListOther = [];
    this.zipCodeListOther = [];
    this.neighborhoodListOther = [];
    this.streetsListOther = [];


    this.invokeServiceGetMunicipalyByStateName
    (this.converterToKeyValueClass(null, this.subGroup.addressList[i].state.toUpperCase()), this._ADICIONAL);

    this.invokeServiceGetZipCodesByMunicipalyCode(
      this.converterToKeyValueClass(this.subGroup.addressList[i].municipalityCode, null), this._ADICIONAL);

    // Invocamos las colonia
    this.invokeServiceGetColoniesByZipCode(
      this.converterToKeyValueClass(
        this.subGroup.addressList[i].zipCode
        , this.subGroup.addressList[i].zipCode), this._ADICIONAL);


    if (this.subGroup.addressList[i].streetCode !== null
      && this.subGroup.addressList[i].streetCode !== undefined) {
      this.invokeServiceGetStreetsByColonie(
        this.converterToKeyValueClass(this.subGroup.addressList[i].neighborhoodCode, null), this._ADICIONAL);
    }
  }

  /**
   * Edita una dirección adicional
   */
  editAddressAditional() {

    if (!this.validateDuplicateAddress(this.address, this.indexUpdate)) {
      if (this.isNewStreetOther) {
        this.address.streetCode = null; // Debido a que comparten el mismo nombre del formControl, hay que estar limpiando
      }
      this.subGroup.addressList[this.indexUpdate] = this.address;
      const state = document.getElementById('stateOther').textContent;
      this.subGroup.addressList[this.indexUpdate].state = state.slice(1, state.length - 1);
      const municipality = document.getElementById('municipalityOther').textContent;
      this.subGroup.addressList[this.indexUpdate].municipality = municipality.slice(1, municipality.length - 1);
      const neighborhood = document.getElementById('neighborhoodOther').textContent;
      this.subGroup.addressList[this.indexUpdate].neighborhood = neighborhood.slice(1, neighborhood.length - 1);

      if (this.subGroup.addressList[this.indexUpdate].streetCode !== null &&
        this.subGroup.addressList[this.indexUpdate].streetCode !== undefined) {
        const street = document.getElementById('streetOther').textContent;
        this.subGroup.addressList[this.indexUpdate].street = street.slice(1, street.length - 1);
      }
      this.address = new Address(4);
      this.isEditionOtherAddress = false;
    }
  }

  /**
   * funcion que resetea la información correpondiente a un nuevo subgrupo
   */
  async newSubGroup() {
    await this.loadNewSubgroup();
    this.clearState();
    this.clearStateFiscal();
    this.invokeServiceGetStatesByCountry();
    this.datosContactoForm.reset();
    this.datosGeneralesForm.reset();
    this.direccionesForm.reset();
    this.uploadedFilesToLoad = [];
    this.isStepEnable = false;
    this.sameAddressFiscal = false;
    this.allAddresDisabled(false);
    this.inicilizeDataEveryNewGroup();
    // ---------------------------------------
    // this.ngWizardService.reset();
    this.selectedStepIndex = 1;
    this.ngWizardService.show(1);
    this.disableBtnNext = false;
    this.isStepEnable = true;
    this.datosGeneralesForm.controls.nuevoSubgrupo.setValue(true);
    this.isFileValidated = false;
    this.isNewStreetFiscal = false;
    this.isNewStreet = false;
    this.isNewStreetOther = false;
    this.direccionesForm.controls.countryFiscal.setValue('MÉXICO');
    this.direccionesForm.controls.country.setValue('MÉXICO');
    this.direccionesForm.controls.countryOther.setValue('MÉXICO');
    this.direccionesForm.controls.country.disable();
  }

  /**
   * funcion que activa o desactiva todas los campos
   */
  disableIfIsGroupExist(isNewSubGroup: boolean) {
    if (isNewSubGroup) {
      this.datosGeneralesForm.get('typeSociety').enable();
      this.datosGeneralesForm.get('razonSocial').enable();
      this.datosGeneralesForm.get('comentarios').enable();
      this.datosContactoForm.enable();
      this.direccionesForm.enable();
      this.loadNewSubgroup();
      this.disableFiles = false;
      this.uploadedFilesToLoad = [];
      this.subGroup.general_data.clientType = this.clientType;
    } else {
      this.datosGeneralesForm.get('typeSociety').disable();
      this.datosGeneralesForm.get('razonSocial').disable();
      this.datosGeneralesForm.get('comentarios').disable();
      this.datosContactoForm.disable();
      this.direccionesForm.disable();
    }
  }

  disableAllFisicoIsSubgroupExist(isNewSubGroup: boolean) {
    if (isNewSubGroup) {
      this.datosGeneralesFisicoForm.disable();
      this.direccionesFisicoForm.disable();
      this.disableFiles = true;
    } else {
      this.datosGeneralesFisicoForm.disable();
      this.direccionesFisicoForm.disable();
      this.disableFiles = true;
    }
  }

  /**
   * funcion que oculta el pop up de polizas
   */
  closeModalPolizas() {
   this.modalRef.hide();
  }

  closeModalOnly() {
    this.onCloseAction.emit();
  }

  /**
   * funcion que carga la póliza seleccionada
   */
  loadPolicy(policy) {
    this.shared.fPolicyDataReadonly = true;
    this.shared.gPolicyId.next(policy.policyId);
    this.router.navigate(['/admin/policies']);
    this.modalRef.hide();
  }

  copyContact(event) {
    const contactToCopy = this.subGroup.contactList[event];

    this.contact.name = contactToCopy.name;
    this.contact.email = contactToCopy.email;
    this.contact.phone = contactToCopy.phone;
  }

  addContact() {
    if (this.datosContactoForm.valid) {
      let typeContactExist = false;
      this.subGroup.contactList.some(CONTACT => {
        if (Number(this.contact.contactTypeId) !== 5 && Number(CONTACT.contactTypeId) === Number(this.contact.contactTypeId)) {
          typeContactExist = true;
        }
      });

      if (typeContactExist) {
        this.toastr.warning('EL TIPO DE CONTACTO ' +
        (this.contact.contactTypeId === 3 ? 'COMERCIAL' : 'COBRAZA') +
          ' YA EXISTE, FAVOR DE SELECCIONAR OTRO', 'NOTIFICACIÓN');
      } else {
        this.subGroup.contactList.push(this.contact);
        this.contact = new Contact();
        this.datosContactoForm.reset();
      }
    } else {
      this.datosContactoForm.markAllAsTouched();
    }
  }

  deleteContact(index) {
    this.subGroup.contactList.splice(index, 1);
  }

  editContact(index) {
    this.contact = this.subGroup.contactList[index];
    this.subGroup.contactList.splice(index, 1);
  }

  validContacs(): boolean {
    if (this.subGroup.contactList === null || this.subGroup.contactList === undefined || this.subGroup.contactList.length === 0) {
      return false;
    } else {
      let isCobranza = false;
      let isComercial = false;
      this.subGroup.contactList.map(CONTACT => {
        if (String(CONTACT.contactTypeId) === '3' || String(CONTACT.contactTypeId) === '4') {
          if (String(CONTACT.contactTypeId) === '3') {
            isComercial = true;
          } else {
            isCobranza = true;
          }
        }
      });

      if (isCobranza && isComercial) {
        return true;
      } else {
        return false;
      }
    }
  }

  validContacsMessage(): boolean {
    if (!this.validContacs()) {
      this.toastr.warning('ES NECESARIO TENER UN CONTACTO COBRANCA Y UN CONTACTO COMERCIAL', 'NOTIFIACIÓN');
      return false;
    } else {
      return true;
    }
  }

  getTypesAddress() {
    this.api.getTypesAddressForClients().then((data: any) => {
      if (data != null) {
        this.typesAddress = data;
      }
    }, error => {
    });
  }

  /**
   * Función guarda un grupo
   */
  saveGroup() {
    if (this.datosAgrupadorForm.valid) {
      this.spinner.show('sp');
      this.api.validateGroup(this.group.name).then(
        (exist: any) => {

          console.log(exist);
          if ((exist === 'false' || exist === false)) {
            this.api.saveGroup(this.group).then((data: any) => {
              this.spinner.hide('sp');
              this.toastr.success('EL GRUPO SE GUARDÓ DE FORMA CORRECTA', 'Notificación');
              this.group = data;
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.spinner.hide('sp');
            });
          } else if ((exist === 'true' || exist === true) && (this.group.groupId !== null)) {
            this.api.saveGroup(this.group).then((data: any) => {
              this.spinner.hide('sp');
              this.toastr.success('EL GRUPO SE GUARDÓ DE FORMA CORRECTA', 'Notificación');
              this.group = data;
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.spinner.hide('sp');
            });
          } else {
            this.spinner.hide('sp');
            this.toastr.warning('Ya existe un grupo llamado ' + this.group.name + ', favor de modificar el nombre', 'Notificación');
          }
        });

    } else {
      this.datosAgrupadorForm.markAllAsTouched();
    }
  }

  /**
   * Función guarda un grupo sin form
   */
  saveGroupPhysic() {
      this.spinner.show('sp');
      this.api.validateGroup(this.groupNamePhisic).then(
        (exist: any) => {
          if ((exist === 'false' || exist === false)) {

            const group = new Group();
            group.subgroups = new Array<Subgroup>();
            group.name = this.groupNamePhisic;
            group.groupId = null;

            this.api.saveGroup(group).then((data: any) => {
              this.toastr.success('EL GRUPO SE GUARDÓ DE FORMA CORRECTA', 'Notificación');
              this.spinner.hide('sp');
              this.invokeServiceGetGroups();
              this.closeModalNewGroup();
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.spinner.hide('sp');
              this.closeModalNewGroup();
            });
          } else {
            this.toastr.warning('Ya existe un grupo llamado ' + this.groupNamePhisic + ', favor de modificar el nombre', 'Notificación');
            this.spinner.hide('sp');
          }
        });
  }

  /**
   * Función guarda un grupo
   */
  saveSubgroup() {
    if (this.datosGeneralesForm.valid) {
      if (this.group.groupId === null || this.group.groupId === undefined) {
        this.toastr.warning('ES NECESARIO GUARDAR UN GRUPO', 'Notificación');
      } else {
        if (!this.group.subgroups.includes(this.subGroup)) {
          this.group.subgroups.push(this.subGroup);
        }
        this.spinner.show('sp');
        this.api.saveSubGroup(this.group).then((data: any) => {
          this.spinner.hide('sp');
          this.toastr.success('EL SUBGRUPO SE GUARDÓ DE MANERA EXITOSA', 'Notificación');
          this.group = data;
          this.subGroup = data.subgroups[0];
          localStorage.setItem('guardado', 'true');
          localStorage.setItem('groupMoral', JSON.stringify(this.group));
          localStorage.setItem('subgroupMoral', JSON.stringify(this.subGroup));
        }, error => {
          if (error.status === 500) {
            this.spinner.hide('sp');
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        });
      }

    } else {
      this.datosGeneralesForm.markAllAsTouched();
      this.spinner.hide('sp');
    }
  }

  /**
   * Función guarda un grupo
   */
  saveContactsSubgroup() {
    if (this.validContacsMessage()) {
      if (this.group.groupId === null || this.group.groupId === undefined) {
        this.toastr.warning('Es necesario guardar un grupo', 'Notificación');
        return;
      } else if (this.group.subgroups.length === null
        || this.group.subgroups.length === 0
        || this.group.subgroups[0].general_data.clientId === null
        || this.group.subgroups[0].general_data.clientId === undefined) {
        this.toastr.warning('Es necesario guardar un subgrupo', 'Notificación');
        return;
      } else {
        if (!this.group.subgroups.includes(this.subGroup)) {
          this.group.subgroups.push(this.subGroup);
        }
        this.spinner.show('sp');
        this.api.saveContactsSubgroup(this.group).then((data: any) => {
          this.spinner.hide('sp');
          this.toastr.success('EL CONTACTO SE GUARDÓ DE MANERA EXITOSA', 'Notificación');
          this.group = data;
          this.subGroup = data.subgroups[0];
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        });
      }

    } else {
      this.datosGeneralesForm.markAllAsTouched();
    }
  }

  /**
   * Función guardar la dirección
   */
  saveAddress() {
    if (this.direccionesForm.valid) {
      if (this.group.groupId === null || this.group.groupId === undefined) {
        this.toastr.warning('Es necesario guardar un grupo', 'Notificación');
        return;
      } else if (this.group.subgroups.length === null
        || this.group.subgroups.length === 0
        || this.group.subgroups[0].general_data.clientId === null
        || this.group.subgroups[0].general_data.clientId === undefined) {
        this.toastr.warning('Es necesario guardar un subgrupo', 'Notificación');
        return;
      } else {
        if (!this.group.subgroups.includes(this.subGroup)) {
          this.group.subgroups.push(this.subGroup);
        }
        this.spinner.show('sp');
        this.api.saveAddress(this.group).then((data: any) => {
          this.spinner.hide('sp');
          this.toastr.success('LAS DIRECCIONES SE GUARDARON DE MANERA EXITOSA', 'Notificación');
          this.group = data;
          this.subGroup = data.subgroups[0];
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        });
      }

    } else {
      this.direccionesForm.markAllAsTouched();
    }
  }

  /**
   * Función guardar la dirección
   */
  saveClientDocumentation() {
      if (this.validateFileRequieredFail()) {
      this.toastr.warning('ES NECESARIO CARGAR TODOS LOS DOCUMENTOS REQUERIDOS, MARCADOS POR UN *.', 'NOTIFICACIÓN');
      return;
      } else if (this.group.subgroups.length === null
        || this.group.subgroups.length === 0
        || this.group.subgroups[0].general_data.clientId === null
        || this.group.subgroups[0].general_data.clientId === undefined) {
        this.toastr.warning('Es necesario guardar un subgrupo', 'Notificación');
        return;
      } else {

        this.addFilesToModel();
        this.isFileValidated = true;

        if (!this.group.subgroups.includes(this.subGroup)) {
          this.group.subgroups.push(this.subGroup);
        }

        this.spinner.show('sp');
        this.api.saveClientDocumentation(this.group).then((data: any) => {
          this.spinner.hide('sp');
          this.toastr.success('LOS DOCUMENTOS SE GUARDARON DE MANERA EXITOSA', 'Notificación');
          this.group = data;
          this.subGroup = data.subgroups[0];
          if (this.subGroup.documentationList === undefined) {
            this.subGroup.documentationList = new Array<DocumentationList>();
          }
          this.addModelFilesToListFiles();
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        });
      }
  }

  /**
   * Función guarda un grupo
   */
  saveClientPhysic() {
    if (this.datosGeneralesFisicoForm.valid || this.datosGeneralesFisicoForm.disabled) {
      if (!this.groupFisico.subgroups.includes(this.subgroupFisico)) {
        this.groupFisico.subgroups.push(this.subgroupFisico);
      }

      if (this.isNuevoSubgroup) {
        this.invokeServiceSaveClientPhysical(this.groupFisico);
      } else {
        this.invokeServiceSaveRelationGroupFisicToAmmia(this.groupFisico);
      }

    }
  }

  /**
   * Funcion que invoka el servicio para guardar una Direcciín de un cliente Persona Física
   */
  saveClientPhysicAddres() {

    if (this.direccionesFisicoForm.valid || this.direccionesFisicoForm.disabled ) {
      if (this.subgroupFisico.general_data.clientId === null
        || this.subgroupFisico.general_data.clientId === undefined) {
        this.toastr.warning('Es necesario guardar los datos del cliente', 'Notificación');
        return;
      } else {
        if ( this.groupFisico.subgroups !== undefined && this.groupFisico.subgroups.length > 0) {
          this.groupFisico.subgroups = [];
        }
        this.groupFisico.subgroups.push(this.subgroupFisico);

        this.spinner.show('sp');
        this.api.saveAddressPhysicalClient(this.groupFisico).then((data: any) => {
          this.spinner.hide('sp');
          this.toastr.success('LAS DIRECCIONES SE GUARDARON DE MANERA EXITOSA', 'Notificación');
          this.groupFisico = data;
          this.subgroupFisico = data.subgroups[0];
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        });
      }
    }
  }

  /**
   * Función guardar la dirección
   */
  saveDocumentsPhysical() {
    if (this.validateFileRequieredFail()) {
      this.toastr.warning('ES NECESARIO CARGAR TODOS LOS DOCUMENTOS REQUERIDOS, MARCADOS POR UN *.', 'NOTIFICACIÓN');
      return;
    } else if (this.subgroupFisico.general_data.clientId === null
      || this.subgroupFisico.general_data.clientId === undefined) {
      this.toastr.warning('Es necesario guardar los datos del cliente', 'Notificación');
      return;
    } else {

      this.addFilesToModel();
      this.isFileValidated = true;

      if ( this.groupFisico.subgroups !== undefined && this.groupFisico.subgroups.length > 0) {
        this.groupFisico.subgroups = [];
      }
      this.groupFisico.subgroups.push(this.subgroupFisico);

      this.spinner.show('sp');
      this.api.saveClientPhysicalDocumentation(this.groupFisico).then((data: any) => {
        this.spinner.hide('sp');
        this.toastr.success('LOS DOCUMENTOS SE GUARDARON DE MANERA EXITOSA', 'Notificación');
        this.groupFisico = data;
        this.subgroupFisico = data.subgroups[0];
        if (this.subgroupFisico.documentationList === undefined) {
          this.subgroupFisico.documentationList = new Array<DocumentationList>();
        }
        this.addModelFilesToListFiles();
        this.existFileUnSave();
      }, error => {
        this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        this.existFileUnSave();
        this.spinner.hide('sp');
      });
    }
  }

  openModalAddGroup(template: TemplateRef<any>) {
    this.modalRefNewGroup =
      this.modalService.show(template, {class: 'modal-sm', keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }

  //  -------------------CODIGO DE CARGA MASIVA DE CLIENTES FISICO /  MORAL -------------------
  closeModalNewGroup() {
    localStorage.setItem('clientNew', '');
    this.groupNamePhisic = '';
    // this.onCloseAction.emit();
    this.modalRefNewGroup.hide();
  }

  fileUploadXls(event: any) {
// Obtener la fecha actual
    const today = new Date();
    const e: any = event;
// Convertir a cadena ISO y extraer la fecha en formato aaaa-mm-dd
    const formattedDate = today.toISOString().slice(0, 10);

    console.log('FECHA: ' , formattedDate); // Salida: "2023-05-19";
// Obtener la fecha y hora actual
    const now = new Date();
    console.log('FECHA-HORA: ', now);
// Extraer la hora en formato hh:mm:ss
    const formattedTime = now.toLocaleTimeString();

    console.log('HORA: ' , formattedTime); // Salida: "13:45:30"

    this.presenta = false;
    console.log(event.target.files);
    let selectedFile = event.target.files[0];
    // this.onFileSelected(event);
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);

    if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {

      // tslint:disable-next-line:no-shadowed-variable
      fileReader.onload = (event) => {
        console.log('event: ', event);
        // @ts-ignore
        const binaryData = event.target.result;
        const workBook = XLSX.read(binaryData, {type: 'binary'});

        const sheetName = workBook.SheetNames[0];
        const worksheet = workBook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        if (this.validateExcelStructured(sheetName, worksheet, jsonData)) {

        workBook.SheetNames.forEach(sheet => {
          const data = XLSX.utils.sheet_to_json(workBook.Sheets[sheet]);
          console.log('data: ', data);
          this.convertToJson = JSON.stringify(data, undefined, 4);
        });
        console.log('workBook: ', workBook);
        console.log('convertToJson: ', this.convertToJson);
        this.infoCliente = JSON.parse(this.convertToJson);
        console.log('CONVERTIR A infoClienteFisico', this.infoCliente);

        if (this.validaExcel(this.infoCliente)) {

          this.convertToObjectToJson(formattedDate, formattedTime);
          console.log('YA VIENE CON FECHA Y HORA: ', this.infoCliente);

          this.convertToJson = JSON.stringify(this.infoCliente, undefined, 4);
          console.log('EL OBJETO QUE MANDO AL API: ', this.convertToJson);
          this.anexosFilesZip(this.infoCliente);
          if (this.esClienteFisico) {
            // llamado a servicios de cliente fisico
            this.spinner.show('sp');
            this.api.loadMassiveClientFisico(JSON.parse(this.convertToJson))
              .subscribe(
                response => {
                  this.idCargaCliente = response;
                  console.log('IDCARGA: ', this.idCargaCliente.idCarga);

                  this.cargaTemporalFisico(this.idCargaCliente.idCarga);
                }, error => {
                  selectedFile = null;
                  e.target.value = '';
                  this.toastr.warning(error.error);
                  this.spinner.hide('sp');
                  return;
                }
              );
          } else {
            // llamado a servicios de cliente moral
            this.spinner.show('sp');
            this.api.loadMassiveClientMoral(JSON.parse(this.convertToJson))
              .subscribe(
                response => {
                  this.idCargaCliente = response;
                  console.log('IDCARGA: ', this.idCargaCliente.idCarga);

                  this.cargaTemporalMoral(this.idCargaCliente.idCarga);
                }, error => {
                  selectedFile = null;
                  e.target.value = '';
                  this.toastr.warning(error.error);
                  this.spinner.hide('sp');
                  return;
                }
              );
          }
          // console.log('ES EL ERRONEO');
          // this.esValido = false;

          this.presenta = true;

          selectedFile = null;

        } else {
          selectedFile = null;
          e.target.value = '';
        }
        } else {
          if (this.esClienteFisico) {
            this.toastr.error('EL ARCHIVO SELECCIONADO, NO CONTIENE UNA ESTRUCTURA VALIDA PARA LA CARGA MASIVA DE CLIENTES FÍSICOS', 'NOTIFICACIÓN');
          } else {
            this.toastr.error('EL ARCHIVO SELECCIONADO, NO CONTIENE UNA ESTRUCTURA VALIDA PARA LA CARGA MASIVA DE CLIENTES MORALES', 'NOTIFICACIÓN');
          }
          selectedFile = null;
          e.target.value = '';
          return;
        }

      };

    } else {
      this.toastr.error('EL ARCHIVO SELECCIONADO, NO ES UN ARCHIVO DE EXCEL VALIDO, SELECCIONE EL ARCHIVO CORRECTO', 'NOTIFICACIÓN');
      selectedFile = null;
      e.target.value = '';
      return;
    }

  }

  convertToObjectToJson(formattedDate: any, formattedTime: any) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.infoCliente.length; i++) {
      this.infoCliente[i].fecha = formattedDate + ' ' + formattedTime;
      this.infoCliente[i].idUsuario = 946;
    }

    if (this.esClienteFisico) {
      // ES CLIENTE FISICO
      for (let i = 0; i < this.infoCliente.length; i++) {
        this.infoCliente[i].nombre = this.infoCliente[i].NOMBRE;
        this.infoCliente[i].apellidoPaterno = this.infoCliente[i].APELLIDO_PATERNO;
        this.infoCliente[i].apellidoMaterno = this.infoCliente[i].APELLIDO_MATERNO;
        this.infoCliente[i].rfc = this.infoCliente[i].RFC_CLIENTE;
        this.infoCliente[i].grupo = this.infoCliente[i].GRUPO;
        this.infoCliente[i].telefono = this.infoCliente[i].TELEFONO;
        this.infoCliente[i].email = this.infoCliente[i].CORREO;
        this.infoCliente[i].paisCliente = this.infoCliente[i].PAIS_CLIENTE;
        this.infoCliente[i].cpCliente = this.infoCliente[i].CODIGO_POSTAL_CLIENTE;
        this.infoCliente[i].estadoCliente = this.infoCliente[i].ESTADO_CLIENTE;
        this.infoCliente[i].municipioCliente = this.infoCliente[i].MUNICIPIO_CLIENTE;
        this.infoCliente[i].coloniaCliente = this.infoCliente[i].COLONIA_CLIENTE;
        this.infoCliente[i].calleCliente = this.infoCliente[i].CALLE_CLIENTE;
        this.infoCliente[i].numExtCliente = this.infoCliente[i].NUM_EXT_CLIENTE;
        this.infoCliente[i].numIntCliente = this.infoCliente[i].NUM_INT_CLIENTE;
        this.infoCliente[i].paisFiscal = this.infoCliente[i].PAIS_FISCAL;
        this.infoCliente[i].cpFiscal = this.infoCliente[i].CODIGO_POSTAL_FISCAL;
        this.infoCliente[i].estadoFiscal = this.infoCliente[i].ESTADO_FISCAL;
        this.infoCliente[i].municipioFiscal = this.infoCliente[i].MUNICIPIO_FISCAL;
        this.infoCliente[i].coloniaFiscal = this.infoCliente[i].COLONIA_FISCAL;
        this.infoCliente[i].calleFiscal = this.infoCliente[i].CALLE_FISCAL;
        this.infoCliente[i].numExtFiscal = this.infoCliente[i].NUM_EXT_FISCAL;
        this.infoCliente[i].numIntFiscal = this.infoCliente[i].NUM_INT_FISCAL;
        this.infoCliente[i].actaNacimiento = (this.infoCliente[i].ACTA_NACIMIENTO === undefined ? '' : this.infoCliente[i].ACTA_NACIMIENTO.toUpperCase());
        this.infoCliente[i].comprobanteDomFiscal = (this.infoCliente[i].COMPROBANTE_DOMICILIO_FISCAL === undefined ? '' : this.infoCliente[i].COMPROBANTE_DOMICILIO_FISCAL.toUpperCase());
        this.infoCliente[i].identificacionOficial = (this.infoCliente[i].IDENTIFICACION_OFICIAL === undefined ? '' : this.infoCliente[i].IDENTIFICACION_OFICIAL.toUpperCase());
        this.infoCliente[i].rfcDocumento = (this.infoCliente[i].RFC_DOCUMENTO === undefined ? '' : this.infoCliente[i].RFC_DOCUMENTO.toUpperCase());
        this.infoCliente[i].acusePrescripcion =  (this.infoCliente[i].ACUSE_PRESCRIPCION_RFC === undefined ? '' : this.infoCliente[i].ACUSE_PRESCRIPCION_RFC.toUpperCase());

        delete this.infoCliente[i].NOMBRE;
        delete this.infoCliente[i].APELLIDO_PATERNO;
        delete this.infoCliente[i].APELLIDO_MATERNO;
        delete this.infoCliente[i].RFC_CLIENTE;
        delete this.infoCliente[i].GRUPO;
        delete this.infoCliente[i].TELEFONO;
        delete this.infoCliente[i].CORREO;
        delete this.infoCliente[i].PAIS_CLIENTE;
        delete this.infoCliente[i].CODIGO_POSTAL_CLIENTE;
        delete this.infoCliente[i].ESTADO_CLIENTE;
        delete this.infoCliente[i].MUNICIPIO_CLIENTE;
        delete this.infoCliente[i].COLONIA_CLIENTE;
        delete this.infoCliente[i].CALLE_CLIENTE;
        delete this.infoCliente[i].NUM_EXT_CLIENTE;
        delete this.infoCliente[i].NUM_INT_CLIENTE;
        delete this.infoCliente[i].PAIS_FISCAL;
        delete this.infoCliente[i].CODIGO_POSTAL_FISCAL;
        delete this.infoCliente[i].ESTADO_FISCAL;
        delete this.infoCliente[i].MUNICIPIO_FISCAL;
        delete this.infoCliente[i].COLONIA_FISCAL;
        delete this.infoCliente[i].CALLE_FISCAL;
        delete this.infoCliente[i].NUM_EXT_FISCAL;
        delete this.infoCliente[i].NUM_INT_FISCAL;
        delete this.infoCliente[i].ACTA_NACIMIENTO;
        delete this.infoCliente[i].COMPROBANTE_DOMICILIO_FISCAL;
        delete this.infoCliente[i].IDENTIFICACION_OFICIAL;
        delete this.infoCliente[i].RFC_DOCUMENTO;
        delete this.infoCliente[i].ACUSE_PRESCRIPCION_RFC;

      }

    } else {
      // ES CLIENTE MORAL
      for (let i = 0; i < this.infoCliente.length; i++) {
        this.infoCliente[i].grupo = this.infoCliente[i].NOMBRE_GRUPO;
        this.infoCliente[i].rfcSubgrupo = this.infoCliente[i].RFC_SUBGRUPO;
        this.infoCliente[i].tipoSociedad = this.infoCliente[i].TIPO_SOCIEDAD;
        this.infoCliente[i].razonSocial = this.infoCliente[i].RAZON_SOCIAL;
        this.infoCliente[i].nombreContacto = this.infoCliente[i].NOMBRE_CONTACTO;
        this.infoCliente[i].telefono = this.infoCliente[i].TELEFONO_CONTACTO;
        this.infoCliente[i].email = this.infoCliente[i].EMAIL_CONTACTO;
        this.infoCliente[i].tipoContacto = this.infoCliente[i].TIPO_CONTACTO;
        this.infoCliente[i].paisCliente = this.infoCliente[i].PAIS_CLIENTE;
        this.infoCliente[i].cpCliente = this.infoCliente[i].CODIGO_POSTAL_CLIENTE;
        this.infoCliente[i].estadoCliente = this.infoCliente[i].ESTADO_CLIENTE;
        this.infoCliente[i].municipioCliente = this.infoCliente[i].MUNICIPIO_CLIENTE;
        this.infoCliente[i].coloniaCliente = this.infoCliente[i].COLONIA_CLIENTE;
        this.infoCliente[i].calleCliente = this.infoCliente[i].CALLE_CLIENTE;
        this.infoCliente[i].numExtCliente = this.infoCliente[i].NUM_EXT_CLIENTE;
        this.infoCliente[i].numIntCliente = this.infoCliente[i].NUM_INT_CLIENTE;
        this.infoCliente[i].paisFiscal = this.infoCliente[i].PAIS_FISCAL;
        this.infoCliente[i].cpFiscal = this.infoCliente[i].CODIGO_POSTAL_FISCAL;
        this.infoCliente[i].estadoFiscal = this.infoCliente[i].ESTADO_FISCAL;
        this.infoCliente[i].municipioFiscal = this.infoCliente[i].MUNICIPIO_FISCAL;
        this.infoCliente[i].coloniaFiscal = this.infoCliente[i].COLONIA_FISCAL;
        this.infoCliente[i].calleFiscal = this.infoCliente[i].CALLE_FISCAL;
        this.infoCliente[i].numExtFiscal = this.infoCliente[i].NUM_EXT_FISCAL;
        this.infoCliente[i].numIntFiscal = this.infoCliente[i].NUM_INT_FISCAL;
        this.infoCliente[i].actaConstitutiva =  (this.infoCliente[i].ACTA_CONSTITUTIVA === undefined ? '' : this.infoCliente[i].ACTA_CONSTITUTIVA.toUpperCase());
        this.infoCliente[i].poderNotarial =  (this.infoCliente[i].PODER_NOTARIAL === undefined ? '' : this.infoCliente[i].PODER_NOTARIAL.toUpperCase());
        this.infoCliente[i].identificacionApoderado =  (this.infoCliente[i].IDENTIFICACION_APODERADO === undefined ? '' : this.infoCliente[i].IDENTIFICACION_APODERADO.toUpperCase());
        this.infoCliente[i].comprobanteDomicilio =  (this.infoCliente[i].COMPROBANTE_DOMICILIO === undefined ? '' : this.infoCliente[i].COMPROBANTE_DOMICILIO.toUpperCase());
        this.infoCliente[i].comprobanteDomFiscal =  (this.infoCliente[i].COMPROBANTE_DOMICILIO_FISCAL === undefined ? '' : this.infoCliente[i].COMPROBANTE_DOMICILIO_FISCAL.toUpperCase());

        delete this.infoCliente[i].NOMBRE_GRUPO;
        delete this.infoCliente[i].RFC_SUBGRUPO;
        delete this.infoCliente[i].TIPO_SOCIEDAD;
        delete this.infoCliente[i].RAZON_SOCIAL;
        delete this.infoCliente[i].NOMBRE_CONTACTO;
        delete this.infoCliente[i].TIPO_CONTACTO;
        delete this.infoCliente[i].TELEFONO_CONTACTO;
        delete this.infoCliente[i].EMAIL_CONTACTO;
        delete this.infoCliente[i].PAIS_CLIENTE;
        delete this.infoCliente[i].CODIGO_POSTAL_CLIENTE;
        delete this.infoCliente[i].ESTADO_CLIENTE;
        delete this.infoCliente[i].MUNICIPIO_CLIENTE;
        delete this.infoCliente[i].COLONIA_CLIENTE;
        delete this.infoCliente[i].CALLE_CLIENTE;
        delete this.infoCliente[i].NUM_EXT_CLIENTE;
        delete this.infoCliente[i].NUM_INT_CLIENTE;
        delete this.infoCliente[i].PAIS_FISCAL;
        delete this.infoCliente[i].CODIGO_POSTAL_FISCAL;
        delete this.infoCliente[i].ESTADO_FISCAL;
        delete this.infoCliente[i].MUNICIPIO_FISCAL;
        delete this.infoCliente[i].COLONIA_FISCAL;
        delete this.infoCliente[i].CALLE_FISCAL;
        delete this.infoCliente[i].NUM_EXT_FISCAL;
        delete this.infoCliente[i].NUM_INT_FISCAL;
        delete this.infoCliente[i].ACTA_CONSTITUTIVA;
        delete this.infoCliente[i].PODER_NOTARIAL;
        delete this.infoCliente[i].IDENTIFICACION_APODERADO;
        delete this.infoCliente[i].COMPROBANTE_DOMICILIO;
        delete this.infoCliente[i].COMPROBANTE_DOMICILIO_FISCAL;

      }
    }
  }

  cargaTemporalFisico(idCarga: any) {

    this.api.getMassiveClienteTempFisico(idCarga)
      .then(
        (response: any) => {
          console.log('CARGA INFO TEMPORAL: ', response);
          this.api.validateLoadMassiveClientFisico(response)
            .subscribe(
              datos => {
                let data: any;
                data = datos;
                for (let i = 0; i < data.length; i++) {
                  data[i].cargaId = idCarga;
                }
                console.log('datos finales de validacion con el ID DE CARGA: ', data);
                this.infoClienteFisico = data;
                setTimeout(() => {
                    this.spinner.hide('sp');
                    this.toastr.success('SU INFORMACIÓN HA SIDO CARGADA CORRECTAMENTE.', 'NOTIFICACIÓN');
                  },
                  1000);
              }, error => {
                this.spinner.hide('sp');
                this.toastr.warning(error.error);
              }
            );

        }, error => {
          console.error(error);
          this.spinner.hide('sp');
        }
      );

  }
  openDelete(template: TemplateRef<any>, r) {
    this.detalleId = 0;
    this.detalleId = r.detalleId;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false, backdrop: 'static'});
  }

  deleteRow() {
    if (this.esClienteFisico) {
      this.spinner.show('sp');
      this.api.deleteRowLoadMassiveFisico(this.detalleId)
        .then(
          (response: any) => {

            const indexF = this.infoClienteFisico.findIndex((fisico) => fisico.detalleId === this.detalleId);
            if (indexF >= 0) {
              this.infoClienteFisico.splice(indexF, 1);
            }
            this.anexosFilesZip(this.infoClienteFisico);
            this.toastr.success('LA FILA SE HA ELIMINADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
            this.modalRef.hide();
          }, error => {
            this.toastr.error('OCURRIÓ UN PROBLEMA AL PROECESAR SU PETICIÓN, CONTACTE AL ADMINISTRADOR.', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
          }
        );
    } else {
      this.spinner.show('sp');
      this.api.deleteRowLoadMassiveMoral(this.detalleId)
        .then(
          (response: any) => {

            const indexM = this.infoClienteMoral.findIndex((fisico) => fisico.detalleId === this.detalleId);
            if (indexM >= 0) {
              this.infoClienteMoral.splice(indexM, 1);
            }
            this.anexosFilesZip(this.infoClienteMoral);
            this.toastr.success('LA FILA SE HA ELIMINADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
            this.modalRef.hide();
          }, error => {
            this.toastr.error('OCURRIÓ UN PROBLEMA AL PROECESAR SU PETICIÓN, CONTACTE AL ADMINISTRADOR.', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
          }
        );
    }
  }

  closeModal() {
    this.modalRef.hide();
  }

  closeThisModal() {
  }

  editClientF(template: TemplateRef<any>, clientFisico: ClienteFisico) {
    const clientPhisique = { ...clientFisico };
    this.neighborhoodListClientes = [];
    this.neighborhoodListClientesFisico = [];
    const dato = {
      cKey: clientPhisique.cpFiscal,
      cValue: clientPhisique.cpFiscal
    };
    const datoCliente = {
      cKey: clientPhisique.cpCliente,
      cValue: clientPhisique.cpCliente
    };
    // tslint:disable-next-line:no-unused-expression
    this.cargaColonias(dato);
    this.cargaColoniasClient(datoCliente);

    setTimeout(() => {
        this.editingClientFisico = { ...clientFisico };
        this.editingClientFisico.paisFiscal = 'MÉXICO';
        this.editingClientFisico.paisCliente = 'MÉXICO';
        this.editingClientFisico.idPaisFiscal = 1;
        this.editingClientFisico.idPaisCliente = 1;
        this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
      },
      1000);
  }
  saveClientF() {
    if (this.editingClientFisico && this.validaExcelEdicion(this.editingClientFisico)) {
      const index = this.infoClienteFisico.findIndex((fisico) => fisico.detalleId === this.editingClientFisico.detalleId);
      this.infoClienteFisico[index] = { ...this.editingClientFisico };
      this.editingClientFisico = null;
      this.neighborhoodListClientes = [];
      this.neighborhoodListClientesFisico = [];
      this.modalRef.hide();
    }

  }

  cancelarEdicion() {
    this.neighborhoodListClientes = [];
    this.editingClientFisico = null;
    this.editingClientMoral = null;
    this.modalRef.hide();
  }

  changeState(e: any) {
    let valor: any;
    if (this.esClienteFisico) {
       valor = this.stateListFiscal.find(a => a.cKey === e);
       this.editingClientFisico.idEstadoFiscal = e;
       this.editingClientFisico.estadoFiscal = valor.cValue;
    } else {
       valor = this.stateListFiscal.find(a => a.cKey === e);
       this.editingClientMoral.idEstadoFiscal = e;
       this.editingClientMoral.estadoFiscal = valor.cValue;
    }
    console.log(e);
    this.cargaMunicipios(valor);
  }

  cargaMunicipios(data: any) {
    this.api.getMunicipalyByStateName(data).then((result: any) => {
      if (result != null) {
        this.municipalityListClientes = result;
      }

    }, error => {

    });
  }
  changeMunicipaly(e: any) {
    let valor: any;
    if (this.esClienteFisico) {
      valor = this.municipalityListClientes.find(a => a.cKey === e);
      this.editingClientFisico.idMunicipioFiscal = e;
      this.editingClientFisico.municipioFiscal = valor.cValue;
    } else {
       valor = this.municipalityListClientes.find(a => a.cKey === e);
       this.editingClientMoral.idMunicipioFiscal = e;
       this.editingClientMoral.municipioFiscal = valor.cValue;
    }
    console.log(e);
  }

  cargaColonias(data: any) {
  this.api.getColoniesByZipCode(data).then((result: any) => {
  if (result != null) {
  this.neighborhoodListClientes = result;
  }
  }, error => {
    /*console.error(error.status);
*/
  });
  }

  changeColony(e: any) {
    let valor: any;
    if (this.esClienteFisico) {
      valor = this.neighborhoodListClientes.find(a => a.cKey === e);
      this.editingClientFisico.idColoniaFiscal = e;
      this.editingClientFisico.coloniaFiscal = valor.cValue;
    } else {
      valor = this.neighborhoodListClientes.find(a => a.cKey === e);
      this.editingClientMoral.idColoniaFiscal = e;
      this.editingClientMoral.coloniaFiscal = valor.cValue;
    }
    console.log(e);
  }

  cargaColoniasonFocus(tipo: number) {
    let dato: any;
if (this.esClienteFisico) {
  if (tipo === 1) {
    dato = {
      cKey: this.editingClientFisico.cpFiscal,
      cValue: this.editingClientFisico.cpFiscal
    };
    this.editingClientFisico.idColoniaFiscal = null;
  } else {
    dato = {
      cKey: this.editingClientFisico.cpCliente,
      cValue: this.editingClientFisico.cpCliente
    };
    this.editingClientFisico.idColoniaCliente = null;
  }
  this.neighborhoodListClientes = [];
} else {
  if (tipo === 1) {
    dato = {
      cKey: this.editingClientMoral.cpFiscal,
      cValue: this.editingClientMoral.cpFiscal
    };
    this.editingClientMoral.idColoniaFiscal = null;
  } else {
    dato = {
      cKey: this.editingClientMoral.cpCliente,
      cValue: this.editingClientMoral.cpCliente
    };
    this.editingClientMoral.idColoniaCliente = null;
  }
  this.neighborhoodListClientes = [];
}

    const tipoDireccion = 'FISCAL';
// ----------------------------
    this.api.getCheckZipeCode(dato.cKey).then((result: any) => {
      if (result != null) {
        if (result) {
          this.invokeStateByZipCodeCM(dato.cKey, tipoDireccion, tipo);
          let value;
          if (tipoDireccion === 'FISCAL') {
              value = {
                cKey: dato.cKey,
                cValue: dato.cKey
              };
            }


        } else {
          this.toastr.warning('CODIGO POSTAL INEXISTENTE, POR FAVOR INTENTE CON OTRO', 'NOTIFICACIÓN');
          return;
        }
      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });
// ------------------------------

    this.api.getColoniesByZipCode(dato).then((result: any) => {
      if (result != null) {
        if (tipo === 1) {
          this.neighborhoodListClientes = result;
        } else {
          this. neighborhoodListClientesFisico = result;
        }
      } else {
        // this.toastr.warning('CODIGO POSTAL INEXISTENTE', 'NOTIFICACIÓN');
        return false;
      }
    }, error => {
      /*console.error(error.status);
  */
    });
  }

  validateInfo() {

    if (this.esClienteFisico) {
      this.anexosFilesZip(this.infoClienteFisico);
    } else {
      this.anexosFilesZip(this.infoClienteMoral);
    }

    if (this.anexosZips.length > 0) {
      if (this.archivosZip != null) {
        if (this.archivosZip.length === 0) {
          this.toastr.warning('CARGUE EL ARCHIVO .ZIP, CON LOS ARCHIVOS DE LOS ANEXOS, DESCRITOS EN EL LAYOUT.', 'NOTIFICACIÓN');
          return;
        }
      } else {
        this.toastr.warning('CARGUE EL ARCHIVO .ZIP, CON LOS ARCHIVOS DE LOS ANEXOS, DESCRITOS EN EL LAYOUT.', 'NOTIFICACIÓN');
        return;
      }
    }

    if (this.anexosZips.length !== this.archivosZip.length) {
      // tslint:disable-next-line:max-line-length
      this.toastr.error('EL NUMERO DE ARCHIVOS CONTENIDOS EN EL .ZIP, NO CORRESPONDEN AL NUMERO CONTENIDOS EN LA PLANTILLA, VERIFIQUE SUS ARCHIVOS', 'NOTIFICACIÓN');
      return;
    }

    let contador = 0;
    for (const fxlsx of this.anexosZips) {
      console.log(fxlsx.toUpperCase());
      for (const fzip of this.archivosZip) {
        if (fxlsx.toUpperCase() === fzip.toUpperCase() ) {
          contador++;
        }
      }
    }

    if (this.anexosZips.length !== contador) {
      // tslint:disable-next-line:max-line-length
      this.toastr.error('LOS ARCHIVOS CONTENIDOS EN EL .ZIP, NO CORRESPONDEN A LOS DESCRITOS EN LA PLANTILLA, VERIFIQUE SUS ARCHIVOS', 'NOTIFICACIÓN');
      return;
    }

    this.infoClienteValidate = [];
    if (this.esClienteFisico) {

      for (let i = 0; i < this.infoClienteFisico.length; i++) {
        this.infoClienteFisico[i].cargaId = this.idCargaCliente.idCarga;
      }
      for (const dato of this.infoClienteFisico) {
        this.infoClienteValidate.push(dato);
      }

      if (this.infoClienteValidate.length === 0) {
        if (this.esClienteFisico) {
          this.toastr.warning('CARGUE EL LAYOUT DE CARGA MASIVA DE CLIENTES FÍSICOS, PARA PODER VALIDAR LA INFORMACION.', 'NOTIFICACIÓN');
        } else {
          this.toastr.warning('CARGUE EL LAYOUT DE CARGA MASIVA DE CLIENTES MORALES, PARA PODER VALIDAR LA INFORMACION.', 'NOTIFICACIÓN');
        }
        return;
      }

      console.log('VALIDANDO: ', this.infoClienteValidate);
      this.spinner.show('sp');
      this.api.validateLoadMassiveClientFisico(this.infoClienteValidate)
        .subscribe(
          datos => {
            let data: any;
            data = datos;
            console.log('datos finales que ENVIO AL UPDATE: ', data);

            this.api.updateMassiveClienteFisico(data)
              .subscribe(
                response => {
                  console.log('IDCARGA DEL UPDATE: ', this.idCargaCliente.idCarga);
                  this.cargaTemporalUpdateFisico(this.idCargaCliente.idCarga);

                  this.infoClienteValidate = [];
                }, error => {
                  this.toastr.warning(error.error);
                  this.spinner.hide('sp');
                }
              );


          }, error => {
            this.toastr.warning(error.error);
            this.spinner.hide('sp');
          }
        );
    } else {

      for (let i = 0; i < this.infoClienteMoral.length; i++) {
        this.infoClienteMoral[i].cargaId = this.idCargaCliente.idCarga;
      }

      for (const dato of this.infoClienteMoral) {
        this.infoClienteValidate.push(dato);
      }

      console.log('VALIDANDO INFO MORAL: ', this.infoClienteValidate);
      this.spinner.show('sp');
      this.api.validateLoadMassiveClientMoral(this.infoClienteValidate)
        .subscribe(
          datos => {
            let data: any;
            data = datos;
            console.log('datos finales que ENVIO AL UPDATE: ', data);

            this.api.updateMassiveClienteMoral(data)
              .subscribe(
                response => {
                  console.log('IDCARGA DEL UPDATE: ', this.idCargaCliente.idCarga);
                  this.cargaTemporalUpdateMoral(this.idCargaCliente.idCarga);

                  this.infoClienteValidate = [];
                }, error => {
                  this.toastr.warning(error.error);
                  this.spinner.hide('sp');
                }
              );

          }, error => {
            this.toastr.warning(error.error);
            this.spinner.hide('sp');
          }
        );

    }
  }

  cargaTemporalUpdateFisico(idCarga: any) {

    this.api.getMassiveClienteTempFisico(idCarga)
      .then(
        (response: any) => {

          this.api.validateLoadMassiveClientFisico(response)
            .subscribe(
              datos => {
                 this.infoClienteFisico = [];
                // this.infoPolicyMoral = [];
                 this.infoClienteFisico = datos;
                 this.spinner.hide('sp');
                 console.log('RESULTADO DE LA VALIDACION DE INFORMACION FISICA: ', this.infoClienteFisico);
                 this.procesarInfo(this.infoClienteFisico);
                 if (!this.esValidoProcesar) {
                  this.toastr.success('SU INFORMACIÓN HA SIDO ACTUALIZADA CORRECTAMENTE.', 'NOTIFICACIÓN');
                } else {
                  this.toastr.success('INFORMACIÓN VÁLIDA PARA PROCESAR.', 'NOTIFICACIÓN');
                }

              }, error => {
                this.toastr.warning(error.error);
                this.spinner.hide('sp');
              }
            );

        }, error => {
          console.error(error);
          this.spinner.hide('sp');
        }
      );
  }

  validaExcel(excel: any) {

    if (excel.length === 0) {
      this.toastr.error('LA PLANTILLA NO CONTIENE INFORMACIÓN', 'NOTIFICACIÓN');
      return false;
    }

    if (this.esClienteFisico) {
        // VALIDACION CLIENTE FISICO
        for (const columna of excel) {
          if (columna.NOMBRE === '' || columna.NOMBRE === undefined) {
            this.toastr.error('LA COLUMNA NOMBRE, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
            return false;
            break;
          }
          if (!this.validarCamposSqlInjection(columna.NOMBRE)) {
            this.toastr.error('LA COLUMNA NOMBRE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.NOMBRE)) {
            this.toastr.error('LA COLUMNA NOMBRE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.NOMBRE)) {
            this.toastr.error('LA COLUMNA NOMBRE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }
        }


        for (const columna of excel) {
          if (columna.APELLIDO_PATERNO === '' || columna.APELLIDO_PATERNO === undefined) {
            this.toastr.error('LA COLUMNA APELLIDO_PATERNO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
            return false;
            break;
          }
          if (!this.validarCamposSqlInjection(columna.APELLIDO_PATERNO)) {
            this.toastr.error('LA COLUMNA APELLIDO_PATERNO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.APELLIDO_PATERNO)) {
            this.toastr.error('LA COLUMNA APELLIDO_PATERNO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }
          if (!this.validarCaracteresEspeciales(columna.APELLIDO_PATERNO)) {
            this.toastr.error('LA COLUMNA APELLIDO_PATERNO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

        }


        for (const columna of excel) {
        if (!this.validarCamposSqlInjection(columna.APELLIDO_MATERNO)) {
          this.toastr.error('LA COLUMNA APELLIDO_MATERNO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

          if (!this.validarCamposCommandInjection(columna.APELLIDO_MATERNO)) {
            this.toastr.error('LA COLUMNA APELLIDO_MATERNO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.APELLIDO_MATERNO)) {
            this.toastr.error('LA COLUMNA APELLIDO_MATERNO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

      }

        const rfcCliente: any[] = [];
        for (const columna of excel) {
        if (columna.RFC_CLIENTE === '' || columna.RFC_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA RFC_CLIENTE, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (!this.validarCamposSqlInjection(columna.RFC_CLIENTE)) {
          this.toastr.error('LA COLUMNA RFC_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
        rfcCliente.push(columna.RFC_CLIENTE);
        if (!this.validarRFC(columna.RFC_CLIENTE, this.esClienteFisico ? 'FISICA' : 'MORAL')) {
          this.toastr.error('EL RFC DEL CLIENTE, NO TIENE EL FORMATO CORRECTO, VERIFÍQUELO', 'NOTIFICACIÓN');
          return false;
          break;
        }

      }

        if (this.encontrarDuplicados(rfcCliente)) {
        this.toastr.error('LA COLUMNA RFC_CLIENTE, NO DEBE TENER INFORMACIÓN DUPLICADA', 'NOTIFICACIÓN');
        return false;
      }

      for (const columna of excel) {
        if (columna.GRUPO === '' || columna.GRUPO === undefined) {
          this.toastr.error('LA COLUMNA GRUPO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (!this.validarCamposSqlInjection(columna.GRUPO)) {
          this.toastr.error('LA COLUMNA GRUPO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.GRUPO)) {
          this.toastr.error('LA COLUMNA GRUPO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.GRUPO)) {
          this.toastr.error('LA COLUMNA GRUPO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

      }

        for (const columna of excel) {
          if (columna.TELEFONO === '' || columna.TELEFONO === undefined) {
            this.toastr.error('LA COLUMNA TELEFONO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (columna.CORREO === '' || columna.CORREO === undefined) {
            this.toastr.error('LA COLUMNA CORREO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposSqlInjection(columna.TELEFONO)) {
            this.toastr.error('LA COLUMNA TELÉFONO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarSoloNumeros(columna.TELEFONO)) {
            this.toastr.error('LA COLUMNA TELÉFONO, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposSqlInjection(columna.CORREO)) {
            this.toastr.error('LA COLUMNA CORREO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.TELEFONO)) {
            this.toastr.error('LA COLUMNA TELEFONO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarSoloNumeros(columna.TELEFONO)) {
            this.toastr.error('LA COLUMNA TELÉFONO, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.CORREO)) {
            this.toastr.error('LA COLUMNA CORREO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.TELEFONO)) {
            this.toastr.error('LA COLUMNA TELEFONO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarSoloNumeros(columna.TELEFONO)) {
            this.toastr.error('LA COLUMNA TELÉFONO, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
            return false;
            break;
          }

/*          if (!this.validarCaracteresEspeciales(columna.CORREO)) {
            this.toastr.error('LA COLUMNA CORREO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          } */

        }

        for (const columna of excel) {

          if (columna.PAIS_FISCAL === '' || columna.PAIS_FISCAL === undefined) {
            this.toastr.error('LA COLUMNA PAIS_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (columna.CODIGO_POSTAL_FISCAL === '' || columna.CODIGO_POSTAL_FISCAL === undefined) {
            this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
            return false;
            break;
          }
          if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_FISCAL)) {
            this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
            return false;
            break;
          }
          if (columna.ESTADO_FISCAL === '' || columna.ESTADO_FISCAL === undefined) {
            this.toastr.error('LA COLUMNA ESTADO_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
            return false;
            break;
          }
          if (columna.MUNICIPIO_FISCAL === '' || columna.MUNICIPIO_FISCAL === undefined) {
            this.toastr.error('LA COLUMNA MUNICIPIO_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
            return false;
            break;
          }
          if (columna.COLONIA_FISCAL === '' || columna.COLONIA_FISCAL === undefined) {
            this.toastr.error('LA COLUMNA COLONIA_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (columna.CALLE_FISCAL === '' || columna.CALLE_FISCAL === undefined) {
            this.toastr.error('LA COLUMNA CALLE_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (columna.NUM_EXT_FISCAL === '' || columna.NUM_EXT_FISCAL === undefined) {
            this.toastr.error('LA COLUMNA NUM_EXT_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposSqlInjection(columna.PAIS_FISCAL)) {
            this.toastr.error('LA COLUMNA PAIS_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposSqlInjection(columna.CODIGO_POSTAL_FISCAL)) {
            this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }
          if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_FISCAL)) {
            this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposSqlInjection(columna.ESTADO_FISCAL)) {
            this.toastr.error('LA COLUMNA ESTADO_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposSqlInjection(columna.MUNICIPIO_FISCAL)) {
            this.toastr.error('LA COLUMNA MUNICIPIO_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposSqlInjection(columna.COLONIA_FISCAL)) {
            this.toastr.error('LA COLUMNA COLONIA_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposSqlInjection(columna.CALLE_FISCAL)) {
            this.toastr.error('LA COLUMNA CALLE_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposSqlInjection(columna.NUM_EXT_FISCAL)) {
            this.toastr.error('LA COLUMNA NUM_EXT_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposSqlInjection(columna.NUM_INT_FISCAL)) {
            this.toastr.error('LA COLUMNA NUM_INT_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.CODIGO_POSTAL_FISCAL)) {
            this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }
          if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_FISCAL)) {
            this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.ESTADO_FISCAL)) {
            this.toastr.error('LA COLUMNA ESTADO_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.MUNICIPIO_FISCAL)) {
            this.toastr.error('LA COLUMNA MUNICIPIO_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.COLONIA_FISCAL)) {
            this.toastr.error('LA COLUMNA COLONIA_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.CALLE_FISCAL)) {
            this.toastr.error('LA COLUMNA CALLE_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.NUM_EXT_FISCAL)) {
            this.toastr.error('LA COLUMNA NUM_EXT_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.NUM_INT_FISCAL)) {
            this.toastr.error('LA COLUMNA NUM_INT_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.CODIGO_POSTAL_FISCAL)) {
            this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }
          if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_FISCAL)) {
            this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.ESTADO_FISCAL)) {
            this.toastr.error('LA COLUMNA ESTADO_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.MUNICIPIO_FISCAL)) {
            this.toastr.error('LA COLUMNA MUNICIPIO_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.COLONIA_FISCAL)) {
            this.toastr.error('LA COLUMNA COLONIA_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.CALLE_FISCAL)) {
            this.toastr.error('LA COLUMNA CALLE_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.NUM_EXT_FISCAL)) {
            this.toastr.error('LA COLUMNA NUM_EXT_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.NUM_INT_FISCAL)) {
            this.toastr.error('LA COLUMNA NUM_INT_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

        }

        for (const columna of excel) {
        if (columna.PAIS_CLIENTE === '' || columna.PAIS_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA PAIS_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (columna.CODIGO_POSTAL_CLIENTE === '' || columna.CODIGO_POSTAL_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_CLIENTE)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.ESTADO_CLIENTE === '' || columna.ESTADO_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA ESTADO_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.MUNICIPIO_CLIENTE === '' || columna.MUNICIPIO_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA MUNICIPIO_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.COLONIA_CLIENTE === '' || columna.COLONIA_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA COLONIA_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (columna.CALLE_CLIENTE === '' || columna.CALLE_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA CALLE_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (columna.NUM_EXT_CLIENTE === '' || columna.NUM_EXT_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA NUM_EXT_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.PAIS_CLIENTE)) {
          this.toastr.error('LA COLUMNA PAIS_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.CODIGO_POSTAL_CLIENTE)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_CLIENTE)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.ESTADO_CLIENTE)) {
          this.toastr.error('LA COLUMNA ESTADO_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.MUNICIPIO_CLIENTE)) {
          this.toastr.error('LA COLUMNA MUNICIPIO_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.COLONIA_CLIENTE)) {
          this.toastr.error('LA COLUMNA COLONIA_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.CALLE_CLIENTE)) {
          this.toastr.error('LA COLUMNA CALLE_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.NUM_EXT_CLIENTE)) {
          this.toastr.error('LA COLUMNA NUM_EXT_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.NUM_INT_CLIENTE)) {
          this.toastr.error('LA COLUMNA NUM_INT_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

          if (!this.validarCamposCommandInjection(columna.CODIGO_POSTAL_CLIENTE)) {
            this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

        if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_CLIENTE)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
          return false;
          break;
        }

          if (!this.validarCamposCommandInjection(columna.ESTADO_CLIENTE)) {
            this.toastr.error('LA COLUMNA ESTADO_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.MUNICIPIO_CLIENTE)) {
            this.toastr.error('LA COLUMNA MUNICIPIO_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.COLONIA_CLIENTE)) {
            this.toastr.error('LA COLUMNA COLONIA_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.CALLE_CLIENTE)) {
            this.toastr.error('LA COLUMNA CALLE_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.NUM_EXT_CLIENTE)) {
            this.toastr.error('LA COLUMNA NUM_EXT_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCamposCommandInjection(columna.NUM_INT_CLIENTE)) {
            this.toastr.error('LA COLUMNA NUM_INT_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.CODIGO_POSTAL_CLIENTE)) {
            this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_CLIENTE)) {
            this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.ESTADO_CLIENTE)) {
            this.toastr.error('LA COLUMNA ESTADO_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.MUNICIPIO_CLIENTE)) {
            this.toastr.error('LA COLUMNA MUNICIPIO_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.COLONIA_CLIENTE)) {
            this.toastr.error('LA COLUMNA COLONIA_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.CALLE_CLIENTE)) {
            this.toastr.error('LA COLUMNA CALLE_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.NUM_EXT_CLIENTE)) {
            this.toastr.error('LA COLUMNA NUM_EXT_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.NUM_INT_CLIENTE)) {
            this.toastr.error('LA COLUMNA NUM_INT_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

      }

        const anexosDuplicados: any[] = [];
        for (const columna of excel) {
        if (!this.validarCamposSqlInjection(columna.ACTA_NACIMIENTO)) {
          this.toastr.error('LA COLUMNA ACTA_NACIMIENTO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.ACTA_NACIMIENTO !== undefined) {
          anexosDuplicados.push(columna.ACTA_NACIMIENTO);
        }
        if (!this.validarCamposSqlInjection(columna.COMPROBANTE_DOMICILIO_FISCAL)) {
          this.toastr.error('LA COLUMNA COMPROBANTE_DOMICILIO_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.COMPROBANTE_DOMICILIO_FISCAL !== undefined) {
            anexosDuplicados.push(columna.COMPROBANTE_DOMICILIO_FISCAL);
          }
        if (!this.validarCamposSqlInjection(columna.IDENTIFICACION_OFICIAL)) {
          this.toastr.error('LA COLUMNA IDENTIFICACION_OFICIAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.IDENTIFICACION_OFICIAL !== undefined) {
            anexosDuplicados.push(columna.IDENTIFICACION_OFICIAL);
          }
        if (!this.validarCamposSqlInjection(columna.RFC_DOCUMENTO)) {
          this.toastr.error('LA COLUMNA RFC_DOCUMENTO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.RFC_DOCUMENTO !== undefined) {
            anexosDuplicados.push(columna.RFC_DOCUMENTO);
          }
        if (!this.validarCamposSqlInjection(columna.ACUSE_PRESCRIPCION_RFC)) {
          this.toastr.error('LA COLUMNA ACUSE_PRESCRIPCION_RFC, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.ACUSE_PRESCRIPCION_RFC !== undefined) {
            anexosDuplicados.push(columna.ACUSE_PRESCRIPCION_RFC);
          }

          if (!this.validarCaracteresEspeciales(columna.ACTA_NACIMIENTO)) {
            this.toastr.error('LA COLUMNA ACTA_NACIMIENTO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.COMPROBANTE_DOMICILIO_FISCAL)) {
            this.toastr.error('LA COLUMNA COMPROBANTE_DOMICILIO_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.IDENTIFICACION_OFICIAL)) {
            this.toastr.error('LA COLUMNA IDENTIFICACION_OFICIAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.RFC_DOCUMENTO)) {
            this.toastr.error('LA COLUMNA RFC_DOCUMENTO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

          if (!this.validarCaracteresEspeciales(columna.ACUSE_PRESCRIPCION_RFC)) {
            this.toastr.error('LA COLUMNA ACUSE_PRESCRIPCION_RFC, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
            return false;
            break;
          }

      }

        console.log('ANEXOS DUPLICADOS EN PLANTILLA DE CLIENTES FÍSICOS: ', anexosDuplicados);

        if (this.encontrarDuplicados(anexosDuplicados)) {
        this.toastr.error('LOS ANEXOS NO DEBEN TENER NOMBRES DE ARCHIVOS DUPLICADOS ', 'NOTIFICACIÓN');
        return false;
      }
} else {
      // VALIDACION CLIENTE MORAL

      for (const columna of excel) {
        if (columna.NOMBRE_GRUPO === '' || columna.NOMBRE_GRUPO === undefined) {
          this.toastr.error('LA COLUMNA NOMBRE_GRUPO, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (!this.validarCamposSqlInjection(columna.NOMBRE_GRUPO)) {
          this.toastr.error('LA COLUMNA NOMBRE_GRUPO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.NOMBRE_GRUPO)) {
          this.toastr.error('LA COLUMNA NOMBRE_GRUPO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.NOMBRE_GRUPO)) {
          this.toastr.error('LA COLUMNA NOMBRE_GRUPO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

      }

      for (const columna of excel) {
        if (columna.TIPO_SOCIEDAD === '' || columna.TIPO_SOCIEDAD === undefined) {
          this.toastr.error('LA COLUMNA TIPO_SOCIEDAD, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (!this.validarCamposSqlInjection(columna.TIPO_SOCIEDAD)) {
          this.toastr.error('LA COLUMNA TIPO_SOCIEDAD, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.TIPO_SOCIEDAD)) {
          this.toastr.error('LA COLUMNA TIPO_SOCIEDAD, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.TIPO_SOCIEDAD)) {
          this.toastr.error('LA COLUMNA TIPO_SOCIEDAD, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
      }

      for (const columna of excel) {
        if (columna.RAZON_SOCIAL === '' || columna.RAZON_SOCIAL === undefined) {
          this.toastr.error('LA COLUMNA RAZON_SOCIAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (!this.validarCamposSqlInjection(columna.RAZON_SOCIAL)) {
          this.toastr.error('LA COLUMNA RAZON_SOCIAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.RAZON_SOCIAL)) {
          this.toastr.error('LA COLUMNA RAZON_SOCIAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.RAZON_SOCIAL)) {
          this.toastr.error('LA COLUMNA RAZON_SOCIAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

      }

      for (const columna of excel) {
        if (columna.NOMBRE_CONTACTO === '' || columna.NOMBRE_CONTACTO === undefined) {
          this.toastr.error('LA COLUMNA NOMBRE_CONTACTO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (!this.validarCamposSqlInjection(columna.NOMBRE_CONTACTO)) {
          this.toastr.error('LA COLUMNA NOMBRE_CONTACTO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.NOMBRE_CONTACTO)) {
          this.toastr.error('LA COLUMNA NOMBRE_CONTACTO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.NOMBRE_CONTACTO)) {
          this.toastr.error('LA COLUMNA NOMBRE_CONTACTO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

      }


      const rfcCliente: any[] = [];
      for (const columna of excel) {
        if (columna.RFC_SUBGRUPO === '' || columna.RFC_SUBGRUPO === undefined) {
          this.toastr.error('LA COLUMNA RFC_SUBGRUPO, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (!this.validarCamposSqlInjection(columna.RFC_SUBGRUPO)) {
          this.toastr.error('LA COLUMNA RFC_SUBGRUPO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
        rfcCliente.push(columna.RFC_SUBGRUPO);
        if (!this.validarRFC(columna.RFC_SUBGRUPO, this.esClienteFisico ? 'FISICA' : 'MORAL')) {
          this.toastr.error('EL RFC_SUBGRUPO, NO TIENE EL FORMATO CORRECTO, VERIFIQUELO', 'NOTIFICACIÓN');
          return false;
          break;
        }

      }

      if (this.encontrarDuplicados(rfcCliente)) {
        this.toastr.error('LA COLUMNA RFC_SUBGRUPO, NO DEBE TENER INFORMACIÓN DUPLICADA', 'NOTIFICACIÓN');
        return false;
      }

      for (const columna of excel) {
        if (columna.TELEFONO_CONTACTO === '' || columna.TELEFONO_CONTACTO === undefined) {
          this.toastr.error('LA COLUMNA TELEFONO_CONTACTO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (columna.EMAIL_CONTACTO === '' || columna.EMAIL_CONTACTO === undefined) {
          this.toastr.error('LA COLUMNA EMAIL_CONTACTO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (columna.TIPO_CONTACTO === '' || columna.TIPO_CONTACTO === undefined) {
          this.toastr.error('LA COLUMNA TIPO_CONTACTO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.TELEFONO_CONTACTO)) {
          this.toastr.error('LA COLUMNA TELEFONO_CONTACTO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarSoloNumeros(columna.TELEFONO_CONTACTO)) {
          this.toastr.error('LA COLUMNA TELÉFONO, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.EMAIL_CONTACTO)) {
          this.toastr.error('LA COLUMNA EMAIL_CONTACTO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.TIPO_CONTACTO)) {
          this.toastr.error('LA COLUMNA TIPO_CONTACTO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.TELEFONO_CONTACTO)) {
          this.toastr.error('LA COLUMNA TELEFONO_CONTACTO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarSoloNumeros(columna.TELEFONO_CONTACTO)) {
          this.toastr.error('LA COLUMNA TELÉFONO, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.EMAIL_CONTACTO)) {
          this.toastr.error('LA COLUMNA EMAIL_CONTACTO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.TIPO_CONTACTO)) {
          this.toastr.error('LA COLUMNA TIPO_CONTACTO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.TELEFONO_CONTACTO)) {
          this.toastr.error('LA COLUMNA TELEFONO_CONTACTO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarSoloNumeros(columna.TELEFONO_CONTACTO)) {
          this.toastr.error('LA COLUMNA TELÉFONO, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
          return false;
          break;
        }

    /*    if (!this.validarCaracteresEspeciales(columna.EMAIL_CONTACTO)) {
          this.toastr.error('LA COLUMNA EMAIL_CONTACTO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
    */
        if (!this.validarCaracteresEspeciales(columna.TIPO_CONTACTO)) {
          this.toastr.error('LA COLUMNA TIPO_CONTACTO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

      }

      for (const columna of excel) {

        if (columna.PAIS_FISCAL === '' || columna.PAIS_FISCAL === undefined) {
          this.toastr.error('LA COLUMNA PAIS_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (columna.CODIGO_POSTAL_FISCAL === '' || columna.CODIGO_POSTAL_FISCAL === undefined) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_FISCAL)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.ESTADO_FISCAL === '' || columna.ESTADO_FISCAL === undefined) {
          this.toastr.error('LA COLUMNA ESTADO_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.MUNICIPIO_FISCAL === '' || columna.MUNICIPIO_FISCAL === undefined) {
          this.toastr.error('LA COLUMNA MUNICIPIO_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.COLONIA_FISCAL === '' || columna.COLONIA_FISCAL === undefined) {
          this.toastr.error('LA COLUMNA COLONIA_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (columna.CALLE_FISCAL === '' || columna.CALLE_FISCAL === undefined) {
          this.toastr.error('LA COLUMNA CALLE_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (columna.NUM_EXT_FISCAL === '' || columna.NUM_EXT_FISCAL === undefined) {
          this.toastr.error('LA COLUMNA NUM_EXT_FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.PAIS_FISCAL)) {
          this.toastr.error('LA COLUMNA PAIS_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.CODIGO_POSTAL_FISCAL)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_FISCAL)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.ESTADO_FISCAL)) {
          this.toastr.error('LA COLUMNA ESTADO_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.MUNICIPIO_FISCAL)) {
          this.toastr.error('LA COLUMNA MUNICIPIO_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.COLONIA_FISCAL)) {
          this.toastr.error('LA COLUMNA COLONIA_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.CALLE_FISCAL)) {
          this.toastr.error('LA COLUMNA CALLE_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.NUM_EXT_FISCAL)) {
          this.toastr.error('LA COLUMNA NUM_EXT_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.NUM_INT_FISCAL)) {
          this.toastr.error('LA COLUMNA NUM_INT_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.CODIGO_POSTAL_FISCAL)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_FISCAL)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.ESTADO_FISCAL)) {
          this.toastr.error('LA COLUMNA ESTADO_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.MUNICIPIO_FISCAL)) {
          this.toastr.error('LA COLUMNA MUNICIPIO_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.COLONIA_FISCAL)) {
          this.toastr.error('LA COLUMNA COLONIA_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.CALLE_FISCAL)) {
          this.toastr.error('LA COLUMNA CALLE_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.NUM_EXT_FISCAL)) {
          this.toastr.error('LA COLUMNA NUM_EXT_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(columna.NUM_INT_FISCAL)) {
          this.toastr.error('LA COLUMNA NUM_INT_FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.CODIGO_POSTAL_FISCAL)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_FISCAL)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_FISCAL, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.ESTADO_FISCAL)) {
          this.toastr.error('LA COLUMNA ESTADO_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.MUNICIPIO_FISCAL)) {
          this.toastr.error('LA COLUMNA MUNICIPIO_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.COLONIA_FISCAL)) {
          this.toastr.error('LA COLUMNA COLONIA_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.CALLE_FISCAL)) {
          this.toastr.error('LA COLUMNA CALLE_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.NUM_EXT_FISCAL)) {
          this.toastr.error('LA COLUMNA NUM_EXT_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.NUM_INT_FISCAL)) {
          this.toastr.error('LA COLUMNA NUM_INT_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

      }

      for (const columna of excel) {
        if (columna.PAIS_CLIENTE === '' || columna.PAIS_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA PAIS_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (columna.CODIGO_POSTAL_CLIENTE === '' || columna.CODIGO_POSTAL_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.ESTADO_CLIENTE === '' || columna.ESTADO_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA ESTADO_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.MUNICIPIO_CLIENTE === '' || columna.MUNICIPIO_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA MUNICIPIO_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.COLONIA_CLIENTE === '' || columna.COLONIA_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA COLONIA_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (columna.CALLE_CLIENTE === '' || columna.CALLE_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA CALLE_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (columna.NUM_EXT_CLIENTE === '' || columna.NUM_EXT_CLIENTE === undefined) {
          this.toastr.error('LA COLUMNA NUM_EXT_CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.PAIS_CLIENTE)) {
          this.toastr.error('LA COLUMNA PAIS_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.CODIGO_POSTAL_CLIENTE)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_CLIENTE)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.ESTADO_CLIENTE)) {
          this.toastr.error('LA COLUMNA ESTADO_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.MUNICIPIO_CLIENTE)) {
          this.toastr.error('LA COLUMNA MUNICIPIO_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.COLONIA_CLIENTE)) {
          this.toastr.error('LA COLUMNA COLONIA_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.CALLE_CLIENTE)) {
          this.toastr.error('LA COLUMNA CALLE_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.NUM_EXT_CLIENTE)) {
          this.toastr.error('LA COLUMNA NUM_EXT_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposSqlInjection(columna.NUM_INT_CLIENTE)) {
          this.toastr.error('LA COLUMNA NUM_INT_CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(excel.CODIGO_POSTAL_CLIENTE)) {
          // tslint:disable-next-line:max-line-length
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_CLIENTE)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(excel.ESTADO_CLIENTE)) {
          this.toastr.error('LA COLUMNA ESTADO_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(excel.MUNICIPIO_CLIENTE)) {
          this.toastr.error('LA COLUMNA MUNICIPIO_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(excel.COLONIA_CLIENTE)) {
          this.toastr.error('LA COLUMNA COLONIA_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(excel.CALLE_CLIENTE)) {
          this.toastr.error('LA COLUMNA CALLE_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(excel.NUM_EXT_CLIENTE)) {
          this.toastr.error('LA COLUMNA NUM_EXT_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCamposCommandInjection(excel.NUM_INT_CLIENTE)) {
          this.toastr.error('LA COLUMNA NUM_INT_CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }


        if (!this.validarCaracteresEspeciales(columna.CODIGO_POSTAL_CLIENTE)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarSoloNumeros(columna.CODIGO_POSTAL_CLIENTE)) {
          this.toastr.error('LA COLUMNA CODIGO_POSTAL_CLIENTE, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.ESTADO_CLIENTE)) {
          this.toastr.error('LA COLUMNA ESTADO_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.MUNICIPIO_CLIENTE)) {
          this.toastr.error('LA COLUMNA MUNICIPIO_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.COLONIA_CLIENTE)) {
          this.toastr.error('LA COLUMNA COLONIA_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.CALLE_CLIENTE)) {
          this.toastr.error('LA COLUMNA CALLE_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.NUM_EXT_CLIENTE)) {
          this.toastr.error('LA COLUMNA NUM_EXT_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.NUM_INT_CLIENTE)) {
          this.toastr.error('LA COLUMNA NUM_INT_CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }


      }

      const anexosDuplicados: any[] = [];
      for (const columna of excel) {
        if (!this.validarCamposSqlInjection(columna.ACTA_CONSTITUTIVA)) {
          this.toastr.error('LA COLUMNA ACTA_CONSTITUTIVA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.ACTA_CONSTITUTIVA !== undefined) {
          anexosDuplicados.push(columna.ACTA_CONSTITUTIVA);
        }

        if (!this.validarCamposSqlInjection(columna.PODER_NOTARIAL)) {
          this.toastr.error('LA COLUMNA PODER_NOTARIAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.PODER_NOTARIAL !== undefined) {
          anexosDuplicados.push(columna.PODER_NOTARIAL);
        }

        if (!this.validarCamposSqlInjection(columna.IDENTIFICACION_APODERADO)) {
          this.toastr.error('LA COLUMNA IDENTIFICACION_APODERADO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.IDENTIFICACION_APODERADO !== undefined) {
          anexosDuplicados.push(columna.IDENTIFICACION_APODERADO);
        }

        if (!this.validarCamposSqlInjection(columna.COMPROBANTE_DOMICILIO)) {
          this.toastr.error('LA COLUMNA COMPROBANTE_DOMICILIO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.COMPROBANTE_DOMICILIO !== undefined) {
          anexosDuplicados.push(columna.COMPROBANTE_DOMICILIO);
        }

        if (!this.validarCamposSqlInjection(columna.COMPROBANTE_DOMICILIO_FISCAL)) {
          this.toastr.error('LA COLUMNA COMPROBANTE_DOMICILIO_FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
          return false;
          break;
        }
        if (columna.COMPROBANTE_DOMICILIO_FISCAL !== undefined) {
          anexosDuplicados.push(columna.COMPROBANTE_DOMICILIO_FISCAL);
        }

        if (!this.validarCaracteresEspeciales(columna.ACTA_CONSTITUTIVA)) {
          this.toastr.error('LA COLUMNA ACTA_CONSTITUTIVA, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.PODER_NOTARIAL)) {
          this.toastr.error('LA COLUMNA PODER_NOTARIAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.IDENTIFICACION_APODERADO)) {
          this.toastr.error('LA COLUMNA IDENTIFICACION_APODERADO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.COMPROBANTE_DOMICILIO)) {
          this.toastr.error('LA COLUMNA COMPROBANTE_DOMICILIO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        if (!this.validarCaracteresEspeciales(columna.COMPROBANTE_DOMICILIO_FISCAL)) {
          this.toastr.error('LA COLUMNA COMPROBANTE_DOMICILIO_FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
          return false;
          break;
        }

        console.log('ANEXOS DUPLICADOS EN PLANTILLA DE CLIENTES MORALES: ', anexosDuplicados);
        if (this.encontrarDuplicados(anexosDuplicados)) {
          this.toastr.error('LOS ANEXOS NO DEBEN TENER NOMBRES DE ARCHIVOS DUPLICADOS', 'NOTIFICACIÓN');
          return false;
        }
      }
}
    return true;
  }

  validarCamposSqlInjection(texto: string): boolean {
    const patronSQL = /SELECT|INSERT|UPDATE|DELETE|DROP|UNION|EXEC/i;
    if (patronSQL.test(texto)) {
      return false;
    }
    return true;
  }

  encontrarDuplicados(arr: any[]): boolean {
    const duplicados = [];
    const valoresUnicos = new Set();

    for (const valor of arr) {
      if (valoresUnicos.has(valor)) {
        duplicados.push(valor);
      } else {
        valoresUnicos.add(valor);
      }
    }
    if (duplicados.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  validarRFC(rfc: string, tipo: string): boolean {
    let re: any;
    if (tipo === 'FISICA') {
      re = /^[A-Z&Ñ]{4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/;
    } else {
      re = /^[A-Z&Ñ]{3}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/;
    }
    // const re = /^[A-ZÑ&]{3,4}\d{6}[A-V1-9][0-9A-HJ-NP-TV-Z]{2}$/;
    return re.test(rfc);
  }

  validarNombreArchivoPDF(nombreArchivo: string): boolean {

    const re = /^[a-zA-Z0-9_]+\.(pdf|PDF)$/;
    return re.test(nombreArchivo);
  }

  cargaTemporalMoral(idCarga: any) {

    this.api.getMassiveClienteTempMoral(idCarga)
      .then(
        (response: any) => {
          console.log('CARGA INFO TEMPORAL: ', response);
          this.api.validateLoadMassiveClientMoral(response)
            .subscribe(
              datos => {
                let data: any;
                data = datos;
                for (let i = 0; i < data.length; i++) {
                  data[i].cargaId = idCarga;
                }
                console.log('datos finales de validacion con el ID DE CARGA: ', data);
                this.infoClienteMoral = data;
                setTimeout(() => {
                    this.spinner.hide('sp');
                    this.toastr.success('SU INFORMACIÓN HA SIDO CARGADA CORRECTAMENTE.', 'NOTIFICACIÓN');
                  },
                  1000);
              }, error => {
                this.spinner.hide('sp');
                this.toastr.warning(error.error);
              }
            );

        }, error => {
          console.error(error);
          this.spinner.hide('sp');
        }
      );

  }

  cargaTemporalUpdateMoral(idCarga: any) {

    this.api.getMassiveClienteTempMoral(idCarga)
      .then(
        (response: any) => {

          this.api.validateLoadMassiveClientMoral(response)
            .subscribe(
              datos => {
                this.infoClienteMoral = [];
                this.infoClienteMoral = datos;
                setTimeout(() => {
                    this.spinner.hide('sp');
                    console.log('RESULTADO DE LA VALIDACION DE INFORMACION MORAL: ', this.infoClienteMoral);
                    this.procesarInfo(this.infoClienteMoral);
                    if (!this.esValidoProcesar) {
                      this.toastr.success('SU INFORMACIÓN HA SIDO ACTUALIZADA CORRECTAMENTE.', 'NOTIFICACIÓN');
                    } else {
                      this.toastr.success('INFORMACIÓN VÁLIDA PARA PROCESAR.', 'NOTIFICACIÓN');
                    }
                  },
                  1000);
              }, error => {
                this.toastr.warning(error.error);
                this.spinner.hide('sp');
              }
            );

        }, error => {
          console.error(error);
          this.spinner.hide('sp');
        }
      );
  }

  changeStateClient(e: any) {
    let valor: any;
    if (this.esClienteFisico) {
      valor = this.stateListClient.find(a => a.cKey === e);
      this.editingClientFisico.idEstadoCliente = e;
      this.editingClientFisico.estadoCliente = valor.cValue;
    } else {
      valor = this.stateListClient.find(a => a.cKey === e);
      this.editingClientMoral.idEstadoCliente = e;
      this.editingClientMoral.estadoCliente = valor.cValue;
    }
    console.log(e);
    this.cargaMunicipiosClient(valor);
  }

  cargaMunicipiosClient(data: any) {
    this.api.getMunicipalyByStateName(data).then((result: any) => {
      if (result != null) {
        this.municipalityListClientesFisico = result;
      }

    }, error => {

    });
  }
  changeMunicipalyClient(e: any) {
    let valor: any;
    if (this.esClienteFisico) {
      valor = this.municipalityListClientesFisico.find(a => a.cKey === e);
      this.editingClientFisico.idMunicipioCliente = e;
      this.editingClientFisico.municipioCliente = valor.cValue;
    } else {
      valor = this.municipalityListClientesFisico.find(a => a.cKey === e);
      this.editingClientMoral.idMunicipioCliente = e;
      this.editingClientMoral.municipioCliente = valor.cValue;
    }
    console.log(e);
  }

  cargaColoniasClient(data: any) {
    this.api.getColoniesByZipCode(data).then((result: any) => {
      if (result != null) {
        this.neighborhoodListClientesFisico = result;
      }
    }, error => {
      /*console.error(error.status);
  */
    });
  }

  changeColonyClient(e: any) {
    let valor: any;
    if (this.esClienteFisico) {
      valor = this.neighborhoodListClientesFisico.find(a => a.cKey === e);
      this.editingClientFisico.idColoniaCliente = e;
      this.editingClientFisico.coloniaCliente = valor.cValue;
    } else {
      valor = this.neighborhoodListClientesFisico.find(a => a.cKey === e);
      this.editingClientMoral.idColoniaCliente = e;
      this.editingClientMoral.coloniaCliente = valor.cValue;
    }
    console.log(e);
  }

  cargaColoniasonFocusClient() {
    let dato: any;
    if (this.esClienteFisico) {
       dato = {
        cKey: this.editingClientFisico.cpCliente,
        cValue: this.editingClientFisico.cpCliente

      };
       this.editingClientFisico.idColoniaCliente = null;
    } else {
       dato = {
        cKey: this.editingClientMoral.cpCliente,
        cValue: this.editingClientMoral.cpCliente
      };
       this.editingClientMoral.idColoniaCliente = null;
    }
    this.neighborhoodListClientesFisico = [];
    this.api.getColoniesByZipCode(dato).then((result: any) => {
      if (result != null) {
        this.neighborhoodListClientesFisico = result;
      } else {
        this.toastr.warning('CODIGO POSTAL INEXISTENTE', 'NOTIFICACIÓN');
        return false;
      }
    }, error => {
      /*console.error(error.status);
  */
    });
  }

  editClientM(template: TemplateRef<any>, clientMorales: ClienteMoral) {
    const clienteMorales = { ...clientMorales };
    this.neighborhoodListClientes = [];
    this.neighborhoodListClientesFisico = [];
    const dato = {
      cKey: clienteMorales.cpFiscal,
      cValue: clienteMorales.cpFiscal
    };
    const datoCliente = {
      cKey: clienteMorales.cpCliente,
      cValue: clienteMorales.cpCliente
    };
    // tslint:disable-next-line:no-unused-expression
    this.cargaColonias(dato);
    this.cargaColoniasClient(datoCliente);

    setTimeout(() => {
        this.editingClientMoral = { ...clientMorales };
        this.editingClientMoral.paisFiscal = 'MÉXICO';
        this.editingClientMoral.paisCliente = 'MÉXICO';
        this.editingClientMoral.idPaisFiscal =  1;
        this.editingClientMoral.idPaisCliente = 1;
        this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
      },
      1000);
  }
  saveClientM() {
    if (this.editingClientMoral && this.validaExcelEdicion(this.editingClientMoral)) {
      const index = this.infoClienteMoral.findIndex((moral) => moral.detalleId === this.editingClientMoral.detalleId);
      this.infoClienteMoral[index] = { ...this.editingClientMoral };
      this.editingClientMoral = null;
      this.neighborhoodListClientes = [];
      this.neighborhoodListClientesFisico = [];
      this.modalRef.hide();
    }

  }

  changeTabCargaMasiva(e: any) {
    this.esCargaMasiva = e;
  }
  getTipoSociedad() {
    const data = {name: 'TYPE_SOCIETY'};
    this.api.getCatalogByBusiness(data).then((result: any) => {
      if (result != null) {
        const vacio = {
          id: 0,
          description: 'ELIJA UN VALOR VALIDO',
        };
        result.push(vacio);
        this.typeSocietiesListCM = result;
      }
    }, error => {

    });
  }

  changeTipoSociedad(e: any) {
    const valor = this.typeSocietiesListCM.find(a => a.id === e);
    if (!this.esClienteFisico) {
      this.editingClientMoral.idTipoSociedad = e;
      this.editingClientMoral.tipoSociedad = valor.description;
    } else {
      this.editingClientFisico.idGrupo = e;
      this.editingClientFisico.grupo = valor.description;
    }
    console.log(e);
  }

  changeTipoContacto(e: any) {
    const valor = this.typeContactForClientsCM.find(a => a.id === e);
    this.editingClientMoral.idTipoContacto = e;
    this.editingClientMoral.tipoContacto = valor.description;
    console.log(e);
  }

  getTipoContacto() {
    this.api.getTypeContactForClients().then((result: any) => {
      if (result != null) {
        const vacio = {
          id: 0,
          description: 'ELIJA UN VALOR VALIDO',
        };
        result.push(vacio);

        this.typeContactForClientsCM = result;
      }
    }, error => {

    });
  }

  validateExcelStructured(sheetName: any, worksheet: any, jsonData: any ): boolean {
    if (this.esClienteFisico) {
      const expectedColumns = {
        NOMBRE: 'string',
        APELLIDO_PATERNO: 'string',
        APELLIDO_MATERNO: 'string',
        RFC_CLIENTE: 'string',
        GRUPO: 'string',
        TELEFONO: 'string',
        CORREO: 'string',
        PAIS_FISCAL: 'string',
        CODIGO_POSTAL_FISCAL: 'string',
        ESTADO_FISCAL: 'string',
        MUNICIPIO_FISCAL: 'string',
        COLONIA_FISCAL: 'string',
        CALLE_FISCAL: 'string',
        NUM_EXT_FISCAL: 'string',
        NUM_INT_FISCAL: 'string',
        PAIS_CLIENTE: 'string',
        CODIGO_POSTAL_CLIENTE: 'string',
        ESTADO_CLIENTE: 'string',
        MUNICIPIO_CLIENTE: 'string',
        COLONIA_CLIENTE: 'string',
        CALLE_CLIENTE: 'string',
        NUM_EXT_CLIENTE: 'string',
        NUM_INT_CLIENTE: 'string',
        ACTA_NACIMIENTO: 'string',
        COMPROBANTE_DOMICILIO_FISCAL: 'string',
        IDENTIFICACION_OFICIAL: 'string',
        RFC_DOCUMENTO: 'string',
        ACUSE_PRESCRIPCION_RFC: 'string'
      };
      // Validar la estructura de la tabla
      if (jsonData[0].NUM_INT_CLIENTE === undefined) {
        jsonData[0].NUM_INT_CLIENTE = '';
      }

      if (jsonData[0].NUM_INT_FISCAL === undefined) {
        jsonData[0].NUM_INT_FISCAL = '';
      }

      if (jsonData[0].ACTA_NACIMIENTO === undefined) {
        jsonData[0].ACTA_NACIMIENTO = '';
      }

      if (jsonData[0].COMPROBANTE_DOMICILIO_FISCAL === undefined) {
        jsonData[0].COMPROBANTE_DOMICILIO_FISCAL = '';
      }

      if (jsonData[0].IDENTIFICACION_OFICIAL === undefined) {
        jsonData[0].IDENTIFICACION_OFICIAL = '';
      }

      if (jsonData[0].RFC_DOCUMENTO === undefined) {
        jsonData[0].RFC_DOCUMENTO = '';
      }

      if (jsonData[0].ACUSE_PRESCRIPCION_RFC === undefined) {
        jsonData[0].ACUSE_PRESCRIPCION_RFC = '';
      }

      const columns = Object.keys(jsonData[0]);


      if (columns.length !== Object.keys(expectedColumns).length) {
        console.log('El número de columnas en la tabla es incorrecto.');
        return false;
      }

      for (const column of columns) {
        if (!expectedColumns.hasOwnProperty(column)) {
          console.log(`El nombre de la columna "${column}" o su tipo son incorrectos.`);
          return false;
        }
      }

    } else {
      const expectedColumns = {
        NOMBRE_GRUPO: 'string',
        RFC_SUBGRUPO: 'string',
        TIPO_SOCIEDAD: 'string',
        RAZON_SOCIAL: 'string',
        NOMBRE_CONTACTO: 'string',
        TELEFONO_CONTACTO: 'string',
        EMAIL_CONTACTO: 'string',
        TIPO_CONTACTO: 'string',
        PAIS_FISCAL: 'string',
        CODIGO_POSTAL_FISCAL: 'string',
        ESTADO_FISCAL: 'string',
        MUNICIPIO_FISCAL: 'string',
        COLONIA_FISCAL: 'string',
        CALLE_FISCAL: 'string',
        NUM_EXT_FISCAL: 'string',
        NUM_INT_FISCAL: 'string',
        PAIS_CLIENTE: 'string',
        CODIGO_POSTAL_CLIENTE: 'string',
        ESTADO_CLIENTE: 'string',
        MUNICIPIO_CLIENTE: 'string',
        COLONIA_CLIENTE: 'string',
        CALLE_CLIENTE: 'string',
        NUM_EXT_CLIENTE: 'string',
        NUM_INT_CLIENTE: 'string',
        ACTA_CONSTITUTIVA: 'string',
        PODER_NOTARIAL: 'string',
        IDENTIFICACION_APODERADO: 'string',
        COMPROBANTE_DOMICILIO: 'string',
        COMPROBANTE_DOMICILIO_FISCAL: 'string'
      };
      // Validar la estructura de la tabla
      if (jsonData[0].NUM_INT_CLIENTE === undefined) {
        jsonData[0].NUM_INT_CLIENTE = '';
      }

      if (jsonData[0].NUM_INT_FISCAL === undefined) {
        jsonData[0].NUM_INT_FISCAL = '';
      }

      if (jsonData[0].ACTA_CONSTITUTIVA === undefined) {
        jsonData[0].ACTA_CONSTITUTIVA = '';
      }

      if (jsonData[0].PODER_NOTARIAL === undefined) {
        jsonData[0].PODER_NOTARIAL = '';
      }

      if (jsonData[0].IDENTIFICACION_APODERADO === undefined) {
        jsonData[0].IDENTIFICACION_APODERADO = '';
      }

      if (jsonData[0].COMPROBANTE_DOMICILIO === undefined) {
        jsonData[0].COMPROBANTE_DOMICILIO = '';
      }

      if (jsonData[0].COMPROBANTE_DOMICILIO_FISCAL === undefined) {
        jsonData[0].COMPROBANTE_DOMICILIO_FISCAL = '';
      }

      const columns = Object.keys(jsonData[0]);
      if (columns.length !== Object.keys(expectedColumns).length) {
        console.log('El número de columnas en la tabla es incorrecto.');
        return false;
      }

      for (const column of columns) {
        if (!expectedColumns.hasOwnProperty(column)) {
          console.log(`El nombre de la columna "${column}" o su tipo son incorrectos.`);
          return false;
        }
      }

    }
    console.log('La estructura de la tabla es correcta.');
    return true;
  }

  validarCamposCommandInjection(texto: string): boolean {
    console.log('ENTRO A VALIDAR COMANDOS INJECTION', texto);
    if (texto !== 'CASH FLOW') {
      const patronSQL = /.BIN|.RUN|.SH|TOUCH|LS|CHMOD|SH|MV/i;
      if (patronSQL.test(texto)) {
        return false;
      }
    }
    return true;
  }

  validaExcelEdicion(excel: any) {


    if (this.esClienteFisico) {
      // VALIDACION CLIENTE FISICO

      if (excel.nombre === '' || excel.nombre === undefined) {
        this.toastr.error('EL CAMPO NOMBRE, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposSqlInjection(excel.nombre)) {
        this.toastr.error('EL CAMPO NOMBRE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.nombre)) {
        this.toastr.error('EL CAMPO NOMBRE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCaracteresEspeciales(excel.nombre)) {
        this.toastr.error('EL CAMPO NOMBRE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (excel.apellidoPaterno === '' || excel.apellidoPaterno === undefined) {
        this.toastr.error('EL CAMPO APELLIDO PATERNO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposSqlInjection(excel.apellidoPaterno)) {
        this.toastr.error('EL CAMPO APELLIDO PATERNO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.apellidoPaterno)) {
        this.toastr.error('EL CAMPO APELLIDO PATERNO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCaracteresEspeciales(excel.apellidoPaterno)) {
        this.toastr.error('EL CAMPO APELLIDO PATERNO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCamposSqlInjection(excel.apellidoMaterno)) {
        this.toastr.error('EL CAMPO APELLIDO MATERNO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }


      if (!this.validarCamposCommandInjection(excel.apellidoMaterno)) {
        this.toastr.error('EL CAMPO APELLIDO MATERNO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCaracteresEspeciales(excel.apellidoMaterno)) {
        this.toastr.error('EL CAMPO APELLIDO MATERNO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }
      // const rfcCliente: any[] = [];


      if (excel.rfc === '' || excel.rfc === undefined) {
        this.toastr.error('EL CAMPO RFC, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposSqlInjection(excel.rfc)) {
        this.toastr.error('EL CAMPO RFC, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarRFC(excel.rfc, this.esClienteFisico ? 'FISICA' : 'MORAL')) {
        this.toastr.error('EL RFC DEL CLIENTE, NO TIENE EL FORMATO CORRECTO, VERIFIQUELO', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.rfc)) {
        this.toastr.error('EL CAMPO RFC, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.idGrupo === 0) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA EL GRUPO', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.telefono === '' || excel.telefono === undefined) {
        this.toastr.error('EL CAMPO TELEFONO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.email === '' || excel.email === undefined) {
        this.toastr.error('EL CAMPO EMAIL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.telefono)) {
        this.toastr.error('EL CAMPO TELEFONO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.telefono)) {
        this.toastr.error('EL CAMPO TELEFONO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarSoloNumeros(excel.telefono)) {
        this.toastr.error('LA COLUMNA TELÉFONO, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCamposSqlInjection(excel.email)) {
        this.toastr.error('EL CAMPO EMAIL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.email)) {
        this.toastr.error('EL CAMPO EMAIL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCaracteresEspeciales(excel.telefono)) {
        this.toastr.error('EL CAMPO TELEFONO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (excel.paisFiscal === '' || excel.paisFiscal === undefined) {
        this.toastr.error('EL CAMPO PAIS FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.cpFiscal === '' || excel.cpFiscal === undefined) {
        this.toastr.error('EL CAMPO CODIGO POSTAL FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }
      if (excel.estadoFiscal === '' || excel.estadoFiscal === undefined) {
        this.toastr.error('EL CAMPO ESTADO FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.idEstadoFiscal === null) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA EL ESTADO FISCAL', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.municipioFiscal === '' || excel.municipioFiscal === undefined) {
        this.toastr.error('EL CAMPO MUNICIPIO FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.idMunicipioFiscal === null) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA EL MUNICIPIO FISCAL', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.coloniaFiscal === '' || excel.coloniaFiscal === undefined) {
        this.toastr.error('EL CAMPO COLONIA FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.idColoniaFiscal === null) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA LA COLONIA FISCAL', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.calleFiscal === '' || excel.calleFiscal === undefined) {
        this.toastr.error('EL CAMPO CALLE FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.numExtFiscal === '' || excel.numExtFiscal === undefined) {
        this.toastr.error('EL CAMPO NUMERO EXTERIOR FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }


      if (!this.validarCamposSqlInjection(excel.paisFiscal)) {
        this.toastr.error('EL CAMPO PAIS FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.cpFiscal)) {
        this.toastr.error('EL CAMPO CODIGO POSTAL FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.estadoFiscal)) {
        this.toastr.error('EL CAMPO ESTADO FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.municipioFiscal)) {
        this.toastr.error('EL CAMPO MUNICIPIO FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.coloniaFiscal)) {
        this.toastr.error('EL CAMPO COLONIA FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.calleFiscal)) {
        this.toastr.error('EL CAMPO CALLE FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.numExtFiscal)) {
        this.toastr.error('EL CAMPO NUMERO EXTERIOR FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.numIntFiscal)) {
        this.toastr.error('EL CAMPO NUMERO INTERIOR FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.numIntFiscal)) {
        this.toastr.error('EL CAMPO NUMERO INTERIOR FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCaracteresEspeciales(excel.calleFiscal)) {
        this.toastr.error('EL CAMPO CALLE FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCaracteresEspeciales(excel.numExtFiscal)) {
        this.toastr.error('EL CAMPO NUMERO EXTERIOR FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCaracteresEspeciales(excel.numIntFiscal)) {
        this.toastr.error('EL CAMPO NUMERO INTERIOR FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (excel.paisCliente === '' || excel.paisCliente === undefined) {
        this.toastr.error('EL CAMPO PAIS CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.cpCliente === '' || excel.cpCliente === undefined) {
        this.toastr.error('EL CAMPO CODIGO POSTAL CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }
      if (excel.estadoCliente === '' || excel.estadoCliente === undefined) {
        this.toastr.error('EL CAMPO ESTADO CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.idEstadoCliente === null) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA EL ESTADO DEL CLIENTE', 'NOTIFICACIÓN');
        return false;
      }


      if (excel.municipioCliente === '' || excel.municipioCliente === undefined) {
        this.toastr.error('EL CAMPO MUNICIPIO CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.idMunicipioCliente === null) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA EL MUNICIPIO DEL CLIENTE', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.coloniaCliente === '' || excel.coloniaCliente === undefined) {
        this.toastr.error('EL CAMPO COLONIA CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }


      if (excel.idColoniaCliente === null) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA LA COLONIA DEL CLIENTE', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.calleCliente === '' || excel.calleCliente === undefined) {
        this.toastr.error('EL CAMPO CALLE CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.numExtCliente === '' || excel.numExtCliente === undefined) {
        this.toastr.error('EL CAMPO NUMERO EXTERIOR CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.paisCliente)) {
        this.toastr.error('EL CAMPO PAIS CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.cpCliente)) {
        this.toastr.error('EL CAMPO CODIGO POSTAL CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.estadoCliente)) {
        this.toastr.error('EL CAMPO ESTADO CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.municipioCliente)) {
        this.toastr.error('EL CAMPO MUNICIPIO CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.coloniaCliente)) {
        this.toastr.error('EL CAMPO COLONIA CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.calleCliente)) {
        this.toastr.error('EL CAMPO CALLE CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.numExtCliente)) {
        this.toastr.error('EL CAMPO NUMERO EXTERIOR CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.numIntCliente)) {
        this.toastr.error('EL CAMPO NUMERO INTERIOR CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.numIntCliente)) {
        this.toastr.error('EL CAMPO NUMERO INTERIOR CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCaracteresEspeciales(excel.calleCliente)) {
        this.toastr.error('EL CAMPO CALLE CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCaracteresEspeciales(excel.numExtCliente)) {
        this.toastr.error('EL CAMPO NUMERO EXTERIOR CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCaracteresEspeciales(excel.numIntCliente)) {
        this.toastr.error('EL CAMPO NUMERO INTERIOR CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCamposSqlInjection(excel.actaNacimiento)) {
        this.toastr.error('EL CAMPO ACTA DE NACIMIENTO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.comprobanteDomFiscal)) {
        this.toastr.error('EL CAMPO COMPROBANTE DOMICLIO FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.identificacionOficial)) {
        this.toastr.error('EL CAMPO IDENTIFICACION OFICIAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.rfcDocumento)) {
        this.toastr.error('EL CAMPO RFC DOCUMENTO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.acusePrescripcion)) {
        this.toastr.error('EL CAMPO ACUSE PRESCRIPCION, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.actaNacimiento)) {
        this.toastr.error('EL CAMPO ACTA DE NACIMIENTO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
      }
      if (!this.validarCamposCommandInjection(excel.comprobanteDomFiscal)) {
        this.toastr.error('EL CAMPO COMPROBANTE DOMICLIO FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCamposCommandInjection(excel.identificacionOficial)) {
        this.toastr.error('EL CAMPO IDENTIFICACION OFICIAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCamposCommandInjection(excel.rfcDocumento)) {
        this.toastr.error('EL CAMPO RFC DOCUMENTO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCamposCommandInjection(excel.acusePrescripcion)) {
        this.toastr.error('EL CAMPO ACUSE PRESCRIPCION, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCaracteresEspeciales(excel.actaNacimiento)) {
        this.toastr.error('EL CAMPO ACTA DE NACIMIENTO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }
      if (!this.validarCaracteresEspeciales(excel.comprobanteDomFiscal)) {
        this.toastr.error('EL CAMPO COMPROBANTE DOMICLIO FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }
      if (!this.validarCaracteresEspeciales(excel.identificacionOficial)) {
        this.toastr.error('EL CAMPO IDENTIFICACION OFICIAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }
      if (!this.validarCaracteresEspeciales(excel.rfcDocumento)) {
        this.toastr.error('EL CAMPO RFC DOCUMENTO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }
      if (!this.validarCaracteresEspeciales(excel.acusePrescripcion)) {
        this.toastr.error('EL CAMPO ACUSE PRESCRIPCION, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }


    } else {
      // VALIDACION CLIENTE MORAL

      if (excel.grupo === '' || excel.grupo === undefined) {
        this.toastr.error('EL CAMPO GRUPO, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposSqlInjection(excel.grupo)) {
        this.toastr.error('EL CAMPO GRUPO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.grupo)) {
        this.toastr.error('EL CAMPO GRUPO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCaracteresEspeciales(excel.grupo)) {
        this.toastr.error('EL CAMPO GRUPO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (excel.idTipoSociedad === 0) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA EL TIPO DE SOCIEDAD', 'NOTIFICACIÓN');
        return false;

      }


      if (excel.razonSocial === '' || excel.razonSocial === undefined) {
        this.toastr.error('EL CAMPO RAZON SOCIAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposSqlInjection(excel.razonSocial)) {
        this.toastr.error('EL CAMPO RAZON SOCIAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.razonSocial)) {
        this.toastr.error('EL CAMPO RAZON SOCIAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCaracteresEspeciales(excel.razonSocial)) {
        this.toastr.error('EL CAMPO RAZON SOCIAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (excel.nombreContacto === '' || excel.nombreContacto === undefined) {
        this.toastr.error('EL CAMPO NOMBRE CONTACTO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }


      if (excel.idTipoContacto === 0) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA EL TIPO DE CONTACTO', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.nombreContacto)) {
        this.toastr.error('EL CAMPO NOMBRE CONTACTO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }


      if (!this.validarCamposCommandInjection(excel.nombreContacto)) {
        this.toastr.error('EL CAMPO NOMBRE CONTACTO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCaracteresEspeciales(excel.nombreContacto)) {
        this.toastr.error('EL CAMPO NOMBRE CONTACTO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (excel.rfcSubgrupo === '' || excel.rfcSubgrupo === undefined) {
        this.toastr.error('EL CAMPO RFC SUBGRUPO, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposSqlInjection(excel.rfcSubgrupo)) {
        this.toastr.error('EL CAMPO RFC SUBGRUPO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.rfcSubgrupo)) {
        this.toastr.error('EL CAMPO RFC SUBGRUPO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }


      if (!this.validarRFC(excel.rfcSubgrupo, this.esClienteFisico ? 'FISICA' : 'MORAL')) {
        this.toastr.error('EL RFC SUBGRUPO, NO TIENE EL FORMATO CORRECTO, VERIFIQUELO', 'NOTIFICACIÓN');
        return false;

      }



      if (excel.telefono === '' || excel.telefono === undefined) {
        this.toastr.error('EL CAMPO TELEFONO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }


      if (excel.email === '' || excel.email === undefined) {
        this.toastr.error('EL CAMPO EMAIL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      /*
              if (excel.TIPO_CONTACTO === '' || excel.TIPO_CONTACTO === undefined) {
                this.toastr.error('LA COLUMNA TIPO_CONTACTO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
                return false;

              }
      */
      if (!this.validarCamposSqlInjection(excel.telefono)) {
        this.toastr.error('EL CAMPO TELEFONO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.telefono)) {
        this.toastr.error('EL CAMPO TELEFONO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.email)) {
        this.toastr.error('EL CAMPO EMAIL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }


      if (!this.validarCamposCommandInjection(excel.email)) {
        this.toastr.error('EL CAMPO EMAIL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCaracteresEspeciales(excel.telefono)) {
        this.toastr.error('EL CAMPO TELEFONO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarSoloNumeros(excel.telefono)) {
        this.toastr.error('EL CAMPO TELEFONO, DEBE SER UN VALOR NÚMERICO', 'NOTIFICACIÓN');
        return false;
      }


      if (excel.paisFiscal === '' || excel.paisFiscal === undefined) {
        this.toastr.error('EL CAMPO PAIS FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.cpFiscal === '' || excel.cpFiscal === undefined) {
        this.toastr.error('EL CAMPO CODIGO POSTAL FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }
      if (excel.estadoFiscal === '' || excel.estadoFiscal === undefined) {
        this.toastr.error('EL CAMPO ESTADO FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.idEstadoFiscal === null) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA EL ESTADO FISCAL', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.municipioFiscal === '' || excel.municipioFiscal === undefined) {
        this.toastr.error('EL CAMPO MUNICIPIO FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.idMunicipioFiscal === null) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA EL MUNICIPIO FISCAL', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.coloniaFiscal === '' || excel.coloniaFiscal === undefined) {
        this.toastr.error('EL CAMPO COLONIA FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.idColoniaFiscal === null) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA LA COLONIA FISCAL', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.calleFiscal === '' || excel.calleFiscal === undefined) {
        this.toastr.error('EL CAMPO CALLE FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.numExtFiscal === '' || excel.numExtFiscal === undefined) {
        this.toastr.error('EL CAMPO NUMERO EXTERIOR FISCAL, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.paisFiscal)) {
        this.toastr.error('EL CAMPO PAIS FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.cpFiscal)) {
        this.toastr.error('EL CAMPO CODIGO POSTAL FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.estadoFiscal)) {
        this.toastr.error('EL CAMPO ESTADO FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.municipioFiscal)) {
        this.toastr.error('EL CAMPO MUNICIPIO FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.coloniaFiscal)) {
        this.toastr.error('EL CAMPO COLONIA FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.calleFiscal)) {
        this.toastr.error('EL CAMPO CALLE FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.numExtFiscal)) {
        this.toastr.error('EL CAMPO NUMERO EXTERIOR FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.numIntFiscal)) {
        this.toastr.error('EL CAMPO NUMERO INTERIOR FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.numIntFiscal)) {
        this.toastr.error('EL CAMPO NUMERO INTERIOR FISCAL, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCaracteresEspeciales(excel.calleFiscal)) {
        this.toastr.error('EL CAMPO CALLE FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCaracteresEspeciales(excel.numExtFiscal)) {
        this.toastr.error('EL CAMPO NUMERO EXTERIOR FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCaracteresEspeciales(excel.numIntFiscal)) {
        this.toastr.error('EL CAMPO NUMERO INTERIOR FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (excel.paisCliente === '' || excel.paisCliente === undefined) {
        this.toastr.error('EL CAMPO PAIS CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.cpCliente === '' || excel.cpCliente === undefined) {
        this.toastr.error('EL CAMPO CODIGO POSTAL CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }
      if (excel.estadoCliente === '' || excel.estadoCliente === undefined) {
        this.toastr.error('EL CAMPO ESTADO CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.idEstadoCliente === null) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA EL ESTADO DEL CLIENTE', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.municipioCliente === '' || excel.municipioCliente === undefined) {
        this.toastr.error('EL CAMPO MUNICIPIO CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.idMunicipioCliente === null) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA EL MUNICIPIO DEL CLIENTE', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.coloniaCliente === '' || excel.coloniaCliente === undefined) {
        this.toastr.error('EL CAMPO COLONIA CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.idColoniaCliente === null) {
        this.toastr.error('SELECCIONE UN VALOR VALIDO PARA LA COLONIA DEL CLIENTE', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.calleCliente === '' || excel.calleCliente === undefined) {
        this.toastr.error('EL CAMPO CALLE CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (excel.numExtCliente === '' || excel.numExtCliente === undefined) {
        this.toastr.error('EL CAMPO NUMERO EXTERIOR CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.paisCliente)) {
        this.toastr.error('EL CAMPO PAIS CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.cpCliente)) {
        this.toastr.error('EL CAMPO CODIGO POSTAL CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.estadoCliente)) {
        this.toastr.error('EL CAMPO ESTADO CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.municipioCliente)) {
        this.toastr.error('EL CAMPO MUNICIPIO CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.coloniaCliente)) {
        this.toastr.error('EL CAMPO COLONIA CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.calleCliente)) {
        this.toastr.error('EL CAMPO CALLE CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.numExtCliente)) {
        this.toastr.error('EL CAMPO NUMERO EXTERIOR CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposSqlInjection(excel.numIntFiscal)) {
        this.toastr.error('EL CAMPO NUMERO INTERIOR CLIENTE, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.numIntFiscal)) {
        this.toastr.error('EL CAMPO NUMERO INTERIOR CLIENTE, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCaracteresEspeciales(excel.calleCliente)) {
        this.toastr.error('EL CAMPO CALLE CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCaracteresEspeciales(excel.numExtCliente)) {
        this.toastr.error('EL CAMPO NUMERO EXTERIOR CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCaracteresEspeciales(excel.numIntCliente)) {
        this.toastr.error('EL CAMPO NUMERO INTERIOR CLIENTE, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCamposSqlInjection(excel.actaConstitutiva)) {
        this.toastr.error('EL CAMPO ACTA CONSTITUTIVA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposSqlInjection(excel.poderNotarial)) {
        this.toastr.error('EL CAMPO PODER NOTARIAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposSqlInjection(excel.identificacionApoderado)) {
        this.toastr.error('EL CAMPO IDENTIFICACION APODERADO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposSqlInjection(excel.comprobanteDomicilio)) {
        this.toastr.error('EL CAMPO COMPROBANTE DOMICILIO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposSqlInjection(excel.comprobanteDomFiscal)) {
        this.toastr.error('EL CAMPO COMPROBANTE DOMICILIO FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCamposCommandInjection(excel.actaConstitutiva)) {
        this.toastr.error('EL CAMPO ACTA CONSTITUTIVA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposCommandInjection(excel.poderNotarial)) {
        this.toastr.error('EL CAMPO PODER NOTARIAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposCommandInjection(excel.identificacionApoderado)) {
        this.toastr.error('EL CAMPO IDENTIFICACION APODERADO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposCommandInjection(excel.comprobanteDomicilio)) {
        this.toastr.error('EL CAMPO COMPROBANTE DOMICILIO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }
      if (!this.validarCamposCommandInjection(excel.comprobanteDomFiscal)) {
        this.toastr.error('EL CAMPO COMPROBANTE DOMICILIO FISCAL, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;

      }

      if (!this.validarCaracteresEspeciales(excel.actaConstitutiva)) {
        this.toastr.error('EL CAMPO ACTA CONSTITUTIVA, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCaracteresEspeciales(excel.poderNotarial)) {
        this.toastr.error('EL CAMPO PODER NOTARIAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCaracteresEspeciales(excel.identificacionApoderado)) {
        this.toastr.error('EL CAMPO IDENTIFICACION APODERADO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCaracteresEspeciales(excel.comprobanteDomicilio)) {
        this.toastr.error('EL CAMPO COMPROBANTE DOMICILIO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

      if (!this.validarCaracteresEspeciales(excel.comprobanteDomFiscal)) {
        this.toastr.error('EL CAMPO COMPROBANTE DOMICILIO FISCAL, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
      }

    }

    return true;
  }


  onChangeInputFileZip(e: any): void {
    this.spinner.show('sp');
    this.errorZip = {active: false, msg: ''};
    this.fileUploadZip = e.target.files[0];
    if (0 > this.fileTypesZip.indexOf(this.fileUploadZip.type)) {
      this.errorZip = {active: true, msg: 'Debe seleccionar un archivo válido'};
      this.spinner.hide('sp');
    } else {
      console.log('INFOCLIENTE: ', this.infoCliente);
      if (this.infoCliente.length === 0) {
        this.archivosZip = null;
        const file = e.target.files[0];
        e.target.value = '';
        if (this.esClienteFisico) {
          this.toastr.info('CARGUE PRIMERO EL LAYOUT DE LOS CLIENTES FÍSICOS', 'NOTIFICACIÓN');
        } else {
          this.toastr.info('CARGUE PRIMERO EL LAYOUT DE LOS CLIENTES MORALES', 'NOTIFICACIÓN');
        }
        this.spinner.hide('sp');
      } else {
        this.fileUpZip(e);
      }
    }
  }


  fileUpZip(event: any) {
    const selectedFile = event.target.files[0];
    const filePath = event.target.value;
    this.readZip(selectedFile, event);

  }

  readZip(e: any, event: any) {
    this.archivosZip = [];
    JSZip.loadAsync(e)
      .then(zip => {
          zip.forEach((relativePath, file) => {
            console.log('nombre del archivo: ', relativePath);
            this.archivosZip.push(relativePath);
          });
          for (const numero of this.archivosZip) {
            console.log('ARREGLO ZIP: ', numero);
          }
          this.spinner.hide('sp');
          console.log('tamaño del arreglo zip: ', this.archivosZip.length);
          console.log('tamaño del arreglo xls: ', this.anexosZips.length);
          this.validaZip(event);
        }, error => {
          this.spinner.hide('sp');
          this.toastr.warning(error.error);

        }
      );
  }



  anexosFilesZip(e: any) {
    this.anexosZips = [];
    if (this.esClienteFisico) {
      // ES CLIENTE FISICO
      for (const numero of e) {
        if (numero.actaNacimiento !== '' && numero.actaNacimiento !== undefined) {
          this.anexosZips.push(numero.actaNacimiento);
        }
      }

      for (const numero of e) {
        if (numero.comprobanteDomFiscal !== '' && numero.comprobanteDomFiscal !== undefined) {
          this.anexosZips.push(numero.comprobanteDomFiscal);
        }
      }

      for (const numero of e) {
        if (numero.identificacionOficial !== '' && numero.identificacionOficial !== undefined) {
          this.anexosZips.push(numero.identificacionOficial);
        }
      }


      for (const numero of e) {
        if (numero.rfcDocumento !== '' && numero.rfcDocumento !== undefined) {
          this.anexosZips.push(numero.rfcDocumento);
        }
      }

      for (const numero of e) {
        if (numero.acusePrescripcion !== '' && numero.acusePrescripcion !== undefined) {
          this.anexosZips.push(numero.acusePrescripcion);
        }
      }
      for (const numero of this.anexosZips) {
        console.log('ANEXOS PERSONA FISICA: ', numero);
      }
    } else {
      // ES CLIENTE MORAL
      for (const numero of e) {
        if (numero.actaConstitutiva !== '' && numero.actaConstitutiva !== undefined) {
          this.anexosZips.push(numero.actaConstitutiva);
        }
      }

      for (const numero of e) {
        if (numero.poderNotarial !== '' && numero.poderNotarial !== undefined) {
          this.anexosZips.push(numero.poderNotarial);
        }
      }

      for (const numero of e) {
        if (numero.identificacionApoderado !== '' && numero.identificacionApoderado !== undefined) {
          this.anexosZips.push(numero.identificacionApoderado);
        }
      }

      for (const numero of e) {
        if (numero.comprobanteDomicilio !== '' && numero.comprobanteDomicilio !== undefined) {
          this.anexosZips.push(numero.comprobanteDomicilio);
        }
      }

      for (const numero of e) {
        if (numero.comprobanteDomFiscal !== '' && numero.comprobanteDomFiscal !== undefined) {
          this.anexosZips.push(numero.comprobanteDomFiscal);
        }
      }
      for (const numero of this.anexosZips) {
        console.log('ANEXOS PERSONA MORAL: ', numero);
      }

      /*
      e.forEach((item) => {
        console.log(item);
        console.log(item.identificacionApoderado);
      })

      e.map((item) => {
        console.log(item);
        console.log(item.comprobanteDomicilio);

      });
       */

    }
  }

  validaZip(e: any) {

    for (const fzip of this.archivosZip) {
      if (!this.validarCamposCommandInjection(fzip.toUpperCase())) {
        this.toastr.error('EL ARCHIVO .ZIP, CONTIENE ARCHIVOS QUE PUEDEN AFECTAR LA INTEGRIDAD DE LA BASE DE DATOS, VERIFIQUE SUS ARCHIVOS', 'NOTIFICACIÓN');
        this.archivosZip = null;
        const file = e.target.files[0];
        e.target.value = '';
        return;
      }
    }

    if (this.anexosZips.length !== this.archivosZip.length) {
      // tslint:disable-next-line:max-line-length
      this.toastr.error('EL NUMERO DE ARCHIVOS CONTENIDOS EN EL .ZIP, NO CORRESPONDEN AL NUMERO CONTENIDOS EN LA PLANTILLA, VERIFIQUE SUS ARCHIVOS', 'NOTIFICACIÓN');
      this.archivosZip = null;
      const file = e.target.files[0];
      e.target.value = '';
      return;
    }

    let contador = 0;
    for (const fxlsx of this.anexosZips) {
      console.log(fxlsx.toUpperCase());
      for (const fzip of this.archivosZip) {
        if (fxlsx.toUpperCase() === fzip.toUpperCase() ) {
          contador++;
        }
      }
    }

    if (this.anexosZips.length !== contador) {
      // tslint:disable-next-line:max-line-length
      this.toastr.error('LOS ARCHIVOS CONTENIDOS EN EL .ZIP, NO CORRESPONDEN A LOS DESCRITOS EN LA PLANTILLA, VERIFIQUE SUS ARCHIVOS', 'NOTIFICACIÓN');
      this.archivosZip = null;
      const file = e.target.files[0];
      e.target.value = '';
      return;
    }

    this.toastr.success('EL ARCHIVO .ZIP SE CARGO CORRECTAMENTE', 'NOTIFICACIÓN');
    const file = e.target.files[0];
    e.target.value = '';
  }

  downloadLayoutClient(id) {
    this.spinner.show('sp');
    this.api.getLayoutFile(id)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            this.spinner.hide('sp');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (id === 27 || id === 28) {
              if (this.esClienteFisico) {
                downloadLink.setAttribute('download', 'LAYOUT_CARGA_MASIVA_CLIENTES_FÍSICOS.xlsx');
              } else {
                downloadLink.setAttribute('download', 'LAYOUT_CARGA_MASIVA_CLIENTES_MORALES.xlsx');
              }
            }
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  procesarInfo(e: any) {
    if (this.esClienteFisico) {
      const validaRfc = [];
      const validaGrupo = [];
      const validaPaisC = [];
      const validaEstadoC = [];
      const validaMunicipioC = [];
      const validaCpC = [];
      const validaColoniaC = [];
      const validaPaisF = [];
      const validaEstadoF = [];
      const validaMunicipioF = [];
      const validaCpF = [];
      const validaColoniaF = [];

      validaRfc.push(e.filter(d => d.valRfc === true).map(d => d.valRfc));
      validaGrupo.push(e.filter(d => d.valGrupo === true).map(d => d.valGrupo));
      validaPaisC.push(e.filter(d => d.valPaisCliente === true).map(d => d.valPaisCliente));
      validaEstadoC.push(e.filter(d => d.valEstadoCliente === true).map(d => d.valEstadoCliente));
      validaMunicipioC.push(e.filter(d => d.valMunicipioCliente === true).map(d => d.valMunicipioCliente));
      validaCpC.push(e.filter(d => d.valCpCliente === true).map(d => d.valCpCliente));
      validaColoniaC.push(e.filter(d => d.valColoniaCliente === true).map(d => d.valColoniaCliente));
      validaPaisF.push(e.filter(d => d.valPaisFiscal === true).map(d => d.valPaisFiscal));
      validaEstadoF.push(e.filter(d => d.valEstadoFiscal === true).map(d => d.valEstadoFiscal));
      validaMunicipioF.push(e.filter(d => d.valMunicipioFiscal === true).map(d => d.valMunicipioFiscal));
      validaCpF.push(e.filter(d => d.valCpFiscal === true).map(d => d.valCpFiscal));
      validaColoniaF.push(e.filter(d => d.valColoniaFiscal === true).map(d => d.valColoniaFiscal));
      const procesar = e.length;
      if (validaRfc[0].length === procesar && validaGrupo[0].length === procesar && validaPaisC[0].length === procesar && validaEstadoC[0].length === procesar &&
        validaMunicipioC[0].length === procesar && validaCpC[0].length === procesar && validaColoniaC[0].length === procesar &&
        validaPaisF[0].length === procesar && validaEstadoF[0].length === procesar && validaMunicipioF[0].length === procesar &&
        validaCpF[0].length === procesar && validaColoniaF[0].length === procesar) {
        this.esValidaInformacion = false;
        this.esValidoProcesar = true;
        console.log('VALIDACION CORRECTA, PUEDE PROCESAR LA INFORMACION FISICA');
      }
    } else {
        // ES CLIENTE MORAL
      const validaGrupo = [];
      const validaRfcSubGrupo = [];
      const validaTipoSociedad = [];
      const validaTipoContacto = [];
      const validaPaisC = [];
      const validaEstadoC = [];
      const validaMunicipioC = [];
      const validaCpC = [];
      const validaColoniaC = [];
      const validaPaisF = [];
      const validaEstadoF = [];
      const validaMunicipioF = [];
      const validaCpF = [];
      const validaColoniaF = [];

      validaGrupo.push(e.filter(d => d.valGrupo === true).map(d => d.valGrupo));
      validaRfcSubGrupo.push(e.filter(d => d.valRfcSubGrupo === true).map(d => d.valRfcSubGrupo));
      validaTipoSociedad.push(e.filter(d => d.valTipoSociedad === true).map(d => d.valTipoSociedad));
      validaTipoContacto.push(e.filter(d => d.valTipoContacto === true).map(d => d.valTipoContacto));
      validaPaisC.push(e.filter(d => d.valPaisCliente === true).map(d => d.valPaisCliente));
      validaEstadoC.push(e.filter(d => d.valEstadoCliente === true).map(d => d.valEstadoCliente));
      validaMunicipioC.push(e.filter(d => d.valMunicipioCliente === true).map(d => d.valMunicipioCliente));
      validaCpC.push(e.filter(d => d.valCpCliente === true).map(d => d.valCpCliente));
      validaColoniaC.push(e.filter(d => d.valColoniaCliente === true).map(d => d.valColoniaCliente));
      validaPaisF.push(e.filter(d => d.valPaisFiscal === true).map(d => d.valPaisFiscal));
      validaEstadoF.push(e.filter(d => d.valEstadoFiscal === true).map(d => d.valEstadoFiscal));
      validaMunicipioF.push(e.filter(d => d.valMunicipioFiscal === true).map(d => d.valMunicipioFiscal));
      validaCpF.push(e.filter(d => d.valCpFiscal === true).map(d => d.valCpFiscal));
      validaColoniaF.push(e.filter(d => d.valColoniaFiscal === true).map(d => d.valColoniaFiscal));
      const procesar = e.length;

      if (validaGrupo[0].length === procesar && validaRfcSubGrupo[0].length === procesar && validaTipoSociedad[0].length === procesar &&
        validaTipoContacto[0].length === procesar && validaPaisC[0].length === procesar && validaEstadoC[0].length === procesar &&
        validaMunicipioC[0].length === procesar && validaCpC[0].length === procesar && validaColoniaC[0].length === procesar &&
        validaPaisF[0].length === procesar && validaEstadoF[0].length === procesar && validaMunicipioF[0].length === procesar &&
        validaCpF[0].length === procesar && validaColoniaF[0].length === procesar) {
        this.esValidaInformacion = false;
        this.esValidoProcesar = true;
        console.log('VALIDACION CORRECTA, PUEDE PROCESAR LA INFORMACION MORAL');
      } else {
        console.log('FALTAN DATOS POR VALIDAR..');
      }
    }
  }


  invokeStateByZipCodeCM(zipCode, typeAddress, tipo) {
    this.api.getStatesByZipeCode(zipCode).then((result: any) => {
      if (result != null) {
        if (this.esClienteFisico) {
          if (tipo === 1) {
            this.editingClientFisico.estadoFiscal = result.cValue;
            this.editingClientFisico.idEstadoFiscal = result.cKey;
          } else {
            this.editingClientFisico.estadoCliente = result.cValue;
            this.editingClientFisico.idEstadoCliente = result.cKey;
          }
        } else {
          if (tipo === 1) {
            this.editingClientMoral.estadoFiscal = result.cValue;
            this.editingClientMoral.idEstadoFiscal = result.cKey;
          } else {
            this.editingClientMoral.estadoCliente = result.cValue;
            this.editingClientMoral.idEstadoCliente = result.cKey;
          }
        }
        this.invokeServiceGetMunicipalyByStateNameCM(result, 'FISCAL', zipCode, tipo);
          // }

      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });
  }

  invokeServiceGetMunicipalyByStateNameCM(data, typeAddress, zipcode, tipo) {
    this.api.getMunicipalyByStateName(data).then((result: any) => {
      if (result != null) {

        if (tipo === 1) {
          this.municipalityListClientes = null;
          this.municipalityListClientes = result;
        } else {
          this.municipalityListClientesFisico = null;
          this.municipalityListClientesFisico = result;
        }
        this.invokeTownByZipCodeCM(zipcode, typeAddress, tipo);


      }
    }, error => {

    });

  }

  invokeTownByZipCodeCM(zipCode, typeAddress, tipo) {
    this.api.getTownByPostcode(zipCode).then((result: any) => {
      if (result != null) {
        if (this.esClienteFisico) {
          if (tipo === 1) {
            this.editingClientFisico.municipioFiscal = result.cValue;
            this.editingClientFisico.idMunicipioFiscal = result.cKey;
          } else {
            this.editingClientFisico.municipioCliente = result.cValue;
            this.editingClientFisico.idMunicipioCliente = result.cKey;
          }
        } else {
          if (tipo === 1) {
            this.editingClientMoral.municipioFiscal = result.cValue;
            this.editingClientMoral.idMunicipioFiscal = result.cKey;
          } else {
            this.editingClientMoral.municipioCliente = result.cValue;
            this.editingClientMoral.idMunicipioCliente = result.cKey;
          }
        }
      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });
  }

procesaInfo() {

 if (this.esClienteFisico) {
   let listaclientes: GroupFisico[] = [];

   console.log('GRUPO FISICO: ', this.groupFisico);

   listaclientes = this.infoClienteFisico.map(ct => {
     let i = 0
     const clienteF = new GroupFisico();
     const subgrupo = new SubgroupFisico();

     subgrupo.addressList.push(new AddressFisico(1)); // Fiscal
     subgrupo.addressList.push(new AddressFisico(3)); // Cliente

     clienteF.groupId = ct.idGrupo;
     clienteF.name = ct.grupo;

     clienteF.subgroups.push(subgrupo);
     subgrupo.general_data.clientType = 1;
     subgrupo.general_data.rfc = ct.rfc;
     subgrupo.general_data.name = ct.nombre;
     subgrupo.general_data.firstName = ct.apellidoPaterno;
     subgrupo.general_data.lastName = ct.apellidoMaterno;
     subgrupo.general_data.phone = ct.telefono;
     subgrupo.general_data.email = ct.email;
     subgrupo.general_data.groupId = ct.idGrupo;

     if (ct.actaNacimiento !== '') {
       const documentacion = new DocumentationListFisico();
       subgrupo.documentationList.push(documentacion);
       subgrupo.documentationList[i].name = ct.actaNacimiento;
       i++;
     }
     if (ct.comprobanteDomFiscal !== '') {
       const documentacion = new DocumentationListFisico();
       subgrupo.documentationList.push(documentacion);
       subgrupo.documentationList[i].name = ct.comprobanteDomFiscal;
       i++;
     }
     if (ct.identificacionOficial !== '') {
       const documentacion = new DocumentationListFisico();
       subgrupo.documentationList.push(documentacion);
       subgrupo.documentationList[i].name = ct.identificacionOficial;
       i++;
     }
     if (ct.rfcDocumento !== '') {
       const documentacion = new DocumentationListFisico();
       subgrupo.documentationList.push(documentacion);
       subgrupo.documentationList[i].name = ct.rfcDocumento;
       i++;
     }
     if (ct.acusePrescripcion !== '') {
       const documentacion = new DocumentationListFisico();
       subgrupo.documentationList.push(documentacion);
       subgrupo.documentationList[i].name = ct.acusePrescripcion;
       i++;
     }


     subgrupo.addressList[0].country = ct.paisFiscal;
     subgrupo.addressList[0].zipCode = ct.cpFiscal;
     subgrupo.addressList[0].state = ct.estadoFiscal;
     subgrupo.addressList[0].stateCode = ct.idEstadoFiscal;
     subgrupo.addressList[0].municipalityCode = ct.idMunicipioFiscal;
     subgrupo.addressList[0].neighborhoodCode = ct.idColoniaFiscal;
     subgrupo.addressList[0].street = ct.calleFiscal;
     subgrupo.addressList[0].streetCode = '';
     subgrupo.addressList[0].numExt = ct.numExtFiscal;
     subgrupo.addressList[0].numInt = ct.numIntFiscal;

     subgrupo.addressList[1].country = ct.paisCliente;
     subgrupo.addressList[1].zipCode = ct.cpCliente;
     subgrupo.addressList[1].state = ct.estadoCliente;
     subgrupo.addressList[1].stateCode = ct.idEstadoCliente;
     subgrupo.addressList[1].municipalityCode = ct.idMunicipioCliente;
     subgrupo.addressList[1].neighborhoodCode = ct.idColoniaCliente;
     subgrupo.addressList[1].street = ct.calleCliente;
     subgrupo.addressList[1].streetCode = '';
     subgrupo.addressList[1].numExt = ct.numExtCliente;
     subgrupo.addressList[1].numInt = ct.numIntCliente;

     return clienteF;

   });

   console.log('GRUPO FISICO FINAL: ', listaclientes);
   this.saveInformacionCliente(listaclientes);

 } else {
   let listaclientes: Group[] = [];

   listaclientes = this.infoClienteMoral.map(ct => {
     let i = 0
     const clienteM = new Group();
     const subgrupoM = new Subgroup();
     const contact = new Contact();

     subgrupoM.addressList.push(new Address(1)); // Fiscal
     subgrupoM.addressList.push(new Address(3)); // Cliente
     subgrupoM.contactList.push(contact);

     clienteM.name = ct.grupo;

     clienteM.subgroups.push(subgrupoM);

     subgrupoM.general_data.clientType = 2;
     subgrupoM.general_data.rfc = ct.rfcSubgrupo;
     subgrupoM.general_data.name = ct.razonSocial;
     subgrupoM.general_data.typeSocietyId = ct.idTipoSociedad;

     subgrupoM.contactList[0].name = ct.nombreContacto;
     subgrupoM.contactList[0].phone = ct.telefono;
     subgrupoM.contactList[0].email = ct.email;
     subgrupoM.contactList[0].contactTypeId= ct.idTipoContacto;


     if (ct.actaConstitutiva !== '') {
       const documentacion = new DocumentationList();
       subgrupoM.documentationList.push(documentacion);
       subgrupoM.documentationList[i].name = ct.actaConstitutiva;
       i++;
     }
     if (ct.poderNotarial !== '') {
       const documentacion = new DocumentationList();
       subgrupoM.documentationList.push(documentacion);
       subgrupoM.documentationList[i].name = ct.poderNotarial;
       i++;
     }
     if (ct.identificacionApoderado !== '') {
       const documentacion = new DocumentationList();
       subgrupoM.documentationList.push(documentacion);
       subgrupoM.documentationList[i].name = ct.identificacionApoderado;
       i++;
     }
     if (ct.comprobanteDomicilio !== '') {
       const documentacion = new DocumentationList();
       subgrupoM.documentationList.push(documentacion);
       subgrupoM.documentationList[i].name = ct.comprobanteDomicilio;
       i++;
     }
     if (ct.comprobanteDomFiscal !== '') {
       const documentacion = new DocumentationList();
       subgrupoM.documentationList.push(documentacion);
       subgrupoM.documentationList[i].name = ct.comprobanteDomFiscal;
       i++;
     }

     subgrupoM.addressList[0].country = ct.paisFiscal;
     subgrupoM.addressList[0].zipCode = ct.cpFiscal;
     subgrupoM.addressList[0].state = ct.estadoFiscal;
     subgrupoM.addressList[0].stateCode = ct.idEstadoFiscal;
     subgrupoM.addressList[0].municipalityCode = ct.idMunicipioFiscal;
     subgrupoM.addressList[0].neighborhoodCode = ct.idColoniaFiscal;
     subgrupoM.addressList[0].street = ct.calleFiscal;
     subgrupoM.addressList[0].streetCode = '';
     subgrupoM.addressList[0].numExt = ct.numExtFiscal;
     subgrupoM.addressList[0].numInt = ct.numIntFiscal;

     subgrupoM.addressList[1].country = ct.paisCliente;
     subgrupoM.addressList[1].zipCode = ct.cpCliente;
     subgrupoM.addressList[1].state = ct.estadoCliente;
     subgrupoM.addressList[1].stateCode = ct.idEstadoCliente;
     subgrupoM.addressList[1].municipalityCode = ct.idMunicipioCliente;
     subgrupoM.addressList[1].neighborhoodCode = ct.idColoniaCliente;
     subgrupoM.addressList[1].street = ct.calleCliente;
     subgrupoM.addressList[1].streetCode = '';
     subgrupoM.addressList[1].numExt = ct.numExtCliente;
     subgrupoM.addressList[1].numInt = ct.numIntCliente;

     return clienteM;

   });

   console.log('GRUPO MORAL FINAL: ', listaclientes);
   this.saveInformacionCliente(listaclientes);

 }
  }

  saveInformacionCliente(e: any) {
    this.spinner.show('sp');
    if (this.esClienteFisico) {
      this.api.processingInfoFisica(e)
        .subscribe(
          response => {
            this.esValidoProcesar = false;
            this.toastr.success('INFORMACION REGISTRADA SATISFACTORIAMENTE.', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
          }, error => {
            this.toastr.warning(error.error);
            this.spinner.hide('sp');
          }
        );
    } else {
      this.api.processingInfoMoral(e)
        .subscribe(
          response => {
            this.esValidoProcesar = false;
            this.toastr.success('INFORMACION REGISTRADA SATISFACTORIAMENTE.', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
          }, error => {
            this.toastr.warning(error.error);
            this.spinner.hide('sp');
          }
        );

    }
  }

  getGrupoFisico() {
    const data = {name: 'GROUPS'};
    this.api.getCatalogByBusiness(data).then((result: any) => {
      if (result != null) {
        const vacio = {
          id: 0,
          description: 'ELIJA UN VALOR VALIDO',
        };
        result.push(vacio);
        this.groupsListCM = result;

      }
    }, error => {

    });
  }

  changeGrupoFisico(e: any) {
    const valor = this.groupsListCM.find(a => a.id === e);
       this.editingClientFisico.idGrupo = e;
      this.editingClientFisico.grupo = valor.description;
     console.log(e);
  }

  validarCaracteresEspeciales(inputString: string): boolean {

    const regex = /[@?'"=]/;
    if (regex.test(inputString)) {
      return false;
    }
    return true;
  }

  validarSoloNumeros(inputString: string): boolean {

    const regex = /[0-9]/;
    if (regex.test(inputString)) {
      return true;
    }
    return false;
  }

}
