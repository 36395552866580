export class Client {
  group: Group;

  constructor() {
    this.group = new Group();
  }
}


export class Group {
  groupId: number;
  name: string;
  subgroups: Array<Subgroup>;

  constructor() {
    this.groupId = null;
    this.name = '';
    this.subgroups = new Array<Subgroup>();
  }
}

export class Subgroup {
  // tslint:disable-next-line:variable-name
  general_data: GeneralData;
  contactList: Array<Contact>;
  addressList: Array<Address>;
  documentationList: Array<DocumentationList>;
  policies: Array<Policy>;

  constructor() {
    this.general_data = new GeneralData();
    this.contactList = new Array<Contact>();
    this.addressList = new Array<Address>();
    this.documentationList = new Array<DocumentationList>();
    this.policies = new Array<Policy>();
  }
}

export class GeneralData {
  clientId: number;
  rfc: string;
  name: string;
  clientType: number;
  type: string;
  groupId: number;
  status: boolean;
  comment: string;
  typeSocietyId: number;

  constructor() {
    this.clientId = null;
    this.rfc = '';
    this.name = '';
    this.type = '';
    this.groupId = 0;
    this.status = true;
    this.comment = '';
  }
}

export class Contact {
  contactId: number;
  name: string;
  phone: string;
  email: string;
  contactTypeId: number;
  constructor() {
    this.contactId = null;
    this.name = '';
    this.phone = '';
    this.email = '';
    this.contactTypeId = 3;
  }
}

export class Address {
  addressId: number;
  clientId: number;
  country: string;
  zipCode: string;
  state: string;
  stateCode: string;
  municipality: string;
  municipalityCode: string;
  neighborhood: string;
  neighborhoodCode: string;
  street: string;
  streetName: string;
  streetCode: string;
  numExt: string;
  status: boolean;
  numInt: string;
  addressType: number;
  constructor(addressType: number) {
    this.addressId = null;
    this.clientId = null;
    this.country = 'MÉXICO';
    this.zipCode = null;
    this.stateCode = null;
    this.state = '';
    this.municipalityCode = null;
    this.municipality = '';
    this.neighborhoodCode = null;
    this.neighborhood = '';
    this.street = null;
    this.streetName = '';
    this.streetCode = null;
    this.stateCode = null;
    this.numExt = '';
    this.numInt = '';
    this.status = true;
    this.addressType = addressType;
  }
}

export class DocumentationList {
  clientDocumentationId: number;
  name: string;
  path: string;
  base: string;
  receiptDate: string;
  dateCreated: string;
  dateUpdate: string;
  status: boolean;
  clientId: number;
  catTypeDocumentId: number;
  constructor() {
    this.clientDocumentationId = null;
    this.name = '';
    this.path = '';
    this.base = '';
    this.receiptDate = '';
    this.dateCreated = '';
    this.dateUpdate = '';
    this.status = null;
    this.clientId = 0;
    this.catTypeDocumentId = 0;
  }
}

export class Policy {
  policyId: number;
  number: string;
  constructor() {
    this.policyId = 0;
    this.number = '';
  }
}



