import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-admin-new-request',
  templateUrl: './admin-new-request.component.html',
  styleUrls: ['./admin-new-request.component.css']
})
export class AdminNewRequestComponent implements OnInit {

  catClients: any = [];
  subranchCategoryList: any = [];
  categoriesList: any = [];
  client: any;
  groupList: any = [];
  clientName: any;
  clientId: any;
  policy: any;
  policyName: any;
  categoryName: any;
  selectedType = 0;
  selectedTypeSave = 0;
  category: any;
  emaiValidate = '';
  isEmailValidate = true;
  bsLeavingDateBirthDate: Partial<BsDatepickerConfig>;
  bsLeavingDate: Partial<BsDatepickerConfig>;
  bsLeavingDateBirthDateDependent: Partial<BsDatepickerConfig>;
  bsLeavingDateDependent: Partial<BsDatepickerConfig>;
  genderSelected;
  relationshipList: any = []; // Almaccena la lista de parentescos retornada por el servicio
  catGenders: any = [];
  newRequestForm: FormGroup; // Formulario
  newRequestDependentForm: FormGroup; // Formulario
  newRequestInsuredCancellationForm: FormGroup;
  newRequestDependentCancellationForm: FormGroup;
  newRequestGralForm: FormGroup; // Formulario
  policiesSelectedItems: any = [];
  dependentsLowSelectedItems: any = [];
  dropdownSettingsPolicies = {};
  dropdownSettingsPoliciesInsured = {};
  dropdownSettingsDependentsLow: any = {};
  birthDate: string;
  createAt: string;
  startDate: string;
  modalRefDependent: BsModalRef;
  modalOptionRequest: BsModalRef;
  dataNewDependent: {
    nameDependent,
    firstNameDependent,
    lastNameDependent,
    kinshipId,
    genderDependent,
    birthDate,
    startDate
  };
  insuredList: any = [];
  dependentsListByInsured: any = [];
  newDependentList: any = [];
  idRequest: number;
  subject: string;

  constructor(private modalService: BsModalService,
              private toastr: ToastrService,
              private shared: SharedService,
              private api: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private formBuilder: FormBuilder,
              private spinner: NgxSpinnerService) {

    this.idRequest = Number(localStorage.getItem('idRequest'));
    this.selectedType = Number(localStorage.getItem('selectedType'));

    if (this.idRequest > 0) { // EDICIÓn
      this.selectedTypeSave = Number(localStorage.getItem('selectedTypeSave'));

      if (this.selectedType === 0 && this.selectedTypeSave === 0) {
        this.createNewForm();
        this.getRequestById();

      } else if (this.selectedType === 0 && this.selectedTypeSave === 1) {
        this.createNewFormDependent();
        this.getRequestAltaDependentsById();
      } else if (this.selectedType === 1 && this.selectedTypeSave === 0) {
        this.createNewFormInsuredCancelation();
        this.getRequestByInsuredCencellation();
      } else {
        this.createNewformDependentCancelation();
        this.getRequestByDependentCancellation();
      }
    } else { // NUEVO
      if (this.selectedType === 0 && this.selectedTypeSave === 0) {
        this.createNewForm();
      } else if (this.selectedType === 0 && this.selectedTypeSave === 1) {
        this.createNewFormDependent();
      } else if (this.selectedType === 1 && this.selectedTypeSave === 0) {
        this.createNewFormInsuredCancelation();
      } else  if (this.selectedType === 1 && this.selectedTypeSave === 1) {
        this.createNewformDependentCancelation();
      } else {
        this.createNewFormGral();
      }
    }


  }

  ngOnInit() {

    this.dropdownSettingsPolicies = {
      singleSelection: false,
      idField: 'categoryId',
      textField: 'policyList',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON PÓLIZAS ACTIVAS',
      searchPlaceholderText: 'BUSCAR'
    };

    this.dropdownSettingsPoliciesInsured = {
      singleSelection: false,
      idField: 'categoryId',
      textField: 'policyList',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON PÓLIZAS ACTIVAS',
      searchPlaceholderText: 'BUSCAR'
    };

    this.dropdownSettingsDependentsLow = {
      singleSelection: false,
      idField: 'kinshipId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON DEPENDIENTES',
      searchPlaceholderText: 'BUSCAR'
    };

    this.dataNewDependent = {
      nameDependent: '',
      firstNameDependent: '',
      lastNameDependent: '',
      kinshipId: 0,
      genderDependent: '',
      birthDate: '',
      startDate: ''
    };

    this.bsLeavingDateBirthDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      adaptivePosition: true
    });

    this.bsLeavingDateDependent = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      adaptivePosition: true
    });

    this.bsLeavingDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      adaptivePosition: true
    });

    this.bsLeavingDateBirthDateDependent = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      adaptivePosition: true
    });

    this.invokeServiceGetGenders();
    this.getGroups();


  }

  getRequestById() {

    // console.log(data);
    this.api.getRequestNewInsured(this.newRequestForm.value)
      .then(
        (response: any) => {
          this.getSubgroupByGroupId(response.groupId);
          this.assignRequestNewInsured(response);
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }

  getRequestAltaDependentsById() {
    this.newDependentList.push(this.newRequestDependentForm.value);
    // console.log(data);
    this.api.getRequestNewDependents(this.newDependentList)
      .then(
        (response: any) => {
          this.getSubgroupByGroupId(response[0].groupId);
          this.invokeServiceForGetInsuredList(response[0].groupId, response[0].subgroupId);
          this.newDependentList = [];
          response.map(request => this.newDependentList.push(request));
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }

  getRequestByInsuredCencellation() {

    // console.log(data);
    this.api.getRequestInsuredCancellation(this.newRequestInsuredCancellationForm.value)
      .then(
        (response: any) => {
          this.getSubgroupByGroupId(response.groupId);
          this.invokeServiceForGetInsuredList(response.groupId, response.subgroupId);
          this.assignRequestInsuredCancelation(response);
        }, error => {
            if (error.status === 409) {
              if (error.error.message !== undefined) {
                this.toastr.warning(error.error.message, 'Notificación');
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
        }
      );
  }

  getRequestByDependentCancellation() {
    const dependentsList = [];

    dependentsList.push({
        dependentId : 0,
        idRequest: this.newRequestDependentCancellationForm.controls.idRequest.value,
        groupId: this.newRequestDependentCancellationForm.controls.groupId.value,
        subgroupId: this.newRequestDependentCancellationForm.controls.subgroupId.value,
        userId: this.newRequestDependentCancellationForm.controls.userId.value,
        startDate: this.newRequestDependentCancellationForm.controls.startDate.value,
        createAt: this.newRequestDependentCancellationForm.controls.createAt.value,
        policyList: this.policiesSelectedItems,
        policySubranchCategories: this.newRequestDependentCancellationForm.controls.policySubranchCategories.value
      });

    // console.log(data);
    this.api.getRequestDependentsCancellation(dependentsList)
      .then(
        (response: any) => {
          this.getSubgroupByGroupId(response[0].groupId);
          this.invokeServiceForGetInsuredList(response[0].groupId, response[0].subgroupId);
          this.invokeServiceForGetDependentsByInsured(response[0].userId, response);
          this.assignRequestDependentCancelation(response);
        }, error => {
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
  }


  assignRequestNewInsured(request) {
    let subranch: FormArray;
    this.newRequestForm.controls.groupId.setValue(request.groupId);
    this.newRequestForm.controls.subgroupId.setValue(request.subgroupId);
    this.newRequestForm.controls.userId.setValue((request.userId === undefined || request.userId === null) ? 0 : request.userId);
    /*(this.newRequestForm.get('policyList') as FormArray).push(this.formBuilder.array(
      request.policyList.length === 0 ? [] :
        request.policyList.map(policy => this.createPolicyList(policy))
    ));*/
    this.newRequestForm.controls.name.setValue(request.name);
    this.newRequestForm.controls.firstName.setValue(request.firstName);
    this.newRequestForm.controls.lastName.setValue(request.lastName);
    this.newRequestForm.controls.email.setValue(request.email);
    this.newRequestForm.controls.birthDate.setValue( this.datePipe.transform(request.birthDate, 'dd-MM-yyyy'));
    this.newRequestForm.controls.startDate.setValue( this.datePipe.transform(request.startDate, 'dd-MM-yyyy')); // this.datePipe.transform(response[0].paymentDate, 'yyyy/MM/dd'),
    this.newRequestForm.controls.createAt.setValue( this.datePipe.transform(request.createAt, 'dd-MM-yyyy'));
    this.newRequestForm.controls.gender.setValue(request.gender);
    this.newRequestForm.controls.number.setValue(request.number);
    this.newRequestForm.controls.salary.setValue(request.salary);
    this.newRequestForm.controls.dependetList.setValue(request.dependetList);

    subranch = new FormArray(request.policySubranchCategories.map(subranchs => this.addTopolicySubranchCategories(subranchs)));
    this.newRequestForm.setControl('policySubranchCategories', subranch);

  }

  assignRequestInsuredCancelation(request) {
    let subranch: FormArray;
    this.newRequestInsuredCancellationForm.controls.groupId.setValue(request.groupId);
    this.newRequestInsuredCancellationForm.controls.subgroupId.setValue(request.subgroupId);
    this.newRequestInsuredCancellationForm.controls.userId.setValue((request.userId === undefined || request.userId === null) ? 0 : request.userId);
    this.newRequestInsuredCancellationForm.controls.startDate.setValue(this.datePipe.transform(request.startDate, 'dd-MM-yyyy'));
    this.newRequestInsuredCancellationForm.controls.createAt.setValue(this.datePipe.transform(request.createAt, 'dd-MM-yyyy'));

    subranch = new FormArray(request.policySubranchCategories.map(subranchs => this.addTopolicySubranchCategories(subranchs)));
    this.newRequestInsuredCancellationForm.setControl('policySubranchCategories', subranch);

  }

  assignRequestDependentCancelation(request) {
    let subranch: FormArray;
    this.newRequestDependentCancellationForm.controls.groupId.setValue(request[0].groupId);
    this.newRequestDependentCancellationForm.controls.subgroupId.setValue(request[0].subgroupId);
    this.newRequestDependentCancellationForm.controls.userId.setValue((request[0].userId === undefined || request[0].userId === null) ? 0 : request[0].userId);
    this.newRequestDependentCancellationForm.controls.startDate.setValue(this.datePipe.transform(request[0].startDate, 'dd-MM-yyyy'));
    this.newRequestDependentCancellationForm.controls.createAt.setValue(this.datePipe.transform(request[0].createAt, 'dd-MM-yyyy'));

    subranch = new FormArray(request[0].policySubranchCategories.map(subranchs => this.addTopolicySubranchCategories(subranchs)));
    this.newRequestDependentCancellationForm.setControl('policySubranchCategories', subranch);


  }

  addDependentToFormNewDepentend(dependent, index) {
    let subranch: FormArray;
    this.newRequestDependentForm.controls.groupId.setValue(dependent.groupId);
    this.newRequestDependentForm.controls.subgroupId.setValue(dependent.subgroupId);
    this.newRequestDependentForm.controls.userId.setValue((dependent.userId === undefined || dependent.userId === null) ? 0 : dependent.userId);
    /*(this.newRequestForm.get('policyList') as FormArray).push(this.formBuilder.array(
      request.policyList.length === 0 ? [] :
        request.policyList.map(policy => this.createPolicyList(policy))
    ));*/
    this.newRequestDependentForm.controls.name.setValue(dependent.name);
    this.newRequestDependentForm.controls.firstName.setValue(dependent.firstName);
    this.newRequestDependentForm.controls.lastName.setValue(dependent.lastName);
    this.newRequestDependentForm.controls.birthDate.setValue(this.datePipe.transform(dependent.birthDate, 'dd-MM-yyyy'));
    this.newRequestDependentForm.controls.startDate.setValue(this.datePipe.transform(dependent.startDate, 'dd-MM-yyyy'));
    this.newRequestDependentForm.controls.createAt.setValue(this.datePipe.transform(dependent.createAt, 'dd-MM-yyyy'));
    this.newRequestDependentForm.controls.gender.setValue(dependent.gender);
    this.newRequestDependentForm.controls.kinshipId.setValue(dependent.kinshipId);

    subranch = new FormArray(dependent.policySubranchCategories.map(subranchs => this.addTopolicySubranchCategories(subranchs)));
    this.newRequestDependentForm.setControl('policySubranchCategories', subranch);
    this.newDependentList.splice(index, 1);
  }

  onDeleteSelected(index) {
    this.newDependentList.splice(index, 1);
  }

  onDeleteSelectedFormTitularAndDependent(index) {
    this.dependentList.removeAt(index);
  }

  createPolicyList(policy): FormGroup {
    return this.formBuilder.group({
      policyList: new FormControl(policy.policyList ),
      categoryId: new FormControl(policy.categoryId),
    });
  }

  addSelectedPolicySubranch(policyList) {
    policyList.forEach(policy => {
      this.newRequestForm.controls.policySubranchCategories.value.map(subranchCategory => {
        this.categories(subranchCategory).value.map(cat => {
          if (cat.idCategory === policy.categoryId) {
            cat.selected = true;
          }
        });
      });
    });

  }

  createNewFormGral() {
    this.newRequestGralForm = this.formBuilder.group({
      requestId: new FormControl(this.idRequest),
      groupId: new FormControl(null, [
        Validators.required
      ]),
      subgroupId: new FormControl(null, [
        Validators.required
      ]),
      policyList: new FormArray([]),
      policySubranchCategories: new FormArray([]),
      userId: new FormControl(0, [
        Validators.required
      ]),
      subject: new FormControl(null, [
        Validators.required
      ]),
      description: new FormControl(null, [
        Validators.required
      ]),
      createAt: new FormControl(null, [
        Validators.required
      ]),
      isincumbent: new FormControl(true )
    });
  }

  createNewForm() {
    this.newRequestForm = this.formBuilder.group({
      idRequest: new FormControl(this.idRequest),
      groupId: new FormControl(null, [
        Validators.required
      ]),
      subgroupId: new FormControl(null, [
        Validators.required
      ]),
      userId: new FormControl(0, [
        Validators.required
      ]),
      policyList: new FormArray([]),
      policySubranchCategories: new FormArray([]),
      name: new FormControl(null, [
        Validators.required
      ]),
      firstName: new FormControl(null, [
        Validators.required
      ]),
      lastName: new FormControl(null, [
        Validators.required
      ]),
      email: new FormControl(null, [
        Validators.required
      ]),
      birthDate: new FormControl(null, [
        Validators.required
      ]),
      startDate: new FormControl(null, [
        Validators.required
      ]),
      createAt: new FormControl(null, [
        Validators.required
      ]),
      gender: new FormControl(null, [
        Validators.required
      ]),
      number: new FormControl(null, [
        Validators.required
      ]),
      salary: new FormControl(null, [
        Validators.required
      ]),
      dependetList: this.formBuilder.array([]),
    });
  }

  addTopolicySubranchCategories(subranchCategory): FormGroup {
    return this.formBuilder.group({
      idPolicy: new FormControl(subranchCategory.idPolicy ),
      idSubranch: new FormControl(subranchCategory.idSubranch ),
      subranch: new FormControl(subranchCategory.subranch + ' ' + subranchCategory.numberPolicy ),
      numberPolicy: new FormControl(subranchCategory.numberPolicy),
      policyCategoriesList: this.formBuilder.array(
        subranchCategory.policyCategoriesList.map(policyCategorie => this.addCategories(policyCategorie)))
    });
  }

  addCategories(categories): FormGroup {
    return this.formBuilder.group({
      idCategory: new FormControl(categories.idCategory ),
      category: new FormControl(categories.category ),
      selected: new FormControl(categories.selected )
    });
  }

  get policyList(): FormArray {
    if (this.selectedType === 0 && this.selectedTypeSave === 0) { // Alta de asegurado
      return this.newRequestForm.get('policySubranchCategories') === undefined ?  new FormArray([]) : this.newRequestForm.get('policySubranchCategories') as FormArray;
    } else if (this.selectedType === 0 && this.selectedTypeSave === 1) { // Alta de dependiente
      return this.newRequestDependentForm.get('policySubranchCategories') as FormArray;
    } else if (this.selectedType === 1 && this.selectedTypeSave === 0) { // Baja de asegurado
      return this.newRequestInsuredCancellationForm.get('policySubranchCategories') as FormArray;
    } else if (this.selectedType === 1 && this.selectedTypeSave === 1) {
      return this.newRequestDependentCancellationForm.get('policySubranchCategories') as FormArray;
    } else { // General
      return this.newRequestGralForm.get('policySubranchCategories') as FormArray;
    }
  }

  categories(policySubranchCategories): FormArray {
    return policySubranchCategories === undefined ? new FormArray([]) : policySubranchCategories.get('policyCategoriesList') as FormArray;
  }

  createNewFormDependent() {
    this.newRequestDependentForm = this.formBuilder.group({
      idRequest: new FormControl(this.idRequest, ),
      groupId: new FormControl(null, [
        Validators.required
      ]),
      subgroupId: new FormControl(null, [
        Validators.required
      ]),
      userId: new FormControl(null, [
        Validators.required
      ]),
      policyList: new FormArray([]),
      policySubranchCategories: new FormArray([]),
      name: new FormControl(null, [
        Validators.required
      ]),
      firstName: new FormControl(null, [
        Validators.required
      ]),
      lastName: new FormControl(null, [
        Validators.required
      ]),
      birthDate: new FormControl(null, [
        Validators.required
      ]),
      startDate: new FormControl(null, [
        Validators.required
      ]),
      createAt: new FormControl(null, [
        Validators.required
      ]),
      gender: new FormControl(null, [
        Validators.required
      ]),
      kinshipId: new FormControl(null, [
        Validators.required
      ])
    });
  }

  createNewFormInsuredCancelation() {
    this.newRequestInsuredCancellationForm = this.formBuilder.group({
      idRequest: new FormControl(this.idRequest, ),
      groupId: new FormControl(null, [
        Validators.required
      ]),
      subgroupId: new FormControl(null, [
        Validators.required
      ]),
      userId: new FormControl(null, [
        Validators.required
      ]),
      startDate: new FormControl(null, [
        Validators.required
      ]),
      createAt: new FormControl(null, [
        Validators.required
      ]),
      policyList: new FormArray([]),
      policySubranchCategories: new FormArray([])
    });
  }

  createNewformDependentCancelation() {
      this.newRequestDependentCancellationForm = this.formBuilder.group({
        idRequest: new FormControl(this.idRequest, ),
        groupId: new FormControl(null, [
          Validators.required
        ]),
        subgroupId: new FormControl(null, [
          Validators.required
        ]),
        userId: new FormControl(null, [
          Validators.required
        ]),
        createAt: new FormControl(null, [
          Validators.required
        ]),
        startDate: new FormControl(null, [
          Validators.required
        ]),
        policyList: new FormArray([]),
        policySubranchCategories: new FormArray([]),
        dependentId: new FormControl(this.dependentsLowSelectedItems, [
          Validators.required
        ])
      });
  }


  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupList = data;
    }, error => {
    });
  }

  invokeGetSubgrupobyGroup(idGroup) {
    if (idGroup !== undefined ) {
      const group = idGroup.groupId;
      this.getSubgroupByGroupId(group);
    }
  }

  getSubgroupByGroupId(group) {
    this.api.getSubGroups(group).then((data: any) => {
      this.catClients = data;
    }, error => {

    });
  }

  clearGroup() {
    this.client = null;
    this.catClients = [];
  }

  onChangeSubgrupo() {

    if (this.selectedType === 0) { // Es para Alta
      if (this.selectedTypeSave === 0) { // Es para un Asegurado

        // obtener ramos y categorías
        // this.invokeServiceTiGetPolicies(this.newRequestForm.controls.subgroupId.value, this.newRequestForm.controls.groupId.value);
        this.invokeServiceGetSubranchCategory(this.newRequestForm.controls.subgroupId.value);

      } else {
        this.insuredList = [];
        this.invokeServiceForGetInsuredList(this.newRequestDependentForm.controls.groupId.value,
          this.newRequestDependentForm.controls.subgroupId.value); // Se buscan los asegurados de determinado cliente
      }
    } else if (this.selectedType === 1) { // Es para baja
      if (this.selectedTypeSave === 0) {
        this.insuredList = [];
        this.invokeServiceForGetInsuredList(this.newRequestInsuredCancellationForm.controls.groupId.value,
          this.newRequestInsuredCancellationForm.controls.subgroupId.value);
      } else {
        this.insuredList = [];
        this.invokeServiceForGetInsuredList(this.newRequestDependentCancellationForm.controls.groupId.value,
          this.newRequestDependentCancellationForm.controls.subgroupId.value);
      }
    } else {
      this.insuredList = [];
      this.invokeServiceForGetInsuredList(this.newRequestGralForm.controls.groupId.value,
        this.newRequestGralForm.controls.subgroupId.value);
    }
  }

  clearClients() {
    this.policy = null;
    this.catClients = [];
    this.policiesSelectedItems = null;
  }

  invokeGetCategoryByPolicies(idPolicy) {
    if (idPolicy !== undefined) {
      for (const i in this.policyList) {
        if (this.policyList[i].policyId === idPolicy) {
          this.policyName = this.policyList[i].number;
        }
      }

      this.api.getCategoriesByPolicyIdClientId(idPolicy, this.clientId).then((data: any) => {
        this.categoriesList = data;
      }, error => {

      });
    }
  }


  getCategory(idPolicy) {
    this.categoryName = idPolicy.name;
  }

  onKey(event) {

    this.emaiValidate = event.target.value + '';
    const regOficial = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regOficial.test(this.emaiValidate)) {
      document.getElementById('emailOK').innerText = 'Ingrese un correo valido';
      this.isEmailValidate = false;
    } else if (!reg.test(this.emaiValidate)) {
      document.getElementById('emailOK').innerText = 'Ingrese un correo valido';
      this.isEmailValidate = false;
    } else {

      this.isEmailValidate = true;
      document.getElementById('emailOK').innerText = '';
    }
  }

  getSelectedGender(event) {
    this.genderSelected = Number(event);
  }

  invokeServiceGetGenders() {
    console.log('invokeServiceGetGenders');
    const data = {name: 'GENDERS'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
      if (data != null) {
        this.catGenders = data;
        console.log('data');
        console.log(data);
      }
    }, error => {

    });
  }

  /**
   * función para regresar a solicitudes principal
   */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/request']);
  }

  changePolicies() {
  }

  get dependentList(): FormArray {
    return this.newRequestForm.get('dependetList') as FormArray;
  }

  closeModalNewDependent() {
    this.dataNewDependent = {
        nameDependent: '',
        firstNameDependent: '',
        lastNameDependent: '',
        kinshipId: 0,
        genderDependent: '',
        birthDate: '',
        startDate: ''
    };
    this.modalRefDependent.hide();
  }

  invokeServiceTiGetPolicies(subgroupId, groupId) {
    this.api.getPoliciesRequestTemp(subgroupId, groupId)
      .subscribe(
        (response: any) => {

        }, error => {
          console.error(error);
        }
      );
  }
  invokeServiceGetSubranchCategory(request) {
    let subranch: FormArray;
    this.api.getPoliciesclients(request)
      .subscribe(
        (response: any) => {
          this.subranchCategoryList = response;
          subranch = new FormArray(this.subranchCategoryList.map(subranchs => this.addTopolicySubranchCategories(subranchs)));
          this.newRequestForm.setControl('policySubranchCategories', subranch);

        }, error => {
          console.error(error);
        }
      );
  }

  addCheckboxesToForm(arrayCheckBoxes: []) {
    const policySubranchCategories = this.newRequestForm.get('policySubranchCategories') as FormGroup;

    arrayCheckBoxes.forEach((subranch: any) => {
      policySubranchCategories.addControl(subranch.subranch + ' ' + subranch.numberPolicy, new FormControl(true));
    });
  }


  addDependentShow(template: TemplateRef<any>) {
    this.dataNewDependent.startDate = this.newRequestForm.controls.startDate.value;
    this.modalRefDependent =
      this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }

  addDependentToList() {
    if (!this.newRequestDependentForm.valid) {
      this.newRequestDependentForm.markAllAsTouched();
      return;
    }

    this.newRequestDependentForm.controls.birthDate.setValue(this.dateFormatShort(this.birthDate) ?
        this.datePipe.transform(this.toDate(this.birthDate), 'yyyy-MM-dd') :
        this.datePipe.transform(this.birthDate, 'yyyy-MM-dd', 'es-ES'));

    this.newRequestDependentForm.controls.startDate.setValue(this.dateFormatShort(this.startDate) ?
        this.datePipe.transform(this.toDate(this.startDate), 'yyyy-MM-dd') :
        this.datePipe.transform(this.startDate, 'yyyy-MM-dd', 'es-ES'));
    this.newRequestDependentForm.controls.createAt.setValue(this.dateFormatShort(this.createAt) ?
        this.datePipe.transform(this.toDate(this.createAt), 'yyyy-MM-dd') :
        this.datePipe.transform(this.createAt, 'yyyy-MM-dd', 'es-ES'));
    this.newDependentList.push(this.newRequestDependentForm.value);
    this.newRequestDependentForm.controls.name.setValue(null);
    this.newRequestDependentForm.controls.firstName.setValue(null);
    this.newRequestDependentForm.controls.lastName.setValue(null);
    this.newRequestDependentForm.controls.birthDate.setValue(null);
    this.newRequestDependentForm.controls.gender.setValue(null);
    this.newRequestDependentForm.controls.kinshipId.setValue(null);
  }

  dateFormatShort(date: string): boolean {
    if (/^\d{2}-\d{2}-\d{4}$/.test(date)) {
      return true;
    } else {
      return false;
    }
  }

  toDate(dateStr) {
      const parts = dateStr.split('-');
      return new Date(parts[2], parts[1] - 1, parts[0]);
  }

  addNewDependent() {
    if (!this.newDependent().valid) {
      this.toastr.warning('Es necesario agregar todos los datos del dependiente', 'Notificación');
      return;
    }
    this.dependentList.push(this.newDependent());
    this.clearDepedent();
    this.closeModalNewDependent();
  }

  newDependent(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl(this.dataNewDependent.nameDependent, [
        Validators.required
      ]),
      firstName: new FormControl(this.dataNewDependent.firstNameDependent, [
        Validators.required
      ]),
      lastName: new FormControl(this.dataNewDependent.lastNameDependent, [
        Validators.required
      ]),
      kinshipId: new FormControl(Number(this.dataNewDependent.kinshipId), [
        Validators.required
      ]),
      startDate: new FormControl( this.dataNewDependent.startDate = this.dateFormatShort(this.dataNewDependent.startDate) ?
        this.datePipe.transform(this.toDate(this.dataNewDependent.startDate), 'yyyy-MM-dd') :
        this.datePipe.transform(this.dataNewDependent.startDate, 'yyyy-MM-dd', 'es-ES'), [
        Validators.required
      ]),
      birthDate: new FormControl(this.dataNewDependent.birthDate = this.dateFormatShort(this.dataNewDependent.birthDate) ?
        this.datePipe.transform(this.toDate(this.dataNewDependent.birthDate), 'yyyy-MM-dd') :
        this.datePipe.transform(this.dataNewDependent.birthDate, 'yyyy-MM-dd', 'es-ES'), [
        Validators.required
      ]),
      gender: new FormControl(Number(this.dataNewDependent.genderDependent), [
        Validators.required
      ]),
      userId: new FormControl(this.newRequestForm.controls.userId.value, [
        Validators.required
      ]),
    });
  }

  clearDepedent() {
    this.dataNewDependent = {
      nameDependent: '',
      firstNameDependent: '',
      lastNameDependent: '',
      kinshipId: 0,
      genderDependent: '',
      birthDate: '',
      startDate: ''
    };
  }

   changeFormatDate(date: string): string{
     const parts = date.split('-'); // split the date string into an array of day, month, and year components
     const year = parts[2];
     const month = parts[1];
     const day = parts[0];
     return `${year}-${month}-${day}`;
  }
   saveRequest() {

    if (!this.newRequestForm.valid) {
      this.newRequestForm.markAllAsTouched();
      return;
    }
    this.spinner.show('sp');
    if (this.newRequestForm.controls.idRequest.value > 0) {
      // StartDate
      this.newRequestForm.controls.startDate.setValue(this.dateFormatShort(this.newRequestForm.controls.startDate.value) ?
        this.datePipe.transform(this.toDate(this.newRequestForm.controls.startDate.value), 'yyyy-MM-dd') :
        this.datePipe.transform(this.newRequestForm.controls.startDate.value, 'yyyy-MM-dd', 'es-ES'));
      // birthDate
      this.newRequestForm.controls.birthDate.setValue(this.dateFormatShort(this.newRequestForm.controls.birthDate.value) ?
        this.datePipe.transform(this.toDate(this.newRequestForm.controls.birthDate.value), 'yyyy-MM-dd') :
        this.datePipe.transform(this.newRequestForm.controls.birthDate.value, 'yyyy-MM-dd', 'es-ES'));
      // birthDate
      this.newRequestForm.controls.createAt.setValue(this.dateFormatShort(this.newRequestForm.controls.createAt.value) ?
        this.datePipe.transform(this.toDate(this.newRequestForm.controls.createAt.value), 'yyyy-MM-dd') :
        this.datePipe.transform(this.newRequestForm.controls.createAt.value, 'yyyy-MM-dd', 'es-ES'));

      this.api.updateRequest(this.newRequestForm.value).then((data: any) => {
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        this.goToBack();
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
    } else {
      this.api.postRequest(this.newRequestForm.value).then((data: any) => {
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        this.goToBack();
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
    }

  }

  saveRequestGral() {

    if (!this.newRequestGralForm.valid) {
      this.newRequestGralForm.markAllAsTouched();
      return;
    }
    this.spinner.show('sp');

    if (this.newRequestGralForm.controls.requestId.value > 0) {
      this.api.updateRequest(this.newRequestGralForm.value).then((data: any) => {
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        this.goToBack();
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
    } else {
      this.api.postRequestGral(this.newRequestGralForm.value).then((data: any) => {
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        this.goToBack();
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
    }

  }

  saveRequestDependent() {
    this.spinner.show('sp');
    if (this.newDependentList[0].idRequest > 0) { // Actualizar dependientes
      this.api.updateRequestDependent(this.newDependentList).then((data: any) => {
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        this.goToBack();
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
    } else { // Agregar uno nuevo
      this.api.postRequestDependent(this.newDependentList).then((data: any) => {
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        this.goToBack();
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
    }

  }

  saveRequestCancelation() {
    if (!this.newRequestInsuredCancellationForm.valid) {
      this.newRequestInsuredCancellationForm.markAllAsTouched();
      return;
    }

    this.spinner.show('sp');
    if (this.newRequestInsuredCancellationForm.controls.idRequest.value > 0) {
      // StartDate
      this.newRequestInsuredCancellationForm.controls.startDate.setValue(this.dateFormatShort(this.newRequestInsuredCancellationForm.controls.startDate.value) ?
        this.datePipe.transform(this.toDate(this.newRequestInsuredCancellationForm.controls.startDate.value), 'yyyy-MM-dd') :
        this.datePipe.transform(this.newRequestInsuredCancellationForm.controls.startDate.value, 'yyyy-MM-dd', 'es-ES'));
      // createAt
      this.newRequestInsuredCancellationForm.controls.createAt.setValue(this.dateFormatShort(this.newRequestInsuredCancellationForm.controls.createAt.value) ?
        this.datePipe.transform(this.toDate(this.newRequestInsuredCancellationForm.controls.createAt.value), 'yyyy-MM-dd') :
        this.datePipe.transform(this.newRequestInsuredCancellationForm.controls.createAt.value, 'yyyy-MM-dd', 'es-ES'));

      this.api.updateRequestInsuredCancelation(this.newRequestInsuredCancellationForm.value).then((data: any) => {
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        this.goToBack();
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
    } else {
      this.api.postRequestInsuredCancelation(this.newRequestInsuredCancellationForm.value).then((data: any) => {
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        this.goToBack();
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
    }
  }

  saveRequestCancelationDependent() {
    const dependentsList = [];

    this.dependentsLowSelectedItems.forEach(dependents => {
      dependentsList.push({
        dependentId : dependents.kinshipId,
        idRequest: this.newRequestDependentCancellationForm.controls.idRequest.value,
        groupId: this.newRequestDependentCancellationForm.controls.groupId.value,
        subgroupId: this.newRequestDependentCancellationForm.controls.subgroupId.value,
        userId: this.newRequestDependentCancellationForm.controls.userId.value,
        startDate: this.dateFormatShort(this.newRequestDependentCancellationForm.controls.startDate.value) ?
          this.datePipe.transform(this.toDate(this.newRequestDependentCancellationForm.controls.startDate.value), 'yyyy-MM-dd') :
          this.datePipe.transform(this.newRequestDependentCancellationForm.controls.startDate.value, 'yyyy-MM-dd', 'es-ES'),
        createAt: this.dateFormatShort(this.newRequestDependentCancellationForm.controls.createAt.value) ?
          this.datePipe.transform(this.toDate(this.newRequestDependentCancellationForm.controls.createAt.value), 'yyyy-MM-dd') :
          this.datePipe.transform(this.newRequestDependentCancellationForm.controls.createAt.value, 'yyyy-MM-dd', 'es-ES'),
        policyList: this.policiesSelectedItems,
        policySubranchCategories: this.newRequestDependentCancellationForm.controls.policySubranchCategories.value
      });
    });

    if (!this.newRequestDependentCancellationForm.valid) {
      this.newRequestDependentCancellationForm.markAllAsTouched();
      return;
    }
    this.spinner.show('sp');
    if (this.idRequest > 0) {
      this.api.updateRequestDependentCancelation(dependentsList).then((data: any) => {
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        this.goToBack();
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.Error !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
    } else {
      this.api.postRequestDependentCancelation(dependentsList).then((data: any) => {
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        this.goToBack();
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.Error !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
    }


  }

  registerInsured(event) {
   this.clearDepedent();
   this.newRequestForm.reset();
  }

  closeModalOpionRequest() {
    this.modalOptionRequest.hide();
  }

  getSelectedTypeInsuredSave(event) {
    if (this.selectedType === 0) { // Es asegurado
      if (event === 0) { // SI es 0 es Alta, 1 es baja
        this.createNewForm();
      } else {
        this.createNewFormDependent();
      }
    } else {
      if (event === 0) {
        this.createNewFormInsuredCancelation();
      } else {
        this.createNewformDependentCancelation();
      }
    }

  }
  invokeServiceForGetInsuredList(groupId, subgroupId) {
    this.api.getInsuredList(groupId, subgroupId, '')
      .subscribe(
        (response: any) => {
          if (response.length > 0) {

            this.insuredList = response;
          } else {
            this.insuredList = [];
          }
        }, error => {
          // console.error(error);
        });
  }

  clearDepedentForm() {
    this.newRequestDependentForm.reset();
  }

  invokeServiceForGetPoliciesInsuredIdForDependent(insuredId) {
    let subranch: FormArray;

    if (this.selectedType === 1 && this.selectedTypeSave === 1) {
      this.invokeServiceForGetDependentsByInsured(insuredId.userId);
    } else {
      this.api.getPolicySubranchCategoryByDependent(insuredId.userId)
        .then(
          (response: any) => {
            console.log(response);
            if (response !== null && response.length > 0) {
              this.subranchCategoryList = response;
              subranch = new FormArray(this.subranchCategoryList.map(subranchs => this.addTopolicySubranchCategories(subranchs)));
              if (this.selectedType === 0) {
                this.newRequestDependentForm.setControl('policySubranchCategories', subranch);
              } else if (this.selectedType === 2) {
                this.newRequestGralForm.setControl('policySubranchCategories', subranch);
              } else {
                this.newRequestInsuredCancellationForm.setControl('policySubranchCategories', subranch);
              }

            } else {
              // this.policyList = [];
            }
          }, error => {

          }
        );
    }
  }

  invokeServiceForGetDependentsByInsured(insuredId, request?) {

    this.dependentsListByInsured = [];
    this.api.getDependentsByInsured(insuredId).then((data: any) => {
      if (data !== undefined && data !== null ) {

        if (data.length > 0 ) {
          this.dependentsListByInsured = data;
          if (this.idRequest > 0) {
            this.dependentsListByInsured.map(dependent => {
              request.map (req => {
                if (dependent.kinshipId === req.dependentId) {
                  this.dependentsLowSelectedItems.push(dependent);
                }
              });
            });
            this.newRequestDependentCancellationForm.controls.dependentId.setValue(this.dependentsLowSelectedItems);
          }

        } else {
          this.dependentsListByInsured = [];
        }
      }
    }, error => {
      this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  selectLowDependent() {
    if (this.dependentsLowSelectedItems !== undefined && this.dependentsLowSelectedItems.length !== 0) {
        this.invokeServiceForGetPoliciesDependetId(this.dependentsLowSelectedItems[0].kinshipId);
    }
  }

  invokeServiceForGetPoliciesDependetId(dependentId) {
    let subranch: FormArray;
    this.api.getSubranchCategoryByDependent(dependentId)
      .then(
        (response: any) => {
          console.log(response);
          if (response !== null && response.length > 0) {
            this.subranchCategoryList = response;
            subranch = new FormArray(this.subranchCategoryList.map(subranchs => this.addTopolicySubranchCategories(subranchs)));
            this.newRequestDependentCancellationForm.setControl('policySubranchCategories', subranch);
          } else {
            // this.policyList = [];
          }
        }, error => {

        }
      );
  }

}
