import {Component, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {regex} from '../../common/regex';
import {string} from '@amcharts/amcharts4/core';
import {CoverageModel} from './coverage-model';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-admin-special-coverage',
  templateUrl: './admin-special-coverage.component.html',
  styleUrls: ['./admin-special-coverage.component.css']
})
export class AdminSpecialCoverageComponent implements OnInit {

  coverageForm: FormGroup; // Formulario
  coverageModel: CoverageModel;
  modalRef: BsModalRef;
  coverageList: any = [];
  coverageCoincidenceList: any = [];
  // -Varibles para paginado de usuarios->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filters: any = {};
  branchList: any[]; // Tipos de ramos
  subranchList: any[]; // Tipos de subramos
  branchListDetail: any[]; // Tipos de ramos Detail
  subranchListDetail: any[]; // Tipos de subramos Detail
  branch: number;
  confirmSave: number;
  listChange: boolean;
  isView: boolean;

  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) {

    // Se inicializa el formulario
    this.coverageForm = new FormGroup(
      {
        nameCoverage: new FormControl(null, [
          Validators.maxLength(100),
          Validators.required,
          Validators.pattern(regex.alfanumerico)
        ]),
        descriptionCoverage: new FormControl(null, [
          Validators.maxLength(100),
          Validators.required,
          Validators.pattern(regex.alfanumerico)
        ]),
        subranchDetail: new FormControl(null, [
          Validators.required
        ]),
        branchDetail: new FormControl(null, [
          Validators.required
        ])
      }
    );
    this.invokeServicesGetBranches(false);
  }

  ngOnInit() {
    this.confirmSave = 0;
    this.isView = false;
    this.listChange = false;
    this.validateAccess.validateAccessCore(39).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.clearFiltersTable();
      }
    });
  }

  onChangeBranch() {
    this.subranchList = [];
    this.filters.idSubRamo = null;
    this.invokeServicesGetSubranches(false, this.filters.idRamo);
  }

  onChangeBranchDetail() {
    this.coverageCoincidenceList = [];
    this.confirmSave = 0;
    this.subranchListDetail = [];
    this.coverageModel.subranch = null;
    this.invokeServicesGetSubranches(true, this.coverageModel.branch);
  }
  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    this.subranchList = [];
    this.filters = {
      nameCoverage: '',
      idRamo: null,
      idSubRamo: null,
      page: 1,
      size: 10
    };
    this.validateAccess.validateAccessCore(39).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }

  /**
   * Función para obtener las coberturas especiales del sistema
   */
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    const ramo = this.filters.idRamo;
    if (ramo !== null) {
      this.filters.idRamo = Number(ramo);
    }
    const subramo  = this.filters.idSubRamo;
    if (subramo !== null) {
      this.filters.idSubRamo = Number(subramo);
    }
    this.api.getSpecialCoveragePagination(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.coverageList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.coverageList = this.paginationResponse.tList;
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.coverageList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }

  /**
   * Función para obtener las coberturas del sistema
   */
  loadFiltersTable() {
    this.validateAccess.validateAccessCore(39).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }

  /**
   * Función para ir al detalle de una cobertura
   */
  openDetailCoverage(template: TemplateRef<any>, coverageId: number, isView: boolean) {
    let accessCore = 0;
    if (coverageId === 0 && !isView) {
      accessCore = 40;
    } else if ( coverageId > 0 && !isView) {
      accessCore = 41;
    } else if ( coverageId > 0 && isView) {
      accessCore = 42;
    }
    this.validateAccess.validateAccessCore(accessCore).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.isView = isView;
        this.clearCoverageDetail();

        if (coverageId > 0) {
          const id = String(coverageId);
          this.invokeServicesGetCoverage(template, id);
          if (isView) {
            this.coverageForm.disable();
          }

        } else {
          this.coverageModel = new CoverageModel();
          this.invokeServicesGetBranches(true);
          this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
        }
      }
    });
  }

  private clearCoverageDetail() {
    this.confirmSave = 0;
    this.coverageForm.enable();
    this.coverageForm.reset();
    this.subranchListDetail = [];
    this.branchListDetail = [];
    this.coverageCoincidenceList = [];
  }

  /**
   * Carga los datos según la página seleccionada
   */
  pageChangedSpecial(event: PageChangedEvent): void {
    console.log(event);
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }

  onChangeName(event) {
    this.coverageCoincidenceList = [];
    this.confirmSave = 0;
  }
  /**
   * funcion que guarda una cobertura
   */
  validateCoincidence() {
    if (this.coverageForm.valid) {
      if (this.confirmSave === 0 ) {
        const name = {
          name: String(this.coverageModel.name).concat(' ', String(this.coverageModel.description)),
          idSubranch: String(this.coverageModel.subranch),
          idCoverage: Number(this.coverageModel.coverageId)
        };
        this.api.getCoincidence(name).then( // Si todo bien, se guarda
          (response: any) => {

            this.coverageCoincidenceList = response;
            this.confirmSave++;
            if ( this.coverageCoincidenceList.length === 0) {
              if (this.coverageModel.coverageId !== null && this.coverageModel.coverageId > 0) {
                this.editCoverage();
              } else {
                this.saveCoverage();
              }

            }
          }, error => {
            this.spinner.hide('sp');
            if (error.status === 500) {
              if (error.error.Error !== undefined) {
                this.toastr.error(error.error.message, 'Notificación');
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          }
        );
      } else if (this.confirmSave === 1) {
        if (this.coverageModel.coverageId !== null && this.coverageModel.coverageId > 0) {
          this.editCoverage();
        } else {
          this.saveCoverage();
        }
      }
    } else {
      this.coverageForm.controls.nameCoverage.markAsTouched();
      this.coverageForm.controls.descriptionCoverage.markAsTouched();
      this.coverageForm.controls.subranchDetail.markAsTouched();
      this.coverageForm.controls.branchDetail.markAsTouched();
    }
  }

  /**
   * funcion que guarda una cobertura
   */
  saveCoverage() {
    if (this.coverageForm.valid) {

      this.api.saveSpecialCoverage(this.coverageModel).then( // Si todo bien, se guarda
        (response: any) => {
          this.spinner.hide('sp');
          this.confirmSave = 0;
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.closeModalCoverage();
        }, error => {
          this.spinner.hide('sp');
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.error(error.error.message , 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
    } else {
      this.coverageForm.controls.nameCoverage.markAsTouched();
      this.coverageForm.controls.descriptionCoverage.markAsTouched();
      this.coverageForm.controls.subranchDetail.markAsTouched();
      this.coverageForm.controls.branchDetail.markAsTouched();
    }
  }

  /**
   * funcion que guarda una cobertura
   */
  editCoverage() {
    if (this.coverageForm.valid) {
      this.api.updateCoverage(this.coverageModel).then( // Si todo bien, se guarda
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.closeModalCoverage();
        }, error => {
          this.spinner.hide('sp');
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.error(error.error.message , 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
    } else {
      this.coverageForm.controls.nameCoverage.markAsTouched();
      this.coverageForm.controls.descriptionCoverage.markAsTouched();
      this.coverageForm.controls.subranchDetail.markAsTouched();
      this.coverageForm.controls.branchDetail.markAsTouched();
    }
  }

  invokeServicesGetBranches(isForDetail: boolean) {
    this.api.getBranchesForSpecialCoverage()
      .then(
        (response: any) => {
          if (isForDetail) {
            this.branchListDetail = response;
          } else {
            this.branchList = response;
          }
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Ramos', 'Notificación');
        }
      );
  }

  invokeServicesGetSubranches(isForDetail: boolean, idBranch: number) {
    if (idBranch === null) {
      idBranch = 0;
    }
    const branch = {
      id: String(idBranch)
    };
    this.api.getSubranches(branch)
      .then(
        (response: any) => {
          if (isForDetail) {
            this.subranchListDetail = response;
          } else {
            this.subranchList = response;
          }
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }

  invokeServicesGetCoverage(template: TemplateRef<any>, coverageId) {
    const coverage = {
      id: coverageId
    };
    this.api.getCoverageById(coverage)
      .then(
        (response: any) => {
         this.coverageModel = response;
         this.invokeServicesGetBranches(true);
         this.invokeServicesGetSubranches(true, this.coverageModel.branch);
         this.modalRef = this.modalService.show(template, {
           class: 'modal-lg',
           keyboard: false,
           backdrop: 'static',
           ignoreBackdropClick: true});
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }

  /**
   * funcion que oculta el pop up de coverage
   */
  closeModalCoverage() {
    this.clearFiltersTable();
    this.isView = false;
    this.clearCoverageDetail();
    this.modalRef.hide();
  }
}
