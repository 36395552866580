import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attachmentName'
})
export class AttachmentNamePipe implements PipeTransform {

  transform(userss: any [], searchName: string): any [] {
    if (!userss || !searchName) {
      return userss;
    }

    return userss.filter(any =>
      any.name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1);
  }

}
