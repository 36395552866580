import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-graph-net-premium-vs-deveng',
  templateUrl: './graph-net-premium-vs-deveng.component.html',
  styleUrls: ['./graph-net-premium-vs-deveng.component.css']
})
export class GraphNetPremiumVsDevengComponent implements OnInit {
  lineChartOptions: ChartOptions = {
    responsive: true
  };
  lineChartLabels: Label[] = [];
  lineChartType: ChartType = 'line';
  lineChartLegend  = true;
  lineChartPlugins  = [];
  lineChartData: ChartDataSets[] = [];
  lineChartColors = [
    {
      borderColor: 'rgba(68, 138, 166, 1)'
    },
    {
      borderColor: 'rgba(89, 89, 89, 1)'
    }
  ];

  private _groupId: number;
  private _subranchId: number;

  reportList: any = [];

  constructor(private api: ApiService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {


  }

  loadChart() {
    this.spinner.show('sp');
    this.api.getIncidentVsPrimaDev(this._groupId, this._subranchId)
      .then(
        (response: any) => {
          if (response == null) {
            this.reportList = [];
            this.spinner.hide('sp');
            return;
          }
          this.reportList = response;
          this.creatChart();
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.reportList = [];
          this.spinner.hide('sp');
        }
      );
  }

  creatChart() {
    const data = {
      data: [],
      label: 'Siniestralidad'
    };
    // Obtenemos las etiquetas
    this.reportList.incidents.forEach(X => {
      this.lineChartLabels.push(X.month);
      data.data.push(X.premium);
    });

    this.lineChartData.push(data);

    const dataPD = {
      data: [],
      label: 'Prima neta devengada'
    };
    // Obtenemos las etiquetas
    this.reportList.colletions.forEach(X => {
      dataPD.data.push(X.premium);
    });

    this.lineChartData.push(dataPD);

  }

  get groupId(): number {
    return this._groupId;
  }

  @Input()
  set groupId(value: number) {
    this._groupId = value;
    this.lineChartData = [];
    this.lineChartLabels = [];
    if (this._subranchId !== undefined) {
      this.loadChart();
    }

  }

  get subranchId(): number {
    return this._subranchId;
  }

  @Input()
  set subranchId(value: number) {
    this._subranchId = value;
    this.lineChartData = [];
    this.lineChartLabels = [];
    if (this._subranchId !== undefined) {
      this.loadChart();
    }
  }
}
