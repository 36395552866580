import { Component, OnInit, TemplateRef } from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {FormBuilder, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NgxSpinnerService} from "ngx-spinner";
import {ValidateAccessService} from "../validate-access.service";

// import {Component, OnInit, TemplateRef,ViewChild} from '@angular/core';

@Component({
  selector: 'app-admin-catalog',
  templateUrl: './admin-catalog.component.html',
  styleUrls: ['./admin-catalog.component.css']
})
export class AdminCatalogComponent implements OnInit {
  flag: number;
  private fieldArray: Array<any> = [];
  private newAttribute: any = {};
  private editAttribute: any = {};
  groupList: any = [];
  clientList: any = [];
  insuranceCompanyList: any = [];
  branchList: any = [];
  subBranchList: any = [];
  paymentFormList: any = [];
  paymentTypeList: any = [];
  taxList: any = [];
  coverageList: any = [];
  coverageSpecialList: any = [];
  holidaysList: any = [];
  modalRef: BsModalRef;
  editModalRef: BsModalRef;
  editModalRefEditHoliday: BsModalRef;
  editModalRefDeleteHoliday: BsModalRef;
  holidayId: number;
  roleId: number;
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  userLoginId;
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private modalService: BsModalService,
              public fb: FormBuilder,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) {
    this.userLoginId = Number(localStorage.getItem('user-id'));
  }

  catalogForm = this.fb.group({
    catalogs: ['groups', [Validators.required]]
  });

  ngOnInit() {
    this.flag = 0;
    this.roleId = Number(localStorage.getItem('roleId'));
  }

  goToBack() {
    this.router.navigate(['/admin/executive']);
  }

  loadCatalog(value) {
    console.log(value.catalogs);
    this.flag = value.catalogs;
    switch (this.flag) {
      case 1:
        this.invokeServiceLoadGroups();
        break;
      case 2:
        this.invokeServiceLoadClients();
        break;
      case 3:
        break;
      case 4:
        this.invokeServiceLoadBranch();
        break;
      case 6:
        this.invokeServiceLoadSubBranch();
        break;
      case 7:
        this.invokeServicePaymentForm();
        break;
      case 8:
        this.invokeServicePaymentType();
        break;
      case 9:
        this.invokeServiceTax();
        break;
      case 10:
        this.invokeServiceCoverage();
        break;
      case 11:
        this.invokeServiceCoverageSpecial();
        break;
      case 12:
        this.validateAccess.validateAccessCore(20).then((access: any) => {
          console.log('valor que regresa', access.accessCore);
          if (access.accessCore === 0) {
            this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
          } else {
            this.invokeServiceHolidays();
          }
        });
        break;
        case 13:
        // this.invokeServiceHolidays();
        break;
      case 14:
        break;
      case 15:
        break;
      case 21:
        console.log('endosos');
        break;
    }
  }

  /*+-------------------------------------------------------------------------------------------------------+
    |------------------------------------------|  G R U P O S |------------------------------------------|
    +-------------------------------------------------------------------------------------------------------+*/
  invokeServiceLoadGroups() {
    this.groupList = [
      {
        groupId: 1,
        groupName: 'GLOBANT'
      },
      {
        groupId: 2,
        groupName: 'QUARKSOFT'
      },
      {
        groupId: 3,
        groupName: 'ZENDERE'
      }
    ];
  }
  addFieldValue() {
    this.groupList.push(this.newAttribute);
    this.newAttribute = {};
  }
  openModalDeleteHolidays(template: TemplateRef<any>, data) {
    this.validateAccess.validateAccessCore(23).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.holidayId = data.holidayId;
        this.editModalRefDeleteHoliday = this.modalService.show(template);
      }
    });
  }
  openModalEditHolidays(template: TemplateRef<any>, data) {
    this.validateAccess.validateAccessCore(22).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.editAttribute = data;
        console.log('this.editAttribute');
        console.log(this.editAttribute);
        this.editModalRefEditHoliday = this.modalService.show(template);
      }
    });
  }
  addHoliday() {
    this.validateAccess.validateAccessCore(21).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        // se ocupa los atributos de newAttribute para envio de datos
        if ( this.newAttribute.rfc === null || this.newAttribute.rfc === undefined
          || this.newAttribute.name === null || this.newAttribute.name === undefined
          || this.newAttribute.status === null || this.newAttribute.status === undefined
        ) {
          this.toastr.warning('Favor de llenar todos los campos!', 'Notificación');
        } else {
          // tslint:disable-next-line:max-line-length
          const data = {dateValue: this.newAttribute.rfc.toUpperCase(), description: this.newAttribute.name.toUpperCase(), status: this.newAttribute.status};
          this.invokeServiceSaveHolidays(data);
        }
      }
    });
  }

  addHolidayFile() {
    const formDataXlsx = new FormData();
    formDataXlsx.append('fileXlsx', this.fileUploadXlsx);

    this.api.importHolidays(formDataXlsx).subscribe(
      responseInsured => {
        this.toastr.success('Se han importado los días festivos.', 'Notificación');
        this.invokeServiceHolidays();
        this.fileUploadXlsx = undefined;
        }, error => {

      });
  }
  downloadLayout(id) {
    this.spinner.show('sp');
    this.api.getLayoutFile(id)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',  'LAYOUT_HOLIDAYS.xlsx');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  deleteHolidays() {
    const data = {holidayId: this.holidayId};
    this.invokeServiceDeleteHolidays(data);

  }
  actualizarHolidays() {
    console.log('this.editAttribute');
    console.log(this.editAttribute);
    if(this.editAttribute.dateValue==="" || this.editAttribute.dateValue===null
      ||this.editAttribute.description ==="" || this.editAttribute.description ===null
      ||this.editAttribute.status ==="" || this.editAttribute.status ===null
    ){
      this.toastr.warning('Favor de llenar todos los campos!', 'Notificación');
    }else{
      this.invokeServiceUpdateHolidays(this.editAttribute);
    }

  }
  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
  }

  onChangeInputFileXlsx(e) {
    this.validateAccess.validateAccessCore(24).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.errorXlsx = {active: false, msg: ''};
        this.fileUploadXlsx = e.target.files[0];
        if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
          this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
          this.fileUploadXlsx = null;
        }
      }
    });
  }

  /*+-------------------------------------------------------------------------------------------------------+
    |------------------------------------------|  C L I E N T E S |------------------------------------------|
    +-------------------------------------------------------------------------------------------------------+*/
  invokeServiceLoadClients() {
    this.clientList = [{
      clientId: 1,
      rfc: 'KSQWE3241243D',
      name: 'AVANXO SA DE CV',
      typeClient: 'Moral'
    }];
  }

  /*+-------------------------------------------------------------------------------------------------------+
   |------------------------------------------|  A S E G U R A D O R A |------------------------------------------|
   +-------------------------------------------------------------------------------------------------------+*/
  invokeServiceLoadInsuranceCompany() {
    this.insuranceCompanyList = [{
      insuranceId: 1,
      rfc: 'KSQWE3241243D',
      name: 'SEGUROS ATLAS',
      imagePath: ''
    }];
  }

  /*+-------------------------------------------------------------------------------------------------------+
   |------------------------------------------|  R A M O S |------------------------------------------|
   +-------------------------------------------------------------------------------------------------------+*/
  invokeServiceLoadBranch() {
    this.branchList = [{
      branchId: 1,
      branchType: 'BENEFICIOS'
    }];
  }

  /*+-------------------------------------------------------------------------------------------------------+
   |------------------------------------------| S U B R A M O S |------------------------------------------|
   +-------------------------------------------------------------------------------------------------------+*/
  invokeServiceLoadSubBranch() {
    this.subBranchList = [{
      subBranchId: 1,
      subBranch: 'GMM GRUPO',
      branch: 'BENEFICIOS'
    }];
  }

  /*+-------------------------------------------------------------------------------------------------------+
   |------------------------------------------| F O R M A S P A G O |------------------------------------------|
   +-------------------------------------------------------------------------------------------------------+*/
  invokeServicePaymentForm() {
    this.paymentFormList = [{
      paymentFormId: 1,
      paymentform: 'CHEQUE'
    }];
  }


  /*+-------------------------------------------------------------------------------------------------------+
   |------------------------------------------| T I P O P A G O |------------------------------------------|
   +-------------------------------------------------------------------------------------------------------+*/
  invokeServicePaymentType() {
    this.paymentTypeList = [{
      typePaymentId: 1,
      typePayment: 'CONTADO'
    }];
  }

  /*+-------------------------------------------------------------------------------------------------------+
   |------------------------------------------| I M P U E S T O S |------------------------------------------|
   +-------------------------------------------------------------------------------------------------------+*/
  invokeServiceTax() {
    this.taxList = [{
      taxId: 1,
      tax: 'IVA 16%',
      taxValue: '.16'
    }];
  }

  /*+-------------------------------------------------------------------------------------------------------+
   |------------------------------------------| C O B E R T U R A S |------------------------------------------|
   +-------------------------------------------------------------------------------------------------------+*/
  invokeServiceCoverage() {
    this.coverageList = [{
      coverageId: 1,
      name: 'PLAN CONTRATADO',
      visible: 'SI',
      subBranch: 'GMM GRUPO'
    }];
  }

  /*+-------------------------------------------------------------------------------------------------------+
   |------------------------------------------| C O B E R T U R A S E S P E C I A L E S |------------------------------------------|
   +-------------------------------------------------------------------------------------------------------+*/
  invokeServiceCoverageSpecial() {
    this.coverageSpecialList = [{
      coverageId: 1,
      name: 'MATERNIDAD',
      subBranch: 'GMM GRUPO'
    }];
  }

   /*+-------------------------------------------------------------------------------------------------------+
     |------------------------------------------|  DIAS INHABILES |------------------------------------------|
     +-------------------------------------------------------------------------------------------------------+*/
   invokeServiceHolidays() {
      this.api.getHoliays().then((data: any) => {
        console.log('getHoliays');
        console.log(data);
        if (data != null) {
          this.holidaysList = data;
         }
       }, error => {
         console.error(error.status);
         console.error(error.statusText);
         console.error(error.message);
       });
   }
   invokeServiceSaveHolidays(data) {
      this.api.saveHoliays(data).then((data: any) => {
        console.log('getHoliays');
        console.log(data);
        if (data.holidayId != null) {
            this.toastr.success('Se ha agregado un nuevo día inhábil al catálogo ', 'Notificación');
            this.invokeServiceHolidays();
            this.newAttribute = {};
         }
       }, error => {
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }

       });
   }
   invokeServiceDeleteHolidays(data) {
      this.api.deleteHolidays(data).then((data: any) => {
          this.toastr.success('Se ha elimando correctamente.', 'Notificación');
          this.editModalRefDeleteHoliday.hide();
          this.invokeServiceHolidays();
         }, error => {
           this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
           console.error(error.status);
           console.error(error.statusText);
           console.error(error.message);
         });
      }
   invokeServiceUpdateHolidays(data) {
       this.api.updateHolidays(data).then((data: any) => {
         this.toastr.success('Se ha actualizado la información correctamente.', 'Notificación');
         this.editModalRefEditHoliday.hide();
         this.invokeServiceHolidays();
        }, error => {
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        });
   }
}
