import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../api.service';

import moment from 'moment';

@Component({
    selector: 'envio-programado',
    templateUrl: 'envio-programado.component.html'
})
export class EnvioProgramadoComponent implements OnInit {

    @Input()
    dynamicReportId: number;

    public onClose: Subject<any>;

    private tipo: string;

    dias = [];

    jobForm: FormGroup; // Formulario
    job: any;
    minDate = new Date();

    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: FormBuilder,
        private apiService: ApiService
    ) {
    }

    ngOnInit(): void {
        this.minDate.setDate(this.minDate.getDate());
        this.onClose = new Subject();

        for(let i = 1; i <= 31; i++) {
            this.dias.push({
                id: i,
                name: i
            });
        }

        this.loadJob();
    }

    loadJob(): void {
        this.apiService.getDynamicReportJobByDynamicReport({ dynamicReportId: this.dynamicReportId }).subscribe(response => {
            this.job = response ? response : {};
            this.tipo = this.job.type;

            this.createForm();
        },
        err => {
            console.log(err);
        });
    }

    createForm(): void {
        const dateTmp = this.job.dateExec ? moment(this.job.dateExec, 'YYYY-MM-DD HH:mm:ss') : moment();
        const dateExec = dateTmp.format('DD/MM/YYYY');
        const timeExec = { hour: +dateTmp.format('HH'), minute: +dateTmp.format('mm') }
        let value = this.job.value ? JSON.parse(this.job.value) : null;
        let lunes = null;
        let martes = null;
        let miercoles = null;
        let jueves = null;
        let viernes = null;
        let sabado = null;
        let domingo = null;
        if (this.tipo === 'SEMANALMENTE') {
            lunes = value.includes("L");
            martes = value.includes("M");
            miercoles = value.includes("MI");
            jueves = value.includes("J");
            viernes = value.includes("V");
            sabado = value.includes("S");
            domingo = value.includes("D");
        }
        this.jobForm = this.formBuilder.group({
            type: [this.job.type, Validators.required],
            value: [null],
            lunes: [lunes],
            martes: [martes],
            miercoles: [miercoles],
            jueves: [jueves],
            viernes: [viernes],
            sabado: [sabado],
            domingo: [domingo],
            dynamicReportsId: [this.dynamicReportId, Validators.required],
            dateExec: [dateExec, Validators.required],
            timeExec: [timeExec, Validators.required],
        });
    }

    submit(): void {
        const params = this.jobForm.value;
        if (params.type === 'SEMANALMENTE') {
            const value = [];
            if (params.lunes) {
                value.push('L');
            }
            if (params.martes) {
                value.push('M');
            }
            if (params.miercoles) {
                value.push('MI');
            }
            if (params.jueves) {
                value.push('J');
            }
            if (params.viernes) {
                value.push('V');
            }
            if (params.sabado) {
                value.push('S');
            }
            if (params.domingo) {
                value.push('D');
            }
            params.value = value;
        }

        const dateExec = moment(params.dateExec).format('YYYY-MM-DD') + ' ' + (params.timeExec.hour.length === 1 ? '0' + params.timeExec.hour : params.timeExec.hour) + ':' + (params.timeExec.minute.length === 1 ? '0' + params.timeExec.minute : params.timeExec.minute);
        params.dateExec = dateExec;
        params.value = JSON.stringify(params.value);

        const newParams: any = {
            type: params.type,
            value: params.value,
            dynamicReportsId: params.dynamicReportsId,
            dateExec: params.dateExec
        };

        if (this.job.idDynamicReportsJobs) {
            newParams.dynamicReportsJobsId = this.job.dynamicReportsJobsId;
            this.apiService.updateDynamicReportJob(newParams).subscribe(response => {
                this.onClose.next({ action: 'update' });
                this.bsModalRef.hide();
            },
            err => {
                console.log(err);
            });
        } else {
            this.apiService.saveDynaminReportJob(newParams).subscribe(response => {
                this.onClose.next({ action: 'save' });
                this.bsModalRef.hide();
            },
            err => {
                console.log(err);
            });
        }
    }

    close(): void {
        this.onClose.next({ action: 'close' });
        this.bsModalRef.hide();
    }

    selectTipo(tipo: string): void {
        this.tipo = tipo;
    }

}
