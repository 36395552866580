import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-attachment-flotilla-mdl',
  templateUrl: './attachment-flotilla-mdl.component.html',
  styleUrls: ['./attachment-flotilla-mdl.component.css']
})
export class AttachmentFlotillaMdlComponent implements OnInit {

  modalRef: BsModalRef;
  dataToSendIssuePolicy: any = {};
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  public fileUploadZip: File;
  errorZip = {active: false, msg: ''};
  fileTypesZip: string[] = ['application/zip', 'application/x-zip-compressed'];
  responseImportInsured: any = [];
  insuredProcess: number;
  insuredUnprocessed: number;
  private _importMassive: boolean;
  branchId: string;
  validationPolicy: any = {};

  catGenders: any = [];
  genderSelected;
  groupListReceipt: any = [];
  subgroup: any;
  group: any;
  statusList: any = [];
  branchList: any = [{id: 1, name: 'BENEFICIOS'}, {id: 3, name: 'AUTOS'}];
  branchIdSelected: any;
  receiptStatusId: any = 0;

  constructor(private modalService: BsModalService,
              private toastr: ToastrService,
              private shared: SharedService,
              private api: ApiService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) {
  }

  ngOnInit() {
    this.branchIdSelected = 3;

  }


  openModal(template: TemplateRef<any>) {
    this.validateAccess.validateAccessCore(67).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.spinner.show('sp');
        this.getGroups();
        this.getStatusReceipts();
        this.loadInitialModal();
        this.modalRef = this.modalService.show(template);
        this.spinner.hide('sp');
      }
    });
  }

  sendReceipt() {
    console.log(this.fileUploadXlsx);

    if (this.fileUploadXlsx === undefined) {
      this.toastr.warning('DEBE DE CARGAR EL LAYOUT DE SUS AUTOS', 'NOTIFICACIÓN');
      return;
    }

    this.spinner.show('sp');
    this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
    const formDataXlsx = new FormData();
    formDataXlsx.append('fileXlsx', this.fileUploadXlsx);

    if (this.fileUploadZip !== undefined) {
      formDataXlsx.append('fileCompressed', this.fileUploadZip);
    }
    this.api.postMassiveReceipts(formDataXlsx, Number(localStorage.getItem('policy-id')))
      .then( // Si todo bien, se guarda
        (response: any) => {
          this.responseImportInsured = response;
          this.toastr.success('EL PROCESO DE IMPORTACIÓN HA INICIADO EN CUANTO TERMINE SE LE NOTIFICARA', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.router.navigate(['/admin/executive']);
        }, error => {
          console.error();
          this.modalRef.hide();
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
          this.spinner.hide('sp');
        }
      );
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  onChangeInputFileXlsx(e) {
    this.errorXlsx = {active: false, msg: ''};
    this.fileUploadXlsx = e.target.files[0];
    // console.log(this.fileUploadXlsx);
    if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
      this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  onChangeInputFileZip(e) {
    this.errorZip = {active: false, msg: ''};
    this.fileUploadZip = e.target.files[0];
    if (0 > this.fileTypesZip.indexOf(this.fileUploadZip.type)) {
      this.errorZip = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  loadInitialModal() {
    this.fileUpload = undefined;
    this.fileUploadXlsx = undefined;
    this.fileUploadZip = undefined;
  }

  clearGroup() {
    this.subgroup = undefined;
  }

  clearClients() {
    this.subgroup = undefined;
  }

  getStatusReceipts() {
    this.api.getStatusReceipts().then((data: any) => {
      this.statusList = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupListReceipt = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  downloadReceipsByPolicyId() {
    this.spinner.show('sp');
    this.api.downloadReceiptByGroupId(this.group, this.receiptStatusId, this.branchIdSelected).then((response: any) => {
      if (response == null) {
        this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
        this.spinner.hide('sp');
        return;
      } else {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download',  'RECEIPTS.xlsx');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      }
      this.spinner.hide('sp');
    }, error => {
      this.toastr.error('Su solicitud no se ha realizado de forma exitosa, comuniquese con el Administrador', 'Notificación');
      this.spinner.hide('sp');
    });
  }

  @Input()
  get importMassive(): boolean {
    return this._importMassive;
  }

  set importMassive(value: boolean) {
    this._importMassive = value;
  }
}
