import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {regex} from '../../common/regex';
import {Action, RolModel} from './rol-model';
import {Group} from '../admin-users-detail/user-model';
import {SharedService} from '../shared-service.service';
import {number} from '@amcharts/amcharts4/core';

@Component({
  selector: 'app-admin-role-detail',
  templateUrl: './admin-role-detail.component.html',
  styleUrls: ['./admin-role-detail.component.css']
})
export class AdminRoleDetailComponent implements OnInit {

  rolForm: FormGroup; // Formulario
  rolModel: RolModel; // Modelo de Usuario
  processList: any[]; // Lista de módulos
  accionsList: any[]; // Lista de acciones
  acctionListSelected: Array<Action>; // Lista de acciones seleccionadas
  operationUser: number; // Si es edicion o vista
  roleId: number;
  moduleSelected: string;
  idModuleSelected: number;
  emptyList: boolean;

  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private spinner: NgxSpinnerService) {

    // Se inicializa el formulario
    this.rolForm = new FormGroup(
      {
        name: new FormControl(null, [
          Validators.maxLength(100),
          Validators.required,
          Validators.pattern(regex.alfanumerico)
        ]),
        description: new FormControl(null, [
          Validators.maxLength(100),
          Validators.required,
          Validators.pattern(regex.alfanumerico)
        ]),
        module: new FormControl(),
        actionSelected: new FormControl()
      }
    );
    this.invokeGetProcess();

    this.operationUser = Number(localStorage.getItem('operationUser'));
    this.roleId = Number(localStorage.getItem('role-id'));
  }

  ngOnInit() {
    this.rolModel = new RolModel();
    this.acctionListSelected = new Array<Action>();

    if (this.operationUser === 1 || this.operationUser === 2) { // 1 y 2 son Edición o View
      this.inokeServiceRole(this.roleId);
      if (this.operationUser === 2 ) {
        this.rolForm.disable();
      }
    }

    this.emptyList = false;
  }
  /**
   * Retorna al listado
   */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/catalog']);
  }

  /**
   * Al cambiar el proceso, invocar las acciones
   */
  onChangeModule(event) {
    if(event !== undefined) {
      this.moduleSelected = event.name;
      this.idModuleSelected = Number(event.id);
      this.invokeGetAccionsByProcess(this.idModuleSelected);
    } else {
      this.moduleSelected = '';
      this.idModuleSelected = -1;
      this.accionsList = [];
    }
  }

 /**
  * Invoca la lista de acciones por idProcess
  */
  invokeGetAccionsByProcess(idProcess) {
    this.spinner.show('sp');
    const process = {
      id: idProcess
    };

    this.api.getAccionsByProcess(process)
      .then(
        (response: any) => {
          this.accionsList = response;
          if (this.accionsList.length === 0) {
            this.emptyList = true;
          } else {
            this.emptyList = false;
          }
          this.cleanActionsSelected(this.moduleSelected); // Vamos a eliminar los que ya están asignados
          this.spinner.hide('sp');
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ocurrió un problema al cargar el catálogo de acciones', 'Notificación');
        }
      );
  }

  /**
   * Invoca la lista de procesos
   */
  invokeGetProcess() {
    this.api.getProcesoList()
      .then(
        (response: any) => {
          this.processList = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de acciones', 'Notificación');
        }
      );
  }
  /**
   * Invoca el servicio de roles
   */
  inokeServiceRole(roleId) {
    const role = {
      id: roleId
    };

    this.api.getRolById(role)
      .then(
        (response: any) => {
          this.rolModel = response;

          if (this.rolModel.actions === null) {
            this.rolModel.actions = new Array<Action>();
          }
          this.acctionListSelected = [];
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el rol', 'Notificación');
        }
      );
  }

  /**
   * Se agrega las acciones seleccionadas
   */
  onActionsSelected() {
    this.acctionListSelected.map(ITEM => {
      let exist = false;
      if (this.rolModel.actions.length !== 0) { // Si la lista está vacía, se agrega la acción

        this.rolModel.actions.some(ACTION => {
          if (ITEM.idAction === ACTION.idAction) { // Buscar si existe
            exist = true;
            return;
          }
        });

        if (!exist) { // Si no exist, se agrega
          this.rolModel.actions.push(ITEM);
        }

      } else {
        this.rolModel.actions.push(ITEM);
      }
    });

    this.cleanActionsSelected(this.moduleSelected);
  }
  /**
   * Elimina las accione que ya estén asignadas
   */
  cleanActionsSelected(module) {
    const indexSelected = [];
    const acctionsByModule =  this.rolModel.actions.filter((ITEM) => ITEM.process === module);

    acctionsByModule.map((ITEM) => {
      this.accionsList.some((ACCION, index) => {
        if (Number(ACCION.idAction) === Number(ITEM.idAction)) {
          this.accionsList.splice(index, 1);
          return;
        }
      });
    });
    this.acctionListSelected = [];
  }
  /**
   * Invoca las acciones del proceso seleccionado
   */
  onDeleteAccionFromRol(indexAction, module: string)  {
    this.rolModel.actions.splice(indexAction, 1);
    if (module === this.moduleSelected) {
      this.invokeGetAccionsByProcess(this.idModuleSelected);
    }
  }
  /**
   * Función que agrega las acciones seleccionadas a la lista
   */
  onSelectedRow(action) {
    if (action.selected) {
      this.acctionListSelected.push(action);
    } else {
      const index = this.acctionListSelected.findIndex(ACCION => ACCION.idAction === action.idAction);
      this.acctionListSelected.splice(index, 1);
    }
  }
  /**
   * Función que guarda un rol
   */
  saveRol() {
    if (this.rolForm.valid) { // Validación del formulario
      if (this.rolModel.actions.length === 0) { // Validar si hay acciones
        this.toastr.warning('Es necesario agregar al menos una acción', 'Notificación');
      } else {
        this.api.saveRol(this.rolModel)
          .then(
            (response: any) => {
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.router.navigate(['/admin/role']);
            }, error => {
              if (error.status === 500) {
                if (error.error.message !== undefined) {
                  this.toastr.warning(error.error.message, 'Notificación');
                } else {
                  this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                }
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            }
          );
      }
    } else {
      this.rolForm.controls.name.markAsTouched();
      this.rolForm.controls.description.markAsTouched();
    }
  }
  /**
   * Función que actualiza un rol
   */
  updateRol() {
    if (this.rolForm.valid) { // Validación del formulario
      if (this.rolModel.actions.length === 0) { // Validar si hay subgrupos
        this.toastr.warning('Es necesario agregar al menos una acción', 'Notificación');
      } else {
        this.api.updateRol(this.rolModel)
          .then(
            (response: any) => {
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.router.navigate(['/admin/role']);
            }, error => {
              if (error.status === 500) {
                if (error.error.message !== undefined) {
                  this.toastr.warning(error.error.message, 'Notificación');
                } else {
                  this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                }
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            }
          );
      }
    } else {
      this.rolForm.controls.name.markAsTouched();
      this.rolForm.controls.description.markAsTouched();
    }
  }
}
