import {Component, NgModule, OnInit, TemplateRef, ViewChildren} from '@angular/core';
import {CoverageModel} from '../admin-car-coverage/coverage-model-autos';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {DualListComponent} from 'angular-dual-listbox';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {regex} from '../../common/regex';
import {ITreeOptions, TreeComponent, TreeNode} from '@circlon/angular-tree-component';
import * as _ from 'lodash';
import {ValidateAccessService} from "../validate-access.service";
@Component({
  selector: 'app-admin-package-coverage',
  templateUrl: './admin-package-coverage.component.html',
  styleUrls: ['./admin-package-coverage.component.css']
})
export class AdminPackageCoverageComponent implements OnInit {
  tree: any;
  insurancesList: any[]; // Tipos de aseguradoras
  insuranceListDetail: any[]; // Tipos de aseguradoras
  packageForm: FormGroup; // Formulario
  coverageForm: FormGroup; // Formulario Cobertura
  insurance: any;
  packageList: any[];
  filters: any = {};
  branchList: any[]; // Tipos de ramos
  subranchList: any[]; // Tipos de subramos
  branchListDetail: any[]; // Tipos de ramos Detail
  subranchListDetail: any[]; // Tipos de subramos Detail

  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 5; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgCurrentPage = 1; // Página actual
  packageListPaginated: any = [];
  pgTotalItems: number; // Total de registros
  paginationResponse: any = {};

  idBranch = 3;
  packageModel: any = {};
  modalRef: BsModalRef;
  modalRefCoverage: BsModalRef;
  modalRefOtherValue: BsModalRef;
  coverageCoincidenceList: any = [];
  dataSourcesCoverages: Array<CoverageModel>;
  dataSourcesCoveragesEmmision: Array<CoverageModel>;
  format = { add: 'ASIGNAR COBERTURA', remove: 'QUITAR COBERTURA', all: 'SELECCIONAR TODOS', none: 'DESELECCIONAR',
    direction: DualListComponent.LTR, draggable: true };
  addNewCoverage: true;
  coverageList: any = [];
  coverageListSelected: any = [];
  key: any;
  display: any;
  keyEmmision: any;
  displayEmmision: any;
  branch: any;
  subranch: any;
  isView: boolean;
  coverageEmmision: any = [];

  // Variables de coberturas
  isParent: boolean;
  dropdownSettingsGroups: any = {};
  dropdownSettingsInsurance: any = {};
  nodes = [ ];

  options: ITreeOptions = {
    displayField: 'name',
    useVirtualScroll: false,
    nodeHeight: 50,
    allowDrag: false,
    allowDrop: false
  };

  selectedNode = {
    id: '',
    name: '',
    value: '',
    level: '',
    open: false,
    meditionType: 'PESOS MEXICANOS',
    children: []
  };
  showEdit: boolean;
  confirmSave: number;
  isText: boolean;
  typeList: any = [];
  optionsTemplate: {};

  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private validateAccess: ValidateAccessService) { }

  ngOnInit() {
    this.validateAccess.validateAccessCore(53).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.typeList = [{id: 1, name: 'PORCENTAJE'},
          {id: 2, name: 'UMAM'},
          {id: 3, name: 'USD'},
          {id: 4, name: 'PESOS MEXICANOS'},
          {id: 5, name: 'TEXTO'}];
        this.isText = false;
        this.invokeServicesGetSubranches(false);
        this.getInsuranceCompanies(false);
        this.clearFiltersTable();
        this.key = 'idCoverage';
        this.display = 'nameCoverage';
        this.keyEmmision = 'idCoverage';
        this.displayEmmision = 'name';
        this.typeaheadOnSelectPackage('TODO');
        this.typeaheadOnSelectPackage('SELECTED');
      }
    });
  }

  invokeServicesGetSubranches(isForDetail: boolean) {
    const branch = {
      id: String(this.idBranch)
    };
    this.api.getSubranches(branch)
      .then(
        (response: any) => {
          if (isForDetail) {
            this.subranchListDetail = response;
          } else {
            this.subranchList = response;
          }
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }

  getInsuranceCompanies(isForDetail: boolean) {
    this.api.getInsuranceCompaniesCar().then((data: any) => {
      if (isForDetail) {
        this.insuranceListDetail = data;
      } else {
        this.insurancesList = data;
      }
    }, error => {
      this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
    });
  }

  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    this.subranchList = [];
    this.filters = {
      namePackage: '',
      idInsurance: null,
      page: 1,
      size: 10
    };
    this.validateAccess.validateAccessCore(53).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }

  /**
   * Función para obtener las coberturas del sistema
   */
  loadFiltersTable() {
    this.validateAccess.validateAccessCore(53).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }

  /**
   * Función para obtener las coberturas especiales del sistema
   */
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.pgCurrentPage = page;
    const insurance = this.filters.idInsurance;
    if (insurance !== null) {
      this.filters.idInsurance = Number(insurance);
    }
    this.api.getPackageCoverageCarPagination(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.packageListPaginated = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.packageListPaginated = this.paginationResponse.tList;
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.packageListPaginated = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }

  createFormNew() {
    // Se inicializa el formulario
    this.packageForm = new FormGroup(
      {
        idPackage: new FormControl({value: 0, disabled: false}),
        name: new FormControl(null, [
          Validators.maxLength(100),
          Validators.required,
          Validators.pattern(regex.alfanumerico)
        ]),
        status: new FormControl(true),
        description: new FormControl(null, [
          Validators.maxLength(300),
          Validators.required,
          Validators.pattern(regex.alfanumerico)
        ]),
        idInsurance: new FormControl(null, [
          Validators.required
        ]),
        coveragePackages: this.formBuilder.array([])
      }
    );
  }

  onDestinationChange(evento) {
    console.log(evento);
    // this.coveragePackages.push(this.newCoverage(evento));
  }

  /**
   * Función para ir al detalle de una cobertura
   */
  openDetailCoverage(template: TemplateRef<any>, coverageId: number, isView: boolean) {
    let accessCore = 0;
    if (coverageId === 0 && !isView) {
      accessCore = 54;
    } else if(coverageId > 0 && !isView) {
      accessCore = 55;
    } else {
      accessCore = 56;
    }
    this.validateAccess.validateAccessCore(accessCore).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.createFormNew();
        this.isView = isView;
        this.invokeServicesGetSubranches(true);
        this.getInsuranceCompanies(true);

        if (coverageId > 0) {
          const id = String(coverageId);
          this.invokeServicesGetPackageCoverage(template, id);
          if (isView) {
            this.packageForm.disable();
          }
        } else {
          // tslint:disable-next-line:max-line-length
          this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
        }
      }
    });
  }

  invokeServicesGetPackageCoverage(template: TemplateRef<any>, coverageId) {
    const coverage = {
      idPackage: Number(coverageId)
    };
    this.api.getCoverageFromPackageById(coverage)
      .then(
        (response: any) => {
          this.packageModel = response;
          this.createFormPackage();
          this.modalRef =
            this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }


  onChangeInsurance() {
    if (this.packageForm.get('idInsurance').value !== null ) {
      this.invokeCoverages();
      this.invokeGetCoverageEmission(this.packageForm.get('idInsurance').value);
    }
  }

  invokeCoverages() {
    this.api.getCoverageCarBySubranchInsurance(this.packageForm.value)
      .then(
        (response: any) => {
          this.coverageList = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }

  /**
   * funcion que oculta el pop up de coverage
   */
  closeModalCoverage() {
    this.clearFiltersTable();
    this.coverageListSelected = [];
    this.isView = false;
    this.clearPackageDetail();
    this.modalRef.hide();
  }

  private clearPackageDetail() {
    this.packageForm.enable();
    this.packageForm.reset();
    this.subranchListDetail = [];
    this.insuranceListDetail = [];
  }

  typeaheadOnSelectPackage(e) {
  }

  savePackage() {
    if (this.packageForm.valid) {
      if (this.coverageListSelected.length === 0) {
        this.toastr.warning('Es necesario agregar coberturas al paquete', 'Notificación');
        return;
      } else {
          this.coveragePackages.clear();
          this.getCoveragetoForm();
          this.spinner.show('sp');
          this.api.savePackageCoverageCar(this.packageForm.value).then(
            (response: any) => {
              this.spinner.hide('sp');
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.closeModalCoverage();
            }, error => {
              this.spinner.hide('sp');
              if (error.status === 500) {
                if (error.error.Error !== undefined) {
                  this.toastr.error(error.error.message, 'Notificación');
                } else {
                  this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                }
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            }
          );
      }

    } else {
      this.packageForm.controls.idInsurance.markAsTouched();
      this.packageForm.controls.name.markAsTouched();
      this.packageForm.controls.description.markAsTouched();
    }
  }

  editPackage() {
    if (this.packageForm.valid) {
      if (this.coverageListSelected.length === 0) {
        this.toastr.warning('Es necesario agregar coberturas al paquete', 'Notificación');
        return;
      } else {
        this.coveragePackages.clear();
        this.getCoveragetoForm();
        this.spinner.show('sp');
        this.api.updatePackageCoverageCar(this.packageForm.value).then(
          (response: any) => {
            this.spinner.hide('sp');
            this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
            this.closeModalCoverage();
          }, error => {
            this.spinner.hide('sp');
            if (error.status === 500) {
              if (error.error.Error !== undefined) {
                this.toastr.error(error.error.message, 'Notificación');
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          }
        );
      }

    } else {
      this.packageForm.controls.idInsurance.markAsTouched();
      this.packageForm.controls.name.markAsTouched();
      this.packageForm.controls.description.markAsTouched();
    }
  }

  getCoveragetoForm() {
    this.coverageListSelected.map( coverage => { // Se recorre el nodo
      this.coveragePackages.push(this.newCoverage(coverage));
    });
  }

  get coveragePackages(): FormArray {
    return this.packageForm.get('coveragePackages') as FormArray;
  }

  newCoverage(coverage): FormGroup {
    return this.formBuilder.group({
      idPackageCoverage: new FormControl(coverage.idPackageCoverage),
      packageId: new FormControl(this.packageForm.controls.idPackage.value),
      idCoverage: new FormControl(coverage.idCoverage),
      nameCoverage: new FormControl(coverage.nameCoverage),
      mandatory: new FormControl(coverage.mandatory),
      level: new FormControl(coverage.level)
    });
  }

  createFormPackage() {
    // Se inicializa el formulario

    this.packageForm.controls.idPackage.setValue(this.packageModel.idPackage);
    this.packageForm.controls.name.setValue(this.packageModel.name);
    this.packageForm.controls.status.setValue(this.packageModel.status);
    this.packageForm.controls.description.setValue(this.packageModel.description);
    this.packageForm.controls.idInsurance.setValue(this.packageModel.idInsurance);
    this.coverageListSelected = this.packageModel.coveragePackages;
    this.onChangeInsurance();
  }

 // Código nueva cobertura
  /**
   * Función para ir al detalle de una cobertura
   */
  openNewCoverage(template: TemplateRef<any>, coverageId: number) {
    this.addNewCoverage = true;
    this.confirmSave = 0;
    this.createFormNewCoverage();
    this.modalRefCoverage =
      this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }

  createFormNewCoverage() {
    // Se inicializa el formulario
    this.coverageForm = new FormGroup(
      {
        idCoverage: new FormControl({value: 0, disabled: false}),
        name: new FormControl(null, [
          Validators.maxLength(100),
          Validators.required,
          Validators.pattern(regex.alfanumericoConGuiones)
        ]),
        activate: new FormControl(true),
        description: new FormControl(null, [
          Validators.maxLength(300),
          Validators.required,
          Validators.pattern(regex.alfanumericoConGuiones)
        ]),
        idInsurance: new FormControl(this.packageForm.controls.idInsurance.value, [
          Validators.required
        ]),
        idBranch: new FormControl(this.idBranch),
        carOptionsArrayList: this.formBuilder.array([])
      }
    );
  }

  onChangeNewCoverage(event) {
    this.addNewCoverage = event;
  }

  /* TREE NODE */

  onActivateNode(event: any) {
    this.selectedNode = event.node.data;
    this.showEdit = true;
    // Do stuff with selected node
  }

  copyNode(node: any, tree) {
    const parentNode = node.realParent ? node.realParent : node.treeModel.virtualRoot;
    const copyNode = JSON.stringify(node.data);
    const newNode = JSON.parse(copyNode);
    this.deleteIds(newNode);
    parentNode.data.children.push(newNode);
    tree.treeModel.update();
  }

  deleteIds(node: TreeNode) {
    node.id = null;
    if (node.children) {
      node.children.forEach(child => this.deleteIds(child));
    }
  }

  canAddNode(node: any): boolean {
    const level = node.data.level.split('.');
    return level.length < 2;
  }

  addNode(node: any, tree, index) {
    const level = node.data.level + '.' + (index + 1);
    const newNode = {
      idCoverageCarOption: 0,
      name: 'new item',
      level};
    if (!node.data.children) {
      node.data.children = [];
    }
    node.data.children.push(newNode);
    tree.treeModel.update();

    const someNode = tree.treeModel.getNodeById(node.id);
    someNode.expand();
  }

  deleteNode(node, tree) {
    const parentNode = node.realParent ? node.realParent : node.treeModel.virtualRoot;
    // tslint:disable-next-line:only-arrow-functions
    _.remove(parentNode.data.children, function(child) {
      return child === node.data;
    });
    tree.treeModel.update();
    if (node.children !== null && node.children.length === 0) {
      node.hasChildren = false;
    }
  }

  nodeEdited() {
    this.showEdit = false;
  }

  editNode(template: TemplateRef<any>, node: any, tree) {
    this.selectedNode = node.data;
    if (!this.selectedNode.open) {
      if (this.selectedNode.meditionType === 'USD') {
        this.isText = false;
        this.optionsTemplate = {
          suffix: ' USD'
        };
      } else if (this.selectedNode.meditionType === 'PESOS MEXICANOS') {
        this.isText = false;
        this.optionsTemplate = {};
      } else if (this.selectedNode.meditionType === 'PORCENTAJE') {
        this.isText = false;
        this.optionsTemplate = {
          allowNegative: true,
          allowZero: true,
          decimal: '',
          precision: 0,
          prefix: '',
          suffix: ' %',
          thousands: '',
          nullable: true
        };
      } else if (this.selectedNode.meditionType === 'UMAM') {
        this.isText = false;
        this.optionsTemplate = {
          allowNegative: true,
          allowZero: true,
          decimal: '',
          precision: 0,
          prefix: '',
          suffix: ' UMAM',
          thousands: '',
          nullable: true
        };
      } else {
        this.isText = true;
        this.optionsTemplate = {};
      }
    } else {
      this.isText = true;
    }

    this.modalRefOtherValue =
      this.modalService.show(template, {
        class: 'modal-sm', keyboard: false, focus: false, ignoreBackdropClick: true, backdrop: false
      });

  }

  closeModal() {
    this.modalRefOtherValue.hide();
    this.selectedNode = {
      id: '',
      name: '',
      value: '',
      level: '',
      open: false,
      meditionType: 'PESOS MEXICANOS',
      children: []
    };
  }

  addNewValue() {

    /*if (this.selectedNode.name === '' || this.selectedNode.name === undefined) {
      this.toastr.warning('Es necesario agregar el nombre de la opción', 'Notificación');
      return;
    } else*/

    if (this.selectedNode.value === '' || this.selectedNode.value === undefined) {
      this.toastr.warning('Es necesario agregar el valor de la opción', 'Notificación');
      return;
    }
    const option = document.getElementById('valueCoverage') as HTMLInputElement | null;

    if (this.isParent) {
      const newNode = {
        name: option.value,
        value: this.selectedNode.value,
        meditionType: this.selectedNode.meditionType,
        level: String(this.nodes.length + 1),
        open: this.selectedNode.open,
        children: []};
      this.nodes.push(newNode);
      this.modalRefOtherValue.hide();
      this.isParent = false;
      this.selectedNode = {
        id: '',
        name: '',
        value: '',
        meditionType: 'PESOS MEXICANOS',
        level: '',
        open: false,
        children: []
      };
      this.tree.treeModel.update();
    } else {
      this.selectedNode.name = option.value;
      this.closeModal();
    }
  }



  addParentNode(tree: TreeComponent, template: TemplateRef<any>, isParent) {
    this.isParent = isParent;
    this.tree = tree;
    this.modalRefOtherValue =
      this.modalService.show(template, {class: 'modal-sm', keyboard: false, focus: false, ignoreBackdropClick: true, backdrop: false});
  }

  /**
   * funcion que guarda una cobertura
   */
  validateCoincidence() {
    if (this.coverageForm.valid) {
      if (this.nodes.length === 0) {
        this.toastr.warning('Es necesario agregar las opciones que contendrá la cobertura', 'Notificación');
        return;
      } else {
        if (this.confirmSave === 0) {
          this.carOptionsArrayList.clear();
          this.getOptionsNodeToModel(this.nodes);
          // this.coverageForm.controls.idInsurance.setValue(this.coverageForm.value.idInsurance[0].insuranceId);
          this.api.getCoincidenceCoverageCar(this.coverageForm.value).then(
            (response: any) => {
              this.coverageCoincidenceList = response;
              this.confirmSave++;
              if (this.coverageCoincidenceList.length === 0) { // Si todo bien, se guarda
                this.saveCoverage();
              }
            }, error => {
              this.spinner.hide('sp');
              if (error.status === 500) {
                if (error.error.Error !== undefined) {
                  this.toastr.error(error.error.message, 'Notificación');
                } else {
                  this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                }
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            }
          );
        } else if (this.confirmSave === 1) {
          this.saveCoverage();
        }
      }

    } else {
      this.coverageForm.controls.name.markAsTouched();
      this.coverageForm.controls.description.markAsTouched();
      this.coverageForm.controls.idInsurance.markAsTouched();
    }
  }

  get carOptionsArrayList(): FormArray {
    return this.coverageForm.get('carOptionsArrayList') as FormArray;
  }

  getOptionsNodeToModel(nodes: any[]) {
    nodes.map( nodo => { // Se recorre el nodo
      this.createCarOptionArrayList(nodo);
    });
  }

  createCarOptionArrayList(node) {
    this.carOptionsArrayList.push(this.newNode(node)); // Se agrega Nodo
    if (node.children !== undefined && node.children.length > 0) { // Si el nodo tiene hijos, obtenerlos
      this.getOptionsNodeToModel(node.children); // Se envían los hijos
    }
  }

  newNode(node): FormGroup {
    return this.formBuilder.group({
      idCoverageCarOption: new FormControl(node.idCoverageCarOption),
      coverageId: new FormControl(this.coverageForm.controls.idCoverage.value),
      value: new FormControl(node.value),
      open: new FormControl(node.open),
      meditionType: new FormControl(node.meditionType),
      description: new FormControl(node.name),
      order: new FormControl(node.level),
    });
  }

  /**
   * funcion que guarda una cobertura
   */
  saveCoverage() {
    if (this.coverageForm.valid) {

      this.api.saveCoverageCar(this.coverageForm.value).then( // Si todo bien, se guarda
        (response: any) => {
          this.spinner.hide('sp');
          this.confirmSave = 0;
          this.invokeCoverages();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.closeModalNewCoverage();
        }, error => {
          this.spinner.hide('sp');
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.error(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
    } else {
      this.coverageForm.controls.name.markAsTouched();
      this.coverageForm.controls.description.markAsTouched();
      this.coverageForm.controls.idInsurance.markAsTouched();
    }
  }

  /**
   * funcion que oculta el pop up de coverage
   */
  closeModalNewCoverage() {
    this.nodes = [];
    this.isView = false;
    this.clearCoverageDetail();
    this.modalRefCoverage.hide();
  }

  private clearCoverageDetail() {
    this.confirmSave = 0;
    this.coverageForm.enable();
    this.coverageForm.reset();
    this.coverageCoincidenceList = [];
    this.coverageEmmision = [];
    this.dataSourcesCoverages = [];
  }

  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
  }

  changeColorPagination() {
  }

  invokeGetCoverageEmission(idInsurance) {
    this.api.getCoverageCarEmmision(idInsurance).then(
      (response: any) => {
        this.coverageEmmision = response;
      }, error => {

        if (error.status === 500) {
          if (error.error.Error !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  onChangeMedition(e) {
    console.log(e);
    if (!this.selectedNode.open) {
      switch (e) {
        case 'PORCENTAJE': // 'PORCENTAJE' :
          this.isText = false;
          this.optionsTemplate = {
            allowNegative: true,
            allowZero: true,
            decimal: '',
            precision: 0,
            prefix: '',
            suffix: ' %',
            thousands: '',
            nullable: true
          };
          break;

        case 'UMAM': // 'UMAM' :
          this.isText = false;
          this.optionsTemplate = {
            allowNegative: true,
            allowZero: true,
            decimal: '',
            precision: 0,
            prefix: '',
            suffix: ' UMAM',
            thousands: '',
            nullable: true
          };
          break;

        case 'PESOS MEXICANOS': // 'PESOS MEXICANOS' :
          this.isText = false;
          this.optionsTemplate = {};
          break;

        case 'USD': // 'USD' :
          this.isText = false;
          this.optionsTemplate = {
            suffix: ' USD'
          };
          break;

        case 'TEXTO': // 'TEXTO' :
          this.isText = true;
          this.optionsTemplate = {
            allowNegative: true,
            allowZero: true,
            decimal: '',
            precision: 0,
            prefix: '',
            suffix: ' UMAM',
            thousands: '',
            nullable: true
          };
          break;

        default:
          break;
      }
    }
  }

  clickCheckActive() {
    if (this.selectedNode.open) {
      this.isText = true;
      this.selectedNode.value = '-1';
    } else {
      this.selectedNode.value = '0';
      this.onChangeMedition(this.selectedNode.meditionType);
    }
  }
}
