import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';

import {HttpClient, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';

const API_REST = environment.api_rest;
const API_REST1 = environment.api_rest_admin;
const API_REST4 = environment.api_user_external;
const API_REST_ADMIN = environment.api_rest_admin_surexs;
const API_REST_AMMIA = environment.api_rest_ammia_surexs;
const API_REST_AMMIA_WEB = environment.api_rest_ammia_web_surexs;

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) { }

  public getInsuredList(page, size, token) {

    // return this.http.get('http://localhost:7084/api/centralmapping/insured/'+page+'/'+size).pipe(
    return this.http.get(API_REST_ADMIN + '/centralmapping/insured/' + page + '/' + size).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, insuredList: []};
        } else {
          return res;
        }
      })
    );
  }
  public getInsuredListPaginated(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7010/api/core/directories/insured_client_group', data).subscribe( res => {
      this.http.post(`${API_REST_ADMIN}/directories/insured_client_group`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });

  }
  public getcountNotification() {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/notifications').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getNotificationUser() {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/notifications/user').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public putNotification(notificationId): Observable<any> {
    return this.http.put(API_REST +  '/notifications/' + notificationId, null);
  }
  public deleteNotification(notificationId): Observable<any> {
    return this.http.delete(API_REST +  '/notifications/' + notificationId);
  }

  public getReportsType(dataType) {
    return this.http.get(`${API_REST}/reports/${dataType}/types`);
  }

  public getReportFile(reportId) {
    return this.http.get(`${API_REST}/reports/downloads/${reportId}`, {responseType: 'arraybuffer'});
  }

  public getReportId(reportId) {
    return new Promise((response, error) => {
      this.http.get(API_REST  + '/reports/' + reportId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReporFilters(reportId, id) {
    return new Promise((response, error) => {
      this.http.get(API_REST  + '/reports/' + reportId + '/filters/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

 public getReporFiltersSearch(reportId, kinship, date, movement) {
    return this.http.get(`${API_REST}/reports/${reportId}/filters?kinship=${kinship}&date=${date}&movement=${movement}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, requestList: []};
        } else {
          return res;
        }
      })
    );
  }
  public getFileReceipt() {
    return this.http.get(`${API_REST}/receipts/downloads`, {responseType: 'arraybuffer'});
  }

  public getFileInsured() {
    return this.http.get(`${API_REST}/users/downloads/insured`, {responseType: 'arraybuffer'});
  }
  public getDataTypes() {
    return this.http.get(`${API_REST}/requests/types`);
  }

  public getCustomerList() {
    return this.http.get(`${API_REST}/general/customers`);
  }
  /* -----------------------------------------------------------------------------------------------------
  -------------------------------------- LOGIN-RECOVERY ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  public authentication(credentials) {
    const formParam = new HttpParams()
      .set('username', credentials.username)
      .set('password', credentials.password);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST1 + '/security/login', formParam, {
      // this.http.post('http://localhost:7019/api/core' + '/security/login', formParam, {
        headers: HEADERS
      }).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });

  }
  public postRecoveyPassword(email) {
    const formParam = new HttpParams()
      .set('email', email);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST1 + '/security/emails/recovery', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public validateToken(token) {
    const formParam = new HttpParams()
      .set('token', token);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST1 + '/security/validations/tokens', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public recoveypassword(newPassword, token) {
    const formParam = new HttpParams()
      .set('token', token)
      .set('newPassword', newPassword.password);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.put(API_REST1 + '/security/recovery/passwords', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public validatecode(code, userId) {
    const formParam = new HttpParams()
      .set('code', code)
      .set('userId', userId);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST1 + '/security/validations/codes', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
    -------------------------------------- PERFIL ---------------------------------------
    ----------------------------------------------------------------------------------------------------- */
  public getUserProfile() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/users').subscribe( res => {
       // this.http.get('http://localhost:7010/api' +  '/users').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateImageProfile(image): Observable<any> {
    return this.http.put(API_REST_ADMIN +  '/users/images/profiles', image);
  }

  public updatePassword(oldpass, newpass) {
    const formParam = new HttpParams()
      .set('oldPassword', oldpass)
      .set('newPassword', newpass);

    const HEADERS = new HttpHeaders().set('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/users/passwords', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
    -------------------------------------- BUSQUEDA ---------------------------------------
    ----------------------------------------------------------------------------------------------------- */

  public getFinderResultPolicyByFilter(param) {
    // return this.http.post(`http://localhost:7003/api/core/centralmapping/searchesByFilter`, param);
    return this.http.post(API_REST_ADMIN + `/centralmapping/searchesByFilter`, param);
  }

  /* -----------------------------------------------------------------------------------------------------
     -------------------------------------- D A S H B O A R D ---------------------------------------
     ----------------------------------------------------------------------------------------------------- */
  /*******Documentos******/
  public getPolicyIndicators() {
    return new Promise((response, error) => {
      // this.http.get(  'http://localhost:7006/api/policies/indicators').subscribe( res => {
      this.http.get(API_REST_ADMIN +  '/policies/indicators').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /******cobranza********/
  public getReceiptIndicators() {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN +  '/receipts/indicators').subscribe( res => {
      // this.http.get('http://localhost:7008/api/receipts/indicators').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  /* gestion de asegurado */
  public getClientGroupList() {
    // return this.http.get('http://localhost:7002/api/clients/groupByUser').pipe(
    return this.http.get(`${API_REST_ADMIN}/clients/groupByUser`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, groupClientList: []};
        } else {
          return res;
          // console.log('res');
          // console.log(res);
        }
      })
    );
  }
  public getRequestList(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/centralmapping/requestList', data ).subscribe( res => {
      // this.http.post('http://localhost:7084/api/centralmapping/requestList', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequestById(requestId, operationType) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/centralmapping/request/' + requestId + '/' + operationType).subscribe( res => {
      // this.http.get('http://localhost:7084/api/centralmapping/request/' + requestId + '/' + operationType).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  //   postCategoryPolicyData
  public getNumberPoliciesByIdsCLient(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7006/api/policies/clients', data).subscribe( res => {
      this.http.post(`${API_REST_ADMIN}/policies/clients`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postInsured(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7019/api/core/directories/insured', data).subscribe( res => {
      this.http.post(`${API_REST_ADMIN}/directories/insured/`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postInsuredCar(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7019/api/core/directories/insuredSimple', data).subscribe(res => {
      this.http.post(`${API_REST_ADMIN}/directories/insuredSimple/`, data).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  public postInsuredCarUser(policyId) {
      return new Promise((response, error) => {
        // this.http.put(`http://localhost:7006/api/core/policies/createUserByCar/${policyId}`, null).subscribe(res => {
        this.http.put(`${API_REST_ADMIN}/policies/createUserByCar/${policyId}`, null).subscribe( res => {
          response(res);
        }, (err) => {
          error(err);
        });
      });
    }

  public asociateUserBToCar(policyId) {
    return new Promise((response, error) => {
      // this.http.put(`http://localhost:7006/api/core/policies/asociateUserByToCar`, policyId).subscribe(res => {
      this.http.put(`${API_REST_ADMIN}/policies/asociateUserByToCar`, policyId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public createPolicyIssues(policyId) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7010/api/policies/createPolicyIssue/${policyId}`, data).subscribe(res => {
      this.http.post(`${API_REST_ADMIN}/policies/createPolicyIssue/${policyId}`, '').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  public postDependents(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7060/api/kinship', data).subscribe( res => {
      this.http.post(`${API_REST_ADMIN}/kinship`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPasswordUser() {
    return new Promise((response, error) => {

      // this.http.get('http://localhost:7010/api/directories/insured/password').subscribe( res => {
      this.http.get(`${API_REST_ADMIN}/directories/insured/password`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPoliciesInsured(insuredId) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:7006/api/policies/insured/' + insuredId).subscribe( res => {
      this.http.get(`${API_REST_ADMIN}/policies/insured/` + insuredId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPoliciesInsuredForDependent(insuredId) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:7006/api/policies/insuredForDependet/' + insuredId).subscribe( res => {
      this.http.get(`${API_REST_ADMIN}/policies/insuredForDependet/` + insuredId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPolicySubranchCategoryByDependent(insuredId) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:7006/api/policies/policySubranchCategoryByDependent/' + insuredId).subscribe( res => {
      this.http.get(`${API_REST_ADMIN}/policies/policySubranchCategoryByDependent/` + insuredId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  public getSubranchCategoryByDependent(dependentId) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:7006/api/policies/getSubranchCategoryByDependent/' + dependentId).subscribe( res => {
      this.http.get(`${API_REST_ADMIN}/policies/getSubranchCategoryByDependent/` + dependentId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPoliciesDependent(dependentId) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:7006/api/policies/depedent/' + dependentId).subscribe( res => {
      this.http.get(`${API_REST_ADMIN}/policies/depedent/` + dependentId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public lowDependents(data) {
    return new Promise((response, error) => {

      // this.http.put('http://localhost:7010/api/directories/insured', data).subscribe( res => {
      this.http.put(`${API_REST_ADMIN}/directories/insured`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getDependentsByInsured(insuredId) {
    return new Promise((response, error) => {
     // this.http.get('http://localhost:7084/api/centralmapping/dependents/'+insuredId).subscribe( res => {
     this.http.get(`${API_REST_ADMIN}/centralmapping/dependents/` + insuredId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getDataDependentsByInsured(data) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/kinship/data_kinship/`, data).subscribe( res => {
        // this.http.post('http://localhost:7060/api/kinship/data_kinship', data).subscribe( res => {

        response(res);
      }, (err) => {
        error(err);
      });
    });

  }
  public updateDataDependent(data) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/kinship/update_data_kinship/`, data).subscribe( res => {
      // this.http.post('http://localhost:7060/api/kinship/update_data_kinship', data).subscribe( res => {

        response(res);
      }, (err) => {
        error(err);
      });
    });

  }

  public postImportInsured(data) {
    // return this.http.post(`http://localhost:7013/api/core/policies/insureds/uploads`, data);
    return this.http.post(`${API_REST_ADMIN}/policies/insureds/uploads`, data);
  }

  public postImportCertificates(data) {
    // return this.http.post(`http://localhost:7013/api/policies/certificates/uploads`, data);
    return this.http.post(`${API_REST_ADMIN}/policies/certificates/uploads`, data);
  }

  public postImportRequest(data, subgroupId) {
    // return this.http.post(`http://localhost:7066/api/request/saveRequestTemp/${subgroupId}`, data);
    return this.http.post(`${API_REST_ADMIN}/request/saveRequestTemp/${subgroupId}`, data);
  }


  /* -----------------------------------------------------------------------------------------------------
    -------------------------------------- E V E N T O S -------------------------------------------------
    ----------------------------------------------------------------------------------------------------- */
  public getImportOverview(eventId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/events/import/overview/' + eventId).subscribe( res => {
      // this.http.get('http://localhost:7064/api/events/import/overview/' + eventId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportImportOverview(eventId) {
    return this.http.get(`${API_REST_ADMIN}/events/download/import/overview/${eventId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:7064/api/events/download/import/overview/${eventId}`, {responseType: 'arraybuffer'});
  }

  public getEventsTemp() {
      return new Promise((response, error) => {
       // this.http.get('http://localhost:7064/api/events').subscribe( res => {
       this.http.get(`${API_REST_ADMIN}/events`).subscribe( res => {

          response(res);
        }, (err) => {
          error(err);
        });
      });
    }
    public updateRead(data) {
      return new Promise((response, error) => {
        // this.http.put('http://localhost:7064/api/events',data).subscribe( res => {
        this.http.put(`${API_REST_ADMIN}/events`, data).subscribe( res => {
          response(res);
        }, (err) => {
          error(err);
        });
      });
    }


  /* -----------------------------------------------------------------------------------------------------
  -------------------------------------- P O L I Z A ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  public getGroups() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/groups?search=').subscribe( res => {
      // this.http.get('http://localhost:7030/api' +  '/groups?search=').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getSubGroups(groupId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/clients/groups/' + groupId).subscribe( res => {
      // this.http.get('http://localhost:7002/api' +  '/clients/groups/' + groupId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPoliciesByCliente(clientId) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN +  '/centralmapping/getPolicyByIdClient/' + clientId).subscribe( res => {
        // this.http.get('http://localhost:7084/api/centralmapping/getPolicyByIdClient/' + clientId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPoliciesRequestTemp(clientId, groupId) {
    // return this.http.get(`http://localhost:7066/api/request/policiesClients/${clientId}/${groupId}`);
    return this.http.get(`${API_REST_ADMIN}/request/policiesClients/${clientId}/${groupId}`);
  }

  public getPoliciesclients(clientId) {
    // return this.http.get(`http://localhost:7006/api/policies/policiesClients/${clientId}`);
    return this.http.get(`${API_REST_ADMIN}/policies/policiesClients/${clientId}`);
  }

  public getRequestNewInsured(request) {
    return new Promise((response, error) => {
        // this.http.post(`http://localhost:7066/api/core/request/getRequestNewInsured`, request).subscribe( res => {
        return this.http.post(`${API_REST_ADMIN}/request/getRequestNewInsured`, request).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequestInsuredCancellation(request) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7066/api/request/getRequestInsuredCancellation`, request).subscribe( res => {
        return this.http.post(`${API_REST_ADMIN}/request/getRequestInsuredCancellation`, request).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequestDependentsCancellation(request) {
    return new Promise((response, error) => {
        // this.http.post(`http://localhost:7066/api/core/request/getRequestDependentCancellation`, request).subscribe( res => {
        return this.http.post(`${API_REST_ADMIN}/request/getRequestDependentCancellation`, request).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequestNewDependents(requestDependent) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7066/api/request/getRequestNewDependents`, requestDependent).subscribe( res => {
      return this.http.post(`${API_REST_ADMIN}/request/getRequestNewDependents`, requestDependent).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }



  public getCategoriesByPolicyIdClientId(policyId, clientId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/centralmapping/getCategoryByPolicyIdClientId/' + policyId + '/' + clientId).subscribe( res => {
      // this.http.get('http://localhost:7084/api' +  '/centralmapping/getCategoryByPolicyIdClientId/' + policyId + '/' + clientId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postRequest(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7017/api/core/request/registrations`, data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/request/registrations`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postRequestGral(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7066/api/request/general`, data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/request/general`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateRequest(data) {
    return new Promise((response, error) => {
        // this.http.post(`http://localhost:7066/api/core/request/updateRequestFromCore`, data ).subscribe( res => {
        this.http.post(API_REST_ADMIN + `/request/updateRequestFromCore`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postRequestDependent(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7066/api/core/request/dependents/registrations`, data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/request/dependents/registrations`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateRequestDependent(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7066/api/core/request/updateRequestDependent`, data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/request/updateRequestDependent`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postRequestInsuredCancelation(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7066/api/request/insured/cancellations`, data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/request/insured/cancellations`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateRequestInsuredCancelation(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7066/api/core/request/insured/updateCancellations`, data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/request/insured/updateCancellations`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postRequestDependentCancelation(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7066/api/core/request/dependents/cancellations`, data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/request/dependents/cancellations`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  public updateRequestDependentCancelation(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7066/api/request/dependents/updateCancellationDependents`, data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/request/dependents/updateCancellationDependents`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getClient(clientId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/clients/client/' + clientId).subscribe( res => {
      // this.http.get('http://localhost:7002/api' +  '/clients/client/' + clientId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getClientUser(clientId) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/clients/clientUser', clientId).subscribe( res => {
      // this.http.post('http://localhost:7005/api/core' +  '/clients/clientUser', clientId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getGroupName(clientId) {
    return new Promise((response, error) => {
       this.http.post(API_REST_ADMIN +  '/clients/groupByClient', clientId).subscribe( res => {
      // this.http.post('http://localhost:7002/api' +  '/clients/groupByClient', clientId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getAppertainSubGroups(groupId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/clients/appertain_groups/' + groupId).subscribe( res => {
        // this.http.get('http://localhost:7002/api' +  '/clients/appertain_groups/' + groupId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getInsuranceCompanies() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/insurance-companies/searches?search=').subscribe( res => {
      // this.http.get('http://localhost:7004/api/insurance-companies/searches?search=').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getInsuranceCompaniesCar() {
    return new Promise((response, error) => {
        // this.http.get(API_REST_ADMIN +  '/insurance-companies/searches?search=').subscribe( res => {
         this.http.get(API_REST_ADMIN +  '/insurance-companies/searchescar?search=').subscribe( res => {
        // this.http.get('http://localhost:7004/api/insurance-companies/searchescar?search=').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getAgentKeysByInsuranceCompany(insuranceId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/insurance-companies/' + insuranceId + '/agents/keys').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getBranches() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/branches?search=').subscribe( res => {
      // this.http.get('http://localhost:7014/api' +  '/branches?search=').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getSubBranches(branchId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/branches/subbranches/' + branchId + '?search=').subscribe( res => {
      // this.http.get('http://localhost:7014/api' +  '/branches/subbranches/' + branchId + '?search=').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getTaxes() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/taxes?search=').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postPolicyData(data) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/policies`, data).subscribe( res => {
      // this.http.post('http://localhost:7006/api/core/policies', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postCategoryPolicyData(data, policyId) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/policies/${policyId}/categories`, data).subscribe( res => {
      // this.http.post('http://localhost:7006/api/core' + `/policies/${policyId}/categories`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public putCategoryPolicyData(data) {
    return new Promise((response, error) => {
      this.http.put(`${API_REST_ADMIN}/categories`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCategoriesPolicy(policyId, endorsementId?) {
    const policy = {
      policyId,
      endorsementId: endorsementId === null || endorsementId === undefined ? 0 : endorsementId
    };
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/policies/getCategoriesPolicy', policy ).subscribe( res => {
      // this.http.post('http://localhost:7006/api/core' +  '/policies/getCategoriesPolicy', policy ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCategoriesByPolicy(policyId) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/categories/getCategoriesByPolicies`, policyId).subscribe( res => {
      // this.http.post(`http://localhost:7016/api/core/categories/getCategoriesByPolicies`, policyId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUsersByPolicyAndCategory(data) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/centralmapping/getUsersByCategoryAndPolicy`, data).subscribe( res => {
      // this.http.post(`http://localhost:7084/api/core/centralmapping/getUsersByCategoryAndPolicy`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getDownloadUsersByPolicyAndCategory(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/core/centralmapping/getDownloadUsersByCategoryAndPolicy',  data, {responseType: 'arraybuffer'} ).subscribe( res => {
      this.http.post(`${API_REST_ADMIN}/centralmapping/getDownloadUsersByCategoryAndPolicy`, data, {responseType: 'arraybuffer'} ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCollectionsByPolicy(policyId) {
    return new Promise((response, error) => {
       this.http.get(API_REST_ADMIN +  '/policies/' + policyId + '/collections' ).subscribe( res => {
       // this.http.get('http://localhost:7006/api' +  '/policies/' + policyId + '/collections' ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCategoriesPolicyUserId(policyId, userId) {
    return new Promise((response, error) => {
       this.http.get(API_REST_ADMIN +  '/policies/' + policyId + '/categories/' + userId).subscribe( res => {
      // this.http.get('http://localhost:7006/api' +  '/policies/' + policyId + '/categories/' + userId ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public deleteCategoryPolicy(categoryId): Observable<any> {
    return this.http.delete(API_REST_ADMIN +  '/categories/' + categoryId);
  }
  public getCoverageSubbranch(id) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN +  '/catalogs_coverages/' + id).subscribe( res => {
      // this.http.get('http://localhost:7028/api/catalogs_coverages/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCoverageCategory(categoryId, endorsementId?) {
    const category = {
      categoryId,
      endorsementId: endorsementId === null || endorsementId === undefined ? 0 : endorsementId
    };
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/coverages/getCoveragesCategory', category).subscribe( res => {
       //  this.http.post('http://localhost:7018/api/core' +  '/coverages/getCoveragesCategory', category).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoverageIndividualDental(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/catalogs_coverages/individual/dental',  data).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core' +  '/catalogs_coverages/individual/dental',  data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getTariffByCategory(categoryId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/tariffs/' + categoryId).subscribe( res => {
        // this.http.get('http://localhost:7018/api' +  '/coverages/' + categoryId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postCoverageData(data, categoryId) {
    return new Promise((response, error) => {
         this.http.put(`${API_REST_ADMIN}/coverages/${categoryId}`, data).subscribe( res => {
        // this.http.put(`http://localhost:7018/api/coverages/${categoryId}`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postCoverageCarData(data, categoryId) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/policies_car/saveCoverageByCategory/${categoryId}`, data).subscribe( res => {
      // this.http.post(`http://localhost:7006/api/policies_car/saveCoverageByCategory/${categoryId}`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoverageCarFlotillaData(categoryId) {
    return new Promise((response, error) => {
        this.http.get(`${API_REST_ADMIN}/catalogs_coverages_car/getCoveragesCarByIdCategory/${categoryId}`).subscribe( res => {
        // this.http.get(`http://localhost:7028/api/catalogs_coverages_car/getCoveragesCarByIdCategory/${categoryId}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  public getAttachmentPolicy(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/policies/' + id + '/attachments').subscribe( res => {
      // this.http.get('http://localhost:7006/api' +  '/policies/' + id + '/attachments').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postAttachmentData(data) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/policies/attachments`, data).subscribe( res => {
      // this.http.post('http://localhost:7006/api'+`/core/policies/attachments`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getAttachmentFilepolicy(attachmentFileId) {
    return this.http.get(`${API_REST_ADMIN}/attachments/downloads/${attachmentFileId}`, {responseType: 'arraybuffer'});
  }

  public getAttachmentFileGeneric(data) {
    return this.http.post(`${API_REST_ADMIN}/attachments/downloads`, data, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:7000/api/core/attachments/downloads`, data, {responseType: 'arraybuffer'});
  }

  public getAttachmentByViewer(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/attachments/downloads/' + id + '/viewer').subscribe( res => {
      //  this.http.get('http://localhost:7012/api' + '/attachments/downloads/' + id + '/viewer').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getAttachmentByModalViewer(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN +  '/attachments/downloads/viewer', data).subscribe( res => {
        // this.http.post('http://localhost:7000/api/core' + '/attachments/downloads/viewer',  data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getTypePayments() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/payments?search=').subscribe( res => {
      // this.http.get('http://localhost:7038/api' +  '/payments?search=').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postReceiptData(data) {
    return new Promise((response, error) => {
        this.http.post(`${API_REST_ADMIN}/policies/receipts/automatics`, data).subscribe( res => {
        // this.http.post(`http://localhost:7013/api/core/policies/receipts/automatics`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postReceiptFlotillaData(data) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/policies/receipts/flotilla/automatics`, data).subscribe( res => {
      // this.http.post(`http://localhost:7013/api/core/policies/receipts/flotilla/automatics`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postReceiptFlotillaDataFlotilla(data) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/policies/receipts/flotilla/automatics/byId`, data).subscribe( res => {
        // this.http.post(`http://localhost:7013/api/core/policies/receipts/flotilla/automatics`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postReceiptCarData(data) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/policies/receipts/car/automatics`, data).subscribe( res => {
      // this.http.post(`http://localhost:7006/api/policies/receipts/car/automatics`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postReceiptDataRenews(data) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/policies/receipts/automatics/renewals`, data).subscribe( res => {
      // this.http.post('http://localhost:7006/api' + `/policies/receipts/automatics/renewals`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getReceiptPolicyCategory(policyId, categoryId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/policies/' + policyId + '/receipts/' + categoryId).subscribe( res => {
      // this.http.get('http://localhost:7013/api/core' +  '/policies/' + policyId + '/receipts/' + categoryId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReceiptPolicyCar(policyId, carId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/policies_car/' + policyId + '/receipts/' + carId).subscribe( res => {
      // this.http.get('http://localhost:7006/api' + '/policies_car/' + policyId + '/receipts/' + carId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPolicy(policyId, endorsementId?) {
    const policy = {
      policyId,
      endorsementId: endorsementId === undefined ? 0 : endorsementId === null ? 0 : endorsementId
    };

    return new Promise((response, error) => {
     this.http.post(API_REST_ADMIN +  '/policies/getPolicy', policy ).subscribe( res => {
     // this.http.post('http://localhost:7013/api/core' +  '/policies/getPolicy', policy ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public putChangeStatusPolicy(data) {
    const formParam = new HttpParams()
      .set('policyId', data.policyId)
      .set('statusId', data.statusId)
      .set('cancelationId', data.cancelationId === undefined || data.cancelationId === null ? 0 : data.cancelationId)
      .set('number', data.number);
    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/policies/status', formParam, {
      // this.http.put('http://localhost:7013/api/core' + '/policies/status', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getId() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/policies/generations/ids').subscribe( res => {
      // this.http.get('http://localhost:7006/api/policies/generations/ids').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public deleteAttachment(attachmentId) {
    return this.http.delete(API_REST_ADMIN +  '/attachments/' + attachmentId);
  }
  public deletePolicyAttachmentRelation(attachmentId): Observable<any> {
    return this.http.delete(API_REST_ADMIN +  '/policies_attachments/' + attachmentId);
    // return this.http.delete('http://localhost:7006/api' +  '/policies_attachments/' + attachmentId);
  }
  public postLayoutTariff(data, categoryId) {
    return this.http.post(`${API_REST_ADMIN}/tariffs/layouts?categoryId=${categoryId}`, data);
  }
  public putPolicyData(data) {
    return new Promise((response, error) => {
      this.http.put(`${API_REST_ADMIN}/policies`, data).subscribe( res => {
      // this.http.put('http://localhost:7006/api'+`/policies`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postChangePaymentPolicy(data) {
    return new Promise((response, error) => {
       this.http.post(`${API_REST_ADMIN}/catalogs_coverages/policies/changes/payment`, data).subscribe( res => {
       // this.http.post(`http://localhost:7028/api/catalogs_coverages/policies/changes/payment`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPolicyFile(policyId) {
    return this.http.get(`${API_REST_ADMIN}/policies/downloads/${policyId}`, {responseType: 'arraybuffer'});
    // return this.http.get('http://localhost:7006/api'+`/policies/downloads/${policyId}`, {responseType: 'arraybuffer'});
  }
  public postFilePolicy(data, policyId) {
    return this.http.post(`${API_REST_ADMIN}/policies/attachments/policymaster?policyId=${policyId}`, data);
    // return this.http.post('http://localhost:7006/api/core'+`/policies/attachments/policymaster?policyId=${policyId}`, data);
  }
  public deleteReceiptsPolicyCategory(categoryId, policyId) {
     return this.http.delete(API_REST_ADMIN +  '/receipts/categories/' + categoryId + '/' + policyId);
     // return this.http.delete('http://localhost:7013/api/core/receipts/categories/' + categoryId + '/' + policyId);
  }

  public deleteReceiptsPolicyCar(carId, policyId) {
    return this.http.delete(API_REST_ADMIN +  '/receipts/car/' + carId + '/' + policyId);
    // return this.http.delete('http://localhost:7008/api/receipts/car/' + carId + '/' + policyId);
  }
  public getAttachmentReceipt(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/receipts/attachments/' + id ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postAttachmentDataReceipt(data) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/receipts/attachments`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public putReceiptData(data) {
    return new Promise((response, error) => {
      // this.http.put('http://localhost:7008/api/receipts', data).subscribe( res => {
      this.http.put(`${API_REST_ADMIN}/receipts`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getClientsPolicy(policyId, endorsementId?) {
    const policy = {
      policyId,
      endorsementId: endorsementId === null || endorsementId === undefined ? 0 : endorsementId
    };

    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/policies/getSubgroupsPolicy', policy ).subscribe( res => {
      // this.http.get('http://localhost:7006/api/core' +  '/policies/getSubgroupsPolicy', policy ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postRenewalsPolicy(policyId) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/policies/renewals/' + policyId, null ).subscribe( res => {
      // this.http.post('http://localhost:7006/api' +  '/policies/renewals/' + policyId, null ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public sendMail(policyId) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/policies/mail/' + policyId, null ).subscribe( res => {
      // this.http.post('http://localhost:7006/api/policies/mail/' + policyId, null ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getExecutivesByPolicy(policyId, id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/policies/executives/' + policyId + '/' + id ).subscribe( res => {
      // this.http.get('http://localhost:7006/api' +  '/policies/executives/' + policyId + '/' + id ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getTypePaymentsPolicy() {
    return new Promise((response, error) => {
       this.http.get(API_REST_ADMIN +  '/policies/collections').subscribe( res => {
        // this.http.get('http://localhost:7006/api' +  '/policies/collections').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getExecutives(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/directories/executives/' + id ).subscribe( res => {
      // this.http.get('http://localhost:7010/api' +  '/directories/executives/' + id ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getSalesman() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/directories/salesman').subscribe( res => {
      // this.http.get('http://localhost:7010/api' +  '/directories/salesman').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postImportInsuredPolicy(data, clientId, policyId) {
    // return this.http.post(`http://localhost:7006/api/core/policies/insured/uploads?clientId=${clientId}&policyId=${policyId}`, data);
    return this.http.post(`${API_REST_ADMIN}/policies/insured/uploads?clientId=${clientId}&policyId=${policyId}`, data);
  }

  public postImportInsuredPolicySimply(data, clientId, policyId) {
    // return this.http.post(`http://localhost:7006/api/core/policies/simply/insured/uploads?clientId=${clientId}&policyId=${policyId}`, data);
    return this.http.post(`${API_REST_ADMIN}/policies/simply/insured/uploads?clientId=${clientId}&policyId=${policyId}`, data);
  }

  public postImportPolicyCar(data) {
    // return this.http.post(`http://localhost:7006/api/core/policies_car/cars/uploads`, data);
    return this.http.post(`${API_REST_ADMIN}/policies_car/cars/uploads`, data);
  }

  public validationIssuancePolicy(policyId) {
    const formParam = new HttpParams()
      .set('policyId', policyId);
    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
       this.http.post(API_REST_ADMIN + '/policies/validations/issues/policies', formParam, {
        // this.http.post('http://localhost:7006/api' + '/policies/validations/issues/policies', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public validationIssuancePolicySimple(policyId) {
    const formParam = new HttpParams()
      .set('policyId', policyId);
    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/validations/issues/policies/simple', formParam, {
      // this.http.post('http://localhost:7006/api/core' + '/policies/validations/issues/policies/simple', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postImportPolicyDamages(data) {

    return this.http.post(`${API_REST_ADMIN}/policies/damages`, data);
    // return this.http.post('http://localhost:7006/api/policies/damages', data);
  }
  /*
  SERVICIOS PARA REPORTES DE DOCUMENTOS
   */
  public getDocumentsPagination(data, page, size) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/receipts/documents?page=' + page + '&size=' + size, data ).subscribe( res => {
        // this.http.post('http://localhost:7013/api/core/receipts/documents?page=' + page + '&size=' + size, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getExcelFileDocuments(data) {
    return this.http.post(`${API_REST_ADMIN}/receipts/downloads/documents`, data, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:7013/api/core/receipts/downloads/documents`, data, {responseType: 'arraybuffer'});
  }
  public getLayoutFile(type) {
   return this.http.get(`${API_REST_ADMIN}/policies/layouts/populations/${type}`, {responseType: 'arraybuffer'});
     // return this.http.get(`http://localhost:7006/api/policies/layouts/populations/${type}`, {responseType: 'arraybuffer'});
  }
  public getLayoutFileAutoFlotilla(type, categoryId) {
    return this.http.get(`${API_REST_ADMIN}/policies_car/layouts/populations/${type}/${categoryId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:7006/api/policies_car/layouts/populations/${type}/${categoryId}`, {responseType: 'arraybuffer'});
  }
  public postExpiredPolicies(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/expired/renewals', data ).subscribe( res => {
      // this.http.post('http://localhost:7006/api/core/policies/expired/renewals', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getSplitRights(policyId, categoryId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/policies/splits/rights/' + policyId + '/' + categoryId ).subscribe( res => {
       // this.http.get('http://localhost:7006/api' +  '/policies/splits/rights/' + policyId + '/' + categoryId ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public downloadReceiptByCarId(carId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/receipts/car/' + carId, {responseType: 'arraybuffer'} ).subscribe( res => {
      // this.http.get('http://localhost:7008/api' +  '/receipts/car/' + carId, {responseType: 'arraybuffer'} ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public downloadReceiptByPolicyId(policyId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/receipts/policy/' + policyId, {responseType: 'arraybuffer'}).subscribe( res => {
      // this.http.get('http://localhost:7008/api' +  '/receipts/policy/' + policyId, {responseType: 'arraybuffer'} ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public downloadReceiptByGroupId(groupId, receiptId, branchId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/receipts/groupId/' + groupId + '/' + receiptId + '/' + branchId, {responseType: 'arraybuffer'}).subscribe( res => {
      // this.http.get('http://localhost:7008/api/core' +  '/receipts/groupId/' + groupId + '/' + receiptId + '/' + branchId, {responseType: 'arraybuffer'} ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  public postMassiveReceipts(receipts, policyId) {
    return new Promise((response, error) => {
     // this.http.post(`http://localhost:7013/api/core/receipts/saveReceiptCar/${policyId}`, receipts ).subscribe( res => {
     this.http.post(API_REST_ADMIN + `/receipts/saveReceiptCar/${policyId}`, receipts ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getSplitRightsCarId(policyId, carId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/policies/splits/rightsByCar/' + policyId + '/' + carId ).subscribe( res => {
        // this.http.get('http://localhost:7006/api' +  '/policies/splits/rights/' + policyId + '/' + categoryId ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
/*****servicios anteiores********/
  public getDetailPolicy(id, insuredId?, carId?) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/policies/detail/' + id + '/' + insuredId + '/' + carId).subscribe( res => {
      // this.http.get('http://localhost:7006/api/policies/detail/' + id + '/' + insuredId + '/' + carId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getDetailPolicyjson(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/policies/details/' + id).subscribe( res => {
      // this.http.get('http://localhost:7006/api' +  '/policies/details/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getAgentsChatSurexs() {
    return this.http.post(`${API_REST}/general/executives/agencies?customerId=72`, '');
  }
  public getCountCertificates(policyId) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/policies/' + policyId + '/certificates').subscribe( res => {
      // this.http.get('http://localhost:7006/api' +  '/policies/' + policyId + '/certificates').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCertificatesSearch(policyId, search) {
    return this.http.post(`${API_REST}/policies/${policyId}/certificates?search=${search}`, '');
    // return this.http.post('http://localhost:7006/api'+`/policies/${policyId}/certificates?search=${search}`,'');
  }
  public getSalesmanExternal(userIdExternal) {
    return new Promise((response, error) => {
      this.http.get(`${API_REST4}/users/salesmans/${userIdExternal}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCertificate(certificateId) {
    return this.http.get(`${API_REST}/policies/certificats/${certificateId}`, {responseType: 'arraybuffer'});
    // return this.http.get('http://localhost:7006/api'+`/policies/certificats/${certificateId}`, {responseType: 'arraybuffer'});
  }

  // todo  servicios para ammia lite
  public getGroupsLite(ammia: string) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/groups?search=' + ammia).subscribe( res => {
        // this.http.get('http://localhost:7030/api' +  '/groups?search=').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public putPolicyDataLite(data) {
    return new Promise((response, error) => {
      this.http.put(`${API_REST_ADMIN}/policies/ammia/lite`, data).subscribe( res => {
        // this.http.put('http://localhost:7006/api'+`/policies/ammia/lite`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postPolicyDataLite(data) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/policies/ammia/lite`, data).subscribe( res => {
        // this.http.post('http://localhost:7006/api/policies/ammia/lite', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postImportInsuredPolicyLite(data, policyId) {
    // return this.http.post(`http://localhost:7006/api/policies/ammia/lite/insured/uploads?policyId=${policyId}`, data);
    return this.http.post(`${API_REST_ADMIN}/policies/ammia/lite/insured/uploads?policyId=${policyId}`, data);
  }
  public postImportInsuredPolicyLiteLow(data, policyId) {
    // return this.http.post(`http://localhost:7006/api/policies/ammia/lite/insured/uploads/low?policyId=${policyId}`, data);
    return this.http.post(`${API_REST_ADMIN}/policies/ammia/lite/insured/uploads/low?policyId=${policyId}`, data);
  }

  public getManagerIncident() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/directories/managers/incident').subscribe( res => {
      //  this.http.get('http://localhost:7010/api/directories/managers/incident').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getExcelFilePaymentOrder(data) {
    return this.http.post(`${API_REST_ADMIN}/centralmapping/payments/orders/downloads`, data, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:7084/api/centralmapping/payments/orders/downloads`, data, {responseType: 'arraybuffer'});
  }
  public getPaymentOrdersPagination(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/centralmapping/payments/orders', data ).subscribe( res => {
      // this.http.post('http://localhost:7084/api/centralmapping/payments/orders', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCatAgeMaxLimit(id) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN +  '/policies/max/ages/limit/requests/' + id).subscribe( res => {
        // this.http.get('http://localhost:7006/api' +  '/policies/max/ages/limit/requests/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getValidateNumberPolicy(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/policies/validate/numbers/policies', data ).subscribe( res => {
        // this.http.post('http://localhost:7013/api/core/policies/validate/numbers/policies', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  /* -----------------------------------------------------------------------------------------------------
 -------------------------------------- C O B R A N Z A ---------------------------------------
 ----------------------------------------------------------------------------------------------------- */
  public getReceiptsPolicy(policyId) {
    return new Promise((response, error) => {
      // this.http.get(API_REST_ADMIN +  '/receipts/policies/' + policyId).subscribe( res => {
      this.http.get(API_REST_ADMIN +  '/receipts/policies/' + policyId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStatusReceipts() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/receipts/filters/status').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPaymentForms() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/payments/forms?search=').subscribe( res => {
      // this.http.get('http://localhost:7038/api'+  '/payments/forms?search=').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCancellations(branchId) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/policies/getReasonOfCancellation', branchId).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/policies/getReasonOfCancellation', branchId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReasonsNoRenew(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN +  '/policies/getReasonOfNoRenew', data).subscribe( res => {
      //  this.http.post('http://localhost:7013/api/core/policies/getReasonOfNoRenew', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getSubGroupsMultiselect(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/clients/subgrupo', data).subscribe( res => {
      // this.http.post('http://localhost:7002/api' +  '/clients/subgrupo', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getSubBranchesMulti(data) {
    return new Promise((response, error) => {
    return this.http.post(API_REST_ADMIN +  '/branches/subbranches/list', data).subscribe( res => {
    // return this.http.post('http://localhost:7014/api' +  '/branches/subbranches/list', data).subscribe( res => {
      response(res);
    }, (err) => {
      error(err);
    });
  });
  }

  public getPolicyFilterDocuments() {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN +  '/policies/numbers/filter').subscribe( res => {
        // this.http.get('http://localhost:7006/api' +  '/policies/numbers/filter').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPolicyFilterDocumentsEmmit(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN +  '/policies/numbers/filter/reports', data).subscribe( res => {
        // this.http.post('http://localhost:7006/api' +  '/policies/numbers/filter/reports', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReceiptsPagination(data, page, size) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/receipts/policies?page=' + page + '&size=' + size, data ).subscribe( res => {
        // this.http.post('http://localhost:7008/api/core/receipts/policies?page=' + page + '&size=' + size, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getExcelFile(data) {
       // return this.http.post(`http://localhost:7008/api/core/receipts/downloads`, data,{responseType: 'arraybuffer'});
      return this.http.post(`${API_REST_ADMIN}/receipts/downloads`, data, {responseType: 'arraybuffer'});
  }

  public getReceiptById(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/receipts/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /*****servicios anteiores********/
  public getReceiptStatusList(policyId) {
    return new Promise((response, error) => {
      this.http.get(`${API_REST}/receipts/${policyId}/status`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getInsuranceReceiptsList(statusId, policyId) {
    return new Promise((response, error) => {
      this.http.get(`${API_REST}/receipts/${statusId}/${policyId}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getReceiptAttachment(receiptId) {
    return new Promise((response, error) => {
      this.http.get(`${API_REST}/receipts/${receiptId}/anexes`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getAttachmentFile(annexId) {
    return this.http.get(`${API_REST}/receipts/anexes/${annexId}`, {responseType: 'arraybuffer'});
  }
  public getReceiptAttachmentZipFile(receiptId) {
    return this.http.get(`${API_REST}/receipts/anexes/zip/${receiptId}`, {responseType: 'arraybuffer'});
  }

  /* -----------------------------------------------------------------------------------------------------
   -------------------------------------- consulta de asegurados component insured ---------------------------------------
   ----------------------------------------------------------------------------------------------------- */
  public getPoliciesSize(insuredId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN  + '/policies/size/' + insuredId).subscribe( res => {
      // this.http.get('http://localhost:7006/api'  + '/policies/size/' + insuredId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getTypePolicies(insuredId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN  + '/policies/type/user/' + insuredId).subscribe( res => {
      // this.http.get('http://localhost:7006/api'  + '/policies/type/user/' + insuredId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getUserincident(typePolicyId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN  + '/incidents/recents/' + typePolicyId).subscribe( res => {
      // this.http.get('http://localhost:7186/api'  + '/incidents/recents/' + typePolicyId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getDetailIncident(incidentId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN  + '/incidents/detail/' + incidentId).subscribe( res => {
      // this.http.get('http://localhost:7186/api'  + '/incidents/detail/' + incidentId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentAttachments(incidentId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN  + '/incident/' + incidentId + '/anexes').subscribe( res => {
      // this.http.get('http://localhost:7186/api'  + '/incidents/' + incidentId + '/anexes').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentLog(incidentId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN  + '/events/incident/' + incidentId).subscribe( res => {
      // this.http.get('http://localhost:7064/api'  + '/events/incident/' + incidentId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCommentsLog(eventId) {
    return new Promise((response, error) => {
      this.http.get(API_REST  + '/logs/comments/' + eventId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getLogsAttachmentZipFile(eventId) {
    return this.http.get(`${API_REST}/logs/attachments/zip/${eventId}`, {responseType: 'arraybuffer'});
  }
  public getLogsAttachmentFile(attachmentId) {
    return this.http.get(`${API_REST}/logs/attachment/document/${attachmentId}`, {responseType: 'arraybuffer'});
  }
  public getUserPoliciesType(id, insuredId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/policies/type/thumbnail/' + id + '/' + insuredId).subscribe( res => {
      // this.http.get('http://localhost:7006/api' +  '/policies/type/thumbnail/' + id + '/' + insuredId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getUserTypeIncident(insuredId, id, policyType, page, size) {
    return this.http.get(`${API_REST_ADMIN}/incidents/status/${id}/${policyType}/${insuredId}/pages?page=${page}&size=${size}`).pipe(
    // return this.http.get('http://localhost:7186/api' + `/incidents/status/${id}/${policyType}/${insuredId}/pages?page=${page}&size=${size}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, incidentList: []};
        } else {
          return res;
        }
      })
    );
  }
  public getAttachmentlPolicy(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/policies/attachments/' + id).subscribe( res => {
      // this.http.get('http://localhost:7006/api' +  '/policies/attachments/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentAnnexFile(annexId) {
    return this.http.get(`${API_REST}/incident/attachments/${annexId}`, {responseType: 'arraybuffer'});
  }
  public getPolicyAttachmentFile(attachmentFileId) {
    return this.http.get(`${API_REST}/policies/attachments/document/${attachmentFileId}`, {responseType: 'arraybuffer'});
    // return this.http.get('http://localhost:7006/api'+`/policies/attachments/document/${attachmentFileId}`, {responseType: 'arraybuffer'});
  }
  public getPolicyAttachmentZip(policyId) {
    return this.http.get(`${API_REST}/policies/attachments/document/zip/${policyId}`, {responseType: 'arraybuffer'});
    // return this.http.get('http://localhost:7006/api'+`/policies/attachments/document/zip/${policyId}`, {responseType: 'arraybuffer'});
  }
  public getPolicyDependents(policyId) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/policies/dependents/' + policyId).subscribe( res => {
      // this.http.get('http://localhost:7006/api' +  '/policies/dependents/' + policyId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getRequestTypeGeneral(insuredId, statusId, page, size) {
    return this.http.get(`${API_REST_ADMIN}/request/general/${statusId}/${insuredId}/pages?page=${page}&size=${size}`).pipe(
    // return this.http.get('http://localhost:7066/api' + `/request/general/${statusId}/${insuredId}/pages?page=${page}&size=${size}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, requestList: []};
        } else {
          return res;
        }
      })
    );
  }
  public postRequestData(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7017/api/core/request/execute', data).subscribe( res => {
      this.http.post(`${API_REST_ADMIN}/request/execute`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getRequest(data) {
        return new Promise((response, error) => {
          // this.http.post('http://localhost:7017/api/core' +  '/request', data).subscribe( res => {
          this.http.post(`${API_REST_ADMIN}/request`, data).subscribe( res => {
            response(res);
          }, (err) => {
            error(err);
          });
        });
  }

  public getPoliciesByRequest(data) {

    return new Promise((response, error) => {
      // this.http.get('http://localhost:7017/api/core' +  '/request/gePoliciesByIdRequestId/' + data).subscribe( res => {
      this.http.get(`${API_REST_ADMIN}/request/gePoliciesByIdRequestId/` + data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public setFinishedRequest(idRequest) {

    return new Promise((response, error) => {
      // this.http.get('http://localhost:7066/api' +  `/request/requestFinished/${idRequest}`).subscribe( res => {
      this.http.get(`${API_REST_ADMIN}/request/requestFinished/${idRequest}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
   public getInfoRequest() {

          return new Promise((response, error) => {
            // this.http.get('http://localhost:7066/api' +  '/request/info').subscribe( res => {
              this.http.get(`${API_REST_ADMIN}/request/info`).subscribe( res => {
              response(res);
            }, (err) => {
              error(err);
            });
          });
    }

  public validateRequest(data) {

          return new Promise((response, error) => {
            // this.http.post('http://localhost:7017/api/core' +  '/request/validate', data).subscribe( res => {
            this.http.post(`${API_REST_ADMIN}/request/validate`, data).subscribe( res => {
              console.log('res1');
              console.log(res);
              response(res);
            }, (err) => {
              error(err);
            });
          });

    }
  public getStatusCatRequests() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/request/status').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getLogAttachments(eventId) {
    return new Promise((response, error) => {
      this.http.get(API_REST + '/logs/attachments/' + eventId).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getLayoutMassiveUsers(type, data) {
    return this.http.post(`${API_REST_ADMIN}/centralmapping/download/requests/${type}`, data, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:7084/api/core/centralmapping/download/requests/${type}`, data, {responseType: 'arraybuffer'});
  }


   /* -----------------------------------------------------------------------------------------------------
     -------------------------------------- consulta de provisiones  ---------------------------------------
     ----------------------------------------------------------------------------------------------------- */

   public getProvisions(data) {

      return new Promise((response, error) => {
                  // this.http.post('http://localhost:7006/api/policies/provitions',data).subscribe( res => {
                  this.http.post(`${API_REST_ADMIN}/policies/provitions`, data).subscribe( res => {
                    response(res);
                  }, (err) => {
                    error(err);
                  });
                });
   }

   public postSaveSiniesters(data) {
       // return this.http.post('http://localhost:7078/api/report/siniesters', data);
       return this.http.post(`${API_REST_ADMIN}/report/siniesters`, data);
     }


  /* -----------------------------------------------------------------------------------------------------
       -------------------------------------- COBERTURAS DAÑOS  ---------------------------------------
       ----------------------------------------------------------------------------------------------------- */

   public getStatesByCode(code) {
     return new Promise((response, error) => {
        // this.http.get('http://localhost:7000/api/sepomex/address/'+code).subscribe( res => {
        this.http.get(`${API_REST_ADMIN}/sepomex/address/` + code).subscribe( res => {
            response(res);
         }, (err) => {
              error(err);
            });
         });
      }

    public getActivities() {

         return new Promise((response, error) => {

                     // this.http.get('http://localhost:7072/api/location/activity').subscribe( res => {
                     this.http.get(`${API_REST_ADMIN}/location/activity`).subscribe( res => {
                       response(res);
                     }, (err) => {
                       error(err);
                     });
                   });
    }
   public getConstructionTypes() {

            return new Promise((response, error) => {

                        // this.http.get('http://localhost:7072/api/location/constructionTypes').subscribe( res => {
                        this.http.get(`${API_REST_ADMIN}/location/constructionTypes`).subscribe( res => {
                          response(res);
                        }, (err) => {
                          error(err);
                        });
                      });
       }
   public getFireProtection() {

            return new Promise((response, error) => {

                        // this.http.get('http://localhost:7072/api/location/fireprotection').subscribe( res => {
                        this.http.get(`${API_REST_ADMIN}/location/fireprotection`).subscribe( res => {
                          response(res);
                        }, (err) => {
                          error(err);
                        });
                      });
       }

       public getSecurityMeasures() {

            return new Promise((response, error) => {

                        // this.http.get('http://localhost:7072/api/location/securitymeasures').subscribe( res => {
                        this.http.get(`${API_REST_ADMIN}/location/securitymeasures`).subscribe( res => {
                          response(res);
                        }, (err) => {
                          error(err);
                        });
                      });
       }
        public getEnterpriseAdministrator() {

                   return new Promise((response, error) => {

                               // this.http.get('http://localhost:7010/api/directories/enterprise/administrator').subscribe( res => {
                               this.http.get(`${API_REST_ADMIN}/directories/enterprise/administrator`).subscribe( res => {
                                 response(res);
                               }, (err) => {
                                 error(err);
                               });
                             });
              }
   public getPostCodes() {

      return new Promise((response, error) => {

        // this.http.get('http://localhost:7000/api/sepomex/address/postcode').subscribe( res => {
         this.http.get(`${API_REST_ADMIN}/sepomex/address/postcode`).subscribe( res => {

             response(res);
          }, (err) => {
              error(err);
          });
       });
   }

   public postLocation(data) {

       return new Promise((response, error) => {

             // this.http.post('http://localhost:7072/api/location', data).subscribe( res => {
             this.http.post(`${API_REST_ADMIN}/location`, data).subscribe( res => {
               response(res);
             }, (err) => {
               error(err);
             });
           });
     }

   public postCoverageDamage(data) {
          return new Promise((response, error) => {

             // this.http.post('http://localhost:7074/api/coverages/damages', data).subscribe( res => {
            this.http.post(`${API_REST_ADMIN}/coverages/damages`, data).subscribe( res => {
              response(res);
            }, (err) => {
              error(err);
            });
          });
        }

    public getLocationByPolicy(data) {

       return new Promise((response, error) => {

             // this.http.post('http://localhost:7072/api/location/location_policy', data).subscribe( res => {
             this.http.post(`${API_REST_ADMIN}/location/location_policy`, data).subscribe( res => {
               response(res);
             }, (err) => {
               error(err);
             });
           });
     }

    public getJsonCoeragesByLocationId(data) {

         return new Promise((response, error) => {

               // this.http.post('http://localhost:7074/api/coverages/damages/json_value', data).subscribe( res => {
               this.http.post(`${API_REST_ADMIN}/coverages/damages/json_value`, data).subscribe( res => {
                 response(res);
               }, (err) => {
                 error(err);
               });
             });
       }
     /* -----------------------------------------------------------------------------------------------------
            -------------------------------------- DIAS INHABILES  ---------------------------------------
            ----------------------------------------------------------------------------------------------------- */
      public getHoliays() {
      return new Promise((response, error) => {
              // this.http.get('http://localhost:7082/api/holidays').subscribe( res => {
              this.http.get(`${API_REST_ADMIN}/holidays`).subscribe( res => {
                response(res);
              }, (err) => {
                error(err);
              });
            });
      }

      public saveHoliays(data) {
          return new Promise((response, error) => {

            // this.http.post('http://localhost:7082/api/holidays', data).subscribe( res => {
            this.http.post(`${API_REST_ADMIN}/holidays`, data).subscribe( res => {
              response(res);
            }, (err) => {
              error(err);
            });
          });
        }

      public importHolidays(data) {
      //  return this.http.post(`http://localhost:7082/api/holidays/import`, data);
        return this.http.post(`${API_REST_ADMIN}/holidays/import`, data);
      }

      public deleteHolidays(data) {

           return new Promise((response, error) => {

                      // this.http.post('http://localhost:7082/api/holidays/delete', data).subscribe( res => {
                      this.http.post(`${API_REST_ADMIN}/holidays/delete`, data).subscribe( res => {
                        response(res);
                      }, (err) => {
                        error(err);
                      });
                    });
       }
       public updateHolidays(data) {

           return new Promise((response, error) => {

             // this.http.put('http://localhost:7082/api/holidays', data).subscribe( res => {
             this.http.put(`${API_REST_ADMIN}/holidays`, data).subscribe( res => {
               response(res);
             }, (err) => {
               error(err);
             });
           });
       }

  /* -----------------------------------------------------------------------------------------------------
   -------------------------------------- REPORTES ---------------------------------------
   ----------------------------------------------------------------------------------------------------- */
  public getProvitionsInitialPopulation(data) {
      return new Promise((response, error) => {
              // this.http.post('http://localhost:7006/api/core/policies/provitions_initial_pupulation', data,{responseType: 'arraybuffer'}).subscribe( res => {
              this.http.post(`${API_REST_ADMIN}/policies/provitions_initial_pupulation`, data, {responseType: 'arraybuffer'}).subscribe( res => {
                response(res);
              }, (err) => {
                error(err);
              });
            });
      }

  public generatePdfAnalisys(data) {

  return new Promise((response, error) => {

              // this.http.post('http://localhost:7078/api/report/analisis',data,{responseType: 'arraybuffer'}).subscribe( res => {
              this.http.post(`${API_REST_ADMIN}/report/analisis`, data, {responseType: 'arraybuffer'}).subscribe( res => {
                response(res);
              }, (err) => {
                error(err);
              });
            });
   }

   public getDataInsuredById(data) {

     return new Promise((response, error) => {

                 // this.http.post('http://localhost:7010/api/core/directories/insured_update', data).subscribe( res => {
                 this.http.post(`${API_REST_ADMIN}/directories/insured_update`, data).subscribe( res => {
                   response(res);
                 }, (err) => {
                   error(err);
                 });
               });
      }

  public postUpdateInsuredById(data) {
    return new Promise((response, error) => {

      // this.http.post('http://localhost:7010/api/directories/insured_kinship', data).subscribe(res => {
      this.http.post(`${API_REST_ADMIN}/directories/insured_kinship`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public restorePassword(data) {
    return new Promise((response, error) => {

      // this.http.post('http://localhost:7010/api/directories/update_password', data).subscribe(res => {
      this.http.post(`${API_REST_ADMIN}/directories/update_password`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
   -------------------------------------- CONFIGURACIO DE DOCUMENTOS ---------------------------------------
   ----------------------------------------------------------------------------------------------------- */
   public getProcess() {
   return new Promise((response, error) => {
           // this.http.get('http://localhost:7080/api/document_proccess/process').subscribe( res => {
           this.http.get(`${API_REST_ADMIN}/document_proccess/process`).subscribe( res => {
             response(res);
           }, (err) => {
             error(err);
           });
         });
   }
   public getSubProcess(data) {
      return new Promise((response, error) => {
            // this.http.post('http://localhost:7080/api/document_proccess/subprocess',data).subscribe( res => {
            this.http.post(`${API_REST_ADMIN}/document_proccess/subprocess`, data).subscribe( res => {
              response(res);
            }, (err) => {
              error(err);
            });
          });
   }
  public getConfigDocumentsById(id) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:7080/api/document_proccess/config_documents/' + id).subscribe( res => {
      this.http.get(`${API_REST_ADMIN}/document_proccess/config_documents/${id}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
    public getProcessByFilter(data) {
      return new Promise((response, error) => {
            // this.http.post('http://localhost:7080/api/document_proccess/filters', data).subscribe( res => {
            this.http.post(`${API_REST_ADMIN}/document_proccess/filters`, data).subscribe( res => {
              response(res);
            }, (err) => {
              error(err);
            });
          });
    }
    public getTypesDocuments() {
      return new Promise((response, error) => {

            // this.http.get('http://localhost:7080/api/document_proccess/type_documents').subscribe( res => {
            this.http.get(`${API_REST_ADMIN}/document_proccess/type_documents`).subscribe( res => {
              response(res);
            }, (err) => {
              error(err);
            });
          });
    }

  public getTypesDocumentsByConfiguration(id) {
    return new Promise((response, error) => {

      // this.http.get('http://localhost:7080/api/document_proccess/type_documents/' + id).subscribe( res => {
      this.http.get(`${API_REST_ADMIN}/document_proccess/type_documents/${id}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
    public saveDocument(data) {
      return new Promise((response, error) => {
        // this.http.post('http://localhost:7080/api/document_proccess', data).subscribe( res => {
        this.http.post(`${API_REST_ADMIN}/document_proccess`, data).subscribe( res => {
              response(res);
            }, (err) => {
              error(err);
            });
          });
    }

    public deleteDocumentProccess(data) {
      return new Promise((response, error) => {
             // this.http.post('http://localhost:7080/api/document_proccess/remove', data).subscribe( res => {
             this.http.post(`${API_REST_ADMIN}/document_proccess/remove`, data).subscribe( res => {
               response(res);
             }, (err) => {
               error(err);
             });
           });
     }

     public getCatalogByBusiness(data) {
      return new Promise((response, error) => {

             // this.http.post('http://localhost:7076/api/catalog', data).subscribe( res => {
             this.http.post(`${API_REST_ADMIN}/catalog`, data).subscribe( res => {
               response(res);
             }, (err) => {
               error(err);
             });
           });
     }

  public getTypeContactForClients() {
    return new Promise((response, error) => {

      // this.http.get('http://localhost:7076/api/catalog/getTypeContactForClients').subscribe( res => {
      this.http.get(API_REST_ADMIN + '/catalog/getTypeContactForClients').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getTypesAddressForClients() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:7076/api/catalog/getTypeAddress').subscribe( res => {
      this.http.get(API_REST_ADMIN + '/catalog/getTypeAddress').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
     public getClientsByFilters(data) {
      return new Promise((response, error) => {
              // this.http.post('http://localhost:7084/api/centralmapping/clients_filters', data).subscribe( res => {
              this.http.post(`${API_REST_ADMIN}/centralmapping/clients_filters`, data).subscribe( res => {
               response(res);
             }, (err) => {
               error(err);
             });
           });
     }
     public updateDocumentProccess(data) {
           return new Promise((response, error) => {
                  this.http.post(`${API_REST_ADMIN}/document_proccess/update_document`, data).subscribe( res => {
                  // this.http.post('http://localhost:7080/api/document_proccess/update_document', data).subscribe( res => {
                    response(res);
                  }, (err) => {
                    error(err);
                  });
                });
          }


    /* -----------------------------------------------------------------------------------------------------
    -------------------------------------- R E Q U E S T  ---------------------------------------
    ----------------------------------------------------------------------------------------------------- */

  public getRequestTempDetail(idRequestTemp, subgroupId) {
    return new Promise((response, error) => {
      // this.http.get(`http://localhost:7066/api/request/getRequestTempDetail/` + subgroupId + '/' + idRequestTemp).subscribe( res => {
      this.http.get(`${API_REST_ADMIN}/request/getRequestTempDetail/` + subgroupId + '/' + idRequestTemp).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public editRequestTempDetail(RequestTempDetailList) {
    return new Promise((response, error) => {
      // return this.http.post(`http://localhost:7066/api/request/editRequestTemp`, RequestTempDetailList).subscribe( res => {
      return this.http.post(`${API_REST_ADMIN}/request/editRequestTemp`, RequestTempDetailList).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequestTempUpdated(idRequestTemp) {
    return new Promise((response, error) => {
      // return this.http.get(`http://localhost:7066/api/request/getRequestTemp/` + idRequestTemp).subscribe( res => {
      return this.http.get(`${API_REST_ADMIN}/request/getRequestTemp/` + idRequestTemp).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public processMassiveRequest(RequestTempDetailList, groupId, subgroupId) {
    return new Promise((response, error) => {
      // return this.http.post(`http://localhost:7017/api/core/request/imports/${subgroupId}/${groupId}`, RequestTempDetailList).subscribe(res => {
      return this.http.post(`${API_REST_ADMIN}/request/imports/${subgroupId}/${groupId}`, RequestTempDetailList).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequestTemp(subgroupId) {
    return new Promise((response, error) => {
      // return this.http.get('http://localhost:7066/api/request/getRequestTemp/' + subgroupId).subscribe(res => {
      return this.http.get(`${API_REST_ADMIN}/request/getRequestTemp/` + subgroupId).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public deleteRequestTemp(idRequestTemp) {
    return new Promise((response, error) => {
      // return this.http.delete(`http://localhost:7066/api/request/deleteRequestTemp/` + idRequestTemp).subscribe(res => {
      return this.http.delete(`${API_REST_ADMIN}/request/deleteRequestTemp/` + idRequestTemp).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getLayoutMassiveRequestLoadInsured(subgroupId) {
    return new Promise((response, error) => {
      // return this.http.get(`http://localhost:7066/api/request/downloadolayout/` + subgroupId, {responseType: 'arraybuffer'}).subscribe( res => {
      return this.http.get(`${API_REST_ADMIN}/request/downloadolayout/` + subgroupId, {responseType: 'arraybuffer'}).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getTypesRequest() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/request/types' ).subscribe( res => {
      // this.http.get('http://localhost:7066/api/request/types').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getStatusRequest() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/request/status' ).subscribe( res => {
      // this.http.get('http://localhost:7066/api/request/status').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getInsured() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/request/insured' ).subscribe( res => {
      // this.http.get('http://localhost:7066/api/request/insured').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getFolios() {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/request/folios' ).subscribe( res => {
        // this.http.get('http://localhost:7066/api/request/folios').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPoliciesRequest() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/request/policies/filter' ).subscribe( res => {
      // this.http.get('http://localhost:7066/api/request/policies/filter').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequestPagination(data) {
    return new Promise((response, error) => {
         this.http.post(API_REST_ADMIN + '/centralmapping/requests/list', data ).subscribe( res => {
         // this.http.post('http://localhost:7084/api/centralmapping/requests/list', data ).subscribe( res => {
          response(res);
        }, (err) => {
          error(err);
        });
    });
  }
  public getDetailRequest(requestId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/request/general/' + requestId ).subscribe( res => {
      // this.http.get('http://localhost:7066/api/request/general/' + requestId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getRequestMessagesAttention(type, requestId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/request/messages/' + type + '/' + requestId).subscribe( res => {
        // this.http.get('http://localhost:7066/api/request/messages/' + type + '/' + requestId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getAttachmentRequestById(id) {
    return this.http.get(`${API_REST_ADMIN}/request/downloads/${id}`, {responseType: 'arraybuffer'});
    // return this.http.get('http://localhost:7066/api' + `/request/downloads/${id}`, {responseType: 'arraybuffer'});
  }
  public postSendMessageRequest(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/request/messages', data ).subscribe( res => {
      // this.http.post('http://localhost:7066/api/request/messages', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getRequestAuthors(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/request/' + id + '/authors' ).subscribe( res => {
      // this.http.get('http://localhost:7066/api/request/' + id + '/authors').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequestTypesEvents(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/request/' + id + '/types/events').subscribe( res => {
      // this.http.get('http://localhost:7066/api/request/' + id + '/types/events').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequestPaginationLog(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/request/messages/filters', data ).subscribe( res => {
      //   this.http.post('http://localhost:7066/api/request/messages/filters', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateRequestStatus(requestId, statusId) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/request/' + requestId + '/status/' + statusId, null ).subscribe( res => {
      // this.http.put('http://localhost:7066/api/request/' + requestId + '/status/' + statusId, null).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getRequestMessageDetail(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/request/messages/' + id  ).subscribe( res => {
        // this.http.get('http://localhost:7066/api/request/messages/' + id ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateRequestIncident(data) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/request/incidents', data ).subscribe( res => {
        // this.http.put('http://localhost:7066/api/request/incidents', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequestByIdRequest(data) {

    return new Promise((response, error) => {
      // this.http.post('http://localhost:7066/api' +  '/request', data).subscribe( res => {
      this.http.post(`${API_REST_ADMIN}/request`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
   -------------------------------------- I N S U R A N C E C O M P A N Y  ---------------------------------------
   ----------------------------------------------------------------------------------------------------- */
  public getInsurancePagination(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/insurance-companies/filters', data ).subscribe( res => {
      //   this.http.post('http://localhost:7004/api/insurance-companies/filters', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public deleteInsurance(insuranceId) {
    return this.http.delete(API_REST_ADMIN +  '/insurance-companies/' + insuranceId);
  }
  public getInsuranceCompanyById(insuranceId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/' + insuranceId ).subscribe( res => {
      // this.http.get( 'http://localhost:7004/api/insurance-companies/' + insuranceId ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postInsuranceCompany(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/insurance-companies', data ).subscribe( res => {
      // this.http.post('http://localhost:7004/api/insurance-companies', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateInsuranceCompany(data) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/insurance-companies', data ).subscribe( res => {
      //  this.http.put('http://localhost:7004/api/insurance-companies', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getValidationsRFC(rfc, insuranceId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/validations/' + rfc + '/' + insuranceId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getContactsByInsurance(insuranceId, search) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/insurance/contacts/' + insuranceId + '?search=' + search ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public deleteContactInsuranceById(contactId): Observable<any> {
    return this.http.delete(API_REST_ADMIN +  '/contacts/' + contactId);
  }
  public getContactsById(contactId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/contacts/' + contactId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getContactTypes() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/contacts/types?search=').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postContact(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/insurance-companies/insurance/contacts', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateContact(data) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/contacts/insurance', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getAttachmentByInsurance(insuranceId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/insurance/attachments/' + insuranceId).subscribe( res => {
        // this.http.get( 'http://localhost:7004/api/insurance-companies/insurance/attachments/' + insuranceId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getAttachmentId(id) {
    return this.http.get(`${API_REST_ADMIN}/attachments/downloads/${id}`, {responseType: 'arraybuffer'});
    // return this.http.get('http://localhost:7066/api' + `/request/downloads/${id}`, {responseType: 'arraybuffer'});
  }
  public deleteAttachmentId(id) {
    return this.http.delete(API_REST_ADMIN +  '/attachments/' + id);
  }
  public getSubBranchesAll(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/branches/subbranches/list', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postAttachmentInsurance(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/insurance-companies/insurance/attachments', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getAgentKeyByInsurancePagination(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/insurance-companies/agents/keys/filters', data ).subscribe( res => {
        // this.http.post('http://localhost:7004/api/insurance-companies/filters', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public deleteAgentKeyId(id) {
    return this.http.delete(API_REST_ADMIN +  '/insurance-companies/agents/keys/' + id);
  }
  public getAgentKeyById(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/agents/keys/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getValidationAgentKey(agentKey, insuranceId, agentKeyId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/agents/keys/validations/'
        + agentKey + '/' + insuranceId + '/' + agentKeyId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postAgentKey(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/insurance-companies/agents/keys', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateAgentKey(data) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/insurance-companies/agents/keys', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getAddressByInsurance(id, search) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/insurance/address/' + id + '?search=' + search).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public deleteAddressInsuranceById(id) {
    return this.http.delete(API_REST_ADMIN +  '/address/' + id);
  }
  public getTypesAddress() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/address/types').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getAddressById( insuranceId, addressId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/insurance/address/' + insuranceId + '/' + addressId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postAddressInsurance(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/insurance-companies/insurance/address', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateAddressInsurance(data) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/address/insurance', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getDaysPaymentByInsurance(insuranceId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/' + insuranceId + '/days/payments' ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getDayPaymentId(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/days/payments/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public deleteDaysPaymentId(id) {
    return this.http.delete(API_REST_ADMIN +  '/insurance-companies/days/payments/' + id);
  }
  public postDaysPayment(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/insurance-companies/days/payments', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public putDaysPayment(data) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/insurance-companies/days/payments', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getSubBranchesByInsurance(insuranceId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/sub/branches/' + insuranceId ).subscribe( res => {
      //  this.http.get( 'http://localhost:7004/api/insurance-companies/sub/branches/' + insuranceId ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getConfigurationRightsByInsurance(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/insurance-companies/rights/filters', data).subscribe( res => {
      //  this.http.post('http://localhost:7004/api/core/insurance-companies/rights/filters', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getConfigurationRightsById(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/rights/' + id).subscribe( res => {
      // this.http.get('http://localhost:7004/api/core/insurance-companies/rights/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public saveConfigurationRightByInsurance(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/insurance-companies/rights', data).subscribe( res => {
      // this.http.post('http://localhost:7004/api/core/insurance-companies/rights', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateConfigurationRightByInsurance(data) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/insurance-companies/rights', data).subscribe( res => {
      // this.http.put('http://localhost:7004/api/core/insurance-companies/rights', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public deleteConfigurationRightById(id) {
    return this.http.delete(API_REST_ADMIN +  '/insurance-companies/rights/' + id);
    // return this.http.delete('http://localhost:7004/api/core/insurance-companies/rights/' + id);
  }

  public getConfigurationMedicalCareByInsurance(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/insurance-companies/configurations/medical/cares/filters', data).subscribe( res => {
      // this.http.post('http://localhost:7004/api/core/insurance-companies/configurations/medical/cares/filters', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public saveConfigurationMedicalCareByInsurance(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/insurance-companies/configurations/medical/cares', data).subscribe( res => {
      // this.http.post('http://localhost:7004/api/core/insurance-companies/configurations/medical/cares', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getConfigurationMedicalCareById(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/configurations/medical/cares/' + id).subscribe( res => {
      // this.http.get('http://localhost:7004/api/core/insurance-companies/configurations/medical/cares/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateConfigurationMedicalCareByInsurance(data) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/insurance-companies/configurations/medical/cares', data).subscribe( res => {
      // this.http.put('http://localhost:7004/api/core/insurance-companies/configurations/medical/cares', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public deleteConfigurationMedicalCareById(id) {
    return this.http.delete(API_REST_ADMIN +  '/insurance-companies/configurations/medical/cares/' + id);
    // return this.http.delete('http://localhost:7004/api/core/insurance-companies/configurations/medical/cares/' + id);
  }

  public getConfigurationRightsByInsuranceSubBranch(subBranchId, insuranceId) {
    return new Promise((response, error) => {
      // tslint:disable-next-line:max-line-length
      this.http.get(API_REST_ADMIN + '/insurance-companies/configurations/rights/sub/branches/' + subBranchId + '/' + insuranceId).subscribe( res => {
      // tslint:disable-next-line:max-line-length
      // this.http.get('http://localhost:7004/api/core/insurance-companies/configurations/rights/sub/branches/' + subBranchId + '/' + insuranceId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getConfigurationMedicalCareByInsuranceSubBranch(subBranchId, insuranceId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/configurations/medical/cares/sub/branches/' + subBranchId + '/' + insuranceId).subscribe( res => {
      // this.http.get('http://localhost:7004/api/core/insurance-companies/configurations/medical/cares/sub/branches/' + subBranchId + '/' + insuranceId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getBranchBySubBranches(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/branches/subbranches/branches/complements', data).subscribe( res => {
      // this.http.post('http://localhost:7014/api/core/branches/subbranches/branches/complements', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  /* -----------------------------------------------------------------------------------------------------
   -------------------------------------- CLIENT ---------------------------------------
   ----------------------------------------------------------------------------------------------------- */
  public getCheckZipeCode(data) {
    return new Promise((response, error) => {
      this.http.get(`${API_REST_ADMIN}/sepomex/postcode/${data}/check`).subscribe( res => {
      // this.http.get('http://localhost:7000/api/sepomex' + `/postcode/${data}/check`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getColoniesZipeCode(data) {
    return new Promise((response, error) => {
      this.http.get(`${API_REST_ADMIN}/sepomex/postcode/${data}/colonies`).subscribe( res => {
      // this.http.get('http://localhost:7000/api/sepomex' + `/postcode/${data}/colonies`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStatesByZipeCode(data) {
    return new Promise((response, error) => {
      this.http.get(`${API_REST_ADMIN}/sepomex/postcode/${data}/state`).subscribe( res => {
      // this.http.get('http://localhost:7000/api/sepomex' + `/postcode/${data}/state`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getTownByPostcode(data) {
    return new Promise((response, error) => {
      this.http.get(`${API_REST_ADMIN}/sepomex/postcode/${data}/town`).subscribe( res => {
      // this.http.get('http://localhost:7000/api/sepomex' + `/postcode/${data}/town`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCountries() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/sepomex/countries').subscribe( res => {
      // this.http.get('http://localhost:7000/api/sepomex/countries').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getStatesByCountry() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/sepomex/states').subscribe( res => {
            // this.http.get('http://localhost:7000/api/sepomex/states').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getMunicipalyByStateName(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/sepomex/towns', data).subscribe( res => {
       // this.http.post('http://localhost:7000/api/sepomex/towns',data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getZipCodesByState(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/sepomex/zipcode_state', data).subscribe( res => {
      // this.http.post('http://localhost:7000/api/sepomex/zipcode_state',data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getZipCodesByMunicipalyCode(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/sepomex/zipcode_municipaly', data).subscribe( res => {
      // this.http.post('http://localhost:7000/api/sepomex/zipcode_municipaly',data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getColoniesByZipCode(data) {
    return new Promise((response, error) => {
       this.http.post(API_REST_ADMIN + '/sepomex/colonies_zipcode', data).subscribe( res => {
        // this.http.post('http://localhost:7000/api/sepomex/colonies_zipcode',data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public validateGroup(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/clients/validate_group', data).subscribe( res => {
        // this.http.post('http://localhost:7002/api/clients/validate_group',data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public saveDataClient(data) {
    return new Promise((response, error) => {
       this.http.post(API_REST_ADMIN + '/clients/data_client', data).subscribe( res => {
        // this.http.post('http://localhost:7002/api/clients/data_client',data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveGroup(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/clients/saveGroup', data).subscribe( res => {
        // this.http.post('http://localhost:7002/api/clients/saveGroup', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveSubGroup(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/clients/saveSubGroup', data).subscribe( res => {
      // this.http.post('http://localhost:7002/api/clients/saveSubGroup', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveContactsSubgroup(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/clients/saveContactsSubgroup', data).subscribe( res => {
      // this.http.post('http://localhost:7002/api/clients/saveContactsSubgroup', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveAddress(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/clients/saveAddressSubgroup', data).subscribe( res => {
      // this.http.post('http://localhost:7002/api/clients/saveAddressSubgroup', data).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveAddressPhysicalClient(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/clients/saveClientPhysicalAddres', data).subscribe( res => {
      // this.http.post('http://localhost:7002/api/clients/saveClientPhysicalAddres', data).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveClientDocumentation(data) {
      return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/clients/saveDocumentation', data).subscribe( res => {
        // this.http.post('http://localhost:7002/api/clients/saveDocumentation', data).subscribe( res => {
          response(res);
        }, (err) => {
          error(err);
        });
      });
  }

  public saveClientPhysicalDocumentation(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/clients/saveDocumentationClientPhysical', data).subscribe( res => {
      // this.http.post('http://localhost:7002/api/clients/saveDocumentationClientPhysical', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }



  public saveEditClient(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/clients/update_data_client', data).subscribe( res => {
        // this.http.post('http://localhost:7002/api/clients/update_data_client',data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveDataClientPhysical(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/clients/data_client_fhysical_person', data).subscribe( res => {
        // this.http.post('http://localhost:7002/api/clients/data_client_fhysical_person',data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveDataMainClientPhysical(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/clients/saveClientPhysical', data).subscribe( res => {
      // this.http.post('http://localhost:7002/api/core/clients/saveClientPhysical', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveRelationGroupFisicToAmmia(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/clients/saveRelationGroupFisicToAmmia', data).subscribe( res => {
      // this.http.post('http://localhost:7002/api/core/clients/saveRelationGroupFisicToAmmia', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveEditClientPhyscal(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/clients/update_data_client_physical', data).subscribe( res => {
        // this.http.post('http://localhost:7002/api/clients/update_data_client_physical',data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStreetsByColonie(data) {
    return new Promise((response, error) => {
       this.http.post(API_REST_ADMIN + '/sepomex/streets_colonies', data).subscribe( res => {
       // this.http.post('http://localhost:7000/api/sepomex/streets_colonies',data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getAllSubGroupWhitoutGroup() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/clients/allSubGroupWhitoutGroup').subscribe( res => {
         // this.http.get('http://localhost:7002/api/clients/allSubGroupWhitoutGroup').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getClientInformation(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/centralmapping/client_information', data).subscribe( res => {
        // this.http.post('http://localhost:7084/api/centralmapping/client_information',data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getClientInformationPhysical(data) {
    return new Promise((response, error) => {
         this.http.post(API_REST_ADMIN + '/centralmapping/client_physica_information', data).subscribe( res => {
         // this.http.post('http://localhost:7084/api/core/centralmapping/client_physica_information',data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getDocuments(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7080/api/document_proccess/byConguration', data).subscribe( res => {
      this.http.post(`${API_REST_ADMIN}/document_proccess/byConguration`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getDocumentsTipoPersona(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7000/api/core/document_proccess/byConfiguration/tipo_persona', data).subscribe( res => {
      this.http.post(`${API_REST_ADMIN}/document_proccess/byConfiguration/tipo_persona`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getIdConfigurationByIdSubproccess(idSubprocess: number) {
    return new Promise((response, error) => {
      // this.http.get(`http://localhost:7080/api/document_proccess/id_configuration?idSubprocess=${idSubprocess}`).subscribe( res => {
      this.http.get(`${API_REST_ADMIN}/document_proccess/id_configuration?idSubprocess=${idSubprocess}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
  ----------------------------------------------- U S E R ------------------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  public getUserPagination(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA + '/users/getUsers', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUserById(user) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA + '/users/getUser', user ).subscribe( res => {
      // this.http.post('http://localhost:8801/api/ammia/v1.0/protected/sso/users/getUser', user ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRolList() {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA + '/users/getRoleCatalogList', null).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getGenderList() {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA + '/users/getGenderCatalogList', null).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getGroupList() {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA +  '/users/getGroupsCatalogList', null).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getSubGroupList(groupId) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA +  '/users/getSubGroupsCatalogList', groupId).subscribe( res => {
      // this.http.post('http://localhost:8801/api/ammia/v1.0/protected/sso/users/getSubGroupsCatalogList', groupId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveUser(user) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA +  '/users/addUser', user).subscribe( res => {
      // this.http.post('http://localhost:8801/api/ammia/v1.0/protected/sso/users/addUser', user).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateUser(user) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA +  '/users/updateUser', user).subscribe( res => {
       // this.http.post('http://localhost:8801/api/ammia/v1.0/protected/sso/users/updateUser', user).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public disableUser(user) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA +  '/users/toDisableUser', user).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public downloadXLS(filter) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7010/api/directories/downloadUser', filter, {responseType: 'arraybuffer'} ).subscribe( res => {
      this.http.post(API_REST_ADMIN +  '/directories/downloadUser', filter, {responseType: 'arraybuffer'}).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
 ----------------------------------------------- R O L E S ------------------------------------------------
 ----------------------------------------------------------------------------------------------------- */
  public getRolPagination(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA + '/role/getRoles', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRolById(rol) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA + '/role/getRole', rol ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRolListRol() {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA + '/role/getRolesCatalogList', null).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getProcesoList() {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA + '/role/getProcesoCatalogList', null).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveRol(rol) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA +  '/role/addRole', rol).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateRol(rol) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA +  '/role/updateRole', rol).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getAccionsByProcess(idProcess) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA +  '/role/getActionsModule', idProcess).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
  -------------------------------------- C O V E R A G E   CAR--------------------------------------------
  ----------------------------------------------------------------------------------------------------- */

  public getCoverageCarPagination(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages_car/getCoveragesCar', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages_car/getCoveragesCar', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoincidenceCoverageCar(name) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages_car/getCoincidenceNameCoverage', name).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages_car/getCoincidenceNameCoverage', name).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateCoverageCar(coverage) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages_car/updateCoveragesCar', coverage).subscribe( res => {
      this.http.post(API_REST_ADMIN +  '/catalogs_coverages_car/updateCoveragesCar', coverage).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveCoverageCar(coverage) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages_car/saveCoveragesCar', coverage).subscribe( res => {
      this.http.post(API_REST_ADMIN +  '/catalogs_coverages_car/saveCoveragesCar', coverage).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoverageCarEmmision(idInsurance) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:7028/api/catalogs_coverages_car/getCoverageCarEmissionByInsurance/' + idInsurance).subscribe( res => {
      this.http.get(API_REST_ADMIN +  '/catalogs_coverages_car/getCoverageCarEmissionByInsurance/' + idInsurance).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoverageCarById(coverageCar) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages_car/getCoverageCarByIdCoverage', coverageCar ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages_car/getCoverageCarByIdCoverage', coverageCar ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public validationIssuancePolicyCar(policyId) {
    const formParam = new HttpParams()
      .set('policyId', policyId);
    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/policies_car/validations/issues/policies_car', formParam, {
        // this.http.post('http://localhost:7006/api' + '/policies_car/validations/issues/policies_car', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postMassiveCar(coverageCar) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7013/api/core/policies_car/saveDataCar`, coverageCar ).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/policies_car/saveDataCar`, coverageCar ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postCar(data, endorsementId) {
    const AutoFlotillaWithEndorsement = {
      autoFlotilla: data,
      endorsementId
    };
    return new Promise((response, error) => {
        // this.http.post(`http://localhost:7006/api/core/policies_car/saveOneAutoFlotilla`, AutoFlotillaWithEndorsement ).subscribe( res => {
        this.http.post(API_REST_ADMIN + `/policies_car/saveOneAutoFlotilla`, AutoFlotillaWithEndorsement ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postCarintoAutoFlotilla(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7006/api/core/policies_car/saveCarWithCertificate`, data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/policies_car/saveCarWithCertificate`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public deleteCar(data, endorsementId?) {
    const flotillaWithEndorsement = {
      autoList: data,
      endorsementId: endorsementId === null || endorsementId === undefined ? 0 : endorsementId
    };
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7013/api/core/policies_car/deleteAutoFlotilla`, flotillaWithEndorsement).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/policies_car/deleteAutoFlotilla`, flotillaWithEndorsement).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public deleteAllCar(policyId, categoryId) {
    return new Promise((response, error) => {
      // this.http.delete(`http://localhost:7006/api/policies_car/deleteAllAutoFlotilla/${policyId}/${categoryId}`).subscribe( res => {
      this.http.delete(API_REST_ADMIN + `/policies_car/deleteAllAutoFlotilla/${policyId}/${categoryId}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public deletePysichalAllCar(policyCategory) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7013/api/core/policies_car/deleteAllAutoFlotillaByCategory`, policyCategory).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/policies_car/deleteAllAutoFlotillaByCategory`, policyCategory).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public deleteCategoryFlotilla(policyCategory) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7013/api/core/policies_car/deleteCategory`, policyCategory).subscribe( res => {
        this.http.post(API_REST_ADMIN + `/policies_car/deleteCategory`, policyCategory).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  public getCoverageCarFlotillaById(data, endorsementId?) {
    const flotillaWithEndorsement = {
      flotilla: data,
      endorsementId: endorsementId === null || endorsementId === undefined ? 0 : endorsementId
    };
    return new Promise((response, error) => {
        // this.http.post(`http://localhost:7006/api/core/policies_car/autosFlotilla`, flotillaWithEndorsement).subscribe( res => {
        this.http.post(API_REST_ADMIN + `/policies_car/autosFlotilla`, flotillaWithEndorsement ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoveragesByInsurance(insuranceId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/catalogs_coverages_car' +  `/getCoverageByIInsurance/${insuranceId}` ).subscribe( res => {
      // this.http.get('http://localhost:7028/api/catalogs_coverages_car' +  `/getCoverageByIInsurance/${insuranceId}` ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postCoverageNetPremium(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  `/catalogs_coverages_car/updateAllACoverage`, data ).subscribe( res => {
      // this.http.post(`http://localhost:7028/api/catalogs_coverages_car/updateAllACoverage`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getDataAcumulativeFlotilla(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  `/catalogs_coverages_car/getDataAcumulativeFlotilla`, data ).subscribe( res => {
      // this.http.post(`http://localhost:7028/api/catalogs_coverages_car/getDataAcumulativeFlotilla`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getDataAmountFlotilla(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  `/catalogs_coverages_car/getDataAmountFlotilla`, data ).subscribe( res => {
      // this.http.post(`http://localhost:7028/api/core/catalogs_coverages_car/getDataAmountFlotilla`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
  -------------------------------- P A C K A G E   C O V E R A G E   CAR----------------------------------
  ----------------------------------------------------------------------------------------------------- */

  public getPackageCoverageCarPagination(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/core/catalogs_coverages_car/getPackageCoveragesCar', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages_car/getPackageCoveragesCar', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoverageCarBySubranchInsurance(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages_car/getCoverageByIInsuranceAndSubranch', data ).subscribe( res => {
        this.http.post(API_REST_ADMIN + '/catalogs_coverages_car/getCoverageByIInsuranceAndSubranch', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public savePackageCoverageCar(coverage) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages_car/savePackageCoveragesCar', coverage).subscribe( res => {
      this.http.post(API_REST_ADMIN +  '/catalogs_coverages_car/savePackageCoveragesCar', coverage).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoverageFromPackageById(coverageCar) {
    return new Promise((response, error) => {
        // this.http.post('http://localhost:7028/api/catalogs_coverages_car/getPacakgeByIdPackage', coverageCar ).subscribe( res => {
        this.http.post(API_REST_ADMIN + '/catalogs_coverages_car/getPacakgeByIdPackage', coverageCar ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updatePackageCoverageCar(coverage) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages_car/updatePackageCar', coverage).subscribe( res => {
        this.http.post(API_REST_ADMIN +  '/catalogs_coverages_car/updatePackageCar', coverage).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postCategoryCoveragePolicyCar(data) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/policies_car/categories/coverage`, data).subscribe( res => {
      // this.http.post('http://localhost:7006/api/policies_car/categories/coverage', data).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCategoryCoverages(policyId, endorsementId?) {
    const policy = {
      policyId,
      endorsementId: endorsementId === null || endorsementId === undefined ? 0 : endorsementId
    };
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN +  '/policies_car/categories', policy ).subscribe( res => {
        // this.http.post('http://localhost:7006/api/core' +  '/policies_car/categories', policy ).subscribe( res => {
          response(res);
        }, (err) => {
          error(err);
        });
      });
  }

  public getCategoryCoveragesCar(policyId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/centralmappingCar/' + policyId + '/categories' ).subscribe( res => {
      // this.http.get('http://localhost:7084/api' +  '/centralmappingCar/' + policyId + '/categories' ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
  --------------------------------  P O L I C Y   CAR----------------------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  public getMakeCatalogist(data) {
    return new Promise((response, error) => {
        // this.http.post('https://qa.ammia.store/api/ammia/v1.0/public/emission/catalog/getMakesCatalogList', data).subscribe( res => {
        this.http.post(API_REST_AMMIA_WEB +  '/emission/catalog/getMakesCatalogList', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getModelYearCatalogist(data) {
    return new Promise((response, error) => {
        // this.http.post('https://qa.ammia.store/api/ammia/v1.0/public/emission/catalog/getModelYearCatalogList', data).subscribe( res => {
        this.http.post(API_REST_AMMIA_WEB +  '/emission/catalog/getModelYearCatalogList', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCarVersionCatalogist(data) {
    return new Promise((response, error) => {
      // this.http.post('https://qa.ammia.store/api/ammia/v1.0/public/emission/catalog/getCarVersionCatalogList', data).subscribe( res => {
      this.http.post(API_REST_AMMIA_WEB +  '/emission/catalog/getCarVersionCatalogList', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPacakge(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages_car/getPackageByIInsuranceAndSubranch', data).subscribe( res => {
      this.http.post(API_REST_ADMIN +  '/catalogs_coverages_car/getPackageByIInsuranceAndSubranch', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoverageFromPacakge(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages_car/getCoveragesByPackage', data).subscribe( res => {
      this.http.post(API_REST_ADMIN +  '/catalogs_coverages_car/getCoveragesByPackage', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsCar(policyId) {
    return new Promise((response, error) => {
      // this.http.get(`http://localhost:7006/api/policies_car/autosFlotillaDownload/${policyId}`,  {responseType: 'arraybuffer'} ).subscribe( res => {
      this.http.get(API_REST_ADMIN +  `/policies_car/autosFlotillaDownload/${policyId}` ,  {responseType: 'arraybuffer'} ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoincidenceUser(name) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7010/api/directories/getCoincidenceNameCoverage', name).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/directories/getCoincidenceNameCoverage', name).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public validateSerieNumber(serialNumber) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/centralmappingCar/validatSerieNumber/' + serialNumber, {}).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmappingCar/validatSerieNumber/' + serialNumber, {}).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  /* -----------------------------------------------------------------------------------------------------
  -------------------------------------- S P E C I A L  C O V E R A G E ----------------------------------
  ----------------------------------------------------------------------------------------------------- */
  public getSpecialCoveragePagination(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages/getSpecialCoverages', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/getSpecialCoverages', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoverageById(user) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages/getSpecialCoverage', user ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/getSpecialCoverage', user ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getSubranches(branch) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages/getSubBranches', branch).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/getSubBranches', branch).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getBranchesForSpecialCoverage() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:7028/api/catalogs_coverages/getBranches').subscribe( res => {
       this.http.get(API_REST_ADMIN + '/catalogs_coverages/getBranches').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoincidence(name) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages/getCoincidenceNameCoverage', name).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/getCoincidenceNameCoverage', name).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveSpecialCoverage(coverage) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages/addCoverage', coverage).subscribe( res => {
      this.http.post(API_REST_ADMIN +  '/catalogs_coverages/addCoverage', coverage).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateCoverage(coverage) {
    return new Promise((response, error) => {
       // this.http.post('http://localhost:7028/api/catalogs_coverages/editCoverage', coverage).subscribe( res => {
       this.http.post(API_REST_ADMIN +  '/catalogs_coverages/editCoverage', coverage).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
  -------------------------------------- S P E C I A L  C O V E R A G E ----------------------------------
  ----------------------------------------------------------------------------------------------------- */
  public getCategoriesPolicyAndSpecialCoverage(policyId, endorsementId?) {
    const policy = {
      policyId,
      endorsementId: endorsementId === null || endorsementId === undefined ? 0 : endorsementId
    };
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/policies/specialCategories', policy ).subscribe( res => {
      // this.http.post('http://localhost:7006/api/policies/specialCategories', policy).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoveragesSpecialCategory(categoryId, endorsementId?) {
    const category = {
      categoryId,
      endorsementId: endorsementId === null || endorsementId === undefined ? 0 : endorsementId
    };
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/coverages/getCoveragesSpecialCategory', category ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/coverages/getCoveragesSpecialCategory',  category).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoveragesSpecialByPolicy(policyId) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/categories/getCoveragesSpecialByPolicy', policyId ).subscribe( res => {
        // this.http.get('http://localhost:7006/api' +  '/policies/' + policyId + '/categories' ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getSpecialCoveragesNoPagination(subranch) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN +  '/catalogs_coverages/getSpecialCoveragesNoPagination', subranch ).subscribe( res => {
        // this.http.post('http://localhost:7013/api/core/catalogs_coverages/getSpecialCoveragesNoPagination', subranch).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveSpecialCoverageCategory(categoryCoverage) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/coverages/saveCoverageSpecialCategory', categoryCoverage ).subscribe( res => {
      // this.http.post('http://localhost:7018/api/coverages/saveCoverageSpecialCategory', categoryCoverage).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateSpecialCoverageCategory(categoryCoverage) {
    return new Promise((response, error) => {
       this.http.post(API_REST_ADMIN +  '/coverages/updateCoverageSpecialCategory', categoryCoverage ).subscribe( res => {
      // this.http.post('http://localhost:7018/api/coverages/updateCoverageSpecialCategory', categoryCoverage).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateOneCoverageSpecialCategory(categoryCoverage) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/coverages/updateOneCoverageSpecialCategory', categoryCoverage ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/coverages/updateOneCoverageSpecialCategory', categoryCoverage).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public deleteCoverageCategory(categoryId, coverageId) {
    return this.http.delete(API_REST_ADMIN +  '/coverages/' + categoryId + '/' + coverageId);
    // return this.http.delete('http://localhost:7008/api/receipts/categories/' + categoryId + '/' + policyId);
  }

  public downloadCoverages(policy) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7018/api/coverages/getCoveragesDownload', policy, {responseType: 'arraybuffer'} ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/coverages/getCoveragesDownload', policy, {responseType: 'arraybuffer'}).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  /* -----------------------------------------------------------------------------------------------------
  -------------------------------------- S I N I E S T R O S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  public getIncidentIndicators() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/incidents/indicators').subscribe( res => {
      // this.http.get( 'http://localhost:7186/api/incidents/indicators' ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentsByFolio(param) {
    return this.http.get(  API_REST_ADMIN + `/incidents/folios?folio=${param}`);
    // return this.http.get(`http://localhost:7186/api/incidents/folios?folio=${param}`);
  }
  public getPoliciesIncident() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/policies/numbers').subscribe( res => {
      // this.http.get('http://localhost:7006/api/incidents/policies/numbers').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getInsuredIncident() {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/insured' ).subscribe( res => {
          // this.http.get('http://localhost:7186/api/core/incidents/insured').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getKinshipIncident() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/incidents/kinship' ).subscribe( res => {
      // this.http.get('http://localhost:7186/api/incidents/kinship').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStatusIncident() {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/status' ).subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/status').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPhaseIncident() {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/phases' ).subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/phases').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentPagination(data) {
    return new Promise((response, error) => {
         this.http.post(API_REST_ADMIN + '/incidents/filters', data ).subscribe( res => {
        //  this.http.post('http://localhost:7009/api/core/incidents/filters', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getIncidentDetail(incidentId) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/' + incidentId ).subscribe( res => {
        // this.http.get('http://localhost:7009/api/core/incidents/' + incidentId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentDetailCar(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/incidents/details-car', data).subscribe( res => {
        // this.http.post('http://localhost:7009/api/core/incidents/details-car', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentTypes(policyId) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/types/' + policyId ).subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/types/' + policyId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getTypesRequestIncident() {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/requests/types' ).subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/requests/types').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getTypesRequestIncidentHigh(type) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/requests/types/' + type ).subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/requests/types/' + type).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getDocumentsIncident(incidentId) {
    return new Promise((response, error) => {
         this.http.get(API_REST_ADMIN + '/incidents/'  + incidentId + '/documents' ).subscribe( res => {
         // this.http.get('http://localhost:7186/api/incidents/'  + incidentId + '/documents').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public saveDocumentIncident(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/incidents/documents', data).subscribe( res => {
        //  this.http.post('http://localhost:7009/api/core/incidents/documents', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateDocumentIncident(data) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/incidents/documents', data ).subscribe( res => {
      // this.http.put('http://localhost:7186/api/incidents/documents', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getDocumentsAdditionalIncident(incidentId) {
    return new Promise((response, error) => {
         this.http.get(API_REST_ADMIN + '/incidents/'  + incidentId + '/additional/documents').subscribe( res => {
         // this.http.get('http://localhost:7186/api/incidents/'  + incidentId + '/additional/documents').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public saveDocumentAdditionalIncident(data) {
    return new Promise((response, error) => {
         this.http.post(API_REST_ADMIN + '/incidents/additional/documents', data).subscribe( res => {
         // this.http.post('http://localhost:7186/api/incidents/additional/documents', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveDocumentAdditionalIncidentNoNotifications(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/incidents/additional/documents/no/notifications', data).subscribe( res => {
        // this.http.post('http://localhost:7186/api/core/incidents/additional/documents/no/notifications', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateDocumentAdditionalIncident(data) {
    return new Promise((response, error) => {
        this.http.put(API_REST_ADMIN + '/incidents/additional/documents', data).subscribe( res => {
      // this.http.put('http://localhost:7186/api/incidents/additional/documents', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public upgradeDocumentAdditionalIncident(data) {
    return new Promise((response, error) => {
       this.http.put(API_REST_ADMIN + '/incidents/additional/documents/upgrade', data).subscribe( res => {
       // this.http.put('http://localhost:7186/api/incidents/additional/documents/upgrade', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public deleteDocument(documentId): Observable<any> {
    return this.http.delete(API_REST_ADMIN +  '/incidents/additional/documents/' + documentId);
    // return this.http.delete('http://localhost:7186/api/incidents/additional/documents/' + documentId);
  }

  public updateDocumentsByAuthorization(data) {
    return new Promise((response, error) => {
        this.http.put(API_REST_ADMIN + '/incidents/documents/authorization', data ).subscribe( res => {
        // this.http.put('http://localhost:7186/api/incidents/documents/authorization', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getIncidentMessagesAttention(type, incidentId) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/messages/' + type + '/' + incidentId).subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/messages/' + type + '/' + incidentId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postSendMessageIncident(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/incidents/messages', data ).subscribe( res => {
        // this.http.post('http://localhost:7186/api/core/incidents/messages', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getAttachmentIncidentById(id) {
     return this.http.get(`${API_REST_ADMIN}/incidents/downloads/${id}`, {responseType: 'arraybuffer'});
     // return this.http.get('http://localhost:7186/api' + `/incidents/downloads/${id}`, {responseType: 'arraybuffer'});
  }

  public getDocumentIncidentById(id) { // verificar
     return this.http.get(`${API_REST_ADMIN}/incidents/downloads/documents/${id}`, {responseType: 'arraybuffer'});
    // return this.http.get('http://localhost:7186/api' + `/incidents/downloads/documents/${id}`, {responseType: 'arraybuffer'});
  }
  public getIncidentAuthors(id) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/' + id + '/authors').subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/' + id + '/authors').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentTypesEvents(id) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/' + id + '/types/events').subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/' + id + '/types/events').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getIncidentPaginationLog(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/incidents/messages/filters', data ).subscribe( res => {
        // this.http.post('http://localhost:7186/api/incidents/messages/filters', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentMessageDetail(id) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/messages/' + id ).subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/messages/' + id ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getTextBySendEmailInsurance(incidentId) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/texts/emails/' + incidentId).subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/texts/emails/' + incidentId ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public saveIncidentByRequest(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/incidents/requests', data).subscribe( res => {
      // this.http.post('http://localhost:7186/api/core/incidents/requests', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public sendEmailDocumentation(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/incidents/sends/emails/insurance', data).subscribe( res => {
        // this.http.post('http://localhost:7186/api/incidents/sends/emails/insurance', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getDownloadZipDocumentation(id) { // verificar
      return this.http.get(`${API_REST_ADMIN}/incidents/documents/downloads/${id}`, {responseType: 'arraybuffer'});
      // return this.http.get('http://localhost:7186/api' + `/incidents/documents/downloads/${id}`, {responseType: 'arraybuffer'});
  }

  public getIncidentsByUserId(userId) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/classifications/' + userId).subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/classifications/' + userId ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateClassificationIncident(data) {
    return new Promise((response, error) => {
        this.http.put(API_REST_ADMIN + '/incidents/classifications', data ).subscribe( res => {
        // this.http.put('http://localhost:7186/api/incidents/classifications', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateStatusIncident(data) {
    return new Promise((response, error) => {
        this.http.put(API_REST_ADMIN + '/incidents/status', data ).subscribe( res => {
        // this.http.put('http://localhost:7186/api/incidents/status', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public cancelStatusIncident(data) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/incidents/status/cancel', data ).subscribe( res => {
      //  this.http.put('http://localhost:7186/api/core/incidents/status/cancel', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getUserByGroupClient(groupId, clientId) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/users/' + groupId + '/' + clientId).subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/users/' + groupId + '/' + clientId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPoliciesByUserId(userId) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/policies/users/' + userId).subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/policies/users/' + userId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getTypeIncidentByPolicy(policyId) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/types/' + policyId).subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/types/' + policyId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getKinshipByUserPolicy(policyId, userId) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/policies/' + policyId + '/users/' + userId + '/kinship').subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/policies/' + policyId + '/users/' + userId + '/kinship' ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public saveIncident(data) {
    return new Promise((response, error) => {
       this.http.post(API_REST_ADMIN + '/incidents', data).subscribe( res => {
       // this.http.post('http://localhost:7186/api/core/incidents', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public saveIncidentCar(data) {
    return new Promise((response, error) => {
       this.http.post(API_REST_ADMIN + '/incidents/save-incident/car', data).subscribe( res => {
      //  this.http.post('http://localhost:7009/api/core/incidents/save-incident/car', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public saveMassiveIncidentCar(data) {
    return new Promise((response, error) => {
       this.http.post(API_REST_ADMIN + '/incidents/save-incident/car', data).subscribe( res => {
      //  this.http.post('http://localhost:7009/api/core/incidents/save-massive-incident-car', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPhasesIncidentCarByCatType(data) {
    return new Promise((response, error) => {
       this.http.post(API_REST_ADMIN + '/incidents/get-phases/by-cattype', data).subscribe( res => {
      //  this.http.post('http://localhost:7009/api/core/incidents/get-phases/by-cattype', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPhasesIncidentCarByOrden(data) {
    return new Promise((response, error) => {
       this.http.post(API_REST_ADMIN + '/incidents/get-phases/by-orden-cattype', data).subscribe( res => {
      //  this.http.post('http://localhost:7009/api/core/incidents/get-phases/by-orden-cattype', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPhasesById(data) {
      return  this.http.post(API_REST_ADMIN + '/incidents/get-phases/by-id', data);
      // return this.http.post('http://localhost:7009/api/core/incidents/get-phases/by-id', data )
  }
  public updateIncident(data) {
    return new Promise((response, error) => {
        this.http.put(API_REST_ADMIN + '/incidents', data ).subscribe( res => {
        // this.http.put('http://localhost:7009/api/core/incidents', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateIncidentCar(data) {
    return new Promise((response, error) => {
        this.http.put(API_REST_ADMIN + '/incidents/update-incident-car', data ).subscribe( res => {
        // this.http.put('http://localhost:7009/api/core/incidents/update-incident-car', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getDownloadZipDocumentationByIncidentId(data) { // verificar
    return this.http.post(API_REST_ADMIN + '/incidents/documents/downloads/review',  data, {responseType: 'arraybuffer'});
    // return this.http.post('http://localhost:7009/api/core/incidents/documents/downloads/review', data, {responseType: 'arraybuffer'});
  }

  public getQuizByIncident(incidentId) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN + '/incidents/' + incidentId + '/quiz/questions').subscribe( res => {
        // this.http.get('http://localhost:7186/api/incidents/' + incidentId + '/quiz/questions' ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public saveQuizByIncident(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/incidents/quiz/answers', data).subscribe( res => {
        // this.http.post('http://localhost:7186/api/incidents/quiz/answers', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportLogXls(data) {
    return new Promise((response, error) => {
        // this.http.post('http://localhost:7186/api/incidents/messages/filters/excel', data, {responseType: 'arraybuffer'}).subscribe( res => {
        this.http.post(`${API_REST_ADMIN}/incidents/messages/filters/excel`, data, {responseType: 'arraybuffer'}).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportChatXls(incidentId) {
    return new Promise((response, error) => {
      // tslint:disable-next-line:max-line-length
      // this.http.get('http://localhost:7186/api/incidents/messages/' + incidentId + '/excel', {responseType: 'arraybuffer'}).subscribe( res => {
        this.http.get(API_REST_ADMIN + '/incidents/messages/' + incidentId + '/excel', {responseType: 'arraybuffer'}).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public changePhaseByIncident(data) {
    return new Promise((response, error) => {
        this.http.put(API_REST_ADMIN + '/incidents/changes/phases', data).subscribe( res => {
        // this.http.put('http://localhost:7186/api/incidents/changes/phases', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getTimelineByIncident(incidentId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/incidents/timeline/' + incidentId).subscribe( res => {
      // this.http.get('http://localhost:7186/api/incidents/timeline/' + incidentId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getDocumentById(attachmentId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/incidents/documents/downloads/viewer/' + attachmentId).subscribe( res => {
      // this.http.get('http://localhost:7186/api/incidents/documents/downloads/viewer/' + attachmentId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public changePhaseByIncidentOpinion(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/incidents/pending/opinion', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateChatZulipByIncident(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/directories/chat', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
 -----------------------------------------------R E P O R T  C O R E-------------------------------------
 ----------------------------------------------------------------------------------------------------- */
  public getReportsCore(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7078/api/report/getReportsCore', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/report/getReportsCore', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsProvision(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7068/api/core/cubeProvisions/getReportsProvisiones', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/cubeProvisions/getReportsProvisiones', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsProduccion(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7003/api/core/centralmapping/getReportProduction', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/getReportProduction', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsProduccionByCobranza(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/centralmapping/getReportProductionByCobranza', data ).subscribe( res => {
        this.http.post(API_REST_ADMIN + '/centralmapping/getReportProductionByCobranza', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsCobranzaCSV(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/centralmapping/getCobranzaByMonthXLS', data, {responseType: 'arraybuffer'} ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/getCobranzaByMonthXLS', data, {responseType: 'arraybuffer'} ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsIncident(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7003/api/core/centralmapping/getReportIncident', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/getReportIncident', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsIncidentGraph(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/core/centralmapping/getReportIncidentGraphic', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/getReportIncidentGraphic', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getIncidentTypesReport() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/centralmapping/types' ).subscribe( res => {
      // this.http.get('http://localhost:7084/api/centralmapping/types').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsIncidentXls(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/centralmapping/getReportIncidentExcel', data,{responseType: 'arraybuffer'}).subscribe( res => {
      this.http.post(`${API_REST_ADMIN}/centralmapping/getReportIncidentExcel`, data, {responseType: 'arraybuffer'}).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportIncidentGeneralExcel(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/centralmapping/getReportIncidentGeneralExcel', data,{responseType: 'arraybuffer'}).subscribe( res => {
      this.http.post(`${API_REST_ADMIN}/centralmapping/getReportIncidentGeneralExcel`, data, {responseType: 'arraybuffer'}).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportIncidentGeneralExcelFromGraphic(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/core/centralmapping/getReportIncidentGeneralExcelFromGraphic', data, {responseType: 'arraybuffer'}).subscribe( res => {
      this.http.post(`${API_REST_ADMIN}/centralmapping/getReportIncidentGeneralExcelFromGraphic`, data, {responseType: 'arraybuffer'}).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportUserExcelFromGraphic(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/centralmapping/getReportUserExcelFromGraphic',
        // data, {responseType: 'arraybuffer'}).subscribe( res => {
        this.http.post(`${API_REST_ADMIN}/centralmapping/getReportUserExcelFromGraphic`,
          data, {responseType: 'arraybuffer'}).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsProvisionXls(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/core/centralmapping/getReportsProvisionesXls',  data, {responseType: 'arraybuffer'} ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/getReportsProvisionesXls',  data, {responseType: 'arraybuffer'} ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsProduccionXls(data, typeGraphicPolicy) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7003/api/core/centralmapping/getReportProductionXls/${typeGraphicPolicy}`,  data, {responseType: 'arraybuffer'} ).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/centralmapping/getReportProductionXls/${typeGraphicPolicy}`,  data, {responseType: 'arraybuffer'} ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsProductionGraphic(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/centralmapping/getGrpahicProduction', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/getGrpahicProduction', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsAmountGraphic(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/centralmapping/getGraphicCantidad', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/getGraphicCantidad', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsIngresoGraphic(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/centralmapping/getGraphicIngreso', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/getGraphicIngreso', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsSubranchGraphic(data, typeGraphic) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/centralmapping/getGraphicSubRamo/' + typeGraphic, data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/getGraphicSubRamo/' + typeGraphic, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsCobranzaGraphic(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/core/centralmapping/getGraphicCobranza', data  ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/getGraphicCobranza', data ).subscribe( res => {
          response(res);
        }, (err) => {
          error(err);
      });
    });
  }

  public getGraphicUsoPoliza(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7003/api/core/centralmapping/getGraphicUsoPoliza', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/getGraphicUsoPoliza', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getIncidents(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/core/centralmapping/getIncidents', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/getIncidents', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getIncidentVsPrimaDev(groupId: number, subranchId: number) {
    return new Promise((response, error) => {
      // this.http.get(`http://localhost:7084/api/centralmapping/accidents/${groupId}/${subranchId}`).subscribe( res => {
        this.http.get(API_REST_ADMIN + `/centralmapping/accidents/${groupId}/${subranchId}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPolicyTypeByGroup(groupId) {
    return new Promise((response, error) => {
      // this.http.get(`http://localhost:7084/api/centralmapping/types/policies/${groupId}`).subscribe( res => {
      this.http.get(API_REST_ADMIN +  `/centralmapping/types/policies/${groupId}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getusers(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:7084/api/centralmapping/users`,data).subscribe( res => {
      this.http.post(API_REST_ADMIN + `/centralmapping/users`, data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
 ---------------------------- U S E R S A D M I N S C L I E N T S ---------------------------------------
 ----------------------------------------------------------------------------------------------------- */

  public getUsersAdminClientPagination(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/directories/administrators/clients/paginations', data ).subscribe( res => {
      // this.http.post('http://localhost:7010/api/directories/administrators/clients/paginations', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public deleteUserAdminClient(userId): Observable<any> {
    return this.http.delete(API_REST_ADMIN +  '/directories/administrators/clients/' + userId);
    // return this.http.delete('http://localhost:7010/api/directories/administrators/clients/' + userId);
  }

  public getUserByGroup(groupId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/directories/administrators/clients/groups/' + groupId).subscribe( res => {
      //  this.http.get('http://localhost:7010/api/directories/administrators/clients/groups/' + groupId ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postSaveUserAdmin(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7019/api/core/directories/administrators/clients', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/directories/administrators/clients', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getUserAdminById(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/directories/administrators/clients/' + id).subscribe( res => {
      // this.http.get('http://localhost:7019/api/core/directories/administrators/clients/' + id ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postUpdateUserAdmin(data) {
    return new Promise((response, error) => {
       // this.http.put('http://localhost:7019/api/core/directories/administrators/clients', data ).subscribe( res => {
        this.http.put(API_REST_ADMIN + '/directories/administrators/clients', data ).subscribe( res => {
          response(res);
        }, (err) => {
          error(err);
        });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
  -------------------------------------- M E N S A J E R I A ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  public getTemplates() {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN +  '/directories/templates/emails').subscribe( res => {
        // this.http.get('http://localhost:7010/api' +  '/directories/templates/emails').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public saveTemplates(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/directories/templates/emails',  data).subscribe( res => {
      //  this.http.post('http://localhost:7010/api/core' +  '/directories/templates/emails',  data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postSendEmailMass(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/centralmapping/notifications', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/notifications', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPoliciesMessaging(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/centralmapping/users/messaging/policies', data).subscribe( res => {
      // this.http.post('http://localhost:7084/api' +  '/centralmapping/users/messaging/policies' , data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCategoriesByPolicyMessaging(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/centralmapping/users/messaging/policies/categories/' + id).subscribe( res => {
      // this.http.get('http://localhost:7084/api' +  '/centralmapping/users/messaging/policies/categories/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getUsersMessagingPagination(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/centralmapping/users/messaging', data ).subscribe( res => {
        // this.http.post('http://localhost:7084/api/centralmapping/users/messaging', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getImportOverviewMessaging(eventId) {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN +  '/events/import/overview/messaging/' + eventId).subscribe( res => {
        // this.http.get('http://localhost:7064/api/events/import/overview/messaging/' + eventId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getReportImportOverviewMessaging(eventId) {
    return this.http.get(`${API_REST_ADMIN}/events/download/import/overview/Messaging/${eventId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:7064/api/events/download/import/overview/Messaging/${eventId}`, {responseType: 'arraybuffer'});
  }

  public getReportImportOverviewCar(eventId) {
    return this.http.get(`${API_REST_ADMIN}/events/download/import/overview/massivecar/${eventId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:7064/api/events/download/import/overview/massivecar/${eventId}`, {responseType: 'arraybuffer'});
  }

  public getReportImportOverviewCancelPolicy(eventId) {
    return this.http.post(`${API_REST_ADMIN}/events/download/import/overview/cancelledPolicy`, eventId, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:7064/api/events/download/import/overview/cancelledPolicy`, eventId, {responseType: 'arraybuffer'});
  }

  public getReportImportOverviewInsuredInfoUpdated(eventId) {
    return this.http.post(`${API_REST_ADMIN}/events/download/import/overview/insuredInfoUpdated`, eventId, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:7064/api/events/core/download/import/overview/insuredInfoUpdated`, eventId, {responseType: 'arraybuffer'});
  }

  public getReportImportOverviewCarIndividualMassive(eventId) {
    return this.http.get(`${API_REST_ADMIN}/events/download/import/overview/massivecarindividual/${eventId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:7064/api/core/events/download/import/overview/massivecarindividual/${eventId}`, {responseType: 'arraybuffer'});
  }

  public getReportImportOverviewRequest(eventId) {
    return this.http.get(`${API_REST_ADMIN}/events/download/import/overview/massiverequest/${eventId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:7064/api/events/download/import/overview/massiverequest/${eventId}`, {responseType: 'arraybuffer'});
  }


  public getImportOverviewCar(eventId) {
    return new Promise((response, error) => {

        this.http.get(API_REST_ADMIN +  '/events/import/overview/car/' + eventId).subscribe( res => {
        // this.http.get('http://localhost:7064/api/events/import/overview/car/' + eventId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getImportOverviewCancelPolicies(eventId) {
    return new Promise((response, error) => {

      this.http.post(API_REST_ADMIN +  '/events/import/overview/cancelledPolicy', eventId).subscribe( res => {
        // this.http.post('http://localhost:7064/api/events/import/overview/car', eventId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getImportOverviewInsuredUpdated(eventId) {
    return new Promise((response, error) => {

      this.http.post(API_REST_ADMIN +  '/events/import/overview/insuredUpdated', eventId).subscribe( res => {
      // this.http.post('http://localhost:7064/api/core/events/import/overview/insuredUpdated', eventId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getImportOverviewCarIndividual(eventId) {
    return new Promise((response, error) => {

      this.http.get(API_REST_ADMIN +  '/events/import/carindividual/' + eventId).subscribe( res => {
        // this.http.get('http://localhost:7064/api/events/import/carindividual/' + eventId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getImportOverviewRequest(eventId) {
    return new Promise((response, error) => {

      this.http.get(API_REST_ADMIN +  '/events/import/overview/request/' + eventId).subscribe( res => {
      // this.http.get('http://localhost:7064/api/events/import/overview/request/' + eventId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public putReceiptDataGeneral(data) {
    return new Promise((response, error) => {
      // this.http.put('http://localhost:7013/api/core/receipts/updatereceipts/policies', data).subscribe( res => {
      this.http.put(`${API_REST_ADMIN}/receipts/updatereceipts/policies`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
 ---------------------------- D A M A G E S ---------------------------------------
 ----------------------------------------------------------------------------------------------------- */
  public getSectionById(data) {
    return new Promise((response, error) => {
      // tslint:disable-next-line:max-line-length
      this.http.post(API_REST_ADMIN +  '/catalogs_coverages/damages/sections/details',  data).subscribe( res => {
        // tslint:disable-next-line:max-line-length
       // this.http.post('http://localhost:7028/api/core/catalogs_coverages/damages/sections/details', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getSectionByGroups(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/catalogs_coverages/damages/groups/sections', data).subscribe( res => {
      // this.http.post('http://localhost:7028/api/core/catalogs_coverages/damages/groups/sections', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCollectionsByPolicyDamages(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/policies/collections/damages', data ).subscribe( res => {
      // this.http.post('http://localhost:7006/api/core' +  '/policies/collections/damages',  data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReceiptPolicyCategoryDamages(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN +  '/policies/receipts/damages', data).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core' +  '/policies/receipts/damages', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postReceiptDataDamages(data) {
    return new Promise((response, error) => {
        this.http.post(`${API_REST_ADMIN}/policies/receipts/automatics/damages`, data).subscribe( res => {
        // this.http.post(`http://localhost:7013/api/core/policies/receipts/automatics/damages`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getTypePaymentsPolicyDamages() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN +  '/policies/damages/collections').subscribe( res => {
      //  this.http.get('http://localhost:7006/api' +  '/policies/damages/collections').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getLocationsByPolicy(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/centralmapping/damages/policies/locations', data ).subscribe( res => {
      // this.http.post('http://localhost:7084/api/core/centralmapping/damages/policies/locations', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCatalogDamagesWall() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/damages/catalogs/walls' ).subscribe( res => {
      // this.http.get('http://localhost:7004/api/insurance-companies/damages/catalogs/walls' ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCatalogDamagesSecurity() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/damages/catalogs/security/measures' ).subscribe( res => {
      // this.http.get('http://localhost:7004/api/insurance-companies/damages/catalogs/security/measures' ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCatalogDamagesFire() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/damages/catalogs/fire/protections' ).subscribe( res => {
      // this.http.get('http://localhost:7004/api/insurance-companies/damages/catalogs/fire/protections' ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getContactDamagePagination(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/damages/contacts/pages', data ).subscribe( res => {
      //  this.http.post('http://localhost:7006/api/policies/damages/contacts/pages', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getContactsDamageById(contactId) {
    return new Promise((response, error) => {
       this.http.get(API_REST_ADMIN + '/policies/damages/contacts/' + contactId).subscribe( res => {
      //  this.http.get('http://localhost:7006/api/policies/damages/contacts/' + contactId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getLocationsDamageByPolicyId(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/damages/locations/contacts', data).subscribe( res => {
      // this.http.post('http://localhost:7006/api/core/policies/damages/locations/contacts', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postContactDamage(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/damages/contacts', data ).subscribe( res => {
      //  this.http.post('http://localhost:7006/api/policies/damages/contacts', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public putContactDamage(data) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/policies/damages/contacts', data ).subscribe( res => {
      // this.http.put('http://localhost:7006/api/policies/damages/contacts', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public deleteContactDamageById(contactId): Observable<any> {
    return this.http.delete(API_REST_ADMIN +  '/policies/damages/contacts/' + contactId);
    // return this.http.delete('http://localhost:7006/api/policies/damages/contacts/' + contactId);
  }
  public validationIssuancePolicyDamages(policyId) {
    const formParam = new HttpParams()
      .set('policyId', policyId);
    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/damages/validations/issues/policies', formParam, {
      //  this.http.post('http://localhost:7006/api' + '/policies/damages/validations/issues/policies', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public putChangeStatusPolicyDamage(data) {
    const formParam = new HttpParams()
      .set('policyId', data.policyId)
      .set('statusId', data.statusId)
      .set('number', data.number);
    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/policies/status/damages', formParam, {
      //  this.http.put('http://localhost:7006/api' + '/policies/status/damages', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public putReceiptDataDamages(data) {
    return new Promise((response, error) => {
      // this.http.put('http://localhost:7013/api/core/receipts/damages/policies', data).subscribe( res => {
      this.http.put(`${API_REST_ADMIN}/receipts/damages/policies`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postLocationDamages(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/damages/locations', data).subscribe( res => {
      // this.http.post('http://localhost:7006/api' + '/policies/damages/locations', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public updateLocationDamages(data) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/policies/damages/locations', data).subscribe( res => {
      // this.http.put('http://localhost:7006/api' + '/policies/damages/locations', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getLocationDamageById( data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/damages/locations/ubications', data).subscribe( res => {
      // this.http.post('http://localhost:7006/api/core' + '/policies/damages/locations/ubications', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public deleteLocationDamageById(id): Observable<any> {
    return this.http.delete(API_REST_ADMIN +  '/policies/damages/locations/' + id);
    // return this.http.delete('http://localhost:7006/api/policies/damages/locations/' + id);
  }
  public postSectionDamageByLocation(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/sections/locations', data ).subscribe( res => {
        // this.http.post('http://localhost:7028/api/catalogs_coverages/damages/sections/locations', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public deleteSectionLocationDamageById(locationId, sectionId): Observable<any> {
    return this.http.delete(API_REST_ADMIN +  '/catalogs_coverages/damages/sections/locations/' + sectionId + '/' + locationId);
    // return this.http.delete('http://localhost:7028/api/catalogs_coverages/damages/sections/locations/' + sectionId + '/' + locationId);
  }

  public postDuplicateLocation(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/sections/locations/duplicate', data ).subscribe( res => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages/damages/sections/locations/duplicate', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getLayoutFileDamageSections(type) {
    return this.http.get(`${API_REST_ADMIN}/catalogs_coverages/layouts/damages/sections/${type}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:7028/api/catalogs_coverages/layouts/damages/sections/${type}`, {responseType: 'arraybuffer'});
  }
  public getLayoutFileDamageLocation(data) {
    return this.http.post(`${API_REST_ADMIN}/catalogs_coverages/layouts/damages/locations/`, data, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:7028/api/catalogs_coverages/layouts/damages/locations/`, data, {responseType: 'arraybuffer'});
  }

  public postImportLocationDamage(data, policyId) {
    // return this.http.post(`http://localhost:7084/api/centralmapping/damages/massive/imports/locations?policyId=${policyId}`, data);
    return this.http.post(`${API_REST_ADMIN}/centralmapping/damages/massive/imports/locations?policyId=${policyId}`, data);
  }

  public postLocationsDamages(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/massive/imports/sections/process', data ).subscribe( res => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages/damages/massive/imports/sections/process', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postImportSectionDamage(data, layoutId, sectionId, policyId) {
    // tslint:disable-next-line:max-line-length
    // return this.http.post(`http://localhost:7028/api/catalogs_coverages/damages/massive/imports/sections?layoutId=${layoutId}&sectionId=${sectionId}&policyId=${policyId}`, data);
    // tslint:disable-next-line:max-line-length
    return this.http.post(`${API_REST_ADMIN}/catalogs_coverages/damages/massive/imports/sections?layoutId=${layoutId}&sectionId=${sectionId}&policyId=${policyId}`, data);
  }

  public getOffices() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/policies/offices').subscribe( res => {
      // this.http.get('http://localhost:7006/api' + '/policies/offices').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getBranchesGeneral() {
    return new Promise((response, error) => {
        this.http.get(API_REST_ADMIN +  '/branches/general').subscribe( res => {
        // this.http.get('http://localhost:7014/api' +  '/branches/general').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postMergeDocument(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/merge/documents', data ).subscribe( res => {
      //  this.http.post('http://localhost:7028/api/catalogs_coverages/merge/documents', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCoveragesAmmiaHousing(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/catalogs_coverages/ammia/coverages/' + id).subscribe( res => {
      // this.http.get('http://localhost:7028/api/catalogs_coverages/ammia/coverages/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getAddressHousing(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/locations/housing/types', data).subscribe( res => {
      // this.http.post('http://localhost:7028/api/core/catalogs_coverages/damages/locations/housing/types', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCatalogTypeHousing() {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/insurance-companies/damages/catalogs/types/housing' ).subscribe( res => {
      //   this.http.get('http://localhost:7004/api/insurance-companies/damages/catalogs/types/housing' ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postLocationHousing(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/locations', data ).subscribe( res => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages/damages/locations', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public putLocationHousing(data) {
    return new Promise((response, error) => {
      this.http.put(API_REST_ADMIN + '/catalogs_coverages/damages/locations', data ).subscribe( res => {
      // this.http.put('http://localhost:7028/api/catalogs_coverages/damages/locations', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postEmmitPolicyHousing(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/emmit/policies/housing', data ).subscribe( res => {
      // this.http.post('http://localhost:7028/api/core/catalogs_coverages/emmit/policies/housing', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCoveragesAmmiaCellPhone(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/centralmapping/damages/details/ammia/coverages/' + id).subscribe( res => {
      // this.http.get('http://localhost:7084/api/centralmapping/damages/details/ammia/coverages/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postStreet(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/address/streets', data ).subscribe( res => {
      // this.http.post('http://localhost:7000/api/address/streets', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getNumberLocationAutomatic(policyId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/catalogs_coverages/damages/locations/numbers/automatic/' + policyId).subscribe( res => {
      //  this.http.get('http://localhost:7028/api/catalogs_coverages/damages/locations/numbers/automatic/' + policyId ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getDetailCivilWork(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/locations/civil/works/details', data).subscribe( res => {
      // this.http.post('http://localhost:7028/api/core/catalogs_coverages/damages/locations/civil/works/details', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoverageBasicsCivilWork(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/civil/works/coverage/basics', data).subscribe( res => {
      // this.http.post('http://localhost:7028/api/core/catalogs_coverages/damages/civil/works/coverage/basics', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public postCoverageBasicsCivilWork(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/coverage/civil/works/', data ).subscribe( res => {
      //  this.http.post('http://localhost:7028/api/catalogs_coverages/damages/coverage/civil/works/', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoverageAdditionalCivilWork(policyId) {
    return new Promise((response, error) => {
      this.http.get(API_REST_ADMIN + '/catalogs_coverages/damages/civil/works/coverage/basics/' + policyId).subscribe( res => {
      // this.http.get('http://localhost:7028/api/catalogs_coverages/damages/civil/works/coverage/basics/' + policyId ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoverageAdditionalById(data) {
    return new Promise((response, error) => {
      // tslint:disable-next-line:max-line-length
      this.http.post(API_REST_ADMIN +  '/catalogs_coverages/damages/civil/works/additional/details', data).subscribe( res => {
        // tslint:disable-next-line:max-line-length
      // this.http.post('http://localhost:7028/api/core/catalogs_coverages/damages/civil/works/additional/details', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postCoverageAdditionalDamageCivilWork(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/coverage/specials/civil/works/', data ).subscribe( res => {
      //  this.http.post('http://localhost:7028/api/catalogs_coverages/damages/coverage/specials/civil/works/', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postCivilWork(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/locations/civil/works', data ).subscribe( res => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages/damages/locations/civil/works', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateCivilWork(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/locations/civil/works', data ).subscribe( res => {
      // this.http.put('http://localhost:7028/api/catalogs_coverages/damages/locations/civil/works', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getEndorsementCivilWork(id) {
    return new Promise((response, error) => {
      // tslint:disable-next-line:max-line-length
      this.http.get(API_REST_ADMIN +  '/catalogs_coverages/damages/endorsements/civil/works/' + id).subscribe( res => {
      // tslint:disable-next-line:max-line-length
      // this.http.get('http://localhost:7028/api/catalogs_coverages/damages/endorsements/civil/works/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getEndorsementCivilWorkByLocation(data) {
    return new Promise((response, error) => {
      // tslint:disable-next-line:max-line-length
      this.http.post(API_REST_ADMIN +  '/catalogs_coverages/damages/endorsements/civil/works/locations', data).subscribe( res => {
      // tslint:disable-next-line:max-line-length
      // this.http.post('http://localhost:7028/api/core/catalogs_coverages/damages/endorsements/civil/works/locations', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postCivilWorkEndorsement(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/endorsements/civil/works/', data ).subscribe( res => {
      // this.http.post('http://localhost:7028/api/catalogs_coverages/damages/endorsements/civil/works/', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public deleteEndorsementLocationDamageById(locationId, sectionId): Observable<any> {
    return this.http.delete(API_REST_ADMIN +  '/catalogs_coverages/damages/endorsements/' + sectionId + '/' + locationId);
    // return this.http.delete('http://localhost:7028/api/catalogs_coverages/damages/endorsements/' + sectionId + '/' + locationId);
  }


  public postGeneratedProvisions(data) {
    // return this.http.post(`http://localhost:7013/api/core/certificates/regenerateProvision`, data);
    return this.http.post(`${API_REST_ADMIN}/certificates/regenerateProvision`, data);
  }

  public getCertificatesZipByUserId(userId) {
    // tslint:disable-next-line:max-line-length
    // return this.http.get(`http://localhost:7056/api/core/certificates/getCertificatesZipByUserId/${userId}`, {responseType: 'arraybuffer'});
    return this.http.get(`${API_REST_ADMIN}/certificates/getCertificatesZipByUserId/${userId}`, {responseType: 'arraybuffer'});
  }

  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- E N D O R S E M E N T C I V I L W O R K ----------------------------------
  ----------------------------------------------------------------------------------------------------- */
  public getEndorsementsPagination(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/core/catalogs_coverages/damages/endorsements/filters', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/endorsements/filters', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getEndorsementsCoincidence(data) {
    return new Promise((response, error) => {
      // tslint:disable-next-line:max-line-length
      // this.http.post('http://localhost:7028/api/core/catalogs_coverages/damages/endorsements/coincidences/adds',  data).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/endorsements/coincidences/adds',  data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public deleteEndorsementCivilWorkById(id): Observable<any> {
    return this.http.delete(API_REST_ADMIN +  '/catalogs_coverages/damages/endorsements/civil/work/' + id );
    // return this.http.delete('http://localhost:7028/api/core/catalogs_coverages/damages/endorsements/civil/work/' + id );
  }

  public saveEndorsementCivilWork(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7028/api/core/catalogs_coverages/damages/endorsements/civil/work', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/catalogs_coverages/damages/endorsements/civil/work', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getEndorsementById(id) {
    return new Promise((response, error) => {
      // tslint:disable-next-line:max-line-length
      this.http.get(API_REST_ADMIN +  '/catalogs_coverages/damages/endorsements/civil/work/' + id).subscribe( res => {
        // tslint:disable-next-line:max-line-length
      //  this.http.get('http://localhost:7028/api/core/catalogs_coverages/damages/endorsements/civil/work/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  /* -----------------------------------------------------------------------------------------------------
  --------------I N I C I O  M O D U L O   D E   C A R G A   M A S I V A   D E   P O L I Z A S -----------
  ----------------------------------------------------------------------------------------------------- */

  public getLayout() {
    return this.http.post(API_REST_ADMIN + '/centralmapping/layouts/massive_policy', {}, {responseType: 'arraybuffer'});
    // return this.http.post('http://localhost:7003/api/core/centralmapping/layouts/massive_policy', {}, {responseType: 'arraybuffer'});
  }

  public loadMassivePolicy(data) {
    return this.http.post(API_REST_ADMIN + '/policies/loadMassiveInitial', data);
    // return this.http.post('http://localhost:7006/api/core/policies/loadMassiveInitial', data);
  }

  public getMassivePolicyTemp(idCarga) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + `/policies/getDataInitial/${idCarga}`, '').subscribe( res => {
      // this.http.post(`http://localhost:7006/api/core/policies/getDataInitial/${idCarga}`, '').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public validateLoadMassivePolicy(data) {
    return this.http.post(API_REST_ADMIN + '/centralmapping/validateMassiveInitial', data);
    // return this.http.post('http://localhost:7084/api/core/centralmapping/validateMassiveInitial', data);
  }

  public updateMassivePolicy(data) {
    return this.http.post(API_REST_ADMIN + '/policies/updateMassiveInitial', data);
    // return this.http.post('http://localhost:7006/api/core/policies/updateMassiveInitial', data);
  }

  public deleteRowLoadMassive(idDetalle) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + `/policies/delDataInitial/${idDetalle}`, '').subscribe( res => {
      // this.http.post(`http://localhost:7006/api/core/policies/delDataInitial/${idDetalle}`, '').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });

  }

  public loadMassiveClientFisico(data) {
    return this.http.post(API_REST_ADMIN + '/clients/loadMassiveInitialFisico', data);
    // return this.http.post('http://localhost:7002/api/core/clients/loadMassiveInitialFisico', data);
  }

  public getMassiveClienteTempFisico(idCarga) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + `/clients/getDataInitialFisico/${idCarga}`, '').subscribe( res => {
      // this.http.post(`http://localhost:7002/api/core/clients/getDataInitialFisico/${idCarga}`, '').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public validateLoadMassiveClientFisico(data) {
    return this.http.post(API_REST_ADMIN + '/centralmapping/validateMassiveInitialFisicos', data);
    // return this.http.post('http://localhost:7084/api/core/centralmapping/validateMassiveInitialFisicos', data);
  }

  public deleteRowLoadMassiveFisico(idDetalle) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + `/clients/delDataInitialFisico/${idDetalle}`, '').subscribe( res => {
      // this.http.post(`http://localhost:7002/api/core/clients/delDataInitialFisico/${idDetalle}`, '').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });

  }

  public updateMassiveClienteFisico(data) {
    return this.http.post(API_REST_ADMIN + '/clients/updateMassiveInitialFisico', data);
    // return this.http.post('http://localhost:7002/api/core/clients/updateMassiveInitialFisico', data);
  }

  public loadMassiveClientMoral(data) {
    return this.http.post(API_REST_ADMIN + '/clients/loadMassiveInitialMoral', data);
    // return this.http.post('http://localhost:7002/api/core/clients/loadMassiveInitialMoral', data);
  }

  public getMassiveClienteTempMoral(idCarga) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + `/clients/getDataInitialMoral/${idCarga}`, '').subscribe( res => {
      // this.http.post(`http://localhost:7002/api/core/clients/getDataInitialMoral/${idCarga}`, '').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public validateLoadMassiveClientMoral(data) {
    return this.http.post(API_REST_ADMIN + '/centralmapping/validateMassiveInitialMorales', data);
    // return this.http.post('http://localhost:7084/api/core/centralmapping/validateMassiveInitialMorales', data);
  }

  public updateMassiveClienteMoral(data) {
    return this.http.post(API_REST_ADMIN + '/clients/updateMassiveInitialMoral', data);
    // return this.http.post('http://localhost:7002/api/core/clients/updateMassiveInitialMoral', data);
  }

  public deleteRowLoadMassiveMoral(idDetalle) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + `/clients/delDataInitialMoral/${idDetalle}`, '').subscribe( res => {
      // this.http.post(`http://localhost:7002/api/core/clients/delDataInitialMoral/${idDetalle}`, '').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });

  }

  public processingInfoFisica(data) {
    return this.http.post(API_REST_ADMIN + '/clients/saveClientPhysicalMassive', data);
    // return this.http.post('http://localhost:7002/api/core/clients/saveClientPhysicalMassive', data);
  }

  public processingInfoMoral(data) {
    return this.http.post(API_REST_ADMIN + '/clients/saveGroupMassive', data);
    // return this.http.post('http://localhost:7002/api/core/clients/saveGroupMassive', data);
  }

  public processingInfoPolicy(data) {
    return this.http.post(API_REST_ADMIN + '/policies/savePolicyMassive', data);
    // return this.http.post('http://localhost:7006/api/core/policies/savePolicyMassive', data);
  }

  /* -----------------------------------------------------------------------------------------------------
    --------------F I N A L  M O D U L O   D E   C A R G A   M A S I V A   D E   P O L I Z A S -----------
    ----------------------------------------------------------------------------------------------------- */



  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- M O D U L E R E P O R T S ----------------------------------
  ----------------------------------------------------------------------------------------------------- */
  public getReportsPagination(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7078/api/core/report/types/filters', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/report/types/filters', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportFileById(reportId) {
   return this.http.get(`${API_REST_ADMIN}/report/downloads/${reportId}`, {responseType: 'arraybuffer'});
   // return this.http.get('http://localhost:7078/api/core' + `/report/downloads/${reportId}`, {responseType: 'arraybuffer'});
  }

  public deleteReportCoreId(data): Observable<any> {
    return this.http.post(API_REST_ADMIN +  '/report/types/delete', data );
    // return this.http.post('http://localhost:7078/api/core/report/types/delete', data );
  }

  public saveReportCore(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7078/api/core/report/types', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/report/types', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- DYNAMIC REPORTS ----------------------------------
  ----------------------------------------------------------------------------------------------------- */
  public getDynamicReports(params: any) {
    return this.http.post(`${API_REST_ADMIN}/report/dynamic_reports/by-current-user`, params);
    // return this.http.post(`http://localhost:7078/api/core/report/dynamic_reports/by-current-user`, params);
  }

  public getTopics() {
    return this.http.post(`${API_REST_ADMIN}/report/dynamic_reports/topics/list`, {});
    // return this.http.post(`http://localhost:7078/api/core/report/dynamic_reports/topics/list`, {});
  }

  public getVariablesByTopic(params: any) {
    return this.http.post(`${API_REST_ADMIN}/report/dynamic_reports/topics_variables/by-topic`, params);
    // return this.http.post(`http://localhost:7078/api/core/report/dynamic_reports/topics_variables/by-topic`, params);
  }

  public dinamicQuery(params: any) {
    return this.http.post(`${API_REST_ADMIN}/centralmapping/dynamic_reports/dynamic_query`, params);
    // return this.http.post(`http://localhost:7003/api/core/centralmapping/dynamic_reports/dynamic_query`, params);
  }

  public deleteDynamicReport(params: any) {
    return this.http.delete(`${API_REST_ADMIN}/report/dynamic_reports`, params);
    // return this.http.post(`http://localhost:7078/api/core/report/dynamic_reports`, params);
  }

  public saveDynaminReport(params: any) {
    return this.http.post(`${API_REST_ADMIN}/report/dynamic_reports/save/by-current-user`, params);
    // return this.http.post(`http://localhost:7078/api/core/report/dynamic_reports/save/by-current-user`, params);
  }

  public saveDynaminReportJob(params: any) {
    return this.http.post(`${API_REST_ADMIN}/report/dynamic_reports_jobs/save`, params);
    // return this.http.post(`http://localhost:7078/api/core/report/dynamic_reports_jobs/save`, params);
  }

  public updateDynamicReportJob(params: any) {
    return this.http.post(`${API_REST_ADMIN}/report/dynamic_reports_jobs/update`, params);
    // return this.http.put(`http://localhost:7078/api/core/report/dynamic_reports_jobs/update`, params);
  }

  public updateDynamicReportNoSchedule(params: any) {
    return this.http.put(`${API_REST_ADMIN}/report/dynamic_reports/update/no-scheduled`, params);
    // return this.http.put(`http://localhost:7078/api/core/report/dynamic_reports/update/no-scheduled`, params);
  }

  public getDynamicReportJobByDynamicReport(params: any) {
    return this.http.post(`${API_REST_ADMIN}/report/dynamic_reports_jobs/get/by-dynamic_report`, params);
    // return this.http.post(`http://localhost:7078/api/core/report/dynamic_reports_jobs/get/by-dynamic_report`, params);
  }

  public getDynamicReport(params: any) {
    return this.http.post(`${API_REST_ADMIN}/report/dynamic_reports/get`, params);
    // return this.http.post(`http://localhost:7078/api/core/report/dynamic_reports/get`, params);
  }

  public getCatalogo(urlCatalogo: string) {
  //  return this.http.post(`${API_REST_ADMIN}/report/dynamic_reports/by-current-user`, {});
    return this.http.get(urlCatalogo);
  }

  public postCatalogo(urlCatalogo: string, params) {
  //  return this.http.post(`${API_REST_ADMIN}/report/dynamic_reports/by-current-user`, {});
    return this.http.post(urlCatalogo, params);
  }

  public getBranchesByGroupReportIncident(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/core/centralmapping/reports/groups/branches', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/reports/groups/branches', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getInsuranceByGroupBranchReportIncident(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/core/centralmapping/reports/groups/branches/insurances', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/reports/groups/branches/insurances', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getConfigurationInitialByInsuranceReportIncident(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7078/api/core/report/configurations/incidents', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/report/configurations/incidents', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getConfigurationHeadersByInsuranceReportIncident(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7078/api/core/report/configurations/validations/headers', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/report/configurations/validations/headers', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getPolicyByGroupReportInsurance(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7084/api/core/centralmapping/reports/policies/groups', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/centralmapping/reports/policies/groups', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  public processReportIncident(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7078/api/core/report/configurations/reads/files/incidents', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/report/configurations/reads/files/incidents', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public processReportIncidentSave(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7078/api/core/report/configurations/reads/files/incidents/saves', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/report/configurations/reads/files/incidents/saves', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPoliciesNotFoundReport(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7078/api/core/report/configurations/reads/files/incidents/policies', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/report/configurations/reads/files/incidents/policies', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRecordsByReport(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7078/api/core/report/configurations/reads/files/incidents/results', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/report/configurations/reads/files/incidents/results', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updatePoliciesByReportIncident(data) {
    return new Promise((response, error) => {
      // this.http.put('http://localhost:7078/api/core/report/configurations/reads/files/incidents/relations/policies', data ).subscribe( res => {
      this.http.put(API_REST_ADMIN + '/report/configurations/reads/files/incidents/relations/policies', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
    ----------------------------- M O D U L E R E P O R T S ----------------------------------
    ----------------------------------------------------------------------------------------------------- */
  public getConfigurationNotificationsPagination(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/centralmapping/notifications/payments/filter', data ).subscribe( res => {
        // this.http.post('http://localhost:7084/api/core/centralmapping/notifications/payments/filter', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveConfigurationNotificationsPayment(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/receipts/notifications/payments/configurations', data ).subscribe( res => {
      // this.http.post('http://localhost:7008/api/core/receipts/notifications/payments/configurations', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public sendPoliciesForCancell(policiesString) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/cancelPoliciesMassive', policiesString).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core' + '/policies/cancelPoliciesMassive', policiesString).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public downloadInsuredListXLS(groupId) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7010/api/core/directories/getInsuredInfo', groupId, {responseType: 'arraybuffer'} ).subscribe( res => {
      this.http.post(API_REST_ADMIN +  '/directories/getInsuredInfo', groupId, {responseType: 'arraybuffer'}).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postInsuredData(data) {
    return new Promise((response, error) => {
      this.http.post(`${API_REST_ADMIN}/directories/updateInsuredInfo`, data).subscribe( res => {
      // this.http.post('http://localhost:7010/api/core/directories/updateInsuredInfo', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public sendEmailManually(policiesString) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/directories/sendEmailManually', policiesString).subscribe( res => {
      // this.http.post('http://localhost:7010/api/core' + '/directories/sendEmailManually', policiesString).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }




  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- E N D O R S E M E N T S P O L I C I E S ----------------------------------
  ----------------------------------------------------------------------------------------------------- */
  public getEndorsementsByPolicy(data) {
    return new Promise((response, error) => {
        this.http.post(API_REST_ADMIN + '/policies/endorsements/by/policies', data ).subscribe( res => {
        // this.http.post('http://localhost:7006/api/core/policies/endorsements/by/policies', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getEndorsementsReceiptsByEndorsement(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/endorsements/detail/by/id', data ).subscribe( res => {
      // this.http.post('http://localhost:7006/api/core/policies/endorsements/detail/by/id', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveEndorsements(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/endorsements', data ).subscribe( res => {
      // this.http.post('http://localhost:7006/api/core/policies/endorsements', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getEndorsementsById(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/endorsements/detail/by/id', data ).subscribe( res => {
      // this.http.post('http://localhost:7006/api/core/policies/endorsements/detail/by/id', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getEndorsementFile(data) {
    return this.http.post(API_REST_ADMIN + '/policies/endorsements/files', data, {responseType: 'arraybuffer'});
    // return this.http.post('http://localhost:7006/api/core/policies/endorsements/files', data,  {responseType: 'arraybuffer'});
  }

  public putReceiptDataDamagesEndorsement(data) {
    return new Promise((response, error) => {
      // this.http.put('http://localhost:7006/api/core/policies/endorsements/receipts/policies', data).subscribe( res => {
      this.http.put(`${API_REST_ADMIN}/policies/endorsements/receipts/policies`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getValidateEndorsementByPolicy(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/endorsements/validate/numbers', data ).subscribe( res => {
      //  this.http.post('http://localhost:7006/api/core/policies/endorsements/validate/numbers', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public closeEndorsements(data) {
      return new Promise((response, error) => {
          this.http.post(API_REST_ADMIN + '/policies/closeEndorsements', data ).subscribe( res => {
          // this.http.post('http://localhost:7006/api/core/policies/closeEndorsements', data ).subscribe( res => {
              response(res);
          }, (err) => {
              error(err);
          });
      });
  }

  /****************************************************************************************************************
   ********************* servicios ammia **************************************************************************/
  public getPublicKey() {
    return new Promise((response, error) => {
    this.http.post(API_REST_AMMIA + '/system/policy/getPublicPolicy', null, ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  getPublicKey1(): Observable<any> {
    return this.http.post(API_REST_AMMIA + '/system/policy/getPublicPolicy', null, );
  }

  public getLoginAmmia(params) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA + '/login/login', params ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public sendMessageFromCoreIncident(message) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA_WEB + '/executive_app/incident/sendMessageFromCoreApp', message ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public sendMessageFromCoreIncidentLetter(message) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA_WEB + '/executive_app/incident/sendSettlementLetterFromCoreApp', message ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public sendMessageFromCoreRequest(message) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA_WEB + '/executive_app/request/sendMessageFromCoreApp', message ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRolSecondary(data) {
    return new Promise((response, error) => {
       this.http.post(API_REST_ADMIN + '/users/multiple/profiles/users', data ).subscribe( res => {
      //  this.http.post('http://localhost:7019/api/core/users/multiple/profiles/users', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateSecondaryRol(data) {
    return new Promise((response, error) => {
       this.http.put(API_REST_ADMIN + '/users/multiple/profiles', data ).subscribe( res => {
      // this.http.put('http://localhost:7019/api/core/users/multiple/profiles', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveSecondaryRol(data) {
    return new Promise((response, error) => {
       this.http.post(API_REST_ADMIN + '/users/multiple/profiles', data ).subscribe( res => {
      // this.http.post('http://localhost:7019/api/core/users/multiple/profiles', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  /* -----------------------------------------------------------------------------------------------------
    -------------------------------------- P L A C E M E N T ---------------------------------------------
    ----------------------------------------------------------------------------------------------------- */

    public getplacement(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/placement/placementlist', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/placement/placementlist', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public clonePlacement(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/placement/clonePlacement', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/placement/clonePlacement', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveDataCompany(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/placement/saveDataCompany', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/placement/saveDataCompany', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getSubBranchesPlacement() {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/placement/getAllSubranches', {} ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/placement/getAllSubranches', {} ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoveragesBySubranchId(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/placement/getCoveragesBySubranch', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/placement/getCoveragesBySubranch', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCoveragesByGroupId(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/placement/getCoveragesByGroup', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/placement/getCoveragesByGroup', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getEndorsementBySubranchId(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/placement/getEndorementBySubranch', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/placement/getEndorementBySubranch', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveCategorySubranchCategories(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/placement/saveSubranchCategory', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/placement/saveSubranchCategory', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCompanyInformtion(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/placement/getDataCompany', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/placement/getDataCompany', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPackagesByBranchId(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/placement/getPackagesByBranch', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/placement/getPackagesByBranch', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public deleteCategory(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/placement/deleteCategory', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/placement/deleteCategory', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public downloadReportPlacementCoverages(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7013/api/core/placement/getReportPlacement', data, {responseType: 'arraybuffer'} ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/placement/getReportPlacement', data, {responseType: 'arraybuffer'}).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public stablishBaseVersion(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7013/api/core/placement/changeStatus', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/placement/changeStatus', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
    -------------------------------------- L I N K   DE  P A G O------------------------------------------
    ----------------------------------------------------------------------------------------------------- */

  public getPaymentLink(data) {
    return new Promise((response, error) => {
      // this.http.post('https://qa.ammia.store/api/ammia/v1.0/public/emission/getPaymentLink', data).subscribe( res => {
      this.http.post(API_REST_AMMIA_WEB +  '/emission/getPaymentLink', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  /* -----------------------------------------------------------------------------------------------------
   -------------------------------------- P O L I C Y   A U T O M A T I O N-------------------------------
   ----------------------------------------------------------------------------------------------------- */

  public getDataFromPolicyAutomationById(data) {
    return new Promise((response, error) => {
      // this.http.post('http://localhost:7013/api/core/policies/getDataFromPolicyAutomationById', data ).subscribe( res => {
      this.http.post(API_REST_ADMIN + '/policies/getDataFromPolicyAutomationById', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getAutomationProcess(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/automationList', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/policies/automationList', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public saveProcessLoadingAutomation(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/saveProcess', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/policies/saveProcess', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public startLoadingAutomationPolicy(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_AMMIA_WEB + '/automations/policy/startLoadingPolicyProcess', data ).subscribe( res => {
      // this.http.post('http://localhost:8816/api/ammia/v1.0/protected/automations/policy/startLoadingPolicyProcess', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public savePhase(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/savePhase', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/policies/savePhase', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public downloadInsuredFile(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/downloadInsured', data, {responseType: 'arraybuffer'} ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/policies/downloadInsured', data, {responseType: 'arraybuffer'} ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public generatePolicy(idProcess) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/generatePolicyFromPolicyAutomations', idProcess ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/policies/generatePolicyFromPolicyAutomations', idProcess ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public uploadInsurer(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST_ADMIN + '/policies/uploadInsured', data ).subscribe( res => {
      // this.http.post('http://localhost:7013/api/core/policies/uploadInsured', data ).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  /* -----------------------------------------------------------------------------------------------------
   -------------------------------------- SINIESTORS AUTOS-------------------------------
   ----------------------------------------------------------------------------------------------------- */

  public getInfoCarByNoSerie(params: any) {
    return this.http.post(`${API_REST_ADMIN}/policies_car/get-info-car-incident`, params);
    // return this.http.post(`http://localhost:7013/api/core/policies_car/get-info-car-incident`, params);
  }

  public updateIncidentPhaseCar(data) {
      return this.http.put(API_REST_ADMIN + '/incidents/update-phase/car', data );
      // return this.http.put('http://localhost:7009/api/core/incidents/update-phase/car', data );
  }

  public getConfigurationIdByPhase(data) {
      return this.http.post(API_REST_ADMIN + '/incidents/get-configuration/by-phase', data );
      // return this.http.post('http://localhost:7009/api/core/incidents/get-configuration/by-phase', data );
  }

  public getIncidentByFolio(data) {
      return this.http.post(API_REST_ADMIN + '/incidents/find-incident/by-folio-insurance', data );
      // return this.http.post('http://localhost:7009/api/core/incidents/find-incident/by-folio-insurance', data );
  }
}
