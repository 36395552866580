import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ChartDataSets} from 'chart.js';
import {Color, Label} from 'ng2-charts';

import dataTmp from '../../dinamic-reports-new/csvjson.json';

@Component({
    selector: 'ver',
    templateUrl: 'ver.component.html'
})
export class VerReporteComponent implements OnInit {

    public onClose: Subject<boolean>;

    variablesDestination: any = [
        {
            id: '1',
            name: 'Documento',
            type: 'TEXT'
        },
        {
            id: '2',
            name: 'Desde',
            type: 'DATE'
        },
        {
            id: '3',
            name: 'Hasta',
            type: 'DATE'
        },
        {
            id: '11',
            name: 'Prima neta',
            type: 'NUMBER'
        },
        {
            id: '12',
            name: 'Prima total',
            type: 'NUMBER'
        },
    ];
    filtrosDestination: any = [
        {
            id: '1',
            name: 'PRUEBA',
            type: 'TEXT'
        },
        {
            id: '2',
            name: 'PRUEBA 2',
            type: 'OPTIONS'
        },
        {
            id: '3',
            name: 'PRUEBA 3',
            type: 'DATE'
        }
    ];

    data: any[];
    tipoGrafica: string;
    columnasValores: any = [
        {
            id: '11',
            name: 'Prima neta',
            type: 'NUMBER'
        },
        {
            id: '12',
            name: 'Prima total',
            type: 'NUMBER'
        },
    ];
    columnaAgrupacion: any =
        {
            id: '1',
            name: 'Documento',
            type: 'TEXT'
        };

    lineChartData: ChartDataSets[] = [];

    lineChartLabels: Label[] = [];

    lineChartOptions = {
        responsive: true,
    };

    lineChartColors: Color[] = [
        {
        borderColor: '#326173',
        backgroundColor: 'rgba(41, 63, 121, 0.05)',
        },
    ];

    lineChartLegend = true;
    lineChartPlugins = [];
    lineChartType = 'line';

    showGrafica: boolean = false;
    page = 1;
    pageSize = 10;

    constructor(
        public bsModalRef: BsModalRef,
    ) {
    }

    ngOnInit(): void {
        this.onClose = new Subject();

        this.ejecutarReporte();
    }

    ejecutarReporte() {
        this.data = [];
        dataTmp.forEach(dat => {
            const tmp = {};
            this.variablesDestination.forEach(column => {
                tmp[column.name] = dat[column.name];
            });
            this.data.push(tmp);
        });
        console.log(this.data);

        this.tipoGrafica = 'BAR';
        if (this.tipoGrafica) {
            const datas = [];
            const labels = [];
            this.columnasValores.forEach(valor => {
                const dat = [];
                this.data.forEach(dato => {
                    const val = dato[valor.name];
                    dat.push(val);
                });

                datas.push({
                    data: dat,
                    label: 'Prueba'
                });
            });

            this.data.forEach(dato => {
                const val = dato[this.columnaAgrupacion.name];
                labels.push(val);
            });

            this.lineChartData = datas;
            this.lineChartLabels = labels;
            this.lineChartType = this.tipoGrafica === 'BAR' ? 'line' : 'pie';
            this.showGrafica = true;
        }
    }

    close(): void {
        this.onClose.next();
        this.bsModalRef.hide();
    }

}
