import {number, string} from '@amcharts/amcharts4/core';

export class PlacementDetail {
  private _idTradeName: number;
  private _rfc: string;
  private _directionCompany: string;
  private _tradeName: string;
  private _companyId: number;

  constructor(idTradeName: number, rfc: string, directionCompany: string, tradeName: string, companyId: number) {
    this._idTradeName = idTradeName;
    this._rfc = rfc;
    this._directionCompany = directionCompany;
    this._tradeName = tradeName;
    this._companyId = companyId;
  }

  get rfc(): string {
    return this._rfc;
  }

  set rfc(value: string) {
    this._rfc = value;
  }

  get directionCompany(): string {
    return this._directionCompany;
  }

  set directionCompany(value: string) {
    this._directionCompany = value;
  }

  get tradeName(): string {
    return this._tradeName;
  }

  set tradeName(value: string) {
    this._tradeName = value;
  }

  get companyId(): number {
    return this._companyId;
  }

  set companyId(value: number) {
    this._companyId = value;
  }

  get idTradeName(): number {
    return this._idTradeName;
  }

  set idTradeName(value: number) {
    this._idTradeName = value;
  }

  toPlainObject(): any {
    return {
      idTradeName: this._idTradeName,
      rfc: this._rfc,
      directionCompany: this._directionCompany,
      tradeName: this._tradeName,
      companyId: this._companyId
    };
  }
}
