import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupReceiptBySubgroupIdPipe'
})
export class GroupReceiptBySubgroupIdPipe  implements PipeTransform {

  transform(categoryList: any[]): { [subGroupId: string]: any[] } {
    const groupedReceipt: { [subGroupId: string]: any[] } = {};

    categoryList.forEach(category => {
      const subGroupId = category.get('clientId').value;
      if (!groupedReceipt[subGroupId]) {
        groupedReceipt[subGroupId] = [];
      }

      const existingReceipts = groupedReceipt[subGroupId];
      const newReceipts = category;

      // Verificar si ya existe un elemento con el mismo clientId
      const existingClientIndex = existingReceipts.findIndex(categoryExist => {
        return categoryExist.get('clientId').value === subGroupId;
      });

      // Si no existe, se agrega al grupo
      if (existingClientIndex === -1) {
        groupedReceipt[subGroupId].push(newReceipts);
      }
    });

    return groupedReceipt;
  }



}

