import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appInputAlphaNumeric]'
})
export class InputAlphaNumericDirective {

  private regex: RegExp = new RegExp(/[a-zA-Z0-9\_\-\/]$/);
  private specialKeys: Array<string> = ['Backspace', 'Space', 'Tab', 'End', 'Home'];


  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.code) !== -1) {
      return;
   }
   let current: string = this.el.nativeElement.value;
   let next: string = current.concat(event.key);
   if (next && !String(next).match(this.regex)) {
      event.preventDefault();
   }
  }

}
