import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-insurance-company-medical-care',
  templateUrl: './insurance-company-medical-care.component.html',
  styleUrls: ['./insurance-company-medical-care.component.css']
})
export class InsuranceCompanyMedicalCareComponent implements OnInit {
  subBranchTypeSelected: any = {};
  subBranchList: any = [];
  dataFilterPayment: any = {};
  // tslint:disable-next-line:variable-name
  _insuranceId: number;
  paymentInsuranceList: any = [];
  // -Varibales para paginado ->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  modalRef: BsModalRef;
  configurationId: number;
  myFormConfiguration: FormGroup;
  dataConfiguration: any = {};
  applyPaymentList: any = [];
  typePaymentList: any = [];
  medicalCareList: any = [];
  filters: any = [];
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService) {
    this.myFormConfiguration = this.fb.group({
      subBranchId: [''],
      medicalCare: [''],
      amount: ['']
    });
  }

  ngOnInit() {
    this.invokeFunctionInitFilter();
    this.getSubBranchesByInsurance();
    this.getByPagination(1, 10);
  }
  get insuranceId(): number {
    return this._insuranceId;
  }
  @Input()
  set insuranceId(value: number) {
    this._insuranceId = value;
  }
  changeSubBranchToConsult(event: any) { }
  clearSubBranch() {
    this.dataFilterPayment.subBranchId = 0;
  }
  getSubBranchesByInsurance() {
    this.spinner.show('sp');
    this.subBranchList = [];
    this.api.getSubBranchesByInsurance(this._insuranceId).then((data: any) => {
      if (data != null ) {
        this.subBranchList = data;
        this.subBranchTypeSelected = data[0].subBranchList;
      } else {
        this.toastr.warning('DEBE ASIGNAR LOS SUBRAMOS DE ESTA ASEGURADO ANTES DE AGREGAR LOS ANEXOS', 'NOTIFICACIÓN');
      }
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  clearFiltersTable() {
    this.invokeFunctionInitFilter();
    this.getByPagination(1, 10);
  }
  loadFiltersTable() {
    this.getByPagination(1, 10);
  }
  openModalDeleteRegister(template: TemplateRef<any>, id) {
    this.configurationId = id;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
  }
  confirmDelete() {
    this.spinner.show('sp');
    this.api.deleteConfigurationMedicalCareById(this.configurationId).subscribe( response => {
      this.modalRef.hide();
      this.toastr.success('OPERACIÓN REALIZADA CON ÉXITO', 'NOTIFICACIÓN');
      this.getByPagination(1, 10);
      this.spinner.hide('sp');
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
      this.spinner.hide('sp');
    });
  }
  pageChanged(event: PageChangedEvent): void {
    // // // console.loglog(event);
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.api.getConfigurationMedicalCareByInsurance(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.medicalCareList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.total ;
          this.medicalCareList = this.paginationResponse.list ;
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.medicalCareList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }
  invokeFunctionOpenConfiguration(template: TemplateRef<any>, id) {
    this.configurationId = id;
    this.invokeServiceGetTypePayment();
    this.invokeServiceGetApplyPayment();
    this.getSubBranchesByInsurance();
    if (id > 0) {
      this.api.getConfigurationMedicalCareById(id).then((data: any) => {
        this.dataConfiguration = data;
      }, error => {
      });
    } else {
      this.invokeFunctionInitConfigurationMedial();
    }
    setTimeout(() => {
      this.modalRef = this.modalService.show(template, {class: 'modal-md', keyboard: false});
    }, 1000);
  }

  invokeServiceGetTypePayment() {
    this.typePaymentList = [
      { typePaymentId: 1,
        name: 'PRIMER RECIBO'
      },
      { typePaymentId: 2,
        name: 'ENTRE TODOS LOS RECIBOS'
      }
    ];
  }
  invokeServiceGetApplyPayment() {
    this.applyPaymentList = [
      {
        applyPaymentId: 1,
        name: 'POR TITULAR'
      },
      {
        applyPaymentId: 2,
        name: 'POR PERSONA'
      }
    ];
  }
  invokeFunctionInitFilter() {
    this.filters = {
      subBranchId: 0,
      insuranceId: this._insuranceId,
      page: 1,
      size: 10
    };
  }
  invokeFunctionInitConfigurationMedial() {
    this.dataConfiguration = {
      id: 0,
      medicalCare: '',
      amount: undefined,
      subBranchId: 0,
      subBranch: '',
      insuranceId: this._insuranceId
    };
  }
  invokeServiceSaveMedicalCare() {
    this.spinner.show('sp');
    this.api.saveConfigurationMedicalCareByInsurance(this.dataConfiguration)
      .then(
        (response: any) => {
          if (response.rightId === 0) {
            this.toastr.info('YA SE HA GUARADO UNA CONFIGURACIÓN PARA EL SUB RAMO ESPECIFICADO, POR FAVOR SELECCIONE OTRO', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
            return;
          }
          this.toastr.success('SU CONFIGURACIÓN SE GUARDO CORRECTAMENTE.', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.clearFiltersTable();
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  invokeServiceUpdateMedicalCare() {
    this.spinner.show('sp');
    this.api.updateConfigurationMedicalCareByInsurance(this.dataConfiguration)
      .then(
        (response: any) => {
          this.toastr.success('SU CONFIGURACIÓN SE GUARDO CORRECTAMENTE.', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.getByPagination(1, 10);
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
}
