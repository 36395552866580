import { Component, OnInit ,Input} from '@angular/core';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {
  lclRequestId: number;
  lclRequestDetail: any = {};
  // modal
   modalRef: BsModalRef;
  constructor(private api: ApiService,
              public shared: SharedService,
               private modalService: BsModalService,) {
    this.shared.gRequestIdObservable().subscribe(
      gRequestId => {
        console.info(gRequestId);
        this.lclRequestId = gRequestId;
        this.invokeServiceForGetRequestDetail(this.lclRequestId);
      }
    );
  }

  ngOnInit() {
  }

  invokeServiceForGetRequestDetail(requestId) {
  /*
    this.api.getRequestById(requestId).subscribe(res => {
      this.lclRequestDetail = res;
    }, (error) => {
      console.error(error);
    });*/
  }




}
