import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';


@Component({
  selector: 'app-insurance-company-payment',
  templateUrl: './insurance-company-payment.component.html',
  styleUrls: ['./insurance-company-payment.component.css']
})
export class InsuranceCompanyPaymentComponent implements OnInit {
  subBranchTypeSelected: any = {};
  subBranchList: any = [];
  dataFilterPayment: any = {};
  // tslint:disable-next-line:variable-name
  _insuranceId: number;
  paymentInsuranceList: any = [];
  // -Varibales para paginado ->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  modalRef: BsModalRef;
  configurationId: number;
  myFormConfiguration: FormGroup;
  dataConfiguration: any = {};
  applyPaymentList: any = [];
  typePaymentList: any = [];
  filters: any = {};
  applyTypePayment: boolean;
  applyAmount: boolean;
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService) {
    this.myFormConfiguration = this.fb.group({
      subBranchId: ['', [Validators.required]],
      typePayment: ['', [Validators.required]],
      applyPayment: ['', [Validators.required]],
      amountPayment: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.invokeFunctionInitFilter();
    this.getSubBranchesByInsurance();
    this.getByPagination(1, 10);
    this.applyTypePayment = true;
    this.applyAmount = true;
  }
  get insuranceId(): number {
    return this._insuranceId;
  }
  @Input()
  set insuranceId(value: number) {
    this._insuranceId = value;
  }
  changeSubBranchToConsult(event: any) { }
  clearSubBranch() {
    this.dataFilterPayment.subBranchId = 0;
  }
  getSubBranchesByInsurance() {
    this.spinner.show('sp');
    this.api.getSubBranchesByInsurance(this._insuranceId).then((data: any) => {
      if (data != null) {
        this.subBranchList = data;
        this.subBranchTypeSelected = data[0].subBranchList;
        this.invokeFunctionServiceGetBranchBySubBranches();
      } else {
        this.toastr.warning('DEBE ASIGNAR LOS SUBRAMOS DE ESTA ASEGURADO ANTES DE AGREGAR LOS ANEXOS', 'NOTIFICACIÓN');
      }
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  invokeFunctionServiceGetBranchBySubBranches() {
    // console.log('branch');
    const subBranches = [];
    this.subBranchList.forEach(sub => {
      subBranches.push(sub.subBranchId);
    });
    setTimeout(() => {
      this.api.getBranchBySubBranches(subBranches).then((data: any) => {
           // console.log(data);
           // console.log('peticional adicional');
           this.subBranchList.forEach(sub => {
             data.forEach(sub1 => {
               if (sub.subBranchId === sub1.subBranchId) {
                 sub.branchId = sub1.branchId;
               }
             });
           });
      }, error => {
      });
    }, 2000);
  }
  clearFiltersTable() {
    this.invokeFunctionInitFilter();
    this.getByPagination(1, 10);
  }
  loadFiltersTable() {
    this.getByPagination(1, 10);
  }
  openModalDeleteRegister(template: TemplateRef<any>, id) {
    this.configurationId = id;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
  }
  confirmDelete() {
    this.api.deleteConfigurationRightById(this.configurationId).subscribe( response => {
      this.modalRef.hide();
      this.toastr.success('OPERACIÓN REALIZADA CON ÉXITO', 'NOTIFICACIÓN');
      this.getByPagination(1, 10);
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.api.getConfigurationRightsByInsurance(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.paymentInsuranceList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.total ;
          this.paymentInsuranceList = this.paginationResponse.list;
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.paymentInsuranceList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }
  invokeFunctionOpenConfiguration(template: TemplateRef<any>, id) {
    this.configurationId = id;
    this.invokeServiceGetTypePayment();
    this.invokeServiceGetApplyPayment();
    if (this.subBranchList.length === 0) {
      this.getSubBranchesByInsurance();
    }
    if (id > 0) {
      this.api.getConfigurationRightsById(id).then((data: any) => {
        this.dataConfiguration = data;
      }, error => {
      });
    } else {
      this.invokeFunctionInitConfigurationRight();
    }
    setTimeout(() => {
      this.modalRef = this.modalService.show(template, {class: 'modal-md', keyboard: false});
    }, 1000);
  }

  invokeServiceGetTypePayment() {
    this.typePaymentList = [
      { typePaymentId: 1,
        name: 'PRIMER RECIBO'
      },
      { typePaymentId: 2,
        name: 'TODOS LOS RECIBOS'
      }
    ];
  }
  invokeServiceGetApplyPayment() {
    this.applyPaymentList = [
      {
        applyPaymentId: 1,
        name: 'POR TITULAR'
      },
      {
        applyPaymentId: 2,
        name: 'POR PERSONA'
      },
      {
        applyPaymentId: 3,
        name: 'NO APLICA'
      }
    ];
  }
  invokeFunctionInitFilter() {
    this.filters = {
      subBranchId: 0,
      insuranceId: this._insuranceId,
      page: 1,
      size: 10
    };
  }
  invokeFunctionInitConfigurationRight() {
    this.dataConfiguration = {
      rightId: 0,
      insuranceId: this._insuranceId,
      subBranchId: 0,
      applyRightId: 0,
      configurationRightId: 0,
      amount: undefined,
      subBranch: '',
      applyRight: '',
      configurationRight: ''
    };
  }

  invokeServiceSaveConfiguration() {
    this.spinner.show('sp');
    this.api.saveConfigurationRightByInsurance(this.dataConfiguration)
      .then(
        (response: any) => {
          if (response.rightId === 0) {
            this.toastr.info('YA SE HA GUARADO UNA CONFIGURACIÓN PARA EL SUB RAMO ESPECIFICADO, POR FAVOR SELECCIONE OTRO', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
            return;
          }
          this.toastr.success('SU CONFIGURACIÓN SE GUARDO CORRECTAMENTE.', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.clearFiltersTable();
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  invokeServiceUpdateConfiguration() {
    this.spinner.show('sp');
    this.api.updateConfigurationRightByInsurance(this.dataConfiguration)
      .then(
        (response: any) => {
          this.toastr.success('SU CONFIGURACIÓN SE GUARDO CORRECTAMENTE.', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.getByPagination(1, 10);
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  invokeFunctionValidateBranch() {
    // console.log(this.dataConfiguration.subBranchId);
    // console.log(this.subBranchList);
    this.subBranchList.forEach(item => {
      if (item.subBranchId === this.dataConfiguration.subBranchId) {
        if (item.branchId !== 1) {
          this.applyTypePayment = false;
          this.applyAmount = false;
          this.dataConfiguration.applyRightId = 3;
          this.dataConfiguration.amount = 0;
          if (item.branchId === 3) {
            this.dataConfiguration.amount = undefined;
            this.applyAmount = true;
          }
        }
      }
    });
  }
}
