import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Documents} from './documents';
import {FileUploadControl, FileUploadValidators} from '@iplab/ngx-file-upload';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';


@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css'],
})
export class UploadFileComponent implements OnInit {

  private _configurationId: number;
  private _uploadedFiles: Array<Documents> = [];
  private _DISABLE: boolean;
  @Output() filesUpoladedEvent = new EventEmitter<Documents[]>();
  typeDocumentList: any = [];
  configDocumentation: any = [];
  filesUploaded: [];
  fileUpload: File;
  form: FormGroup;
  order: number;
  base64File = ' ';
  alreadyUpload: boolean;
  orderSelected: number;
  public fileUploadControl = new FileUploadControl({listVisible: false, discardInvalid: true, accept: ['.pdf', '.jpeg', '.png', '.jpg']},
    [FileUploadValidators.accept(['.pdf', '.jpeg', '.png', '.jpg']), FileUploadValidators.fileSize(52428800)]);
  date: any;
  bsStartDate: Partial<BsDatepickerConfig>;
  isComprobanteDomicilio: boolean;
  comprobanteMaxDate = new Date();
  comprobanteMinDate = new Date();

  // Construimos la vista con los forms necesarios y la lista  de los documentos
  constructor(private api: ApiService,
              private toastr: ToastrService,
              private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      orders: new FormArray([]),
      files: new FormControl(),
      dateComprobanteDomicilio: new FormControl()
    });
  }

  get ordersFormArray() {
    return this.form.controls.orders as FormArray;
  }

  ngOnInit() {
    this.comprobanteMaxDate.setDate(this.comprobanteMaxDate.getDate());
    this.comprobanteMinDate.setDate(this.comprobanteMaxDate.getDate() - 365);
    // El compoente de arhivos iniciará disabled always
    this.fileUploadControl.disable(true);
    this.alreadyUpload = true;
    this.isComprobanteDomicilio = false;

    // inicializan valores para documentos
    this.configDocumentation = {
      configurationDocumentationId: this._configurationId,
      name: '',
      proccessId: 0,
      subproccessId: 0,
      process: '',
      subprocess: '',
      status: 0,
      createdAt: '',
      updated: '',
      number: 0,
      catTypeDocumentId: null,
      required: false,
      total: 0,
      statusName: ''
    };
    // Obtenemos los arhivos de la configuración
    this.invokeServiceGetDocuments(this.configDocumentation);

  }

  /*invocacion de servicios*/
  invokeServiceGetDocuments(configDocumentation: any) {
    const data = configDocumentation;
    this.api.getDocuments(data).then((data: any) => {
      if (data != null) {
        this.typeDocumentList = data;
        // Se construyen los checboxes
        this.addCheckboxes();
      }
    }, error => {
      if (error.status === 409) {
        this.toastr.error('Hubo un error al procesar la información de los documentos.', 'Notificación');
      } else if (error.status === 204) {
        this.toastr.warning('No se encontró información de los documentos.', 'Notificación');
      }
    });
  }

  private addCheckboxes() {

    this.typeDocumentList.forEach(typeDocument => {
      // Validamos si existe en el listado para ponerlas como marcada
      if ((this._uploadedFiles.findIndex(document => document.number === typeDocument.number)) >= 0) {
        this.ordersFormArray.push(new FormControl({value: true, disabled: this._DISABLE}));
      } else {
        this.ordersFormArray.push(new FormControl({value: false, disabled: this._DISABLE}));
      }
    });
  }

  // Convertimos la imágen o PDF a base64
  getBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  onLoadDocument() {
    if (this.fileUploadControl.invalid) {
      this.fileUploadControl.getError().map(ERROR => {
        if (ERROR.fileSize !== undefined) {
          this.toastr.warning('Archivo inválido. El tamaño del archivo debe ser menor a 50 MB', 'Notificación');
        } else if (ERROR.fileTypes !== undefined) {
          this.toastr.warning('Archivo inválido. Formato no válido, solo PDF, JPEG, JPG y PNG', 'Notificación');
        }
      });
    } else {
      this.deleteFileIfExist();

      const documents = new Documents();

      this.getBase64(this.fileUploadControl.value[0]).then(
        res => {
          documents.File = this.fileUploadControl.value[0];
          documents.number = this.order;
          documents.base64 = res.split(',')[1];
          documents.fechaComprobanteDomicilio = this.date;
          documents.name = this.fileUploadControl.value[0].name;
          documents.idDocument = null;
          this.base64File = documents.base64;

          this._uploadedFiles.push(documents);
          this.filesUpoladedEvent.emit(this._uploadedFiles);
        }
      );
      this.alreadyUpload = true;
    }
  }

  deleteFileIfExist() {
    const index = this._uploadedFiles.findIndex(x => Number(x.number) === Number(this.order));
    if (index >= 0) {
      this._uploadedFiles.splice(index, 1);
    }
  }

  activateFileUpload(event) {
    this.order = event.target.value;
    this.fileUploadControl.clear();
    this.date = null;
    this.fileUploadControl.disable(true);

    // tslint:disable-next-line:triple-equals
    const file = this.typeDocumentList.filter(typeFile => typeFile.number == this.order);

    if (event.target.checked) { // Verificar mismo checkbox, entonces, aún no han cargado file
      if (this.alreadyUpload || (this.order === this.orderSelected)) { // si ya existe un archivo
        if (file[0].nameFile.includes('COMPROBANTE DE DOMICILIO')) {// Si es de un comprobante de domicilio, activar el datapicke
          this.isComprobanteDomicilio = true;
        } else {
          this.isComprobanteDomicilio = false;
          this.fileUploadControl.disable(false);
        }
        this.alreadyUpload = false;
      } else { // Si no existe el archivo, no permitirle que seleccione otro
        this.toastr.warning('Necesitas cargar un archivo para activar la siguiente opción.', 'Notificación');
        const input = document.getElementById(event.target.id) as HTMLInputElement;
        input.checked = false;
      }
    } else {
      this.fileUploadControl.disable(true);
      this.isComprobanteDomicilio = false;
      this.alreadyUpload = true;
      this.deleteFileIfExist();
      this.filesUpoladedEvent.emit(this._uploadedFiles);
    }

    this.base64File = ' ';
    this.orderSelected = this.order;
  }

  seeFile(numberFile: number) {
    this.base64File = ' ';
    const findDocument = this._uploadedFiles.find(document => document.number == numberFile);
    if (findDocument !== undefined) {
      this.base64File = findDocument.base64;
    }
  }

  onChangeDataPicker(event) {
    if (event != null) {
      this.fileUploadControl.disable(false);
    } else {
      this.fileUploadControl.disable(true);
    }
  }

  getName(name: any): string {
    let res = name.nameFile;
    if (name.required) {
      res += '*';
    }

    return res;
  }

  get configurationId(): number {
    return this._configurationId;
  }
  @Input()
  set configurationId(value: number) {
    this._configurationId = value;
  }

  get uploadedFiles(): Array<Documents> {
    return this._uploadedFiles;
  }

  @Input()
  set uploadedFiles(value: Array<Documents>) {

    if (value.length === 0 && this.ordersFormArray.controls.length !== 0) {
      this.ordersFormArray.controls.forEach((_ELEMENT, i) => {
        _ELEMENT.patchValue(false);
        this.base64File = ' ';
        // this.form.controls.files.setValue(null);
        this.fileUploadControl.disable(true);
        this.fileUploadControl.clear();
        this.form.controls.dateComprobanteDomicilio.setValue(null);
        this.isComprobanteDomicilio = false;

      });
    } else if ( value.length !== 0 && this.ordersFormArray.controls.length !== 0) {
        value.map(_DOCUMENT => {
          const input = document.getElementById(((_DOCUMENT.number).toString())) as HTMLInputElement;
          if ( input !== null && input !== undefined) {
            input.checked = true;
          }
          });
    }
    this._uploadedFiles = value;
  }

  @Input()
  set DISABLE(value: boolean) {
    this._DISABLE = value;
    this.ordersFormArray.controls.forEach((_ELEMENT, i) => {
      if (value) {
        _ELEMENT.disable();
      } else {
        _ELEMENT.enable();
      }
    });
  }
}
