import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-admin-report-core',
  templateUrl: './admin-report-core.component.html',
  styleUrls: ['./admin-report-core.component.css']
})
export class AdminReportCoreComponent implements OnInit {

  reportList: any = [];
  // -Varibles para paginado de usuarios->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filters: any = {};
  filtersDownload: any = {};

  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) { }

  ngOnInit() {
    this.clearFiltersTable();
  }
  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    this.filters = {
      nameReport: '',
      page: 1,
      size: 10
    };
    this.getByPagination(1, 10);
  }

  /**
   * Carga los datos según la página seleccionada
   */
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }
  /**
   * Función para obtener los usuarios del sistema
   */
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.api.getReportsCore(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.reportList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.reportList = this.paginationResponse.tList;
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.reportList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }
  /**
   * Función para obtener los usuarios del sistema
   */
  loadFiltersTable() {
    this.getByPagination(1, 10);
  }
  /**
   * Función para ir al detalle de un usuario
   */
  openReport(reportId) {

    switch (reportId) {
      case 2: // Polizas
        this.validateAccess.validateAccessCore(7).then((response: any) => {
          console.log('valor que regresa', response.accessCore);
          if (response.accessCore === 0) {
            this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
          } else {
            this.router.navigate(['/admin/report_core_provision']);
          }
        });
        break;
      case 1: // Datos Generales del Subgrupo

        this.validateAccess.validateAccessCore(5).then((response: any) => {
          console.log('valor que regresa', response.accessCore);
          if (response.accessCore === 0) {
            this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
          } else {
            this.router.navigate(['/admin/report_core_produccion']);
          }
        });
        break;
      case 3: // Datos Direcciones Subgrupo
        this.validateAccess.validateAccessCore(9).then((response: any) => {
          console.log('valor que regresa', response.accessCore);
          if (response.accessCore === 0) {
            this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
          } else {
            this.router.navigate(['/admin/report_core_incident']);
          }
        });
        break;
      default:
        break;
    }
  }

  /**
   * función para regresar a dashboard principal
   */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }
}
