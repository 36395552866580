import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-attachmet-damages-mdl',
  templateUrl: './attachmet-damages-mdl.component.html',
  styleUrls: ['./attachmet-damages-mdl.component.css']
})
export class AttachmetDamagesMdlComponent implements OnInit {
 modalRef: BsModalRef;
  dataToSendIssuePolicy: any = {};
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  public fileUploadZip: File;
  errorZip = {active: false, msg: ''};
  fileTypesZip: string[] = ['application/zip'];
  responseImportInsured: any = [];
  insuredProcess: number;
  insuredUnprocessed: number;
  importMassive: boolean;
  constructor(private modalService: BsModalService,
                            private toastr: ToastrService,
                            private shared: SharedService,
                            private api: ApiService,
                            private spinner: NgxSpinnerService) { }

   ngOnInit() {
    }
    openModal(template: TemplateRef<any>) {
      this.loadInitialModal();
      this.modalRef = this.modalService.show(template);
    }

    SendPolicy() {
        this.spinner.show('sp');
        console.log(this.dataToSendIssuePolicy);
        const formData = new FormData();
        formData.append('file', this.fileUpload);
        const formDataXlsx = new FormData();
        formDataXlsx.append('fileXlsx', this.fileUploadXlsx);
        // const formDataZip = new FormData();
        formDataXlsx.append('fileZip', this.fileUploadZip);

        this.api.postImportPolicyDamages(formDataXlsx)
          .subscribe(
            responseInsured => {

              this.responseImportInsured = responseInsured;
                       console.log(this.responseImportInsured);
                       this.toastr.success('El proceso de importación ha iniciado en cuanto termine se le notificara.', 'Notificación');
                       this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
                       this.insuredUnprocessed = 0;
                       this.insuredProcess = 0;
              /*this.responseImportInsured = responseInsured;
              console.log(this.responseImportInsured);
              this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
              this.insuredUnprocessed = 0;
              this.insuredProcess = 0;
              console.log('responseInsured');
              console.log(responseInsured);
              if(responseInsured===true){
               this.toastr.success('Importación de polizas realizada correctamente.', 'Notificación');

              }*/
              /*this.responseImportInsured.forEach(item => {
                  if (item.insuredId > 0) {
                    this.insuredProcess = this.insuredProcess + 1;
                  } else {
                    this.insuredUnprocessed = this.insuredUnprocessed + 1;
                  }
              });*/
              this.importMassive = true;
              this.spinner.hide('sp');
              this.modalRef.hide();
            }, error => {
              console.log(error);
              this.toastr.warning(error.error);
              console.error();
              this.spinner.hide('sp');
            }
          );
        /*
        this.api.postFilePolicy(formData, this.dataToSendIssuePolicy.policyId)
          .subscribe(
            response => {

            }, error => {
              this.toastr.warning(error.error);
              console.error();
              this.spinner.hide('sp');
            }
          );*/
        /*this.api.putChangeStatusPolicy(this.dataToSendIssuePolicy)
          .then(
            (data) => {

            }, error => {
              this.spinner.hide('sp');
              this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
            }
          );*/
      }

      onChangeInputFile(e) {
        this.error = {active: false, msg: ''};
        this.fileUpload = e.target.files[0];
        if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
          this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
        }
      }
      onChangeInputFileXlsx(e) {
        this.errorXlsx = {active: false, msg: ''};
        this.fileUploadXlsx = e.target.files[0];
        console.log(this.fileUploadXlsx);
        if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
          this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
        }
      }
      onChangeInputFileZip(e) {
        this.errorZip = {active: false, msg: ''};
        this.fileUploadZip = e.target.files[0];
        if (0 > this.fileTypesZip.indexOf(this.fileUploadZip.type)) {
          this.errorZip = {active: true, msg: 'Debe seleccionar un archivo válido'};
        }
      }
      loadInitialModal() {
        this.importMassive = false;
        this.dataToSendIssuePolicy = {
          policyId: localStorage.getItem('policy-id'),
          statusId: 1,
          number: ''
        };
        this.fileUpload = undefined;
      }

}
