import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';


@Component({
  selector: 'app-attachmet-sinister-mdl',
  templateUrl: './attachmet-sinister-mdl.component.html',
  styleUrls: ['./attachmet-sinister-mdl.component.css']
})
export class AttachmetSinisterMdlComponent implements OnInit {
  modalRef: BsModalRef;
    dataToSendIssuePolicy: any = {};
    public fileUpload: File;
    error = {active: false, msg: ''};
    fileTypes: string[] = ['application/pdf'];
    public fileUploadXlsx: File;
    errorXlsx = {active: false, msg: ''};
    fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    public fileUploadZip: File;
    errorZip = {active: false, msg: ''};
    fileTypesZip: string[] = ['application/zip'];
    responseImportInsured: any = [];
    insuredProcess: number;
    insuredUnprocessed: number;
    importMassive: boolean;
   constructor(private modalService: BsModalService,
                              private toastr: ToastrService,
                              private shared: SharedService,
                              private api: ApiService,
                              private spinner: NgxSpinnerService) { }

     ngOnInit() {
      }
      openModal(template: TemplateRef<any>) {
        this.loadInitialModal();
        this.modalRef = this.modalService.show(template);
      }

      saveSiniesters() {
         if(this.fileUploadXlsx===null || this.fileUploadXlsx===undefined){
           this.toastr.warning("Seleccione un archivo para poder continuar","Notificación");
         }else{
           this.spinner.show('sp');
           // console.log(this.dataToSendIssuePolicy);
           const formData = new FormData();
           formData.append('file', this.fileUpload);
           const formDataXlsx = new FormData();
           formDataXlsx.append('fileXlsx', this.fileUploadXlsx);
           // const formDataZip = new FormData();
           formDataXlsx.append('fileZip', this.fileUploadZip);
           //this.api.postImportPolicyDamages(formDataXlsx)
           this.api.postSaveSiniesters(formDataXlsx)
             .subscribe(
               responseInsured => {

                 if(responseInsured!==null && responseInsured!==undefined){
                   if(responseInsured["success"]===true){
                     this.toastr.success('Se han cargado los siniestros correctamente.', 'Notificación');

                   }
                 }
                 else{
                   this.toastr.warning('No se lograron procesaron los siniestros, verifique que el layout sea el correcto', 'Notificación');
                 }

                 this.spinner.hide('sp');
                 this.importMassive = true;
                 this.modalRef.hide();
               }, error => {
                 // console.log(error);
                 this.toastr.warning(error.error);
                 console.error();
                 this.spinner.hide('sp');
               }
             );
         }
        }

        onChangeInputFile(e) {
          this.error = {active: false, msg: ''};
          this.fileUpload = e.target.files[0];
          if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
            this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};

          }
        }
        onChangeInputFileXlsx(e) {
          this.errorXlsx = {active: false, msg: ''};
          this.fileUploadXlsx = e.target.files[0];
          console.log(this.fileUploadXlsx);
          if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
            this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
            this.fileUploadXlsx=null;
          }
        }
        onChangeInputFileZip(e) {
          this.errorZip = {active: false, msg: ''};
          this.fileUploadZip = e.target.files[0];
          if (0 > this.fileTypesZip.indexOf(this.fileUploadZip.type)) {
            this.errorZip = {active: true, msg: 'Debe seleccionar un archivo válido'};
            this.fileUploadZip=null;
          }
        }
        loadInitialModal() {
          this.importMassive = false;
          this.dataToSendIssuePolicy = {
            policyId: localStorage.getItem('policy-id'),
            statusId: 1,
            number: ''
          };
          this.fileUpload = undefined;
        }
}
