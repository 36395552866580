import {Directive, Input} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '([formControlName], [formControl]) [disableControl]'
})
export class DisableControlDirective {
  @Input() set disableControl(state: boolean) {
    const action = state ? 'disable' : 'enable';
    this.ngControl.control[action]();
  }

  constructor(private readonly ngControl: NgControl) {}

}
