import {CoveragePlacement} from './coveragePlacement';
import {number, string} from '@amcharts/amcharts4/core';

export class CoverageOptionPlacement {
  private _idCoveragesOptionPlacement: number;
  private _value: string;
  private _description: string;
  private _group: string;
  private _meditionType: string;
  private _orderLevel: string;
  private _isOpen: boolean;
  private _coveragesPlacementId: number;
  private _subCoveragesPlacementId: number;
  private _coveragesPlacementList: CoveragePlacement[];

  constructor(
      idCoveragesOptionPlacement: number,
      value: string,
      description: string,
      group: string,
      meditionType: string,
      orderLevel: string,
      isOpen: boolean,
      coveragesPlacementId: number,
      subCoveragesPlacementId: number,
      coveragesPlacementList = []
  ) {
    this._idCoveragesOptionPlacement = idCoveragesOptionPlacement;
    this._value = value;
    this._description = description;
    this._group = group;
    this._meditionType = meditionType;
    this._orderLevel = orderLevel;
    this._isOpen = isOpen;
    this._coveragesPlacementId = coveragesPlacementId;
    this._subCoveragesPlacementId = subCoveragesPlacementId;

    // Mapea placementDetails usando el constructor de PlacementDetail
    this._coveragesPlacementList = coveragesPlacementList ? coveragesPlacementList.map(detail => new CoveragePlacement(
      detail.idCoverage,
      detail.nameCoverage,
      detail.description,
      detail.isCoverageSpecial,
      detail.cuantitative,
      detail.optional,
      detail.open,
      detail.binary,
      detail.coverageOptionPlacements
    )) : [];
  }

  // Getter y Setter para _idCoveragesOptionPlacement
  get idCoveragesOptionPlacement(): number {
    return this._idCoveragesOptionPlacement;
  }

  set idCoveragesOptionPlacement(value: number) {
    this._idCoveragesOptionPlacement = value;
  }

  // Getter y Setter para _value
  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
  }

  // Getter y Setter para _description
  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  // Getter y Setter para _meditionType
  get meditionType(): string {
    return this._meditionType;
  }

  set meditionType(value: string) {
    this._meditionType = value;
  }

  // Getter y Setter para _orderLevel
  get orderLevel(): string {
    return this._orderLevel;
  }

  set orderLevel(value: string) {
    this._orderLevel = value;
  }

  // Getter y Setter para _isOpen
  get isOpen(): boolean {
    return this._isOpen;
  }

  set isOpen(value: boolean) {
    this._isOpen = value;
  }

  // Getter y Setter para _coveragesPlacementId
  get coveragesPlacementId(): number {
    return this._coveragesPlacementId;
  }

  set coveragesPlacementId(value: number) {
    this._coveragesPlacementId = value;
  }

  // Getter y Setter para _subCoveragesPlacementId
  get subCoveragesPlacementId(): number {
    return this._subCoveragesPlacementId;
  }

  set subCoveragesPlacementId(value: number) {
    this._subCoveragesPlacementId = value;
  }


  get coveragesPlacementList(): CoveragePlacement[] {
    return this._coveragesPlacementList;
  }

  set coveragesPlacementList(value: CoveragePlacement[]) {
    this._coveragesPlacementList = value;
  }

  get group(): string {
    return this._group;
  }

  set group(value: string) {
    this._group = value;
  }

  toPlainObject(): any {
    return {
      idCoveragesOptionPlacement: this._idCoveragesOptionPlacement,
      value: this._value,
      description: this._description,
      group: this._group,
      meditionType: this._meditionType,
      orderLevel: this._orderLevel,
      isOpen: this._isOpen,
      coveragesPlacementId: this._coveragesPlacementId,
      subCoveragesPlacementId: this._subCoveragesPlacementId,

      coveragesPlacementList: this._coveragesPlacementList.map(coveragesPlacement => coveragesPlacement.toPlainObject())
    };
  }
}
