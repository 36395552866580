import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';
import {QuoteService} from '../quote.service';

@Component({
  selector: 'app-emission-detail',
  templateUrl: './emission-detail.component.html',
  styleUrls: ['./emission-detail.component.css']
})
export class EmissionDetailComponent implements OnInit {

  emissionData: any = {};
  coveragesList: any = [];
  receiptData: any = {};
  constructor(public shared: SharedService,
              private quoteApi: QuoteService) { }

  ngOnInit() {
    this.invokeServiceForGetEmissionDetail();
  }

  private invokeServiceForGetEmissionDetail() {
    this.quoteApi.getEmissionById(this.shared.gQuote.movementId, this.shared.gQuote.insurerId)
      .subscribe(
        (response: any = {}) => {
          this.emissionData = response;
          this.invokeServiceForGetCoverages();
          this.invokeServiceForGetReceipt();
        }, error => {
          console.info(error);
        }
      );
  }

  private invokeServiceForGetCoverages() {
    this.quoteApi.getCoveragesByQuoteId(this.shared.gQuote.movementId, this.shared.gQuote.insurerId)
      .subscribe(
        (response: any = {}) => {
          this.coveragesList = response;
        }, error => {
          console.error(error);
        }
      );
  }

  private invokeServiceForGetReceipt() {
    this.quoteApi.getReceiptByEmissionId(this.shared.gQuote.movementId, this.shared.gQuote.insurerId)
      .subscribe(
        (response: any = {}) => {
          this.receiptData = response;
        }, error => {
          console.error(error);
        }
      );
  }

  public downloadPolicyPdf() {
    this.quoteApi.downloadPolicyPdf(this.shared.gQuote.movementId, this.shared.gQuote.insurerId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          if (this.emissionData.policyNumber) {
            downloadLink.setAttribute('download', this.emissionData.policyNumber + '.pdf');
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        });

  }

  public downloadReceiptPdf() {
    this.quoteApi.downloadReceiptPdf(this.shared.gQuote.movementId, this.shared.gQuote.insurerId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          if (this.emissionData.policyNumber) {
            downloadLink.setAttribute('download', this.emissionData.policyNumber + '_recibo.pdf');
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        });

  }


}
