import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';
import {QuoteService} from '../quote.service';
import {ToastrService} from 'ngx-toastr';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {DatePipe} from '@angular/common';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import * as uuid from 'uuid';
import Swal from 'sweetalert2/src/sweetalert2.js';
import {InvoiceValidatorComponent} from '../invoice-validator/invoice-validator.component';

@Component({
  selector: 'app-quote-form',
  templateUrl: './quote-form.component.html',
  styleUrls: ['./quote-form.component.css']
})
export class QuoteFormComponent implements OnInit {

  catStatesList: any = [];
  catGendersList: any = [];
  catPackagesList: any = [];
  // Objetos para los DatePickers ->
  bsBirthDate: Partial<BsDatepickerConfig>;

  dataToSend: any = {};
  markList: any = [];
  modelList: any = [];
  vehicleTypes: any = [];
  vehicleVersions: any = [];
  vehicleSelected: any = {};

  qualitasPackageToQuote: any = [];
  gnpPackageToQuote: any = [];
  quoteIterator = 0;

  enableInvoiceValue: boolean;

  txtPostcodeChanged: Subject<string> = new Subject<string>();

  bsModalRef: BsModalRef;

  constructor(public shared: SharedService,
              private quoteApi: QuoteService,
              private toastr: ToastrService,
              private datePipe: DatePipe,
              private modalService: BsModalService) {

    this.txtPostcodeChanged.pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(cp => {
        console.info(cp);
        if (cp !== undefined && cp.length === 5) {
          this.invokeServiceForCheckPostcode(cp);
        }
      });
  }

  ngOnInit() {
    this.dataToSend.mark = '';
    this.dataToSend.type = '';
    this.dataToSend.model = '';
    this.dataToSend.version = '';
    this.dataToSend.invoiceValueFlag = false;
    // Iniciamos configuración para DatePicker de Fecha de Nacimiento ->
    this.bsBirthDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD/MM/YYYY',
      maxDate: new Date()
    });
    this.invokeServiceForGetCatGenders();
    this.invokeServiceForGetCatPackages();
    this.dataToSend.gender = 1;
    this.dataToSend.insurer = 1;
    this.dataToSend.nationality = 1;
    this.invokeServiceGnpForGetMarks();
  }

  sendRequestData() {
    console.info(this.dataToSend);
    this.qualitasPackageToQuote = [];
    this.gnpPackageToQuote = [];
    this.quoteIterator = 0;

    const insured = {
      personType: 1,
      idCatGender: this.dataToSend.gender,
      birthDate: this.datePipe.transform(this.dataToSend.birthDate, 'dd/MM/yyyy'),
      state: this.dataToSend.state.state,
      stateCode: this.dataToSend.state.code,
      postcode: this.dataToSend.postcode,
      invoiceValueFlag: this.dataToSend.invoiceValueFlag,
      invoiceValue: this.dataToSend.invoiceValue
    };

    const finalRequestData: any = {};
    finalRequestData.uuid = uuid.v4();
    finalRequestData.insured = insured;

    // -Bucle para realizar las cotizaciones con todos los paquetes
    for (const itemPackage of this.catPackagesList) {
      if (itemPackage.qualitas) {
        this.qualitasPackageToQuote.push(itemPackage);
      }
      if (itemPackage.gnp) {
        this.gnpPackageToQuote.push(itemPackage);
      }
    }

    if (this.dataToSend.invoiceValueFlag) {
      Swal.fire({
        title: 'Cero kilometraje!',
        text: 'Usted esta cotizando con valor factura, ¿Confirma que el vehículo tiene cero kilometraje?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí, confirmo!',
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          this.doQuote(finalRequestData);
          this.launchProgressAlert(this.dataToSend.insurer);
        }
      });
    } else {
      this.doQuote(finalRequestData);
      this.launchProgressAlert(this.dataToSend.insurer);
    }
  }

  private launchProgressAlert(insurer) {
    const insurerName = insurer === 1 ? 'Quálitas' : insurer === 2 ? 'GNP' : 'Desconocido';
    Swal.fire({
      title: `Procesando cotizaciones con ${insurerName}!`,
      html: `Cotizando ${this.quoteIterator + 1} de ${this.gnpPackageToQuote.length} con plan de coberturas ${this.gnpPackageToQuote[this.quoteIterator].name}`,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
      onClose: () => {
      }
    });
  }


  doQuote(finalRequestData) {
    finalRequestData.vehicleCat = undefined;
    finalRequestData.rate = undefined;
    finalRequestData.insured.idQuotePackage = undefined;
    finalRequestData.insured.packageKey = undefined;
    finalRequestData.insured.packageName = undefined;

    if (this.dataToSend.insurer === 2 && this.gnpPackageToQuote.length > 0) {
      // console.info(`Cotizando GNP ${this.quoteIterator} de ${this.gnpPackageToQuote.length} con plan de coberturas
      // ${this.gnpPackageToQuote[this.quoteIterator].name}`);
      finalRequestData.vehicleCat = this.vehicleSelected;
      finalRequestData.insured.idQuotePackage = this.gnpPackageToQuote[this.quoteIterator].idQuotePackage;
      finalRequestData.insured.packageKey = this.gnpPackageToQuote[this.quoteIterator].gnpKey;
      finalRequestData.insured.packageName = this.gnpPackageToQuote[this.quoteIterator].name;

      this.quoteApi.doGnpQuote(finalRequestData)
        .subscribe(
          (response: any) => {
            console.info(response);
            this.quoteIterator++;
            if (this.quoteIterator < this.gnpPackageToQuote.length) {
              this.doQuote(finalRequestData);
              Swal.getHtmlContainer().textContent = `Cotizando ${this.quoteIterator + 1} de ${this.gnpPackageToQuote.length} con plan de coberturas ${this.gnpPackageToQuote[this.quoteIterator].name}`;
            } else {
              this.shared.gQuote = {uuid: response.uuid, insurerId: 2};
              this.shared.fCloseSidebar();
              Swal.close();
              setTimeout( () => {
                this.shared.fOpenSidebar(9, 'md');
              }, 700);
            }
          }, (error) => {
            Swal.fire({
              icon: 'error',
              title: 'Ups...',
              text: `Ocurrió un problema al procesar la cotización (${error.status})`,
              allowOutsideClick: false
            });
          }
        );
    } else if (this.dataToSend.insurer === 1 && this.qualitasPackageToQuote.length > 0) {
      // console.info(`Cotizando Quálitas ${this.quoteIterator} de ${this.qualitasPackageToQuote.length} con plan de coberturas
      // ${this.qualitasPackageToQuote[this.quoteIterator].name}`);
      finalRequestData.rate = this.vehicleSelected;
      finalRequestData.insured.idQuotePackage = this.qualitasPackageToQuote[this.quoteIterator].idQuotePackage;
      finalRequestData.insured.packageKey = this.qualitasPackageToQuote[this.quoteIterator].qualitasKey;
      finalRequestData.insured.packageName = this.qualitasPackageToQuote[this.quoteIterator].name;

      this.quoteApi.doQltQuote(finalRequestData)
        .subscribe(
          (response: any) => {
            console.info(response);
            this.quoteIterator++;
            if (this.quoteIterator < this.qualitasPackageToQuote.length) {
              this.doQuote(finalRequestData);
              Swal.getHtmlContainer().textContent = `Cotizando ${this.quoteIterator + 1} de ${this.qualitasPackageToQuote.length} con plan de coberturas ${this.qualitasPackageToQuote[this.quoteIterator].name}`;
            } else {
              this.shared.gQuote = {uuid: response.uuid, insurerId: 1};
              this.shared.fCloseSidebar();
              Swal.close();
              setTimeout( () => {
                this.shared.fOpenSidebar(9, 'md');
              }, 700);
            }
          }, error => {
            console.error(error);
            Swal.fire({
              icon: 'error',
              title: 'Ups...',
              html: `Ocurrió un problema al procesar la cotización (${error.status}) <br> <i>${error.error.message}</i>`,
              allowOutsideClick: false
            });
          }
        );
    }
  }

  public insurerChanged() {
    this.invokeServiceGnpForGetMarks();
    this.dataToSend.mark = '';
    this.dataToSend.type = '';
    this.dataToSend.model = '';
    this.dataToSend.version = '';
  }

  public postcodeChanged(i) {
    this.txtPostcodeChanged.next(i);
  }
  /**
   * Invocamos el servicio para verificar que el CP exista
   */
  public invokeServiceForCheckPostcode(postcode) {
    this.quoteApi.getCheckPostcode(postcode)
      .subscribe(
        (response) => {
          this.dataToSend.postcode = postcode;
          this.invokeServiceForGetStateByPostCode(postcode);
        }, error => {
          console.error(error);
          this.dataToSend.postcode = undefined;
          this.toastr.warning(error.error.message, `El Código Postal ingresado no existe`);
          this.txtPostcodeChanged.next(undefined);
        }
      );
  }
  private invokeServiceForGetStateByPostCode(postcode) {
    this.quoteApi.getStateByPostcode(postcode)
      .subscribe(
        (response: any = {}) => {
          this.dataToSend.state = {
            state: response.cValue,
            code: response.cKey
          };
        }, error => {
          console.error(error);
          this.dataToSend.postcode = undefined;
          this.toastr.warning(error.error.message, `El Código Postal ingresado no existe`);
          this.txtPostcodeChanged.next(undefined);
        }
      );
  }

  /**
   * Invocamos el servicio para recuperar el catálogo de Generos
   */
  private invokeServiceForGetCatGenders() {
    this.quoteApi.getCatGenders()
      .subscribe(
        (response) => {
          this.catGendersList = response;
        }, error => {
          console.error(error);
        }
      );
  }

  /**
   * Invocamos el servicio para recuperar el catálogo de Paquetes de coberturas
   */
  private invokeServiceForGetCatPackages() {
    this.quoteApi.getCatPackages()
      .subscribe(
        (response) => {
          this.catPackagesList = response;
        }, error => {
          console.error(error);
        }
      );
  }

  private invokeServiceGnpForGetMarks() {
    this.markList = [];
    if (this.dataToSend.insurer === 2) {
      this.quoteApi.getGnpMarks()
        .subscribe(
          (response: any = {}) => {
            this.markList = response.catalog[0].element;
          }, error => {
            console.info(error);
          }
        );
    } else if (this.dataToSend.insurer === 1) {
      this.quoteApi.getQltMarks()
        .subscribe(
          (response: any = {}) => {
            for (const item of response.elements) {
              this.markList.push({key: item.cMarca, name: item.cMarcaLarga});
            }
          }, error => {
            console.error(error);
          }
        );
    }
  }

  public loadVehiclesModels() {
    this.vehicleTypes = [];
    this.dataToSend.model = '';
    this.dataToSend.type = '';
    this.dataToSend.version = '';
    if (this.dataToSend.insurer === 2) {
      this.quoteApi.getGnpVehicleModels(this.dataToSend.mark)
        .subscribe(
          (response: any = {}) => {
            this.modelList = response.catalog[0].element.map(item => item.key).sort().reverse();
            console.info(this.modelList);
          }, error => {
            console.error(error);
          }
        );
    } else if (this.dataToSend.insurer === 1) {
      this.quoteApi.getQltVehicleTypes(this.dataToSend.mark, '')
        .subscribe(
          (response: any = {}) => {
            this.modelList = response.elements.map(item => item.cModelo).filter((value, index, self) => self.indexOf(value) === index).sort().reverse();
            console.info(this.modelList);
          }, error => {
            console.error(error);
          }
        );
    }
  }

  public loadVehiclesTypes() {
    if (this.dataToSend.model >= (new Date()).getFullYear()) {
      this.enableInvoiceValue = true;
    } else {
      this.enableInvoiceValue = false;
      this.dataToSend.invoiceValueFlag = false;
    }
    this.vehicleTypes = [];
    this.dataToSend.type = '';
    this.dataToSend.version = '';
    if (this.dataToSend.insurer === 2) {
      this.quoteApi.getGnpVehicleTypes(this.dataToSend.mark)
        .subscribe(
          (response: any = {}) => {
            this.vehicleTypes = response.catalog[0].element;
          }, error => {
            console.error(error);
          }
        );
    } else if (this.dataToSend.insurer === 1) {
      this.quoteApi.getQltVehicleTypes(this.dataToSend.mark, this.dataToSend.model)
        .subscribe(
          (response: any = {}) => {
            let items: any;
            let itemsFinal: any;
            // -Filtramos solo los registros que correspondan al modelo selecionado
            items = response.elements.filter(itemx => itemx.cModelo == this.dataToSend.model);
            // -Eliminamos los Tipos duplicados
            itemsFinal = items.map(item => item.cTipo).filter(
              (value, index, self) => self.indexOf(value) === index).sort();
            for (const item of itemsFinal) {
              this.vehicleTypes.push({key: item, name: item});
            }
            console.info(this.vehicleTypes);
          }, error => {
            console.error(error);
          }
        );
    }
  }

  public loadVehiclesVersions() {
    this.vehicleVersions = [];
    this.dataToSend.version = '';
    if (this.dataToSend.insurer === 2) {
      this.quoteApi.getGnpVehicleVersions(this.dataToSend.mark, this.dataToSend.type)
        .subscribe(
          (response: any = {}) => {
            this.vehicleVersions = response.catalog[0].element;
          }, error => {
            console.error(error);
          }
        );
    } else if (this.dataToSend.insurer === 1) {
      this.quoteApi.getQltVehicleVersions(this.dataToSend.mark, this.dataToSend.type, this.dataToSend.model)
        .subscribe(
          (response: any = {}) => {
            for (const item of response.elements) {
              this.vehicleVersions.push({key: item.cVersion, name: item.cVersion});
            }
          }, error => {
            console.error(error);
          }
        );
    }
  }

  public getVehicle() {
    this.vehicleSelected = {};
    if (this.dataToSend.insurer === 2) {
      this.quoteApi.getGnpVehicle(this.dataToSend.mark, this.dataToSend.type, this.dataToSend.version, this.dataToSend.model)
        .subscribe(
          (response: any = {}) => {
            console.info(response);
            this.vehicleSelected = response;
          }, error => {
            console.error(error);
            if (error.status === 400) {
              this.toastr.warning(error.error.description, `Notificación`);
              this.dataToSend.version = undefined;
            }
          }
        );
    } else if (this.dataToSend.insurer === 1) {
      this.quoteApi.getQltVehicle(this.dataToSend.mark, this.dataToSend.type, this.dataToSend.model, this.dataToSend.version)
        .subscribe(
          (response: any = {}) => {
            console.info(response);
            this.vehicleSelected = response.elements[0];
          }, error => {
            console.error(error);
          }
        );
    }
  }

  openModalInvoiceValidator() {
    if (!this.dataToSend.invoiceValueFlag) {
      this.dataToSend.invoiceValue = undefined;
      return;
    }
    this.bsModalRef = this.modalService.show(InvoiceValidatorComponent, {keyboard: false, ignoreBackdropClick: true});
    this.bsModalRef.setClass('modal-md');
    this.bsModalRef.content.onClose.subscribe(result => {
      console.info(result);
      if (result.success === true) {
        this.dataToSend.invoiceValue = result.insuredAmount;
      } else {
        this.dataToSend.invoiceValueFlag = false;
        this.dataToSend.invoiceValue = undefined;
      }
    });
  }

}
