import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-admin-policies-paymentlink',
  templateUrl: './admin-policies-paymentlink.component.html',
  styleUrls: ['./admin-policies-paymentlink.component.css']
})
export class AdminPoliciesPaymentlinkComponent implements OnInit {

  private _noPolicy: string;
  private _policyId: number;
  modalRefPaymentLink: BsModalRef;
  message: string;
  isLink: boolean;
  linkUrl: SafeResourceUrl;
  linkUrlText: string;
  errorMessage: string;
  copied = false;

  get noPolicy(): string {
    return this._noPolicy;
  }

  get policyId(): number {
    return this._policyId;
  }

  @Input()
  set noPolicy(value: string) {
    this._noPolicy = value;
  }
  @Input()
  set policyId(value: number) {
    this._policyId = value;
  }



  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private spinner: NgxSpinnerService,
              private sanitizer: DomSanitizer,
              private modalService: BsModalService) { }

  ngOnInit() {



  }

  openModal(template: TemplateRef<any>) {
    const dataToSend = {
     noPolicy: this.cutString(this.noPolicy),
     policyId: this.policyId
   };

    this.invokeGetPaymentLink(dataToSend);

    this.modalRefPaymentLink = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }
  /**
   * Función para cargar roles
   */
  invokeGetPaymentLink(dataToSend) {
    this.api.getPaymentLink(dataToSend)
      .then(
        (response: any) => {
          this.processResponse(response.message);
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }

  processResponse(message: string): void {
    if (message.startsWith('https://')) {
      this.isLink = true;
      this.linkUrlText = message;
      this.linkUrl = this.sanitizer.bypassSecurityTrustResourceUrl(message);
    } else {
      this.isLink = false;
      const errorMatch = message.match(/{"error":"(.*?)"}/);
      if (errorMatch) {
        this.errorMessage = errorMatch[1];
      } else {
        this.errorMessage = 'Unknown error';
      }
    }
  }

  closeModalPaymentLink() {
    this.modalRefPaymentLink.hide();
  }

  copyToClipboard(): void {
    navigator.clipboard.writeText(this.linkUrlText).then(() => {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000); // Restablecer la bandera 'copied' después de 2 segundos
    }).catch(err => {
      console.error('Error al copiar el link al portapapeles: ', err);
    });
  }

  cutString(str: string): string {
    // Verificar si el string tiene 18 caracteres, empieza con "04" y termina con "000000"
    if (str.length === 18 && str.startsWith('04') && str.endsWith('000000')) {
      // Recortar los primeros 2 caracteres y los últimos 6 caracteres
      return str.slice(2, -6);
    } else {
      return str;
    }
  }
}
