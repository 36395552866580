import {Component, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {SharedService} from '../shared-service.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {regex} from '../../common/regex';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {element} from "protractor";
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-admin-messenger',
  templateUrl: './admin-messenger.component.html',
  styleUrls: ['./admin-messenger.component.css']
})
export class AdminMessengerComponent implements OnInit {
  dataSendEmail: any = [];
  dataSendPush: any = [];
  dataSaveTemplate: any = {};
  myFormSendEmail: FormGroup;
  myFormSendPush: FormGroup;
  myFormSaveTemplate: FormGroup;
  modalRef: BsModalRef;
  modalTemplate: BsModalRef;
  catTemplates: any = [];
  templateId: number;
  header: string;
  footer: string;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    sanitize: false,
    toolbarPosition: 'top',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    toolbarHiddenButtons: [
      ['insertImage',
        'insertVideo',
        'link',
        'unlink',
      ]
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
  groupList: any = [];
  groupsSelectedItems: any = [];
  dropdownSettingsGroups = {};
  clientList: any = [];
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  filters: any = {};
  userList: any = [];
  paginationResponse: any = {};
  catGroups: any = [];
  dataFilter: any = {};
  catPolicies: any = [];
  categoryList: any = [];
  categorySelectedItems: any = [];
  dropdownSettingsCategory = {};
  roleList: any = [];
  roleSelectedItems: any = [];
  dropdownSettingsRole = {};
  typeNotifications: any = [];
  userSelection: any = [];
  typeNotificationsUsers: any = [];
  // -Varibales para paginado->
  pgReceiptBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgReceiptMaxSize = 3; // Número de links en paginado
  pgReceiptItemsPerPage = 20; // Número de registros por página
  pgReceiptTotalItems: number; // Total de registros
  pgReceiptCurrentPage = 1; // Página actual
  constructor(private api: ApiService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService,
              private shared: SharedService,
              private formBuilder: FormBuilder,
              private validateAccess: ValidateAccessService) {
    this.myFormSendEmail =  this.formBuilder.group({
      title: ['', [Validators.required]],
      message : ['', [Validators.required]],
      template : ['', [Validators.required]]
    });

    this.myFormSendPush =  this.formBuilder.group({
      title: ['', [Validators.required]],
      message : ['', [Validators.required]]
    });
    this.myFormSaveTemplate = this.formBuilder.group( {
      name: ['', [Validators.required]],
      description : ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.initDataFilter();
    this.getGroups();
    this.dropdownSettingsClients = {
      singleSelection: false,
      idField: 'clientId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsGroups = {
      singleSelection: false,
      idField: 'groupId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsCategory = {
      singleSelection: false,
      idField: 'categoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON CATEGORIAS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.roleList = [
      { roleId: 4, name: 'ASEGURADOS COORPORATIVOS'},
      { roleId: 5, name: 'ASEGURADOS AMMIA'},
      { roleId: 3, name: 'ADMINISTRADORES DE CLIENTE'},
      { roleId: 6, name: 'EJECUTIVOS SUREXS' }
    ];
    this.typeNotificationsUsers = [
      {typeUser: 1, name: 'USUARIOS CON POLIZA LOGUEADOS'},
      {typeUser: 2, name: 'USUARIOS CON POLIZA NO LOGUEADOS'},
      {typeUser: 3, name: 'USUARIOS SIN POLIZA PERO CON CORREO EXISTENTE'}
    ];
    this.dropdownSettingsRole = {
      singleSelection: false,
      idField: 'roleId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON RESULTADOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dataFilter.roles = [4];
    this.roleSelectedItems = [{ roleId: 4, name: 'ASEGURADOS COORPORATIVOS'}];
    this.typeNotifications = [
      { typeNotification: 1 , type: 'PUSH' },
      { typeNotification: 2 , type: 'EMAIL'}
    ];
    this.validateAccess.validateAccessCore(47).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      }
    });
  }
  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupList = data;
      this.catGroups = data;
    }, error => {
    });
  }
  invokeServiceGetPolicies(g, sG) {
    const obj = {
      groups: g,
      subGroups: sG
    };
    this.api.getPoliciesMessaging(obj).then((data: any) => {
      if (data != null) {
        this.catPolicies = data;
      } else {
        this.catPolicies = [];
      }
    }, error => {
    });
  }

  loadFiltersTable() {
    this.userSelection = [];
    this.validateAccess.validateAccessCore(47).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getPaymentOrdersByPagination(1, 20);
      }
    });
  }
  loadFiltersTableClear() {
    this.initDataFilter();
    this.userList = [];
    this.paginationResponse.total =  0;
    this.categorySelectedItems = [];
    this.clientsSelectedItems = [];
    this.groupsSelectedItems = [];
    this.roleSelectedItems = [];
    this.userSelection = [];
  }
  getPaymentOrdersByPagination(page, size) {
    this.spinner.show('sp');
    this.dataFilter.page = page;
    this.dataFilter.size = size;
    this.api.getUsersMessagingPagination(this.dataFilter)
      .then(
        (response: any) => {
          if (response == null) {
            this.pgReceiptTotalItems = 0;
            this.userList = [];
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON EL FILTRO SELECCIONADO', 'NOTIFICACIÓN');
          } else {
            this.paginationResponse = response;
            this.pgReceiptTotalItems = this.paginationResponse.total ;
            this.userList = this.paginationResponse.list ;
          }
          this.spinner.hide('sp');
        }, error => {
          this.pgReceiptTotalItems = 0;
          this.userList = [];
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  sendEmailMdl(template: TemplateRef<any>) {
    this.validateAccess.validateAccessCore(48).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        if (this.userList.length === 0) {
          this.toastr.info('DEBE DE APLICAR FILTROS PARA PODER ENVIAR NOTIFICACIONES', 'NOTIFICACIÓN');
          return;
        }
        this.templateId = 0;
        this.invokeServiceGetTemplatesEmail();
        this.initObjectSendEmail();
        this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
      }
    });
  }

  sendPushMdl(template: TemplateRef<any>) {
    this.validateAccess.validateAccessCore(48).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        if (this.userList.length === 0) {
          this.toastr.info('DEBE DE APLICAR FILTROS PARA PODER ENVIAR NOTIFICACIONES', 'NOTIFICACIÓN');
          return;
        }
        this.initObjectSendPush();
        this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
      }
    });
  }

  closeModal() {
    this.modalRef.hide();
  }
  pageReceiptChanged(event: PageChangedEvent): void {
    this.pgReceiptCurrentPage = event.page;
    this.getPaymentOrdersByPagination(this.pgReceiptCurrentPage, this.pgReceiptItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() {
  }
  initObjectSendEmail() {
    this.dataSendEmail = {
      groups: this.dataFilter.groups,
      subGroups: this.dataFilter.subGroups,
      policyId: this.dataFilter.policyId,
      categories: this.dataFilter.categories,
      users: this.userSelection,
      roles: this.dataFilter.roles,
      typeUser: this.dataFilter.typeUser,
      title: '',
      message: '',
      typeNotification: 2,
      search: this.dataFilter.search
    };
  }

  initObjectSendPush() {
    this.dataSendPush = {
      groups: this.dataFilter.groups,
      subGroups: this.dataFilter.subGroups,
      policyId: this.dataFilter.policyId,
      categories: this.dataFilter.categories,
      users: this.userSelection,
      roles: this.dataFilter.roles,
      typeUser: this.dataFilter.typeUser,
      title: '',
      message: '',
      typeNotification: 1,
      search: this.dataFilter.search
    };
  }

  invokeServiceGetTemplatesEmail() {
    this.api.getTemplates().then((data: any) => {
      this.catTemplates = data;
    }, error => {
    });
  }
  loadTemplateEmail(id: number) {
    this.spinner.show('sp');
    this.catTemplates.forEach(item => {
      if (item.templateId === id) {
        this.dataSendEmail.message = item.html;
        this.header = item.header;
        this.footer = item.footer;
        this.spinner.hide('sp');
        return;
      }
    });
  }
  invokeServiceSendEmail(template: TemplateRef<any>) {
    this.spinner.show('sp');
    this.api.postSendEmailMass(this.dataSendEmail)
      .then(
        (response: any) => {
          this.toastr.success('EL PROCESO DE NOTIFICACIONES MASIVAS A INICIADO, CUANDO TERMINE SE LE NOTIFICARA', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          this.closeModal();
          this.functionInitDataSaveTemplate();
          this.dataSaveTemplate.html = this.dataSendEmail.message;
          this.modalTemplate = this.modalService.show(template, {class: 'modal-md', keyboard: false});
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición, comunicarse con el administrador del sistema', 'Notificación');
          this.spinner.hide('sp');
          this.closeModal();
        }
      );
  }

  changeStatusGroups() {
    const groups = [];
    let groupsName = '';
    const group = [];
    setTimeout(() => {
      try {
        this.groupsSelectedItems.forEach(item => {
          groups.push(item.name);
          group.push(item.groupId);
          groupsName = groupsName + item.name + ', ';
        });
        this.dataFilter.groups = group;
        let multiGroup = {};
        multiGroup = {
          idsGroups: group
        };
        this.getSubGroups(multiGroup);
        this.invokeServiceGetPolicies(this.dataFilter.groups, this.dataFilter.subGroups);
      } catch (e) { }
    }, 1000);
  }
  getSubGroups(groups) {
    this.api.getSubGroupsMultiselect(groups).then((data: any) => {
      this.clientList = data;
    }, error => {
    });
  }
  changeStatusSubGroups() {
    const subGroups = [];
    let subGroupsName = '';
    setTimeout(() => {
      try {
        this.clientsSelectedItems.forEach(item => {
          subGroups.push(item.clientId);
          subGroupsName = subGroupsName + item.name + ', ';
        });
        this.dataFilter.subGroups = subGroups;
        this.invokeServiceGetPolicies(this.dataFilter.groups, this.dataFilter.subGroups);
      } catch (e) { }
    }, 500);
  }
  changeStatusCategory() {
    const categories = [];
    setTimeout(() => {
      try {
        this.categorySelectedItems.forEach(item => {
          categories.push(item.categoryId);
        });
        this.dataFilter.categories = categories;
      } catch (e) { }
    }, 500);
  }

  changeStatusRole() {
    const role = [];
    setTimeout(() => {
      try {
        this.roleSelectedItems.forEach(item => {
          role.push(item.roleId);
        });
        this.dataFilter.roles = role;
      } catch (e) { }
    }, 500);
  }
  initDataFilter() {
    this.dataFilter = {
      groups: [],
      subGroups: [],
      policyId: 0,
      categories: [],
      roles: [],
      typeUser: 0,
      search: '',
      typeNotification: 2,
      page: 1,
      size: 20
    };
    this.userSelection = [];
  }
  invokeServiceGetCategories() {
    this.api.getCategoriesByPolicyMessaging(this.dataFilter.policyId).then((data: any) => {
      if (data != null) {
        this.categoryList = data;
      } else {
        this.categoryList = [];
      }
    }, error => {
    });
  }

  checkUser(i) {
    if (i.selection) {
      this.userSelection.push(i.userId);
    } else {
      // console.log('entra a delete', i.selection, i.userId);
      delete this.userSelection[i.userId];
      const newArray = this.userSelection.filter(item => {
        return item !== i.userId;
      });
      this.userSelection = newArray;
    }
   // console.log('---------------------------------------------------------------------------');
   // console.log(this.userSelection);
  }
  invokeServiceSendPush() {
    // console.log(this.dataSendPush);
    this.spinner.show('sp');
    this.api.postSendEmailMass(this.dataSendPush)
      .then(
        (response: any) => {
          this.toastr.success('EL PROCESO DE NOTIFICACIONES MASIVAS A INICIADO, CUANDO TERMINE SE LE NOTIFICARA', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          this.closeModal();
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición, comunicarse con el administrador del sistema', 'Notificación');
          this.spinner.hide('sp');
          this.closeModal();
        }
      );
  }

  invokeServiceSaveTemplate() {
    this.spinner.show('sp');
    this.api.saveTemplates(this.dataSaveTemplate)
      .then(
        (response: any) => {
          this.toastr.success('LA PLANTILLA SE GUARDO CORRECTAMENTE', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          this.modalTemplate.hide();
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición, comunicarse con el administrador del sistema', 'Notificación');
          this.spinner.hide('sp');
          this.modalTemplate.hide();
        }
      );
  }
  functionInitDataSaveTemplate() {
    this.dataSaveTemplate = {
      templateId: 0,
      name: '',
      html: '',
      header: '',
      footer: ''
    };
  }
}
