import { Component, OnInit } from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {Router} from '@angular/router';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {BrowserError} from 'protractor/built/exitCodes';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {
  eventsList: any;
  dataEvent: any = [];
  countEvent: number;
  countEventTemp: number;
  constructor(private socket: Socket,
              private router: Router,
              private api: ApiService,
              private toastr: ToastrService,
              public shared: SharedService) {

  }

  ngOnInit() {
    this.getEventsTemp();
    this.getEvents();
  }
  getEventsTemp() {
    this.api.getEventsTemp().then((data: any) => {
      if (data != null) {
        this.countEventTemp = data.length;
      }
      }, error => {
        console.error(error.status);
        console.error(error.statusText);
        console.error(error.message);
      });
  }

  getEvents() {

    this.socket.on('notification', (data: any) => {
      this.eventsList = data;
      this.countEvent = this.eventsList.length;
      if (+this.countEvent != (+this.countEventTemp)) {
         let dif = this.countEvent - this.countEventTemp;
         for (let i = 0; i < dif; i++) {
           switch (this.eventsList[i].eventtype) {
             case 1:
               break;
             case 2:
               this.toastr.success('SE HA REPORTADO UN NUEVO SINIESTRO', 'NOTIFICACION');
               break;
             case 3:
               this.toastr.success('SE HA REPORTADO UNA SOLICITUD', 'NOTIFICACION');
               break;
             case 4:
               this.toastr.success('IMPORTACIÓN MASIVA REALIZADA DE FORMA EXITOSA', 'NOTIFICACION');
               break;
             case 5:
               this.toastr.success('IMPORTACIÓN MASIVA REALIZADA DE FORMA EXITOSA', 'NOTIFICACION');
               break;
             case 6:
               this.toastr.success('SE HA REPORTADO UNA SOLICITUD GENERAL', 'NOTIFICACION');
               break;
             case 7:
               this.toastr.success('SE HA REALIZADO LA IMPORTACIÓN MASIVA DE AUTOS, POR FAVOR, VERIFICA EL RESULTADO DE TU IMPORTACIÓN', 'NOTIFICACION');
               break;
             case 8:
               this.toastr.success('IMPORTACIÓN MASIVA DE SOLICITUDES REALIZADA DE FORMA EXITOSA', 'NOTIFICACION');
               break;
             case 9:
               this.toastr.success('SE HA REALIZADO LA IMPORTACIÓN MASIVA DE RECIBOS, TUS RECIBOS EN LA PÓLIZA', 'NOTIFICACION');
               break;
             case 10:
               this.toastr.success('SE HA REALIZADO LA IMPORTACIÓN MASIVA DE SOLICITUDES EN CORE', 'NOTIFICACION');
               break;
             case 11:
               this.toastr.success('SE HA REALIZADO LA IMPORTACIÓN MASIVA DE PÓLIZAS DE AUTOS', 'NOTIFICACION');
               break;
             case 12:
               this.toastr.success('SE HAN REGENERADO LAS PROVISIONES', 'NOTIFICACION');
               break;
             case 13:
               this.toastr.success('SE HA REALIZADO LA CANCELACIÓN MASIVA DE PÓLIZAS', 'NOTIFICACION');
               break;
             case 14:
               this.toastr.success('SE HA REALIZADO LA ACTUALIZACIÓN MASIVA DE ASEGURADOS', 'NOTIFICACION');
               break;
           }
         }
         this.countEventTemp = this.countEvent;
      }
    });
  }
  updateRead(eventId) {
        this.api.updateRead(eventId).then((data: any) => {
          if (data != null) {
            this.countEventTemp = data.length;
            // console.log('getEventsTemp');
            // console.log(this.countEventTemp);
          }
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        });
  }

  eventDetail(event) {
    switch (event.eventtype) {
      case 1:
        break;
      case 2:
        localStorage.setItem('incidentId',  event.relationid);
        this.router.navigate(['/admin/incident_detail']);
        break;
      case 3:
        localStorage.setItem('eventView', JSON.stringify(event));
        this.shared.fCloseSidebar();
        setTimeout( () => {
          this.shared.fOpenSidebar(23, 'md');
        }, 700);
        break;
      case 4:
        localStorage.setItem('eventView', JSON.stringify(event));
        this.shared.fCloseSidebar();
        setTimeout( () => {
          this.shared.fOpenSidebar(23, 'md');
        }, 700);
        break;
      case 5:
        localStorage.setItem('eventView', JSON.stringify(event));
        this.shared.fCloseSidebar();
        setTimeout( () => {
          this.shared.fOpenSidebar(24, 'md');
        }, 700);
        break;
      case 6:
        this.shared.fCloseSidebar();
        localStorage.setItem('request-id', event.relationid);
        // this.updateRead({'eventId': event.eventid});
        break;
      case 8:
        localStorage.setItem('eventView', JSON.stringify(event));
        this.shared.fCloseSidebar();
        setTimeout( () => {
          this.shared.fOpenSidebar(23, 'md');
        }, 700);
        break;
      case 7:
        localStorage.setItem('eventView', JSON.stringify(event));
        this.shared.fCloseSidebar();
        setTimeout( () => {
          this.shared.fOpenSidebar(25, 'md');
        }, 700);
        break;
      case 10:
        localStorage.setItem('eventView', JSON.stringify(event));
        this.shared.fCloseSidebar();
        setTimeout( () => {
          this.shared.fOpenSidebar(26, 'md');
        }, 700);
        break;
      case 11:
        localStorage.setItem('eventView', JSON.stringify(event));
        this.shared.fCloseSidebar();
        setTimeout( () => {
          this.shared.fOpenSidebar(27, 'md');
        }, 700);
        break;
      case 13:
        localStorage.setItem('eventView', JSON.stringify(event));
        this.shared.fCloseSidebar();
        setTimeout( () => {
          this.shared.fOpenSidebar(28, 'md');
        }, 700);
        break;
      case 14:
        localStorage.setItem('eventView', JSON.stringify(event));
        this.shared.fCloseSidebar();
        setTimeout( () => {
          this.shared.fOpenSidebar(29, 'md');
        }, 700);
        break;
    }
    this.updateRead({'eventId': event.eventid});
  }

}

