import {CompanySubranchCategories} from './companySubranchCategories';
import {number} from '@amcharts/amcharts4/core';

export class CompanySubranch {
  private _idCompanySubranch: number;
  private _companyId: number;
  private _subranchId: number;
  private _branchId: number;
  private _companySubranchCategories: CompanySubranchCategories[];

  constructor(idCompanySubranch: number, companyId: number, subranchId: number, branchId: number, companySubranchCategories: CompanySubranchCategories[] = []) {
    this._idCompanySubranch = idCompanySubranch;
    this._companyId = companyId;
    this._subranchId = subranchId;
    this._branchId = branchId;
    this._companySubranchCategories = companySubranchCategories.map(detail => new CompanySubranchCategories(
        detail.idCoverageSubranchCategories,
        detail.nameCategory,
        detail.coveragesJson,
        detail.endorsementJson,
        detail.endorsementPlacementFactorsSelectedJson,
        detail.coveragePlacementFactorsSelectedJson,
        detail.companySubranchId,
        detail.monedaType,
        detail.groupId,
        detail.coveragePlacementSelected,
        detail.endorsementPlacementSelected,
        detail.coveragePlacementFactorsSelected,
        detail.endorsementPlacementFactorsSelected
    ));
  }

  // Getter y Setter para _idCompanySubranch
  get idCompanySubranch(): number {
    return this._idCompanySubranch;
  }

  set idCompanySubranch(value: number) {
    this._idCompanySubranch = value;
  }

  // Getter y Setter para _companyId
  get companyId(): number {
    return this._companyId;
  }

  set companyId(value: number) {
    this._companyId = value;
  }

  // Getter y Setter para _subranchId
  get subranchId(): number {
    return this._subranchId;
  }

  set subranchId(value: number) {
    this._subranchId = value;
  }

  get companySubranchCategories(): CompanySubranchCategories[] {
      return this._companySubranchCategories;
  }

  set companySubranchCategories(value: CompanySubranchCategories[]) {
      this._companySubranchCategories = value;
  }

  get branchId(): number {
    return this._branchId;
  }

  set branchId(value: number) {
    this._branchId = value;
  }

  toPlainObject(): any {
    return {
      idCompanySubranch: this._idCompanySubranch,
      companyId: this._companyId,
      subranchId: this._subranchId,
      branchId: this._branchId,
      companySubranchCategories: this._companySubranchCategories.map(detail => detail.toPlainObject()),
    };
  }
}
