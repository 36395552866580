import {Component, Input, OnInit, TemplateRef, ViewChildren} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgWizardComponent, NgWizardConfig, NgWizardService, STEP_STATE, StepChangedArgs, THEME} from 'ng-wizard';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-admin-proyection-renovation',
  templateUrl: './admin-proyection-renovation.component.html',
  styleUrls: ['./admin-proyection-renovation.component.css']
})
export class AdminProyectionRenovationComponent implements OnInit {
  listCategories: any = [];
  listUsers: any = [];
  tariffList: any = [];
  private _modalRefProyection: BsModalRef;
  @ViewChildren('wiz') wiz: NgWizardComponent;
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.default,
    cycleSteps: false,
    toolbarSettings: {
      showPreviousButton: false
      , showNextButton: false
    },
    anchorSettings: {
      anchorClickable: true,
      enableAllAnchors: true,
      markDoneStep: true,
      markAllPreviousStepsAsDone: true,
      removeDoneStepOnNavigateBack: false,
      enableAnchorOnDoneStep: true
    }
  };

  // Varibales para paginado de usuarios
  pgBoundaryLinks = true; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 5; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  hideTariff: boolean;
  categoryIdSelected: number;
  constructor(private modalService: BsModalService,
              private apiService: ApiService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService) {
    this.hideTariff = true;

  }

  ngOnInit() {
    this.getCategoriesPolicy();
  }


  get modalRefProyection(): BsModalRef {
    return this._modalRefProyection;
  }
  @Input()
  set modalRefProyection(value: BsModalRef) {
    this._modalRefProyection = value;
  }

  closeModalProyection() {
    this._modalRefProyection.hide();
  }

  showTarrif() {
    if (this.hideTariff) {
      this.hideTariff = false;
    } else {
      this.hideTariff = true;
    }
  }
  stepChanged(args: StepChangedArgs) {
    this.categoryIdSelected = this.listCategories[args.step.index].categoryId;
    this.pgCurrentPage = 1;
    this.getUserByPolicyAndCategory(1, 10, this.categoryIdSelected);
    this.invokeServiceGetTariff(this.categoryIdSelected);
  }
  getCategoriesPolicy() {
    this.listCategories = [];
    this.apiService.getCategoriesByPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.listCategories = data;
      this.categoryIdSelected = this.listCategories[0].categoryId;
      // this.getUserByPolicyAndCategory(1, 10, this.categoryIdSelected);
      // this.invokeServiceGetTariff(this.categoryIdSelected);

    }, error => {

    });
  }

  /**
   * Carga los datos según la página seleccionada
   */
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getUserByPolicyAndCategory(this.pgCurrentPage, this.pgItemsPerPage, this.categoryIdSelected);
  }
  getUserByPolicyAndCategory(page, size, categoryId) {
    this.listUsers = [];
    const dataFilter = {
      policyId: localStorage.getItem('policy-id'),
      categoryId,
      page,
      size
    };
    this.apiService.getUsersByPolicyAndCategory(dataFilter).then((data: any) => {
      if (data == null) {
        this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
        this.listUsers = [];
        this.pgTotalItems = 0;
        this.spinner.hide('sp');
        return;
      }
      this.paginationResponse = data;
      this.spinner.hide('sp');
      this.pgTotalItems = this.paginationResponse.totals;
      this.listUsers = this.paginationResponse.userProyectionFilters;
    }, error => {
      this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
      this.listUsers = [];
      this.pgTotalItems = 0;
      this.spinner.hide('sp');
    });
  }
  invokeServiceGetTariff(categoryId) {
    this.apiService.getTariffByCategory(categoryId).then((data: any) => {
      this.tariffList = data;
    }, error => {
    });
  }

  downloadExcel(categoryId) {
    const dataFilter = {
      policyId: localStorage.getItem('policy-id'),
      categoryId: 0,
    };
    this.apiService.getDownloadUsersByPolicyAndCategory(dataFilter).then((data: any) => {
          const dataType = data.type;
          const binaryData = [];
          binaryData.push(data);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', 'proyeccion.xls');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          console.error(error);
        }
      );
  }
}
