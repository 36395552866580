import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared-service.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-request-event-detail',
  templateUrl: './request-event-detail.component.html',
  styleUrls: ['./request-event-detail.component.css']
})
export class RequestEventDetailComponent implements OnInit {

  event: any = {};
  insuredList: any = [];
  importOverview: any = {};
  constructor(public shared: SharedService,
              private toastr: ToastrService,
              private api: ApiService,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.event = JSON.parse(localStorage.getItem('eventView'));
    this.getEventDetailImportOverview(this.event.eventid);
  }


  public invokeServiceForGetAttachmentFile() {
    this.api.getReportImportOverviewRequest(this.event.eventid)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', 'INFORME DE CARGA DE SOLICITUDES.xls');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          console.error(error);
        }
      );
  }

  getEventDetailImportOverview(id) {
    this.api.getImportOverviewRequest(id).then((data: any) => {
      this.importOverview = data;
      this.insuredList = this.importOverview.requestTempDetailLayouts;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

}
