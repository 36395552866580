import {Component, OnInit} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BranchDTO, Group, GroupBranchListDTO, UserModel} from './user-model';
import {regex} from '../../common/regex';
import {error} from 'util';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-admin-users-detail',
  templateUrl: './admin-users-detail.component.html',
  styleUrls: ['./admin-users-detail.component.css']
})
export class AdminUsersDetailComponent implements OnInit {

  userForm: FormGroup; // Formulario
  userModel: UserModel; // Modelo de Usuario
  group: Group;
  operationUser: number; // Si es edicion o vista
  userId: number;

  branchsSelectedItems: any = [];
  roleList: any[]; // Tipo de rol
  genderList: any[]; // Catálogo de Genero
  groupList: any[]; // Catálogo de Grupo
  subGroupList: any[]; // Catálogo de Subgrupos
  branchList: any[]; // Catálogo de Branch
  dropdownSettingsBranchs = {};
  groupTableView: any[];
  rolSecondary: boolean;
  secondaryRol: any = {};
  rolesSecondaryList: any = [];

  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) {

    // Se inicializa el formulario
    this.userForm = new FormGroup(
      {
        name: new FormControl(null, [
          Validators.maxLength(100),
          Validators.required,
          Validators.pattern(regex.alfanumerico)
        ]),
        firstname: new FormControl(null, [
          Validators.maxLength(100),
          Validators.required,
          Validators.pattern(regex.alfanumerico)
        ]),
        lastname: new FormControl(null, [
          Validators.maxLength(100),
          Validators.required,
          Validators.pattern(regex.alfanumerico)
        ]),
        role: new FormControl(null, [
          Validators.required
        ]),
        email: new FormControl(null, [
          Validators.maxLength(200),
          Validators.required,
          Validators.pattern(regex.correoElectronicoSurex)
        ]),
        gender: new FormControl(null, [
          Validators.required
        ]),
        group: new FormControl(),
        branch: new FormControl(),
        status: new FormControl({disabled: true}),
        cellPhoneNumber: new FormControl(null, [
          Validators.required,
          Validators.pattern(regex.numeros)
        ]),
        secondaryRol: new FormControl()
      }
    );

    this.invokeServiceGetRoles();
    this.invokeServiceGetCatalogGender();
    this.invokeServiceGetGroupList();
    this.invokeServicesGetBranches();

    this.operationUser = Number(localStorage.getItem('operationUser'));
    this.userId = Number(localStorage.getItem('user-id'));
  }

  ngOnInit() {
    this.rolSecondary = false;
    this.dropdownSettingsBranchs = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON RAMOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.userModel = new UserModel();
    this.group = new Group();

    if (this.operationUser === 1 || this.operationUser === 2) { // 1 y 2 son Edición o View
      this.inokeServiceUser(this.userId);
      if (this.operationUser === 2) {
        this.userForm.disable();
      }
    }
  }

  /**
   * Función para regresar a la pantalla de usuarios
   */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/catalog']);
  }

  /**
   * Función para obtener el subgrupo según el grupo seleccionado
   */
  onGroupSelected(event) {

    if (event !== undefined) {
      this.group.group = event.name;
    }
  }

  /**
   * Función para obtener un usuario por su ID
   */
  inokeServiceUser(userId) {
    const user = {
      id: userId
    };

    this.api.getUserById(user)
      .then(
        (response: any) => {
          this.userModel = response;

          if (this.userModel.groupList === null) {
            this.userModel.groupList = new Array<Group>();
          }

          if (this.userModel.groupBranchList === null) {
            this.userModel.groupBranchList = new Array<GroupBranchListDTO>();

          }
          this.group = new Group();
          this.invokeFunctionInitObjectSecondaryRol(userId, this.userModel.cellPhoneNumber, this.userModel.role, 0, '');
          if (this.userModel.role === 6) {
            this.invokeServiceGetSecondaryRol(userId);
            this.rolSecondary = true;
            this.rolesSecondaryList = [ { id: 0, name: 'NO APLICA' }, { id: 3, name: 'EJECUTIVO SINIESTRO'} ];
          } else if (this.userModel.role === 9) {
            this.invokeServiceGetSecondaryRol(userId);
            this.rolSecondary = true;
            this.rolesSecondaryList = [ { id: 0, name: 'NO APLICA' }, { id: 2, name: 'EJECUTIVO CUENTA'} ];
          }
          this.userModel.groupBranchList[0].idBranchList.forEach(item => {
            this.secondaryRol.branches.push(item.branchId);
          });
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el usuario', 'Notificación');
        }
      );

  }
  invokeServiceGetSecondaryRol(id) {
    const user = {
      userId: id,
      name: ''
    };
    this.api.getRolSecondary(user)
      .then(
        (response: any) => {
          console.log(response);
          this.secondaryRol.roleSecondary = response.roleId;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el usuario', 'Notificación');
        }
      );
  }

  invokeServiceUpdateSecondaryRol() {
    this.api.updateSecondaryRol(this.secondaryRol)
      .then(
        (response: any) => {
          console.log(response);
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el usuario', 'Notificación');
        }
      );
  }

  invokeServiceSaveSecondaryRol() {
    this.api.saveSecondaryRol(this.secondaryRol)
      .then(
        (response: any) => {
          console.log(response);
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el usuario', 'Notificación');
        }
      );
  }

  invokeFunctionInitObjectSecondaryRol(id, phone, role, secondary, em) {
    this.secondaryRol = {
      userId: id,
      phoneNumber: phone,
      rolePrincipal: role,
      roleSecondary: secondary,
      branches: [],
      email: em
    };
  }

  /**
   * Función para obtener el catálogo de roles
   */
  invokeServiceGetRoles() {
    this.api.getRolList()
      .then(
        (response: any) => {
          this.roleList = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }

  onRolSelected(event) {
    console.log(event);
    if (event === 6) {
      this.rolSecondary = true;
      this.rolesSecondaryList = [ { id: 0, name: 'NO APLICA' }, { id: 3, name: 'EJECUTIVO SINIESTRO'} ];
      this.secondaryRol.roleSecondary = 0;
    } else if (event === 9) {
      this.rolSecondary = true;
      this.rolesSecondaryList = [ { id: 0, name: 'NO APLICA' }, { id: 2, name: 'EJECUTIVO CUENTA'} ];
      this.secondaryRol.roleSecondary = 0;
    }
  }
  /**
   * Función para obtener el catálogo de géneros
   */
  invokeServiceGetCatalogGender() {
    this.api.getGenderList()
      .then(
        (response: any) => {
          this.genderList = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Género', 'Notificación');
        }
      );
  }

  /**
   * Función para obtener el catálogo de grupos
   */
  invokeServiceGetGroupList() {
    this.api.getGroupList()
      .then(
        (response: any) => {
          this.groupList = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar lo grupos', 'Notificación');
        }
      );
  }

  /**
   * Función para obtener el catálogo de subgrupos según el grupo
   */
  invokeServiceGetSubgroupList(groupId) {
    let exist = false;

    this.userModel.groupBranchList.some(GROUP => {
      if (Number(GROUP.idGroup) === Number(this.group.groupId)) {
        exist = true;
      }
    });

    if (exist) {
      this.toastr.warning('El grupo ya se encuentroa asociado', 'Notificación');
    } else {
      const group = {
        id: Number(groupId)
      };
      this.api.getSubGroupList(group)
        .then(
          (response: any) => {
            this.subGroupList = response;
            this.associateAllSubGroupToGroup();
          }, error => {
            this.toastr.error('Ocurrió un problema al cargar los subgrupos', 'Notificación');
          }
        );
    }
  }

  associateAllSubGroupToGroup() {

    if (this.userModel.groupList.length === 0) {
      this.userModel.groupList = [];
      this.userModel.groupBranchList = [];
    }

    this.subGroupList.forEach(SUBGRUPO => {
      const groupItem = new Group();
      groupItem.groupId = this.group.groupId;
      groupItem.group = this.group.group;
      groupItem.subGroupId = SUBGRUPO.id;
      groupItem.subGroup = SUBGRUPO.name;
      this.userModel.groupList.push(groupItem);
    });

    const groupBranches = new GroupBranchListDTO();

    this.branchsSelectedItems.forEach(BRANCH => {
      const branchs = new BranchDTO();
      branchs.branchId = BRANCH.id;
      branchs.name = BRANCH.name;
      groupBranches.idGroup = this.group.groupId;
      groupBranches.nameGroup = this.group.group;
      groupBranches.idBranchList.push(branchs);

    });
    this.userModel.groupBranchList.push(groupBranches);
    this.branchsSelectedItems = [];
    this.group = new Group();

  }

  /**
   * Función para asignar los subgrupos al usuario
   */
  onBranchSelected() {
    this.invokeServiceGetSubgroupList(this.group.groupId);

  }

  /**
   * Función para elimina un subrupo de un usuario
   */
  onDeleteSelected(index) {
    const group = this.userModel.groupBranchList[index].idGroup;
    this.userModel.groupBranchList.splice(index, 1);
    const idsSubgrups = [];

    this.userModel.groupList.forEach(GROUP => {
      if (Number(GROUP.groupId) === Number(group)) {
        idsSubgrups.push(GROUP.subGroupId);
      }
    });

    idsSubgrups.forEach(ID => {
      index = this.userModel.groupList.findIndex(GROUP => Number(GROUP.subGroupId) === Number(ID));
      this.userModel.groupList.splice(index, 1);
    });
  }

  /**
   * Función para editar un subrupo de un usuario
   */
  onEditSelected(index) {
    this.userModel.groupBranchList[index].idBranchList.forEach(BRANCH => {
      this.branchsSelectedItems.push({
        id: BRANCH.branchId,
        name: BRANCH.name
      });
    });
    this.userForm.controls.branch.setValue(this.branchsSelectedItems);

    // tslint:disable-next-line:max-line-length
    // this.branchsSelectedItems.push({id:this.userModel.groupBranchList[index].idBranchList }); = this.userModel.groupBranchList[index].idBranchList;
    this.group.groupId = this.userModel.groupBranchList[index].idGroup;
    this.group.group = this.userModel.groupBranchList[index].nameGroup;

    this.userModel.groupBranchList.splice(index, 1);
    const idsSubgrups = [];

    this.userModel.groupList.forEach(GROUP => {
      if (Number(GROUP.groupId) === Number(this.group.groupId)) {
        idsSubgrups.push(GROUP.subGroupId);
      }
    });

    idsSubgrups.forEach(ID => {
      index = this.userModel.groupList.findIndex(GROUP => Number(GROUP.subGroupId) === Number(ID));
      this.userModel.groupList.splice(index, 1);
    });

  }

  /**
   * Función guardar un usuario
   */
  saveUser() {
    if (this.userForm.valid) { // Validación del formulario
      if (this.userModel.groupList.length === 0) { // Validar si hay subgrupos
        this.toastr.warning('Es necesario agregar un grupo', 'Notificación');
      } else {
        const role = this.userModel.role;
        this.userModel.role = Number(role);
        const gender = this.userModel.gender;
        this.userModel.gender = Number(gender);
        this.spinner.show('sp');
        this.api.saveUser(this.userModel).then( // Si todo bien, se guarda
          (response: any) => {
            console.log(response);
            if (this.userModel.role === 6 || this.userModel.role === 9) {
              // tslint:disable-next-line:max-line-length
              this.invokeFunctionInitObjectSecondaryRol(0, this.userModel.cellPhoneNumber, this.userModel.role, this.secondaryRol.roleSecondary, this.userModel.email);
              this.userModel.groupBranchList[0].idBranchList.forEach(item => {
                this.secondaryRol.branches.push(item.branchId);
              });
            }
            setTimeout(() => {
              this.invokeServiceSaveSecondaryRol();
            }, 500);
            this.spinner.hide('sp');
            this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
            this.router.navigate(['/admin/user']);
          }, error => {
            this.spinner.hide('sp');
            if (error.status === 500) {
              if (error.error.message !== undefined) {
                this.toastr.warning(error.error.message, 'Notificación');
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          }
        );
      }
    } else {
      this.userForm.controls.name.markAsTouched();
      this.userForm.controls.firstname.markAsTouched();
      this.userForm.controls.lastname.markAsTouched();
      this.userForm.controls.role.markAsTouched();
      this.userForm.controls.gender.markAsTouched();
      this.userForm.controls.cellPhoneNumber.markAsTouched();
    }
  }

  /**
   * Función actualizar un usuario
   */
  updateUser() {
    if (this.userForm.valid) { // Validación del formulario
      if (this.userModel.groupList.length === 0) { // Validar si hay subgrupos
        this.toastr.warning('Es necesario agregar un subgrupo', 'Notificación');
      } else {
        const role = this.userModel.role;
        this.userModel.role = Number(role);
        const gender = this.userModel.gender;
        this.userModel.gender = Number(gender);

        this.api.updateUser(this.userModel).then(
          (response: any) => {
            this.invokeServiceUpdateSecondaryRol();
            this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
            this.router.navigate(['/admin/user']);
          }, error => {
            if (error.status === 500) {
              if (error.error.message !== undefined) {
                this.toastr.warning(error.error.message, 'Notificación');
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          }
        );
      }
    } else {
      this.userForm.controls.name.markAsTouched();
      this.userForm.controls.firstname.markAsTouched();
      this.userForm.controls.lastname.markAsTouched();
      this.userForm.controls.role.markAsTouched();
      this.userForm.controls.gender.markAsTouched();
      this.userForm.controls.cellPhoneNumber.markAsTouched();
    }
  }

  /**
   * Función desactivar un usuario
   */
  desactiveUser(idUser) {
    this.validateAccess.validateAccessCore(33).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        const user = {
          id: Number(idUser),
          active: false
        };
        this.api.disableUser(user)
          .then(
            (response: any) => {
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.router.navigate(['/admin/user']);
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            });
      }
    });
  }

  /**
   * Función activar un usuario
   */
  activeUser(idUser) {
    const user = {
      id: Number(idUser),
      active: true
    };

    this.api.disableUser(user)
      .then(
        (response: any) => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.router.navigate(['/admin/user']);
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        });
  }

  invokeServicesGetBranches() {
    this.api.getBranchesForSpecialCoverage()
      .then(
        (response: any) => {
          this.branchList = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }

  branchs(arrayBranchDTO: BranchDTO[]): string {

    const branchs = arrayBranchDTO.map(branch => {
      return branch.name;
    }).join(', ');

    return branchs;
  }
}
