import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysName'
})
export class DaysNamePipe implements PipeTransform {

  transform(subbranches: any [], searchDays: string): any [] {
    if (!subbranches || !searchDays) {
      return subbranches;
    }

    return subbranches.filter(any =>
      any.subBranch.toLowerCase().indexOf(searchDays.toLowerCase()) !== -1);
  }

}
