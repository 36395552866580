

export class PolicyLoadMassive {
  policyLoad: PolicyLoad;

  constructor() {
    this.policyLoad = new PolicyLoad();
  }
}
export class PolicyLoad {
  policyBusiness: PolicyBusiness;
  receiptBusiness: ReceiptBusiness;
  constructor() {
    this.policyBusiness = new PolicyBusiness();
    this.receiptBusiness = new ReceiptBusiness();
  }
}


export class PolicyBusiness {
  policyId: number;
  number: string;
  startDate: string;
  expirationDate: string;
  groupId: number;
  branchId: number;
  subBranchId: number;
  renew: boolean;
  insuranceId: number;
  status: number;
  clients: Array<number>;
  currencyId: number; // 1:peso 2: USD
  userId: number;
  policyIdOld: number;
  policyNumberOld: string;
  paymentTypeId: number;
  payment: number;
  directoryId: number;
  coordinators: Array<number>;
  executiveSurexs: Array<number>;
  executiveIncidents: Array<number>;
  agentKeyId: number;
  typePaymentPolicy: number;
  managerIncident: number;
  maxAgeLimit: number;
  officeId: number;
  startDatePeriod: string;
  endDatePeriod: string;
  medicalCare: boolean;
  policySimply: boolean;
  policyIssued: number; // salesman
  financialClient: boolean;
  nombrePolizaMaestra: string;
  archivoPolizaMaestra: string;
  policyPath: string;


  constructor() {
    this.policyId = 0;
    this.number = '';
    this.startDate = '';
    this.expirationDate = '';
    this.groupId = 0;
    this.branchId = 0;
    this.subBranchId = 0;
    this.renew = false;
    this.insuranceId = 0;
    this.status = 0;
    this.clients = [];
    this.currencyId = 0;
    this.userId = 0;
    this.policyIdOld = 0;
    this.policyNumberOld = '';
    this.paymentTypeId = 0;
    this.payment = 0;
    this.directoryId = 0;
    this.coordinators = [];
    this.executiveSurexs = [];
    this.executiveIncidents = [];
    this.agentKeyId = 0;
    this.typePaymentPolicy = 0;
    this.managerIncident = 0;
    this.maxAgeLimit = 0;
    this.officeId = 0;
    this.startDatePeriod = '';
    this.endDatePeriod = '';
    this.medicalCare = false;
    this.policyIssued = 0;
    this.financialClient = false;
    this.nombrePolizaMaestra = '';
    this.archivoPolizaMaestra = '';
    this.policyPath = 'prueba';

  }
}

export class ReceiptBusiness {
  receiptId: number;
  commission: number;
  commissionAmount: number;
  netPremium: number;
  rights: number;
  paymentCharges: number;
  iva: number;
  totalPremium: number;
  paymentDate: '';
  paymentMonth: number;
  policyId: number;
  receiptStatusId: number;
  paymentFormId: number;
  paymentType: number;
  categoryId: number;
  taxId: number;
  numberReceipt: '';
  clientId: number;

  constructor() {
    this.receiptId = 0;
    this.commission = 0;
    this.commissionAmount = 0;
    this.netPremium = 0;
    this.rights = 0;
    this.paymentCharges = 0;
    this.iva = 0;
    this.totalPremium = 0;
    this.paymentDate = '';
    this.paymentMonth = 0;
    this.policyId = 0;
    this.receiptStatusId = 0;
    this.paymentFormId = 0;
    this.paymentType = 0;
    this.categoryId = 0;
    this.taxId = 0;
    this.numberReceipt = '';
    this.clientId = 0;  }
}


